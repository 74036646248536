import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Copyright from "../../../assets/common/copyright.png";
import Email from "../../../assets/common/email.png";
import Facebook from "../../../assets/common/facebook.png";
import Instagram from "../../../assets/common/instagram.png";
import Linkedin from "../../../assets/common/linkedin.png";
import Logo from "../../../assets/common/logoPrimaryFooter.png";
import Phone from "../../../assets/common/phone.png";
import Twitter from "../../../assets/common/twitter.png";
import emailBlack from "../../../assets/emailBlue.png";
import "./Footer.css";

function Footer() {
  const footerSchema = Yup.object().shape({
    Email: Yup.string().email("Invalid email").required("Required"),
  });

  const handleNewsletter = (values, { resetForm }) => {
    Swal.fire({
      title: "Subscribed",
      icon: "success",
      timer: 1000,
      showConfirmButton: false,
    }).then(() => {
      resetForm();
    });
  };

  return (
    <div>
      <div className="footer">
        <div className="upperFooter">
          <div className="upperChild1">
            <div className="logoImage">
              <a href="/">
                <img alt="Logo" src={Logo} />
              </a>
            </div>
            <div className="addressFooterCompanyName">
              Eines Healthcare Private Limited
            </div>
            <div className="addressFooter">
              <p>
                1025-1030, 80ft Main Road, <br />
                Koramangala 1st Block, <br />
                Bengaluru - 560034
              </p>
              <p>
                Office no. 881,
                <br /> Aggarwal Millennium Tower 2 , 
                <br />
                Netaji Subhash Place,
                <br />
                Pitampura, Delhi-110034
              </p>
            </div>
            <div className="emailContact">
              <img alt="Email" src={Email} />
              <a
                href="mailto: contact@yuvahealth.in"
                style={{ color: "#44576A" }}
              >
                <p>contact@yuvahealth.in</p>{" "}
              </a>
            </div>
            <div className="phoneContact">
              <img alt="Phone" src={Phone} />
              <p> +91 7619166998 ,
               </p> 
               <p> 1145045428 (9am - 7pm)
               </p>
            </div>

            <div className="socialmediaContact">
              <a
                href="https://www.instagram.com/yuva_health/"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="Instagram" src={Instagram} />
              </a>
              <a
                href="https://www.facebook.com/yuvahealthwellness"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="Facebook" src={Facebook} />
              </a>
              <a href="https://twitter.com/" target="_blank" rel="noreferrer">
                <img alt="Twitter" src={Twitter} />
              </a>
              <a
                href="https://www.linkedin.com/company/yuvahealth/"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="Linkedin" src={Linkedin} />
              </a>
            </div>
          </div>
          <div className="upperChild2Footer">
            <p>Company</p>
            <ol>
              <a href="/about-us">
                <li>About Us</li>
              </a>
              <a href="/terms-and-conditions">
                <li>Terms and Conditions</li>
              </a>
              <a href="/privacy-policy">
                <li>Privacy Policy</li>
              </a>
              <a href="/cancellation-and-refund-policy">
                <li>Cancellation And Refund</li>
              </a>
              <a href="/under-construction">{/* <li>FAQ's</li> */}</a>
              <a href="/contact-us">
                <li>Contact Us</li>
              </a>
            </ol>
          </div>
          <div className="upperChild3Footer">
            <p>Services</p>
            <ol>
              <a
                href={
                  localStorage.getItem("token") !== null
                    ? "/health-risk-assessment-locked"
                    : "/health-risk-assessment"
                }
              >
                <li>Health Risk Assessment</li>
              </a>
              <a href="/view-test">
                <li> Health Checkup & Diagnostics</li>
              </a>
              <a href="/rental-equipment">
                <li>Rental Equipment</li>
              </a>
              <a href="/consultation/cashless-opd">
                <li> OPD Consultation Program</li>
              </a>
              <a href="/talk-to-doctor">
                <li>Talk to Doctor</li>
              </a>
              <a href="/subscription">
                <li>Our Plans</li>
              </a>
            </ol>
          </div>
          <div className="upperChild4">
            <p>Newsletter</p>

            <div className="upperChild4Description">
              <span>
                Signup our newsletter to get update on information, news,
                insights and promotions.
              </span>
            </div>
            <Formik
              initialValues={{
                Email: "",
              }}
              enableReinitialize={true}
              validationSchema={footerSchema}
              onSubmit={handleNewsletter} // Pass handleNewsletter as onSubmit
            >
              {({ setFieldValue }) => (
                <Form>
                  <div className="emailInput">
                    <Field
                      placeholder="Email"
                      name="Email"
                      type="text"
                      className="newsLetterInput"
                    ></Field>
                    <ErrorMessage
                      name="Email"
                      component="div"
                      className="footerValidation"
                    />
                  </div>
                  <button className="signup" type="submit">
                    <img alt="Email" src={emailBlack} />
                    Sign-up
                  </button>
                </Form>
              )}
            </Formik>
            <span id="siteseal">
              <script
                async
                type="text/javascript"
                src="https://seal.starfieldtech.com/getSeal?sealID=ESPpgYVQ4Aie7coD4f2WEvPiNwWZhmTygcn8wBi6WR2HxkvADAL0C14ofBxp"
              ></script>
            </span>
          </div>
        </div>
        <div className="downFooter">
          <div className="copyrightSign">
            <img alt="Copyright" src={Copyright} />
          </div>
          <div className="copyrightText">
            <p>Yuva Health - 2023. All right reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
