import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../Action/HealthRiskAssessment/SaveSectionAnswersAction";
import NetworkService from "../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* SaveSectionAnswersSaga(action) {
  try {
    let response = yield call(NetworkService.SaveSectionAnswers, action);
    yield put(actions.SaveSectionAnswersSuccess(response));
  } catch (err) {
    yield put(actions.SaveSectionAnswersFailure(err));
  }
}

export function* WatchSaveSectionAnswersSaga() {
  yield takeLatest(types.SAVE_SECTION_ANSWERS_REQUEST, SaveSectionAnswersSaga);
}
