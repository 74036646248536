import React from "react";
import { useSelector } from "react-redux";
import cross from "../../../assets/MyAccount/cross_button.png";
import greenTick from "../../../assets/cart/greenTick.png";
import Coupon from "../../../assets/coupon.png";
import rightArrow from "../../../assets/rightArrow.png";

function CartOrderSummary({
  cart,
  apply,
  RemoveCoupon,
  handleCoupon,
  handleKeyPress,
  coupon1,
  handleApply,
  errorMessage,
  newPin,
  couponCode,
  handleApplyCoupon,
  handleCheckout,
  CouponNotFound,
}) {
  const getCart = useSelector((state) => state.cart.getCart);
  const getAllCoupons = useSelector((state) => state.cart.getAllCoupon);

  return (
    <div className="CartMainSecondContent">
      <h5 className="CartMainSecondContentHeaderNew">Order Summary</h5>

      <div className="CartMainSecondContentText">
        <div className="secondCartText">Price</div>
        <div className="PriceCartText">
          ₹
          {cart?.itemDtoList.length > 0
            ? cart?.cartPriceResponseDto.totalCost
            : 0}
          /-
        </div>
      </div>
      {cart?.cartPriceResponseDto.discountBeforeCoupon === 0 ? (
        ""
      ) : (
        <div className="CartMainSecondContentText">
          <div className="secondCartText">Discount</div>
          <div className="PriceCartText2">
            {`₹${cart?.cartPriceResponseDto.discountBeforeCoupon}/-`}
          </div>
        </div>
      )}

      <div className="CartMainSecondContentText" style={{ marginTop: "1.1em" }}>
        <div className="secondCartText">Order Amount</div>
        <div className="PriceCartText">
          ₹{cart?.cartPriceResponseDto.costAfterDiscount}/-
        </div>
      </div>
      <div className="CartMainSecondContentText">
        <div className="secondCartTextgst">Inclusive GST*</div>
      </div>

      <div className="CartMainSecondContentText" style={{ marginTop: "1.1em" }}>
        <div className="secondCartText">Collection Charges</div>
        <div className="PriceCartText">
          ₹{cart?.cartPriceResponseDto.processingCharge}/-
        </div>
      </div>
      <div className="CartMainSecondContentText">
        <div className="secondCartTextsmallProcessingCharge">
          Applicable for Diagnostic Tests*
        </div>
      </div>
      {apply ? (
        <div className="finalPaymentPlanNewDescriptionCartPage">
          <div className="finalPaymentPlanNewAppliedCoupon">
            <img src={Coupon} alt="CouponIcon" />

            <p>
              {getCart?.data?.data?.data?.data.cartPriceResponseDto.couponCode}
            </p>
            <img
              src={cross}
              onClick={RemoveCoupon}
              alt="crossIcon"
              style={{ cursor: "pointer" }}
            />
          </div>
          <h1 className="finalPaymentPlanNewDescriptionFixedText4">
            - ₹{cart?.cartPriceResponseDto.discountForCoupon}/-
          </h1>
        </div>
      ) : (
        ""
      )}
      <div className="CartMainSecondContentCoupon">
        <div className="CouponText">Apply Coupon</div>
        <div className="CodeAlert">
          <input
            className="CouponCodeCart"
            placeholder="Enter the coupon code"
            name="Coupon Code"
            onChange={handleCoupon}
            autoComplete="Off"
            onKeyPress={handleKeyPress}
            style={{ textTransform: "uppercase" }}
            value={coupon1}
            id="CouponCodeCartId"
          />
          <div
            className="CouponApplyButton"
            onClick={handleApply}
            onKeyPress={handleKeyPress}
          >
            Apply
          </div>
        </div>
        <p className="errorMessageCart">{errorMessage}</p>
        <p className="errorMessageCart">{CouponNotFound ? "Coupon Not Found":""}</p>
        <div className="lineseparatorcart">
          <hr />
        </div>
        <div className="CouponCodeNew">Coupon Code</div>
        {cart?.itemDtoList?.length > 0 ? (
          <div className="CouponsArea">
            {getAllCoupons?.data?.action?.data?.data.totalCoupons !== 0 ? (
              getAllCoupons?.data?.action?.data?.data.userCouponResponseDtoList.map(
                (item, index) => (
                  <div className="CartCardContentCoupon">
                    {item.maxDiscount === null ? (
                      <div className="CartCardContentChild1">
                        ₹&nbsp;{item.discountAmountOrPercentage}/- OFF
                        <div className="ApplyCode">
                          {parseInt(newPin) === index ||
                          couponCode === item.couponCode ? (
                            <img src={greenTick} alt="Green Tick" />
                          ) : (
                            <img
                              src={rightArrow}
                              onClick={handleApplyCoupon}
                              code={item.couponCode}
                              pin={index}
                              alt="Right arrow"
                            />
                          )}
                          &nbsp;
                          <p
                            onClick={handleApplyCoupon}
                            code={item.couponCode}
                            pin={index}
                          >
                            {parseInt(newPin) === index ||
                            couponCode === item.couponCode ? (
                              <p className="appliedPara">Applied</p>
                            ) : (
                              "Apply"
                            )}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="CartCardContentChild1">
                        {item.discountAmountOrPercentage}% OFF upto ₹
                        {item.maxDiscount}
                        <div className="ApplyCode">
                          {parseInt(newPin) === index ||
                          couponCode === item.couponCode ? (
                            <img src={greenTick} alt="Green Tick" />
                          ) : (
                            <img
                              src={rightArrow}
                              onClick={handleApplyCoupon}
                              code={item.couponCode}
                              pin={index}
                              alt="right arrow"
                            />
                          )}
                          &nbsp;
                          <p
                            onClick={handleApplyCoupon}
                            code={item.couponCode}
                            pin={index}
                          >
                            {parseInt(newPin) === index ||
                            couponCode === item.couponCode ? (
                              <p className="appliedPara">Applied</p>
                            ) : (
                              "Apply"
                            )}
                          </p>
                        </div>
                      </div>
                    )}

                    <div className="CartCardContentChild2">
                      Code: {item.couponCode}
                    </div>
                    <div className="CartCardContentChild3"></div>
                  </div>
                )
              )
            ) : (
              <div className="NoCoupons"> No Coupon available</div>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div className="buttonOfSecondCart" onClick={handleCheckout}>
        To Be Paid ₹ {cart?.cartPriceResponseDto.amountToBePaid} /-
      </div>
    </div>
  );
}

export default CartOrderSummary;
