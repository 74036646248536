import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Cart/CreateCartActionType";
import * as actions from "../../Action/Cart/RemoveCartItemAction";
import NetworkService from "../../../network/Network/Cart/CartNetwork";

export function* removeCartItemSaga(action) {
  try {
    let response = yield call(NetworkService.removeCartItem, action);
    yield put(actions.removeCartItemSuccess(response));
  } catch (err) {
    yield put(actions.removeCartItemFailure(err));
  }
}

export function* watchRemoveCartItemSaga() {
  yield takeLatest(types.REMOVE_CART_ITEM_REQUEST, removeCartItemSaga);
}
