import { all } from "redux-saga/effects";
import { watchClinicNetworkFormSaga } from "./clinicNetworkFormSaga";
import { watchConnectionBetweenUsSaga } from "./ConnectionBetweenUsSaga";
import { watchDiagnosticNetworkFormSaga } from "./diagnosticNetworkFormSaga";
import { watchHospitalNetworkFormSaga } from "./hospitalNetworkFormSaga";
import { watchProfessionalServicesNetworkFormSaga } from "./professionalServicesNetworkFormSaga";
import { watchViewAllHospitalSaga } from "./viewAllHospitalSaga";
import { watchViewAllNetworkSaga } from "./viewAllNetworkSaga";
import { watchViewAllNetworkTypeSaga } from "./viewAllNetworkTypeSaga";
import { watchViewNetworkCountSaga } from "./viewNetworkCountSaga";


function* rootNetworkSaga() {
    yield all([
        watchClinicNetworkFormSaga(),
        watchConnectionBetweenUsSaga(),
        watchDiagnosticNetworkFormSaga(),
        watchHospitalNetworkFormSaga(),
        watchProfessionalServicesNetworkFormSaga(),
        watchViewAllHospitalSaga(),
        watchViewAllNetworkSaga(),
        watchViewAllNetworkTypeSaga(),
        watchViewNetworkCountSaga(),
    ])
}

export default rootNetworkSaga;