import RequestService from "../../RequestService";
import { api } from "../../../helpers/OTP/generateEmailOtpConstant";

class NetworkService {
  generateEmailOtp(action) {
    const endPoint = `${api.generateEmailOtp}`;
    return RequestService.postRequest(endPoint,{
        email:action.email,
        name:action.name
    });
  }
}

export default new NetworkService();