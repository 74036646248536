import * as types from "../../Config/Account/InReportDiagnosticActionType";

export const myReportDiagnosticRequest = (id) => {
  return {
    type: types.MY_REPORT_DIAGNOSTIC_REQUEST,
    id:id
  };
};

export const myReportDiagnosticSuccess = (data) => {
  return {
    type: types.MY_REPORT_DIAGNOSTIC_SUCCESS,
    data,
  };
};

export const myReportDiagnosticFailure = (data) => {
  return {
    type: types.MY_REPORT_DIAGNOSTIC_FAILURE,
    data,
  };
};
