import * as types from "../../Config/ERM/MedicalRecordListingConfig"

export const medicalRecordDropdownRequest = () => {
    return {
        type: types.MEDICAL_RECORD_DROPDOWN_REQUEST,
    }
}

export const medicalRecordDropdownSuccess = (data) => {
    return {
        type: types.MEDICAL_RECORD_DROPDOWN_SUCCESS,
        data:data,
    }
}

export const medicalRecordDropdownFailure = (data) => {
    return {
        type: types.MEDICAL_RECORD_DROPDOWN_FAILURE,
        data:data,
    }
}