import React, { useState ,useEffect ,useRef} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import storage from "../../../Utils/storage";
import main from "../../../assets/PlanPage/main.png";
import BookAppointment from "../../Pages/ServicesOffered/BookAppointment";
import "./packageHero.css";
import Swal from "sweetalert2";
import { GetProfileRequest } from "../../../redux/Action/User/GetProfileAction";
import { useDispatch, useSelector } from "react-redux";

export default function PackageHero({
  relationsAllowed,
  PlanName,
  duration,
  price,
  finalCost,
}) {
  const navigate = useNavigate();
  const dispatch=useDispatch(); 
  const [openAppointment, setOpenAppointment] = useState(false);

  var nav = false;
  // const appointmentRef = useRef(null);

  const search = useLocation().search;
  const PlanUuid = new URLSearchParams(search).get("id");

  useEffect(() => {
    dispatch(GetProfileRequest());
  }, [dispatch]);


  const GetProfile = useSelector((state) => state.user.getProfile);

  useEffect(() => {
    if (GetProfile?.data?.data?.data?.data?.kycIsVerified !== undefined) {
      localStorage.setItem("kycVerification", GetProfile.data.data.data.data.kycIsVerified);
    }
  }, [GetProfile?.data]);



  function handleBuy(e) {
    if(localStorage.getItem("kycVerification")==="true"){
      storage.setStorage("PurchasePlanUuid", PlanUuid);
      if (storage.getStorage("token") !== null) {
        navigate(`/cart-checkout-plan`, {
          state: {
            uuid: PlanUuid,
          },
        });
      } else {
        navigate("/login", {
          state: "plan",
        });
      }
    }
    else{
      Swal.fire({
        title: "Your KYC is not completed !! You can purchase the plan once the KYC is completed ..",
        showDenyButton: true,
        confirmButtonText: "Go for KYC",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/account-settings");
        } else if (result.isDenied) {
         
        }
      });
    }
   
  }
  function handleAppointment() {
    setOpenAppointment(true);
  }

  function closeAppointment(boolean) {
    setOpenAppointment(boolean);
  }

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (appointmentRef.current && !appointmentRef.current.contains(event.target)) {
  //       setOpenAppointment(false);
  //     }
  //   }
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [appointmentRef]);

  return (
    <div className="packageHero">
      {openAppointment ? (
        //  <div ref={appointmentRef}>
          <BookAppointment closeAppointment={closeAppointment} nav={nav} />
          // </div>
      ) : (
        ""
      )}
      <div>
        <div style={{ display: "flex" , alignItems: 'flex-end'}}>
          <div className="packageHeroContent">
            {PlanName ? (
              <div className="packageHeroTitle">
                <span>{PlanName}: </span>
                Comprehensive Healthcare for Your Well-being
              </div>
            ) : (
              <div className="packageHeroTitle">
                We Are Providing Best & Affordable
                <br />
                <span>Diagnostic Services</span>
              </div>
            )}
            <div className="packageHeroDescription">
              Get a full body health checkup at the affordable cost . The
              package included, CBC , KFT, LFT , Thyroid profile, Vitamin
              profile ( D &12 ), Iron studies calcium & Urine Examination
            </div>
            <button
              className="packageHeroButton"
              onClick={PlanName ? handleBuy : handleAppointment}
            >
              {PlanName ? "Book Now" : "Book an Appointment"}
            </button>
          </div>
          <img src={main} alt="Doctors" />
        </div>

        {PlanName && (
          <div className="packageHeroFoot">
            <div className="packageHeroFootMember">
              <div className="packageHeroFootTitle">
                Member - {relationsAllowed.split(",").length}
              </div>
              <div>{relationsAllowed}</div>
            </div>
            <hr className="packageHeroFootLine" />
            <div className="packageHeroFootDuration">
              <div className="packageHeroFootTitle">Plan Period</div>
              <div>{duration}</div>
            </div>
            <hr className="packageHeroFootLine" />
            <div className="packageHeroFootPrice">
              {price === finalCost ? (
                <div className="packageHeroFootTitle">
                  <span className="packageHeroFinalPrice">₹ {finalCost}/-</span>
                </div>
              ) : (
                <div className="packageHeroPrice">
                  <span className="packageHeroFootTitle">₹ {finalCost}/-</span>
                  <span className="packageHeroDiscountPrice">₹ {price}/-</span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
