import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../../Action/HealthRiskAssessment/GetQuestionSection5Action";
import NetworkService from "../../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* GetQuestionSection5Saga(action) {
  try {
    let response = yield call(NetworkService.GetQuestionSection5, action);
    yield put(actions.GetQuestionSection5Success(response));
  } catch (err) {
    yield put(actions.GetQuestionSection5Failure(err));
  }
}

export function* watchGetQuestionSection5Saga() {
  yield takeLatest(types.GET_QUESTION_SECTION5_REQUEST, GetQuestionSection5Saga);
}
