import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const GetQuestionSection2Request = (data) => {
    return {
        type: types.GET_QUESTION_SECTION2_REQUEST,
        data:data
    }
}

export const  GetQuestionSection2Success = (data) => {
    return {
        type: types.GET_QUESTION_SECTION2_SUCCESS,
        data:data,
    }
}

export const  GetQuestionSection2Failure = (data) => {
    return {
        type: types.GET_QUESTION_SECTION2_FAILURE,
        data:data,
    }
}