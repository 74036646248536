import React from "react";
import PingIcon from "../../../assets/MyAccount/PingIcon.png";

function NoAddress() {
  return (
    <div>
      <div className="myprescriptionmaindiv">
        <div className="myprescriptiontext">MY ADDRESS BOOK</div>
        <div className="MyAddressIconsMainBox">
          <div className="MyAddressIconsMainBox2">
            <div className="ExtraClass">
              <img className="MyAddressIconPrescription" src={PingIcon} alt="Pin Icon"></img>
            </div>
            <div className="addressTextPrescription">
              Oops! You have no saved addresses.
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoAddress;
