import React, { useEffect, useState } from "react";
import "./OtpMobileVerification.css";
import "../../../../Organisms/OTP/NewOTP.css";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { userForgetPasswordRequest } from "../../../../../redux/Action/User/forgetPasswordAction";
import { userOtpMobileVerificationRequest } from "../../../../../redux/Action/User/otpMobileVerificationAction";
import CircularProgress from "@mui/material/CircularProgress";
import Email from "../../../../../assets/Login/emailLogoLogin.png";

function VerificationForgotPasswordOTPScreen({ check }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [isWrong, setIsWrong] = useState(false);
  const [isExpire, setExpire] = useState(false);
  const [timer, setTimer] = useState(30);
  const [showLoader, setShowLoader] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const handleChange = (code) => {
    setCode(code);
    setIsWrong(false);
  
  }

  const handleClickOtp = () => {
    if (code.length === 4) {
      const valuesOTP = {
        hash: code,
        id: check,
      };
      dispatch(userOtpMobileVerificationRequest(valuesOTP));
    }
  };
  const responseMobileVerification = useSelector(
    (state) => state.user.otpMobileVerification
  );

  useEffect(() => {
    if (responseMobileVerification.isLoading && code.length === 4) {
      setShowLoader(true);
    }

    if (responseMobileVerification?.data?.data?.data?.data === true) {
      Swal.fire({
        title: "Your OTP is Verified Successfully ",
        icon: "success",
      }).then(() => {
        return navigate("/login/resetPasswordMobile", {
          state: { hash: code, id: check },
        });
      });
    } else if (responseMobileVerification?.data?.data?.data?.data === false) {
      setIsWrong(true);
      setShowLoader(false);
    } else if (
      responseMobileVerification?.error?.data?.response?.status === 401
    ) {
      setShowLoader(false);
      return navigate("/linkExpired");
    } else if (
      responseMobileVerification?.error?.data?.response?.status === 502
    ) {
      setShowLoader(false);
      return navigate("/linkExpired");
    } else if (
      responseMobileVerification?.error?.data?.message === "Network Error"
    ) {
      setShowLoader(false);
      return navigate("/linkExpired");
    }
  }, [responseMobileVerification]);

  useEffect(() => {
    let intervalId;
    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      setIsTimerRunning(false);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isTimerRunning, timer]);

  const handleResendOTP = () => {
    const value = {
      email_or_phone: check,
    };
    dispatch(userForgetPasswordRequest(value));
    Swal.fire({
      title: "Please wait!!",
      text: "Generating OTP for you...",
      showCancelButton: false,
      showConfirmButton: false,
    });
  };

  return (
    <div className="signupArea">
      <p className="signupAreaFirstHeading">Forgot Password</p>
      <p className="signupAreaSecondHeading">Verify your Mobile Number here!</p>
      <div className="otpArea">
        <OtpInput
          value={code}
          onChange={handleChange}
          containerStyle={{
            width: "75%",
            height: "100%",
          }}
          numInputs={4}
          separator={<span style={{ width: "8px" }}></span>}
          isInputNum={true}
          shouldAutoFocus={true}
          inputStyle={{
            background: "#F3F0F0",
            borderRadius: "6px",
            border: "1px solid #1C71E1",
            borderRadius: "8px",
            width: "100%",
            height: "75px",
            fontSize: "26px",
            color: "#000",
            fontWeight: "400",
            caretColor: "blue",
          }}
          focusStyle={{
            border: "1px solid #CFD3DB",
            outline: "none",
          }}
        />
      </div>
      <div className="otpRegisterBottomDiv">
        {isExpire ? (
          <div className="expireOTPMessageMobile">
            <p>OTP Expired</p>
          </div>
        ) : null}
        {isWrong ? (
          <div className="expireOTPMessageMobile">Incorrect OTP</div>
        ) : null}
      </div>
      <button
        onClick={handleClickOtp}
        type="submit"
        className="signupInputFieldButton"
        style={showLoader ? { background: "transparent" } : {}}
        disabled={showLoader}
      >
        {showLoader ? <CircularProgress /> : <>Sign In</>}
      </button>
      {isTimerRunning ? (
        <p className="otpRegisterTimer">{timer} seconds</p>
      ) : (
        <p className=" otpRegisterTimer">
          Didn't Recive Code? &nbsp;
          <span
            style={{
              color: "#0089ED",
              cursor: "pointer",
              fontSize: "1em",
            }}
            onClick={handleResendOTP}
          >
            Resend
          </span>
        </p>
      )}
    </div>
  );
}

export default VerificationForgotPasswordOTPScreen;
