import * as types from "../../Config/City/cityActionType"

export const getCityRequest = (lat, long) => {
    return {
        type: types.GET_CITY_REQUEST,
        lat,
        long
    }
}

export const getCitySuccess = (data) => {
    return {
        type: types.GET_CITY_SUCCESS,
        data: data,
    }
}

export const getCityFailure = (data) => {
    return {
        type: types.GET_CITY_FAILURE,
        data: data,
    }
}