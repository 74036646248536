import RequestService from "../../RequestService";
import { api } from "../../../helpers/Network/networkConstant";

class NetworkService {
  connectionBetweenUs(action) {
    const endPoint = `${api.connectionBetweenUs}`;
    return RequestService.postRequest(endPoint, {
      organizationType: action.data.values.organizationType,
      name: action.data.values.Name,
      email: action.data.values.Email,
      city: action.data.values.City,
      number: action.data.values.ContactNumber.toString(),
      availability: action.data.avail,
    });
  }

  viewAllHospital(action) {
    const endPoint = `${api.viewAllHospital}?pageNo=${action.page}&pageSize=10&sortBy=ID&sortOrder=ASC`;
    return RequestService.postRequest(endPoint, {
      cityList: [""],
      search: action.search,
    });
  }

  hospitalNetworkForm(action) {
    const endPoint = `${api.hospitalNetworkForm}`;
    return RequestService.postRequest(
      endPoint,
      action.data
    );
  }

  clinicNetworkForm(action) {
    const endPoint = `${api.clinicNetworkForm}`;
    return RequestService.postRequest(
      endPoint,
      action.data
    );
  }

  diagnosticNetworkForm(action) {
    const endPoint = `${api.diagnosticNetworkForm}`;
    return RequestService.postRequest(endPoint, action.data);
  }

  professionalServicesNetworkForm(action) {
    const endPoint = `${api.professionalServicesNetworkForm}`;
    return RequestService.postRequest(
      endPoint,
      action.data
    );
  }
  viewAllNetworkType() {
    const endPoint = `${api.viewAllNetworkType}`;
    return RequestService.getRequest(endPoint);
  }

  viewAllNetwork(action) {

    const endPoint = `${api.viewAllNetwork}?network=${action.data}&pageNo=${action.page}&pageSize=12&sortBy=ID&sortOrder=ASC`;

    return RequestService.postRequest(endPoint, {
      cityList: [action.city],
      search: action.search,
      networkStatusList: [],
      planUuidList: !action.selectType ? [] : [action.selectType]
    });
  }
  viewNetworkCount() {
    const endPoint = `${api.viewNetworkCount}`;
    return RequestService.getRequest(endPoint);
  }
}

export default new NetworkService();
