import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Packages/PackageLabTestActionType";
import * as actions from "../../Action/Packages/DiagnosticTestAction";
import NetworkService from "../../../network/Network/Packages/PackageLabTestNetwork";

export function* diagnosticTestSaga(action) {
  try {
    let response = yield call(NetworkService.diagnosticSelectTest, action);
    yield put(actions.diagnosticTestSuccess(response));
  } catch (err) {
    yield put(actions.diagnosticTestFailure(err));
  }
}

export function* watchdiagnosticTestSaga() {
  yield takeLatest(types.DIAGNOSTIC_TEST_SELECT_REQUEST, diagnosticTestSaga);
}
