import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import networkIcon1 from "../../../assets/Network/mblIcon1Network.png";
import networkIcon2 from "../../../assets/Network/mblIcon1Network2.png";
import magnifying from "../../../assets/Rental/Vector.jpg";
import Title from "../../Atoms/Title/Title";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import "../Network/Network.css";
import { viewAllYuvaClinicRequest } from "../../../redux/Action/YuvaClinic/viewAllYuvaClinicAction";

export default function YuvaClinics() {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [city, setCity] = useState([]);

  

 
  const viewCityNames = useSelector((state) => state.city.viewAllCity);
  const viewAllYuvaClinic = useSelector((state) => state.YuvaClinic.viewAllYuvaClinic);


 

  const handleChangeCity = (event) => {
    setCity([parseInt(event.target.value)]);      
  };

  useEffect(()=>{
      dispatch(viewAllYuvaClinicRequest({city,page,search}));

  },[city,page])





  const handleChange =  (event, value) => {
    setPage(value);
    setTimeout(()=>{
    window.scrollTo({ top: 0, behavior: "smooth" });

    },100)
  };

  const handleSearch = (e) => {
    if(search){
      dispatch(viewAllYuvaClinicRequest({city,page,search}));
    }
  };
  const handleMapLocaion = (event) => {
    const mapUrl = event.target.attributes.url.value;
    const isValidUrl = /^https?:\/\/[\w-]+(\.[\w-]+)+[/#?]?.*$/.test(mapUrl);

    if (isValidUrl) {
      window.open(mapUrl);
    } else {
      window.open("/linkExpired");
    }
  };

  

  return (
    <div>
      <Title title="Yuva | Network" />
      <ScrollToTop />
      <div>
        <div className="networkBody1">
          <div className="networkMainHeading">
            Search for your nearest{" "}
            <span style={{ color: "#2D354E" }}>Network provider</span> to plan
            and avail a cashless facility
          </div>

          <div className="head">
            <div className="networkLeftHead">
              <div className="networkLeftHeadDropdown">
               

             
                <div className="networkBlock">
                  <select
                    className= "autocompleteNetwork_selects"
                    onChange={handleChangeCity}
                    as="Select"
                    value={city}
                    label="Select Type"
                  >
                    <option value={null} disabled selected>
                      Select City
                    </option>
                    <option value="">All Cities</option>
                    {viewCityNames?.data?.data?.data?.data.map(
                      (item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>

              <div
                className="networkSearch"
                
              >
                <img alt="Magnifying Glass" src={magnifying} />
                <input
                  type="search"
                  placeholder="Search"
                  onBlur={(e) => setSearch(e.target.value)}
                />
                <div>
                  <button className="networkSearchBtn" 
                  onClick={handleSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bodyBackground">
          <div className="networkCardbody">
            {viewAllYuvaClinic.data?.data.data.data.yuvaClinicDtoList.length === 0 ? (
              <p>No record found</p>
            ) : (
              viewAllYuvaClinic.data?.data.data.data.yuvaClinicDtoList.map((item) => (
                <div className="networkCard">
                  <div className="networkCardText">
                    <div className="textLeftHead">
                      {item.clinicName !== null && (
                        <div className="title">{item.clinicName}</div>
                      )}

                    

                      <div className="address">{item.address}</div>
                      <div
                        style={{
                          marginTop: "2vw",
                        }}
                      >
                        <div className="address1">
                          <img alt="Phone" src={networkIcon1} />
                         +91 8130584255
                        </div>
                        <div className="address1">
                          <img alt="Location" src={networkIcon2} />
                          <div
                            className="MapurlCSS"
                            url={item.mapUrl}
                            onClick={handleMapLocaion}
                          >
                            {" "}
                            View Map
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>

          <div className="networkPagination">
            <Pagination
              count={viewAllYuvaClinic.data?.data.data.data.totalPages}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
