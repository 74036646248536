import React from "react";

function MedicalConditionPage() {
  return (
      <div className="MostSearchedBody">
        <div className="MedicalConditionPageContainer">
          Most searched medical conditions
        </div>
        <div className="MedicalConditionPageContainerGrid">
          <div className="grid-MedicalConditionPageContainer">
            <div className="grid-item_grid-MedicalConditionPageContainer">
              Diet & Lifestye
            </div>
            <div className="grid-item_grid-MedicalConditionPageContainer">
              Dizziness
            </div>
            <div className="grid-item_grid-MedicalConditionPageContainer">GERD</div>
            <div className="grid-item_grid-MedicalConditionPageContainer">
              Covid-19
            </div>
            <div className="grid-item_grid-MedicalConditionPageContainer">
              Cough
            </div>
            <div className="grid-item_grid-MedicalConditionPageContainer">PCOS</div>
            <div className="grid-item_grid-MedicalConditionPageContainer">
              Viral Infection
            </div>
            <div className="grid-item_grid-MedicalConditionPageContainer">
              Beta HCG
            </div>
            <div className="grid-item_grid-MedicalConditionPageContainer">UTI</div>

            <div className="grid-item_grid-MedicalConditionPageContainer">
              Sex Intercourse
            </div>
            <div className="grid-item_grid-MedicalConditionPageContainer">
              Blood Pressure
            </div>
            <div className="grid-item_grid-MedicalConditionPageContainer">
              Sore Throat
            </div>
            <div className="grid-item_grid-MedicalConditionPageContainer">
              Viral Infection
            </div>
            <div className="grid-item_grid-MedicalConditionPageContainer">HIV</div>
            <div className="grid-item_grid-MedicalConditionPageContainer">
              Paracetamol
            </div>
            <div className="grid-item_grid-MedicalConditionPageContainer">
              Pantoprazole
            </div>
            <div className="grid-item_grid-MedicalConditionPageContainer">
              Acid Reflux
            </div>
            <div className="grid-item_grid-MedicalConditionPageContainer">
              Physiotherapy
            </div>
          </div>
        </div>
      </div>
  );
}

export default MedicalConditionPage;
