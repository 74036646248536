import * as types from "../../Config/EcomUser/EcomUserActionType"

export const viewProductByCategoryIdRequest = (id) => {
    return {
        type: types.VIEW_PRODUCT_BY_CATEGORY_ID_REQUEST,
        id
    }
}

export const  viewProductByCategoryIdSuccess = (data) => {
    return {
        type: types.VIEW_PRODUCT_BY_CATEGORY_ID_SUCCESS,
        data:data,
    }
}

export const  viewProductByCategoryIdFailure = (data) => {
    return {
        type: types.VIEW_PRODUCT_BY_CATEGORY_ID_FAILURE,
        data:data,
    }
}