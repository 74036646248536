import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const GetSection1AnswersRequest = (data) => {
    return {
        type: types.GET_SECTION_1_ANSWERS_REQUEST,
        data:data
    }
}

export const  GetSection1AnswersSuccess = (data) => {
    return {
        type: types.GET_SECTION_1_ANSWERS_SUCCESS,
        data:data,
    }
}

export const  GetSection1AnswersFailure = (data) => {
    return {
        type: types.GET_SECTION_1_ANSWERS_FAILURE,
        data:data,
    }
}