import React, { useState } from "react";
import "../SingleLogin.css";
import signupImage from "../../../../assets/Register/signupImage.png";
import Logo from "../../../../assets/common/logoPrimaryFooter.png";
import logo from "../../../../assets/common/logoPrimaryNavbar.png";

import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import eye from "../../../../assets/Login/eye.png";
import { useLocation, useNavigate } from "react-router-dom";
import { userResetPasswordRequest } from "../../../../redux/Action/User/resetPasswordAction";
import Swal from "sweetalert2";
import Title from "../../../Atoms/Title/Title";

function ResetPasswordMobile() {
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Must be minimum 6 digits.")
      .required("Please enter your password."),

    retypePassword: Yup.string()
      .oneOf([Yup.ref("password")], "Your passwords do not match.")
      .required("Please retype your password."),
  });

  const dispatch = useDispatch();
  const responseResetPassword = useSelector(
    (state) => state.user.resetPassword
  );
  function handlePassword() {
    setShowPassword(!showPassword);
  }
  function handleConfirmPassword() {
    setShowConfirmPassword(!showConfirmPassword);
  }
  const handleSubmit = (values) => {
    setIsSubmitting(true);
    const finalValue = {
      emailOrNumber: location.state.id,
      hash: location.state.hash,
      values: values.password,
    };

    dispatch(userResetPasswordRequest(finalValue));
  };

  if (responseResetPassword?.data?.data?.status === 200) {
    Swal.fire({
      title: "Your password has been reset Successfully",
      icon: "success",
    }).then(() => {
      setIsSubmitting(true);
      navigate("/login");
    });
  } else if (responseResetPassword?.error?.data?.response?.status === 401) {
    return navigate("/linkExpired");
  } else if (responseResetPassword?.error?.data?.response?.status === 502) {
    return navigate("/linkExpired");
  } else if (responseResetPassword?.error?.data?.response?.status === 404) {
    return navigate("/linkExpired");
  } else if (responseResetPassword?.error?.data?.message === "Network Error") {
    return navigate("/linkExpired");
  } else if (responseResetPassword?.error?.data?.response?.status === 404) {
    return navigate("/linkExpired");
  }

  return (
    <div className="signupMain">
      <Title title={"Yuva | Reset Password"} />
      <div className="signupMainLeft">
        <img src={signupImage} alt="SignUp" className="signupLeftBackground" />
        <img src={Logo} alt="Logo" className="LogoSignUp" />
      </div>
      <div className="signupMainRight">
        <div className="signupArea">
          <div className="LogoAreaLOGIN_SIGNUP">
            <img src={logo} alt="Logo" />
          </div>
          <p className="signupAreaFirstHeading">Reset Password</p>
          <p className="signupAreaSecondHeading">Get your new password</p>
          <div className="signupForm">
            <Formik
              initialValues={{
                password: "",
                retypePassword: "",
              }}
              validationSchema={resetPasswordSchema}
              onSubmit={handleSubmit}
            >
              {() => {
                return (
                  <Form className="SingleRegisterForm">
                    <div className="signUpBox">
                      <div style={{ position: "relative" }}>
                        {showPassword ? (
                          <Field
                            type="text"
                            name="password"
                            placeholder="Password"
                            className="signupInputField"
                          />
                        ) : (
                          <Field
                            type="password"
                            name="password"
                            placeholder="Password"
                            className="signupInputField"
                          />
                        )}

                        <ErrorMessage
                          className="errorMessageResetPassword"
                          name="password"
                          component="div"
                        />
                        <img
                          src={eye}
                          onClick={handlePassword}
                          style={{
                            position: "absolute",
                            right: "10%",
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                          alt="Eye"
                        />
                      </div>
                    </div>
                    <div className="signUpBox">
                      <div style={{ position: "relative" }}>
                        {showConfirmPassword ? (
                          <Field
                            type="text"
                            name="retypePassword"
                            placeholder="Confirm Password"
                            className="signupInputField"
                          />
                        ) : (
                          <Field
                            type="Password"
                            name="retypePassword"
                            placeholder="Confirm Password"
                            className="signupInputField"
                          />
                        )}

                        <ErrorMessage
                          className="errorMessageResetPassword"
                          name="retypePassword"
                          component="div"
                        />
                        <img
                          src={eye}
                          onClick={handleConfirmPassword}
                          style={{
                            position: "absolute",
                            right: "10%",
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                          alt="Eye"
                        />
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="signupInputFieldButton"
                      disabled={isSubmitting}
                    >
                      Reset Password
                    </button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordMobile;
