import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Plan/PlanActionType";
import * as actions from "../../Action/Plan/planLockAction";
import NetworkService from "../../../network/Network/Plan/GetPLanDetailNetwork";

export function* planLockSaga(action) {
  try {
    let response = yield call(NetworkService.planLock, action);
    yield put(actions.planLockSuccess(response));
  } catch (err) {
    yield put(actions.planLockFailure(err));
  }
}

export function* watchPlanLockSaga() {
  yield takeLatest(types.PLAN_LOCK_REQUEST, planLockSaga);
}
