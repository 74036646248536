import { all } from "redux-saga/effects";
import { watchCheckEmailSaga } from "./CheckEmail";
import { watchCheckNumberSaga } from "./CheckNumber";

function* rootCheckEmailSaga() {
    yield all([
        watchCheckEmailSaga(),
        watchCheckNumberSaga(),
    ])
}

export default rootCheckEmailSaga;