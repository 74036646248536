import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Advertisement/GetCallRequestActionType";
import * as actions from "../../Action/Advertisement/GetCallRequestAction";
import NetworkService from "../../../network/Network/Advertisement/AdvertisementNetwork";

export function* getCallSaga(action) {

  try {
    let response = yield call(NetworkService.getCall, action);
    yield put(actions.getCallSuccess(response));
  } catch (err) {
    yield put(actions.getCallFailure(err));
  }
}

export function* watchGetCallSaga() {
  yield takeLatest(types.GET_CALL_REQUEST, getCallSaga);
}
