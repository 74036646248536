import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Account/ApplyForKYCActionType";
import * as actions from "../../Action/Account/ApplyForKYCAction";
import NetworkService from "../../../network/Network/AccountsSetting/AccountsNetwork";

export function* ApplyForKYCSaga(action) {

  try {
    let response = yield call(NetworkService.ApplyForKYC, action);
    yield put(actions.ApplyForKYCSuccess(response));
  } catch (err) {
    yield put(actions.ApplyForKYCFailure(err));
  }
}

export function* watchApplyForKYCSaga() {
  yield takeLatest(types.APPLY_FOR_KYC_REQUEST, ApplyForKYCSaga);
}
