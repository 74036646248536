import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Plan/PlanActionType";
import * as actions from "../../Action/Plan/GetSelectedPlan";
import NetworkService from "../../../network/Network/Plan/GetPLanDetailNetwork";

export function* getSelectedPlanSaga(action) {
  try {
    let response = yield call(NetworkService.getSelectedPlan, action);
    yield put(actions.getSelectedPlanSuccess(response));
  } catch (err) {
    yield put(actions.getSelectedPlanFailure(err));
  }
}

export function* watchgetSelectedPlanSaga() {
  yield takeLatest(types.GET_SELECTED_PLAN_REQUEST, getSelectedPlanSaga);
}
