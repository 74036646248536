import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Plan/PlanActionType";
import * as actions from "../../Action/Plan/RequestCall";
import NetworkService from "../../../network/Network/Plan/GetPLanDetailNetwork";

export function* getPlanRequestCallSaga(action) {
  try {
    let response = yield call(NetworkService.getPlanRequestCallPost, action);
    yield put(actions.getPlanRequestCallSuccess(response));
  } catch (err) {
    yield put(actions.getPlanRequestCallFailure(err));
  }
}

export function* watchgetPlanRequestCallSaga() {
  yield takeLatest(types.PLAN_REQUEST_CALL_REQUEST, getPlanRequestCallSaga);
}
