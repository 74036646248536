import * as types from "../../../Config/OTP/OTP/otpActionType"

export const otpRequest = (data) => {
    return {
        type: types.OTP_REQUEST,
        data:data
    }
}

export const otpRequestSuccess = (data) => {
    return {
        type: types.OTP_SUCCESS,
        data:data,
    }
}

export const otpRequestFailure = (data) => {
    return {
        type: types.OTP_FAILURE,
        data:data,
    }
}

export const otpRequestBackToNormal = (data) => {
    return {
        type: types.OTP_BACK_TO_NORMAL,
        data:data
        
    }
}