import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/EcomUser/EcomUserActionType";
import * as actions from "../../Action/EcomUser/viewHomeScreenProductAction";
import NetworkService from "../../../network/Network/EcomUser/EcomUserNetwork";

export function* viewHomeScreenProductSaga(action) {
  try {
    let response = yield call(NetworkService.viewHomeScreenProduct);
    yield put(actions.viewHomeScreenProductSuccess(response));
  } catch (err) {
    yield put(actions.viewHomeScreenProductFailure(err));
  }
}

export function* watchViewHomeScreenProductSaga() {
  yield takeLatest(types.VIEW_HOME_SCREEN_PRODUCT_REQUEST, viewHomeScreenProductSaga);
}