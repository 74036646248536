import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Account/InAccountActionType";
import * as actions from "../../Action/Account/MyPrescriptionAction";
import NetworkService from "../../../network/Network/MyAccount/MyAccountNetwork";

export function* myPrescriptionSaga(action) {

  try {
    let response = yield call(NetworkService.myPrescription, action);
    yield put(actions.myPrescriptionSuccess(response));
  } catch (err) {
    yield put(actions.myPrescriptionFailure(err));
  }
}

export function* watchMyPrescriptionSaga() {
  yield takeLatest(types.MY_PRESCRIPTION_REQUEST, myPrescriptionSaga);
}