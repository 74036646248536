import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/ProfileLock/ProfileLock";
import * as actions from "../../Action/ProfileLock/ProfileLock";
import NetworkService from "../../../network/Network/ProfileLock/ProfileLock";

export function* profileLockSaga(action) {
  try {
    let response = yield call(NetworkService.ProfileLock, action);
    yield put(actions.profileLockSuccess(response));
  } catch (err) {
    yield put(actions.profileLockFailure(err));
  }
}

export function* watchProfileLockSaga() {
  yield takeLatest(types.PROFILE_LOCK_REQUEST, profileLockSaga);
}
