import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Order/OrderActionType";
import * as actions from "../../Action/Order/GetPlanSubscriptionAction";
import NetworkService from "../../../network/Network/Order/GetOrderNetwork";

export function* getPlanSubscriptionSaga(action) {
  try {
    let response = yield call(NetworkService.getPlanSubscription, action);
    yield put(actions.getPlanSubscriptionSuccess(response));
  } catch (err) {
    yield put(actions.getPlanSubscriptionFailure(err));
  }
}

export function* watchGetPlanSubscriptionSaga() {
  yield takeLatest(types.GET_PLAN_SUBSCRIPTION_REQUEST, getPlanSubscriptionSaga);
}
