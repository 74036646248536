import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { getCallRequest } from "../../../redux/Action/Advertisement/GetCallRequestAction";
import "../ServicesOffered/BookAppointment.css";
import { Input } from 'antd';
import { GetDocumentTypeRequest } from '../../../redux/Action/Account/GetDocumentTypeAction ';
import { ApplyForKYCRequest } from '../../../redux/Action/Account/ApplyForKYCAction';
import { CircularProgress } from '@mui/material';

function KYCForm({ closeKYCForm, nav, userId }) {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);

  const [isClick, setIsClick] = useState(false);


  const getVerifyFormValidation = Yup.object().shape({
    uniqueNumber: Yup.string()
      .required("This is a Required Field")
      .when("documentType", {
        is: "AADHAR_CARD",
        then: Yup.string()
        .length(12, "Aadhar Card must be 12 digits")
        .matches(/^[0-9]+$/, "Aadhar Card must contain only digits"),
      })
      .when("documentType", {
        is: "DRIVING_LICENSE",
        then: Yup.string(),
      })
      .when("documentType", {
        is: "PAN_CARD",
        then: Yup.string().length(10, "PAN Card must be 10 characters"),
      }),
    documentType: Yup.string().required("This is a Required Field"),
    documentFile: Yup.string().required("This is a Required Field"),
  });

  const handleSubmit = async (values) => {
    var kycRequestDto = {
      documentType: values.documentType,
      uniqueId: values.uniqueNumber,
    };

    const newFormData = new FormData();


    if (values.documentFile) {
      newFormData.append("file", values.documentFile);
      try {
        await dispatch(ApplyForKYCRequest({ newFormData, kycRequestDto, userId }));
        setIsClick(true);
      } catch (error) {

      }
    }

  };


  const GetDocumentType = useSelector((state) => state.account.GetDocumentType);

  const ApplyForKYC = useSelector((state) => state.account.ApplyForKYCReducer)


  const initialValues = useMemo(() => ({
    documentType: "",
    uniqueNumber: "",
    documentFile: "",
  }), []);


  useEffect(() => {
    dispatch(GetDocumentTypeRequest());
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: getVerifyFormValidation,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });





  useEffect(() => {
    if (
      ApplyForKYC?.data?.data.status === 201 &&
      ApplyForKYC?.data?.data.data.message !== ""
    ) {
      setShowLoader(false);
      Swal.fire({
        text: ApplyForKYC.data.data.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    }
    if (
      ApplyForKYC?.error?.data?.response.status === 409 &&
      ApplyForKYC?.error?.data.response.data.errorMessage !== ""
    ) {
      setShowLoader(false);
      Swal.fire({
        text: ApplyForKYC?.error?.data.response.data.errorMessage,
        icon: "info",
      }).then(() => {
        window.location.reload();
      });
    }
  }, [ApplyForKYC]);


  function handleKYCFormModal() {
    closeKYCForm(false);
  }

  const [fileTypeError, setFileTypeError] = useState(false);


  const handleFileChange = (event) => {
    const fileType = event.target.files[0].type;

    if (
      fileType === "application/pdf" ||
      fileType === "image/jpeg" ||
      fileType === "image/png"
    ) {
      formik.setFieldValue("documentFile", event.target.files[0]);
      setFileTypeError(false);
    } else {
      setFileTypeError(true);
    }
  };


  useEffect(() => {
    if (ApplyForKYC.isLoading) {
      setShowLoader(true);
    }
  }, [ApplyForKYC]);



  return (
    <div className={nav === true ? "bookAppointmentFormMain" : "bookAppointmentFormMain2"}>
      <div className="bookAppointmentFormMainUpper">
        <p className="bookAppointmentFormMainHeader">Verify your Profile</p>
        <CloseIcon className="bookAppointmentCloseIcon" onClick={handleKYCFormModal} />
      </div>
      <div className="bookAppointmentForm">
        <form onSubmit={formik.handleSubmit}>
          <div className="bookAppointmentFormLabels">
            <label className="">Document Type</label>
            <select
              name="documentType"
              className="bookAppointmentFormLabelsInput"
              placeholder="Select Your Document"
              {...formik.getFieldProps("documentType")}
            >
              <option value="" disabled>
                Select Your Document
              </option>
              {GetDocumentType?.data?.data?.data.data.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
            {formik.touched.documentType && formik.errors.documentType && (
              <div style={{ color: "red" }}>{formik.errors.documentType}</div>
            )}
          </div>
          <div className="bookAppointmentFormLabels">
            <label className="">Document Unique ID</label>
            <input
              type="text"
              name="uniqueNumber"
              className="bookAppointmentFormLabelsInput"
              placeholder="Type Your Unique Number Here"
              {...formik.getFieldProps("uniqueNumber")}
            />
            {formik.touched.uniqueNumber && formik.errors.uniqueNumber && (
              <div style={{ color: "red" }}>{formik.errors.uniqueNumber}</div>
            )}
          </div>
          <div className="bookAppointmentFormLabels">
            <label className="">Document Upload (image/pdf) </label>
            <Input
              type="file"
              name="documentFile"
              id="fileUploadInput"
              className="bookAppointmentFormLabelsInput"
              onChange={(event) => handleFileChange(event)}
            />
            {formik.touched.documentFile && formik.errors.documentFile && (
              <div style={{ color: "red" }}>{formik.errors.documentFile}</div>
            )}
            {fileTypeError && (
              <div style={{ color: "red" }}>
                Please Select only PDF,PNG or JPEG file{" "}
              </div>
            )}
          </div>


          <p className="bookAppointmentFormTerms">

          </p>

          <div className="bookAppointmentFormButton" type="submit">
            <button type="submit" disabled={isClick} style={{ width: "60%" }}>
              {showLoader === true ? (
                <CircularProgress color="warning" style={{ color: "#fff", width: "25%", height: "80%" }} />
              ) : "Apply for verification"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default KYCForm;
