import { ErrorMessage, Field, Form, Formik } from "formik";
import { default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import * as Yup from "yup";
import Email from "../../../assets/Login/emailLogoLogin.png";
import logo from "../../../assets/common/logoPrimaryNavbar.png";
import { CheckEmailRequest } from "../../../redux/Action/CheckEmail/checkEmail";
import { userLoginRequest } from "../../../redux/Action/User/loginAction";

function CorporateSignUpScreen({ token, corporateId, setEmpEmail, isLogin }) {
  const dispatch = useDispatch();

  const loginSchema = Yup.object().shape(
    isLogin
      ? {
          email: Yup.string().required("Required Field"),
          password: Yup.string().required("Required Field"),
        }
      : {
          email: Yup.string().required("Required Field"),
        }
  );

  const handleSubmit = (values) => {
    if (isLogin) {
      dispatch(userLoginRequest(values, token, corporateId));
    } else {
      setEmpEmail(values.email);
      var checkEmail = false;
      const email = values.email.split("@")[1];
      corporateTokenInfo.data?.data.data.data.domainList.forEach((item) => {
        if (email === item) {
          checkEmail = true;
        }
      });
      if (checkEmail) {
        dispatch(CheckEmailRequest(values.email, corporateId, token));
      } else {
        swal({
          title: "Please enter valid domain",
          icon: "error",
        });
      }
    }
  };

  const corporateTokenInfo = useSelector(
    (state) => state.corporateSignUp.corporateTokenInfo
  );

  return (
    <>
      <div className="signupArea">
        <div className="LogoAreaLOGIN_SIGNUP">
          <img src={logo} alt="Logo" />
        </div>
        <p className="signupAreaFirstHeading">Create Account</p>
        <p className="signupAreaSecondHeading">
          Create an account so you can explore
          <br /> all the existing jobs
        </p>
        <div className="signupForm">
          <Formik
            initialValues={{
              companyCode:
                corporateTokenInfo.data?.data.data.data.corporateName,
              email: "",
            }}
            validationSchema={loginSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {() => {
              return (
                <Form className="SingleRegisterForm">
                  <div className="signUpBox">
                    <Field
                      type="text"
                      name="companyCode"
                      className="signupInputField"
                      disabled={true}
                      placeholder="Company Code"
                    />
                  </div>
                  <div className="signUpBox">
                    <Field
                      type="text"
                      name="email"
                      placeholder="Email"
                      className="signupInputField"
                    />
                    <ErrorMessage
                      className="errorMessage"
                      name="email"
                      component="div"
                    />
                  </div>
                  <div className="signUpBox">
                    {isLogin && (
                      <>
                        <label>
                          <Field
                            type="password"
                            name="password"
                            placeholder="Password"
                            className="signupInputField"
                          />
                          <ErrorMessage
                            className="errorMessage"
                            name="password"
                            component="div"
                          />
                        </label>
                      </>
                    )}
                  </div>

                  <button type="submit" className="signupInputFieldButton">
                    <img alt="Email" src={Email} />
                    &nbsp; Sign In
                  </button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CorporateSignUpScreen;
