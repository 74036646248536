import { all } from "redux-saga/effects";
import { watchAddRelationSaga } from "./AddRelationSaga";
import { watchCheckHashIdSaga } from "./checkHashIdSaga";
import { watchForgetPasswordSaga } from "./forgetPasswordSaga";
import { watchGetAllRelativesSaga } from "./GetAllRelativesSaga";
import { watchGetProfileSaga } from "./GetProfileSaga";
import { watchLoginGoogleSaga } from "./loginGoogleSaga";
import { watchLoginSaga } from "./loginSaga";
import { watchOtpMobileVerificationSaga } from "./otpMobileVerificationSaga";
import { watchRegisterSaga } from "./registerSaga";
import { watchResetPasswordSaga } from "./resetPasswordSaga";
import { watchUpdateProfileSaga } from "./UpdateProfileSaga";
import { watchUploadFamilyPictureSaga } from "./UploadFamilyPicSaga";
import { watchUploadProfilePictureSaga } from "./UploadProfilePictureSaga";
import { watchLogoutUserSaga} from "./logoutUserSaga";

function* rootUserSaga() {
    yield all([
        watchAddRelationSaga(),
        watchCheckHashIdSaga(),
        watchForgetPasswordSaga(),
        watchGetAllRelativesSaga(),
        watchGetProfileSaga(),
        watchLoginGoogleSaga(),
        watchLoginSaga(),
        watchOtpMobileVerificationSaga(),
        watchRegisterSaga(),
        watchResetPasswordSaga(),
        watchUpdateProfileSaga(),
        watchUploadFamilyPictureSaga(),
        watchUploadProfilePictureSaga(),
        watchGetAllRelativesSaga(),
        watchLogoutUserSaga(),
    ])
}

export default rootUserSaga;