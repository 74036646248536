import { combineReducers } from "redux";
import { diagnosticTestReducer } from "./diagnosticTestReducer";
import { lifestylePackageReducer } from "./lifestylePackageReducer";
import { lifestylePackageViewAllReducer } from "./lifeStylePackageViewAllReducer";
import { packageGetTestReducer } from "./packageGetTestReducer";
import { packageLabTestReducer } from "./packageLabTestReducer";
import { packageSelectReducer } from "./packageSelectReducer";
import { getTestPackageListingReducer } from "./getTestPackageListingReducer";

export default combineReducers({
  diagnosticTest: diagnosticTestReducer,
  lifestylePackage: lifestylePackageReducer,
  lifestylePackageViewAll: lifestylePackageViewAllReducer,
  packageGetTest: packageGetTestReducer,
  packageLabTest: packageLabTestReducer,
  packageSelect: packageSelectReducer,
  getTestPackageListing : getTestPackageListingReducer,
})