import React from "react";
import Postadd from "../../../assets/MyAccount/Postadd.png";
import "./NoPrescription.css";

const NoHraReports = () => {
  return (
    <div>
      <div className="myprescriptionmaindiv">
        <div className="myprescriptiontext">MY HRA REPORTS</div>
        <div className="MyAddressIconsMainBox">
          <div className="MyAddressIconsMainBox2">
            <div className="ExtraClass">
            <img className="MyAddressIconPrescription" src={Postadd} alt="Post add"></img>
            </div>
            <div className="addressTextPrescription">
              Looks like you Don't have any reports.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoHraReports;