import React from "react";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import ViewDetailsPlanServices from "./ViewDetailsPlanServices";
import PageNotFound from "../../Pages/PageNotFound/PageNotFound";
import "./PlanPage.css";
import Title from "../../Atoms/Title/Title";
import {  useSelector } from "react-redux";


function PlanPage() {
  let apiCheck = "";
  const getSelectedPlan= useSelector((state)=>state.plan.getSelectedPlan);

  if (getSelectedPlan?.error !== null) {
    apiCheck = getSelectedPlan.error.data.response?.data?.errorMessage;
  }

  return (
    <>
      <Title title="YUVA | Plan Details" />
      <ScrollToTop />
      {apiCheck === "" ? (
        <div>
          <ViewDetailsPlanServices />
        </div>
      ) : (
        <div>
          <PageNotFound />
        </div>
      )}
    </>
  );
}

export default PlanPage;
