import RequestService from "../../RequestService";
import { api } from "../../../helpers/Account/MyAccountConstant";

class NetworkService {
  ProfileLock(action) {

    const endPoint = `${api.ProfileLock}`;
    return RequestService.putRequest(endPoint);
  }
}
export default new NetworkService();
