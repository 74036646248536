import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { Input } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { clinicNetworkFormRequest } from "../../../redux/Action/Network/clinicNetworkFormAction";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import Swal from "sweetalert2";
import "./Form.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ClinicForm() {
  const [fileRateList, setFileRateList] = useState();
  const [fileClinicPhoto, setFileClinicPhoto] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [check, setCheck] = useState(false);

  const dispatch = useDispatch();
  const clinicNetworkForm = useSelector(
    (state) => state.network.clinicNetworkForm
  );

  const loginSchema = Yup.object().shape({
    Email: Yup.string()
      .matches(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
        "Enter a E-mail Address"
      )
      .required("Email Valid E-mail Address")
      .required("Email is required"),
    Name: Yup.string().trim().required("Required Field"),
    ClinicType: Yup.string().required("Required Field"),
    OwnerName: Yup.string().trim().required("Required Field"),
    Address: Yup.string().trim().required("Required Field"),
    Location: Yup.string().trim().required("Required Field"),
    City: Yup.string().required("Required Field"),
    Pincode: Yup.string()
      .matches(/^[0-9\b]+$/, "Enter valid pincode")
      .min(6, "Must be exactly 6 digits")
      .max(6, "Must be exactly 6 digits")
      .required("Required Field"),
    ContactNumber: Yup.string()
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits")
      .required("Required Field"),
    RegistrationNumber: Yup.string().required("Required Field"),
    ConsultationPrice: Yup.string().required("Required Field"),
  });

  const Servicesdata = [
    { name: "Consultations", id: 1 },
    { name: "Diagnostics", id: 2 },

    { name: "Day Care", id: 3 },

    { name: "In patient", id: 4 },
  ];

  useEffect(() => {
    if (clinicNetworkForm?.error?.data?.response?.status === 400) {
      Swal.fire({
        title: clinicNetworkForm.error?.data.response?.data.errorMessage,
        icon: "error",
      }).then(() => {
        setIsSubmitting(false);
      });
    }
  }, [clinicNetworkForm?.error]);

  const viewCity = useSelector((state) => state.city.viewAllCity);
  const [service, setService] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setService(typeof value === "string" ? value.split(",") : value);
  };
  function preventnumberinput(e) {
    var keycode = e.keycode ? e.keycode : e.which;
    if ((keycode > 47 && keycode < 58) || (keycode > 95 && keycode < 107)) {
      e.preventDefault();
    }
  }
  const handleSubmit = async (values, { setSubmitting }) => {
    setCheck(true);
    setIsSubmitting(true);
    let serviceUuidArray = [];
    service.forEach((x) => {
      Servicesdata.forEach((item) => {
        if (item.name === x) {
          serviceUuidArray.push(item.name);
        }
      });
    });

    const clinicpartnerData = {
      address: values.Address,
      cityId: values.City,
      clinicName: values.Name,
      clinicType: values.ClinicType,
      consultationFees: values.ConsultationPrice,
      email: values.Email,
      location: values.Location,
      number: values.ContactNumber,
      panNumber: values.PanCardNumber,
      pinCode: values.Pincode,
      registrationNumber: values.RegistrationNumber,
      services: serviceUuidArray.toString(),
      ownerName: values.OwnerName,
    };

    const newFormData = new FormData();
    newFormData.append(
      "clinicPartnerRequestDto",
      JSON.stringify(clinicpartnerData)
    );
    if (fileClinicPhoto && fileRateList) {
      newFormData.append("clinicPhoto", fileClinicPhoto);
      newFormData.append("uploadRateList", fileRateList);
      try {
        await dispatch(clinicNetworkFormRequest(newFormData));
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  const ClinicResponse = useSelector(
    (state) => state.network.clinicNetworkForm
  );

  useEffect(() => {
    if (ClinicResponse.data?.data?.status === 200) {
      Swal.fire({
        title: ClinicResponse?.data?.data?.data.message,
        icon: "success",
      }).then(() => {
        setIsSubmitting(false);
        window.location.reload();
      });
    }
  }, [ClinicResponse.data]);

  function waitForLoad(id, callback) {
    var timer = setInterval(function () {
      if (
        document.getElementById("fileUploadInput") &&
        document.getElementsByClassName("file-upload-select")[0]
      ) {
        clearInterval(timer);
        callback();
      }
    }, 200);
  }
  waitForLoad("subm", function () {
    let fileInput = document.getElementById("fileUploadInput");
    let fileInput2 = document.getElementById("fileUploadInput1");
    fileInput.onchange = function () {
      let filename = fileInput.files[0].name;
      let selectName = document.getElementsByClassName("file-select-name")[0];
      selectName.innerText = filename;
    };
    fileInput2.onchange = function () {
      let filename = fileInput2.files[0].name;
      let selectName = document.getElementsByClassName("file-select-name2")[0];
      selectName.innerText = filename;
    };
  });

  const [fileClinicPhotoError, setFileClinicPhotoError] = useState(false);
  const [fileRateListError, setFileRateListError] = useState(false);

  const handleFileRateListChange = (event) => {
    if (
      event.target.files[0].type === "application/pdf" ||
      event.target.files[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      event.target.files[0].type === "application/vnd.ms-excel"
    ) {
      setFileRateList(event.target.files[0]);
      setFileRateListError(false);
    } else {
      setFileRateListError(true);
    }
  };

  const handleFileClinicPhotoChange = (event) => {
    if (
      event.target.files[0].type === "image/png" ||
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "image/jpg"
    ) {
      setFileClinicPhoto(event.target.files[0]);
      setFileClinicPhotoError(false);
    } else {
      setFileClinicPhotoError(true);
    }
  };
  if (ClinicResponse.isLoading) {
    return <YuvaLoader />;
  }

  return (
    <div>
      <Formik
        initialValues={{
          Email: "",
          Name: "",
          ClinicType: "",
          OwnerName: "",
          Address: "",
          Location: "",
          City: "",
          Pincode: "",
          ContactNumber: "",
          RegistrationNumber: "",
          PanCardNumber: "",
          Services: "",
          ConsultationPrice: "",
        }}
        enableReinitialize={true}
        validationSchema={loginSchema}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <Form>
            <div className="NetworkPageFormInput">
              <label>
                <Field
                  placeholder="Email"
                  name="Email"
                  type="text"
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="Email"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Name of the Clinic"
                  name="Name"
                  type="text"
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="Name"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  name="ClinicType"
                  as="select"
                  placeholder="Select Clinic Type"
                  className="NetworkPageFormInputField"
                >
                  <option value="" label="Entity type" disabled>
                    Select type{" "}
                  </option>
                  <option value="Single Spl" label="Single Spl"></option>
                  <option value="Polyclinic" label="Polyclinic"></option>
                </Field>
                <ErrorMessage
                  name="ClinicType"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Name of the Owner/Director"
                  name="OwnerName"
                  type="text"
                  className="NetworkPageFormInputField"
                  onKeyDown={(event) => preventnumberinput(event)}
                ></Field>
                <ErrorMessage
                  name="OwnerName"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Address"
                  name="Address"
                  type="text"
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="Address"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Location"
                  name="Location"
                  type="text"
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="Location"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  name="City"
                  as="select"
                  placeholder="Select City"
                  className="NetworkPageFormInputField"
                >
                  <option value="" disabled selected>
                    Select City
                  </option>
                  {viewCity?.data?.data?.data?.data.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="City"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Pincode"
                  name="Pincode"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="Pincode"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Contact Number"
                  name="ContactNumber"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="ContactNumber"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Registration Number"
                  name="RegistrationNumber"
                  type="text"
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="RegistrationNumber"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Pan Card Number (optional)"
                  name="PanCardNumber"
                  type="text"
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="PanCardNumber"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Consultation Price"
                  name="ConsultationPrice"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="ConsultationPrice"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <div class="file-upload">
                  <div class="file-upload-select">
                    <div class="file-select-button">Upload Rate List</div>
                    <div class="file-select-name">
                      Supported format PDF and Excel{" "}
                    </div>
                    <Input
                      type="file"
                      name="RateList"
                      id="fileUploadInput"
                      accept="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      className="NetworkPageFormInputField"
                      onChange={(event) => handleFileRateListChange(event)}
                    />
                  </div>
                  {fileRateListError && (
                    <div className="errorFile">
                      Please Select only .pdf or .xlsx Extensions{" "}
                    </div>
                  )}
                </div>

                {fileRateList?.size / 1024 > 10240 && (
                  <div className="FileSizedisable">
                    {" "}
                    File Size should be less than 10mb
                  </div>
                )}
                {fileRateList == null && check && (
                  <div className="FileSizedisable"> Select the file</div>
                )}
              </label>

              <label>
                <div class="file-upload">
                  <div class="file-upload-select">
                    <div class="file-select-button">Upload Photo of Clinic</div>
                    <div class="file-select-name2">
                      Supported format .jpg and .png
                    </div>
                    <Input
                      type="file"
                      name="ClinicPhoto"
                      id="fileUploadInput1"
                      accept="image/png, image/jpeg, image/jpg"
                      className="NetworkPageFormInputField"
                      onChange={(event) => handleFileClinicPhotoChange(event)}
                    />
                  </div>
                  {fileClinicPhotoError && (
                    <div className="errorFile">
                      Please Select only .jpg or .jpeg or .png Extensions
                    </div>
                  )}
                </div>
                {fileClinicPhoto?.size / 1024 > 10240 && (
                  <div className="FileSizedisable">
                    {" "}
                    File Size should be less than 10mb
                  </div>
                )}

                {fileClinicPhoto == null && check && (
                  <div className="FileSizedisable"> Select the file</div>
                )}
              </label>

              <label>
                <div className="formControl">
                  <FormControl sx={{ mL: 1, width: "80%" }}>
                    <InputLabel id="demo-multiple-checkbox-label ">
                      Select Services
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      name="Services"
                      value={service}
                      onChange={handleChange}
                      input={<OutlinedInput label=" Select Services" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {Servicesdata?.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          <Checkbox checked={service.indexOf(item.name) > -1} />
                          <ListItemText primary={item.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </label>
            </div>

            <div className="NetworkPageFormSubmit">
              <button
                type="submit"
                className="NetworkPageFormSubmitButton"
                disabled={
                  isSubmitting ||
                  (fileRateListError || fileClinicPhotoError ? true : false)
                }
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ClinicForm;
