import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Cart/CreateCartActionType";
import * as actions from "../../Action/Cart/PaymentGatewayStatusAction";
import NetworkService from "../../../network/Network/Cart/CartNetwork";

export function* paymentGatewayStatusSaga(action) {
  try {
    let response = yield call(NetworkService.paymentGatewayStatus, action);
    yield put(actions.paymentGatewayStatusSuccess(response));
  } catch (err) {
    yield put(actions.paymentGatewayStatusFailure(err));
  }
}

export function* watchPaymentGatewayStatusSaga() {
  yield takeLatest(types.PAYMENT_GATEWAY_STATUS_REQUEST, paymentGatewayStatusSaga);
}
