import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../Action/HealthRiskAssessment/GetHraQuestionsAction"
import NetworkService from "../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* GetHraQuestionsSaga(action) {
  try {
    let response = yield call(NetworkService.GetHraQuestions, action);
    yield put(actions.GetHraQuestionsSuccess(response));
  } catch (err) {
    yield put(actions.GetHraQuestionsFailure(err));
  }
}

export function* watchGetHraQuestionsSaga() {
  yield takeLatest(types.GET_HRA_QUESTIONS_REQUEST, GetHraQuestionsSaga);
}
