import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { getCallRequest } from "../../../redux/Action/Advertisement/GetCallRequestAction";
import "./BookAppointment.css";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";


function BookAppointment({ closeAppointment, nav }) {
  const [isClick, setIsClick] = useState(false);

  const getCallFormValidation = Yup.object().shape({
    mobileNumber: Yup.string()
      .matches(/^\d{10}$/, "Please Enter a Valid 10-digit Number")
      .required("This is a Required Field"),
    Name: Yup.string().required("This is a Required Field"),
    city: Yup.string().required("This is a Required Field"),
    doctorname: Yup.string().required("This is a Required Field"),
    hospitalName: Yup.string().required("This is a Required Field"),
    state: Yup.string().required("This is a Required Field"),
    doctorSpeciality: Yup.string().required("This is a Required Field"),
    dateAndTime: Yup.string().required("This is a Required Field"),
  });


  const handleSubmit = (values) => {
    dispatch(getCallRequest(values));
    setIsClick(true);
  };

  

  const initialValues = useMemo(() => ({
    Name: "",
    mobileNumber: "",
    city: "",
    doctorname: "",
    hospitalName: "",
    state: "",
    doctorSpeciality: "",
    dateAndTime: "",
  }), []);


  const formik = useFormik({
    initialValues,
    validationSchema: getCallFormValidation,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });


  const dispatch = useDispatch();

  const getCall = useSelector((state) => state.advertisement.getCall);
  const viewAllCity = useSelector((state) => state.city.viewAllCity);

  useEffect(() => {
    if (
      getCall?.data?.data.status === 201 &&
      getCall?.data?.data.data.message !== ""
    ) {
      Swal.fire({
        text: getCall.data.data.data.message,
        icon: "success",
      }).then(() => {
        formik.resetForm({ values: initialValues });
        setIsClick(false);
        closeAppointment(false);
      });
      getCall.data.data.status = "";
    }
  }, [getCall, closeAppointment, formik, initialValues]);


  function handleAppointmentModal() {
    closeAppointment(false);
  }

 

  const handleTime = (val) => {
    formik.setFieldValue("dateAndTime", new Date(val).getTime());
  };

  return (
    <div className={nav === true ? "bookAppointmentFormMain" : "bookAppointmentFormMain1"}>
      <div className="bookAppointmentFormMainUpper">
        <p className="bookAppointmentFormMainHeader">Book Appointment</p>
        <CloseIcon className="bookAppointmentCloseIcon" onClick={handleAppointmentModal} />
      </div>
      {/* <div className="bookAppointmentFormMainUpperCheckbox">
        <input type='checkbox' onChange={handleDoctor}/>
        <p className="bookAppointmentFormMainHeaderCheckbox">Add a Doctor</p>
      </div> */}
      <div className="bookAppointmentForm">
        <form onSubmit={formik.handleSubmit}>
          <div className="bookAppointmentFormLabels">
            <label className="">Name</label>
            <input
              type="text"
              name="Name"
              placeholder="Type Your Name Here"
              className="bookAppointmentFormLabelsInput"
              onWheel={(event) => event.currentTarget.blur()}
              {...formik.getFieldProps("Name")}
            />
            {formik.touched.Name && formik.errors.Name && (
              <div style={{ color: "red" }}>{formik.errors.Name}</div>
            )}
          </div>
          <div className="bookAppointmentFormLabels">
            <label className="">Phone Number</label>
            <input
              type="number"
              name="mobileNumber"
              className="bookAppointmentFormLabelsInput"
              placeholder="Type Your Contact Number Here"
              {...formik.getFieldProps("mobileNumber")}
            />
            {formik.touched.mobileNumber && formik.errors.mobileNumber && (
              <div style={{ color: "red" }}>{formik.errors.mobileNumber}</div>
            )}
          </div>
          <div className="bookAppointmentFormLabels">
            <label className="">Location</label>
            <select
              name="city"
              className="bookAppointmentFormLabelsInput"
              placeholder="Select Your City"
              {...formik.getFieldProps("city")}
            >
              <option value="" disabled>
                Select Your City
              </option>
              {viewAllCity?.data?.data?.data.data.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
            {formik.touched.city && formik.errors.city && (
              <div style={{ color: "red" }}>{formik.errors.city}</div>
            )}
          </div>


          {/* ************************************************************************************ */}



          <div className="bookAppointmentFormLabels">
            <label className="">Doctor Name</label>
            <input
              type="text"
              name="doctorname"
              placeholder="Doctor Name"
              className="bookAppointmentFormLabelsInput"
              onWheel={(event) => event.currentTarget.blur()}
              {...formik.getFieldProps("doctorname")}
            />
            {formik.touched.doctorname && formik.errors.doctorname && (
              <div style={{ color: "red" }}>{formik.errors.doctorname}</div>
            )}
          </div>

          <div className="bookAppointmentFormLabels">
            <label className="">Hospital/Clinic Name</label>
            <input
              type="text"
              name="hospitalName"
              placeholder="Hospital/Clinic Name"
              className="bookAppointmentFormLabelsInput"
              onWheel={(event) => event.currentTarget.blur()}
              {...formik.getFieldProps("hospitalName")}
            />
            {formik.touched.hospitalName && formik.errors.hospitalName && (
              <div style={{ color: "red" }}>{formik.errors.hospitalName}</div>
            )}
          </div>

          <div className="bookAppointmentFormLabels">
            <label className="">Doctor Speciality</label>
            <input
              type="text"
              name="doctorSpeciality"
              placeholder="Doctor Speciality"
              className="bookAppointmentFormLabelsInput"
              onWheel={(event) => event.currentTarget.blur()}
              {...formik.getFieldProps("doctorSpeciality")}
            />
            {formik.touched.doctorSpeciality && formik.errors.doctorSpeciality && (
              <div style={{ color: "red" }}>{formik.errors.doctorSpeciality}</div>
            )}
          </div>

          <div className="bookAppointmentFormLabels">
            <label className="">State</label>
            <input
              type="text"
              name="state"
              placeholder="Location"
              className="bookAppointmentFormLabelsInput"
              onWheel={(event) => event.currentTarget.blur()}
              {...formik.getFieldProps("state")}
            />
            {formik.touched.state && formik.errors.state && (
              <div style={{ color: "red" }}>{formik.errors.state}</div>
            )}
          </div>

          <div className="bookAppointmentFormLabels">
            <label className="">Date And Time</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                inputFormat="dd/MM/yyyy hh:mm a"
                name="dateAndTime"
                minDate={new Date()}
                renderInput={(props) => (
                  <TextField className="DateAndTimeStyle" {...props} />
                )}
                label="Select Date and Time"
                onChange={(newValue) => {
                  handleTime(newValue);
                }}
              />
            </LocalizationProvider>
            {formik.touched.dateAndTime && formik.errors.dateAndTime && (
              <div style={{ color: "red" }}>{formik.errors.dateAndTime}</div>
            )}
          </div>

















          {/* ************************************************************************************ */}








          <p className="bookAppointmentFormTerms">
            *I authorize YuvaHealth representative to contact me. I understand
            that this will override the DND status on my mobile number
          </p>

          <div className="bookAppointmentFormButton" type="submit">
            <button type="submit" disabled={isClick}>
              BOOK APPOINTMENT
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default BookAppointment;
