import * as types from "../../Config/Cart/CreateCartActionType";

export const CartDiscountRequest = () => {
  return {
    type: types.CART_DISCOUNT_REQUEST,
  };
};

export const CartDiscountSuccess = (data) => {
  return {
    type: types.CART_DISCOUNT_SUCCESS,
    data,
  };
};

export const CartDiscountFailure = (data) => {
  return {
    type: types.CART_DISCOUNT_FAILURE,
    data,
  };
};
