import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Order/OrderActionType";
import * as actions from "../../Action/Order/GetOrderDetailAction";
import NetworkService from "../../../network/Network/Order/GetOrderNetwork";

export function* getOrderDetailSaga(action) {
  try {
    let response = yield call(NetworkService.getOrderDetail, action);
    yield put(actions.getOrderDetailSuccess(response));
  } catch (err) {
    yield put(actions.getOrderDetailFailure(err));
  }
}

export function* watchGetOrderDetailSaga() {
  yield takeLatest(types.GET_ORDER_DETAIL_REQUEST, getOrderDetailSaga);
}
