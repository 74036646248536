import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HomeAddressIcon from "../../../assets/MyAccount/homeAddress.png";
import OfficeAddressIcon from "../../../assets/MyAccount/OfficeAddress.png";
import "./MyAddressBook.css";
import { CardContent, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { viewAllAddressRequest } from "../../../redux/Action/Address/viewAllAddressAction";
import Title from "../../Atoms/Title/Title";
import NoAddress from "./NoAddress";

const MyAddressBook = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewAllAddressRequest());
  }, [dispatch]);

  const viewAllAddress = useSelector((state) => state.address.viewAllAddress);

  return (
    <div>
      <Title title="Yuva | My Address Book"></Title>
      {viewAllAddress.data?.data.data.data.length === 0 ? (
        <NoAddress />
      ) : (
        <div className="myAddressMainDiv">
          <div className="myAddresstext">My Address Book</div>
          &nbsp;&nbsp;
          <div className="myAddressalltexts">
            <div className="templateNamesPres1">
              <Box>
                <CardContent>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 1, md: 3, lg: 5 }}
                    direction="row"
                  >
                    {viewAllAddress.data?.data.data.data.map((itm) => (
                      <div className="addressBoxTexts">
                        <div className="addressBoxTextsStarts">
                          {itm.away ? (
                            <img
                              src={OfficeAddressIcon}
                              alt="Office icon"
                            ></img>
                          ) : (
                            <img src={HomeAddressIcon} alt="Home icon"></img>
                          )}
                        </div>
                        <div className="addressBoxBody">
                          <div className="addressBoxTitle">
                            {itm.contactName}
                          </div>
                          <div className="addressBoxBodyText">
                            {itm.address},{itm.cityName},{itm.pinCode}
                          </div>
                          <div className="addressBoxPhone">
                            {itm.contactNumber}
                          </div>
                        </div>
                      </div>
                    ))}
                  </Grid>
                </CardContent>
              </Box>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyAddressBook;
