import * as types from "../../Config/Packages/PackageLabTestActionType";

export const packageGetTestRequest = (data) => {
  return {
    type: types.PACKAGE_GET_TEST_REQUEST,
    data:data,
  };
};

export const packageGetTestSuccess = (data) => {
  return {
    type: types.PACKAGE_GET_TEST_SUCCESS,
    data: data,
  };
};

export const packageGetTestFailure = (data) => {
  return {
    type: types.PACKAGE_GET_TEST_FAILURE,
    data: data,
  };
};
