import RequestService from "../../RequestService";
import { api } from "../../../helpers/Account/MyAccountConstant";

class NetworkService {
  myPrescription(action) {
    if (action.id === null) {
      const endPoint = `${api.MyPrescription}?pageNo=1&pageSize=200&serviceUuid=${action.dropdown}`;
      return RequestService.getRequest(endPoint);
    }
    const endPoint = `${api.MyPrescription}?pageNo=1&pageSize=200&serviceUuid=${action.dropdown}&id=${action.id}`
    return RequestService.getRequest(endPoint);
  }
  myReport() {
    const endPoint = `${api.MyReport} `;
    return RequestService.getRequest(endPoint);
  }
  myReportDiagnostic(action) {
    const idParam = action.id != null ? `?id=${action.id}` : '';
    const endPoint = `${api.MyReportDiagnostic}${idParam} `;
    return RequestService.getRequest(endPoint);
  }
  myAddressBook() {
    const endPoint = `${api.MyAddressBook} `;
    return RequestService.getRequest(endPoint);
  }
}
export default new NetworkService();
