import { all } from "redux-saga/effects";
import { watchdownloadErmReportSaga } from "./downloadErmReportSaga";
import { watchmedicalRecordDropdown } from "./medicalRecordDropdownSaga";
import { watchmedicalRecordFormSaga } from "./medicalRecordFormSaga";
import { watchViewMedicalRecordSaga } from "./MedicalRecordListingSaga";


function* rootErmSaga() {
    yield all([
        watchViewMedicalRecordSaga(),
        watchmedicalRecordDropdown(),
        watchdownloadErmReportSaga(),
        watchmedicalRecordFormSaga()
    ])
}

export default rootErmSaga;