import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../../Action/HealthRiskAssessment/GetQuestionSection3Action";
import NetworkService from "../../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* GetQuestionSection3Saga(action) {
  try {
    let response = yield call(NetworkService.GetQuestionSection3, action);
    yield put(actions.GetQuestionSection3Success(response));
  } catch (err) {
    yield put(actions.GetQuestionSection3Failure(err));
  }
}

export function* watchGetQuestionSection3Saga() {
  yield takeLatest(types.GET_QUESTION_SECTION3_REQUEST, GetQuestionSection3Saga);
}
