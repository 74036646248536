import React from "react";
import "../HRAscreens/HRAInfoScreens.css";
import backArrow from "../../../../assets/hra/backArrow.png";
import hra8b from "../../../../assets/hra/hra8b.png";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../Molecules/ScrollToTop/ScrollToTop";
import storage from "../../../../Utils/storage";
import SingleLogin from "../../Login/SingleLogin";

function HRA8b() {
  const navigate = useNavigate();
  const SectionValue = localStorage.getItem("SectionValue");

  const handlePrevious = () => {
    navigate("/health-risk-assessment/section-8a");
  };
  window.addEventListener("popstate", function (event) {
    storage.setStorage("SectionValue", 7);
  });
  if (storage.getStorage("token") === null) {
    return <SingleLogin />;
  }
  return (
    <>
      <ScrollToTop />

      <div className="hraInfoScreens">
        <div className="hraInfoScreensChild1">
          <p>Your Health Risk Assessment</p>
        </div>
        {SectionValue === "8" && (
          <div
            className="hraInfoScreensChild2"
            style={{ backgroundImage: `url(${hra8b})` }}
          >
            <div className="hraInfoScreensChild2a">
              <div className="hraInfoScreensChild2a1">
                <p>GENERAL SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>DIETARY SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>MENTAL HEALTH SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>ALCOHOL RISK SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>SMOKING & TOBACCO SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>SAFETY HABITS SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>CURRENT MEDICAL CONDITION SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>HERIDETARY AND GENETIC SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a2">
                <div className="hraInfoBackArrow">
                  <img
                    src={backArrow}
                    onClick={handlePrevious}
                    alt="backArrow"
                  />
                  <p>
                    Hereditary And <br />
                    Genetic Section
                  </p>
                </div>
                <div className="hraInfo">
                  <p>
                    Your Hereditary and Genetic Information tells us a lot about
                    who you are.
                  </p>
                </div>
                <button
                  onClick={() => {
                    localStorage.setItem("SectionValue", 8);
                    navigate("/health-risk-assessment/section-8");
                  }}
                >
                  Discover further
                </button>
              </div>
            </div>
          </div>
        )}
        {SectionValue !== "8" && (
          <div className="hraDenied">
            Not Authorized !! please navigate to previous sections
          </div>
        )}
      </div>
    </>
  );
}

export default HRA8b;
