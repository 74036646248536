import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const GetQuestionSection9Request = (data) => {
    return {
        type: types.GET_QUESTION_SECTION9_REQUEST,
        data:data
    }
}

export const  GetQuestionSection9Success = (data) => {
    return {
        type: types.GET_QUESTION_SECTION9_SUCCESS,
        data:data,
    }
}

export const  GetQuestionSection9Failure = (data) => {
    return {
        type: types.GET_QUESTION_SECTION9_FAILURE,
        data:data,
    }
}