import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../../Action/HealthRiskAssessment/GetQuestionSection8Action";
import NetworkService from "../../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* GetQuestionSection8Saga(action) {
  try {
    let response = yield call(NetworkService.GetQuestionSection8, action);
    yield put(actions.GetQuestionSection8Success(response));
  } catch (err) {
    yield put(actions.GetQuestionSection8Failure(err));
  }
}

export function* watchGetQuestionSection8Saga() {
  yield takeLatest(types.GET_QUESTION_SECTION8_REQUEST, GetQuestionSection8Saga);
}
