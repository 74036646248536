import React from "react";
import Chest from "../../../assets/Package/image.png";
import useLocalStorageSync from "../../../hooks/useLocalStorageSync";
import Title from "../../Atoms/Title/Title";
import "./subscriptionHero.css";

export default function SubscriptionHero({
  packageName,
  handleClick,
  isSubmitting,
  packageCost,
  discountedPackageCost,
  id,
  isTest,
}) {
  const itemDtoList = useLocalStorageSync("itemDtoList");

  return (
    <div className="subscriptionHero">
      <div className="subscriptionHeroLeft">
        <div className="subscriptionHeroText">
          <div className="subscriptionHeroTextTitle">
            {packageName ? (
              <div>
                <span>{packageName} : </span>
                Comprehensive Health Screening for Vital Organ Function
              </div>
            ) : (
              <div>
                Reduce Your <span>Medical</span>
                <br />
                <span>Expenses by HALF</span>
              </div>
            )}
          </div>
          <div className="subscriptionHeroTextDesc">
            {isTest ? (
              <>
                Home Sample Collection | Report Available within 8 hrs Unisex -
                0 yrs - 99 yrs | Fasting Required
              </>
            ) : (
              <>Save for things that make you happy</>
            )}
          </div>
          {packageCost && (
            <div className="subscriptionHeroPrice">
              <div className={"subscriptionHeroPriceText"}>
                {packageCost !== discountedPackageCost && `₹ ${packageCost}/-`}
              </div>
              <div className={"subscriptionHeroPriceCost"}>
                ₹ {discountedPackageCost}/-
              </div>
            </div>
          )}
          {isTest ? (
            <>
              {itemDtoList.length > 0 &&
              itemDtoList.filter(
                (x) =>
                  x.productId?.toString() === id?.toString() &&
                  x.productType !== "PRODUCT"
              ).length > 0 ? (
                <button className="subscriptionHeroButton">Added</button>
              ) : (
                <button
                  className="subscriptionHeroButton"
                  disabled={isSubmitting}
                  onClick={() => handleClick()}
                >
                  Add To Cart
                </button>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="subscriptionHeroRight">
        <img src={Chest} alt="Doctor" />
        <div
          className={
            discountedPackageCost
              ? "subscriptionHeroRightText"
              : "subscriptionHeroRightTextHead"
          }
          style={
            packageCost === discountedPackageCost && isTest
              ? {
                  top: "6em",
                  right:"2em"
                }
              : {
              }
          }
        >
          <div className={"subscriptionHeroRightTextDiscount"}>
            {discountedPackageCost ? (
              <span>
                {" "}
                {packageCost !== discountedPackageCost && `₹ ${packageCost} /-`}
              </span>
            ) : (
              "Starting from"
            )}
          </div>
          <div className={"subscriptionHeroRightTextCost"}>
            {discountedPackageCost ? (
              `₹ ${discountedPackageCost} /-`
            ) : (
              <>
                <span>₹ 1600 </span>
                <br />
                per month
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
