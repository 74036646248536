import React, { useEffect, useState } from "react";
import "./EMRMPage.css";
import apple from "../../../assets/Health_Checkups_Assests/apple.png";
import googleplaybadge from "../../../assets/talktodoctorworking/googleplaybadge.png";
import { useNavigate } from "react-router-dom";
import TopBanner from "../../../assets/EMRM/TopBanner.png";
import ElectronicBanner from "../../../assets/EMRM/ElectronicBanner.png";
import Title from "../../Atoms/Title/Title";
import { Helmet } from "react-helmet";

function EMRMPage() {
  const navigate = useNavigate();

  const handleTryNowButton = () => {
    {
      localStorage.getItem("token") === null
        ? navigate("/login")
        : navigate("/selection-page");
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div>
      <Helmet>
        <title>
          EMR Systems | Keep Electronic Health Records Secure and Accessible
        </title>
        <meta
          name="description"
          content="Manage your health records efficiently with our EMR systems. Keep electronic health records secure, organized, and easily accessible for better care."
        />
        <meta
          name="keywords"
          content="For record electronic health records emr systems"
        />
      </Helmet>
      <div className="CashlessOPD_OuterContainer">
        <div
          style={{
            backgroundColor: "#E1EEFF",
          }}
          className="emrmBanner"
        >
          <div className="emrmBannerText">
            <p>Electronic Medical Record</p>
            <p>Free Access To all your record at fingertip</p>
          </div>
          <div className="emrmBannerImage">
            <img src={TopBanner} alt="Doctor giving medicine" />
          </div>
        </div>

        <div className="CashlessOPD_BlockTwo">
          <div className="CashlessOPD_BlockTwoHeading">
            WHAT IS ELECTRONIC MEDICAL RECORD MANAGEMENT
          </div>
          <div className="CashlessOPD_BlockTwoSubHeading">
            Yuva Electronic Medical Record (EMR) is designed for efficient
            record-keeping and seamless collaboration, it empowers healthcare
            professionals to streamline patient care. YuvaHealth offers secure
            and centralized storage of medical records, enabling easy access and
            retrieval of patient information. YuvaHealth prioritizes patient
            privacy and data security, adhering to industry standards and
            encryption protocols.
          </div>
          <div className="EMRM_BlockTwoSubHeading_Button">
            <button onClick={handleTryNowButton}>Try Our EMRM</button>
          </div>
        </div>
        <div className="HraHealthRisk_BlockThree">
          <div
            style={{
              background:
                "linear-gradient(90.56deg, #1c71e1 0.48%, #c2d0e3 55.86%, #1c71e1 97.15%) 0% 0% / 100% no-repeat",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100%",
            }}
            className="CashlessOPDHeadingImage"
          >
            <div className="CashlessOPD_Heading1">
              <div className="OPDheading">
                Download our app to access your reports...
              </div>
              <div className="iconandroidios">
                <a
                  href="https://play.google.com/store/apps/details?id=com.yuva"
                  target="_blank"
                >
                  <img
                    className="androidStore"
                    src={googleplaybadge}
                    alt="play store"
                  ></img>
                </a>
                <a
                  href="https://apps.apple.com/in/app/yuvahealth/id6449449413"
                  target="_blank"
                >
                  <img className="iosStore" src={apple} alt="app store"></img>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="CashlessOPD_BlockFour">
          <div className="CashlessOPD_BlockFourHeading">
            ELECTRONIC MEDICAL RECORD MANAGEMENT
          </div>
          <div className="EMRM_Grid_Container">
            <div className="EMRMIMAGE">
              <img
                className="EMRM_Grid_Conatiner_Image"
                src={ElectronicBanner}
                alt="Doctor Prescribing"
              ></img>
            </div>
            <div className="EMRMIMAGEText">
              <div className="EMRM_Grid_Conatiner_Text">
                <span className="EMRM_Grid_Conatiner_Text_span">
                  Enhanced Efficiency:
                </span>{" "}
                EMR systems eliminate the need for paper-based record-keeping,
                reducing the time and effort spent on manual tasks such as
                filing, retrieving, and organizing patient information. It
                enables healthcare professionals to quickly access and update
                patient records, leading to improved workflow efficiency and
                time management.
              </div>
              <div className="EMRM_Grid_Conatiner_Text">
                <span className="EMRM_Grid_Conatiner_Text_span">
                  Improved Patient Care:{" "}
                </span>{" "}
                EMR systems provide a comprehensive view of a patient's medical
                history, including diagnoses, medications, allergies, and test
                results. This consolidated information helps healthcare
                providers make well-informed decisions and deliver
                better-coordinated care. Alerts and reminders within the system
                can also help prevent medical errors and ensure timely
                interventions.
              </div>
              <div className="EMRM_Grid_Conatiner_Text">
                <span className="EMRM_Grid_Conatiner_Text_span">
                  Increased Accuracy and Legibility:
                </span>{" "}
                Illegible handwriting and transcription errors can compromise
                patient safety and lead to miscommunication. EMR systems
                eliminate these issues by allowing healthcare providers to input
                information directly into the system, ensuring accuracy and
                legibility of medical records.
              </div>
            </div>
          </div>
        </div>
        <div className="EMRM_TryNow_Heading_Button">
          <button onClick={handleTryNowButton}>Try Now </button>
        </div>
      </div>
    </div>
  );
}

export default EMRMPage;
