import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

const initialState = {
    data: null,
    isLoading: false,
    error: null
}

export const section9AnswersReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.SECTION9_ANSWERS_SUCCESS:
            return {
                ...initialState,
                data: action,
            }
      
        default:
            return state
    }
}