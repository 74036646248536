import axios from "axios";
import storage from "./storage.js";
import CryptoJS from "crypto-js";

var token = storage.getStorage("token")

const handleUpdatedToken = (updatedToken) => {
  instance.defaults.headers.common.Authorization = `Bearer ${updatedToken}`;
};

window.addEventListener('refresh', function () {
  const updatedToken = storage.getStorage("token");
  handleUpdatedToken(updatedToken);
});


if (token === null || token === undefined) {
  token = "";
}

const instance = axios.create({
  baseURL: "/api/v1/yuva",
});

instance.defaults.headers.common = {
  Authorization: `Bearer ${token}`,
  "Access-Control-Allow-Origin": "*",
};

const requestHandler = (request) => {
  return request;
};

const responseHandler = (response) => {
  return response;
};

let isRefreshing = false;

const errorHandler = async (error) => {
  if ((error.response?.status === 401 && !isRefreshing) && error.config.url !== "/login") {
    isRefreshing = true;

    try {
      const fcmToken = storage.getStorage("fcmToken");
      const token = storage.getStorage("sessionToken");
      const secretKey = process.env.REACT_APP_SECURITY_KEY;
      const decryptedBytes = CryptoJS.AES.decrypt(token, secretKey);
      const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);

      const refreshResponse = await instance.post("/refresh-token", {
        fcmToken: fcmToken,
        token: decryptedData
      });

      const encryptedToken = CryptoJS.AES.encrypt(
        refreshResponse.data.data.refreshToken,
        secretKey
      ).toString();

      storage.setStorage("token", refreshResponse.data.data.jwt);
      storage.setStorage("sessionToken", encryptedToken);

      window.location.reload();
      isRefreshing = false;
      return;
    } catch (refreshError) {
      storage.removeStorage("token");
      storage.removeStorage("name");
      storage.removeStorage("sessionToken");
      storage.removeStorage("authorization");
      storage.removeStorage("id");
      window.location.href = "/login";
      isRefreshing = false;
    }
  }
  return Promise.reject(error);
};

instance.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

instance.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default instance;