import React from "react";
import "./TalkToDoctorSliderCard.css";

function TalkToDoctorSliderCard({ Content }) {
  return (
    <div className="TalkToDoctorSliderCard">
      <div className="TalkToDoctorSliderCardImage">
        <img src={Content.Image} alt="Disease" />
      </div>
      <p>{Content.Name}</p>
    </div>
  );
}

export default TalkToDoctorSliderCard;
