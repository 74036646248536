import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import "./addAddressForm.css";

function AddAddressForm(props) {
  const navigate = useNavigate();

  const viewAllCity = useSelector((state) => state.city.viewAllCity);

  const addressSchema = Yup.object().shape({
    line1: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    pincode: Yup.string().required("Required"),
    receiverName: Yup.string().required("Required"),
    contactNumber: Yup.string().required("Required"),
  });

  const handleSubmit = (values) => {
    const cityName = viewAllCity.data.data.data.data[values.city - 1].name;
    if (props.location.haveProduct) {
      navigate("/cart-payment", {
        state: {
          PatientName: props.location?.PatientName,
          age: props.location?.age,
          address: values.line1,
          cityId: values.city,
          contactNumber: values.contactNumber,
          pinCode: values.pincode,
          plan: false,
          cityName: cityName,
          away: values.away,
          contactName: values.receiverName,
          haveProduct: props.location?.haveProduct,
        },
      });
    } else {
      if (window.location.pathname === "/cart-checkout-plan") {
        navigate("/cart-payment-plan", {
          state: {
            genderEnum: props.location?.state?.genderEnum,
            PatientName: props.location?.state?.PatientName,
            age: props.location?.state?.age,
            address: values.line1,
            cityId: values.city,
            contactNumber: values.contactNumber,
            pinCode: values.pincode,
            plan: true,
            cityName: cityName,
            contactName: values.receiverName,
            away: values.away,
          },
        });
      } else {
        navigate("/cart-checkout-select-member", {
          state: {
            genderEnum: props.location?.genderEnum,
            PatientName: props.location?.PatientName,
            age: props.location?.age,
            address: values.line1,
            cityId: values.city,
            contactNumber: values.contactNumber,
            pinCode: values.pincode,
            plan: false,
            cityName: cityName,
            contactName: values.receiverName,
            away: values.away,
          },
        });
      }
    }
  };

  return (
    <div className="addAddressForm">
      <div className="addAddressFormBody">
        <div className="addAddressFormTitle">Add New Address</div>
        <div className="addAddressFormValue">
          <Formik
            initialValues={{
              line1: "",
              city: "",
              pincode: "",
              receiverName: "",
              contactNumber: "",
            }}
            enableReinitialize={true}
            validationSchema={addressSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <div className="addAddressFormMain">
                  <div className="addAddressFormLeft">
                    <div className="addAddressFormBlock">
                      <div className="addAddressTextForm">Name</div>
                      <div className="addAddressInput">
                        <Field
                          type="text"
                          name="receiverName"
                          placeholder="Name"
                          className="addAddressInputField"
                        />
                        <ErrorMessage
                          className="errorMessageModalValidation"
                          name="receiverName"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="addAddressFormBlock">
                      <div className="addAddressTextForm">Contact Number</div>
                      <div className="addAddressInput">
                        <Field
                          type="number"
                          name="contactNumber"
                          placeholder="Contact Number"
                          className="addAddressInputField"
                        />
                        <ErrorMessage
                          className="errorMessageModalValidation"
                          name="contactNumber"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="addAddressFormLeft">
                    <div className="addAddressFormBlock">
                      <div className="addAddressTextForm">Address</div>
                      <div className="addAddressInput">
                        <Field
                          as="textarea"
                          type="text"
                          name="line1"
                          placeholder="Apartment/Road/Area"
                          className="addAddressInputFieldArea"
                        />
                        <ErrorMessage
                          className="errorMessageModalValidation"
                          name="line1"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="addAddressFormLeft">
                    <div className="addAddressFormBlock">
                      <div className="addAddressTextForm">City</div>
                      <div className="addAddressInput">
                        <Field
                          as="select"
                          name="city"
                          placeholder="City"
                          className="addAddressInputSelectField"
                        >
                          <option value="" disabled selected>
                            City
                          </option>
                          {viewAllCity.data?.data.data.data.map((itm) => (
                            <option value={itm.id}>{itm.name}</option>
                          ))}
                        </Field>
                        <ErrorMessage
                          className="errorMessageModalValidation"
                          name="city"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="addAddressFormLeft">
                      <div className="addAddressFormBlock">
                        <div className="addAddressTextForm">Pin Code</div>
                        <div className="addAddressInput">
                          <Field
                            type="text"
                            name="pincode"
                            placeholder="Pin Code"
                            className="addAddressInputField"
                          />
                          <ErrorMessage
                            className="errorMessageModalValidation"
                            name="pincode"
                            component="div"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="addAddressFormLeft">
                    <div className="addAddressFormBlock">
                      <div className="addAddressTextForm">Save As</div>
                      <div className="addAddressInput">
                        <Field
                          as="select"
                          name="saveAs"
                          placeholder="Save As"
                          className="addAddressInputSelectField"
                        >
                          <option value={true} selected>
                            Home
                          </option>
                          <option value={false}>Away</option>
                        </Field>
                        <ErrorMessage
                          className="errorMessageModalValidation"
                          name="saveAs"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <button className="addAddressConfirmDetails" type="submit">
                  Confirm Details
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default AddAddressForm;