import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import storage from "../../../Utils/storage";
import { UpdateCart } from "../../../Utils/updateCart";
import { createCartClear } from "../../../redux/Action/Cart/CreateCartAction";
import { GetAllCouponRequest } from "../../../redux/Action/Cart/GetAllCouponAction";
import { getCartRequest } from "../../../redux/Action/Cart/GetCartAction";
import { removeCartItemRequest } from "../../../redux/Action/Cart/RemoveCartItemAction";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import Title from "../../Atoms/Title/Title";
import createSlug from "../../Molecules/SlugGenerator/CreateSlug";
import CartWeb from "../../Organisms/Cart/CartWeb";
import "./MyCart.css";

function Cart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [apply, setApply] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [couponCode, setCouponCode] = useState();
  const [cart, setCart] = useState();
  const [value, setValue] = useState();
  const [coupon, setCoupon] = useState("");
  const [coupon1, setCoupon1] = useState("");
  const [newPin, setNewPin] = useState();
  const [listItems, setListItems] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [CouponNotFound,setCouponNotFound]=useState(false);

  const createCart = useSelector((state) => state.cart.createCart);
  const getCart = useSelector((state) => state.cart.getCart);
  const removeCartItem = useSelector((state) => state.cart.removeCartItem);

  useEffect(() => {
    for (let i = 1; i <= 10; i++) {
      setListItems((prevValue) => [...prevValue, i]);
    }
  }, []);

  useEffect(() => {
    if (getCart.data?.data.status === 200) {
      dispatch(GetAllCouponRequest());
    }
  }, [getCart.data]);

  useEffect(() => {
    if (removeCartItem.data?.data.status === 200) {
      window.dispatchEvent(new Event("storage"));
      dispatch(getCartRequest());
    }
  }, [removeCartItem.data?.data]);

  useEffect(() => {
    if (createCart.data?.data.status === 200 && itemList) {
      localStorage.setItem("itemDtoList", JSON.stringify(itemList));
      window.dispatchEvent(new Event("storage"));
      dispatch(getCartRequest());
    }
    return () => {
      dispatch(createCartClear());
      window.dispatchEvent(new Event("storage"));
    };
  }, [createCart.data, createCart.error, itemList]);

  useEffect(() => {
    if (getCart.data?.data.data.data.itemDtoList.length === 0) {
      navigate("/");
      
    } else if (
      getCart?.error !== null &&
      getCart?.error?.data?.response?.data.errorMessage !== "Coupon not found"
    ) {
      Swal.fire({
        title: `${getCart?.error?.data?.response?.data.errorMessage}`,
        icon: "error",
      }).then(() => {
      RemoveCoupon();
        navigate("/");
      });
    }
    else if (
      getCart?.error !== null &&
      getCart?.error?.data?.response?.data.errorMessage === "Coupon not found"
    ) {
      setCouponNotFound(true);
      setTimeout(()=>{
        setCouponNotFound(false);
      },3000)
      RemoveCoupon();
    }
     else if (
      getCart?.data?.data?.status === 200 &&
      getCart?.data?.data?.data?.data.cartPriceResponseDto.couponCode === null
    ) {
      setApply(false);
      setCouponCode(null);
      setNewPin();
    } else if (
      getCart?.data?.data?.status === 200 &&
      getCart?.data?.data?.data?.data.cartPriceResponseDto.couponCode !== ""
    ) {
      setApply(true);
      setNewPin();
      setCouponCode(
        getCart?.data?.data?.data?.data.cartPriceResponseDto.couponCode
      );
    }
    setCart(getCart.data?.data.data.data);
  }, [getCart]);

  function hasProductTypeAsProduct() {
    var isProduct = false;
    for (let i = 0; i < cart.itemDtoList.length; i++) {
      if (cart.itemDtoList[i].productType === "PRODUCT") {
        isProduct = true;
      } else {
        return false;
      }
    }
    return isProduct;
  }

  const handleCheckout = () => {
    let bool = hasProductTypeAsProduct();

    if (storage.getStorage("token") !== null) {
      navigate("/cart-checkout", {
        state: {
          relationId: null,
          PatientName: value?.Name,
          genderEnum: value?.Gender,
          age: value?.Age,
          haveProduct: bool,
          cart: cart,
        },
      });
    } else {
      navigate("/login", {
        state: "package",
      });
    }
  };

  const handleApplyCoupon = (event) => {
    setCoupon(event.target.attributes.code.value.toUpperCase());
    setNewPin(event.target.attributes.pin.value);
    dispatch(getCartRequest(event.target.attributes.code.value.toUpperCase()));
  };

  const handleCoupon = (e) => {
    setCoupon1(e.target.value.toUpperCase());
  };

  const handleApply = () => {
    setCoupon(coupon1);
    if (coupon1 === "") {
      setErrorMessage("Please enter the coupon code");
    }
  };

  useEffect(()=>{
    if (coupon !== "") {
      setErrorMessage("");
      dispatch(getCartRequest(coupon));
    }
  },[coupon])

  const RemoveCoupon = () => {
    setCoupon1("");
    setCoupon("");
    setErrorMessage("");
    setApply(false);
    dispatch(getCartRequest(true));
  };

  const handleViewDetails = async (item) => {
    try {
      if (item.productType === "PACKAGE") {
        const slug = await createSlug(item.name);
        navigate(`/package/${slug}-${item.productId}`);
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (item.productType === "TEST") {
        try {
          const slug = await createSlug(item.name);
          navigate(`/test/${slug}-${item.productId}`);
        } catch (error) {}
      } else if (item.productType === "PRODUCT") {
        navigate(`/product-info?product-id=${item.productId}`);
      }
    } catch (error) {}
  };

  const handleRemove = (productId, productType, productPriceId) => {
    Swal.fire({
      title: "Do you want to remove this product",
      icon: "info",
      showCloseButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        window.dispatchEvent(new Event("storage"));
        if (productType == "PRODUCT") {
          dispatch(removeCartItemRequest(productPriceId, productType));
        } else {
          dispatch(removeCartItemRequest(productId, productType));
        }
      }
    });
  };

  const handleKeyPress = (e) => {
    if (e.charCode === 32) {
      e.preventDefault();
    }
  };

  async function handleQuantity(e, item) {
    setItemList(await UpdateCart(item.productId, dispatch, e.target.value));
  }

  if (getCart.isLoading) {
    return <YuvaLoader />;
  }

  return (
    <div>
      <Title title="YUVA | Cart Checkout" />
      <CartWeb
        cart={cart}
        listItems={listItems}
        handleRemove={handleRemove}
        apply={apply}
        RemoveCoupon={RemoveCoupon}
        handleCoupon={handleCoupon}
        handleKeyPress={handleKeyPress}
        coupon1={coupon1}
        handleApply={handleApply}
        errorMessage={errorMessage}
        newPin={newPin}
        couponCode={couponCode}
        handleApplyCoupon={handleApplyCoupon}
        handleCheckout={handleCheckout}
        handleViewDetails={handleViewDetails}
        handleQuantity={handleQuantity}
        CouponNotFound={CouponNotFound}
      />
      {/* )} */}
    </div>
  );
}

export default Cart;
