import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import eye from "../../../../assets/Login/eye.png";
import signupImage from "../../../../assets/Register/signupImage.png";
import Logo from "../../../../assets/common/logoPrimaryFooter.png";
import logo from "../../../../assets/common/logoPrimaryNavbar.png";
import { userCheckHashIdRequest } from "../../../../redux/Action/User/checkHashIdAction";
import { userResetPasswordRequest } from "../../../../redux/Action/User/resetPasswordAction";
import Title from "../../../Atoms/Title/Title";
import "./ResetPassword.css";

function ResetPassword() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hashTrue, setHashTrue] = useState(false);

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Must be minimum 6 digits")
      .required("Please enter your password."),
    retypePassword: Yup.string()
      .oneOf([Yup.ref("password")], "Your passwords do not match.")
      .required("Please retype your password."),
  });

  const search = useLocation().search;
  const hash = new URLSearchParams(search).get("hash");
  const id = new URLSearchParams(search).get("id");

  const responseCheckHashId = useSelector((state) => state.user.checkHashId);

  var values1 = {
    hash,
    id,
  };

  useEffect(() => {
    dispatch(userCheckHashIdRequest(values1));
  }, []);

  useEffect(() => {
    if (responseCheckHashId?.data !== null) {
      if (responseCheckHashId?.data?.data?.status === 200) {
        setHashTrue(true);
      } else {
        return navigate("/linkExpired");
      }
    }
  }, [responseCheckHashId]);

  const dispatch = useDispatch();
  const resetPassword = useSelector((state) => state.user.resetPassword);

  const handleSubmit = (values) => {
    setIsSubmitting(true);
    const finalValue = {
      values,
      values1,
    };
    dispatch(userResetPasswordRequest(finalValue));
  };

  if (resetPassword?.data?.data?.status === 200) {
    Swal.fire({
      title: "Password Reset Successfully",
      icon: "success",
    }).then(() => {
      setIsSubmitting(false);
      navigate("/login");
    });
  } else if (resetPassword?.error !== null) {
    Swal.fire({
      title: resetPassword?.error?.data?.response.data.message,
      icon: "error",
    }).then(() => {
      setIsSubmitting(false);
    });
  }

  function handlePassword() {
    setShowPassword(!showPassword);
  }

  function handleConfirmPassword() {
    setShowConfirmPassword(!showConfirmPassword);
  }

  return (
    <div>
      <Title title="Yuva | Reset Password"></Title>

      <div className="signupMain">
        <div className="signupMainLeft">
          <img src={signupImage} className="signupLeftBackground" alt="SignUp"/>
          <img src={Logo} className="LogoSignUp" alt="Logo"/>
        </div>
        <div className="signupMainRight">
          <div className="signupArea">
            <div className="LogoAreaLOGIN_SIGNUP">
              <img src={logo} alt="Logo"/>
            </div>
            <p className="signupAreaFirstHeading">Reset Password</p>
            <p className="signupAreaSecondHeading">Get your new password</p>
            <div className="signupForm">
              <Formik
                initialValues={{
                  password: "",
                  retypePassword: "",
                }}
                validationSchema={resetPasswordSchema}
                onSubmit={handleSubmit}
              >
                {() => {
                  return (
                    <Form className="SingleRegisterForm">
                      <div className="signUpBox">
                        <div style={{ position: "relative" }}>
                          {showPassword ? (
                            <Field
                              type="text"
                              name="password"
                              placeholder="Password"
                              className="signupInputField"
                            />
                          ) : (
                            <Field
                              type="password"
                              name="password"
                              placeholder="Password"
                              className="signupInputField"
                            />
                          )}

                          <ErrorMessage
                            className="errorMessageResetPassword"
                            name="password"
                            component="div"
                          />
                          <img
                            src={eye}
                            onClick={handlePassword}
                            style={{
                              position: "absolute",
                              right: "10%",
                              top: "50%",
                              transform: "translateY(-50%)",
                            }}
                            alt="Eye"
                          />
                        </div>
                      </div>
                      <div className="signUpBox">
                        <div style={{ position: "relative" }}>
                          {showConfirmPassword ? (
                            <Field
                              type="text"
                              name="retypePassword"
                              placeholder="Confirm Password"
                              className="signupInputField"
                            />
                          ) : (
                            <Field
                              type="Password"
                              name="retypePassword"
                              placeholder="Confirm Password"
                              className="signupInputField"
                            />
                          )}

                          <ErrorMessage
                            className="errorMessageResetPassword"
                            name="retypePassword"
                            component="div"
                          />
                          <img
                            src={eye}
                            onClick={handleConfirmPassword}
                            style={{
                              position: "absolute",
                              right: "10%",
                              top: "50%",
                              transform: "translateY(-50%)",
                            }}
                            alt="Eye"
                          />
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="signupInputFieldButton"
                        disabled={isSubmitting}
                      >
                        Reset Password
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
