import * as types from "../../Config/ProfileLock/ProfileLock";

export const profileLockRequest = (data) => {
  return {
    type: types.PROFILE_LOCK_REQUEST,
    data:data,
  };
};

export const profileLockSuccess = (data) => {
  return {
    type: types.PROFILE_LOCK_SUCCESS,
    data: data,
  };
};

export const profileLockFailure = (data) => {
  return {
    type: types.PROFILE_LOCK_FAILURE,
    data: data,
  };
};
