import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Landing/landingActionType";
import * as actions from "../../Action/Landing/joinNetworkAction";
import NetworkService from "../../../network/Network/Landing/landingNetwork";

export function* joinNetworkSaga(action) {

  try {
    let response = yield call(NetworkService.joinNetwork, action);
    yield put(actions.joinNetworkSuccess(response));
  } catch (err) {
    yield put(actions.joinNetworkFailure(err));
  }
}

export function* watchJoinNetworkSaga() {
  yield takeLatest(types.JOIN_NETWORK_REQUEST, joinNetworkSaga);
}