import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Landing/landingActionType";
import * as actions from "../../Action/Landing/getPopularPlanHomeScreenAction";
import NetworkService from "../../../network/Network/Landing/landingNetwork";

export function* getPopularPlanHomeScreenSaga(action) {

  try {
    let response = yield call(NetworkService.getPopularHomeScreenPlan, action);
    yield put(actions.getPopularPlanHomeScreenSuccess(response));
  } catch (err) {
    yield put(actions.getPopularPlanHomeScreenFailure(err));
  }
}

export function* watchGetPopularPlanHomeScreenSaga() {
  yield takeLatest(types.GET_POPULAR_PLAN_HOMESCREEN_REQUEST, getPopularPlanHomeScreenSaga);
}