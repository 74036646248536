export const getPackageMetaData = (id) => {
  switch (id) {
    case "36c5f42c-1719-4625-8a4a-d67861fe87d3":
      return {
        title: "Vitamin Profile Test || Yuva Health",
        description:
          "Looking for a vitamin profile test? Yuva Health offers comprehensive vitamin testing. Book your appointment today for expert care near you.",
        keywords: "Vitamin Profile Test",
      };
    default:
      return {
        title: "Yuva Health: Comprehensive Health Checkup Packages",
        description: "Discover Yuva Health's comprehensive health checkup packages, including  comprehensive medical checkup package also. Take proactive steps towards your well-being with our thorough and affordable health assessments. Book your appointment today for a holistic approach to healthcare.",
        keywords: "Comprehensive Health Checkup Packages",
      };
  }
};
