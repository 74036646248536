import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Packages/PackageLabTestActionType";
import * as actions from "../../Action/Packages/LifeStylePackageViewAllAction";
import NetworkService from "../../../network/Network/Packages/PackageLabTestNetwork";

export function* LifestylePackageViewAllSaga(action) {
  try {
    let response = yield call(NetworkService.lifestylePackageViewAll, action);
    yield put(actions.lifestylePackageViewAllSuccess(response));
  } catch (err) {
    yield put(actions.lifestylePackageViewAllFailure(err));
  }
}

export function* watchLifestylePackageViewAllSaga() {
  yield takeLatest(types.LIFESTYLE_PACKAGE_VIEWALL_REQUEST, LifestylePackageViewAllSaga);
}
