import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/ContactUsPage/ContactUsPageActionType"
import * as actions from "../../Action/ContactUsPage/QueryTypeAction";
import NetworkService from "../../../network/Network/ContactUsPage/ContactUsPageNetwork";
export function* ContactUsPageSaga(action) {

  try {
    let response = yield call(NetworkService.ContactUsPage, action);
    yield put(actions.QueryTypeSuccess(response));
  } catch (err) {
    yield put(actions.QueryTypeFailure(err));
  }
}

export function* watchContactUsPageSaga() {
  yield takeLatest(types.QUERY_TYPE_REQUEST, ContactUsPageSaga);
}