import RequestService from "../../RequestService";
import { api } from "../../../helpers/Order/GetOrderDetailConstant";

class NetworkService {
  getOrderDetail(action) {
    const endPoint = `${api.getOrderDetail}/details/user?id=${action?.data}`;
    return RequestService.getRequest(endPoint);
  }

  getAllOrderHistory(action) {
    const endPoint = `${api.getAllOrderHistory}/order-history/user?pageNo=1&pageSize=100`;
    return RequestService.postRequest(endPoint, {
      productType: action.data.dropdown,
    });
  }

  getPlanSubscription(action) {
    const endPoint = `${api.getPlanSubscription}/user/plan/subscription?pageNo=1&pageSize=20`;
    return RequestService.postRequest(endPoint, {
      orderStatus: action.data.search,
    });
  }

  getProgramSubscription(action) {
    const endPoint = `${api.getProgramSubscription}/program?pageNo=1&pageSize=20`;
    return RequestService.getRequest(endPoint);
  }
}
export default new NetworkService();
