import * as types from "../../Config/Order/OrderActionType";

export const getPlanSubscriptionRequest = (data) => {
  return {
    type: types.GET_PLAN_SUBSCRIPTION_REQUEST,
    data:data,
  };
};

export const getPlanSubscriptionSuccess = (data) => {
  return {
    type: types.GET_PLAN_SUBSCRIPTION_SUCCESS,
    data: data,
  };
};

export const getPlanSubscriptionFailure = (data) => {
  return {
    type: types.GET_PLAN_SUBSCRIPTION_FAILURE,
    data: data,
  };
};
