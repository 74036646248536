import * as types from "../../Config/Packages/PackageLabTestActionType";

export const lifestylePackageViewAllRequest = (data,search) => {
  return {
    type: types.LIFESTYLE_PACKAGE_VIEWALL_REQUEST,
    data:data,
    search:search
  };
};

export const lifestylePackageViewAllSuccess = (data) => {
  return {
    type: types.LIFESTYLE_PACKAGE_VIEWALL_SUCCESS,
    data: data,
  };
};

export const lifestylePackageViewAllFailure = (data) => {
  return {
    type: types.LIFESTYLE_PACKAGE_VIEWALL_FAILURE,
    data: data,
  };
};
