import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import backArrow from "../../../assets/hra/backArrow.png";
import hra2 from "../../../assets/hra/hra2.png";
import { GetQuestionSection2Request } from "../../../redux/Action/HealthRiskAssessment/GetQuestionSection2Action";
import { GetSection2AnswersRequest } from "../../../redux/Action/HealthRiskAssessment/GetSectionAnswers/GetSection2AnswersAction";
import { Section2AnswersSuccess } from "../../../redux/Action/HealthRiskAssessment/HRAAnswers/Section2AnswersAction";
import { SaveSectionAnswersRequest } from "../../../redux/Action/HealthRiskAssessment/SaveSectionAnswersAction";
import storage from "../../../Utils/storage";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import SingleLogin from "../Login/SingleLogin";
import {
  dropDisabled,
  pickerdiet,
  pickerdiet1,
  pickerdiet2,
  pickerdiet3,
  pickerdiet4,
} from "./HraDropdowns";
import { section2Schema } from "./ValidationSchemas";

function Section2() {
  const dispatch = useDispatch();
  const SectionValue = localStorage.getItem("SectionValue");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(GetQuestionSection2Request());
    dispatch(GetSection2AnswersRequest());
  }, []);
  const currentSectionValue = localStorage.getItem("SectionValue");
  const GetSectionAnswers = useSelector(
    (state) => state.getSectionAnswer.getSection2Answers
  );
  const [json, setJson] = useState();
  const [counter, setCounter] = useState(false);

  useEffect(() => {
    if (GetSectionAnswers?.data?.data?.data?.data !== undefined) {
      setJson(JSON.parse(GetSectionAnswers?.data?.data?.data?.data));
      setCounter(true);
    }
  }, [GetSectionAnswers?.data?.data?.data?.data]);

  window.addEventListener("popstate", function (event) {
    storage.setStorage("SectionValue", 1);
  });

  const handleSubmit = (values) => {
    if (values.Q9 === "1" || values.Q9 === "2" || values.Q9 === "3") {
      values.Q9 = "3";
    }
    if (values.Q11 === "1" || values.Q11 === "4") {
      values.Q11 = "0";
    }
    if (values.Q12 === "2" || values.Q12 === "4") {
      values.Q12 = "0";
    }
    if (values.Q13 === "1" || values.Q13 === "2" || values.Q13 === "3") {
      values.Q13 = "3";
    }
    if (values.Q14 === "1" || values.Q14 === "2" || values.Q14 === "3") {
      values.Q14 = "3";
    }

    localStorage.setItem("SectionValue", 3);
    dispatch(Section2AnswersSuccess(values));
    dispatch(SaveSectionAnswersRequest({ values, currentSectionValue }));
    navigate("/health-risk-assessment/section-2a");
  };

  const handlePrevious = () => {
    navigate("/health-risk-assessment/section-1b");
  };
  const GetQuestionSection = useSelector(
    (state) => state.getQuestion.getQuestionSection2
  );

  if (storage.getStorage("token") === null) {
    return <SingleLogin />;
  }

  if (GetQuestionSection.isLoading) {
    return <YuvaLoader />;
  }

  return (
    <>
      <ScrollToTop />

      <div className="HRAQuestionsMain">
        <div className="HRAQuestionsMainChild1">
          <p>Your Health Risk Assessment</p>
        </div>
        {SectionValue == "2" && (
          <div
            className="HRAQuestionsMainChild2"
            style={{ backgroundImage: `url(${hra2})` }}
          >
            <div className="HRAQuestionsMainChild2a">
              <div className="HRAQuestionsMainChild2a1">
                <p>GENERAL SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1 faded">
                <p>DIETARY SECTION</p>
              </div>
              {SectionValue === "2" && (
                <div className="HRAQuestionsMainChild2a2">
                  <Formik
                    initialValues={
                      !counter
                        ? {
                            Q6: "",
                            Q7: "",
                            Q8: "",
                            Q9: "",
                            Q10: "",
                            Q11: "",
                            Q12: "",
                            Q13: "",
                            Q14: "",
                          }
                        : {
                            Q6: json?.Q6.toString(),
                            Q7: json?.Q7.toString(),
                            Q8: json?.Q8.toString(),
                            Q9: json?.Q9.toString(),
                            Q10: json?.Q10.toString(),
                            Q11: json?.Q11.toString(),
                            Q12: json?.Q12.toString(),
                            Q13: json?.Q13.toString(),
                            Q14: json?.Q14.toString(),
                          }
                    }
                    validationSchema={section2Schema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                  >
                    {({ isSubmitting }) => {
                      return (
                        <Form>
                          <div className="HRAQuestionsMainChild2a2div">
                            <div className="hraBackArrow">
                              <img
                                src={backArrow}
                                onClick={handlePrevious}
                                alt="backArrow"
                              />
                              <p>Dietary Section</p>
                            </div>
                            <p className="SingleQuestion">
                              How often do you consume these foods?
                            </p>

                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection?.data?.data?.data?.data[0]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q6"
                                // defaultValue={counter ? json.Q6.toString() : ""}
                                className="select"
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerdiet.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q6"
                                component="div"
                              />
                            </label>

                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection?.data?.data?.data?.data[1]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                // defaultValue={counter ? json.Q7.toString() : ""}
                                name="Q7"
                                className="select"
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerdiet.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q7"
                                component="div"
                              />
                            </label>

                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection?.data?.data?.data?.data[2]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                // defaultValue={counter ? json.Q8 : ""}
                                name="Q8"
                                className="select"
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerdiet.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q8"
                                component="div"
                              />
                            </label>

                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection?.data?.data?.data?.data[3]
                                    .question
                                }
                              </p>
                              <Field as="select" name="Q9" className="select">
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerdiet1.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q9"
                                component="div"
                              />
                            </label>

                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection?.data?.data?.data?.data[4]
                                    .question
                                }
                              </p>
                              <Field as="select" name="Q10" className="select">
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerdiet.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q10"
                                component="div"
                              />
                            </label>

                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection?.data?.data?.data?.data[5]
                                    .question
                                }
                              </p>
                              <Field as="select" name="Q11" className="select">
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerdiet2.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q11"
                                component="div"
                              />
                            </label>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection?.data?.data?.data?.data[6]
                                    .question
                                }
                              </p>
                              <Field as="select" name="Q12" className="select">
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerdiet3.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q12"
                                component="div"
                              />
                            </label>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection?.data?.data?.data?.data[7]
                                    .question
                                }
                              </p>
                              <Field as="select" name="Q13" className="select">
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerdiet4.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q13"
                                component="div"
                              />
                            </label>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection?.data?.data?.data?.data[8]
                                    .question
                                }
                              </p>
                              <Field as="select" name="Q14" className="select">
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerdiet4.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q14"
                                component="div"
                              />
                            </label>

                            <button
                              type="submit"
                              className="HRAQuestionsButton"
                              disabled={isSubmitting}
                            >
                              Next
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        )}

        {SectionValue !== "2" && (
          <div className="hraDenied">
            Not Authorized !! please navigate to previous sections
          </div>
        )}
      </div>
    </>
  );
}

export default Section2;
