export const api = {
    connectionBetweenUs: '/network',
    viewAllHospital: '/hospital/viewAll',
    hospitalNetworkForm:'/network/hospital',
    clinicNetworkForm:'/network/clinic',
    diagnosticNetworkForm:'/network/diagnosticAndLabService',
    professionalServicesNetworkForm:'/network/professionalService',
    viewAllNetworkType: '/network/type',
    viewAllNetwork:'/network/viewAll',
    viewNetworkCount: '/network/count'
  };