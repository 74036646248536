import * as types from "../../../Config/OTP/OTPValidation/UpdateProfileOTPValidationType";

export const UpdateProfileOtpValidationRequest = (data) => {
  return {
    type: types.UPDATE_PROFILE_OTP_VALIDATION_REQUEST,
    data: data,
  };
};

export const UpdateProfileOtpValidationSuccess = (data) => {
  return {
    type: types.UPDATE_PROFILE_OTP_VALIDATION_SUCCESS,
    data: data,
  };
};

export const UpdateProfileOtpValidationFailure = (data) => {
  return {
    type: types.UPDATE_PROFILE_OTP_VALIDATION_FAILURE,
    data: data,
  };
};
