import RequestService from "../../RequestService";
import { api } from "../../../helpers/EcomUser/EcomUserConstants";

class NetworkService {
  viewAllProductCategory() {
    const endPoint = `${api.viewAllProductCategory}`
    return RequestService.getRequest(endPoint);
  }

  viewProductByCategoryId(action) {
    const endPoint = `${api.viewProductByCategoryId}/${action.id}`
    return RequestService.getRequest(endPoint);
  }

  viewProductById(action) {
    const endPoint = `${api.viewProductById}/${action.id}`
    return RequestService.getRequest(endPoint);
  }

  viewTopCategories() {
    const endPoint = `${api.viewTopCategories}`
    return RequestService.getRequest(endPoint);
  }

  viewAllProducts(action) {
    const endPoint = `${api.viewAllProducts}?pageNo=${action.pageNo}&pageSize=${action.pageSize}`
    return RequestService.postRequest(endPoint, {
      pageNo: action.pageNo,
      pageSize: action.pageSize,
      brandIdList: action.brandIdList,
      categoryIdList: action.categoryIdList,
      productIdList: action.productIdList,
      productName: action.productName,
      subCategoryIdList: action.subCategoryIdList
    });
  }

  getBrandDropdown() {
    const endPoint = `${api.getBrandDropdown}`;
    return RequestService.getRequest(endPoint);
  }

  getCategoryDropdown() {
    const endPoint = `${api.getCategoryDropdown}`;
    return RequestService.getRequest(endPoint);
  }

  getSubCategoryDropdown(action) {
    const endPoint = `${api.getSubCategoryDropdown}`;
    return RequestService.postRequest(endPoint, {
      categoryIdList: action.data
    });
  }
  viewHomeScreenProduct(){
    const endPoint = `${api.viewHomeScreenProduct}`;
    return RequestService.getRequest(endPoint);
  }
}

export default new NetworkService();
