import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GetAllRelativesRequest } from "../../../redux/Action/User/GetAllRelatives";
import "./SelectMember.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddNewMemberModal from "../../Organisms/Cart/AddNewMemberModal";
import storage from "../../../Utils/storage";

function SelectMember({ memberDetail }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [addNewMember, setAddNewMember] = useState(false);
  const [addNewMemberInfo, setAddNewMemberInfo] = useState();
  const [addNewMemberInfoIsPresent, setAddNewMemberInfoIsPresent] = useState(false);
  const [member, setMember] = useState();

  const getCart = useSelector((state) => state.cart.getCart);
  const GetAllRelatives = useSelector((state) => state.user.getAllRelatives);

  const handleRadio = (event, value) => {
    setMember(event);
    memberDetail(value.name, value.age, value.gender, value.relation, value.other);
  };

  const addNewMemberBtn = () => {
    setAddNewMember(true);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAllRelativesRequest());
  }, []);

  function NewMemberInfo(memObj) {
    if(memObj.other===true){
      setMember(-1);
      setAddNewMemberInfo(memObj);
      setAddNewMemberInfoIsPresent(true);
      memberDetail(
        memObj.name,
        memObj.age,
        memObj.gender,
        memObj.relation,
        memObj.other
      );
    }
    else{
      setAddNewMemberInfo(memObj);
      setAddNewMemberInfoIsPresent(true);
      memberDetail(
        memObj.name,
        memObj.age,
        memObj.gender,
        memObj.relation,
        memObj.other
      );
    }
    
  }
  if (
    location.state === null ||
    getCart.error !== null ||
    storage.getStorage("token") === null
  ) {
    navigate("/");
  } else {
    return (
      <div>
        <div className="AddressList">
          {addNewMember && (
            <AddNewMemberModal
              open={setAddNewMember}
              NewMemberInfo={NewMemberInfo}
            />
          )}
          <div className="AddressListUpper">
            <p className="AddressListUpperHeadingMember">
              Select  Members
            </p>
            <button
              type="button"
              className="AddressListUpperButton"
              onClick={addNewMemberBtn}
            >
              Add New Member
            </button>
          </div>{" "}
          <div className="AddressListLowerParent">

          <div className="AddressListLower"  onClick={(event) => {
                  handleRadio(-2, {
                    name: null,
                    age: null,
                    gender: null,
                    relation: null,
                    other:false,
                  });
                }}>
            <div className="AddressListLower1">
              <input
                type="radio"
                checked={member == -2}
                onChange={(event) => {
                  handleRadio(-2, {
                    name: null,
                    age: null,
                    gender: null,
                    relation: null,
                    other:false,
                  });
                }}
              />
            </div>
            <div className="AddressListLower2">
              <div
                className="AddressListLower2Name"
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                Myself
              </div>
            </div>
            <div className="AddressListLower3">
              <p className="AddressListLower3Location"></p>
              <p className="AddressListLower2Address"></p>
            </div>
          </div>
         

          {GetAllRelatives?.data?.data.data.data.map((item, index) => (
            <div className="AddressListLower"   onClick={(event) => handleRadio(item.id, {
              name: null,
              age: null,
              gender: null,
              relation: item.id,
              other:false,
            })}>
              <div className="AddressListLower1">
                <input
                  type="radio"
                  checked={member == item.id}
                  onChange={(event) => handleRadio(item.id, {
                    name: null,
                    age: null,
                    gender: null,
                    relation: item.id,
                    other:false,
                  })}
                />
              </div>
              <div className="AddressListLower2">
                <div className="AddressListLower2Name">
                  {" "}
                  {item.name} - ( {item.relation})
                </div>
                <div className="AddressListLower2Address">Age - {item.age}</div>
              </div>
              <div className="AddressListLower3 AddressListLower3Member">
                <div className="AddressListLower3Location">Gender-&nbsp; </div>
                <div className="AddressListLower2Address">{item.gender}</div>
              </div>
            </div>
          ))}
          </div>
          {addNewMemberInfoIsPresent === true ? (
            <div className="AddressListLower"  onClick={(event) => {
              handleRadio(-1, addNewMemberInfo);
            }}>
              <div className="AddressListLower1">
                <input
                  type="radio"
                  checked={member == -1}
                  onChange={(event) => {
                    handleRadio(-1, addNewMemberInfo);
                  }}
                />
              </div>
              <div className="AddressListLower2">
                <div className="AddressListLower2Name">
                  {" "}
                  {addNewMemberInfo.name} -
                  ( Other )
                </div>
                <div className="AddressListLower2Address">
                  Age - {addNewMemberInfo.age}
                </div>
              </div>
              <div className="AddressListLower3">
                <div className="AddressListLower3Location">Gender</div>
                <div className="AddressListLower2Address">
                  {addNewMemberInfo.gender}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}

export default SelectMember;