import * as types from "../../Config/Network/networkActionType"

export const viewNetworkCountRequest = () => {
    return {
        type: types.VIEW_NETWORK_COUNT_REQUEST,
    }
}

export const viewNetworkCountSuccess = (data) => {
    return {
        type: types.VIEW_NETWORK_COUNT_SUCCESS,
        data:data,
    }
}

export const viewNetworkCountFailure = (data) => {
    return {
        type: types.VIEW_NETWORK_COUNT_FAILURE,
        data:data,
    }
}