import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Network/networkActionType";
import * as actions from "../../Action/Network/professionalServicesNetworkFormAction";
import NetworkService from "../../../network/Network/Network/NetworkPageNetwork";

export function* professionalServicesNetworkFormSaga(action) {
  try {
    let response = yield call(NetworkService.professionalServicesNetworkForm, action);
    yield put(actions.professionalServiceNetworkFormSuccess(response));
  } catch (err) {
    yield put(actions.professionalServiceNetworkFormFailure(err));
  }
}

export function* watchProfessionalServicesNetworkFormSaga() {
  yield takeLatest(types.PROFESSIONAL_SERVICES_NETWORK_FORM_REQUEST, professionalServicesNetworkFormSaga);
}