export const Account = {
    MyAccount: "/account",
    MyPrescription : "/account/prescription",
    NoPrescription: "/prescription-none",
    AccountSetting : "/account-settings",
    AddFamilyMember : "/account-add-family-member",
    AddMember : "/AddMember",
    MyReports : "/account/health-risk-assessment-report",
    MyReportHra : "/MyDiagnosticReport",
    AddressBook : "/account/saved-address",
    UpdateEmailOTP: "/verify-email",
    UpdateNumberOTP: "/verify-number",
}