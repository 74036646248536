import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import eye from "../../../assets/Login/eye.png";
import { CheckNumberRequest } from "../../../redux/Action/CheckEmail/CheckNumber";
import { otpMobileRequest } from "../../../redux/Action/OTP/OTPMobile/otpMobileAction";
import "./SingleRegister.css";
import logo from "../../../assets/common/logoPrimaryNavbar.png";

function UserRegisterForm({ setChildData }) {
  const [eye1, setEye1] = useState(false);
  const [eye2, setEye2] = useState(false);
  const phoneRegExp = /^((\\+91-?)|0)?[0-9]{10}$/;

  const registerSchema = Yup.object().shape({
    number: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required Field"),
    name: Yup.string().required("Name is required"),
    password: Yup.string()
      .min(6, "Should be 6 character minimum.")
      .required("No password provided"),
    retypePassword: Yup.string()
      .oneOf([Yup.ref("password"), null], 'Must match "password" field value')
      .min(6, "Should be 6 character minimum.")
      .required("No password provided."),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function preventnumberinput(e) {
    var keycode = e.keycode ? e.keycode : e.which;
    if ((keycode > 47 && keycode < 58) || (keycode > 95 && keycode < 107)) {
      e.preventDefault();
    }
  }

  const checkMobileValue = (event) => {
    if (event.target.value.length > 5) {
      dispatch(CheckNumberRequest(event.target.value));
    }
  };

  const checkNumberSelector = useSelector(
    (state) => state.checkEmail.checkNumber
  );

  function visiblePassword() {
    setEye1(!eye1);
  }

  function visibleRetypePassword() {
    setEye2(!eye2);
  }

  const handleSubmit = (values) => {
    dispatch(otpMobileRequest(values.number));
    Swal.fire({
      title: "Please wait!!",
      text: "Generating OTP for you...",
      showCancelButton: false,
      showConfirmButton: false,
    });
    setChildData(values);
  };

  return (
    <>
      <div className="signupArea">
        <div className="LogoAreaLOGIN_SIGNUP">
          <img src={logo} alt="Logo"/>
        </div>
        <p className="signupAreaFirstHeading">Create Account</p>
        <p className="signupAreaSecondHeading">
          Create an account so you can explore
          <br /> all the existing jobs
        </p>
        <div className="signupForm">
          <Formik
            initialValues={{
              number: "",
              name: "",
              password: "",
              retypePassword: "",
            }}
            validationSchema={registerSchema}
            onSubmit={handleSubmit}
          >
            {() => {
              return (
                <Form className="SingleRegisterForm">
                  <div className="signUpBox">
                    <Field
                      type="text"
                      name="name"
                      placeholder="Name"
                      id="registerTextboxde1"
                      className="signupInputField "
                      onKeyDown={(event) => preventnumberinput(event)}
                    />
                    <ErrorMessage
                      className="errorMessageRegister"
                      name="name"
                      component="div"
                    />
                  </div>
                  <div className="signUpBox">
                    <Field
                      type="number"
                      name="number"
                      placeholder="Mobile Number"
                      className="signupInputField"
                      onBlur={checkMobileValue}
                      onWheel={(event) => event.currentTarget.blur()}
                      onKeyDown={(evt) =>
                        evt.key === "e" && evt.preventDefault()
                      }
                    />
                    <ErrorMessage
                      className="errorMessageRegister"
                      name="number"
                      component="div"
                    />
                    {checkNumberSelector?.error?.data?.response.status ===
                    302 ? (
                      <div className="errorMessageRegister">
                        Phone number already exist!{" "}
                        <a
                          href="/login"
                          style={{
                            cursor: "pointer",
                            color: "blue",
                          }}
                        >
                          Please Login!
                        </a>
                      </div>
                    ) : (
                      <div> </div>
                    )}
                  </div>

                  <div className="signUpBox">
                    <div style={{ position: "relative" }}>
                      {eye1 ? (
                        <Field
                          type="text"
                          name="password"
                          placeholder="Password"
                          id="registerTextboxde2"
                          className="signupInputField"
                        />
                      ) : (
                        <Field
                          type="password"
                          name="password"
                          placeholder="Password"
                          id="registerTextboxde2"
                          className="signupInputField"
                        />
                      )}

                      <img
                        alt="Eye"
                        src={eye}
                        onClick={visiblePassword}
                        className="useregEye"
                        id="eye1"
                        style={{
                          position: "absolute",
                          right: "10%",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>
                    <ErrorMessage
                      className="errorMessageRegister"
                      name="password"
                      component="div"
                    />
                  </div>
                  <div className="signUpBox">
                    <div style={{ position: "relative" }}>
                      {eye2 ? (
                        <Field
                          type="text"
                          name="retypePassword"
                          placeholder="Confirm Password"
                          className="signupInputField"
                          id="registerTextboxde3"
                        />
                      ) : (
                        <Field
                          type="Password"
                          name="retypePassword"
                          placeholder="Confirm Password"
                          className="signupInputField"
                          id="registerTextboxde3"
                        />
                      )}

                      <img
                        alt="Eye"
                        src={eye}
                        onClick={visibleRetypePassword}
                        id="eye2"
                        className="useregEye"
                        style={{
                          position: "absolute",
                          right: "10%",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>
                    <ErrorMessage
                      className="errorMessageRegister"
                      name="retypePassword"
                      component="div"
                    />
                  </div>

                  <button
                    type="submit"
                    className="signupInputFieldButton"
                    disabled={
                      checkNumberSelector?.error?.data?.response.status === 302
                        ? true
                        : false
                    }
                  >
                    Sign up
                  </button>
                </Form>
              );
            }}
          </Formik>
        </div>
        <p className="signupSignInTag">
          Already have an account ? &nbsp;
          <span
            style={{
              color: "#0089ED",
              cursor: "pointer",
              fontSize: "1em",
            }}
            onClick={() => navigate("/login")}
          >
            Login
          </span>
        </p>
      </div>
    </>
  );
}

export default UserRegisterForm;
