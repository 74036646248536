import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { AddCart } from "../../../Utils/addCart";
import useLocalStorageSync from "../../../hooks/useLocalStorageSync";
import { createCartClear } from "../../../redux/Action/Cart/CreateCartAction";
import ProductCard from "../../Molecules/Product/ProductCard";
import "./RelatedProduct.css";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} style={{ display: "block" }} onClick={onClick} />
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} style={{ display: "block" }} onClick={onClick} />
  );
}
function RelatedProducts({ mapData }) {
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [itemList, setItemList] = useState();

  const itemDtoList = useLocalStorageSync("itemDtoList");

  const createCart = useSelector((state) => state.cart.createCart);

  useEffect(() => {
    if (createCart.data?.data.status === 200 && itemList) {
      localStorage.setItem("itemDtoList", JSON.stringify(itemList));
      window.dispatchEvent(new Event("storage"));
    }
    return () => {
      dispatch(createCartClear());
    };
  }, [createCart.data, createCart.error, itemList, dispatch]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow className={"nextArrow"} />,
    prevArrow: <SamplePrevArrow className={"nextArrow"} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleAddCart = async (item) => {
    const data = {
      name: item.name,
      id: item.productId,
      productPriceId: item.priceId,
    };
    try {
      setIsSubmitting(true);
      setItemList(await AddCart(data, dispatch, setIsSubmitting, 1));
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <h3 className="relatedProductTitle"> Related Products </h3>
      <Slider {...settings} className="relatedProductSlider">
        {mapData.length > 0 &&
          mapData?.map((item) => (
            <ProductCard
              productContent={item}
              handleAddCart={handleAddCart}
              isSubmitting={isSubmitting}
              itemDtoList={itemDtoList}
            />
          ))}
      </Slider>
    </div>
  );
}

export default RelatedProducts;
