import * as types from "../../Config/Cart/CreateCartActionType";

export const paymentGatewayStatusRequest = (token, email) => {
  return {
    type: types.PAYMENT_GATEWAY_STATUS_REQUEST,
    token,
    email,
  };
};

export const paymentGatewayStatusSuccess = (data) => {
  return {
    type: types.PAYMENT_GATEWAY_STATUS_SUCCESS,
    data,
  };
};

export const paymentGatewayStatusFailure = (data) => {
  return {
    type: types.PAYMENT_GATEWAY_STATUS_FAILURE,
    data,
  };
};
