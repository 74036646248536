import * as types from "../../Config/User/userActionType"

export const GetAllRelativesRequest = (data) => {
    return {
        type: types.GET_RELATIVES_REQUEST,
        data:data
    }
}

export const GetAllRelativesSuccess = (data) => {
    return {
        type: types.GET_RELATIVES_SUCCESS,
        data:data,
    }
}

export const GetAllRelativesFailure = (data) => {
    return {
        type: types.GET_RELATIVES_FAILURE,
        data:data,
    }
}