import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import section1Back from "../../../assets/AdvertisementSection3CardsImages/section1Back3.png";
import section4c3 from "../../../assets/AdvertisementSection3CardsImages/section4c3.png";
import section4p2 from "../../../assets/AdvertisementSection3CardsImages/section4p2.png";
import logo from "../../../assets/common/logoPrimaryNavbar.png";
import { getCallRequest } from "../../../redux/Action/Advertisement/GetCallRequestAction";
import Title from "../../Atoms/Title/Title";
import AdvertisementBannerMobile from "./AdvertisementBannerMobile";
import { MarketingConstant } from "./AdvertisementConstant";
import "./AvertisementBanner.css";

function AvertisementBanner() {
  const [isMobile, setIsMobile] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.outerWidth < 900) {
      setIsMobile(true);
    }
  }, []);

  const navigate = useNavigate();
  const getCallFormValidation = Yup.object().shape({
    mobileNumber: Yup.string()
      .matches(/^\d{10}$/, "Please Enter Valid Number")
      .required("Mobile number is required"),
    Name: Yup.string().required("Required Field"),
    city: Yup.string().required("Required Field"),
  });

  const [index, setIndex] = useState(0);

  const handleCardClick = (index) => {
    setIndex(index);
  };

  const viewAllCity = useSelector((state) => state.city.viewAllCity);

  const handleSubmit = (values, { setSubmitting }) => {
    setIsSubmitting(true);
    dispatch(getCallRequest(values));
  };

  const getCall = useSelector((state) => state.advertisement.getCall);

  if (
    getCall?.data?.data.status === 201 &&
    getCall?.data?.data.data.message !== ""
  ) {
    Swal.fire({
      title: "Your form has been Submitted",
      icon: "success",
    }).then(() => {
      setIsSubmitting(false);
      window.location.reload();
    });
  }

  return (
    <>
      <Title title={"Yuva"} />
      {isMobile ? (
        <AdvertisementBannerMobile />
      ) : (
        <div className="AvertisementBannerMain">
          {/*section1 */}
          <div
            className="AvertisementBannerSection1"
            style={{
              backgroundImage: `url(${section1Back})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              height: "40.7rem",
              backgroundPosition: "100% 0%",
              padding: "3vw 7.63vw 0vw 7.63vw ",
              display: "flex",
            }}
          >
            <div className="AvertisementBannerSection1p1">
              <Formik
                initialValues={{
                  mobileNumber: "",
                  Name: "",
                  city: "",
                }}
                validationSchema={getCallFormValidation}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                {
                  <Form>
                    <div className="AdvertisementForm">
                      <Field
                        type="number"
                        name="mobileNumber"
                        placeholder="Enter Mobile Number*"
                        className="AdvertisementInput"
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                      <ErrorMessage
                        className="errorMessageValidation"
                        name="mobileNumber"
                        component="div"
                      />
                      <Field
                        type="text"
                        name="Name"
                        placeholder="Enter Your Name*"
                        className="AdvertisementInput"
                      />
                      <ErrorMessage
                        className="errorMessageValidation"
                        name="Name"
                        component="div"
                      />
                      <Field
                        as="select"
                        name="city"
                        className="AdvertisementInput"
                      >
                        <option value="" disabled>
                          Select Your City
                        </option>
                        {viewAllCity?.data?.data?.data.data.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        className="errorMessageValidation"
                        name="city"
                        component="div"
                      />
                      <button
                        type="submit"
                        className="AdvertisementCallButton"
                        disabled={isSubmitting}
                      >
                        Get Free Call
                      </button>
                    </div>
                  </Form>
                }
              </Formik>
              <p className="AdvertisementBannerFormTAC">
                {MarketingConstant.section1TaCHeader}
              </p>
            </div>
            <div className="AvertisementBannerSection1p2">
              <img
                src={logo}
                alt="error"
                className="AvertisementBannerMainLogo"
              />
              <h1 className="AvertisementBannerSection1p2Header2">
                {MarketingConstant.section1Header2}
              </h1>
              <h1 className="AvertisementBannerSection1p2HeaderPlus">+</h1>
              <h1 className="AvertisementBannerSection1p2Header3">
                {MarketingConstant.section1Header3}
              </h1>
            </div>
          </div>

          {/*section2 */}
          <div className="AvertisementBannerSection2">
            <h1 className="AvertisementBannerSection2Header">
              {MarketingConstant.section2Header}
            </h1>
            <button
              className="AvertisementBannerSection2Button"
              onClick={() => navigate("/talk-to-doctor")}
            >
              {MarketingConstant.section2Button}
            </button>
          </div>

          {/*section3 */}
          <div className="AvertisementBannerSection3">
            <h2 className="AvertisementBannerSection3Header">
              {MarketingConstant.section3Header}
            </h2>
            <h1 className="AvertisementBannerSection3SubHeader">
              {MarketingConstant.section3SubHeader}
            </h1>
            <div className="AvertisementBannerSection3CardWrapper">
              {MarketingConstant.section3Cards.map((item, ind) => (
                <button
                  className={
                    ind === index
                      ? "AvertisementBannerSection3CardIndividual2"
                      : "AvertisementBannerSection3CardIndividual"
                  }
                  onClick={() => {
                    handleCardClick(item.index);
                  }}
                >
                  <img src={item.icon} alt="logo"></img>
                  <p className="AvertisementBannerSection3CardText">
                    {item.name}
                  </p>
                </button>
              ))}
            </div>

            <div className="AvertisementBannerSection3Info">
              <img
                src={MarketingConstant.section3CardInfo[index].icon}
                className="AvertisementBannerSection3InfoImage"
                alt="Ventilator"
              ></img>
              <div className="AvertisementBannerSection3Infopart2">
                <h1 className="AvertisementBannerSection3InfoHeader">
                  {MarketingConstant.section3CardInfo[index].name}
                </h1>
                <p className="AvertisementBannerSection3InfoPara">
                  {MarketingConstant.section3CardInfo[index].description}
                </p>
                <button
                  className="AvertisementBannerSection3InfoButton"
                  onClick={() =>
                    navigate(
                      `${MarketingConstant.section3CardInfo[index].route}`
                    )
                  }
                >
                  {MarketingConstant.section3BodyButton}
                </button>
              </div>
            </div>
          </div>

          {/*section4 */}
          <div className="AvertisementBannerSection4">
            <div className="AvertisementBannerSection4Part1">
              <h1 className="AvertisementBannerSection4Part1Header">
                {MarketingConstant.section4Header}
              </h1>
              <h1 className="AvertisementBannerSection4Part1SubHeader">
                {MarketingConstant.section4SubHeader}
              </h1>
              {MarketingConstant.section4Points.map((item) => (
                <ul>
                  <li className="AvertisementBannerSection4Part1Points">
                    {item}
                  </li>
                </ul>
              ))}
              <button
                className="AvertisementBannerSection4Part1Button"
                onClick={() => navigate("/subscription")}
              >
                {MarketingConstant.section4Button}
              </button>
            </div>
            <div className="avp2-Wrapper">
              <div className="AvertisementBannerSection4CardIndividual">
                <img
                  src={MarketingConstant.section4Cards[0].icon}
                  alt="Badge"
                ></img>
                <h1 className="AvertisementBannerSection4CardHeader">
                  {MarketingConstant.section4Cards[0].header}
                </h1>
                <p className="AvertisementBannerSection4CardDescription">
                  {MarketingConstant.section4Cards[0].Description}
                </p>
              </div>
            </div>

            <div
              className="avp2-Wrapper2"
              style={{
                backgroundImage: `url(${section4c3})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "60%",
                backgroundPosition: "100% 0%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="AvertisementBannerSection4CardIndividual">
                <img
                  src={MarketingConstant.section4Cards[1].icon}
                  alt="Badge"
                ></img>
                <h1 className="AvertisementBannerSection4CardHeader">
                  {MarketingConstant.section4Cards[1].header}
                </h1>
                <p className="AvertisementBannerSection4CardDescription">
                  {MarketingConstant.section4Cards[1].Description}
                </p>
              </div>
            </div>
          </div>

          <div className="AvertisementBannerSection4SubPart">
            <div className="AvertisementBannerSection4SubPart1">
              <img src={section4p2} alt="Meeting"></img>
            </div>
            <div className="AvertisementBannerSection4SubPart2">
              <h1 className="AvertisementBannerSection4Part1Header">
                {MarketingConstant.section5Header}
              </h1>
              <h1 className="AvertisementBannerSection4Part1SubHeader">
                {MarketingConstant.section5SubHeader}
              </h1>
              <p className="AvertisementBannerSection4Part1Points">
                {MarketingConstant.section5Description}
              </p>
              <button
                className="AvertisementBannerSection4Part1Button"
                onClick={() => navigate("/network")}
              >
                {MarketingConstant.section4Button}
              </button>
            </div>
          </div>

          {/*section5 */}
          <div className="AvertisementBannerSection5">
            <div className="AvertisementBannerSection5Card1Wrapper">
              {MarketingConstant.section5BannerCity.map((item) => (
                <div className="AvertisementBannerSection5Card1Individual">
                  <img src={item.icon} alt="Castle"></img>
                  <h4 className="AvertisementBannerSection5Card1Header">
                    {item.name}
                  </h4>
                </div>
              ))}
            </div>

            <div className="AvertisementBannerSection5Card2Wrapper">
              {MarketingConstant.section5BannerInfo.map((item) => (
                <div className="AvertisementBannerSection5Card2Individual">
                  <h3 className="AvertisementBannerSection5Card2Number">
                    {item.number}
                  </h3>
                  <h4 className="AvertisementBannerSection5Card2Header">
                    {item.name}
                  </h4>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AvertisementBanner;
