import React, { useEffect, useState } from "react";
import group from "../../../assets/EMRM/Group.png";
import lucide_view from "../../../assets/EMRM/lucide_view.png";
import "./SelectionScreen.css";
import storage from "../../../Utils/storage";
import { useNavigate } from "react-router-dom";
import Title from "../../Atoms/Title/Title";
function SelectionScreen() {
  const navigate = useNavigate();

  if (storage.getStorage("token") === null) {
    navigate("/");
  }

  return (
    <>
      <Title title="Yuva | EMRM" />

      <div className="SelectionScreenBoxContainer">
        <div className="SelectionScreenBoxHeadingFirst">
          Electronic Record Management System
        </div>
        <div className="SelectionScreenBoxSecondContainer">
          <div className="SelectionScreenBoxfirst">
            <div
              className="SelectionScreenBoxfirstImage1"
              onClick={() => {
                navigate("/Medical-Record-Listing");
              }}
            >
              <img className="SelectionScreeneyeImage" src={lucide_view} alt="Black eye"/>
            </div>
            <div
              className="SelectionScreenTextHead"
              onClick={() => {
                navigate("/Medical-Record-Listing");
              }}
            >
              View Medical Record
            </div>
          </div>
          <div
            className="SelectionScreenBoxfirst"
            onClick={() => {
              navigate("/Medical-Record-Form");
            }}
          >
            <div className="SelectionScreenBoxfirstImage1">
              <img className="SelectionScreeneyeImage" src={group} alt="Black Pencil"/>
            </div>
            <div
              className="SelectionScreenTextHead"
              onClick={() => {
                navigate("/Medical-Record-Form");
              }}
            >
              Create Medical Record
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectionScreen;
