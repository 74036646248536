import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../../Action/HealthRiskAssessment/GetSectionAnswers/GetSection1AnswersAction";
import NetworkService from "../../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* GetSection1AnswersSaga(action) {
  try {
    let response = yield call(NetworkService.GetSection1Answers, action);
    yield put(actions.GetSection1AnswersSuccess(response));
  } catch (err) {
    yield put(actions.GetSection1AnswersFailure(err));
  }
}

export function* watchGetSection1AnswersSaga() {
  yield takeLatest(types.GET_SECTION_1_ANSWERS_REQUEST, GetSection1AnswersSaga);
}
