import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/User/userActionType";
import * as actions from "../../Action/User/resetPasswordAction";
import NetworkService from "../../../network/Network/User/userNetwork";

export function* resetPasswordSaga(action) {
  try {
    let response = yield call(NetworkService.resetPasswordService, action);
    yield put(actions.userResetPasswordSuccess (response));
  } catch (err) {
    yield put(actions.userResetPasswordFailure(err));
  }
}

export function* watchResetPasswordSaga() {
  yield takeLatest(types.RESET_PASSWORD_REQUEST, resetPasswordSaga);
}