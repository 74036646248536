import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/CheckEmail/CheckNumber";
import * as actions from "../../Action/CheckEmail/CheckNumber";
import NetworkService from "../../../network/Network/CheckEmail/CheckEmail";
export function* CheckNumberSaga(action) {

  try {
    let response = yield call(NetworkService.CheckEmail, action);
    yield put(actions.CheckNumberSuccess(response));
  } catch (err) {
    yield put(actions.CheckNumberFailure(err));
  }
}

export function* watchCheckNumberSaga() {
  yield takeLatest(types.CHECK_NUMBER_REQUEST, CheckNumberSaga);
}
