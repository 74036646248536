import * as types from "../../Config/Token/DeviceToken";

export const deviceTokenRequest = (data) => {
    return {
        type: types.DEVICE_TOKEN_REQUEST,
        data:data

    }
}

export const deviceTokenSuccess = (data) => {
    return {
        type: types.DEVICE_TOKEN_SUCCESS,
        data:data,
    }
}

export const deviceTokenFailure = (data) => {

    return {
        type: types.DEVICE_TOKEN_FAILURE,
        data:data,
    }
}