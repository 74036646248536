import * as types from "../../Config/Network/networkActionType"

export const viewAllNetworkTypeRequest = () => {
    return {
        type: types.VIEW_ALL_NETWORK_TYPE_REQUEST,
    }
}

export const viewAllNetworkTypeSuccess = (data) => {
    return {
        type: types.VIEW_ALL_NETWORK_TYPE_SUCCESS,
        data:data,
    }
}

export const viewAllNetworkTypeFailure = (data) => {
    return {
        type: types.VIEW_ALL_NETWORK_TYPE_FAILURE,
        data:data,
    }
}