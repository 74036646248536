import { all } from "redux-saga/effects";
import { watchGetSection1AnswersSaga } from "./GetSectionAnswers/GetSection1AnswersSaga";
import { watchGetSection2AnswersSaga } from "./GetSectionAnswers/GetSection2AnswersSaga";
import { watchGetSection3AnswersSaga } from "./GetSectionAnswers/GetSection3AnswersSaga";
import { watchGetSection4AnswersSaga } from "./GetSectionAnswers/GetSection4AnswersSaga";
import { watchGetSection5AnswersSaga } from "./GetSectionAnswers/GetSection5AnswersSaga";
import { watchGetSection6AnswersSaga } from "./GetSectionAnswers/GetSection6AnswersSaga";
import { watchGetSection7AnswersSaga } from "./GetSectionAnswers/GetSection7AnswersSaga";
import { watchGetSection8AnswersSaga } from "./GetSectionAnswers/GetSection8AnswersSaga";
import { watchGetSectionAnswersSaga } from "./HRAAnswers/GetSectionAnswersSaga";


function* rootGetSectionAnswerSaga() {
    yield all([
        watchGetSection1AnswersSaga(),
        watchGetSection2AnswersSaga(),
        watchGetSection3AnswersSaga(),
        watchGetSection4AnswersSaga(),
        watchGetSection5AnswersSaga(),
        watchGetSection6AnswersSaga(),
        watchGetSection7AnswersSaga(),
        watchGetSection8AnswersSaga(),
        watchGetSectionAnswersSaga(),
    ])
}

export default rootGetSectionAnswerSaga;