import * as types from "../../Config/RefreshToken/refreshTokenActionType";

export const refreshTokenRequest = (token, fcmToken) => {
  return {
    type: types.REFRESH_TOKEN_REQUEST,
    token,
    fcmToken
  };
};

export const refreshTokenSuccess = (data) => {
  return {
    type: types.REFRESH_TOKEN_SUCCESS,
    data: data,
  };
};

export const refreshTokenFailure = (data) => {
  return {
    type: types.REFRESH_TOKEN_FAILURE,
    data: data,
  };
};
