import { useState, useEffect } from 'react';

const useLocalStorageSync = (key) => {
  const [dtoList, setDtoList] = useState([]);

  useEffect(() => {
    const handleStorage = () => {
      const temp = JSON.parse(localStorage.getItem(key));
      setDtoList(temp !== null ? temp : []);
    };

    handleStorage();
    window.addEventListener("storage", handleStorage);

    return () => {
      window.removeEventListener("storage", handleStorage);
    };
  }, [key]);

  return dtoList;
};

export default useLocalStorageSync;
