import { all } from "redux-saga/effects";
import { watchViewAllAddressSaga } from "./viewAllAddressSaga";

function* rootAddressSaga() {
  yield all([
    watchViewAllAddressSaga(),
  ]);
}

export default rootAddressSaga;
