import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getPlanServicesUserDetailRequest } from "../../../redux/Action/Plan/GetPlanServicesDetailsAction";
import { getSelectedPlanRequest } from "../../../redux/Action/Plan/GetSelectedPlan";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import PackageHero from "../../Organisms/Hero/PackageHero";
import PlanContent from "../../Organisms/PopularPlans/PlanContent";
import FrequentlyAskedQuestion from "../FrequentlyAskedQuestions/FrequentlyAskedQuestion";
import "./ViewDetailsPlanServices.css";

function ViewDetailsPlanServices() {
  const dispatch = useDispatch();
  const [price, setPrice] = useState();
  const [finalCost, setFinalCost] = useState();
  const [duration, setduration] = useState();

  const search = useLocation().search;
  const PlanUuid = new URLSearchParams(search).get("id");

  useEffect(() => {
    if (PlanUuid) {
      dispatch(getSelectedPlanRequest(PlanUuid));
      dispatch(getPlanServicesUserDetailRequest(PlanUuid));
    }
  }, [dispatch, PlanUuid]);

  const getSelectedPlan = useSelector((state) => state.plan.getSelectedPlan);

  const AllData =
    getSelectedPlan?.data?.data?.data?.data.planServicesResponseDto;
  const relationsAllowed =
    getSelectedPlan?.data?.data?.data?.data?.relationsAllowed;
  const PlanName = getSelectedPlan?.data?.data?.data?.data?.name;

  useEffect(() => {
    const selectedItem = getSelectedPlan?.data?.data?.data?.data;

    if (selectedItem?.quarterlyFinalCost > 0) {
      setPrice(selectedItem?.quarterlyPrice);
      setFinalCost(selectedItem?.quarterlyFinalCost);
      setduration("Quarterly");
    } else if (selectedItem?.yearlyFinalCost > 0) {
      setPrice(selectedItem?.yearlyPrice);
      setFinalCost(selectedItem?.yearlyFinalCost);
      setduration("Yearly");
    } else if (selectedItem?.halfYearlyFinalCost > 0) {
      setPrice(selectedItem?.halfYearlyPrice);
      setFinalCost(selectedItem?.halfYearlyFinalCost);
      setduration("Half Yearly");
    }
  }, [getSelectedPlan?.data?.data?.data?.data]);

  const data = [
    {
      question: "What is Yuva Health Plans?",
      answer:
        "Yuva Health is a health  care membership  Plan for your full family. It offers OPD doctor consultations (Inclinic ), Full body health checkup  and chat with doctor along with other services on Yuva Health platform for 12 whole months for your full family of 4 members.",
    },
    {
      question: "What is an OPD doctor consultation?",
      answer:
        "An OPD (Outpatient Department) doctor consultation is a visit to a doctor's clinic for the treatment of an illness or a medical condition that does not require an overnight stay at a hospital. ",
    },
    {
      question: "What is an Inclinic consultation? ",
      answer:
        "An Inclinic consultation is a type of OPD consultation where you can visit the doctor at their clinic or hospital for a face-to-face consultation.",
    },
    {
      question: "What is a full-body health checkup? ",
      answer:
        "A full-body health checkup is a comprehensive medical examination that includes tests and screenings to assess the overall health of an individual. It typically includes tests for blood pressure, cholesterol, blood sugar, liver function, kidney function, and other important parameters. ",
    },
    {
      question:
        "How can I book an appointment for an OPD consultation or a full-body health checkup? ",
      answer:
        "You can book an appointment by visiting the Yuva Health website and selecting the service you require. ",
    },
    {
      question: "Can I consult with a doctor online?",
      answer:
        "Yes, you can also chat with a doctor online through the Yuva Health website through our talk to doctor Service. This is a convenient way to get medical advice and treatment from the comfort of your own home.",
    },
    {
      question: "How can I access my test reports? ",
      answer:
        "You can access your test reports by logging into your account on the Yuva Health website. Your reports will be available for download once they are read  ",
    },
    {
      question: "What if I have more questions about my health or treatment?",
      answer:
        "If you have more questions or concerns about your health or treatment, you can always Speak to our health advisor. They will be able to provide you with the necessary guidance and advice.  ",
    },
  ];
  
  if (getSelectedPlan?.isLoading) {
    return <YuvaLoader />;
  }

  return (
    <>
      <Helmet>
        <title>Yuva Health: Standard Plan</title>
        <meta name="description" content="Explore Yuva Health's Standard Plan offering comprehensive health checkup packages. Find a health clinic near you and discover our full body checkup options tailored to your needs. 
        Take proactive steps towards your well-being with Yuva Health's convenient and affordable healthcare solutions." />
        <meta name="keywords" content="Health checkup plans" />
      </Helmet>
      <ScrollToTop />
      <>
        {AllData ? (
          <>
            <PackageHero
              relationsAllowed={relationsAllowed}
              PlanName={PlanName}
              duration={duration}
              price={price}
              finalCost={finalCost}
            />
            <PlanContent />
          </>
        ) : (
          <></>
        )}
      </>
      <FrequentlyAskedQuestion data={data} />
    </>
  );
}

export default ViewDetailsPlanServices;
