import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Network/networkActionType";
import * as actions from "../../Action/Network/viewAllHospitalAction";
import NetworkService from "../../../network/Network/Network/NetworkPageNetwork";

export function* viewAllHospitalSaga(action) {
  try {
    let response = yield call(NetworkService.viewAllHospital, action);
    yield put(actions.viewAllHospitalSuccess(response));
  } catch (err) {
    yield put(actions.viewAllHospitalFailure(err));
  }
}

export function* watchViewAllHospitalSaga() {
  yield takeLatest(types.VIEW_ALL_HOSPITAL_REQUEST, viewAllHospitalSaga);
}