import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../../Action/HealthRiskAssessment/GetSectionAnswers/GetSection6AnswersAction";
import NetworkService from "../../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* GetSection6AnswersSaga(action) {
  try {
    let response = yield call(NetworkService.GetSection6Answers, action);
    yield put(actions.GetSection6AnswersSuccess(response));
  } catch (err) {
    yield put(actions.GetSection6AnswersFailure(err));
  }
}

export function* watchGetSection6AnswersSaga() {
  yield takeLatest(types.GET_SECTION_6_ANSWERS_REQUEST, GetSection6AnswersSaga);
}
