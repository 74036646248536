import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddCart } from "../../../Utils/addCart";
import testLogo from "../../../assets/Health_Checkups_Assests/TestLogo.png";
import packageLogo from "../../../assets/Health_Checkups_Assests/packageLogo.png";
import useLocalStorageSync from "../../../hooks/useLocalStorageSync";
import { createCartClear } from "../../../redux/Action/Cart/CreateCartAction";
import AddButton from "../../Atoms/Buttons/AddButtons/AddButton";
import AddedButton from "../../Atoms/Buttons/AddButtons/AddedButton";
import Title from "../../Atoms/Title/Title";
import createSlug from "../../Molecules/SlugGenerator/CreateSlug";

function Listing({
  mapData,
  lifeStyleMapData,
  testAndPackage,
  setPageNo,
  pageNo,
  LifestyleExpand,
  totalPages,
  lifestylePackage,
  enumName,
  displayName,
  pageSizeIncreseTest,
  searchpageTest,
  pageNoTest,
  totalPagesTest,
  pageSizeIncresePackage,
}) {
  const [pathname, setPathname] = useState(false);
  const [showMore, setShowMore] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [itemList, setItemList] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createCart = useSelector((state) => state.cart.createCart);

  useEffect(() => {
    if (createCart.data?.data.status === 200 && itemList) {
      localStorage.setItem("itemDtoList", JSON.stringify(itemList));
      window.dispatchEvent(new Event("storage"));
    }
    return () => {
      dispatch(createCartClear());
    };
  }, [createCart.data, createCart.error, itemList, dispatch]);

  const itemDtoList = useLocalStorageSync("itemDtoList");

  const handleAddCart = async (item) => {
    try {
      setIsSubmitting(true);
      setItemList(await AddCart(item, dispatch, setIsSubmitting, 1));
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const handleClick = () => {
    if (lifestylePackage === "LIFESTYLE") {
      navigate(`/SearchPackage/${enumName}/${displayName}`);
    } else {
      localStorage.setItem(
        "viewEvery",
        JSON.stringify({
          radioItem: "NO_RANGE",
          minPrice: 0,
          maxPrice: 10000,
          allChecked: [testAndPackage],
        })
      );
      navigate("/view-test");
    }
  };

  const handleMoreClick = (e) => {
    if (searchpageTest === "searchpageTest") {
      pageSizeIncreseTest("clicked");
    } else if (searchpageTest === "searchpagepackage") {
      pageSizeIncresePackage("clicked");
    } else {
      setPageNo((prevPage) => prevPage + 1);
      if (pageNo === totalPages) {
        setShowMore(false);
      }
    }
  };

  useEffect(() => {
    if (window.location.pathname === "/view-test") setPathname(true);
  }, []);

  const handleNavigate = async (isTest, id, name) => {
    const slug = await createSlug(name);
    if (isTest) {
      navigate(`/test/${slug}?id=${id}`);
    } else {
      navigate(`/package/${slug}?id=${id}`);
    }
  };

  return (
    <>
      <Title title="YUVA | Health Checkup and Diagnostic" />
      <div className={pathname ? "FHCMainTest" : "FHCMain"}>
        <div className={pathname ? "FHCSection2Test" : "FHCSection2"}>
          {mapData?.length > 0 ? (
            <>
              {mapData.map((item, index) => (
                <div className="FHCSection2_Children" key={index}>
                  <img
                    alt="Logo"
                    src={item.test === true ? testLogo : packageLogo}
                    className="logoImageListing"
                  />
                  <div
                    className="FHCSection2_Children1"
                    onClick={() =>
                      handleNavigate(item.test, item.id, item.name)
                    }
                  >
                    <div className="FHCSectionContainerFirst">
                      <span className="FHCSection2_ChildrenName">
                        {item.name}
                      </span>
                    </div>
                    <p>{item.description}</p>
                  </div>
                  <div className="FHCSection2_Children2">
                    <p>
                      <span className="FHCSection2_Children2OldPrice">
                        {" "}
                        {item.originalPrice === item.finalPrice
                          ? ""
                          : `₹ ${item.originalPrice}`}
                      </span>
                      {""}
                      &nbsp;{" "}
                      <span className="FHCSection2_Children2OldDiscountedPrice">
                        ₹&nbsp;{item.finalPrice}/-
                      </span>
                    </p>
                  </div>
                  <div className="FHCSection2_Children3">
                    {itemDtoList?.length > 0 &&
                    itemDtoList.filter(
                      (x) =>
                        x.productId.toString() === item.id.toString() && x.productType !== "PRODUCT"
                    ).length > 0 ? (
                      <AddedButton />
                    ) : (
                      <AddButton
                        disable={isSubmitting}
                        onClick={() => handleAddCart(item)}
                      />
                    )}
                  </div>
                </div>
              ))}
              {testAndPackage === "testAndPackage" ||
              LifestyleExpand === "LifestyleExpand" ||
              searchpageTest === "searchpageTest" ||
              searchpageTest === "searchpagepackage" ? (
                (showMore || pageNo !== totalPages) && (
                  <div className="FHCSection3">
                    <p onClick={() => handleMoreClick()}>Load More</p>
                  </div>
                )
              ) : (
                <div className="FHCSection3">
                  <p onClick={() => handleClick()}>View More</p>
                </div>
              )}
            </>
          ) : (
            <div className="FHCSection3">
              <p>No Results Found</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Listing;
