import * as types from "../../Config/ERM/MedicalRecordListingConfig"

export const viewMedicalRecordRequest = (page,pageSize,search,selectedValue) => {
    return {
        type: types.VIEW_MEDICAL_RECORD_REQUEST,
        page,
        pageSize,
        search,
        selectedValue
        
    }
}

export const viewMedicalRecordSuccess = (action) => {
    return {
        
        type: types.VIEW_MEDICAL_RECORD_SUCCESS,
        action
    }
}

export const viewMedicalRecordFailure = (action) => {
    return {
        type: types.VIEW_MEDICAL_RECORD_FAILURE,
        action
    }
}