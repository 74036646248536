import * as types from "../../Config/Cart/CreateCartActionType"

export const createCartRequest = (data) => {
    return {
        type: types.CREATE_CART_REQUEST,
        data,
    }
}

export const  createCartSuccess = (data) => {
    return {
        type: types.CREATE_CART_SUCCESS,
        data
    }
}

export const  createCartFailure = (data) => {
    return {
        type: types.CREATE_CART_FAILURE,
        data
    }
}


export const  createCartClear = () => {
    return {
        type: types.CREATE_CART_CLEAR
    }
}