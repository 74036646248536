import RequestService from "../../../RequestService";
import { api } from "../../../../helpers/OTP/OTPMobile/otpMobileConstant";

class NetworkService {
  generateMobileOtp(action) {
    const endPoint = `${api.generateMobileOtp}?number=${action.data}`;
    return RequestService.getRequest(endPoint);
  }
}

export default new NetworkService();