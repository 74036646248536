import { combineReducers } from "redux";
import { getAllOrderHistoryReducer } from "./getAllOrderHistoryReducer";
import { getOrderDetailReducer } from "./getOrderDetailReducer";
import { getPlanSubscriptionReducer } from "./getPlanSubscriptionReducer";
import { getProgramSubscriptionReducer} from "./GetProgramSubscriptionReducer";



export default combineReducers({
    getAllOrderHistory: getAllOrderHistoryReducer,
    getOrderDetail: getOrderDetailReducer,
    getPlanSubscription: getPlanSubscriptionReducer,
    getProgramSubscription:getProgramSubscriptionReducer,
})