import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { lifestylePackageViewAllRequest } from "../../../redux/Action/Packages/LifeStylePackageViewAllAction";
import "./HealthCheckupCatagory.css";

function HealthCheckupCatagory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(lifestylePackageViewAllRequest(false));
  }, []);

  const lifestylePackageViewAll = useSelector(
    (state) => state.package.lifestylePackageViewAll
  );

  function handlePackage(enumName, displayName) {
    navigate(`/SearchPackage/${enumName}/${displayName}`);
  }

  return (
    <div className="HealthCheckupCatagoryParent">
      <div className="HealthCheckupCatagoryUpper">HEALTH CHECKUP CATEGORY</div>
      <div className="HealthCheckupCatagoryLower">
        {lifestylePackageViewAll.data?.data.data.data.map((item) => (
          <div className="HealthCheckupCatagoryChildren">
            <p>{item.displayName}</p>
            {/* <div>{item.description}</div> */}
            <div>
              <button
                onClick={() => handlePackage(item.enumName, item.displayName)}
              >
                View all Package
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HealthCheckupCatagory;
