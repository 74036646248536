export const JOIN_NETWORK_REQUEST = "JOIN_NETWORK_REQUEST"
export const JOIN_NETWORK_SUCCESS = "JOIN_NETWORK_SUCCESS"
export const JOIN_NETWORK_FAILURE = "JOIN_NETWORK_FAILURE"

export const NEWSLETTER_SIGNUP_REQUEST = "NEWSLETTER_SIGNUP_REQUEST"
export const NEWSLETTER_SIGNUP_SUCCESS = "NEWSLETTER_SIGNUP_SUCCESS"
export const NEWSLETTER_SIGNUP_FAILURE = "NEWSLETTER_SIGNUP_FAILURE"

export const GET_POPULAR_PACKAGE_REQUEST = "GET_POPULAR_PACKAGE_REQUEST"
export const GET_POPULAR_PACKAGE_SUCCESS = "GET_POPULAR_PACKAGE_SUCCESS"
export const GET_POPULAR_PACKAGE_FAILURE = "GET_POPULAR_PACKAGE_FAILURE"

export const GET_POPULAR_TEST_REQUEST = "GET_POPULAR_TEST_REQUEST"
export const GET_POPULAR_TEST_SUCCESS = "GET_POPULAR_TEST_SUCCESS"
export const GET_POPULAR_TEST_FAILURE = "GET_POPULAR_TEST_FAILURE"

export const GET_POPULAR_PLAN_REQUEST = "GET_POPULAR_PLAN_REQUEST"
export const GET_POPULAR_PLAN_SUCCESS = "GET_POPULAR_PLAN_SUCCESS"
export const GET_POPULAR_PLAN_FAILURE = "GET_POPULAR_PLAN_FAILURE"

export const GET_POPULAR_PLAN_HOMESCREEN_REQUEST = "GET_POPULAR_PLAN_HOMESCREEN_REQUEST"
export const GET_POPULAR_PLAN_HOMESCREEN_SUCCESS = "GET_POPULAR_PLAN_HOMESCREEN_SUCCESS"
export const GET_POPULAR_PLAN_HOMESCREEN_FAILURE = "GET_POPULAR_PLAN_HOMESCREEN_FAILURE"

export const GET_POPULAR_PACKAGE_HOMESCREEN_REQUEST = "GET_POPULAR_PACKAGE_HOMESCREEN_REQUEST"
export const GET_POPULAR_PACKAGE_HOMESCREEN_SUCCESS = "GET_POPULAR_PACKAGE_HOMESCREEN_SUCCESS"
export const GET_POPULAR_PACKAGE_HOMESCREEN_FAILURE = "GET_POPULAR_PACKAGE_HOMESCREEN_FAILURE"

export const GET_POPULAR_TEST_HOMESCREEN_REQUEST = "GET_POPULAR_TEST_HOMESCREEN_REQUEST"
export const GET_POPULAR_TEST_HOMESCREEN_SUCCESS = "GET_POPULAR_TEST_HOMESCREEN_SUCCESS"
export const GET_POPULAR_TEST_HOMESCREEN_FAILURE = "GET_POPULAR_TEST_HOMESCREEN_FAILURE"
