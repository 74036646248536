import * as types from "../../Config/Plan/PlanActionType";

export const getPlanServicesUserDetailRequest = (data) => {
  return {
    type: types.GET_PLAN_SERVICES_DETAIL_REQUEST,
    data:data,
  };
};

export const getPlanServicesUserDetailSuccess = (data) => {
  return {
    type: types.GET_PLAN_SERVICES_DETAIL_SUCCESS,
    data: data,
  };
};

export const getPlanServicesUserDetailFailure = (data) => {
  return {
    type: types.GET_PLAN_SERVICES_DETAIL_FAILURE,
    data: data,
  };
};
