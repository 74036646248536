import * as types from "../../Config/Advertisement/GetCallRequestActionType"

export const getCallRequest = (data) => {
    return {
        type: types.GET_CALL_REQUEST,
        data:data,
    }
}

export const  getCallSuccess = (data) => {
    return {
        type: types.GET_CALL_SUCCESS,
        data
    }
}

export const  getCallFailure = (data) => {
    return {
        type: types.GET_CALL_FAILURE,
        data
    }
}