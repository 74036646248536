import * as types from "../../Config/Account/ApplyForKYCActionType";

export const ApplyForKYCRequest = (data) => {
  return {
    type: types.APPLY_FOR_KYC_REQUEST,
   data:data,
  };
};

export const ApplyForKYCSuccess = (data) => {
  return {
    type: types.APPLY_FOR_KYC_SUCCESS,
    data:data,
  };
};

export const ApplyForKYCFailure = (data) => {
  return {
    type: types.APPLY_FOR_KYC_FAILURE,
    data:data,
  };
};
