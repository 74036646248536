import * as types from "../../Config/Cart/CreateCartActionType";

export const getCartRequest = (data) => {
  return {
    type: types.GET_CART_REQUEST,
    data
  };
};

export const getCartSuccess = (data) => {
  return {
    type: types.GET_CART_SUCCESS,
    data,
  };
};

export const getCartFailure = (data) => {
  return {
    type: types.GET_CART_FAILURE,
    data,
  };
};
