import DownloadIcon from "@mui/icons-material/Download";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import prescriptionFile from "../../../assets/MyAccount/prescriptionFile.png";
import { myReportRequest } from "../../../redux/Action/Account/MyReportAction";
import storage from "../../../Utils/storage";
import Title from "../../Atoms/Title/Title";
import "./MyPrescription.css";
import "./MyReport.css";
import NoDiagnosticReport from "./NoDiagnosticReport";

function MyReportHra() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(myReportRequest());
  }, [dispatch]);

  function downloadHandles(fileLink) {
    const link = document.createElement("a");
    link.href = fileLink;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
  }
  
  const navigate = useNavigate();

  const myReport = useSelector((state) => state.account.myReport);

  if (storage.getStorage("token") === null) {
    navigate("/");
  }

  return (
    <div className="mainBlockk">
      <Title title="YUVA | Diagnostic Reports" />

      {myReport?.data?.data?.data?.data?.length === 0 ? (
        <NoDiagnosticReport />
      ) : (
        <>
          <div className="hraHeading">MY DIAGNOSTIC REPORTS</div>
          <div className="myprescriptionmaindiv">
            <div className="myprescriptionalltexts">
              <div className="myprescriptionalltextbtn">
                <div className="myprescriptionalltextget">
                  Get a comprehensive understanding about your health through
                  Yuva Health Reports.
                </div>
              </div>

              <div className="templateNamesPres">
                {myReport?.data?.data?.data.data.map((item, index) => (
                  <div className="prescriptionBoxTexts">
                    <div className="prescriptionBoxTextsStarts">
                      <img
                        className="prescriptionFile"
                        src={prescriptionFile}
                        alt="Prescription File"
                      ></img>

                      <div className="presTexting">
                        <div className="prescriptionpee">
                          Booking Id-{item.bookingId}
                        </div>
                        <div className="prescript2">{item.name}</div>
                      </div>
                    </div>
                    <div className="DownloadPresc">
                      <li className="nav_item dropdown">
                        <div
                          className="nav-link dropdown-toggle "
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{ color: "#39466C", gap: "1px" }}
                        >
                          Download
                        </div>

                        <div
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          {myReport?.data?.data?.data?.data[
                            index
                          ].attachmentResponseDtoList.map((item, index) => (
                            <div className="dropdown-itemNew">
                              <div
                                className="downloadBlock"
                                onClick={() => downloadHandles(item.filePath)}
                              >
                                <div
                                  style={{
                                    marginRight: "1vw",
                                  }}
                                >
                                  <DownloadIcon />
                                </div>

                                <div className="downloadItemName">
                                  {item.fileName}
                                </div>

                                <div className="downloadItemDate">
                                  {new Date(item.generatedAt).toLocaleString()}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </li>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default MyReportHra;
