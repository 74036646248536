import React from "react";
import dot from "../../../assets/Health_Checkups_Assests/dot.png";
import group1 from "../../../assets/Package/group1.png";
import group2 from "../../../assets/Package/group2.png";
import group3 from "../../../assets/Package/group3.png";
import group4 from "../../../assets/Package/group4.png";
import downArrow from "../../../assets/SubcriptionPageAssets/DownArrow.png";
import upArrow from "../../../assets/SubcriptionPageAssets/UpArrow.png";
import "./aboutPackage.css";

export default function AboutPackage({
  data,
  handleClick,
  selectedCollapse,
  mapData,
}) {
  return (
    <div className="aboutPackage">
      <span className="aboutPackageHead">
        About the {data?.packageName ? <>Package</> : <>Test</>}
      </span>
      <div className="aboutPackageSubHeading">{data?.description}</div>
      <div className="aboutPackageLabTestHead">
        {data?.parameterCount === 0 ? "" : `${data?.parameterCount} Lab Tests`}
        {data?.packageName && <> in this package</>}
      </div>
      {data?.parameterCount === 0 ? "" :
        <div className="aboutPackageLabGrid">
          {mapData?.map((itm, index) => (
            <div>
              <div
                className={
                  selectedCollapse === index
                    ? "package-card-headnew"
                    : "package-card-head"
                }
                style={
                  (itm.parameterCount !== 0 && itm.parameterCount !== undefined && itm.parameterCount !== null)
                    ? { cursor: "pointer" }
                    : { cursor: "" }
                }
                key={index}
                onClick={() => data?.packageName && handleClick(index)}
              >
                <div>
                  {itm.attributeName !== undefined
                    ? itm.attributeName
                    : itm.parameterName}
                  {itm.parameterCount !== 0 && itm.parameterCount
                    ? `-  ${itm.parameterCount} Tests`
                    : ""}
                </div>
                {itm.parameterCount !== 0 && itm.parameterCount  && (
                  <img
                    className="dropdownImage"
                    src={selectedCollapse === index ? upArrow : downArrow}
                    onClick={() => data?.packageName && handleClick(index)}
                    alt="Arrow"
                  />
                )}
              </div>
              {selectedCollapse === index && (
                <div className="Collapsiblen">
                  {itm.parameters.map((param, i) => (
                    <p className="collapseP" key={i}>
                      <div className="ParamterCountDisplay">
                        {param.parameterName === "" ? (
                          <></>
                        ) : (
                          <img className="dotPackage" src={dot} alt="Dot"></img>
                        )}
                        <span className="ParamterCountDisplaydata">
                          {param.parameterName === "" ? (
                            <>No data</>
                          ) : (
                            <>{param.parameterName}</>
                          )}
                        </span>
                      </div>
                    </p>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      }
      <div className="aboutPackageHead">Why to Book With Us</div>
      <div className="aboutPackageSubHeading">
        Yuvahealth is a healthcare service provider that offers a range of
        medical tests, including blood tests and urine tests. If you are
        considering booking a test with Yuvahealth, here are some reasons why
        you should choose their services:
      </div>
      <div className="WhyToBookWithUsContainer">
        <div className="careerImg">
          <img className="aboutPackageCareerImage" src={group1} alt="Shield"></img>
          <div className="WhyToBookWithUshead">
            Qualified and Experienced Medical Professionals
          </div>
        </div>

        <div className="careerImg">
          <img className="aboutPackageCareerImage" src={group2} alt="Test tube"></img>
          <div className="WhyToBookWithUshead">
            Convenient Home Sample Collection
          </div>
        </div>
        <div className="careerImg">
          <img className="aboutPackageCareerImage" src={group3} alt="Stopwatch"></img>
          <div className="WhyToBookWithUshead"> Quick Turnaround Time</div>
        </div>
        <div className="careerImg">
          <img className="aboutPackageCareerImage" src={group4} alt="Tags"></img>
          <div className="WhyToBookWithUshead">Affordable Prices</div>
        </div>
      </div>

      <div style={{ marginBottom: "5vw" }} className="aboutPackageSubHeading">
        In conclusion, if you are looking for a reliable, convenient, and
        affordable healthcare service provider for your medical tests,
        Yuvahealth may be the right choice for you. Their qualified and
        experienced medical professionals, convenient home sample collection,
        advanced technology and equipment, quick turnaround time, and affordable
        prices make them a top choice in the healthcare industry.
      </div>
    </div>
  );
}
