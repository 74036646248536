import * as types from "../../Config/Landing/landingActionType"

export const getPopularPackageRequest = (data) => {
    return {
        type: types.GET_POPULAR_PACKAGE_REQUEST,
        data:data
    }
}

export const getPopularPackageSuccess = (data) => {
    return {
        type: types.GET_POPULAR_PACKAGE_SUCCESS,
        data:data,
    }
}

export const getPopularPackageFailure = (data) => {
    return {
        type: types.GET_POPULAR_PACKAGE_FAILURE,
        data:data,
    }
}