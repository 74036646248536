import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import HomeAddressIcon from "../../../assets/MyAccount/homeAddress.png";
import OfficeAddressIcon from "../../../assets/MyAccount/OfficeAddress.png";
import { viewAllAddressRequest } from "../../../redux/Action/Address/viewAllAddressAction";
import AddAddressForm from "../../Organisms/AddAddress/AddAddressForm";
import Footer from "../../Organisms/Footer/Footer";
import AddAddressModal from "./AddAddressModal";
import "./Checkout.css";
import storage from "../../../Utils/storage";
import Title from "../../Atoms/Title/Title";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";


function CheckoutPlan() {
  const [address, setAddress] = useState();
  const [addAddress, setAddAddress] = useState(false);
  const [radioItem, setRadioItem] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(viewAllAddressRequest());
  }, []);

  const viewAllAddress = useSelector((state) => state.address.viewAllAddress);

  const handleAddAddress = () => {
    setAddAddress(true);
  };
 

  const handleRadio = (event, value) => {
    setRadioItem(value);
    setAddress(event);
  };
  const handleConfirm = () => {
    if (radioItem !== undefined) {
      navigate("/cart-payment-plan", {
        state: {
          address: radioItem.address,
          cityId: radioItem.cityId,
          contactNumber: radioItem.contactNumber,
          pinCode: radioItem.pinCode,
          plan: true,
          cityName: radioItem.cityName,
          contactName: radioItem.contactName,
          away: radioItem.away,
        },
      });
    } else {
      swal({
        title: "Please Select An Address",
        icon: "error",
      });
    }
  };

  if (storage.getStorage("token") === null) {
    navigate("/");
  }

  return (
    <>
      <ScrollToTop />
    
  
    <div className="checkoutMain">
      <Title title="Yuva | Checkout" />
      <div className="checkoutBody">
        {viewAllAddress.data?.data.data.data.length <= 0 ? (
          <AddAddressForm location={location.state} />
        ) :
        (
          <div className="AddressSelectArea">
          <div className="AddressList">
            {addAddress && (
              <AddAddressModal
                addressOpen={setAddAddress}
              />
            )}
            <div className="AddressListUpper">
              <p className="AddressListUpperHeading">Select Address</p>
              <button
                type="button"
                className="AddressListUpperButton"
                onClick={handleAddAddress}
              >
                Add New Address
              </button>
            </div>
            {viewAllAddress?.data?.data.data.data.map((item, index) => (
              <div className="AddressListLower"   onClick={(event) => handleRadio(index, item)}>
                <div className="AddressListLower1">
                  <input
                    type="radio"
                    value={index}
                    checked={address == index}
                    onChange={(event) => handleRadio(index, item)}
                  />
                </div>
                <div className="AddressListLower2">
                  <p className="AddressListLower2Name">{item.contactName}</p>
                  <p className="AddressListLower2Address">
                    {item.address}, {item.cityName}, {item.pinCode}
                  </p>
                </div>
                <div className="AddressListLower3">
                  <p className="AddressListLower3Location">
                    {item.away === false ? "Home" : "Office"}
                  </p>
                  <p className="AddressListLower2Address">
                    {item.contactNumber}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="AddressListConfirmButtonDiv">
          <button className="AddressListConfirmButton" onClick={handleConfirm}>
                Confirm Details
              </button>
              </div>
        </div>
        )}
      </div>
    </div>
    </>
  );
}

export default CheckoutPlan;
