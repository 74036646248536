import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/OTP/generateEmailOtpActionType";
import * as actions from "../../Action/OTP/generateEmailOtpAction";
import NetworkService from "../../../network/Network/OTP/generateEmailOtpNetwork";

export function* generateEmailOtp(action) {
  try {
    let response = yield call(NetworkService.generateEmailOtp, action);
    yield put(actions.generateEmailOtpSuccess(response));
  } catch (err) {
    yield put(actions.generateEmailOtpFailure(err));
  }
}

export function* watchGenerateEmailOtp() {
  yield takeLatest(types.GENERATE_EMAIL_OTP_REQUEST, generateEmailOtp);
}