import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Network/networkActionType";
import * as actions from "../../Action/Network/clinicNetworkFormAction";
import NetworkService from "../../../network/Network/Network/NetworkPageNetwork";

export function* clinicNetworkFormSaga(action) {
  try {
    let response = yield call(NetworkService.clinicNetworkForm, action);
    yield put(actions.clinicNetworkFormSuccess(response));
  } catch (err) {
    yield put(actions.clinicNetworkFormFailure(err));
  }
}

export function* watchClinicNetworkFormSaga() {
  yield takeLatest(types.CLINIC_NETWORK_FORM_REQUEST, clinicNetworkFormSaga);
}