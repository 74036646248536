import * as types from "../../Config/TellAboutYourselfPage/TellAboutYourselfType"

export const tellAboutYourselfRequest = (data) => {
    return {
        type: types.TELL_ABOUT_YOURSELF_PAGE_REQUEST,
        data:data

    }
}

export const tellAboutYourselfSuccess = (data) => {
    return {
        type: types.TELL_ABOUT_YOURSELF_PAGE_SUCCESS,
        data:data,
    }
}

export const tellAboutYourselfFailure = (data) => {

    return {
        type: types.TELL_ABOUT_YOURSELF_PAGE_FAILURE,
        data:data,
    }
}