import CloseIcon from "@mui/icons-material/Close";
import CryptoJS from "crypto-js";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { getOnMoodNineRequest } from "../../../redux/Action/MentalWellness/OnMoodNineAction";
import { GetAllRelativesRequest } from "../../../redux/Action/User/GetAllRelatives";
import MentalWellnessIframe from "./MentalWellnessIframe";
import "./MentalWellnessMember.css";

function MentalWellnessMember({
  proceedButton,
  closeMentalWellness,
  setProceedButton,
}) {
  const loginSchema = Yup.object().shape({
    Member: Yup.string().trim().required("Required Field"),
  });
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [url, setUrl] = useState("");

  const onMoodNine = useSelector((state) => state.onMoodNine.onMoodNine);

  useEffect(() => {
    if (onMoodNine.error !== null) {
      Swal.fire({
        title: `${onMoodNine?.error?.data?.response?.data.errorMessage}`,
        icon: "error",
      }).then(() => {
        window.location.reload();
      });
    }
  }, [onMoodNine]);


  const handleSubmit = (values) => {
    setIsSubmitting(true);
    const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_ONMOOD9_KEY);
    const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_ONMOOD9_IV);
    var input = "";
    if (values.Member === "Myself") {
      input = `user_id=${onMoodNine.data?.data.data.data.userId}&status=${onMoodNine.data?.data.data.data.paymentStatus}&start_date=${onMoodNine.data?.data.data.data.startDate}&end_date=${onMoodNine.data?.data.data.data.endDate}`;
    } else {
      input = `user_id=${onMoodNine.data?.data.data.data.userId}#${values.Member}&status=${onMoodNine.data?.data.data.data.paymentStatus}&start_date=${onMoodNine.data?.data.data.data.startDate}&end_date=${onMoodNine.data?.data.data.data.endDate}`;
    }
    const encrypted = CryptoJS.AES.encrypt(input, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    });

    const ciphertext = encodeURIComponent(encrypted.toString());

    if (onMoodNine.data?.data.data.data.sub_cunique_id == null) {
      setUrl(
        `https://mood9.com/#/partner-login/p/web/${process.env.REACT_APP_ONMOOD9_CORPORATEID}/${ciphertext}`
      );
    } else {
      setUrl(
        `https://mood9.com/#/partner-login/sc/web/${onMoodNine.data?.data.data.data.sub_cunique_id}/${ciphertext}`
      );
    }

    setIsSubmitting(false);
    setProceedButton(false);
  };

  useEffect(() => {
    dispatch(GetAllRelativesRequest());
    dispatch(getOnMoodNineRequest());
  }, [dispatch]);

  const GetAllRelatives = useSelector((state) => state.user.getAllRelatives);

  function closeMWModal() {
    closeMentalWellness();
  }

  if (proceedButton === false) {
    return <MentalWellnessIframe url={url} />;
  }

  return (
    <div>
      <div className="MentalHealthMemberContainerMain">
        <div className="MentalHealthMemberContainerBox">
          <div className="MentalHealthMemberContainerBoxOuter">
            <div className="MentalWellnessmodalHeading">
              Select Member
              <CloseIcon className="closeButtonMW" onClick={closeMWModal} />
            </div>
          </div>
          <div className="MentalHealthMemberSelectInput1">
            <Formik
              initialValues={{
                Member: "",
              }}
              enableReinitialize={true}
              validationSchema={loginSchema}
              onSubmit={handleSubmit}
            >
              {(formProps) => (
                <Form>
                  <div className="MentalHealthMemberSelectInput">
                    <div className="MentalHealthMemberFirstHeading">Member</div>

                    <label>
                      <Field
                        name="Member"
                        as="select"
                        placeholder="Select Member"
                        className="wellnessinputselect"
                      >
                        <option value="" disabled selected>
                          Select Member
                        </option>
                        <option value="Myself">Myself</option>
                        {GetAllRelatives?.data?.data?.data?.data?.map(
                          (item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          )
                        )}
                      </Field>
                      <ErrorMessage
                        name="Member"
                        className="mentalwellnessValidation"
                        component="div"
                      />
                    </label>
                  </div>
                  <div className="mentalwellnessButtonProceed">
                    <button
                      className="mentalWellnessSaveRealtions"
                      disabled={isSubmitting}
                    >
                      Proceed
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MentalWellnessMember;
