import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

const initialState = {
    data: null,
    isLoading: false,
    error: null
}

export const getQuestionSectionReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.GET_QUESTION_SECTION_SUCCESS:
            return {
                ...initialState,
                data: action,
            }
        case types.GET_QUESTION_SECTION_FAILURE:
            return {
                ...initialState,
                error: action
            }
        case types.GET_QUESTION_SECTION_REQUEST:
            return {
                ...initialState,
                isLoading: true
            }
        default:
            return state
    }
}