import React, { useContext, useState } from "react";
import myAccountIcon1 from "../../../assets/MyAccount/myAccountIcon1.png";
import myAccountIcon2 from "../../../assets/MyAccount/myAccountIcon2.png";
import myAccountIcon3 from "../../../assets/MyAccount/myAccountIcon3.png";
import myAccountIcon4 from "../../../assets/MyAccount/myAccountIcon4.png";
import { UserContext } from "../../../App";
import storage from "../../../Utils/storage";
import nm7 from "../../../assets/MyAccount/nm7.png";
import "./Hamburger.css";

function Hamburger(props) {
  const role = useContext(UserContext);

  const [ans1, setAns1] = useState(false);
  const [ans, setAns] = useState(false);
  const name = storage.getStorage("name");
  const handleAns1 = () => setAns1(!ans1);
  const handleAns = () => setAns(!ans);
  const handleLogout = () => {
    storage.removeStorage("sessionToken");
    storage.removeStorage("token");
    storage.removeStorage("name");
    storage.removeStorage("authorization");
    storage.removeStorage("id");
    storage.removeStorage("profileUpdated");
    window.location.reload();
  };

  const handleClose = () => {
    props.open(false);
  };

  return (
    <div className="menuBody">
      <div className="lists">
        <div className="MenuClose">
          <a href="/">
            <div className="menuBtns1" onClick={""}>
              Home
            </div>
          </a>
          <div style={{ marginTop: "1.7em" }} onClick={handleClose}>
            x
          </div>
        </div>

        <div>
          <div className="menuBtns" onClick={handleAns}>
            Services
          </div>
        </div>
        {ans && (
          <>
            <a href="/consultation/cashless-opd">
              <div className="menuBtns2" onClick={""}>
                OPD Consultation Program
              </div>
            </a>
            <a href="/talk-to-doctor">
              <div className="menuBtns2" onClick={""}>
                Talk to Doctor
              </div>
            </a>
            <a href="/view-test">
              <div className="menuBtns2" onClick={""}>
                Health CheckUp & Diagnostics
              </div>
            </a>
            <a
              href={
                storage.getStorage("token") === null
                  ? "/health-risk-assessment"
                  : "/health-risk-assessment-locked"
              }
            >
              <div className="menuBtns2" onClick={""}>
                Health Risk Assessment
              </div>
            </a>
            <a href="/rental-equipment">
              <div className="menuBtns2" onClick={""}>
                Rental Equipment
              </div>
            </a>
            <a href="/EMRM">
              <div className="menuBtns2" onClick={""}>
                EMRM
              </div>
            </a>
          </>
        )}
        <a href="/mental-wellness">
          <div className="menuBtns" onClick={""}>
            Mental Wellness
          </div>
        </a>
        <a href="/subscription">
          <div className="menuBtns" onClick={""}>
            Subscribe
          </div>
        </a>
        <a href="/product">
          <div className="menuBtns" onClick={""}>
            Products
          </div>
        </a>
        <a href="/network">
          <div className="menuBtns" onClick={""}>
            Network
          </div>
        </a>
        <div>
          <div className="menuBtns3" onClick={handleAns1}>
            Company
          </div>
        </div>
        {ans1 && (
          <>
            <a href="/about-us">
              <div className="menuBtns2" onClick={""}>
                About Us
              </div>
            </a>
            <a href="/career">
              <div className="menuBtns2" onClick={""}>
                Career
              </div>
            </a>
          </>
        )}
        <div className="navLine">_____________________________________</div>
        <div className="menuBtnsName">{name}</div>

        {role ? (
          <>
            <div className="MblNavigationBody">
              <a className="dropdown-itemMbl" href="/account">
                {" "}
                <img alt="My Account" className="iconProfile" src={myAccountIcon1}></img>
                My Account
              </a>
              <a
                className="dropdown-itemMbl"
                href="/account/health-risk-assessment-report"
              >
                {" "}
                <img alt="Hra Report" className="iconProfile" src={myAccountIcon3}></img>
                My HRA Reports
              </a>{" "}
              <a className="dropdown-itemMbl" href="/MyDiagnosticReport">
                {" "}
                <img alt="" className="iconProfile" src={myAccountIcon3}></img>
                My Diagnostic Reports
              </a>{" "}
              <a className="dropdown-itemMbl" href="/account/prescription">
                {" "}
                <img
                  alt="Prescription"
                  className="iconProfile"
                  src={myAccountIcon4}
                ></img>{" "}
                My Prescriptions
              </a>
              <a className="dropdown-itemMbl" href="/order-history-packages">
                {" "}
                <img alt="Purchase History" className="iconProfile" src={myAccountIcon3}></img>
                Purchase History
              </a>
              <a
                className="dropdown-itemMbl"
                href="/order-history-subscriptions"
              >
                {" "}
                <img alt="Subscription" className="iconProfile" src={myAccountIcon2}></img>
                My Subscription
              </a>
              <a className="dropdown-itemMbl" href="/order-history-program">
                {" "}
                <img alt="Corporate Program" className="iconProfile" src={myAccountIcon2}></img>
                My Corporate Program
              </a>
            </div>
            <div
              style={{ marginTop: "4vw", marginBottom: "-1vw" }}
              className="navLine"
            >
              _______________________________
            </div>

            <div>
              <div className="menuBtnss" onClick={handleLogout}>
                <img alt="Logout" className="iconNave" src={nm7}></img> Logout
              </div>
            </div>
          </>
        ) : (
          <div className="loginNregister">
            <a href="/login">
              <div className="menuBtnss">
                <img alt="Login" className="iconNave" src={nm7}></img> Login
              </div>
            </a>
            <div className="menuBtnss1"> or </div>
            <a href="/register">
              <div className="menuBtnss"> Register</div>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default Hamburger;
