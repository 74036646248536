import CryptoJS from "crypto-js";
import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import SessionId from "./Utils/sessionId";
import storage from "./Utils/storage";
import Routing from "./routes/Route";

export const UserContext = createContext();

function App() {
  const dispatch = useDispatch();
  const prevRole = localStorage.getItem('role');
  const secretKey = process.env.REACT_APP_SECURITY_KEY

  if (prevRole === "RETAIL_USER" || prevRole === "EMPLOYEE") {
    localStorage.clear();
    window.location.reload();
  }

  const getCart = useSelector((state) => state.cart.getCart);

  const [role, setRole] = useState(storage.getStorage("authorization"));

  useEffect(() => {
    if (getCart.data !== null) {
      if (getCart.data?.data.data.data?.itemDtoList.length > 0) {
        storage.setStorage("itemDtoList", JSON.stringify(getCart.data?.data.data.data.itemDtoList));
        window.dispatchEvent(new Event('storage'));
      } else {
        storage.removeStorage("itemDtoList");
        window.dispatchEvent(new Event('storage'));
      }
    }
  }, [getCart]);

  useEffect(() => {
    if (role !== null) {
      const decryptedBytes = CryptoJS.AES.decrypt(role, secretKey);
      const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
      setRole(decryptedData);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <UserContext.Provider value={role}>
        <SessionId />
        <BrowserRouter>
          <Routing />
        </BrowserRouter>
      </UserContext.Provider>
    </div>
  );
}

export default App;