import React from 'react'
import CompletePaymentImage from "../../../assets/cart/CompletePaymentImage.png";
import "./ConfirmPayment.css";
import storage from "../../../Utils/storage";
import Title from "../../Atoms/Title/Title";
import ScrollToTop from '../../Molecules/ScrollToTop/ScrollToTop';

function ZeroPaymentConfirmation() {

  storage.removeStorage("itemDtoList");

  return (
    <div>
      <Title title="Yuva | Payment Success"></Title>
      <ScrollToTop />
      <div className="CompleteCartMain">
        <div className="CompleteCart1">
          <img className="CompleteImageZero" src={CompletePaymentImage} alt='cash'></img>
          <div className="CompleteText">
            Thank you, for purchasing
          </div>
          <div className="CompleteSecondText">
            We had received your booking request. One of our executive confirm
            the same .
          </div>
        </div>
      </div>
     
    </div>
  );
};


export default ZeroPaymentConfirmation
