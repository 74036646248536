import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../../Action/HealthRiskAssessment/GetSectionAnswers/GetSection3AnswersAction";
import NetworkService from "../../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* GetSection3AnswersSaga(action) {
  try {
    let response = yield call(NetworkService.GetSection3Answers, action);
    yield put(actions.GetSection3AnswersSuccess(response));
  } catch (err) {
    yield put(actions.GetSection3AnswersFailure(err));
  }
}

export function* watchGetSection3AnswersSaga() {
  yield takeLatest(types.GET_SECTION_3_ANSWERS_REQUEST, GetSection3AnswersSaga);
}
