import * as types from "../../Config/MentalWellness/OnMoodNineActionType";


const initialState = {
    data: null,
    isLoading: false,
    error: null
}

export const onMoodNineReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.GET_ON_MOOD_NINE_SUCCESS:
            return {
                ...initialState,
                data: action,
            }
        case types.GET_ON_MOOD_NINE_FAILURE:
            return {
                ...initialState,
                error: action
            }
        case types.GET_ON_MOOD_NINE_REQUEST:
            return {
                ...initialState,
                isLoading: true
            }
        default:
            return state
    }
}