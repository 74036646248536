export const GET_PLAN_DETAIL_SUCCESS = "GET_PLAN_DETAIL_SUCCESS";
export const GET_PLAN_DETAIL_FAILURE = "GET_PLAN_DETAIL_FAILURE";
export const GET_PLAN_DETAIL_REQUEST = "GET_PLAN_DETAIL_REQUEST";

export const GET_PLAN_FULL_DETAILS_SUCCESS = "GET_PLAN_FULL_DETAILS_SUCCESS";
export const GET_PLAN_FULL_DETAILS_FAILURE = "GET_PLAN_FULL_DETAILS_FAILURE";
export const GET_PLAN_FULL_DETAILS_REQUEST = "GET_PLAN_FULL_DETAILS_REQUEST";

export const PLAN_TYPE_DROPDOWN_SUCCESS = "PLAN_TYPE_DROPDOWN_SUCCESS";
export const PLAN_TYPE_DROPDOWN_FAILURE = "PLAN_TYPE_DROPDOWN_FAILURE";
export const PLAN_TYPE_DROPDOWN_REQUEST = "PLAN_TYPE_DROPDOWN_REQUEST";

export const PLAN_REQUEST_CALL_SUCCESS = "PLAN_REQUEST_CALL_SUCCESS";
export const PLAN_REQUEST_CALL_FAILURE = "PLAN_REQUEST_CALL_FAILURE";
export const PLAN_REQUEST_CALL_REQUEST = "PLAN_REQUEST_CALL_REQUEST";

export const PLAN_LOCK_SUCCESS = "PLAN_LOCK_SUCCESS"
export const PLAN_LOCK_FAILURE = "PLAN_LOCK_FAILURE"
export const PLAN_LOCK_REQUEST = "PLAN_LOCK_REQUEST"

export const GET_SELECTED_PLAN_SUCCESS = "GET_SELECTED_PLAN_SUCCESS"
export const GET_SELECTED_PLAN_FAILURE = "GET_SELECTED_PLAN_FAILURE"
export const GET_SELECTED_PLAN_REQUEST = "GET_SELECTED_PLAN_REQUEST"


export const GET_PLAN_SERVICES_SUCCESS = "GET_PLAN_SERVICES_SUCCESS"
export const GET_PLAN_SERVICES_FAILURE = "GET_PLAN_SERVICES_FAILURE"
export const GET_PLAN_SERVICES_REQUEST = "GET_PLAN_SERVICES_REQUEST"


export const GET_PLAN_SERVICES_DETAIL_SUCCESS = "GET_PLAN_SERVICES_DETAIL_SUCCESS"
export const GET_PLAN_SERVICES_DETAIL_FAILURE = "GET_PLAN_SERVICES_DETAIL_FAILURE"
export const GET_PLAN_SERVICES_DETAIL_REQUEST = "GET_PLAN_SERVICES_DETAIL_REQUEST"
