import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../Action/HealthRiskAssessment/SaveReportAction";
import NetworkService from "../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* SaveReportSaga(action) {
  try {
    let response = yield call(NetworkService.SaveReport, action);
    yield put(actions.SaveReportSuccess(response));
  } catch (err) {
    yield put(actions.SaveReportFailure(err));
  }
}

export function* WatchSaveReportSaga() {
  yield takeLatest(types.SAVE_REPORT_REQUEST, SaveReportSaga);
}
