import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/OTP/OTPValidation/otpValidationActionType";
import * as actions from "../../../Action/OTP/OTPValidation/otpValidationAction";
import NetworkService from "../../../../network/Network/OTP/OTPValidation/otpValidationNetwork";

export function* validateOtp(action) {
  try {
    let response = yield call(NetworkService.validateOtp, action);
    yield put(actions.otpValidationRequestSuccess(response));
  } catch (err) {
    yield put(actions.otpValidationRequestFailure(err));
  }
}

export function* watchValidateOtp() {
  yield takeLatest(types.OTPVALIDATION_REQUEST, validateOtp);
}