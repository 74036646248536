import React from "react";
import { useNavigate } from "react-router-dom";
import Cart from "../../../assets/product/Cart.png";
import AddedButton from "../../Atoms/Buttons/AddButtons/AddedButton";
import "./ProductCard.css";
import Swal from "sweetalert2";

function ProductCard({
  productContent,
  handleAddCart,
  isSubmitting,
  itemDtoList,
}) {
  const navigate = useNavigate();

  function handleNavigation(id) {
    navigate("/product-info?product-id=" + id);
  }

  const handleAddProduct = () => {
    Swal.fire({
      title: "Coming Soon",
      icon: "info",
      timer: 1500,
    });
  };

  return (
    <div>
      <div className="productCard">
        <div
          style={{ display: "flex", justifyContent: "center", height: "60%" }}
          onClick={() => handleNavigation(productContent.productId)}
        >
          <img
            src={productContent.imageFilepath}
            className="productCardImg"
            alt="Product"
          />
        </div>
        <div className="productCardDesc">
          <div>
            <div
              className="productCardTitle"
              onClick={() => handleNavigation(productContent.productId)}
            >
              {productContent.name}
            </div>
            <div className="productCardDescBody">
              <div>
                <div
                  className="productCardBody"
                  onClick={() => handleNavigation(productContent.productId)}
                >
                  {productContent.discountPercentage > 0 && (
                    <div
                      className="productCardMrp"
                      style={
                        productContent.discountPercentage > 0
                          ? { display: "block" }
                          : { display: "hidden" }
                      }
                    >
                      ₹{productContent.originalPrice}
                    </div>
                  )}
                  {productContent.discountPercentage > 0 && (
                    <div
                      className="productCardDiscount"
                      onClick={() => handleNavigation(productContent.productId)}
                    >
                      {productContent.discountPercentage}% off
                    </div>
                  )}
                </div>

                <div
                  className="productCardPrice"
                  onClick={() => handleNavigation(productContent.productId)}
                >
                  ₹{productContent.finalPrice}
                </div>

                <div
                  className="productCardUnit"
                  onClick={() => handleNavigation(productContent.productId)}
                >
                  <span>
                    for {productContent.unit} {productContent.unitEnum}
                  </span>
                </div>
              </div>

              <div
                className="productCardButton"
                disabled={isSubmitting}
                onClick={() => handleAddProduct()}
                // onClick={() =>
                //   isSubmitting ? null : handleAddCart(productContent)
                // }
              >
                {itemDtoList?.length > 0 &&
                itemDtoList.filter(
                  (x) =>
                    x.productId.toString() ===
                      productContent.productId.toString() &&
                    x.productType === "PRODUCT"
                ).length > 0 ? (
                  <AddedButton />
                ) : (
                  <img src={Cart} alt="Cart" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
