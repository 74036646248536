import * as types from "../../Config/Landing/landingActionType"

export const getPopularPlanRequest = (data) => {
    return {
        type: types.GET_POPULAR_PLAN_REQUEST,
        data:data
    }
}

export const getPopularPlanSuccess = (data) => {
    return {
        type: types.GET_POPULAR_PLAN_SUCCESS,
        data:data,
    }
}

export const getPopularPlanFailure = (data) => {
    return {
        type: types.GET_POPULAR_PLAN_FAILURE,
        data:data,
    }
}