import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import SendIcon from "../../../assets/product/Vector (9).png";
import Chest from "../../../assets/product/medicine_chest 1.png";
import "./ProductHero.css";

function ProductHero({ name, image }) {
  const footerSchema = Yup.object().shape({
    Email: Yup.string()
      .matches(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
        "Enter a Valid E-mail Address"
      )
      .required("Email is Required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    Swal.fire({
      title: "Subscribed",
      icon: "success",
      timer: 1000,
      showConfirmButton: false,
    }).then(() => {
      resetForm();
    });
  };

  return (
    <>
      <div className="productCategoryHero">
        <div className="productCategoryHeroLeft">
          <div className="productCategoryHeroText">
            <div className="productCategoryHeroTextTitle">
              Don't miss <br />
              <span>amazing Health deals</span>
            </div>
            <div className="productCategoryHeroTextDesc">
              Sign up for the daily newsletter
            </div>
            <Formik
              initialValues={{
                Email: "",
              }}
              validationSchema={footerSchema}
              onSubmit={handleSubmit}
            >
              {
                <Form>
                  <div className="productCategoryHeroTextInput">
                    <div>
                      <img src={SendIcon} alt="send icon" />
                      <Field
                        placeholder="Your emaill address"
                        name="Email"
                        type="email"
                      ></Field>
                    </div>
                    <button>Subscribe</button>
                  </div>
                  <ErrorMessage
                    name="Email"
                    component="div"
                    className="footerValidation"
                  />
                </Form>
              }
            </Formik>
          </div>
        </div>
        <div className="productCategoryHeroRight">
          <img src={Chest} className="First Aid Kit" alt="First Aid Kit" />
        </div>
      </div>
    </>
  );
}

export default ProductHero;
