import React, { useEffect, useState } from "react";
import deleteIcon from "../../../assets/cart/deleteIconCart.png";
import scientistIcon from "../../../assets/cart/scientistIcon.png";
import CartOrderSummary from "./CartOrderSummary";
import "./CartWeb.css";

function CartWeb({
  cart,
  listItems,
  handleRemove,
  apply,
  RemoveCoupon,
  handleCoupon,
  handleKeyPress,
  coupon1,
  handleApply,
  errorMessage,
  newPin,
  couponCode,
  handleApplyCoupon,
  handleCheckout,
  handleViewDetails,
  handleQuantity,
  CouponNotFound,
}) {
  const [optionClick, setOptionClick] = useState([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest(".productQty") === null) {
        setOptionClick(optionClick.map(() => false));
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [optionClick]);

  return (
    <div className="Cartmain">
      <div className="CartMainContentArea">
        <p className="totalItemsHeading">
          Total Items (
          {cart?.itemDtoList.length > 0 ? cart?.itemDtoList.length : 0})
        </p>
        {cart?.itemDtoList?.length > 0 ? (
          cart?.itemDtoList?.map((item, index) => (
            <div className="cartItems" key={index}>
              <div className="cartItemsPart1">
                <img
                  src={item.imageFilepath || scientistIcon}
                  className="CartItemsImage"
                  alt="scientistIcon"
                />
              </div>
              <div className="cartItemsPart2">
                <p className="cartItemsPart2ItemName">{item.name}</p>
                <div className="CartItemsPriceArea">
                  <p className="DiscountedPrice">₹ {item.discountedCost}</p>
                  {item.discountedCost === item.cost ? (
                    <p></p>
                  ) : (
                    <p className="originalPrice">₹ {item.cost}</p>
                  )}
                </div>
              </div>
              <div className="cartItemsPart3">
                {item.productType === "PRODUCT" ? (
                  <div className="cartItemsPart3SelectDiv" id={item.productId}>
                    <select
                      className="quantitySelect"
                      onChange={(e) => handleQuantity(e, item)}
                      defaultValue={item.count}
                    >
                      {listItems.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div className="CartListItemsNewRightp2-1">{item.count}</div>
                )}
              </div>
              <div className="cartItemsPart4">
                <img
                  src={deleteIcon}
                  alt="removeIcon"
                  className="removeIconCart"
                  onClick={() =>
                    handleRemove(
                      item.productId,
                      item.productType,
                      item.productPriceId
                    )
                  }
                />
              </div>
            </div>
          ))
        ) : (
          <div className="emptyCart">Cart is Empty</div>
        )}
      </div>
      <CartOrderSummary
        cart={cart}
        listItems={listItems}
        handleRemove={handleRemove}
        apply={apply}
        RemoveCoupon={RemoveCoupon}
        handleCoupon={handleCoupon}
        handleKeyPress={handleKeyPress}
        coupon1={coupon1}
        handleApply={handleApply}
        errorMessage={errorMessage}
        newPin={newPin}
        couponCode={couponCode}
        handleApplyCoupon={handleApplyCoupon}
        handleCheckout={handleCheckout}
        handleViewDetails={handleViewDetails}
        handleQuantity={handleQuantity}
        CouponNotFound={CouponNotFound}
      />
    </div>
  );
}

export default CartWeb;
