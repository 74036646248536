import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../../Action/HealthRiskAssessment/GetQuestionSection2Action";
import NetworkService from "../../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* GetQuestionSection2Saga(action) {
  try {
    let response = yield call(NetworkService.GetQuestionSection2, action);
    yield put(actions.GetQuestionSection2Success(response));
  } catch (err) {
    yield put(actions.GetQuestionSection2Failure(err));
  }
}

export function* watchGetQuestionSection2Saga() {
  yield takeLatest(types.GET_QUESTION_SECTION2_REQUEST, GetQuestionSection2Saga);
}
