import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import magnifying from "../../../assets/magniFying.png";
import { getBrandDropdownRequest } from "../../../redux/Action/EcomUser/getBrandDropdownAction";
import { getCategoryDropdownRequest } from "../../../redux/Action/EcomUser/getCategoryDropdownAction";
import { getSubCategoryDropdownRequest } from "../../../redux/Action/EcomUser/getSubCategoryDropdownAction";
import { viewAllProductCategoryRequest } from "../../../redux/Action/EcomUser/viewAllProductCategoryAction";
import { viewAllProductsRequest } from "../../../redux/Action/EcomUser/viewAllProductsAction";
import Title from "../../Atoms/Title/Title";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import FilterTest from "../../Organisms/Filters/FilterTest";
import Filters from "../../Organisms/Products/Filters";
import ProductHero from "../../Organisms/Products/ProductHero";
import ProductList from "../../Organisms/Products/ProductList";
import "./ProductCategory.css";

function ProductCategory() {
  const [checkboxesBrand, setCheckboxesBrand] = useState([]);
  const [checkboxesSubCategory, setCheckboxesSubCategory] = useState([]);
  const [checkboxesCategory, setCheckboxesCategory] = useState([]);
  const [subCategoryShow, setSubCategoryShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [mapData, setMapData] = useState([]);
  const [searchInput, setSearchInput] = useState(null);
  const [totalPages, setTotalPages] = useState();

  const dispatch = useDispatch();
  const loadingRef = useRef(null);

  const viewAllProducts = useSelector((state) => state.eCom.viewAllProducts);

  const getBrandDropdown = useSelector((state) => state.eCom.getBrandDropdown);

  const getCategoryDropdown = useSelector(
    (state) => state.eCom.getCategoryDropdown
  );

  const getSubCategoryDropdown = useSelector(
    (state) => state.eCom.getSubCategoryDropdown
  );

  const brandDropdown = getBrandDropdown.data?.data.data.data || [];
  const categoryDropdown = getCategoryDropdown.data?.data.data.data || [];
  const subCategoryDropdown = getSubCategoryDropdown.data?.data.data.data || [];

  useEffect(() => {
    dispatch(viewAllProductCategoryRequest());
    dispatch(getBrandDropdownRequest());
    dispatch(getCategoryDropdownRequest());
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      viewAllProductsRequest(
        page,
        10,
        checkboxesBrand,
        checkboxesCategory,
        [],
        searchInput,
        checkboxesSubCategory
      )
    );
  }, [
    page,
    checkboxesBrand,
    checkboxesCategory,
    checkboxesSubCategory,
    searchInput,
    dispatch,
  ]);

  useEffect(() => {
    if (checkboxesCategory.length > 0) {
      setSubCategoryShow(true);
      dispatch(getSubCategoryDropdownRequest(checkboxesCategory));
    } else {
      setSubCategoryShow(false);
    }
  }, [checkboxesCategory, dispatch]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          !viewAllProducts.isLoading &&
          page < totalPages
        ) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1.0 }
    );

    const loadingElement = loadingRef.current;
    if (loadingElement) {
      observer.observe(loadingElement);
    }

    return () => {
      if (loadingElement) {
        observer.unobserve(loadingElement);
      }
    };
  }, [isLoading, page, totalPages, viewAllProducts.isLoading]);

  useEffect(() => {
    if (
      viewAllProducts.data?.data.status === 200 &&
      mapData[0]?.productId !==
        viewAllProducts.data?.data.data.data
          .productResponseDtoForUserGridViewList[0]?.productId
    ) {
      setIsLoading(false);
      setMapData((prevState) => [
        ...prevState,
        ...viewAllProducts.data?.data?.data?.data
          ?.productResponseDtoForUserGridViewList,
      ]);
      setTotalPages(viewAllProducts.data?.data?.data?.data?.totalPages);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewAllProducts.data]);

  const handleCheckboxBrandChange = (event) => {
    const { value, checked } = event.target;
    setPage(1);
    setMapData([]);
    if (checked) {
      setCheckboxesBrand([...checkboxesBrand, value]);
    } else {
      setCheckboxesBrand(
        checkboxesBrand.filter((checkbox) => checkbox !== value)
      );
    }
  };

  const handleCheckboxCategoryChange = (event) => {
    setPage(1);
    setMapData([]);
    const { value, checked } = event.target;
    if (checked) {
      setCheckboxesCategory([...checkboxesCategory, value]);
    } else {
      setCheckboxesCategory(
        checkboxesCategory.filter((checkbox) => checkbox !== value)
      );
    }
  };

  const handleCheckboxSubCategoryChange = (event) => {
    setMapData([]);
    setPage(1);
    const { value, checked } = event.target;
    if (checked) {
      setCheckboxesSubCategory([...checkboxesSubCategory, value]);
    } else {
      setCheckboxesSubCategory(
        checkboxesSubCategory.filter((checkbox) => checkbox !== value)
      );
    }
  };

  const handleProductSearch = (event) => {
    setSearchInput(event.target.value);
    setMapData([]);
    setPage(1);
  };

  return (
    <div className="productCategory">
      <Title title="Yuva | Products" />
      <ScrollToTop />
      <ProductHero />
      <div className="productCategoryBody">
        <div className="productCategoryBodyLeft">
          <div className="productCategoryBodyLeftCategory">
            <div className="productCategoryBodyLeftCategoryHead">Filters</div>
            <hr className="productCategoryBodyLeftCategoryBreak" />
            <div className="productCategoryBodyLeftCategoryBody">
              <div className="productCategoryBodyLeftCategoryBodyContent">
                <FilterTest
                  title={"Brand"}
                  mapping={brandDropdown}
                  handleCheckboxChange={handleCheckboxBrandChange}
                  checkboxBrand={checkboxesBrand}
                />
              </div>
              <div className="productCategoryBodyLeftCategoryBodyContent">
                <FilterTest
                  title={"Category"}
                  mapping={categoryDropdown}
                  handleCheckboxChange={handleCheckboxCategoryChange}
                  checkboxBrand={checkboxesCategory}
                />
              </div>
              {subCategoryDropdown.length > 0 && subCategoryShow && (
                <div className="productCategoryBodyLeftCategoryBodyContent">
                  <FilterTest
                    title={"Sub Category"}
                    mapping={subCategoryDropdown}
                    handleCheckboxChange={handleCheckboxSubCategoryChange}
                    checkboxBrand={checkboxesSubCategory}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="productCategoryBodyRight">
          <div className="productCategoryBodyRightHead">
            <div className="productCategoryBodyRightTitle">Products</div>
            <div className="productCategoryBodyRightHeadInput">
              <input
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => handleProductSearch(e)}
              ></input>
              <img src={magnifying} alt="search icon" />
            </div>
          </div>
          {mapData.length > 0 ? (
            <>
              <ProductList product={mapData} col={true} />
              {page >= totalPages && viewAllProducts.isLoading === false ? (
                ""
              ) : (
                <div id="loading" ref={loadingRef}>
                  Loading...
                </div>
              )}
            </>
          ) : (
            <div>No Products Found</div>
          )}
        </div>
        <Filters
          className="productCategoryBodyRightFilter"
          brandDropdown={brandDropdown}
          handleCheckboxBrandChange={handleCheckboxBrandChange}
          checkboxBrand={checkboxesBrand}
          categoryDropdown={categoryDropdown}
          handleCheckboxCategoryChange={handleCheckboxCategoryChange}
          checkboxesCategory={checkboxesCategory}
        />
      </div>
    </div>
  );
}

export default ProductCategory;
