import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Cart/CreateCartActionType";
import * as actions from "../../Action/Cart/CartDiscountAction";
import NetworkService from "../../../network/Network/Cart/CartNetwork";

export function* CartDiscountSaga(action) {

  try {
    let response = yield call(NetworkService.CartDiscount, action);
    yield put(actions.CartDiscountSuccess(response));
  } catch (err) {
    yield put(actions.CartDiscountFailure(err));
  }
}

export function* watchCartDiscountSaga() {
  yield takeLatest(types.CART_DISCOUNT_REQUEST, CartDiscountSaga);
}
