import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../../Action/HealthRiskAssessment/GetQuestionSectionAction";
import NetworkService from "../../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* GetQuestionSectionSaga(action) {
  try {
    let response = yield call(NetworkService.GetQuestionSection, action);
    yield put(actions.GetQuestionSectionSuccess(response));
  } catch (err) {
    yield put(actions.GetQuestionSectionFailure(err));
  }
}

export function* watchGetQuestionSectionSaga() {
  yield takeLatest(types.GET_QUESTION_SECTION_REQUEST, GetQuestionSectionSaga);
}
