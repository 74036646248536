import * as types from "../../Config/CheckEmail/CheckEmail"

export const CheckEmailRequest = (data,corporateId,token) => {
    return {
        type: types.CHECK_EMAIL_REQUEST,
        data:data,
        corporateId,
        token
    }
}

export const  CheckEmailSuccess = (data) => {
    return {
        type: types.CHECK_EMAIL_SUCCESS,
        data:data,
    }
}

export const  CheckEmailFailure = (data) => {
    return {
        type: types.CHECK_EMAIL_FAILURE,
        data:data,
    }
}