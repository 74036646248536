import { all } from "redux-saga/effects";
import { watchGenerateOtp } from "./OTP/otpSaga";
import { watchGenerateMobileOtp } from "./OTPMobile/otpMobileSaga";
import { watchValidateOtp } from "./OTPValidation/otpValidationSaga";
import { watchValidateMobileOtp } from "./OTPValidationMobile/otpMobileValidationSaga";
import { watchGenerateEmailOtp } from "./generateEmailOtpSaga";
import { watchUpdateProfilevalidateOtp } from "./OTPValidation/UpdateProfileOTPValidationSaga";

function* rootOTPSaga() {
  yield all([
    watchGenerateOtp(),
    watchGenerateMobileOtp(),
    watchValidateOtp(),
    watchValidateMobileOtp(),
    watchGenerateEmailOtp(),
    watchUpdateProfilevalidateOtp(),
  ]);
}

export default rootOTPSaga;
