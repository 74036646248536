import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const SaveSectionAnswersRequest = (data) => {
    return {
        type: types.SAVE_SECTION_ANSWERS_REQUEST,
        data:data
    }
}

export const  SaveSectionAnswersSuccess = (data) => {
    return {
        type: types.SAVE_SECTION_ANSWERS_SUCCESS,
        data:data,
    }
}

export const  SaveSectionAnswersFailure = (data) => {
    return {
        type: types.SAVE_SECTION_ANSWERS_FAILURE,
        data:data,
    }
}