import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../../Action/HealthRiskAssessment/GetQuestionSection1Action";
import NetworkService from "../../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* GetQuestionSection1Saga(action) {
  try {
    let response = yield call(NetworkService.GetQuestionSection1, action);
    yield put(actions.GetQuestionSection1Success(response));
  } catch (err) {
    yield put(actions.GetQuestionSection1Failure(err));
  }
}

export function* watchGetQuestionSection1Saga() {
  yield takeLatest(types.GET_QUESTION_SECTION1_REQUEST, GetQuestionSection1Saga);
}
