import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/ERM/MedicalRecordListingConfig"
import * as actions from "../../Action/ERM/medicalRecordDropdownAction"
import NetworkService from "../../../network/Network/ERM/medicalRecordDropdownNetwork"

export function* medicalRecordDropdown(action) {
  try {
    let response = yield call(NetworkService.medicalRecordDropdown,action);

    yield put(actions.medicalRecordDropdownSuccess(response))
  } catch (err) {
    yield put(actions.medicalRecordDropdownFailure(err));
  }
}

export function* watchmedicalRecordDropdown() {
  yield takeLatest(types.MEDICAL_RECORD_DROPDOWN_REQUEST, medicalRecordDropdown);
}
