import * as types from "../../Config/ElasticSearch/ElasticSearchActionType"

export const elasticSearchResultRequest = (data) => {
    return {
        type: types.ELASTIC_SEARCH_RESULT_REQUEST,
        item:data.item,
        search:data.search,
        pageSizeForTest:data.pageSizeForTest,
        pageSizeForPackage:data.pageSizeForPackage,
        pageSizeProduct:data.pageSizeProduct,
        productTypeEnum:data.productTypeEnum,
    }
}

export const  elasticSearchResultSuccess = (data) => {
    return {
        type: types.ELASTIC_SEARCH_RESULT_SUCCESS,
        data
    }
}

export const  elasticSearchResultFailure = (data) => {
    return {
        type: types.ELASTIC_SEARCH_RESULT_FAILURE,
        data
    }
}