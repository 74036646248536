import * as types from "../../Config/Plan/PlanActionType";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const getPlanFullDetailsReducer = (
  state = initialState,
  action = {}
) => {
  switch (action.type) {
    case types.GET_PLAN_FULL_DETAILS_SUCCESS:
      return {
        ...initialState,
        data: action,
      };
    case types.GET_PLAN_FULL_DETAILS_FAILURE:
      return {
        ...initialState,
        error: action,
      };
    case types.GET_PLAN_FULL_DETAILS_REQUEST:
      return {
        ...initialState,
        isLoading: true,
      };
    default:
      return state;
  }
};
