import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import prescriptionFile from "../../../assets/MyAccount/prescriptionFile.png";
import { myReportDiagnosticRequest } from "../../../redux/Action/Account/MyReportDiagnostics";
import Title from "../../Atoms/Title/Title";
import "./MyPrescription.css";
import "./MyReport.css";
import NoHraReports from "./NoHraReports";

function MyReports() {
  const search = useLocation().search;
  const [id, setId] = useState(new URLSearchParams(search).get("id"));
  const name = localStorage.getItem("name");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!name) {
      window.location.href = "/";
    } else {
      dispatch(myReportDiagnosticRequest(id));
    }
  }, [id, dispatch, name]);

  const myReportDiagnostic = useSelector(
    (state) => state.account.myReportDiagnostic
  );

  const handleView = (fileLink) => {
    const link = document.createElement("a");
    link.href = fileLink;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
  };

  const handleViewAll = () => {
    setId(null);
    navigate("/account/health-risk-assessment-report");
  };

  return (
    <div className="mainBlockk">
      <Title title="YUVA | HRA Reports" />

      {myReportDiagnostic?.data?.data?.data?.data?.length === 0 ? (
        <NoHraReports />
      ) : (
        <>
          <div className="hraHeading">MY HRA REPORTS</div>
          <div className="myprescriptionmaindiv">
            <div className="myprescriptionalltexts">
              <div className="myprescriptionalltextbtn">
                <div className="myprescriptionalltextget">
                  Get a comprehensive understanding about your health through
                  Yuva Health Reports.
                </div>
                {id !== null && (
                  <button
                    className="myprescriptionalltextbtnViewAll"
                    onClick={handleViewAll}
                  >
                    View All Reports
                  </button>
                )}
              </div>
              <div className="templateNamesPres">
                {myReportDiagnostic?.data?.data?.data.data.map(
                  (item, index) => (
                    <div className="prescriptionBoxTexts" key={index}>
                      <div className="prescriptionBoxTextsStarts">
                        <img
                          className="prescriptionFile"
                          src={prescriptionFile}
                          alt="Prescription File"
                        ></img>

                        <div className="presText">
                          <div className="prescriptionPre">Health Report</div>
                          <div className="presc2">{item.name}</div>
                        </div>
                      </div>
                      <div className="DownloadPresc">
                        <div className="genDate">
                          Generated On -{new Date(item.date).toLocaleString()}{" "}
                        </div>
                        <div
                          className="downloadIcon"
                          onClick={() => handleView(item.filePath)}
                        >
                          Download{" "}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default MyReports;
