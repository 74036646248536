import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

const initialState = {
    data: null,
    isLoading: false,
    error: null
}

export const getSection9AnswersReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.GET_SECTION_9_ANSWERS_SUCCESS:
            return {
                ...initialState,
                data: action,
            }
            case types.GET_SECTION_9_ANSWERS_FAILURE:
                return {
                    ...initialState,
                    error: action
                }
            case types.GET_SECTION_9_ANSWERS_REQUEST:
                return {
                    ...initialState,
                    isLoading: true
                }
        default:
            return state
    }
}