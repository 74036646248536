import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/EcomUser/EcomUserActionType";
import * as actions from "../../Action/EcomUser/getBrandDropdownAction";
import NetworkService from "../../../network/Network/EcomUser/EcomUserNetwork";

export function* getBrandDropdownSaga(action) {
  try {
    let response = yield call(NetworkService.getBrandDropdown, action);
    yield put(actions.getBrandDropdownSuccess(response));
  } catch (err) {
    yield put(actions.getBrandDropdownFailure(err));
  }
}

export function* watchGetBrandDropdownSaga() {

  yield takeLatest(types.GET_BRAND_DROPDOWN_REQUEST, getBrandDropdownSaga);
}
