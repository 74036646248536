export const dropDisabled = {
  message: "Please Select",
  value: "",
};

export const pickerdiet = [
  {
    option: "Never",
    id: "0",
  },
  {
    option: "1x / week",
    id: "1",
  },
  {
    option: "2/3 times a week",
    id: "2",
  },
  {
    option: "Daily",
    id: "3",
  },
];

export const pickerdiet1 = [
  {
    option: "Never",
    id: "0",
  },
  {
    option: "1x / week",
    id: "1",
  },
  {
    option: "2/3 times a week",
    id: "2",
  },
  {
    option: "Daily",
    id: "3",
  },
];

export const pickerdiet2 = [
  {
    option: "Never",
    id: "3",
  },
  {
    option: "1x / week",
    id: "2",
  },
  {
    option: "2/3 times a week",
    id: "4",
  },
  {
    option: "Daily",
    id: "1",
  },
];

export const pickerdiet3 = [
  {
    option: "Never",
    id: "3",
  },
  {
    option: "1x / week",
    id: "1",
  },
  {
    option: "2/3 times a week",
    id: "2",
  },
  {
    option: "Daily",
    id: "4",
  },
];

export const pickerdiet4 = [
  {
    option: "Never",
    id: "3",
  },
  {
    option: "1x / week",
    id: "2",
  },
  {
    option: "2/3 times a week",
    id: "1",
  },
  {
    option: "Daily",
    id: "0",
  },
];

export const pickerhealth = [
  {
    option: "Not at all",
    id: "0",
  },
  {
    option: "Several days",
    id: "1",
  },
  {
    option: "More than half the days",
    id: "2",
  },
  {
    option: "Nearly every day",
    id: "3",
  },
];

export const pickeryesno = [
  {
    option: "No",
    id: "0",
  },
  {
    option: "Yes",
    id: "1",
  },
];

export const pickeryesnomedication = [
  {
    option: "No",
    id: "0",
  },
  {
    option: "Yes on Medication",
    id: "1",
  },
  {
    option: "Yes",
    id: "2",
  },
];

export const pickeralcohol2 = [
  {
    option: "0-1",
    id: "0",
  },
  {
    option: "2-3",
    id: "1",
  },
  {
    option: ">4",
    id: "2",
  },
];

export const pickeralcohol3 = [
  {
    option: "occasionally",
    id: "3",
  },
  {
    option: "less than 2 days",
    id: "2",
  },
  {
    option: "2-3 days",
    id: "1",
  },
  {
    option: "More than 4 days",
    id: "0",
  },
];

export const pickeralcohol4 = [
  {
    option: "occasionally",
    id: "3",
  },
  {
    option: "less than 2 times",
    id: "2",
  },
  {
    option: "2-3 times",
    id: "1",
  },
  {
    option: "Daily",
    id: "0",
  },
];

export const pickergender = [
  {
    option: "Male",
    id: "0",
  },
  {
    option: "Female",
    id: "1",
  },
];

export const pickersleep = [
  {
    option: "less than 7 hr",
    id: "0",
  },
  {
    option: "between 7-9 hr",
    id: "1",
  },
  {
    option: "greater than 9 hr",
    id: "2",
  },
];
