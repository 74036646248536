import * as React from "react";
import { useEffect, useState } from "react";
import Construction from "../../../assets/common/Construction.png";
import "./UnderConstruction.css";
import Title from "../../Atoms/Title/Title";

function UnderConstructions() {
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    if (window.outerWidth <= 600) {
      setIsMobile(true);
    }
  }, []);

  return (
    <>
    <Title title="Yuva | Under Construction"></Title>

      {isMobile ? (
        <div>
          <div className="mainConstructionMobile">
            <p className="pageConstructionMobile">
              The Page You're Looking For Is Under Construction
            </p>
            <img
              alt="Construction"
              className="ConstructionMobile"
              src={Construction}
            />
            <p className="takemeMobile">Take Me Back To</p>
            <a href="/" className="referenceMobile">
              <button className="homeConstructionMobile">Home</button>
            </a>
          </div>
         
        </div>
      ) : (
        <div>
          <div className="mainConstruction">
            <p className="pageConstruction">
              The Page You're Looking For Is Under Construction
            </p>
            <img
              alt="Construction"
              className="Construction"
              src={Construction}
            />
            <p className="takeme">Take Me Back To</p>
            <a href="/" className="reference">
              <button className="homeConstruction">Home</button>
            </a>
          </div>
         
        </div>
      )}
    </>
  );
}
export default UnderConstructions;
