import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../../Action/HealthRiskAssessment/GetSectionAnswers/GetSection5AnswersAction";
import NetworkService from "../../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* GetSection5AnswersSaga(action) {
  try {
    let response = yield call(NetworkService.GetSection5Answers, action);
    yield put(actions.GetSection5AnswersSuccess(response));
  } catch (err) {
    yield put(actions.GetSection5AnswersFailure(err));
  }
}

export function* watchGetSection5AnswersSaga() {
  yield takeLatest(types.GET_SECTION_5_ANSWERS_REQUEST, GetSection5AnswersSaga);
}
