import * as types from "../../Config/Plan/PlanActionType";

export const getSelectedPlanRequest = (data) => {
  return {
    type: types.GET_SELECTED_PLAN_REQUEST,
    uuid:data,
  };
};

export const getSelectedPlanSuccess = (data) => {
  return {
    type: types.GET_SELECTED_PLAN_SUCCESS,
    data: data,
  };
};

export const getSelectedPlanFailure = (data) => {
  return {
    type: types.GET_SELECTED_PLAN_FAILURE,
    data: data,
  };
};
