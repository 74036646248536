import React from "react";
import "../HRAscreens/HRAInfoScreens.css";
import backArrow from "../../../../assets/hra/backArrow.png";
import hra1b from "../../../../assets/hra/hra1b.png";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../Molecules/ScrollToTop/ScrollToTop";
import storage from "../../../../Utils/storage";
import SingleLogin from "../../Login/SingleLogin";

function HRA1b() {
  const navigate = useNavigate();

  const handlePrevious = () => {
    navigate("/health-risk-assessment/section-1a");
  };

  window.addEventListener("popstate", function (event) {
    storage.setStorage("SectionValue", 1);
  });

  if (storage.getStorage("token") === null) {
    return <SingleLogin />;
  }

  const SectionValue = localStorage.getItem("SectionValue");

  return (
    <>
      <ScrollToTop />

      <div className="hraInfoScreens">
        <div className="hraInfoScreensChild1">
          <p>Your Health Risk Assessment</p>
        </div>
        {SectionValue === "2" && (
          <div
            className="hraInfoScreensChild2"
            style={{ backgroundImage: `url(${hra1b})` }}
          >
            <div className="hraInfoScreensChild2a">
              <div className="hraInfoScreensChild2a1">
                <p>GENERAL SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>DIETARY SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a2">
                <div className="hraInfoBackArrow">
                  <img
                    src={backArrow}
                    onClick={handlePrevious}
                    alt="backArrow"
                  />
                  <p>Dietary Section</p>
                </div>
                <div className="hraInfo">
                  <p>
                    There is no "one diet for everyone" so let's dig deep into
                    your eating habits{" "}
                  </p>
                </div>
                <button
                  onClick={() => {
                    localStorage.setItem("SectionValue", 2);
                    navigate("/health-risk-assessment/section-2");
                  }}
                >
                  Discover further
                </button>
              </div>
            </div>
          </div>
        )}
        {SectionValue !== "2" && (
          <div className="hraDenied">
            Not Authorized !! please navigate to previous sections
          </div>
        )}
      </div>
    </>
  );
}

export default HRA1b;
