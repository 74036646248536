import { default as React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import signupImage from "../../../../assets/Register/signupImage.png";
import Logo from "../../../../assets/common/logoPrimaryFooter.png";
import YuvaLoader from "../../../Atoms/Loader/YuvaLoader";
import Title from "../../../Atoms/Title/Title";
import "./ForgotPassword.css";
import VerificationForgotPasswordOTPScreen from "./OTPmobile/VerificationForgotPasswordOTPScreen";
import UserForgotPasswordForm from "./UserForgotPasswordForm";
import { Helmet } from "react-helmet";

function ForgetPassword() {
  const navigate = useNavigate();

  const [childData, setChildData] = useState(null);
  const [storageCheck, setStorageCheck] = useState("");
  const [showMobileOtpVerification, setShowMobileOtpVerification] =
    useState(false);

  const responseForgetPassword = useSelector(
    (state) => state.user.forgetPassword
  );

  useEffect(() => {
    if (
      responseForgetPassword?.data?.data?.data?.status === true &&
      childData.includes("@") === false
    ) {
      let Status = responseForgetPassword?.data?.data?.data?.message;
      Swal.fire({
        title: Status,
        icon: "info",
      }).then(() => {
        setShowMobileOtpVerification(true);
      });
    } else if (
      responseForgetPassword?.data?.data?.data?.status === true &&
      childData.includes("@") === true
    ) {
      let Status = responseForgetPassword?.data?.data?.data?.message;
      Swal.fire({
        title: Status,
        icon: "success",
      });
    }
  }, [responseForgetPassword?.data?.data?.data?.status, childData]);

  if (responseForgetPassword?.data?.data?.data?.data === false) {
    let errorStatus = responseForgetPassword?.data?.data?.data?.message;
    setShowMobileOtpVerification(false);
    Swal.fire({
      title: errorStatus,
      icon: "warning",
    }).then(() => {
      window.location.reload();
      navigate("/login/forgetPassword");
    });
  } else if (responseForgetPassword?.error?.data?.response?.status === 401) {
    return navigate("/linkExpired");
  } else if (responseForgetPassword?.error?.data?.response?.status === 502) {
    return navigate("/linkExpired");
  } else if (responseForgetPassword?.error?.data?.response?.status === 403) {
    return navigate("/linkExpired");
  } else if (responseForgetPassword?.error?.data?.response?.status === 404) {
    Swal.fire({
      title: `${responseForgetPassword.error?.data?.response?.data?.errorMessage}`,
      icon: "error",
    });
  } else if (responseForgetPassword?.error?.data?.message === "Network Error") {
    return navigate("/linkExpired");
  }

  if (responseForgetPassword.isLoading) {
    return <YuvaLoader />;
  }

  return (
    <div>
      <Helmet>
        <title>Yuva Health: Forgot Password</title>
        <meta
          name="description"
          content="Forgot your password? Don't worry. Reset it easily with Yuva Health. 
        Follow the simple steps to regain access to your account and continue your health journey hassle-free."
        />
      </Helmet>
      <div className="signupMain">
        <div className="signupMainLeft">
          <img
            src={signupImage}
            alt="Sign Up"
            className="signupLeftBackground"
          />
          <img src={Logo} alt="Logo" className="LogoSignUp" />
        </div>
        <div className="signupMainRight">
          {showMobileOtpVerification ? (
            <>
              <VerificationForgotPasswordOTPScreen check={storageCheck} />
            </>
          ) : (
            <>
              <UserForgotPasswordForm
                setChildData={setChildData}
                setStorageCheck={setStorageCheck}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
