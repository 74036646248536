import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/ElasticSearch/ElasticSearchActionType";
import * as actions from "../../Action/ElasticSearch/ElasticSearchAction";
import NetworkService from "../../../network/Network/ElasticSearch/ElasticSearchNetwork";

export function* elasticSearchSaga(action) {
  try {
    let response = yield call(NetworkService.elasticSearch, action);
    yield put(actions.elasticSearchSuccess(response));
  } catch (err) {
    yield put(actions.elasticSearchFailure(err));
  }
}

export function* watchElasticSearchSaga() {
  yield takeLatest(types.ELASTIC_SEARCH_REQUEST, elasticSearchSaga);
}