import * as types from "../../Config/User/userActionType"

export const userRegisterRequest = (data,hash) => {
    return {
        type: types.REGISTER_REQUEST,
        data:data,
        hash:hash
    }
}

export const userRegisterSuccess = (data) => {
    return {
        type: types.REGISTER_SUCCESS,
        data:data,
    }
}

export const userRegisterFailure = (data) => {
    return {
        type: types.REGISTER_FAILURE,
        data:data,
    }
}