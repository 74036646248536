import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Address/AddressActionType";
import * as actions from "../../Action/Address/viewAllAddressAction";
import NetworkService from "../../../network/Network/Address/AddressNetwork";

export function* viewAllAddressSaga(action) {

  try {
    let response = yield call(NetworkService.viewAllAddress, action);
    yield put(actions.viewAllAddressSuccess(response));
  } catch (err) {
    yield put(actions.viewAllAddressFailure(err));
  }
}

export function* watchViewAllAddressSaga() {
  yield takeLatest(types.VIEW_ALL_ADDRESS_REQUEST, viewAllAddressSaga);
}
