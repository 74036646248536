import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const GetSection6AnswersRequest = (data) => {
    return {
        type: types.GET_SECTION_6_ANSWERS_REQUEST,
        data:data
    }
}

export const  GetSection6AnswersSuccess = (data) => {
    return {
        type: types.GET_SECTION_6_ANSWERS_SUCCESS,
        data:data,
    }
}

export const  GetSection6AnswersFailure = (data) => {
    return {
        type: types.GET_SECTION_6_ANSWERS_FAILURE,
        data:data,
    }
}