import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../assets/Loader/animate.gif";
import { paymentGatewayStatusRequest } from "../../../redux/Action/Cart/PaymentGatewayStatusAction";
import ConfirmPayment from "./ConfirmPayment";
import FailedPayment from "./FailedPayment";
import "./LoadingPayment.css";
import storage from "../../../Utils/storage";

function LoadingPayment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const email = new URLSearchParams(search).get("emailOrNumber");

  useEffect(() => {
    dispatch(paymentGatewayStatusRequest(token, email));
    storage.removeStorage("PatientAge");
    storage.removeStorage("PatientGender");
    storage.removeStorage("PatientName");
  }, []);

  const paymentGatewayStatus = useSelector(
    (state) => state.cart.paymentGatewayStatus
  );

  return (
    <div className="loadingPayment">
      {paymentGatewayStatus.isLoading ? (
        <>
          {" "}
          <h2 className="headingLoading">Your Payment is Being Processed</h2>
          <h3 className="headingLoading">Please Wait</h3>
          <img src={Loader} className="imgLoader" alt="loader"/>
        </>
      ) : (
        <>
          {paymentGatewayStatus.data !== null &&
            paymentGatewayStatus.data?.data.data.data.paymentStatus === "PAID" ? (
            <ConfirmPayment />
          ) : (
            <FailedPayment />
          )}
          {paymentGatewayStatus.error !== null && navigate("/")}
        </>
      )}
    </div>
  );
}

export default LoadingPayment;