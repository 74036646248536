import { Landing } from "./landingRoute";
import { User } from "./userRoutes";
import { Network } from "./networkRoutes";
import { Cart } from "./cartRoutes";
import { Services } from "./servicesRoutes";
import { Company } from "./companyRoutes";
import { Plan } from "./planRoutes";
import { HealthCheckup } from "./healthCheckupRoutes";
import { Diagnostic } from "./diagnosticRoutes";
import { Subscribe } from "./subscribeRoutes";
import { HRA } from "./hraRoutes";
import { Misc } from "./miscellaneousRoutes";
import { Account } from "./accountRoutes";
import { PlansAndBookings } from "./PlansAndBookingRoute";
import { ERM } from "./ermRoutes"
import { MentalWellness } from "./MentalWellness";
import { SearchPage } from "./SearchPage";
import { Products } from "./productsRoutes";

export const RouteLocation = {
  Landing,
  User,
  Network,
  Cart,
  Services,
  Company,
  Plan,
  HealthCheckup,
  Diagnostic,
  Subscribe,
  HRA,
  Misc,
  Account,
  PlansAndBookings,
  ERM,
  MentalWellness,
  SearchPage,
  Products
};
