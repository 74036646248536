import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/EcomUser/EcomUserActionType";
import * as actions from "../../Action/EcomUser/viewAllProductsAction";
import NetworkService from "../../../network/Network/EcomUser/EcomUserNetwork";

export function* viewAllProductsSaga(action) {
  try {
    let response = yield call(NetworkService.viewAllProducts, action);
    yield put(actions.viewAllProductsSuccess(response));
  } catch (err) {
    yield put(actions.viewAllProductsFailure(err));
  }
}

export function* watchViewAllProductsSaga() {
  yield takeLatest(types.VIEW_ALL_PRODUCTS_REQUEST, viewAllProductsSaga);
}