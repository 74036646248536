import RequestService from "../../../RequestService";
import { api } from "../../../../helpers/OTP/OTPValidation/otpValidationConstant";

class NetworkService {
  validateOtp(action) {
   
    const endPoint = `${api.validateOtp}`;
    return RequestService.postRequest(endPoint,{
      emailOrNumber:action.data.email,
      otp:action.data.otp,
      name:action.data.name
    });
    
  }
  
}

export default new NetworkService();