import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const GetSection8AnswersRequest = (data) => {
    return {
        type: types.GET_SECTION_8_ANSWERS_REQUEST,
        data:data
    }
}

export const  GetSection8AnswersSuccess = (data) => {
    return {
        type: types.GET_SECTION_8_ANSWERS_SUCCESS,
        data:data,
    }
}

export const  GetSection8AnswersFailure = (data) => {
    return {
        type: types.GET_SECTION_8_ANSWERS_FAILURE,
        data:data,
    }
}