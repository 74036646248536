import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Order/OrderActionType";
import * as actions from "../../Action/Order/GetAllOrderHistoryAction";
import NetworkService from "../../../network/Network/Order/GetOrderNetwork";

export function* getAllOrderHistorySaga(action) {
  try {
    let response = yield call(NetworkService.getAllOrderHistory, action);
    yield put(actions.getAllOrderHistorySuccess(response));
  } catch (err) {
    yield put(actions.getAllOrderHistoryFailure(err));
  }
}

export function* watchGetAllOrderHistorySaga() {
  yield takeLatest(types.GET_ALL_ORDER_HISTORY_REQUEST, getAllOrderHistorySaga);
}
