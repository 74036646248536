import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import apple from "../../../assets/Health_Checkups_Assests/apple.png";
import Chat_bubble_outline from "../../../assets/TalkToDoctorAssests/Chat-bubble-outline.png";
import DoctorMedical from "../../../assets/TalkToDoctorAssests/DoctorMedical.png";
import googleplaybadge from "../../../assets/talktodoctorworking/googleplaybadge.png";

function BookAppointmentCarousel() {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  function handleclose() {
    setOpen(false);
  }
  function handleClick() {
    setOpen(true);
  }
  function handleProceedButton() {
    navigate("/subscription");
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <>
      {open && (
        <div>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="mainModal"
          >
            <Box className="style1">
              <div className="TellUsAboutYourselfMain">
                <div className="TalkToDoctorModalRelationsBox">
                  <div className="TalkToDoctorModalBoxOuter">
                    <div className="TalktodocmodalHeading">
                      To Avail Talk to Doctor consultation please subscribe to
                      Yuva Health Plans
                    </div>
                  </div>
                  <div className="TalkToDoctorBtnSaveRealtions">
                    <button
                      className="TTDButtonSaveRealtions"
                      onClick={handleclose}
                    >
                      Close
                    </button>
                    <button
                      className="TTDButtonSaveRealtions"
                      onClick={handleProceedButton}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      )}
      <div className="bookAppointmentBody">
        <div className="bookAppointmentGrid">
          <div className="bookappointmentPicGrid">
            {" "}
            <img
              className="bookAppointPic3"
              src={DoctorMedical}
              alt="Online consultaion by doctor"
            ></img>
          </div>
          <div className="bookAppointmentContentGrid">
            <div className="bookAppointmentContentGridHeading">
              {" "}
              Dedicated Team of Doctor{" "}
            </div>

            <div className="points2">
              <ol>
                <li className="bb1">
                  Doctors from across specialties under a single roof.{" "}
                </li>
                <li className="bb1">
                  Available 24/7 to provide top-quality, personalized care
                  whenever you need it
                </li>
                <li className="bb1">24-hour support team to assist you</li>
              </ol>
            </div>

            <div className="chatwithusTTD">
              <button className="BookAppointBtn " onClick={() => handleClick()}>
                Chat with Us
                <img
                  className="ChatLogo_TalkToDoctor"
                  src={Chat_bubble_outline}
                  alt="Chat icon"
                ></img>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="HraHealthRisk_BlockThree">
        <div
          style={{
            background:
              "linear-gradient(90.56deg, #1c71e1 0.48%, #c2d0e3 55.86%, #1c71e1 97.15%) 0% 0% / 100% no-repeat",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
          }}
          className="CashlessOPDHeadingImage"
        >
          <div className="CashlessOPD_Heading1">
            <div className="OPDheading">
              Download our app to access your reports...
            </div>
            <div className="iconandroidios">
              <a
                href="https://play.google.com/store/apps/details?id=com.yuva"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="androidStore"
                  src={googleplaybadge}
                  alt="play store"
                ></img>
              </a>
              <a
                href="https://apps.apple.com/in/app/yuvahealth/id6449449413"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="iosStore" src={apple} alt="app store"></img>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BookAppointmentCarousel;
