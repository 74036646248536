import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/MentalWellness/OnMoodNineActionType";
import * as actions from "../../Action/MentalWellness/OnMoodNineAction";
import NetworkService from "../../../network/Network/MentalWellness/MentalWellnessNetwork";

export function* getOnMoodNineSaga(action) {
  try {
    let response = yield call(NetworkService.getOnMoodNine, action);
    yield put(actions.getOnMoodNineSuccess(response));
  } catch (err) {
    yield put(actions.getOnMoodNineFailure(err));
  }
}

export function* watchGetOnMoodNineSaga() {
  yield takeLatest(types.GET_ON_MOOD_NINE_REQUEST, getOnMoodNineSaga);
}
