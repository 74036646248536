import React from "react";
import "./AllBrand.css";

function AllBrand({
  content,
  handleCheckboxChange,
  checkboxes,
  setMoreBrandClick,
  name,
}) {
  return (
    <div className="FilterDirectory-panel">
      <div className="FilterDirectory-titleBar">
        <div className="FilterDirectory-titleBar-title">{name}</div>
        <span className="close-btn" onClick={() => setMoreBrandClick(false)}>
          &times;
        </span>
      </div>
      <ul className="FilterDirectory-list">
        {content.map((item, index) => (
          <li>
            <label key={index} className="common-customCheckbox">
              <input
                type="checkbox"
                value={item.id}
                checked={checkboxes?.includes(item.id)}
                onChange={handleCheckboxChange}
              />
              {item.name}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AllBrand;
