import { combineReducers } from "redux";
import { continueSectionReducer } from "./continueSectionReducer";
import { deleteSectionAnswersReducer } from "./deleteSectionAnswersReducer";
import { getHraQuestionsReducer } from "./getHraQuestionsReducer";
import { getLatestSectionReducer } from "./getLatestSectionReducer";
import { getRelationsReducer } from "./getRelationsReducer";
import { saveReportReducer } from "./saveReportReducer";
import { saveSectionAnswersReducer } from "./saveSectionAnswersReducer";

export default combineReducers({
    continueSection: continueSectionReducer,
    deleteSectionAnswers: deleteSectionAnswersReducer,
    getHraQuestions: getHraQuestionsReducer,
    getLatestSection: getLatestSectionReducer,
    getRelations: getRelationsReducer,
    saveReport: saveReportReducer,
    saveSectionAnswers: saveSectionAnswersReducer,
})