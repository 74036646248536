import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Network/networkActionType";
import * as actions from "../../Action/Network/viewNetworkCountAction";
import NetworkService from "../../../network/Network/Network/NetworkPageNetwork";

export function* viewNetworkCountSaga(action) {
  try {
    let response = yield call(NetworkService.viewNetworkCount);
    yield put(actions.viewNetworkCountSuccess(response));
  } catch (err) {
    yield put(actions.viewNetworkCountFailure(err));
  }
}

export function* watchViewNetworkCountSaga() {
  yield takeLatest(types.VIEW_NETWORK_COUNT_REQUEST, viewNetworkCountSaga);
}