export const api = {
  hraQuestions: "/hra/questions",
  saveReport: "/hra/answers2",
  ContinueSection: "/hra/section-continue",
  SaveSectionAnswers: "/hra/section",
  DeleteSectionAnswers: "/hra/section",
  GetSectionAnswers: "/hra/section",
  GetHraQuestions: "/hra/questions",
  Relations: "/relation/dropdown",
  GetLatestSection:"/hra/section-latest"
};
