import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import apple from "../../../assets/Health_Checkups_Assests/apple.png";
import CashlessODPbanner2 from "../../../assets/OPD Cashless/CashlessODPbanner2.png";
import Cashlesspic1 from "../../../assets/OPD Cashless/Cashlesspic1.png";
import Cashlesspic10 from "../../../assets/OPD Cashless/Cashlesspic10.png";
import Cashlesspic11 from "../../../assets/OPD Cashless/Cashlesspic11.png";
import Cashlesspic12 from "../../../assets/OPD Cashless/Cashlesspic12.png";
import Cashlesspic13 from "../../../assets/OPD Cashless/Cashlesspic13.png";
import Cashlesspic14 from "../../../assets/OPD Cashless/Cashlesspic14.png";
import Cashlesspic15 from "../../../assets/OPD Cashless/Cashlesspic15.png";
import Cashlesspic16 from "../../../assets/OPD Cashless/Cashlesspic16.png";
import Cashlesspic17 from "../../../assets/OPD Cashless/Cashlesspic17.png";
import Cashlesspic18 from "../../../assets/OPD Cashless/Cashlesspic18.png";
import Cashlesspic19 from "../../../assets/OPD Cashless/Cashlesspic19.png";
import Cashlesspic20 from "../../../assets/OPD Cashless/Cashlesspic20.png";
import Cashlesspic6 from "../../../assets/OPD Cashless/Cashlesspic6.png";
import Cashlesspic7 from "../../../assets/OPD Cashless/Cashlesspic7.png";
import Cashlesspic8 from "../../../assets/OPD Cashless/Cashlesspic8.png";
import Cashlesspic9 from "../../../assets/OPD Cashless/Cashlesspic9.png";
import Cashlesspic2 from "../../../assets/OPD Cashless/casslesspic2.png";
import Cashlesspic3 from "../../../assets/OPD Cashless/casslesspic3.png";
import Cashlesspic4 from "../../../assets/OPD Cashless/casslesspic4.png";
import Cashlesspic5 from "../../../assets/OPD Cashless/casslesspic5.png";
import googleplaybadge from "../../../assets/talktodoctorworking/googleplaybadge.png";
import Title from "../../Atoms/Title/Title";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import "./CashlessOPD.css";

function CashlessOPD() {
  const navigate = useNavigate();

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>Online Doctor Consultation | YUVA</title>
        <meta
          name="description"
          content=" Get expert medical advice with our online doctor consultation services. Book your online medical appointment for quick and convenient healthcare."
        />
        <meta name="keywords" content="Online Doctor Consultation " />
      </Helmet>
      <div className="CashlessOPD_OuterContainer">
        <div className="CashlessOPDHeadingImage">
          <div className="CashlessOPD_Heading">
            <div className="CashlessOPD_HeadingTitle">
              OPD Consultation Program
            </div>
            <div className="OPDSubHeadingParaContainer">
              <div className="OPDSubHeadingPara">
                Begin Consultations at your own comfort - at just ₹599
              </div>
            </div>
          </div>
          <div className="CashlessODPbanner2">
            <img
              className="CashlessODPbanner2Image"
              src={CashlessODPbanner2}
              alt="Doctor Patient"
            ></img>
          </div>
        </div>

        <div className="CashlessOPD_BlockTwo">
          <div className="CashlessOPD_BlockTwoHeading">
            What Is OPD Consultation Program
          </div>
          <div className="CashlessOPD_BlockTwoSubHeading">
            This exclusive OPD Consultation program offers you OPD consultation
            in all of our networks of hospitals and clinics. Based on your
            requirements, this benefit can be availed for all the family members
            enrolled under this subscription program.
          </div>
          <div className="CashlessOPD_BlockTwoSubHeading_Button">
            {localStorage.getItem("token") === null ? (
              <button onClick={() => navigate("/login")}>
                Try Our OPD Consultation Program
              </button>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="CashlessOPD_Banner">
          <div
            className="CashlessOPDHeadingImage"
            style={{
              background:
                "linear-gradient(90.56deg, #1c71e1 0.48%, #c2d0e3 55.86%, #1c71e1 97.15%) 0% 0% / 100% no-repeat",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100%",
            }}
          >
            <div className="CashlessOPD_Heading1">
              <div className="OPDheading">
                Download our app to Book an Appointment
              </div>
              <div className="iconandroidios">
                <a
                  href="https://play.google.com/store/apps/details?id=com.yuva"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="androidStore"
                    src={googleplaybadge}
                    alt="Android store"
                  ></img>
                </a>
                <a href="https://apps.apple.com/in/app/yuvahealth/id6449449413">
                  <img className="iosStore" src={apple} alt="IOS Store"></img>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="CashlessOPD_BlockFour">
          <div className="CashlessOPD_BlockFourHeading">SPECIALITIES</div>
          <div className="CashlessOPD_BlockFourSubHeading">
            A whole spectrum of specialties to give you the best healthcare
            experience
          </div>

          <div className="CashlessOPD_BlockFour_grid-container">
            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic1})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">
                Internal medicine
              </div>
            </div>

            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic2})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">Surgery</div>
            </div>

            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic3})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">Pediatrics</div>
            </div>

            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic4})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">Neurology</div>
            </div>

            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic5})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">Dermatology</div>
            </div>

            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic6})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">
                Obstetrics and <br />
                Gynaecology
              </div>
            </div>

            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic7})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">
                Family medicine
              </div>
            </div>

            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic8})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">Psychiatry</div>
            </div>

            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic9})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">Radiology</div>
            </div>

            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic10})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">Ophthalmology</div>
            </div>

            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic11})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">Orthopedics</div>
            </div>

            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic12})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">Pathology</div>
            </div>

            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic13})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">
                General surgery
              </div>
            </div>

            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic14})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">
                Physical therapy
              </div>
            </div>

            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic15})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">
                Otorhinolaryngology
              </div>
            </div>

            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic16})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">Urology</div>
            </div>

            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic17})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">Oncology</div>
            </div>

            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic18})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">Cardiology</div>
            </div>
            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic19})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">
                Plastic surgery
              </div>
            </div>
            <div className="CashlessOPD_BlockFour_grid-items">
              <div
                className="CashlessOPD_BlockFour_grid-item"
                style={{
                  backgroundImage: `url(${Cashlesspic20})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></div>
              <div className="CashlessOPD_BlockFourSubHead">Rheumatology</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CashlessOPD;
