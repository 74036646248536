import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const GetSection5AnswersRequest = (data) => {
    return {
        type: types.GET_SECTION_5_ANSWERS_REQUEST,
        data:data
    }
}

export const  GetSection5AnswersSuccess = (data) => {
    return {
        type: types.GET_SECTION_5_ANSWERS_SUCCESS,
        data:data,
    }
}

export const  GetSection5AnswersFailure = (data) => {
    return {
        type: types.GET_SECTION_5_ANSWERS_FAILURE,
        data:data,
    }
}