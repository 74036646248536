import RequestService from "../../RequestService";
import { api } from "../../../helpers/CorporateSignUp/CorporateSignUpConstants";

class NetworkService {
    getCorporateSignUpTokenInfo(action) {
    const endPoint = `${api.getCorporateSignUpTokenInfo}?corporateId=${action.id}&token=${action.token}`
    return RequestService.getRequest(endPoint);
  }

}

export default new NetworkService();
