import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Network/networkActionType";
import * as actions from "../../Action/Network/ConnectionBetweenUsAction";
import NetworkService from "../../../network/Network/Network/NetworkPageNetwork";

export function* connectionBetweenUsSaga(action) {
  try {
    let response = yield call(NetworkService.connectionBetweenUs, action);
    yield put(actions.connectionBetweenUsSuccess(response));
  } catch (err) {
    yield put(actions.connectionBetweenUsFailure(err));
  }
}

export function* watchConnectionBetweenUsSaga() {
  yield takeLatest(types.CONNECTION_BETWEEN_US_REQUEST, connectionBetweenUsSaga);
}