import { combineReducers } from "redux";
import { addRelationReducer } from "./addRelationReducer";
import { checkHashIdReducer } from "./checkHashIdReducer";
import { forgetPasswordReducer } from "./forgetPasswordReducer";
import { getAllRelativesReducer } from "./getAllRelativesReducer";
import { getProfileReducer } from "./getProfileReducer";
import { googleLoginReducer } from "./loginGoogleReducer";
import { loginReducer } from "./loginReducer";
import { otpMobileVerificationReducer } from "./otpMobileVerificationReducer";
import { registerReducer } from "./registerReducer";
import { resetPasswordReducer } from "./resetPasswordReducer";
import { updateProfileReducer } from "./updateProfileReducer";
import { uploadFamilyPicReducer } from "./uploadFamilyPicReducer";
import { uploadProfilePictureReducer } from "./uploadProfilePictureReducer";
import {logoutUserReducer} from "./logoutUserReducer";



export default combineReducers({
    addRelation: addRelationReducer,
    checkHashId: checkHashIdReducer,
    forgetPassword: forgetPasswordReducer,
    getAllRelatives: getAllRelativesReducer,
    getProfile: getProfileReducer,
    googleLogin: googleLoginReducer,
    login: loginReducer,
    otpMobileVerification: otpMobileVerificationReducer,
    register: registerReducer,
    resetPassword: resetPasswordReducer,
    updateProfile: updateProfileReducer,
    uploadFamilyPic: uploadFamilyPicReducer,
    uploadProfilePicture: uploadProfilePictureReducer,
    logoutUser:logoutUserReducer,
})