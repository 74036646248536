import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Plan/PlanActionType";
import * as actions from "../../Action/Plan/GetPlanServices";
import NetworkService from "../../../network/Network/Plan/GetPLanDetailNetwork";

export function* getPlanServicesSaga(action) {
  try {
    let response = yield call(NetworkService.getPlanServices, action);
    yield put(actions.getPlanServicesSuccess(response));
  } catch (err) {
    yield put(actions.getPlanServicesFailure(err));
  }
}

export function* watchgetPlanServicesSaga() {
  yield takeLatest(types.GET_PLAN_SERVICES_REQUEST, getPlanServicesSaga);
}
