import * as types from "../../Config/ElasticSearch/ElasticSearchActionType"

export const elasticSearchRequest = (data) => {
    return {
        type: types.ELASTIC_SEARCH_REQUEST,
        data,
    }
}

export const  elasticSearchSuccess = (data) => {
    return {
        type: types.ELASTIC_SEARCH_SUCCESS,
        data
    }
}

export const  elasticSearchFailure = (data) => {
    return {
        type: types.ELASTIC_SEARCH_FAILURE,
        data
    }
}