import * as types from "../../Config/User/userActionType"

export const userCheckHashIdRequest = (data) => {
    return {
        type: types.CHECK_HASH_ID_REQUEST,
        data:data
    }
}

export const userCheckHashIdSuccess = (data) => {
    return {
        type: types.CHECK_HASH_ID_SUCCESS,
        data:data,
    }
}

export const userCheckHashIdFailure = (data) => {
    return {
        type: types.CHECK_HASH_ID_FAILURE,
        data:data,
    }
}