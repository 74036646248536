import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"



export const  Section1AnswersSuccess = (data) => {
    return {
        type: types.SECTION1_ANSWERS_SUCCESS,
        data:data,
    }
}


