import React, {useState } from "react";
import { useNavigate } from "react-router-dom";
import halfShade from "../../../assets/LandingPage_Services/halfShade.png";
import ladyDoctor from "../../../assets/LandingPage_Services/ladyDoctor.png";
import underline from "../../../assets/LandingPage_Services/underline.png";
import BookAppointment from "./BookAppointment";
import "./HealthAdvisorPage.css";

function HealthAdvisorPage() {
  var nav = false;
  // const appointmentRef = useRef(null);

  const navigate = useNavigate();
  const [openAppointment, setOpenAppointment] = useState(false)

  function handleAppointment() {
    setOpenAppointment(true);
  }

  function closeAppointment(boolean) {
    setOpenAppointment(boolean);
  }

  const handleBookPopularTest = () => {
    localStorage.setItem(
      "viewEvery",
      JSON.stringify({
        radioItem: "NO_RANGE",
        minPrice: 0,
        maxPrice: 10000,
        allChecked: ["Test"],
      })
    );
    navigate("/view-test");
  };

  const handleBookPackage = () => {
    localStorage.setItem(
      "viewEvery",
      JSON.stringify({
        radioItem: "NO_RANGE",
        minPrice: 0,
        maxPrice: 10000,
        allChecked: ["Packages"],
      })
    );
    navigate("/view-test");
  };

  const handleRadiology = () => {
    localStorage.setItem(
      "viewEvery",
      JSON.stringify({
        radioItem: "NO_RANGE",
        minPrice: 0,
        maxPrice: 10000,
        allChecked: [],
        radiology: true,
      })
    );
    navigate("/view-test");
  };

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (appointmentRef.current && !appointmentRef.current.contains(event.target)) {
  //       setOpenAppointment(false);
  //     }
  //   }
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [appointmentRef]);

  return (
    <div className="frontPage">
      {openAppointment ?  
        <BookAppointment closeAppointment={closeAppointment} nav={nav} />
        : ""
        }
      <div className="frontPageUpper">
        <p>Custom Care Solutions:</p>
        <p>Tailored Healthcare Packages</p>
        <img src={underline} alt="underline" />
        <p>for Your Peace of Mind</p>
        <p>
          Designed to provide you with comprehensive healthcare solutions
          tailored
          <br /> to your needs. With our packages, you can enjoy peace of mind
          knowing
          <br /> that your health is our priority
        </p>
        <button type="button" onClick={handleAppointment}>Book an Appointment</button>
        <img src={halfShade} className="halfShade" alt="shade" />
      </div>
      <div className="frontPageLower">
        <img src={ladyDoctor} className="ladyDoctor" alt="Landing Doctor" />
        <div className="frontPageLowerButtonArea">
          <div className="frontPageLowerButtonAreaDiv">
            <button className="frontPageLowerButtonAreaButton" onClick={handleBookPopularTest}>
              Book Test
            </button>
          </div>
          <div className="frontPageLowerButtonAreaDiv2">
            <button className="frontPageLowerButtonAreaButton" onClick={handleBookPackage}>
              Popular Package
            </button>
          </div>
          <div className="frontPageLowerButtonAreaDiv3">
            <button className="frontPageLowerButtonAreaButton" onClick={handleRadiology}>
              X-Ray & Scan
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HealthAdvisorPage;
