import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import storage from "../../../Utils/storage";
import downArrowOrange from "../../../assets/MyPackageBooking/downArrow.png";
import D80 from "../../../assets/MyPackageBooking/new/80D.png";
import ambulance from "../../../assets/MyPackageBooking/new/ambulance.png";
import discount from "../../../assets/MyPackageBooking/new/discount.svg";
import emrm from "../../../assets/MyPackageBooking/new/emrm.png";
import hra from "../../../assets/MyPackageBooking/new/hra.png";
import insu from "../../../assets/MyPackageBooking/new/insu.png";
import mental from "../../../assets/MyPackageBooking/new/mental.png";
import myTest from "../../../assets/MyPackageBooking/new/myTest.png";
import online from "../../../assets/MyPackageBooking/new/online.png";
import opd from "../../../assets/MyPackageBooking/new/opd.svg";
import pharma from "../../../assets/MyPackageBooking/new/pharma.png";
import upArrow from "../../../assets/MyPackageBooking/upArrow.png";
import healthcard from "../../../assets/PlanLocking/healthcard.png";
import lock1 from "../../../assets/PlanLocking/lock1.png";
import lock2 from "../../../assets/PlanLocking/lock2.png";
import { GetRelationsRequest } from "../../../redux/Action/HealthRiskAssessment/GetRelationsAction";
import { getPlanSubscriptionRequest } from "../../../redux/Action/Order/GetPlanSubscriptionAction";
import { planLockRequest } from "../../../redux/Action/Plan/planLockAction";
import { GetAllRelativesRequest } from "../../../redux/Action/User/GetAllRelatives";
import Title from "../../Atoms/Title/Title";
import RelationshipModal from "../Cart/RelationshipModal";
import NoSubscription from "../MyAccount/NoSubscription";
import "./MyPackageBooking.css";
import "./MySubscriptionMobile.css";
import OrderDetailsModal from "./OrderDetailsModal";

function MySubscription() {
  const dispatch = useDispatch();

  const [OrderDetails, setOrderDetails] = useState(false);
  const [activeKey, setActiveKey] = useState();
  const navigate = useNavigate();

  const uuidImgMaps = {
    "7cb4491e-f058-4f9b-b14e-76dc04802136": mental,
    "5fa298d7-afda-44ea-b452-8aeced24eca6": emrm,
    "7d9a2b3c-905d-4830-b468-84755b7ba5bf": ambulance,
    "61cef9e9-5e7b-4082-aaf8-89606accfc4a": insu,
    "3ec08601-c446-4de7-99e1-fa246e5662bb": D80,
    "3089855d-85a1-4c2f-9538-c8276cd76768": discount,
    "b5aaaf86-e1f3-4acb-97a2-d5198ee4e7bb": pharma,
    "0641b94d-16c4-430e-93ce-7877123d0574": opd,
    "ee5413dd-eb09-4a99-92d0-a4fc6d92a5e9": myTest,
    "1dbcc55e-3dec-4e07-8c2a-e222631afebb": hra,
    "bb4385d4-7f92-11ed-a1eb-0242ac120002": online,
  };

  useEffect(() => {
    dispatch(getPlanSubscriptionRequest(""));
  }, [dispatch]);

  const [value, setValue] = React.useState();

  useEffect(() => {
    const savedTabValue = localStorage.getItem("selectedTab");
    if (savedTabValue) {
      setValue(savedTabValue);
    } else {
      setValue("1");
    }
  }, []);

  const [openclick, setopenClick] = useState(false);

  const handleChange = (newValue) => {
    setValue(newValue);
    localStorage.setItem("selectedTab", newValue);
  };

  const [values, setValues] = useState([]);

  const handleCheckboxChange = (index, e) => {
    const item = index;

    if (e.target.checked) {
      setValues([...values, index]);
    } else {
      setValues(values.filter((i) => i !== item));
    }
  };

  const getPlanSubscription = useSelector(
    (state) => state.order.getPlanSubscription
  );
  const [keys, setKeys] = useState(false);

  function handleAddMember(key) {
    dispatch(
      GetRelationsRequest(
        getPlanSubscription?.data?.data?.data?.data
          ?.userPlanOrderHistoryResponseDtoList[key]
      )
    );
    setKeys(key);
  }

  function handleAddMemberInactive() {
    Swal.fire({
      title: "Card Inactive",
      text: "Your Card Will be Activated Soon",
      icon: "warning",
    });
  }
  function planLock(index) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const array = [];
        var childrenCount = 0;

        for (let i = 0; i < values.length; i++) {
          if (
            GetAllRelatives?.data?.data?.data?.data[values[i]]?.relation ===
            "Son"
          ) {
            childrenCount++;
          }
          if (
            GetAllRelatives?.data?.data?.data?.data[values[i]]?.relation ===
            "Daughter"
          ) {
            childrenCount++;
          }
        }

        if (
          childrenCount <=
          getPlanSubscription?.data?.data?.data?.data
            ?.userPlanOrderHistoryResponseDtoList[index]?.childrenCount
        ) {
          for (let i = 0; i < values.length; i++) {
            array.push(GetAllRelatives?.data?.data?.data?.data[values[i]]?.id);
          }

          const data = {
            uuid: getPlanSubscription?.data?.data?.data?.data
              ?.userPlanOrderHistoryResponseDtoList[index]?.uuid,
            relationId: array,
            userVersion:
              getPlanSubscription?.data?.data?.data?.data
                ?.userPlanOrderHistoryResponseDtoList[index]?.userVersion,
            version:
              getPlanSubscription?.data?.data?.data?.data
                ?.userPlanOrderHistoryResponseDtoList[index]?.version,
          };
          dispatch(planLockRequest(data));

          window.location.reload();
        } else {
          Swal.fire({
            title: "Child count exceeded! ",
            text: `You can add only ${getPlanSubscription?.data?.data?.data?.data?.userPlanOrderHistoryResponseDtoList[index]?.childrenCount} child`,
            icon: "error",
          }).then(() => {
            window.location.reload();
          });
        }
      }
    });
  }
  const userPlanList =
    getPlanSubscription?.data?.data?.data?.data
      ?.userPlanOrderHistoryResponseDtoList;

  const localActiveKey = localStorage.getItem("activeKey");

  useEffect(() => {
    if (localActiveKey && userPlanList) {
      const key = localActiveKey;
      const data = {
        uuid: userPlanList[key]?.uuid,
        version: userPlanList[key]?.version,
        userVersion: userPlanList[key]?.userVersion,
      };
      dispatch(GetAllRelativesRequest(data));
    }
  }, [localActiveKey, dispatch, userPlanList]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("activeKey");
      localStorage.removeItem("selectedTab");
    };
  }, []);

  useEffect(() => {
    const savedActiveKey = localStorage.getItem("activeKey");
    if (savedActiveKey) {
      setActiveKey(Number(savedActiveKey));
    }
  }, []);

  const GetAllRelatives = useSelector((state) => state.user.getAllRelatives);

  const handleDivClickRelation = (key) => {
    const data = {
      uuid: getPlanSubscription?.data?.data?.data?.data
        ?.userPlanOrderHistoryResponseDtoList[key]?.uuid,

      version:
        getPlanSubscription?.data?.data?.data?.data
          ?.userPlanOrderHistoryResponseDtoList[key]?.version,
      userVersion:
        getPlanSubscription?.data?.data?.data?.data
          ?.userPlanOrderHistoryResponseDtoList[key]?.userVersion,
    };
    setValue("2");
    localStorage.setItem("selectedTab", "2");
    dispatch(GetAllRelativesRequest(data));
    dispatch(
      GetRelationsRequest(
        getPlanSubscription?.data?.data?.data?.data?.userProgramResponseDtoList[
          key
        ]?.uuid
      )
    );
  };

  const handleDivClick = (key) => {
    setActiveKey(key === activeKey ? null : key);
    const data = {
      uuid: getPlanSubscription?.data?.data?.data?.data
        ?.userPlanOrderHistoryResponseDtoList[key]?.uuid,

      version:
        getPlanSubscription?.data?.data?.data?.data
          ?.userPlanOrderHistoryResponseDtoList[key]?.version,
      userVersion:
        getPlanSubscription?.data?.data?.data?.data
          ?.userPlanOrderHistoryResponseDtoList[key]?.userVersion,
    };
    dispatch(GetAllRelativesRequest(data));
    dispatch(
      GetRelationsRequest(
        getPlanSubscription?.data?.data?.data?.data?.userProgramResponseDtoList[
          key
        ]?.uuid
      )
    );
  };

  const GetRelations = useSelector((state) => state.hra.getRelations);

  useEffect(() => {
    if (GetRelations?.data?.data?.data?.data?.length === 0) {
      Swal.fire({
        title: "No Active relations left for this plan",
        icon: "warning",
      }).then(() => {
        // window.location.reload();
      });
    } else if (GetRelations?.data?.data?.data?.data?.length > 0) {
      setopenClick(true);
    }
  }, [GetRelations?.data?.data?.data?.data?.length]);

  const handleDataFromChild = () => {
    const data = {
      uuid: getPlanSubscription?.data?.data?.data?.data
        ?.userPlanOrderHistoryResponseDtoList[keys]?.uuid,

      version:
        getPlanSubscription?.data?.data?.data?.data
          ?.userPlanOrderHistoryResponseDtoList[keys]?.version,
      userVersion:
        getPlanSubscription?.data?.data?.data?.data
          ?.userPlanOrderHistoryResponseDtoList[keys]?.userVersion,
    };

    dispatch(GetAllRelativesRequest(data));
    setopenClick(false);
  };

  return (
    <>
      <Title title="YUVA | Subscription" />
      {openclick && (
        <RelationshipModal
          open={setopenClick}
          sendDataToParent={handleDataFromChild}
          state={
            getPlanSubscription?.data?.data?.data?.data
              ?.userPlanOrderHistoryResponseDtoList[keys]
          }
        />
      )}

      <div className="MyPackageBookingMain">
        <div className="MyPackageBookingMainChild1">
          <p>My Subscriptions</p>
        </div>
        {OrderDetails && (
          <OrderDetailsModal OrderDetails={setOrderDetails} id={null} />
        )}
        {getPlanSubscription?.data?.data?.data?.data
          .userPlanOrderHistoryResponseDtoList.length !== 0 ? (
          <div className="MyPackageBookingMainChild2Subs">
            {storage.getStorage("token") !== null ? (
              <>
                {getPlanSubscription?.data?.data?.data?.data.userPlanOrderHistoryResponseDtoList.map(
                  (item, orderIndex) => (
                    <div className="MyPackageBookingMainChild2DivsSubs">
                      {item.couponName !== null ? (
                        <div className="subsCoupon">
                          {item.couponName} Coupon applied successfully. ₹
                          {item.couponDiscount} discount applied to your order.
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="MyPackageBookingMainChild2DivsUpper">
                        <div className="MyPackageBookingMainChild2DivsUpperChild1">
                          <span>DATE OF PURCHASE</span>
                          <span>
                            {item.dateOfPurchase
                              ? new Date(
                                  item.dateOfPurchase
                                ).toLocaleDateString("en-GB")
                              : ""}
                          </span>
                        </div>
                        <div className="MyPackageBookingMainChild2DivsUpperChild1">
                          <span>TOTAL AMOUNT</span>
                          <span
                            className={
                              item.totalAmount !== item.amountPaid
                                ? "subsTotalAmount"
                                : "subsTotalAmount2"
                            }
                            style={{ color: "#1C71E1" }}
                          >
                            ₹{item.totalAmount}
                          </span>
                        </div>
                        <div className="MyPackageBookingMainChild2DivsUpperChild1">
                          <span>AMOUNT PAID</span>
                          <span>₹{item.amountPaid}</span>
                        </div>
                        <div className="MyPackageBookingMainChild2DivsUpperChild1">
                          <span>CUSTOMER NAME</span>
                          <span>{item.customerName}</span>
                        </div>
                        <div className="MyPackageBookingMainChild2DivsUpperChild1">
                          <span> ORDER NUMBER - {item.orderNumber}</span>

                          <a
                            href={item.invoiceFilePath}
                            download="Invoice"
                            className="downloadInvoiceAnchorTag"
                          >
                            Download Invoice
                          </a>
                        </div>
                      </div>
                      <div className="MyPackageBookingMainChild2DivsLower">
                        <div className="MyPackageBookingMainChild2DivsLowerChild1">
                          <div
                            className="YuvaHealthCard"
                            style={{
                              backgroundImage: `url(${healthcard})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "100% 100%",
                            }}
                          >
                            <div className="cardContainer">
                              <p className="cardHeading">YUVA HEALTH CARD</p>

                              <p className="cardCategory">{item.planName}</p>
                              <p className="cardName">
                                {item.cardNumber === null
                                  ? " "
                                  : item.customerName}
                              </p>
                              <p>
                                {item.cardNumber === null ? (
                                  <p className="cardName">Inactive</p>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>

                            <div className="cardContainer2">
                              {item.cardNumber === null ? (
                                <p className="cardContainer2Text1">
                                  xxxx xxxx xxxxx
                                </p>
                              ) : (
                                <p className="cardContainer2Text1">
                                  {item.cardNumber}
                                </p>
                              )}
                              <div className="cardContainer2Text2">
                                <p className="cardContainer2Text2data">
                                  Valid Through
                                </p>
                                <p className="cardContainer2Text2data2">
                                  {item.cardNumber === null ? (
                                    <p></p>
                                  ) : (
                                    <p>
                                      {item.validThrough
                                        ? new Date(item.validThrough)
                                            .toLocaleDateString("en-GB")
                                            .slice(3, 10)
                                        : ""}
                                    </p>
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                          <p className="expire">
                            {item.expired === true ? "EXPIRED" : ""}
                          </p>
                        </div>
                        {getPlanSubscription?.data?.data?.data?.data
                          ?.userPlanOrderHistoryResponseDtoList[orderIndex]
                          ?.locked ? (
                          <>
                            {" "}
                            <div className="planMemberLockedButton">
                              <img
                                className="planLockIcon2"
                                src={lock2}
                                alt="lockIcon"
                              />{" "}
                              <p>Locked</p>{" "}
                            </div>
                          </>
                        ) : (
                          <> </>
                        )}
                      </div>{" "}
                      <div className="MyPackageBookingMainChild2DivsLowerChild1_2">
                        <p>{item.planName}</p>
                      </div>
                      <div className="subsOpener">
                        <div className="subsOpener1"></div>
                        {activeKey === orderIndex ? (
                          <img
                            alt="upArrow"
                            src={upArrow}
                            onClick={() => handleDivClick(orderIndex)}
                          />
                        ) : (
                          <img
                            alt="downArrow"
                            src={downArrowOrange}
                            onClick={() => handleDivClick(orderIndex)}
                          />
                        )}

                        <div className="subsOpener2"></div>
                      </div>
                      <div>
                        <div
                          className=""
                          style={{
                            display:
                              activeKey === orderIndex ? "block" : "none",
                            width: "100%",
                          }}
                        >
                          <div>
                            <div value={value}>
                              <div className="subscriptionTab">
                                <div
                                  label="Plan Details"
                                  value="1"
                                  orientation="vertical"
                                  style={
                                    value === "1"
                                      ? {
                                          backgroundColor:
                                            "var(--globalColorButton)",
                                          color: "white",
                                          border: "0.8px solid #1C71E1",
                                          borderRadius: "12px 0px 0px 0px",
                                        }
                                      : {}
                                  }
                                  onClick={() => handleChange("1")}
                                  className="subscriptionPlanDetail"
                                  selected={value === "1"}
                                >
                                  Plan Details
                                </div>
                                <div
                                  style={
                                    value === "2"
                                      ? {
                                          backgroundColor:
                                            "var(--globalColorButton)",
                                          color: "white",
                                          borderRadius: "0px 12px 0px 0px",
                                          border: "0.8px solid #1C71E1",
                                        }
                                      : {}
                                  }
                                  label="Plan Members"
                                  className="subscriptionPlanDetail"
                                  selected={value === "2"}
                                  onClick={() =>
                                    handleDivClickRelation(orderIndex)
                                  }
                                >
                                  Plan Members
                                </div>
                              </div>
                              {value === "1" && (
                                <div value="1">
                                  <div className="MyPlansBody">
                                    {getPlanSubscription?.data?.data?.data?.data
                                      ?.userPlanOrderHistoryResponseDtoList[
                                      orderIndex
                                    ]?.locked ? (
                                      <div></div>
                                    ) : (
                                      <div className="lockMessage">
                                        You need to lock this program to get
                                        benefit from Yuvahealth Services.
                                      </div>
                                    )}

                                    <div className="subscriptionGrid">
                                      {item?.planServiceDtoList?.map(
                                        (item) =>
                                          item?.serviceUuid !==
                                            "ee5413dd-eb09-4a99-92d0-a4fc6d92a5e9" && (
                                            <div className="MyPackageBookingMainChild2DivsLower2Items">
                                              <div className="MyPackageBookingMainChild2DivsLower2Upper">
                                                <div className="MyPackageBookingMainChild2DivsLower2UpperChild1">
                                                  <img
                                                    alt="serviceIcons"
                                                    src={
                                                      uuidImgMaps[
                                                        item.serviceUuid
                                                      ]
                                                    }
                                                  />
                                                </div>
                                                <div className="MyPackageBookingMainChild2DivsLower2UpperChild2">
                                                  <p>{item.serviceName}</p>
                                                  <p>
                                                    Used -
                                                    {
                                                      item
                                                        ?.assignedAttributeResponseDtoList[0]
                                                        ?.used
                                                    }{" "}
                                                    Available -
                                                    {
                                                      item
                                                        ?.assignedAttributeResponseDtoList[0]
                                                        ?.available
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        // }
                                      )}

                                      {item?.planServiceDtoList?.map(
                                        (item) =>
                                          item?.serviceUuid ===
                                            "ee5413dd-eb09-4a99-92d0-a4fc6d92a5e9" &&
                                          item?.assignedAttributeResponseDtoList?.map(
                                            (item) => (
                                              <div className="MyPackageBookingMainChild2DivsLower2Items">
                                                <div className="MyPackageBookingMainChild2DivsLower2Upper">
                                                  <div className="MyPackageBookingMainChild2DivsLower2UpperChild1">
                                                    <img
                                                      alt="serviceIcons"
                                                      src={myTest}
                                                    />
                                                  </div>
                                                  <div className="MyPackageBookingMainChild2DivsLower2UpperChild2">
                                                    <p>{item.name}</p>
                                                    <p>
                                                      Used -{item.used}{" "}
                                                      Available -
                                                      {item.available}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {value === "2" && (
                                <div value="2">
                                  {GetAllRelatives?.data?.data?.data?.data
                                    .length !== 0 ? (
                                    <div>
                                      {getPlanSubscription?.data?.data?.data
                                        ?.data
                                        ?.userPlanOrderHistoryResponseDtoList[
                                        orderIndex
                                      ]?.locked ? (
                                        <div></div>
                                      ) : (
                                        <div className="lockMessage">
                                          You need to lock this program to get
                                          benefit from Yuvahealth Services.
                                        </div>
                                      )}
                                      <div className="planMemberContainer">
                                        {GetAllRelatives?.data?.data?.data?.data?.map(
                                          (item, index) => (
                                            <div className="planMemberCard">
                                              <div className="planMemberCardHeadingBox">
                                                <div className="planMemberName">
                                                  {item.name}
                                                </div>
                                                {getPlanSubscription?.data?.data
                                                  ?.data?.data
                                                  ?.userPlanOrderHistoryResponseDtoList[
                                                  orderIndex
                                                ]?.locked ? (
                                                  <> </>
                                                ) : (
                                                  <>
                                                    {" "}
                                                    <div>
                                                      {" "}
                                                      <FormGroup>
                                                        <FormControlLabel
                                                          control={
                                                            <Checkbox
                                                              disabled={
                                                                getPlanSubscription
                                                                  ?.data?.data
                                                                  ?.data?.data
                                                                  ?.userPlanOrderHistoryResponseDtoList[
                                                                  orderIndex
                                                                ]?.locked
                                                                  ? true
                                                                  : false
                                                              }
                                                              onChange={(e) =>
                                                                handleCheckboxChange(
                                                                  index,
                                                                  e
                                                                )
                                                              }
                                                            />
                                                          }
                                                        />
                                                      </FormGroup>
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                              <div
                                                style={{
                                                  marginTop: getPlanSubscription
                                                    ?.data?.data?.data?.data
                                                    ?.userPlanOrderHistoryResponseDtoList[
                                                    orderIndex
                                                  ]?.locked
                                                    ? "1vw"
                                                    : "0vw",
                                                }}
                                                className="planMemberRelation"
                                              >
                                                {item.relation}
                                              </div>
                                              <div className="planMemberNo">
                                                {item.age}
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      <div className="noMember">
                                        No members available
                                      </div>
                                    </>
                                  )}
                                  {getPlanSubscription?.data?.data?.data?.data
                                    ?.userPlanOrderHistoryResponseDtoList[
                                    orderIndex
                                  ]?.locked ? (
                                    <>
                                      {" "}
                                      <div className="planMemberContainer3">
                                        <div className="planMemberAddMember">
                                          <img
                                            className="planLockIcon2"
                                            src={lock2}
                                            alt="lockIcon"
                                          />{" "}
                                          Locked
                                        </div>
                                      </div>{" "}
                                    </>
                                  ) : (
                                    <>
                                      <div className="planMemberContainer3">
                                        <div>
                                          <div
                                            className="planMemberAddMember"
                                            onClick={() =>
                                              item.cardNumber === null
                                                ? handleAddMemberInactive(
                                                    orderIndex
                                                  )
                                                : handleAddMember(orderIndex)
                                            }
                                          >
                                            <img
                                              className="planLockIcon"
                                              src={lock1}
                                              alt="addIcon"
                                            ></img>{" "}
                                            <p>Click To Add Members</p>
                                          </div>
                                        </div>

                                        <div
                                          className="planMemberAddMember"
                                          onClick={() =>
                                            item.cardNumber === null
                                              ? handleAddMemberInactive(
                                                  orderIndex
                                                )
                                              : planLock(orderIndex)
                                          }
                                        >
                                          <img
                                            className="planLockIcon2"
                                            src={lock2}
                                            alt="Lock"
                                          />{" "}
                                          <p>Lock Plan</p>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </>
            ) : (
              navigate("/")
            )}
          </div>
        ) : (
          <NoSubscription />
        )}
      </div>
    </>
  );
}

export default MySubscription;
