import React from "react";
import blueShade from "../../../assets/blueShade.png";
import youngPeople from "../../../assets/youngPeople.png";
import "./CorporateProgramPage.css";

function CorporateProgramPage() {
  return (
    <>
      <div className="corporateWellnessMain">
        <img src={blueShade} className="blueShade" alt="Blue Shade" />
        <img src={youngPeople} className="youngPeople" alt="Young People" />

        <p>CORPORATE WELLNESS</p>
        <p>
          Introduce Your Employees And Clients To A <br />
          Whole New Healthcare Experience
        </p>
      </div>
    </>
  );
}

export default CorporateProgramPage;
