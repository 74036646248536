import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const GetSection3AnswersRequest = (data) => {
    return {
        type: types.GET_SECTION_3_ANSWERS_REQUEST,
        data:data
    }
}

export const  GetSection3AnswersSuccess = (data) => {
    return {
        type: types.GET_SECTION_3_ANSWERS_SUCCESS,
        data:data,
    }
}

export const  GetSection3AnswersFailure = (data) => {
    return {
        type: types.GET_SECTION_3_ANSWERS_FAILURE,
        data:data,
    }
}