import React from "react";
import "../HRAscreens/HRAInfoScreens.css";
import backArrow from "../../../../assets/hra/backArrow.png";
import hra9a from "../../../../assets/hra/hra9a.png";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../Molecules/ScrollToTop/ScrollToTop";
import storage from "../../../../Utils/storage";
import SingleLogin from "../../Login/SingleLogin";

function HRA9a() {
  const navigate = useNavigate();

  const SectionValue = localStorage.getItem("SectionValue");

  const handlePrevious = () => {
    localStorage.setItem("SectionValue", 8);
    navigate("/health-risk-assessment/section-8");
  };
  window.addEventListener("popstate", function (event) {
    storage.setStorage("SectionValue", 8);
  });
  if (storage.getStorage("token") === null) {
    return <SingleLogin />;
  }
  return (
    <>
      <ScrollToTop />

      <div className="hraInfoScreens">
        <div className="hraInfoScreensChild1">
          <p>Your Health Risk Assessment</p>
        </div>
        {SectionValue === "9" && (
          <div
            className="hraInfoScreensChild2"
            style={{ backgroundImage: `url(${hra9a})` }}
          >
            <div className="hraInfoScreensChild2a">
              <div className="hraInfoScreensChild2a1">
                <p>GENERAL SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>DIETARY SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>MENTAL HEALTH SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>ALCOHOL RISK SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>SMOKING & TOBACCO SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>SAFETY HABITS SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>CURRENT MEDICAL CONDITION SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>HERIDETARY AND GENETIC SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a2">
                <div className="hraInfoBackArrow">
                  <img
                    src={backArrow}
                    onClick={handlePrevious}
                    alt="backArrow"
                  />
                  <p>Hereditary and Genetic</p>
                </div>
                <div className="hraInfo">
                  <p>
                    Thank you for updating us on your Hereditary and Genetic
                    information, Let’s move ahead and discover more about you.
                  </p>
                </div>
                <button
                  onClick={() => navigate("/health-risk-assessment/section-9b")}
                >
                  Discover further
                </button>
              </div>
            </div>
          </div>
        )}
        {SectionValue !== "9" && (
          <div className="hraDenied">
            Not Authorized !! please navigate to previous sections
          </div>
        )}
      </div>
    </>
  );
}

export default HRA9a;
