export const DEVICE_TOKEN_REQUEST = "DEVICE_TOKEN_REQUEST"
export const DEVICE_TOKEN_SUCCESS = "DEVICE_TOKEN_SUCCESS"
export const DEVICE_TOKEN_FAILURE = "DEVICE_TOKEN_FAILURE"


export const FCM_TOKEN_REQUEST = "FCM_TOKEN_REQUEST"
export const FCM_TOKEN_SUCCESS = "FCM_TOKEN_SUCCESS"
export const FCM_TOKEN_FAILURE = "FCM_TOKEN_FAILURE"


