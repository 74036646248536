import RequestService from "../../RequestService";
import { api } from "../../../helpers/YuvaClinic/YuvaClinicConstant";

class NetworkService {
  viewAllYuvaClinic(action) {
    const endPoint = `${api.viewAllYuvaClinic}?enumForYuvaClinic=YUVA_CLINIC&pageNo=${action.data.page}&pageSize=12&sortBy=ID&sortOrder=ASC`;
    return RequestService.postRequest(endPoint, {
      cityList: action.data.city,
      search: action.data.search
    });
  }

  
}

export default new NetworkService();
