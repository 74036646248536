import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Landing/landingActionType";
import * as actions from "../../Action/Landing/getPopularPackageHomeScreenAction";
import NetworkService from "../../../network/Network/Landing/landingNetwork";

export function* getPopularPackageHomeScreenSaga(action) {

  try {
    let response = yield call(NetworkService.getPopularPackageHomeScreen, action);
    yield put(actions.getPopularPackageHomeScreenSuccess(response));
  } catch (err) {
    yield put(actions.getPopularPackageHomeScreenFailure(err));
  }
}

export function* watchGetPopularPackageHomeScreenSaga() {
  yield takeLatest(types.GET_POPULAR_PACKAGE_HOMESCREEN_REQUEST, getPopularPackageHomeScreenSaga);
}