import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const GetSection2AnswersRequest = (data) => {
    return {
        type: types.GET_SECTION_2_ANSWERS_REQUEST,
        data:data
    }
}

export const  GetSection2AnswersSuccess = (data) => {
    return {
        type: types.GET_SECTION_2_ANSWERS_SUCCESS,
        data:data,
    }
}

export const  GetSection2AnswersFailure = (data) => {
    return {
        type: types.GET_SECTION_2_ANSWERS_FAILURE,
        data:data,
    }
}