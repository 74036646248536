import * as types from "../../Config/Packages/PackageLabTestActionType";

export const packageLabTestRequest = (uuid) => {
  return {
    type: types.PACKAGE_LAB_TEST_REQUEST,
    // data:data,
    uuid: uuid,
  };
};

export const packageLabTestSuccess = (data) => {
  return {
    type: types.PACKAGE_LAB_TEST_SUCCESS,
    data: data,
  };
};

export const packageLabTestFailure = (data) => {
  return {
    type: types.PACKAGE_LAB_TEST_FAILURE,
    data: data,
  };
};
