import React from "react";
import RentalProducts from "../Rental/RentalProducts";
import "./Rental.css";
import bullseye from "../../../assets/Rental/bullseye.png";
import onTimeDelivery from "../../../assets/Rental/onTimeDelivery.png";
import privacyDocument from "../../../assets/Rental/privacyDocument.png";
import selectAll from "../../../assets/Rental/selectAll.png";
import Title from "../../Atoms/Title/Title";
import FrequentlyAskedQuestion from "../FrequentlyAskedQuestions/FrequentlyAskedQuestion";
import { Helmet } from "react-helmet";
function Rental() {
  const data = [
    {
      question: "What kind of medical equipment can I rent?",
      answer: "You can rent any equipment that is available in our catalog. ",
    },
    {
      question: "How do I rent medical equipment?",
      answer:
        "To rent medical equipment, you need to select the product you wish to take on rent, complete your KYC and document verification on our website, and then we will deliver the product to your doorstep. You can then use the product according to your needs. ",
    },
    {
      question: "What is KYC, and why is it required?",
      answer:
        "KYC stands for Know Your Customer and is a process of verifying the identity of the customer. We require KYC to ensure the safety and security of our equipment and to prevent fraud and misuse.",
    },
    {
      question: "What documents do I need to provide for KYC?",
      answer:
        "You need to provide a valid government-issued ID, such as a passport or driver's license, and proof of address, such as a utility bill or bank statement.",
    },
    {
      question: "How long can I rent medical equipment?",
      answer:
        "The rental period depends on the product you rent and the terms of the rental agreement. Typically, rental periods range from a few days to several months.",
    },
    {
      question: "Can I extend the rental period?",
      answer:
        "Yes, you can extend the rental period by contacting our customer support team and requesting an extension. Please note that additional charges may apply.",
    },
    {
      question: "How much does it cost to rent medical equipment?",
      answer:
        "The rental cost depends on the product you rent and the duration of the rental period. Please check our product portfolio for specific pricing information.",
    },
    {
      question: "Do you offer delivery and pickup services?",
      answer:
        "Yes, we offer delivery and pickup services for all rental products. Delivery and pickup charges may apply based on your location",
    },
    {
      question: "What if the equipment is damaged or stops working?",
      answer:
        "Please contact our customer support team immediately if you experience any issues with the equipment. We will arrange for a technician to fix the equipment or replace it if necessary.",
    },
    {
      question: "Do you offer insurance for rented equipment?",
      answer:
        "Yes, we offer insurance for rented equipment at an additional cost. Please contact our customer support team for any specific information.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Medical Equipment for Rent | YUVA</title>
        <meta
          name="description"
          content="Find top-quality medical equipment for rent at affordable rates. Explore our rental solutions for reliable and accessible healthcare support"
        />
        <meta name="keywords" content="Equipment for Rent" />
      </Helmet>
      <div className="RentalBody1">
        <p className="RentalHeading1"> Rental Equipment</p>
      </div>

      <div className="RentalBody">
        <div class="Rentalwrapper">
          <div className="RentalwrapperBlockOne">
            <div className="warpperInnerBlock">
              <img className="RentalImage" src={selectAll} alt="Document" />
            </div>
            <div className="RentalPageStep1">
              <div className="RentalPageHeadinMain">
                <p className="RentalWrapperHeading"> Step One</p>
                <img className="bulleyeRental" src={bullseye} alt="Dart" />
              </div>
              <div className="RentalText">
                <p>Select the equipment you would like to rent.</p>
              </div>
            </div>
          </div>

          <div className="RentalwrapperBlockOne">
            <div className="warpperInnerBlock">
              <img
                className="RentalImage"
                src={privacyDocument}
                alt="Privacy Document"
              />
            </div>
            <div className="RentalPageStep1">
              <div className="RentalPageHeadinMain">
                <p className="RentalWrapperHeading"> Step Two</p>
                <img className="bulleyeRental" src={bullseye} alt="Dart" />
              </div>
              <div className="RentalText">
                <p>
                  Complete your KYC and Document Verification with a few quick
                  clicks.
                </p>
              </div>
            </div>
          </div>
          <div className="RentalwrapperBlockOne">
            <div className="warpperInnerBlock">
              <img className="RentalImage" src={onTimeDelivery} alt="Truck" />
            </div>
            <div className="RentalPageStep1">
              <div className="RentalPageHeadinMain">
                <p className="RentalWrapperHeading"> Step Three</p>
                <img className="bulleyeRental" src={bullseye} alt="Dart" />
              </div>
              <div className="RentalText">
                <p>Get the equipment delivered to your doorstep.</p>
              </div>
            </div>
          </div>
        </div>

        <RentalProducts />
        <FrequentlyAskedQuestion data={data} />
      </div>
    </>
  );
}

export default Rental;
