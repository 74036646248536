import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Cross from "../../../assets/MyAccount/cross_button.png";
import { GetRelationsRequest } from "../../../redux/Action/HealthRiskAssessment/GetRelationsAction";
import {
  AddRelationClearRequest,
  AddRelationRequest,
} from "../../../redux/Action/User/AddRelationAction";
import { GetAllRelativesRequest } from "../../../redux/Action/User/GetAllRelatives";

function AddNewMemberModal(props) {
  const dispatch = useDispatch();
  const [genderOpen, setGenderOpen] = React.useState(false);
  const [stringInclude, setStringInclude] = useState(false);

  const GetRelations = useSelector((state) => state.hra.getRelations);
  const AddRelation = useSelector((state) => state.user.addRelation);

  const str = window.location.href;

  const handleClose = () => {
    props.open(false);
  };

  useEffect(() => {
    if (str.includes("MySubscription")) {
      setStringInclude(true);
    }
  }, [str]);

  useEffect(() => {
    dispatch(GetRelationsRequest());
  }, [dispatch]);

  const loginSchema = Yup.object().shape({
    Name: Yup.string().trim().required("Required Field"),
    Age: Yup.string().required("Required Field"),
    Relationship: Yup.string().required("Required Field"),
    Gender: Yup.string().when("Relationship", {
      is: "other",
      then: Yup.string().required("Required Field"),
    }),
  });

  useEffect(() => {
    if (AddRelation.data?.data.status === 201) {
      dispatch(GetAllRelativesRequest());

      props.open(false);
      dispatch(AddRelationClearRequest());
    }
    if (AddRelation.error?.data?.response?.status === 400) {
      Swal.fire({
        title: AddRelation?.error?.data?.response?.data?.errorMessage,
        icon: "error",
      }).then(() => {
        window.location.reload();
      });
    }
  }, [AddRelation, dispatch, props]);

  const handleSubmit = (values) => {
    if (values.Relationship === "other") {
      props.NewMemberInfo({
        other: true,
        name: values.Name,
        age: values.Age,
        gender: values.Gender,
        relation: null,
      });
      props.open(false);
    } else {
      dispatch(AddRelationRequest(values));
    }
  };

  const handleRelationship = (e) => {
    if (e.target.value === "other") {
      setGenderOpen(true);
    } else {
      setGenderOpen(false);
    }
  };

  return (
    <>
      <div>
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="mainAddAddressModal"
        >
          <Box className="RealtionshipmodalDetails">
            <div className="orderDetailsHead">
              <div className="orderDetailsTitle">
                {stringInclude ? " Add New Member" : "Add Relationship modal"}

                <br />
              </div>
              <img
                alt="close icon"
                src={Cross}
                className="orderDetailsCross"
                onClick={handleClose}
              />
            </div>

            <Formik
              initialValues={{
                Name: "",
                Age: "",
                Relationship: "",
                Gender: "",
              }}
              enableReinitialize={true}
              validationSchema={loginSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue }) => {
                return (
                  <Form>
                    <div>
                      <div className="SelectDetail">
                        <div className="RealtionshipMainHeadingText">Name</div>
                        <label style={{ width: "100%" }}>
                          <Field
                            type="text"
                            name="Name"
                            label="Select Gender"
                            placeholder="Name"
                            className="Relationmodalageinput"
                          ></Field>
                          <ErrorMessage
                            className="RealtionshipMainValidation"
                            name="Name"
                            component="div"
                          />
                        </label>
                      </div>

                      <div className="SelectDetail" >
                        <div className="RealtionshipMainHeadingText">Age</div>
                        <label style={{ width: "100%" }}>
                          <Field
                            type="number"
                            name="Age"
                            placeholder="Age"
                            className="Relationmodalageinput"
                          ></Field>
                          <ErrorMessage
                            className="RealtionshipMainValidation"
                            name="Age"
                            component="div"
                          />
                        </label>
                      </div>

                      <div className="SelectDetail">
                        <div className="RealtionshipMainHeadingText">
                          Relationship
                        </div>
                        <label style={{ width: "100%" }}>
                          <Field
                            as="Select"
                            name="Relationship"
                            placeholder="Relationship"
                            label="Select Relationship"
                            className="Relationmodalselect"
                            onChange={(e) => {
                              setFieldValue("Relationship", e.target.value);
                              handleRelationship(e);
                            }}
                          >
                            <option
                              disabled
                              value=""
                              selected
                              label="Relationship"
                            >
                              Select type{" "}
                            </option>

                            {GetRelations?.data?.data?.data?.data?.map(
                              (item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              )
                            )}
                            <option value={"other"}>Other</option>
                          </Field>
                          <ErrorMessage
                            className="RealtionshipMainValidation"
                            name="Relationship"
                            component="div"
                          />
                        </label>
                      </div>

                      {genderOpen && (
                        <div className="SelectDetail">
                          <div className="RealtionshipMainHeadingText">
                            Gender
                          </div>
                          <label>
                            <Field
                              as="Select"
                              name="Gender"
                              label="Select Gender"
                              className="Relationmodalselect"
                            >
                              <option value="" label="Select type">
                                Select type{" "}
                              </option>
                              <option value="Female" label="Female">
                                {" "}
                                Female
                              </option>
                              <option value="Male" label="Male">
                                Male
                              </option>
                            </Field>
                            <ErrorMessage
                              className="RealtionshipMainValidation"
                              name="Gender"
                              component="div"
                            />
                          </label>
                        </div>
                      )}
                    </div>
                    <div className="RealtionshipModalSubmit">
                      {stringInclude ? (
                        <button className="RealtionshipModalSubmitBtn">
                          Add Member
                        </button>
                      ) : (
                        <button className="RealtionshipModalSubmitBtn">
                          SUBMIT
                        </button>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default AddNewMemberModal;