import { TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import storage from "../../../Utils/storage";
import "./SelectDateAndTime.css";
import Title from "../../Atoms/Title/Title";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

function SelectDateAndTime({ DateAndTime }) {
  const navigate = useNavigate();
  const location = useLocation();

  const getCart = useSelector((state) => state.cart.getCart);

  const [value, setValue] = useState(
    new Date().setDate(new Date().getDate() + 1)
  );

  useEffect(() => {
    var someDate = new Date(value);
    someDate = someDate.getTime();
    DateAndTime(someDate);
  }, []);

  const handleTime = (val) => {
    setValue(val);
    var someDate = new Date(val);
    someDate = someDate.getTime();
    DateAndTime(someDate);
  };

  if (
    location.state === null ||
    getCart.error !== null ||
    storage.getStorage("token") === null
  ) {
    navigate("/");
  } else {
    return (
      <>
        {" "}
        <Helmet>
          <title>Yuva Health - Cart</title>
          <meta
            name="description"
            content="Review and manage your selections with ease at YUVA Health's cart page.
     Experience seamless checkout and personalized service for all your healthcare needs"
          />
        </Helmet>
        <div className="AddressList">
          <div className="AddressListUpper">
            <p className="AddressListUpperHeading">
              Select Your Time & Date Suitable
            </p>
          </div>
          <div className="AddressListLowerDateAndTime">
            <p className="DateAndTimeSelectHeading">Date & Time</p>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                inputFormat="dd/MM/yyyy hh:mm a"
                minDate={new Date() + 1}
                renderInput={(props) => (
                  <TextField className="DateAndTimeStyle" {...props} />
                )}
                label="Select Date and Time"
                value={value}
                onChange={(newValue) => {
                  handleTime(newValue);
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
      </>
    );
  }
}

export default SelectDateAndTime;
