import React from "react";
import Tick from "../../../../assets/tick.png";
import "./button.css";

function AddedButton() {
  return (
    <button className="addButton">
      <img src={Tick} alt="Added icon" />
    </button>
  );
}

export default AddedButton;
