import * as types from "../../Config/Account/InAccountActionType";

export const myPrescriptionRequest = (dropdown,id) => {
  return {
    type: types.MY_PRESCRIPTION_REQUEST,
    dropdown,
    id
  };
};

export const myPrescriptionSuccess = (data) => {
  return {
    type: types.MY_PRESCRIPTION_SUCCESS,
    data,
  };
};

export const myPrescriptionFailure = (data) => {
  return {
    type: types.MY_PRESCRIPTION_FAILURE,
    data,
  };
};
