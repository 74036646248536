import * as types from "../../Config/Landing/landingActionType"

export const getPopularTestRequest = (data) => {
    return {
        type: types.GET_POPULAR_TEST_REQUEST,
        data:data
    }
}

export const getPopularTestSuccess = (data) => {
    return {
        type: types.GET_POPULAR_TEST_SUCCESS,
        data:data,
    }
}

export const getPopularTestFailure = (data) => {
    return {
        type: types.GET_POPULAR_TEST_FAILURE,
        data:data,
    }
}