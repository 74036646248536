import RequestService from "../../RequestService";
import { api } from "../../../helpers/PLan/GetPlanDetailHelper";

class NetworkService {
  getPlanDetail(action) {
    const endPoint = `${api.getPlanDetails}?planUuid=${action?.data}`;
    return RequestService.getRequest(endPoint);
  }
  getPlanTypeDropdown(action) {
    const endPoint = `${api.getPlanTypeDropdown}`;
    return RequestService.getRequest(endPoint);
  }

  getPlanFullDetails(action) {
    if (action.data.appliedCoupon === "") {
      const endPoint = `${api.getPlanFullDetails}?planUuid=${action?.data.uuid}`;
      return RequestService.getRequest(endPoint);
    }

    const endPoint = `${api.getPlanFullDetails}?couponCode=${action?.data.appliedCoupon}&planUuid=${action?.data.uuid}&planTypeEnum=${action?.data.selectedOption}`;
    return RequestService.getRequest(endPoint);
  }
  getSelectedPlan(action) {
    const endPoint = `${api.getSelectedPlan}/${action.uuid}`;
    return RequestService.getRequest(endPoint);
  }

  getPlanRequestCallPost(action) {
    const endPoint = `${api.getPlanRequestCall}`;
    return RequestService.postRequest(endPoint, {
      number: action.data.toString(),
    });
  }

  planLock(action) {
    if (action.data.version === false) {
      const endPoint = `${api.planLock}`;
      return RequestService.postRequest(endPoint, {
        programOrPlanUuid: action.data.uuid,
        relationId: action.data.relationId,
        userVersion: null,
        version: null,
      });
    } else {
      const endPoint = `${api.planLock}`;
      return RequestService.postRequest(endPoint, {
        programOrPlanUuid: action.data.uuid,
        relationId: action.data.relationId,
        userVersion: action.data.userVersion,
        version: action.data.version,
      });
    }
  }

  getPlanServices(action) {
    const endPoint = `${api.getPlanServices}?planUuid=${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  getPlanServicesDetail(action) {
    const endPoint = `${api.getPlanServicesDetails}?planUuid=${action?.data}`;
    return RequestService.getRequest(endPoint);
  }
}
export default new NetworkService();
