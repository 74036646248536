import React, { useState, useEffect } from "react";
import "../HRAscreens/HRAInfoScreens.css";
import backArrow from "../../../../assets/hra/backArrow.png";
import hra2b from "../../../../assets/hra/hra2b.png";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../Molecules/ScrollToTop/ScrollToTop";
import storage from "../../../../Utils/storage";
import SingleLogin from "../../Login/SingleLogin";

function HRA2b() {
  const navigate = useNavigate();

  const handlePrevious = () => {
    navigate("/health-risk-assessment/section-2a");
  };
  window.addEventListener("popstate", function (event) {
    storage.setStorage("SectionValue", 2);
  });
  if (storage.getStorage("token") === null) {
    return <SingleLogin />;
  }

  const SectionValue = localStorage.getItem("SectionValue");

  return (
    <>
      <ScrollToTop />

      <div className="hraInfoScreens">
        <div className="hraInfoScreensChild1">
          <p>Your Health Risk Assessment</p>
        </div>
        {SectionValue === "3" && (
          <div
            className="hraInfoScreensChild2"
            style={{ backgroundImage: `url(${hra2b})` }}
          >
            <div className="hraInfoScreensChild2a">
              <div className="hraInfoScreensChild2a1">
                <p>GENERAL SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>DIETARY SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>MENTAL HEALTH SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a2">
                <div className="hraInfoBackArrow">
                  <img
                    src={backArrow}
                    onClick={handlePrevious}
                    alt="backArrow"
                  />
                  <p>Mental Health Section</p>
                </div>
                <div className="hraInfo">
                  <p>
                    Good mental health allows you to make good choices. Let us
                    get an understanding of where your mental health stands in
                    the spectrum.
                  </p>
                </div>
                <button
                  onClick={() => {
                    localStorage.setItem("SectionValue", 3);
                    navigate("/health-risk-assessment/section-3");
                  }}
                >
                  Discover further
                </button>
              </div>
            </div>
          </div>
        )}
        {SectionValue !== "3" && (
          <div className="hraDenied">
            Not Authorized !! please navigate to previous sections
          </div>
        )}
      </div>
    </>
  );
}

export default HRA2b;
