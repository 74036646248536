function createSlug(name) {
  const slug = name
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/[^\w-]+/g, "") // Remove non-word characters
    .replace(/--+/g, "-"); // Replace consecutive hyphens with a single hyphen

  return slug;
}

export default createSlug;
