import * as types from "../../Config/Network/networkActionType";

export const viewAllNetworkRequest = (page, search, data,selectType,city) => {
  return {
    type: types.VIEW_ALL_NETWORK_REQUEST,
    page,
    search,
    data,
    selectType,
    city
  };
};

export const viewAllNetworkSuccess = (data) => {
  return {
    type: types.VIEW_ALL_NETWORK_SUCCESS,
    data: data,
  };
};

export const viewAllNetworkFailure = (data) => {
  return {
    type: types.VIEW_ALL_NETWORK_FAILURE,
    data: data,
  };
};
