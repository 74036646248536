import RequestService from "../../RequestService";
import { api } from "../../../helpers/Account/MyAccountConstant";

class NetworkService {
   
    GetDocumentType() {
    const endPoint = `${api.GetDocumentType} `;
    return RequestService.getRequest(endPoint);
  }

  
  ApplyForKYC(action) {

    const endPoint = `${api.ApplyForKYC}?documentType=${action.data.kycRequestDto.documentType}&uniqueId=${action.data.kycRequestDto.uniqueId}&userId=${action.data.userId}`;
    return RequestService.postRequest(endPoint, action.data.newFormData);
  }
 
}
export default new NetworkService();
