import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../../Action/HealthRiskAssessment/GetSectionAnswers/GetSection8AnswersAction";
import NetworkService from "../../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* GetSection8AnswersSaga(action) {
  try {
    let response = yield call(NetworkService.GetSection8Answers, action);
    yield put(actions.GetSection8AnswersSuccess(response));
  } catch (err) {
    yield put(actions.GetSection8AnswersFailure(err));
  }
}

export function* watchGetSection8AnswersSaga() {
  yield takeLatest(types.GET_SECTION_8_ANSWERS_REQUEST, GetSection8AnswersSaga);
}
