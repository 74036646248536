import React from "react";
import { useNavigate } from "react-router-dom";
import Group1 from "../../../assets/LandingPage_Services/Group1.png";
import Group10 from "../../../assets/LandingPage_Services/Group10.png";
import Group11 from "../../../assets/LandingPage_Services/Group11.png";
import Group12 from "../../../assets/LandingPage_Services/Group12.png";
import Group2 from "../../../assets/LandingPage_Services/Group2.png";
import Group3 from "../../../assets/LandingPage_Services/Group3.png";
import Group4 from "../../../assets/LandingPage_Services/Group4.png";
import Group5 from "../../../assets/LandingPage_Services/Group5.png";
import Group6 from "../../../assets/LandingPage_Services/Group6.png";
import Group7 from "../../../assets/LandingPage_Services/Group7.png";
import Group8 from "../../../assets/LandingPage_Services/Group8.png";
import Group9 from "../../../assets/LandingPage_Services/Group9.png";
import servicesIconBackground from "../../../assets/LandingPage_Services/servicesIconBackground.png";
import "./Services.css";

function Services() {
  const isTestEnv = process.env.REACT_APP_ENV === "test";

  const navigate = useNavigate();

  const servicesData = [
    {
      imageicon: Group1,
      serviceText: "Book Test",
      serviceDescription:
        "Choose from a wide range of tests, enjoy state-of-the-art facilities, and get accurate results fast.",
      Enum: "MY_TESTS",
      dataTestId: "service-book-test",
    },
    {
      imageicon: Group2,
      serviceText: "Plans",
      serviceDescription:
        "Unlock premium healthcare with Yuva's subscription plans.",
      Enum: "PLANS",
      dataTestId: "service-plans",
    },
    {
      imageicon: Group3,
      serviceText: "Corporate Program",
      serviceDescription:
        "Enhance your company's wellness with Yuva's Corporate tailored made health solutions to keep your team healthy and productive.",
      Enum: "",
      dataTestId: "service-corporate-program",
    },
    {
      imageicon: Group4,
      serviceText: "Search Network",
      serviceDescription:
        "Explore Yuva's extensive network of diagnostic centers and healthcare providers.",
      Enum: "SEARCH_NETWORK",
      dataTestId: "service-search-network",
    },
    {
      imageicon: Group5,
      serviceText: "OPD Consultation",
      serviceDescription:
        "Experience seamless healthcare with Yuva's OPD Consultation services.",
      Enum: "OPD_CONSULTATION",
      dataTestId: "service-opd-consultation",
    },
    {
      imageicon: Group6,
      serviceText: "Health Risk Assessment",
      serviceDescription:
        "Our comprehensive YUVA's Health Risk assessmenr  helps you understand your health risks and take preventive.",
      Enum: "HEALTH_RISK_ASSESSMENT",
      dataTestId: "service-health-risk-assessment",
    },
    {
      imageicon: Group7,
      serviceText: "Pharmacy",
      serviceDescription:
        "Yuva's Pharmacy service offers a convenient and reliable way to manage your medications. ",
      Enum: "PHARMACY",
      dataTestId: "service-pharmacy",
    },
    {
      imageicon: Group8,
      serviceText: "Mental Wellness",
      serviceDescription:
        "Nurture your mental health and find balance in your life with Yuva's holistic approach to wellness.",
      Enum: "MENTAL_WELLNESS",
      dataTestId: "service-pharmacy",
    },
    {
      imageicon: Group9,
      serviceText: "My Tests",
      serviceDescription:
        "View your test reports conveniently through Yuva's online platform.",
      Enum: "MY_TESTS",
      dataTestId: "service-my-tests",
    },
    {
      imageicon: Group10,
      serviceDescription:
        "Streamline your healthcare experience with Yuva's Electronic Medical Record (EMR) system.",
      serviceText: "EMRM",
      Enum: "EMRM",
      dataTestId: "service-emrm",
    },
    {
      imageicon: Group11,
      serviceText: "Online Consultation",
      serviceDescription:
        "Experience convenient healthcare from the comfort of your home with Yuva's Online Consultation service.",
      Enum: "ONLINE_CONSULTATION",
      dataTestId: "service-online-consultation",
    },
    {
      imageicon: Group12,
      serviceText: "Ambulance ",
      serviceDescription:
        "In emergencies, trust Yuva's Assisted Ambulance Services for prompt and reliable assistance.",
      Enum: "AMBULANCE",
      dataTestId: "service-ambulance",
    },
  ];

  function handleServiceClick(serviceText) {
    switch (serviceText) {
      case "OPD_CONSULTATION":
        navigate("/consultation/cashless-opd");
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;

      case "SEARCH_NETWORK":
        navigate("/find-us");
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;

      case "HEALTH_RISK_ASSESSMENT":
        navigate("/health-risk-assessment");
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;

      case "PHARMACY":
        navigate("/Pharmacy");
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;

      case "MENTAL_WELLNESS":
        navigate("/mental-wellness");
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;

      case "MY_TESTS":
        localStorage.setItem(
          "viewEvery",
          JSON.stringify({
            radioItem: "NO_RANGE",
            minPrice: 0,
            maxPrice: 10000,
            allChecked: ["Test"],
          })
        );
        navigate("/view-test");
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;

      case "EMRM":
        navigate("/EMRM");
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;

      case "ONLINE_CONSULTATION":
        navigate("/talk-to-doctor");
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;

      case "AMBULANCE":
        navigate("/ambulance");
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;

      case "PLANS":
        navigate("/subscription");
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;

      default:
        break;
    }
  }

  return (
    <>
      <div className="servicesMainContainer">
        <p className="servicesMainContainerHeading">Services</p>
        <div className="servicesArea">
          {servicesData.map((item, index) => (
            <div
              className="servicesIndividual"
              onClick={() => handleServiceClick(item.Enum)}
              key={index}
              data-test-id={isTestEnv ? item.dataTestId : undefined}
            >
              <div className="servicesIndividualImageDiv">
                <img
                  src={servicesIconBackground}
                  alt="Services icon background"
                />
                <img src={item.imageicon} alt="Services icons" />
              </div>
              <p className="servicesIndividualServiceText">
                {item.serviceText}
              </p>
              <p className="servicesIndividualServiceDesc">
                {item.serviceDescription}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Services;
