import { combineReducers } from "redux";
import { clinicNetworkFormReducer } from "./clinicNetworkFormReducer";
import { connectionBetweenUsReducer } from "./connectionBetweenUsReducer";
import { diagnosticNetworkFormReducer } from "./diagnosticNetworkFormReducer";
import { hospitalNetworkFormReducer } from "./hospitalNetworkFormReducer";
import { professionalServicesNetworkFormReducer } from "./professionalServicesNetworkFormReducer";
import { viewAllNetworkReducer } from "./viewAllNetworkReducer";
import { viewAllNetworkTypeReducer } from "./viewAllNetworkTypeReducer";
import { viewNetworkCountReducer } from "./viewNetworkCountReducer";

export default combineReducers({
    clinicNetworkForm: clinicNetworkFormReducer,
    connectionBetweenUs: connectionBetweenUsReducer,
    diagnosticNetworkForm: diagnosticNetworkFormReducer,
    hospitalNetworkForm: hospitalNetworkFormReducer,
    professionalServicesNetworkForm: professionalServicesNetworkFormReducer,
    viewNetworkCount: viewNetworkCountReducer,
    viewAllNetworkType: viewAllNetworkTypeReducer,
    viewAllNetwork: viewAllNetworkReducer,
})