import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Order/OrderActionType";
import * as actions from "../../Action/Order/GetProgramSubscriptionAction";
import NetworkService from "../../../network/Network/Order/GetOrderNetwork";

export function* getProgramSubscriptionSaga(action) {
  try {
    let response = yield call(NetworkService.getProgramSubscription, action);
    yield put(actions.getProgramSubscriptionSuccess(response));
  } catch (err) {
    yield put(actions.getProgramSubscriptionFailure(err));
  }
}

export function* watchGetProgramSubscriptionSaga() {
  yield takeLatest(types.GET_PROGRAM_SUBSCRIPTION_REQUEST, getProgramSubscriptionSaga);
}
