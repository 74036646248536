import { combineReducers } from "redux";
import { myPrescriptionReducer } from "./myPrescriptionReducer";
import { myReportDiagnosticReducer } from "./myReportDiagnosticReducer";
import { myReportReducer } from "./myReportReducer";
import { GetDocumentTypeReducer } from "./GetDocumentTypeReducer";
import { ApplyForKYCReducer } from "./ApplyForKYCReducer";

export default combineReducers({
    myPrescription: myPrescriptionReducer,
    myReportDiagnostic: myReportDiagnosticReducer,
    myReport: myReportReducer,
    GetDocumentType:GetDocumentTypeReducer,
    ApplyForKYCReducer:ApplyForKYCReducer,
})