import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AddCart } from "../../../Utils/addCart";
import { getPackageMetaData } from "../../../Utils/packageMetaData";
import { createCartClear } from "../../../redux/Action/Cart/CreateCartAction";
import { packageLabTestRequest } from "../../../redux/Action/Packages/PackageLabTestAction";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import AboutPackage from "../../Organisms/AboutTestAndPackage/AboutPackage";
import SubscriptionHero from "../../Organisms/Hero/SubscriptionHero";

function PackageDetail() {
  const search = useLocation().search;
  const uuid = new URLSearchParams(search).get("id");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCollapse, setSelectedCollapse] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [itemList, setItemList] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");

  const createCart = useSelector((state) => state.cart.createCart);

  const dispatch = useDispatch();
  const handleClick = (index) => {
    if (
      packageLabTest?.data?.data?.data.data.attributeResponseDtoList[index]
        .parameterCount === 0
    ) {
      setSelectedCollapse(null);
    } else {
      setSelectedCollapse(index === selectedCollapse ? null : index);
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    if (createCart.data?.data.status === 200 && itemList) {
      localStorage.setItem("itemDtoList", JSON.stringify(itemList));
      window.dispatchEvent(new Event("storage"));
    }
    return () => {
      dispatch(createCartClear());
    };
  }, [createCart.data, createCart.error, itemList, dispatch]);

  useEffect(() => {
    dispatch(packageLabTestRequest(uuid));

    const { title, description, keywords } = getPackageMetaData(uuid);
    setTitle(title);
    setDescription(description);
    setKeywords(keywords);
  }, [dispatch, uuid]);

  const handleAddCart = async () => {
    const data = {
      name: packageLabTest?.data?.data?.data.data.productName,
      id: packageLabTest?.data?.data?.data.data.packageUuid,
      test: false,
    };
    try {
      setIsSubmitting(true);
      setItemList(await AddCart(data, dispatch, setIsSubmitting, 1));
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const packageLabTest = useSelector((state) => state.package.packageLabTest);

  if (packageLabTest.isLoading) {
    return <YuvaLoader />;
  }

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>

      <SubscriptionHero
        packageName={packageLabTest?.data?.data?.data.data.packageName}
        handleClick={handleAddCart}
        isSubmitting={isSubmitting}
        discountedPackageCost={
          packageLabTest?.data?.data?.data.data.discountedPackageCost
        }
        packageCost={packageLabTest?.data?.data?.data.data.packageCost}
        id={uuid}
        isTest={true}
      />
      <AboutPackage
        data={packageLabTest?.data?.data?.data.data}
        handleClick={handleClick}
        selectedCollapse={selectedCollapse}
        mapData={packageLabTest?.data?.data?.data.data.attributeResponseDtoList}
      />
    </>
  );
}

export default PackageDetail;
