import { PageKeys } from "../../../Utils/testPackageFlag";
import * as types from "../../Config/Banner/BannerActionType";

const initialState = {
  data: {
    [PageKeys.BANNER_TYPE_A]: null,
    [PageKeys.BANNER_TYPE_B]: null,
    [PageKeys.BANNER_TYPE_C]: null,
  },
  isLoading: false,
  error: null,
};

export const getUserBannerReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_USER_BANNER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          [action.category]: action,
        },
      }; 
    case types.GET_USER_BANNER_FAILURE:
      return {
        ...state,
        error: action,
      };
    case types.GET_USER_BANNER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
};
