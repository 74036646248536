import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/ElasticSearch/ElasticSearchActionType";
import * as actions from "../../Action/ElasticSearch/ElasticSearchResultAction";
import NetworkService from "../../../network/Network/ElasticSearch/ElasticSearchNetwork";

export function* elasticSearchResultSaga(action) {
  try {
    let response = yield call(NetworkService.elasticSearchResult, action);
    yield put(actions.elasticSearchResultSuccess(response));
  } catch (err) {
    yield put(actions.elasticSearchResultFailure(err));
  }
}

export function* watchElasticSearchResultSaga() {
  yield takeLatest(types.ELASTIC_SEARCH_RESULT_REQUEST, elasticSearchResultSaga);
}