import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../Action/HealthRiskAssessment/GetRelationsAction";
import NetworkService from "../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* GetRelationsSaga(action) {
  try {
    let response = yield call(NetworkService.GetRelations, action);
    yield put(actions.GetRelationsSuccess(response));
  } catch (err) {
    yield put(actions.GetRelationsFailure(err));
  }
}

export function* watchGetRelationsSaga() {
  yield takeLatest(types.GET_RELATIONS_REQUEST, GetRelationsSaga);
}
