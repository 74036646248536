import * as types from "../../Config/User/userActionType"

export const googleLoginRequest = (data) => {
    return {
        type: types.LOGIN_GOOGLE_REQUEST,
        data:data
    }
}

export const googleLoginSuccess = (data) => {
    return {
        type: types.LOGIN_GOOGLE_SUCCESS,
        data:data,
    }
}

export const googleLoginFailure = (data) => {
    return {
        type: types.LOGIN_GOOGLE_FAILURE,
        data:data,
    }
}