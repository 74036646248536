import { all } from "redux-saga/effects";
import { watchElasticSearchSaga } from "./ElasticSearchSaga";
import { watchElasticSearchResultSaga } from "./ElasticSearchResultSaga";



function* rootElasticSearchSaga() {
    yield all([
        watchElasticSearchSaga(),
        watchElasticSearchResultSaga(),
    ])
}

export default rootElasticSearchSaga;