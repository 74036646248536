import React from "react";
import AmbulancePic from "../../../assets/Ambulance/AmbulancePic.png";
import Emoji1 from "../../../assets/Ambulance/Emoji1.png";
import Emoji2 from "../../../assets/Ambulance/Emoji2.png";
import Emoji3 from "../../../assets/Ambulance/Emoji3.png";
import Title from "../../Atoms/Title/Title";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import "./Ambulance.css";

function Ambulance() {
  return (
    <>
      <Title title="YUVA | Ambulance" />
      <ScrollToTop />
      <div className="AmbulanceServiceContainer">
        <div className="ambulanceHero">
          <div className="ambulanceHeroText">
            <div>Ambulance</div>
            <div>Book Ambulance Services with Best Facility</div>
          </div>
          <img
            src={AmbulancePic}
            alt="Ambulance"
            className="AmbulanceServiceCoverPic"
          />
        </div>
        <div className="AmbulanceServiceBody">
          <div className="AmbulanceServiceUnderText">
            Under this service, we provide dedicated ambulances, manpower, and
            our in-house technology to assist with real-time tracking of these
            ambulances, the technology includes which includes dedicated
            applications for the drivers, paramedics, and call center
            executives, and customized dashboards for easy monitoring at the
            back end allowing smooth data sharing, helping us to provide better
            care for the patients.
          </div>
          <div className="AmbulanceServicesContainerGrid">
            <div className="AmbulanceServicesContainerSection1">
              <div className="AmbulanceServicesText">
                <p>24x7 Ambulance Services</p>
                <p className="EmojiContainer">
                  <img src={Emoji1} alt="Emoji1" />
                </p>
              </div>
              <p className="AmbulanceServicesSubTextHead">
                One touch access to medical emergency services
              </p>
            </div>

            <div className="AmbulanceServicesContainerSection1">
              <div className="AmbulanceServicesText">
                <p>Extension of Ambulance Network</p>
                <p className="EmojiContainer">
                  <img src={Emoji2} alt="Emoji1" />
                </p>
              </div>
              <p className="AmbulanceServicesSubTextHead">
                Book ambulances in advance for hassle-free hospital visits.
              </p>
            </div>
            <div className="AmbulanceServicesContainerSection1">
              <div className="AmbulanceServicesText">
                <p>On Call Support</p>
                <p className="EmojiContainer">
                  <img src={Emoji3} alt="Emoji1" />
                </p>
              </div>
              <p className="AmbulanceServicesSubTextHead">
                Seamless communication with ambulance drivers
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ambulance;
