import * as types from "../../Config/Order/OrderActionType";

export const getOrderDetailRequest = (data) => {
  return {
    type: types.GET_ORDER_DETAIL_REQUEST,
    data:data,
  };
};

export const getOrderDetailSuccess = (data) => {
  return {
    type: types.GET_ORDER_DETAIL_SUCCESS,
    data: data,
  };
};

export const getOrderDetailFailure = (data) => {
  return {
    type: types.GET_ORDER_DETAIL_FAILURE,
    data: data,
  };
};
