import React from "react";
import { useNavigate } from "react-router-dom";
import Postadd from "../../../assets/MyAccount/Postadd.png";
import storage from "../../../Utils/storage";
import "./NoPrescription.css";

const NoPurchaseHistory = () => {
  const navigate = useNavigate();

  if (storage.getStorage("token") === null) {
    navigate("/")
  }
  return (
    <div>
      <div className="myprescriptionmaindiv">
        <div className="MyAddressIconsMainBox">
          <div className="MyAddressIconsMainBox2">

            <div className="ExtraClass">
              <img className="MyAddressIconPrescription" src={Postadd} alt="Post Add"></img>
            </div>
            <div className="addressTextPrescription">
              Looks like you have not  purchase anything.
            </div>

          </div>
        </div>
      </div>
     
    </div>
  );
};

export default NoPurchaseHistory;