import RequestService from "../../RequestService";
import { api } from "../../../helpers/Mentalwellness/mentalWellnessConstant";


class NetworkService {
    getOnMoodNine() {
        const endPoint = `${api.getOnMoodNine}`;
        return RequestService.getRequest(endPoint);
    }
}

export default new NetworkService();
