import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/OTP/OTPMobile/otpMobileActionType";
import * as actions from "../../../Action/OTP/OTPMobile/otpMobileAction";
import NetworkService from "../../../../network/Network/OTP/OTPMobile/otpMobileNetwork";

export function* generateMobileOtp(action) {
  try {
    let response = yield call(NetworkService.generateMobileOtp, action);
    yield put(actions.otpMobileRequestSuccess(response));
  } catch (err) {
    yield put(actions.otpMobileRequestFailure(err));
  }
}

export function* watchGenerateMobileOtp() {
  yield takeLatest(types.OTPMOBILE_REQUEST, generateMobileOtp);
}