import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import storage from "../../../Utils/storage";
import networkIcon1 from "../../../assets/Network/mblIcon1Network.png";
import networkIcon2 from "../../../assets/Network/mblIcon1Network2.png";
import magnifying from "../../../assets/Rental/Vector.jpg";
import { viewAllNetworkRequest } from "../../../redux/Action/Network/viewAllNetworkAction";
import { viewAllNetworkTypeRequest } from "../../../redux/Action/Network/viewAllNetworkTypeAction";
import { getPlanTypeDropdownRequest } from "../../../redux/Action/Plan/PlanTypeDropdown";
import Title from "../../Atoms/Title/Title";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import "./Network.css";

export default function Network() {
  storage.setStorage("FormType", "Hospital");
  const dispatch = useDispatch();
  const [filter, setFilter] = React.useState(false);
  const [filterPage, setFilterPage] = React.useState(1);
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [data, setData] = React.useState("CLINIC");
  const [selectType, setSelectType] = React.useState();
  const [city, Setcity] = useState("");

  useEffect(() => {
    dispatch(viewAllNetworkTypeRequest());
    dispatch(getPlanTypeDropdownRequest());
  }, [dispatch]);

  const getPlanTypeDropdown = useSelector(
    (state) => state.plan.getPlanTypeDropdown
  );
  const viewNetworktype = useSelector(
    (state) => state.network.viewAllNetworkType
  );

  useEffect(() => {
    if (data === "DIAGNOSTIC" || data === "PROFESSIONAL_SERVICE") {
      dispatch(viewAllNetworkRequest(page, search, data, "", city));
       
    } else {
      dispatch(viewAllNetworkRequest(page, search, data, selectType, city));

    }
  }, [data, dispatch, city, page, search, selectType]);

  const handleChange = (event, value) => {
    if (filter) {
      if (data === "DIAGNOSTIC" || data === "PROFESSIONAL_SERVICE") {
        dispatch(viewAllNetworkRequest(filterPage, search, data, "", city));
      } else {
        dispatch(
          viewAllNetworkRequest(filterPage, search, data, selectType, city)
        );
      }
      setFilterPage(value);
    } else {
      if (data === "DIAGNOSTIC" || data === "PROFESSIONAL_SERVICE") {
        dispatch(viewAllNetworkRequest(value, search, data, "", city));
      } else {
        dispatch(viewAllNetworkRequest(value, search, data, selectType, city));
      }
      setPage(value);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleChangeCity = (event) => {
    if (data === "DIAGNOSTIC" || data === "PROFESSIONAL_SERVICE") {
      dispatch(
        viewAllNetworkRequest(filterPage, search, data, "", event.target.value)
      );
    } else {
      dispatch(
        viewAllNetworkRequest(
          filterPage,
          search,
          data,
          selectType,
          event.target.value
        )
      );
      
    }
    setFilter(true);
    Setcity(event.target.value);
    if (event.target.value === "") {
      setFilter(false);
      setFilterPage(1);
    }
  };

  const handleChangeType = (event) => {
    if (data === "DIAGNOSTIC" || data === "PROFESSIONAL_SERVICE") {
      dispatch(viewAllNetworkRequest(filterPage, search, data, "", city));
    } else {
      dispatch(
        viewAllNetworkRequest(
          filterPage,
          search,
          data,
          event.target.value,
          city
        )
      );
    }
    setFilter(true);
    setSelectType(event.target.value);

    if (event.target.value === "") {
      setFilter(false);
      setFilterPage(1);
    }
  };

  const viewAllNetwork = useSelector((state) => state.network.viewAllNetwork);

  const viewCityNames = useSelector((state) => state.city.viewAllCity);

  const handleSearch = () => {
    if (data === "DIAGNOSTIC" || data === "PROFESSIONAL_SERVICE") {
      dispatch(viewAllNetworkRequest(filterPage, search, data, "", city));
    } else {
      dispatch(
        viewAllNetworkRequest(filterPage, search, data, selectType, city)
      );
    }
  };
  const handleMapLocaion = (event) => {
    const mapUrl = event.target.attributes.url.value;
    const isValidUrl = /^https?:\/\/[\w-]+(\.[\w-]+)+[/#?]?.*$/.test(mapUrl);

    if (isValidUrl) {
      window.open(mapUrl);
    } else {
      window.open("/linkExpired");
    }
  };

  return (
    <div>
      <Title title="Yuva | Network" />
      <ScrollToTop />
      <div>
        <div className="networkBody1">
          <div className="networkMainHeading">
            Search for your nearest{" "}
            <span style={{ color: "#2D354E" }}>Network provider</span> to plan
            and avail a cashless facility
          </div>

          <div className="head">
            <div className="networkLeftHead">
              <div className="networkLeftHeadDropdown">
                <div
                  className={
                    data === "DIAGNOSTIC" || data === "PROFESSIONAL_SERVICE"
                      ? "autocompleteNetwork"
                      : "autocompleteNetworks"
                  }
                >
                  <select
                    onChange={(e) => setData(e.target.value)}
                    className="autocompleteNetwork_select"
                  >
                    {viewNetworktype.data !== null &&
                      (viewNetworktype.data?.data?.data.data).map(
                        (item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        )
                      )}
                  </select>
                </div>

                {data === "DIAGNOSTIC" || data === "PROFESSIONAL_SERVICE" ? (
                  <></>
                ) : (
                  <>
                    <div className="networkBlock">
                      <select
                        className="autocompleteNetwork_selects"
                        onChange={handleChangeType}
                        as="Select"
                        value={selectType}
                        networkBlock
                        label="Select Type"
                      >
                        <option value={null} disabled selected>
                          Select Type
                        </option>
                        <option value="">All</option>
                        {getPlanTypeDropdown?.data?.data?.data?.data.map(
                          (item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </>
                )}
                <div className="networkBlock">
                  <select
                    className={
                      data === "DIAGNOSTIC" || data === "PROFESSIONAL_SERVICE"
                        ? "autocompleteNetwork_selects_change"
                        : "autocompleteNetwork_selects"
                    }
                    onChange={handleChangeCity}
                    as="Select"
                    value={city}
                    label="Select Type"
                  >
                    <option value={null} disabled selected>
                      Select City
                    </option>
                    <option value="">All Cities</option>
                    {viewCityNames?.data?.data?.data?.data.map(
                      (item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>

              <div
                className={
                  data === "DIAGNOSTIC" || data === "PROFESSIONAL_SERVICE"
                    ? "networkSearchs"
                    : "networkSearch"
                }
              >
                <img alt="Magnifying Glass" src={magnifying} />
                <input
                  type="search"
                  placeholder="Search"
                  onBlur={(e) => setSearch(e.target.value)}
                />
                <div>
                  <button className="networkSearchBtn" onClick={handleSearch}>
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bodyBackground">
          <div className="networkCardbody">
            {viewAllNetwork.data?.data.data.data.networkDtoList.length === 0 ? (
              <p>No record found</p>
            ) : (
              viewAllNetwork.data?.data.data.data.networkDtoList.map((itm) => (
                <div className="networkCard">
                  <div className="networkCardText">
                    <div className="textLeftHead">
                      {itm.name !== null && (
                        <div className="title">{itm.name}</div>
                      )}

                      {itm.diagnosticCenterName !== null && (
                        <div className="title">{itm.diagnosticCenterName}</div>
                      )}

                      <div className="address">{itm.address}</div>
                      <div
                        style={{
                          marginTop: "2vw",
                        }}
                      >
                        <div className="address1">
                          <img alt="Phone" src={networkIcon1} />
                          {itm.number}
                        </div>
                        <div className="address1">
                          <img alt="Location" src={networkIcon2} />
                          <div
                            className="MapurlCSS"
                            url={itm.mapUrl}
                            onClick={handleMapLocaion}
                          >
                            {" "}
                            View Map
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>

          <div className="networkPagination">
            <Pagination
              count={viewAllNetwork.data?.data.data.data.totalPages}
              page={filter ? filterPage : page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
