import React from "react";
import "./RentalProducts.css";
import whyRentPic1 from "../../../assets/Rental/whyRentPic1.png";
import whyRentPic2 from "../../../assets/Rental/whyRentPic2.png";
import whyRentPic3 from "../../../assets/Rental/whyRentPic3.png";
import rentalProduct1 from "../../../assets/Rental/rentalnebulizer.png";
import rentalProduct2 from "../../../assets/Rental/rentalProduct2.png";
import rentalProduct3 from "../../../assets/Rental/rentalProduct3.png";
import rentalProduct4 from "../../../assets/Rental/rentalProduct4.png";
import rentalProduct5 from "../../../assets/Rental/rentalProduct5.png";
import rentalProduct6 from "../../../assets/Rental/rentalProduct6.png";
import rentalProduct7 from "../../../assets/Rental/rentalProduct7.png";
import rentalProduct8 from "../../../assets/Rental/rentalProduct8.png";
import rentalProduct9 from "../../../assets/Rental/rentalProduct9.png";
import rentalProduct10 from "../../../assets/Rental/rentalProduct10.png";
import rentalProduct11 from "../../../assets/Rental/rentalProduct11.png";
import rentalProduct12 from "../../../assets/Rental/rentalProduct12.png";
import rentalProduct13 from "../../../assets/Rental/rentalProduct13.png";
import rentalProduct14 from "../../../assets/Rental/rentalProduct14.png";
import rentalProduct15 from "../../../assets/Rental/rentalProduct15.png";
import rentalProduct16 from "../../../assets/Rental/rentalProduct16.png";
import rentalProduct17 from "../../../assets/Rental/rentalProduct17.png";
import rentalProduct18 from "../../../assets/Rental/rentalProduct18.png";
import rentalProduct19 from "../../../assets/Rental/rentalProduct19.png";

function RentalProducts() {
  const rentalProduct = [
    {
      img: rentalProduct1,
      name: "Nebulizer",
    },
    {
      img: rentalProduct2,
      name: "Phototherapy Machine",
    },
    {
      img: rentalProduct3,
      name: "Pulse Oximeter",
    },
    {
      img: rentalProduct4,
      name: "BiPAP",
    },
    {
      img: rentalProduct5,
      name: "CPAP",
    },
    {
      img: rentalProduct6,
      name: "CPM Machine",
    },
    {
      img: rentalProduct7,
      name: "Difibrillator",
    },
    {
      img: rentalProduct8,
      name: "DVT Pump",
    },
    {
      img: rentalProduct9,
      name: "Feeding Pump",
    },
    {
      img: rentalProduct10,
      name: "Fowler Cot",
    },
    {
      img: rentalProduct11,
      name: "Infusion Pump Vet",
    },
    {
      img: rentalProduct12,
      name: "Multi Parameter Patient Monitor",
    },
    {
      img: rentalProduct13,
      name: "Oxygen Concentrator",
    },
    {
      img: rentalProduct14,
      name: "Oxygen Cylinders",
    },
    {
      img: rentalProduct15,
      name: "Patient Recline Bed",
    },
    {
      img: rentalProduct16,
      name: "Suction Machine",
    },
    {
      img: rentalProduct17,
      name: "Syringe Pump",
    },
    {
      img: rentalProduct18,
      name: "Walker",
    },
    {
      img: rentalProduct19,
      name: "Wheel Chair",
    },
  ];
  return (
    <>
      <div className="RentalProductBody1">
        <div className="RentalProductBlockTwo">
          <div className="RentalProductHeading">PRODUCT CATEGORIES</div>
          <div className="RentalProductHeadingThree">
            <div className="RentalProductHeadingTwo">
              {" "}
              Rent any product from our catalog.
            </div>
          </div>
        </div>
        <div class="RentalProduct_BlockFour_grid-container">
          {rentalProduct.map((item) => (
            <div className="RentalImageCard">
              <div className="Whelloutline">
                <img
                  src={item.img}
                  className="machineoutlineImage"
                  alt="Machines"
                ></img>
              </div>
              <p className="RentalProduct_BlockFourSubHead">{item.name}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="WhyRentBody">
        <div className="WhyRentInnerBody">
          <p>WHY RENT FROM US</p>
          <p>Top reasons to rent a Medical Equipment</p>
        </div>
        <div class="WhyRentalwrapper">
          <div className="WhyRentalwrapperBlockOne">
            <div className="RentalWrapperImage">
              <img src={whyRentPic1} alt="Box" />
            </div>
            <p className="WhyRentalwrapperBlockOneText">Renting Made Easy</p>
            <p className="WhyRentalwrapperBlockOneText">
              Easy access to sophisticated medical equipment on a monthly
              subscription.
            </p>
          </div>
          <div className="WhyRentalwrapperBlockOne">
          <div className="RentalWrapperImage">
              <img src={whyRentPic2} alt="Rupee" />
            </div>
            <p className="WhyRentalwrapperBlockOneText">Pay less then MRP</p>
            <p className="WhyRentalwrapperBlockOneText"> Pay only for what you rent</p>
          </div>
          <div className="WhyRentalwrapperBlockOne">
          <div className="RentalWrapperImage">
              <img src={whyRentPic3} alt="Refund" />
            </div>
            <p className="WhyRentalwrapperBlockOneText"> No Deposit</p>
            <p className="WhyRentalwrapperBlockOneText">
              We don't take any deposit from our customer, mere completing KYC
              will provide you access to all the medical equipment at your door
              step.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default RentalProducts;
