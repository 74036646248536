import * as types from "../../Config/ERM/MedicalRecordListingConfig"

const initialState = {
    data: null,
    isLoading: false,
    error: null
}

export const medicalRecordDropdownReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.MEDICAL_RECORD_DROPDOWN_SUCCESS:
           
            return {
                ...initialState,
                data: action,
            }
        case types.MEDICAL_RECORD_DROPDOWN_FAILURE:
            return {
                ...initialState,
                error: action
            }
        case types.MEDICAL_RECORD_DROPDOWN_REQUEST:
            return {
                ...initialState,
                isLoading: true
            }
        default:
            return state
    }
}