import RequestService from "../../RequestService";
import { api } from "../../../helpers/Cart/CartConstants";

class NetworkService {
  createCart(action) {
    const endPoint = `${api.cart}`;
    return RequestService.postRequest(endPoint, {
      itemDtoList: action.data,
    });
  }

  getCart(action) {
    if (action.data === true) {
      const endPoint = `${api.cart}?clearCoupon=true`;
      return RequestService.getRequest(endPoint);
    } else if (action.data !== "" && action.data !== undefined) {
      const endPoint = `${api.cart}?couponCode=${action.data}`;
      return RequestService.getRequest(endPoint);
    }
    const endPoint = `${api.cart}`;
    return RequestService.getRequest(endPoint);
  }

  CartDiscount() {
    const endPoint = `${api.CartDiscount}`;
    return RequestService.getRequest(endPoint);
  }

  removeCartItem(action) {
    const endPoint = `${api.removeItem}?itemId=${action.id}&productTypeEnum=${action.productType}`;
    return RequestService.deleteRequest(endPoint);
  }

  createOrder(action) {
    if (action.data.plan === false) {
      const endPoint = `${api.createOrder}`;
      var isCod = action.data.cod === "true";

      if (action.data.other === undefined) {
        return RequestService.postRequest(endPoint, {
          cod: isCod,
          cart: true,
          bookingRequestDto: {
            address: action.data.address,
            cityId: action.data.cityId,
            contactNumber: action.data.contactNumber,
            packageUuid: [],
            pinCode: action.data.pinCode,
            testId: [],
            timeSlot: action.data.timeSlot,
          },
        });
      }
      return RequestService.postRequest(endPoint, {
        name: action.data.PatientName,
        genderEnum: action.data.genderEnum,
        age: action.data.age,
        cod: isCod,
        cart: true,
        bookingRequestDto: {
          address: action.data.address,
          cityId: action.data.cityId,
          contactNumber: action.data.contactNumber,
          packageUuid: [],
          pinCode: action.data.pinCode,
          relationId:
            action.data.other === false ? action.data.relationId : null,
          testId: [],
          timeSlot: action.data.timeSlot,
        },
      });
    }
    const endPoint = `${api.createOrder}`;
    return RequestService.postRequest(endPoint, {
      subscriptionRequestDto: action.data.subscriptionRequestDto,
      cart: false,
      cod: action.data.cod,
    });
  }

  paymentGatewayRedirect(action) {
    if (action.id.plan === true) {
      const endPoint = `${api.paymentGatewayRedirect}?orderId=${action.id.id}&cancelURL=${process.env.REACT_APP_BASE_URL}/api/v1/yuva/paymentGateway/response&redirectURL=${process.env.REACT_APP_BASE_URL}/api/v1/yuva/paymentGateway/response`;
      return RequestService.getRequest(endPoint, {});
    }
    const endPoint = `${api.paymentGatewayRedirect}?orderId=${action.id.id}&cancelURL=${process.env.REACT_APP_BASE_URL}/api/v1/yuva/paymentGateway/response&redirectURL=${process.env.REACT_APP_BASE_URL}/api/v1/yuva/paymentGateway/response`;
    return RequestService.getRequest(endPoint, {});
  }

  RedeemCoupon(action) {
    const endPoint = `${api.RedeemCoupon}?couponCode=${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  GetAllCoupon(action) {
    if (action.data !== undefined) {
      const endPoint = `${api.GetAllCoupon}/getAllCoupons/user?pageNo=1&pageSize=10&planTypeEnum=${action.data.selectedOption}&planUuid=${action.data.uuid}`;
      return RequestService.postRequest(endPoint);
    }
    const endPoint = `${api.GetAllCoupon}/getAllCoupons/user?pageNo=1&pageSize=10`;
    return RequestService.postRequest(endPoint);
  }

  paymentGatewayStatus(action) {
    const endPoint = `${api.paymentGatewayStatus}?token=${action.token}&emailOrNumber=${action.email}`;
    return RequestService.getRequest(endPoint);
  }
}

export default new NetworkService();
