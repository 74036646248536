export const GET_QUESTION_SECTION_REQUEST = "GET_QUESTION_SECTION_REQUEST"
export const GET_QUESTION_SECTION_SUCCESS = "GET_QUESTION_SECTION_SUCCESS"
export const GET_QUESTION_SECTION_FAILURE = "GET_QUESTION_SECTION_FAILURE"

export const GET_QUESTION_SECTION1_REQUEST = "GET_QUESTION_SECTION1_REQUEST"
export const GET_QUESTION_SECTION1_SUCCESS = "GET_QUESTION_SECTION1_SUCCESS"
export const GET_QUESTION_SECTION1_FAILURE = "GET_QUESTION_SECTION1_FAILURE"

export const GET_QUESTION_SECTION2_REQUEST = "GET_QUESTION_SECTION2_REQUEST"
export const GET_QUESTION_SECTION2_SUCCESS = "GET_QUESTION_SECTION2_SUCCESS"
export const GET_QUESTION_SECTION2_FAILURE = "GET_QUESTION_SECTION2_FAILURE"

export const GET_QUESTION_SECTION3_REQUEST = "GET_QUESTION_SECTION3_REQUEST"
export const GET_QUESTION_SECTION3_SUCCESS = "GET_QUESTION_SECTION3_SUCCESS"
export const GET_QUESTION_SECTION3_FAILURE = "GET_QUESTION_SECTION3_FAILURE"

export const GET_QUESTION_SECTION4_REQUEST = "GET_QUESTION_SECTION4_REQUEST"
export const GET_QUESTION_SECTION4_SUCCESS = "GET_QUESTION_SECTION4_SUCCESS"
export const GET_QUESTION_SECTION4_FAILURE = "GET_QUESTION_SECTION4_FAILURE"

export const GET_QUESTION_SECTION5_REQUEST = "GET_QUESTION_SECTION5_REQUEST"
export const GET_QUESTION_SECTION5_SUCCESS = "GET_QUESTION_SECTION5_SUCCESS"
export const GET_QUESTION_SECTION5_FAILURE = "GET_QUESTION_SECTION5_FAILURE"

export const GET_QUESTION_SECTION6_REQUEST = "GET_QUESTION_SECTION6_REQUEST"
export const GET_QUESTION_SECTION6_SUCCESS = "GET_QUESTION_SECTION6_SUCCESS"
export const GET_QUESTION_SECTION6_FAILURE = "GET_QUESTION_SECTION6_FAILURE"

export const GET_QUESTION_SECTION7_REQUEST = "GET_QUESTION_SECTION7_REQUEST"
export const GET_QUESTION_SECTION7_SUCCESS = "GET_QUESTION_SECTION7_SUCCESS"
export const GET_QUESTION_SECTION7_FAILURE = "GET_QUESTION_SECTION7_FAILURE"

export const GET_QUESTION_SECTION8_REQUEST = "GET_QUESTION_SECTION8_REQUEST"
export const GET_QUESTION_SECTION8_SUCCESS = "GET_QUESTION_SECTION8_SUCCESS"
export const GET_QUESTION_SECTION8_FAILURE = "GET_QUESTION_SECTION8_FAILURE"

export const GET_QUESTION_SECTION9_REQUEST = "GET_QUESTION_SECTION9_REQUEST"
export const GET_QUESTION_SECTION9_SUCCESS = "GET_QUESTION_SECTION9_SUCCESS"
export const GET_QUESTION_SECTION9_FAILURE = "GET_QUESTION_SECTION9_FAILURE"


export const SAVE_REPORT_REQUEST = "SAVE_REPORT_REQUEST"
export const SAVE_REPORT_SUCCESS = "SAVE_REPORT_SUCCESS"
export const SAVE_REPORT_FAILURE = "SAVE_REPORT_FAILURE"

export const SAVE_RESPONSE = "SAVE_RESPONSE"


export const SECTION1_ANSWERS_SUCCESS = "SECTION1_ANSWERS_SUCCESS"

export const SECTION2_ANSWERS_SUCCESS = "SECTION2_ANSWERS_SUCCESS"

export const SECTION3_ANSWERS_SUCCESS = "SECTION3_ANSWERS_SUCCESS"

export const SECTION4_ANSWERS_SUCCESS = "SECTION4_ANSWERS_SUCCESS"

export const SECTION5_ANSWERS_SUCCESS = "SECTION5_ANSWERS_SUCCESS"

export const SECTION6_ANSWERS_SUCCESS = "SECTION6_ANSWERS_SUCCESS"

export const SECTION7_ANSWERS_SUCCESS = "SECTION7_ANSWERS_SUCCESS"

export const SECTION8_ANSWERS_SUCCESS = "SECTION8_ANSWERS_SUCCESS"

export const SECTION9_ANSWERS_SUCCESS = "SECTION9_ANSWERS_SUCCESS"

export const GET_HRA_QUESTIONS_REQUEST = "GET_HRA_QUESTIONS_REQUEST"
export const GET_HRA_QUESTIONS_SUCCESS = "GET_HRA_QUESTIONS_SUCCESS"
export const GET_HRA_QUESTIONS_FAILURE = "GET_HRA_QUESTIONS_FAILURE"

export const GET_SECTION_ANSWERS_REQUEST = "GET_SECTION_ANSWERS_REQUEST"
export const GET_SECTION_ANSWERS_SUCCESS = "GET_SECTION_ANSWERS_SUCCESS"
export const GET_SECTION_ANSWERS_FAILURE = "GET_SECTION_ANSWERS_FAILURE"

export const CONTINUE_SECTION_REQUEST = "CONTINUE_SECTION_REQUEST"
export const CONTINUE_SECTION_SUCCESS = "CONTINUE_SECTION_SUCCESS"
export const CONTINUE_SECTION_FAILURE = "CONTINUE_SECTION_FAILURE"

export const DELETE_SECTION_ANSWERS_REQUEST = "DELETE_SECTION_ANSWERS_REQUEST"
export const DELETE_SECTION_ANSWERS_SUCCESS = "DELETE_SECTION_ANSWERS_SUCCESS"
export const DELETE_SECTION_ANSWERS_FAILURE = "DELETE_SECTION_ANSWERS_FAILURE"

export const SAVE_SECTION_ANSWERS_REQUEST = "SAVE_SECTION_ANSWERS_REQUEST"
export const SAVE_SECTION_ANSWERS_SUCCESS = "SAVE_SECTION_ANSWERS_SUCCESS"
export const SAVE_SECTION_ANSWERS_FAILURE = "SAVE_SECTION_ANSWERS_FAILURE"


export const GET_RELATIONS_REQUEST = "GET_RELATIONS_REQUEST"
export const GET_RELATIONS_SUCCESS = "GET_RELATIONS_SUCCESS"
export const GET_RELATIONS_FAILURE = "GET_RELATIONS_FAILURE"


export const GET_LATEST_SECTION_REQUEST = "GET_LATEST_SECTION_REQUEST"
export const GET_LATEST_SECTION_SUCCESS = "GET_LATEST_SECTION_SUCCESS"
export const GET_LATEST_SECTION_FAILURE = "GET_LATEST_SECTION_FAILURE"

export const GET_SECTION_1_ANSWERS_REQUEST = "GET_SECTION_1_ANSWERS_REQUEST"
export const GET_SECTION_1_ANSWERS_SUCCESS = "GET_SECTION_1_ANSWERS_SUCCESS"
export const GET_SECTION_1_ANSWERS_FAILURE = "GET_SECTION_1_ANSWERS_FAILURE"

export const GET_SECTION_2_ANSWERS_REQUEST = "GET_SECTION_2_ANSWERS_REQUEST"
export const GET_SECTION_2_ANSWERS_SUCCESS = "GET_SECTION_2_ANSWERS_SUCCESS"
export const GET_SECTION_2_ANSWERS_FAILURE = "GET_SECTION_2_ANSWERS_FAILURE"

export const GET_SECTION_3_ANSWERS_REQUEST = "GET_SECTION_3_ANSWERS_REQUEST"
export const GET_SECTION_3_ANSWERS_SUCCESS = "GET_SECTION_3_ANSWERS_SUCCESS"
export const GET_SECTION_3_ANSWERS_FAILURE = "GET_SECTION_3_ANSWERS_FAILURE"

export const GET_SECTION_4_ANSWERS_REQUEST = "GET_SECTION_4_ANSWERS_REQUEST"
export const GET_SECTION_4_ANSWERS_SUCCESS = "GET_SECTION_4_ANSWERS_SUCCESS"
export const GET_SECTION_4_ANSWERS_FAILURE = "GET_SECTION_4_ANSWERS_FAILURE"

export const GET_SECTION_5_ANSWERS_REQUEST = "GET_SECTION_5_ANSWERS_REQUEST"
export const GET_SECTION_5_ANSWERS_SUCCESS = "GET_SECTION_5_ANSWERS_SUCCESS"
export const GET_SECTION_5_ANSWERS_FAILURE = "GET_SECTION_5_ANSWERS_FAILURE"

export const GET_SECTION_6_ANSWERS_REQUEST = "GET_SECTION_6_ANSWERS_REQUEST"
export const GET_SECTION_6_ANSWERS_SUCCESS = "GET_SECTION_6_ANSWERS_SUCCESS"
export const GET_SECTION_6_ANSWERS_FAILURE = "GET_SECTION_6_ANSWERS_FAILURE"

export const GET_SECTION_7_ANSWERS_REQUEST = "GET_SECTION_7_ANSWERS_REQUEST"
export const GET_SECTION_7_ANSWERS_SUCCESS = "GET_SECTION_7_ANSWERS_SUCCESS"
export const GET_SECTION_7_ANSWERS_FAILURE = "GET_SECTION_7_ANSWERS_FAILURE"

export const GET_SECTION_8_ANSWERS_REQUEST = "GET_SECTION_8_ANSWERS_REQUEST"
export const GET_SECTION_8_ANSWERS_SUCCESS = "GET_SECTION_8_ANSWERS_SUCCESS"
export const GET_SECTION_8_ANSWERS_FAILURE = "GET_SECTION_8_ANSWERS_FAILURE"

export const GET_SECTION_9_ANSWERS_REQUEST = "GET_SECTION_9_ANSWERS_REQUEST"
export const GET_SECTION_9_ANSWERS_SUCCESS = "GET_SECTION_9_ANSWERS_SUCCESS"
export const GET_SECTION_9_ANSWERS_FAILURE = "GET_SECTION_9_ANSWERS_FAILURE"

