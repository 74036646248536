import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { viewHomeScreenProductRequest } from "../../../redux/Action/EcomUser/viewHomeScreenProductAction";
import ProductList from "../../Organisms/Products/ProductList";
import "./ProductHub.css";

function ProductHub() {
  const dispatch = useDispatch();

  const homeScreenProduct = useSelector(
    (state) => state.eCom.viewHomeScreenProduct
  );

  useEffect(() => {
    dispatch(viewHomeScreenProductRequest());
  }, [dispatch]);

  return (
    <div className="productHub">
      {homeScreenProduct.data?.data.data.data.length > 0 && (
        <>
          <div className="productHubTitle">Product Hub</div>
          <div className="productHubBody">
            <div className="productHubBodyContainer">
              <ProductList product={homeScreenProduct.data?.data.data.data} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ProductHub;
