import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/EcomUser/EcomUserActionType";
import * as actions from "../../Action/EcomUser/viewAllProductCategoryAction";
import NetworkService from "../../../network/Network/EcomUser/EcomUserNetwork";

export function* viewAllProductCategorySaga(action) { 
  try {
    let response = yield call(NetworkService.viewAllProductCategory, action);
    yield put(actions.viewAllProductCategorySuccess(response));
  } catch (err) {
    yield put(actions.viewAllProductCategoryFailure(err));
  }
}

export function* watchViewAllProductCategorySaga() {
  yield takeLatest(types.VIEW_ALL_PRODUCT_CATEGORY_REQUEST, viewAllProductCategorySaga);
}
