import * as types from "../../Config/EcomUser/EcomUserActionType"

export const viewAllProductCategoryRequest = () => {
    return {
        type: types.VIEW_ALL_PRODUCT_CATEGORY_REQUEST,
    }
}

export const  viewAllProductCategorySuccess = (data) => {
    return {
        type: types.VIEW_ALL_PRODUCT_CATEGORY_SUCCESS,
        data:data,
    }
}

export const  viewAllProductCategoryFailure = (data) => {
    return {
        type: types.VIEW_ALL_PRODUCT_CATEGORY_FAILURE,
        data:data,
    }
}