import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import backArrow from "../../../assets/hra/backArrow.png";
import hra8 from "../../../assets/hra/hra8.png";
import { GetQuestionSection7Request } from "../../../redux/Action/HealthRiskAssessment/GetQuestionSection7Action";
import { GetSection7AnswersRequest } from "../../../redux/Action/HealthRiskAssessment/GetSectionAnswers/GetSection7AnswersAction";
import { SaveSectionAnswersRequest } from "../../../redux/Action/HealthRiskAssessment/SaveSectionAnswersAction";
import storage from "../../../Utils/storage";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import SingleLogin from "../Login/SingleLogin";
import {
  dropDisabled,
  pickeryesno,
  pickeryesnomedication,
} from "./HraDropdowns";
import "./Sections.css";

function Section7() {
  const dispatch = useDispatch();
  const SectionValue = localStorage.getItem("SectionValue");
  const navigate = useNavigate();
  const [diabetes, setDiabetes] = useState("");
  const [hypertension, setHypertension] = useState("");
  const [bp, setBp] = useState("");
  const [cancer, setCancer] = useState("");
  const [HB1AC, setHB1AC] = useState("");
  const [otherIllness, setOtherIllness] = useState("");
  const [chronicIllness, setChronicIllness] = useState("");

  const Section7Schema = Yup.object().shape({
    Q41: Yup.string().required("Required field"),
    Q45: Yup.string().when("Q41", {
      is: "1",
      then: Yup.string().required("Required field"),
    }),
    Q42: Yup.string().when("Q41", {
      is: "1",
      then: Yup.string().required("Required field"),
    }),
    Q49: Yup.string().when("Q41", {
      is: "1",
      then: Yup.string().required("Required field"),
    }),

    Q43: Yup.string().when("Q42", {
      is: (value) => value === "1" || value === "2",
      then: Yup.string().required("Required field"),
    }),

    Q44: Yup.string().when("Q43", {
      is: "1",
      then: Yup.string()
        .test(
          "is-greater-than-4.5",
          "Value must be greater than or equal to 4.5",
          function (value) {
            return parseFloat(value) >= 4.5;
          }
        )
        .test(
          "is-less-than-50",
          "Value must be less than or equal to 50",
          function (value) {
            return parseFloat(value) <= 50.0;
          }
        )
        .test(
          "is-valid-number",
          "Value must be a number with at most 1 digit after the decimal point",
          function (value) {
            return /^[0-9]+\.?[0-9]{0,1}$/.test(value);
          }
        )
        .required("Required field"),
    }),
    Q46: Yup.string().when("Q45", {
      is: "1",
      then: Yup.string().required("Required field"),
    }),
    Q47: Yup.string().when("Q46", {
      is: "1",
      then: Yup.string().required("Required field"),
    }),
    Q48: Yup.string().when("Q46", {
      is: "1",
      then: Yup.string().required("Required field"),
    }),
    Q50: Yup.string().required("Required Field"),
    chronicIllnessReason: Yup.string().when("Q50", {
      is: "1",
      then: Yup.string().required("Please Enter the reason"),
    }),
    CancerReason: Yup.string().when("Q49", {
      is: "1",
      then: Yup.string().required("Please Enter the reason"),
    }),
  });

  useEffect(() => {
    dispatch(GetQuestionSection7Request());
    dispatch(GetSection7AnswersRequest());
  }, []);

  const GetQuestionSection = useSelector(
    (state) => state.getQuestion.getQuestionSection7
  );
 
  const currentSectionValue = localStorage.getItem("SectionValue");
  const GetSectionAnswers = useSelector(
    (state) => state.getSectionAnswer.getSection7Answers
  );
  const [json, setJson] = useState();
  const [counter, setCounter] = useState(false);

  useEffect(() => {
    if (GetSectionAnswers?.data?.data?.data?.data !== undefined) {
      setJson(JSON.parse(GetSectionAnswers?.data?.data?.data?.data));
      setCounter(true);
    }
  }, [GetSectionAnswers?.data?.data?.data?.data]);

  useEffect(() => {
    if (json !== null) {
      if (json?.Q41.toString() === "1") {
        setOtherIllness("1");

        if (json?.Q42.toString() === "1") {
          setDiabetes("1");
          if (json?.Q43.toString() === "1") {
            setHB1AC("1");
          } else {
            setHB1AC("0");
          }
        } else {
          setDiabetes("0");
          setHB1AC("0");
        }
        if (json?.Q42.toString() === "2") {
          setDiabetes("2");
          if (json?.Q43.toString() === "1") {
            setHB1AC("1");
          }
        }

        if (json?.Q45.toString() === "1") {
          setHypertension("1");
          if (json?.Q46.toString() === "1") {
            setBp("1");
          } else {
            setBp("0");
          }
        } else {
          setHypertension("0");
          setBp("0");
        }
        if (json?.Q49.toString() === "1") {
          setCancer("1");
        } else {
          setCancer("0");
        }
      } else {
        setOtherIllness("0");
      }

      if (json?.Q50.toString() === "1") {
        setChronicIllness("1");
      } else {
        setChronicIllness("0");
      }
    }
  }, [json]);

  const handleSubmit = (allValues, { setSubmitting }) => {
    localStorage.setItem("SectionValue", 8);

    if (allValues.Q41 === "0") {
      allValues.Q42 = "";
      allValues.Q43 = "";
      allValues.Q44 = "";
      allValues.Q45 = "";
      allValues.Q46 = "";
      allValues.Q47 = "";
      allValues.Q48 = "";
      allValues.Q49 = "";
      allValues.CancerReason = "";
    }
    if (allValues.Q50 === "0") {
      allValues.chronicIllnessReason = "";
    }
    if (allValues.Q42 === "0") {
      allValues.Q43 = "";
      allValues.Q44 = "";
    }
    if (allValues.Q43 === "0") {
      allValues.Q44 = "";
    }
    if (allValues.Q45 === "0") {
      allValues.Q46 = "";
      allValues.Q47 = "";
      allValues.Q48 = "";
    }
    if (allValues.Q46 === "0") {
      allValues.Q47 = "";
      allValues.Q48 = "";
    }
    if (allValues.Q49 === "0") {
      allValues.CancerReason = "";
    }

    localStorage.setItem("CancerReason", allValues.CancerReason);
    localStorage.setItem("chronicIllness", allValues.chronicIllnessReason);
    let values = Object.assign({}, allValues);
    Object.keys(values).map((key, index) => {
      if (key === "chronicIllnessReason" || key === "CancerReason") {
        delete values[key];
      }
    });
    dispatch(SaveSectionAnswersRequest({ values, currentSectionValue }));
    navigate("/health-risk-assessment/section-8a");
  };

  window.addEventListener("popstate", function (event) {
    storage.setStorage("SectionValue", 6);
  });
  const handlePrevious = () => {
    navigate("/health-risk-assessment/section-7b");
  };

  const handleOtherIllness = (values) => {
    if (values.target.value === "0") {
      setOtherIllness("0");
    }
    if (values.target.value === "1") {
      setOtherIllness("1");
    }
  };

  const handleChronicIllness = (values) => {
    if (values.target.value === "0") {
      setChronicIllness("0");
    }
    if (values.target.value === "1") {
      setChronicIllness("1");
    }
  };

  const handleDiabetes = (values) => {
    if (values.target.value === "0") {
      setDiabetes("0");
    }
    if (values.target.value === "1") {
      setDiabetes("1");
    }
    if (values.target.value === "2") {
      setDiabetes("2");
    }
  };

  const handleHB1AC = (values) => {
    if (values.target.value === "0") {
      setHB1AC("0");
    }
    if (values.target.value === "1") {
      setHB1AC("1");
    }
  };

  const handleHypertension = (values) => {
    if (values.target.value === "0") {
      setHypertension("0");
    }
    if (values.target.value === "1") {
      setHypertension("1");
    }
  };

  const handleBp = (values) => {
    if (values.target.value === "0") {
      setBp("0");
    }
    if (values.target.value === "1") {
      setBp("1");
    }
  };
  const handleCancer = (values) => {
    if (values.target.value === "0") {
      setCancer("0");
    }
    if (values.target.value === "1") {
      setCancer("1");
    }
  };

  const cir = localStorage.getItem("chronicIllness");
  const cr = localStorage.getItem("CancerReason");

  if (storage.getStorage("token") === null) {
    return <SingleLogin />;
  }

  if (GetQuestionSection.isLoading) {
    return <YuvaLoader />;
  }

  return (
    <>
      <ScrollToTop />

      <div className="HRAQuestionsMain">
        <div className="HRAQuestionsMainChild1">
          <p>Your Health Risk Assessment</p>
        </div>
        {SectionValue === "7" && (
          <div
            className="HRAQuestionsMainChild2"
            style={{ backgroundImage: `url(${hra8})` }}
          >
            <div className="HRAQuestionsMainChild2a">
              <div className="HRAQuestionsMainChild2a1">
                <p>GENERAL SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1 faded">
                <p>DIETARY SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1">
                <p>MENTAL HEALTH SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1 faded">
                <p>ALCOHOL RISK SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1">
                <p>SMOKING & TOBACCO SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1 faded">
                <p>SAFETY HABITS SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1">
                <p>CURRENT MEDICAL CONDITION SECTION</p>
              </div>

              {SectionValue === "7" && (
                <div className="HRAQuestionsMainChild2a2">
                  <Formik
                    initialValues={
                      !counter
                        ? {
                            Q41: "",
                            Q42: "",
                            Q43: "",
                            Q44: "",
                            Q45: "",
                            Q46: "",
                            Q47: "",
                            Q48: "",
                            Q49: "",
                            Q50: "",
                          }
                        : {
                            Q41: json?.Q41.toString(),
                            Q42: json?.Q42 === null ? "" : json?.Q42.toString(),
                            Q43: json?.Q43 === null ? "" : json?.Q43.toString(),
                            Q44: json?.Q44 === null ? "" : json?.Q44.toString(),
                            Q45: json?.Q45 === null ? "" : json?.Q45.toString(),
                            Q46: json?.Q46 === null ? "" : json?.Q46.toString(),
                            Q47: json?.Q47 === null ? "" : json?.Q47.toString(),
                            Q48: json?.Q48 === null ? "" : json?.Q48.toString(),
                            Q49: json?.Q49 === null ? "" : json?.Q49.toString(),
                            CancerReason: cr === "undefined" ? "" : cr,
                            Q50: json?.Q50.toString(),
                            chronicIllnessReason:
                              cir === "undefined" ? "" : cir,
                          }
                    }
                    validationSchema={Section7Schema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                  >
                    {({ setFieldValue }) => {
                      return (
                        <Form>
                          <div className="HRAQuestionsMainChild2a2div">
                            <div className="hraBackArrow">
                              <img
                                src={backArrow}
                                onClick={handlePrevious}
                                alt="backArrow"
                              />
                              <p>Current Medical Condition</p>
                            </div>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection?.data?.data?.data?.data[0]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q41"
                                className="select"
                                onChange={(e) => {
                                  setFieldValue("Q41", e.target.value);
                                  handleOtherIllness(e);
                                }}
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickeryesno.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q41"
                                component="div"
                              />
                            </label>

                            {otherIllness === "1" && (
                              <>
                                <label className="Q_A">
                                  <p>
                                    {
                                      GetQuestionSection?.data?.data?.data
                                        ?.data[1].question
                                    }
                                  </p>
                                  <Field
                                    as="select"
                                    name="Q42"
                                    className="select"
                                    onChange={(e) => {
                                      setFieldValue("Q42", e.target.value);
                                      if (e.target.value === "0") {
                                        setFieldValue("Q44", 5);
                                      }
                                      handleDiabetes(e);
                                    }}
                                  >
                                    <option value={dropDisabled.value} disabled>
                                      {dropDisabled.message}
                                    </option>
                                    {pickeryesnomedication.map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.option}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    className="errorMessageValidation"
                                    name="Q42"
                                    component="div"
                                  />
                                </label>
                                {(diabetes === "1" || diabetes === "2") && (
                                  <>
                                    <label className="Q_A">
                                      <p>
                                        {
                                          GetQuestionSection?.data?.data?.data
                                            ?.data[2].question
                                        }
                                      </p>
                                      <Field
                                        as="select"
                                        name="Q43"
                                        className="select"
                                        onChange={(e) => {
                                          setFieldValue("Q43", e.target.value);
                                          handleHB1AC(e);
                                        }}
                                      >
                                        <option
                                          value={dropDisabled.value}
                                          disabled
                                        >
                                          {dropDisabled.message}
                                        </option>
                                        {pickeryesno.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.option}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        className="errorMessageValidation"
                                        name="Q43"
                                        component="div"
                                      />
                                    </label>

                                    {HB1AC === "1" && (
                                      <>
                                        <label className="Q_A">
                                          <p>
                                            {
                                              GetQuestionSection?.data?.data
                                                ?.data?.data[3].question
                                            }
                                          </p>
                                          <Field
                                            type="number"
                                            name="Q44"
                                            className="inputField"
                                            onWheel={(event) =>
                                              event.currentTarget.blur()
                                            }
                                          />
                                          <ErrorMessage
                                            className="errorMessageValidation"
                                            name="Q44"
                                            component="div"
                                          />
                                        </label>
                                      </>
                                    )}
                                  </>
                                )}

                                <label className="Q_A">
                                  <p>
                                    {
                                      GetQuestionSection?.data?.data?.data
                                        ?.data[4].question
                                    }
                                  </p>
                                  <Field
                                    as="select"
                                    name="Q45"
                                    className="select"
                                    onChange={(e) => {
                                      setFieldValue("Q45", e.target.value);
                                      handleHypertension(e);
                                    }}
                                  >
                                    <option value={dropDisabled.value} disabled>
                                      {dropDisabled.message}
                                    </option>
                                    {pickeryesno.map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.option}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    className="errorMessageValidation"
                                    name="Q45"
                                    component="div"
                                  />
                                </label>

                                {hypertension === "1" && (
                                  <>
                                    <label className="Q_A">
                                      <p>
                                        {
                                          GetQuestionSection?.data?.data?.data
                                            ?.data[5].question
                                        }
                                      </p>
                                      <Field
                                        as="select"
                                        name="Q46"
                                        // value={bp}
                                        className="select"
                                        onChange={(e) => {
                                          setFieldValue("Q46", e.target.value);
                                          handleBp(e);
                                        }}
                                      >
                                        <option
                                          value={dropDisabled.value}
                                          disabled
                                        >
                                          {dropDisabled.message}
                                        </option>
                                        {pickeryesno.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.option}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        className="errorMessageValidation"
                                        name="Q46"
                                        component="div"
                                      />
                                    </label>

                                    {bp === "1" && (
                                      <>
                                        <label className="Q_A">
                                          <p>
                                            {
                                              GetQuestionSection?.data?.data
                                                ?.data?.data[6].question
                                            }
                                          </p>
                                          <Field
                                            type="number"
                                            name="Q47"
                                            // placeholder="Waist (20 to 47 inches)"
                                            className="inputField"
                                            onWheel={(event) =>
                                              event.currentTarget.blur()
                                            }
                                          />
                                          <ErrorMessage
                                            className="errorMessageValidation"
                                            name="Q47"
                                            component="div"
                                          />
                                        </label>

                                        <label className="Q_A">
                                          <p>
                                            {
                                              GetQuestionSection?.data?.data
                                                ?.data?.data[7].question
                                            }
                                          </p>
                                          <Field
                                            type="number"
                                            name="Q48"
                                            // placeholder="Waist size (20 to 47 inches)"
                                            className="inputField"
                                            onWheel={(event) =>
                                              event.currentTarget.blur()
                                            }
                                          />
                                          <ErrorMessage
                                            className="errorMessageValidation"
                                            name="Q48"
                                            component="div"
                                          />
                                        </label>
                                      </>
                                    )}
                                  </>
                                )}

                                <label className="Q_A">
                                  <p>
                                    {
                                      GetQuestionSection?.data?.data?.data
                                        ?.data[8].question
                                    }
                                  </p>
                                  <Field
                                    as="select"
                                    name="Q49"
                                    className="select"
                                    onChange={(e) => {
                                      setFieldValue("Q49", e.target.value);
                                      handleCancer(e);
                                    }}
                                  >
                                    <option value={dropDisabled.value} disabled>
                                      {dropDisabled.message}
                                    </option>
                                    {pickeryesno.map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.option}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    className="errorMessageValidation"
                                    name="Q49"
                                    component="div"
                                  />
                                </label>

                                {cancer === "1" && (
                                  <>
                                    <label className="Q_A">
                                      <p>Please Specify</p>
                                      <Field
                                        type="text"
                                        name="CancerReason"
                                        placeholder="Please Specify"
                                        className="inputField"
                                      />
                                      <ErrorMessage
                                        className="errorMessageValidation"
                                        name="CancerReason"
                                        component="div"
                                      />
                                    </label>
                                  </>
                                )}
                              </>
                            )}

                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection?.data?.data?.data?.data[9]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q50"
                                className="select"
                                onChange={(e) => {
                                  setFieldValue("Q50", e.target.value);
                                  handleChronicIllness(e);
                                }}
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickeryesno.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q50"
                                component="div"
                              />
                            </label>

                            {chronicIllness === "1" && (
                              <>
                                <label className="Q_A">
                                  <p>Please Specify</p>
                                  <Field
                                    type="text"
                                    name="chronicIllnessReason"
                                    placeholder="Please Specify"
                                    className="inputField"
                                  />
                                  <ErrorMessage
                                    className="errorMessageValidation"
                                    name="chronicIllnessReason"
                                    component="div"
                                  />
                                </label>
                              </>
                            )}

                            <button
                              type="submit"
                              className="HRAQuestionsButton"
                            >
                              Next
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        )}
        {SectionValue !== "7" && (
          <div className="hraDenied">
            Not Authorized !! please navigate to previous sections
          </div>
        )}
      </div>
    </>
  );
}

export default Section7;
