import FilterAltIcon from "@mui/icons-material/FilterAlt";
import React, { useState } from "react";
import FilterTest from "../Filters/FilterTest";
import "./Filters.css";

function Filters({
  brandDropdown,
  handleCheckboxBrandChange,
  checkboxesBrand,
  categoryDropdown,
  handleCheckboxCategoryChange,
  checkboxesCategory,
}) {
  const [filterClick, setFilterClick] = useState(false);
  return (
    <div>
      <div
        className="productCategoryBodyRightFilter"
        onClick={() => setFilterClick(true)}
      >
        <div>
          Filter
          <FilterAltIcon fontSize="small" />
        </div>
      </div>
      <div className={filterClick ? "drawer open" : "drawer"}>
        <div className="drawer-content">
          <span className="drawer-content-span">Filters</span>
          <span
            className="close-btn"
            onClick={() => setFilterClick(!filterClick)}
          >
            &times;
          </span>
          <hr />

          <div>
            <FilterTest
              title={"Brand"}
              mapping={brandDropdown}
              handleCheckboxChange={handleCheckboxBrandChange}
              checkboxBrand={checkboxesBrand}
            />
            <hr />
            <FilterTest
              title={"Category"}
              mapping={categoryDropdown}
              handleCheckboxChange={handleCheckboxCategoryChange}
              checkboxBrand={checkboxesCategory}
            />
            <hr />
            {/* {subCategoryShow && (
              <div className="brandsTitle">
                <span className="brandSubHead">Sub Category</span>
                <div className="brandCheckbox">
                  {subCategoryDropdown.map((item, index) => {
                    if (index < 8) {
                      return (
                        <label key={index}>
                          <input
                            type="checkbox"
                            value={item.id}
                            checked={checkboxesSubCategory.includes(item.id)}
                            onChange={handleCheckboxSubCategoryChange}
                          />
                          <span className="itemName">{item.name}</span>
                        </label>
                      );
                    }
                  })}
                </div>
                {subCategoryDropdown.length > 8 && (
                  <div
                    className="moreFilter"
                    onClick={() => setMoreSubCategoryClick(true)}
                  >
                    + {subCategoryDropdown.length - 8} more
                  </div>
                )}
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filters;
