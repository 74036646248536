import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Packages/PackageLabTestActionType";
import * as actions from "../../Action/Packages/LifestylePackageAction";
import NetworkService from "../../../network/Network/Packages/PackageLabTestNetwork";

export function* LifestylePackageSaga(action) {
  try {
    let response = yield call(NetworkService.lifestylePackage, action);
    yield put(actions.lifestylePackageSuccess(response));
  } catch (err) {
    yield put(actions.lifestylePackageFailure(err));
  }
}

export function* watchLifestylePackageSaga() {
  yield takeLatest(types.LIFESTYLE_PACKAGE_REQUEST, LifestylePackageSaga);
}
