import RequestService from "../../RequestService";
import { api } from "../../../helpers/Packages/PackageLabTestConstant";


class NetworkService {
  packageLabTest(action) {
    const endPoint = `${api.packageLabTest}/${action.uuid}`;
    return RequestService.getRequest(endPoint);
  }

  packageSelectTest(action) {
    const endPoint = `${api.viewAllPackages}?pageNo=1&pageSize=18&search=${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  packageGetLab(action) {
    const endPoint = `${api.packageGetLab}/${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  GetTestPackageListing(action) {
    const endPoint = `${api.GetTestPackageListing}?pageNo=${action.pageNo}&pageSize=${action.pageSize}&rangeEnum=${action.rangeEnum}`
    return RequestService.postRequest(endPoint, {
      maxPrice: action.maxPrice,
      minPrice: action.minPrice,
      testType: action.testType,
      radiology: action.radiology
    });
  }

  lifestylePackage(action) {
    const endPoint = `${api.lifeStylePackage}/${action.data.packageEnum}?fromWeb=${action.data.fromWeb}&pageNo=${action.data.pageNo}&pageSize=${action.data.pageSize}`;
    return RequestService.getRequest(endPoint);
  }

  lifestylePackageViewAll(action) {
    const endPoint = `${api.lifeStylePackageViewAll}?fixed=${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  diagnosticSelectTest(action) {
    const endPoint = `${api.diagnosticTest}?pageNo=${action.page}&pageSize=18&search=${action.data}`;
    return RequestService.getRequest(endPoint);
  }

}
export default new NetworkService();