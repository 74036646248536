import * as types from "../../Config/Account/InReportActionType";

export const myReportRequest = () => {
  return {
    type: types.MY_REPORT_REQUEST,
  };
};

export const myReportSuccess = (data) => {
  return {
    type: types.MY_REPORT_SUCCESS,
    data,
  };
};

export const myReportFailure = (data) => {
  return {
    type: types.MY_REPORT_FAILURE,
    data,
  };
};
