import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/CorporateSignUp/CorporateSignUpActionType";
import * as actions from "../../Action/CorporateSignUp/getCorporateInfoAction";
import NetworkService from "../../../network/Network/CorporateSignUp/CorporateSignUpNetwork";


export function* getCorporateSignUpTokenInfoSaga(action) { 
  try {
    let response = yield call(NetworkService.getCorporateSignUpTokenInfo, action);
    yield put(actions.getCorporateTokenInfoSuccess(response));
  } catch (err) {
    yield put(actions.getCorporateTokenInfoFailure(err));
  }
}

export function* watchGetCorporateSignUpTokenInfoSaga() {
  yield takeLatest(types.GET_CORPORATE_TOKEN_INFO_REQUEST, getCorporateSignUpTokenInfoSaga);
}
