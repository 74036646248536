import * as types from "../../../Config/OTP/OTPValidationMobile/otpMobileValidationActionType"

export const otpMobileValidationRequest = (data) => {
    return {
        type: types.OTPMOBILEVALIDATION_REQUEST,
        data:data
    }
}

export const otpMobileValidationRequestSuccess = (data) => {
    return {
        type: types.OTPMOBILEVALIDATION_SUCCESS,
        data:data,
    }
}

export const otpMobileValidationRequestFailure = (data) => {
    return {
        type: types.OTPMOBILEVALIDATION_FAILURE,
        data:data,
    }
}