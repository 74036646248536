import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Account/InReportActionType";
import * as actions from "../../Action/Account/MyReportAction";
import NetworkService from "../../../network/Network/MyAccount/MyAccountNetwork";

export function* myReportSaga(action) {

  try {
    let response = yield call(NetworkService.myReport, action);
    yield put(actions.myReportSuccess(response));
  } catch (err) {
    yield put(actions.myReportFailure(err));
  }
}

export function* watchMyReportSaga() {
  yield takeLatest(types.MY_REPORT_REQUEST, myReportSaga);
}
