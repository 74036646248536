import * as types from "../../Config/Account/InReportDiagnosticActionType";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const myReportDiagnosticReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.MY_REPORT_DIAGNOSTIC_SUCCESS:
      return {
        ...initialState,
        data: action,
        isLoading: false,
        
      };
    case types.MY_REPORT_DIAGNOSTIC_FAILURE:
      return {
        ...initialState,
        error: action,
      };
    case types.MY_REPORT_DIAGNOSTIC_REQUEST:
      return {
        ...initialState,
        isLoading: true,
      };
    default:
      return state;
  }
};
