import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/User/userActionType";
import * as actions from "../../Action/User/LoginGoogle";
import NetworkService from "../../../network/Network/User/userNetwork";

export function* loginGoogleSaga(action) {
  try {
    let response = yield call(NetworkService.loginGoogleService, action);
    yield put(actions.googleLoginSuccess(response));
  } catch (err) {
    yield put(actions.googleLoginFailure(err));
  }
}

export function* watchLoginGoogleSaga() {
  yield takeLatest(types.LOGIN_GOOGLE_REQUEST, loginGoogleSaga);
}