import { useLocation } from "react-router-dom";

function PaymentRedirectMobile() {
  const search = useLocation().search;
  const encRequest = new URLSearchParams(search).get("encRequest");
  if (!encRequest) {
    return null;
  }
  return (window.location.href = `paymentinit.html?encRequest=${encRequest}`);
}

export default PaymentRedirectMobile;
