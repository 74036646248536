export const getTestMetaData = (id) => {
  const numericId = Number(id);

  switch (numericId) {
    case 4:
      return {
        title: "Complete Blood Test, CBC Test | Labs Near Me",
        description:
          "Looking for a Complete Blood Test? Yuva Health offers accurate, affordable CBC Test with quick results. Book now for reliable diagnostics.",
        keywords: "Complete Blood test// CBC Test",
      };
    case 5:
      return {
        title: "Transferrin Saturation Test - Accurate Results | Labs Near Me",
        description:
          "Need a Transferrin Saturation Test? Find labs near me at Yuva Health for precise testing, comprehensive diagnostics, and expert care.",
        keywords: "Transferrin Saturation Test// labs near me",
      };
    case 32:
      return {
        title: "LFT KFT Test || Yuva Health",
        description:
          "Looking for LFT, KFT tests? Yuva Health offers comprehensive kidney tests. Book now for accurate diagnostics and expert care near you.",
        keywords: "KFT Test, LFT KFT TEST",
      };
    case 23:
      return {
        title: "Thyroid Gland Test||Blood Test to Check Thyroid",
        description:
          "Looking for a thyroid gland test? Yuva Health offers blood tests to check thyroid function. Find labs near me and book today.",
        keywords:
          "thyroid gland test, blood test to check thyroid, labs near me",
      };
    default:
      return {
        title: "Yuva Health",
        description: "Description for Yuva Health.",
        keywords: "Yuva Health",
      };
  }
};
