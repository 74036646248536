export const api = {
  login:"/login",
  loginGoogle:"/google-login",
  register:"/signup",
  forgetPassword:"/forgot-password",
  checkHashId:"/verify-link",
  resetPassword:"/reset-password",
  otpMobileVerification:"/verify-link",
  getProfile:"/profile",
  updateProfile:"/profile",
  AddRelation:"/relation",
  getAllRelatives:"/relation",
  UploadFamilyPicture:"/familyPic",
  UploadProfilePicture:"/profilePic",
  logoutUser:"/logout"
};

