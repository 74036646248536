import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../../Action/HealthRiskAssessment/GetQuestionSection6Action";
import NetworkService from "../../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* GetQuestionSection6Saga(action) {
  try {
    let response = yield call(NetworkService.GetQuestionSection6, action);
    yield put(actions.GetQuestionSection6Success(response));
  } catch (err) {
    yield put(actions.GetQuestionSection6Failure(err));
  }
}

export function* watchGetQuestionSection6Saga() {
  yield takeLatest(types.GET_QUESTION_SECTION6_REQUEST, GetQuestionSection6Saga);
}
