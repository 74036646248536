import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import backArrow from "../../../assets/hra/backArrow.png";
import hra1 from "../../../assets/hra/hra1.png";
import { GetQuestionSection1Request } from "../../../redux/Action/HealthRiskAssessment/GetQuestionSection1Action";
import { GetSection1AnswersRequest } from "../../../redux/Action/HealthRiskAssessment/GetSectionAnswers/GetSection1AnswersAction";
import { Section1AnswersSuccess } from "../../../redux/Action/HealthRiskAssessment/HRAAnswers/Section1AnswersAction";
import { SaveSectionAnswersRequest } from "../../../redux/Action/HealthRiskAssessment/SaveSectionAnswersAction";
import storage from "../../../Utils/storage";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import useUnsavedChangesWarning from "../../Molecules/Hooks/useUnsavedChangesWarning";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import SingleLogin from "../Login/SingleLogin";
import { dropDisabled, pickergender } from "./HraDropdowns";
import "./HRAscreens/HRAQuestions.css";
import { Section1Schema } from "./ValidationSchemas";

function Section1() {
  const dispatch = useDispatch();
  const SectionValue = localStorage.getItem("SectionValue");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(GetQuestionSection1Request());
    dispatch(GetSection1AnswersRequest());
  }, []);

  const GetQuestionSection = useSelector(
    (state) => state.getQuestion.getQuestionSection1
  );

  const GetSectionAnswers = useSelector(
    (state) => state.getSectionAnswer.getSection1Answers
  );

  const [json, setJson] = useState();
  const [counter, setCounter] = useState(false);

  useEffect(() => {
    if (GetSectionAnswers?.data?.data?.data?.data !== undefined) {
      setJson(JSON.parse(GetSectionAnswers?.data?.data?.data?.data));
      setCounter(true);
    }
  }, [GetSectionAnswers?.data?.data?.data?.data]);

  const currentSectionValue = localStorage.getItem("SectionValue");

  const handleSubmit = (values, { setSubmitting }) => {
    localStorage.setItem("age", values.Q2);
    localStorage.setItem("SectionValue", 2);
    dispatch(Section1AnswersSuccess(values));
    dispatch(SaveSectionAnswersRequest({ values, currentSectionValue }));
    navigate("/health-risk-assessment/section-1a");
  };

  const handlePrevious = () => {
    localStorage.setItem("SectionValue", 1);
    navigate("/hraStart");
    window.location.reload();
  };

  if (storage.getStorage("token") === null) {
    return <SingleLogin />;
  }

  if (GetQuestionSection?.isLoading) {
    return <YuvaLoader />;
  }

  return (
    <>
      <ScrollToTop />

      <div className="HRAQuestionsMain">
        <div className="HRAQuestionsMainChild1">
          <p>Your Health Risk Assessment</p>
        </div>
        {SectionValue === "1" && (
          <div
            className="HRAQuestionsMainChild2"
            style={{ backgroundImage: `url(${hra1})` }}
          >
            <div className="HRAQuestionsMainChild2a">
              <div className="HRAQuestionsMainChild2a1">
                <p>GENERAL SECTION</p>
              </div>
              {SectionValue === "1" && (
                <div className="HRAQuestionsMainChild2a2">
                  <Formik
                    initialValues={
                      counter === false
                        ? {
                            Q2: parseInt(localStorage.getItem("age")),
                            Q3: "",
                            Q4: "",
                            Q5: "",
                            Q58:
                              localStorage.getItem("gender") === "Male"
                                ? "0"
                                : localStorage.getItem("gender") === "Female"
                                ? "1"
                                : "",
                          }
                        : {
                            Q2: parseInt(json?.Q2),
                            Q3: json?.Q3,
                            Q4: json?.Q4,
                            Q5: json?.Q5,
                            Q58: json?.Q58,
                          }
                    }
                    validationSchema={Section1Schema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                  >
                    {
                      <Form>
                        <div className="HRAQuestionsMainChild2a2div">
                          <div className="hraBackArrow">
                            {/* <img src={backArrow} onClick={handlePrevious} alt="backArrow"/> */}
                            <p>General Section</p>
                          </div>
                          <label className="Q_A">
                            <p>
                              {
                                GetQuestionSection?.data?.data?.data?.data[0]
                                  .question
                              }
                            </p>
                            <Field
                              type="number"
                              name="Q2"
                              placeholder="Age (20 to 100 Years)"
                              className="inputField"
                              onWheel={(event) => event.currentTarget.blur()}
                              disabled={true}
                              style={{
                                "-moz-appearance": "textfield",
                                "-webkit-appearance": "textfield",
                              }}
                            />
                            <ErrorMessage
                              className="errorMessageValidation"
                              name="Q2"
                              component="div"
                            />
                          </label>

                          <label className="Q_A">
                            <p>
                              {
                                GetQuestionSection?.data?.data?.data?.data[1]
                                  .question
                              }
                            </p>
                            <Field
                              type="number"
                              name="Q3"
                              placeholder="Height  (120 to 219 cm)"
                              className="inputField"
                              onWheel={(event) => event.currentTarget.blur()}
                            />
                            <ErrorMessage
                              className="errorMessageValidation"
                              name="Q3"
                              component="div"
                            />
                          </label>

                          <label className="Q_A">
                            <p>
                              {
                                GetQuestionSection?.data?.data?.data?.data[2]
                                  .question
                              }
                            </p>
                            <Field
                              type="number"
                              name="Q4"
                              placeholder="Weight  (20 to 200 kg)"
                              className="inputField"
                              onWheel={(event) => event.currentTarget.blur()}
                            />
                            <ErrorMessage
                              className="errorMessageValidation"
                              name="Q4"
                              component="div"
                            />
                          </label>

                          <label className="Q_A">
                            <p>
                              {
                                GetQuestionSection?.data?.data?.data?.data[3]
                                  .question
                              }
                            </p>
                            <Field
                              type="number"
                              name="Q5"
                              placeholder="Waist size (20 to 47 inches)"
                              className="inputField"
                              onWheel={(event) => event.currentTarget.blur()}
                            />
                            <ErrorMessage
                              className="errorMessageValidation"
                              name="Q5"
                              component="div"
                            />
                          </label>

                          <label className="Q_A">
                            <p>
                              {
                                GetQuestionSection?.data?.data?.data?.data[4]
                                  .question
                              }
                            </p>
                            <Field
                              as="select"
                              name="Q58"
                              className="select"
                              disabled={true}
                            >
                              <option value={dropDisabled.value} disabled>
                                {dropDisabled.message}
                              </option>
                              {pickergender.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.option}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              className="errorMessageValidation"
                              name="Q58"
                              component="div"
                            />
                          </label>

                          <button type="submit" className="HRAQuestionsButton">
                            Next
                          </button>
                        </div>
                      </Form>
                    }
                  </Formik>
                </div>
              )}
            </div>
          </div>
        )}

        {SectionValue !== "1" && (
          <div className="hraDenied">
            Not Authorized !! please navigate to previous sections
          </div>
        )}
      </div>
    </>
  );
}
export default Section1;
