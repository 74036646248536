import * as types from "../../Config/ERM/MedicalRecordListingConfig"

export const downloadErmReportRequest = (data) => {
    return {
        type: types.DOWNLOAD_ERM_REPORT_REQUEST,
        data:data
    }
}

export const downloadErmReportSuccess = (data) => {
    return {
        type: types.DOWNLOAD_ERM_REPORT_SUCCESS,
        data:data,
    }
}

export const downloadErmReportFailure = (data) => {
    return {
        type: types.DOWNLOAD_ERM_REPORT_FAILURE,
        data:data,
    }
}