import * as types from "../../Config/Plan/PlanActionType";

export const getPlanRequestCall = (data) => {
  return {
    type: types.PLAN_REQUEST_CALL_REQUEST,
    data:data,
  };
};

export const getPlanRequestCallSuccess = (data) => {
  return {
    type: types.PLAN_REQUEST_CALL_SUCCESS,
    data: data,
  };
};

export const getPlanRequestCallFailure = (data) => {
  return {
    type: types.PLAN_REQUEST_CALL_FAILURE,
    data: data,
  };
};
