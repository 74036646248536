import React from "react";
import "./button.css";
import addIcon from "../../../../assets/common/addIcon.png";

function AddButton({ onClick, disable }) {
  return (
    <button disabled={disable} onClick={onClick} className="addButton">
      <img src={addIcon} alt="add icon" />
    </button>
  );
}

export default AddButton;
