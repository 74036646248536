import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Account/GetDocumentTypeActionType";
import * as actions from "../../Action/Account/GetDocumentTypeAction ";
import NetworkService from "../../../network/Network/AccountsSetting/AccountsNetwork";

export function* GetDocumentTypeSaga(action) {

  try {
    let response = yield call(NetworkService.GetDocumentType, action);
    yield put(actions.GetDocumentTypeSuccess(response));
  } catch (err) {
    yield put(actions.GetDocumentTypeFailure(err));
  }
}

export function* watchGetDocumentTypeSaga() {
  yield takeLatest(types.GET_DOCUMENT_TYPE_REQUEST, GetDocumentTypeSaga);
}
