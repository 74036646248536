import * as types from "../../Config/User/userActionType";

export const UploadProfilePicRequest = (data) => {
    return {
        type: types.UPLOAD_PROFILE_PICTURE_REQUEST,
        data:data,
    }
}

export const UploadProfilePicSuccess = (data) => {
    return {
        type: types.UPLOAD_PROFILE_PICTURE_SUCCESS,
        data:data,
    }
}

export const UploadProfilePicFailure = (data) => {
    return {
        type: types.UPLOAD_PROFILE_PICTURE_FAILURE,
        data:data,
    }
}