import Fingerprint2 from "fingerprintjs2";
import Cookies from "js-cookie";

const SessionId = () => {
  if (Cookies.get("SESSION_ID") === undefined || Cookies.get("SESSION_ID") === null) {
    Fingerprint2.get((components) => {
      const values = components.map((component) => component.value);
      const deviceId = Fingerprint2.x64hash128(values.join(""), 31);
      
      Cookies.set("SESSION_ID", deviceId, {
        domain: process.env.REACT_APP_DOMAIN,
        path: "/",
        sameSite: 'Lax',
        expires: 30,
      });
    });
  }
};

export default SessionId;