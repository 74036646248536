import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Network/networkActionType";
import * as actions from "../../Action/Network/diagnosticNetworkFormAction";
import NetworkService from "../../../network/Network/Network/NetworkPageNetwork";

export function* diagnosticNetworkFormSaga(action) {
  try {
    let response = yield call(NetworkService.diagnosticNetworkForm, action);
    yield put(actions.diagnosticNetworkFormSuccess(response));
  } catch (err) {
    yield put(actions.diagnosticNetworkFormFailure(err));
  }
}

export function* watchDiagnosticNetworkFormSaga() {
  yield takeLatest(types.DIAGNOSTIC_NETWORK_FORM_REQUEST, diagnosticNetworkFormSaga);
}