import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AddCart } from "../../../Utils/addCart";
import useLocalStorageSync from "../../../hooks/useLocalStorageSync";
import { createCartClear } from "../../../redux/Action/Cart/CreateCartAction";
import { viewProductByIdRequest } from "../../../redux/Action/EcomUser/viewProductByIdAction";
import Title from "../../Atoms/Title/Title";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import ProductDescription from "../../Organisms/Products/ProductDescription";
import ProductHero from "../../Organisms/Products/ProductHero";
import ProductImageCarousel from "../../Organisms/Products/ProductImageCarousel";
import ProductShortDescription from "../../Organisms/Products/ProductShortDescription";
import RelatedProducts from "../../Organisms/Products/RelatedProducts";
import "./ProductInfo.css";

function ProductInfo() {
  const dispatch = useDispatch();

  const search = useLocation().search;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedSize, setSelectedSize] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [itemList, setItemList] = useState();

  const itemDtoList = useLocalStorageSync("itemDtoList");
  const productId = new URLSearchParams(search).get("product-id");

  const createCart = useSelector((state) => state.cart.createCart);
  const productById = useSelector((state) => state.eCom.viewProductById);

  useEffect(() => {
    dispatch(viewProductByIdRequest(productId));
  }, [productId,dispatch]);

  useEffect(() => {
    if (createCart.data?.data.status === 200 && itemList) {
      localStorage.setItem("itemDtoList", JSON.stringify(itemList));
      window.dispatchEvent(new Event("storage"));
    }
    return () => {
      dispatch(createCartClear());
    };
  }, [createCart.data, createCart.error, itemList,dispatch]);

  const handleSizeClick = (index) => {
    setSelectedSize(index);
  };
  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleAddToCart = async () => {
    const data = {
      productPriceId:
        productById.data.data.data.data.productPriceResponseDtoForUserList[
          selectedSize
        ].priceId,
      name: productById.data.data.data.data.name,
      id: productById.data.data.data.data.id,
    };
    try {
      setIsSubmitting(true);
      setItemList(await AddCart(data, dispatch, setIsSubmitting, quantity));
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div>
        <ScrollToTop />
        <Title title={`Yuva | ${productById.data?.data.data.data.name}`}/>
        <ProductHero />
        <div className="productInfo">
          <ProductImageCarousel
            mapData={productById.data?.data.data.data.productImageList}
          />
          <ProductShortDescription
            data={productById.data?.data.data.data}
            handleDecrement={handleDecrement}
            handleIncrement={handleIncrement}
            handleSizeClick={handleSizeClick}
            selectedSize={selectedSize}
            quantity={quantity}
            handleAddToCart={handleAddToCart}
            isSubmitting={isSubmitting}
            itemDtoList={itemDtoList}
          />
        </div>
        <div className="productDescription">
          {productById.data?.data.data.data.description && (
            <ProductDescription
              title="Description"
              description={productById.data?.data.data.data.description}
            />
          )}
          {productById.data?.data.data.data.nutritional && (
            <ProductDescription
              title="Nutritional Value"
              description={productById.data?.data.data.data.nutritional}
            />
          )}
        </div>
        {productById.data?.data.data.data.relatedProductsInGridView.length >
          0 && (
          <div className="productInfoRelatedProduct">
            <RelatedProducts
              mapData={
                productById.data?.data.data.data.relatedProductsInGridView
              }
            />
          </div>
        )}
      </div>
    </>
  );
}

export default ProductInfo;
