export const VIEW_ALL_PRODUCT_CATEGORY_REQUEST = "VIEW_ALL_PRODUCT_CATEGORY_REQUEST"
export const VIEW_ALL_PRODUCT_CATEGORY_SUCCESS = "VIEW_ALL_PRODUCT_CATEGORY_SUCCESS"
export const VIEW_ALL_PRODUCT_CATEGORY_FAILURE = "VIEW_ALL_PRODUCT_CATEGORY_FAILURE"

export const VIEW_PRODUCT_BY_CATEGORY_ID_REQUEST = "VIEW_PRODUCT_BY_CATEGORY_ID_REQUEST"
export const VIEW_PRODUCT_BY_CATEGORY_ID_SUCCESS = "VIEW_PRODUCT_BY_CATEGORY_ID_SUCCESS"
export const VIEW_PRODUCT_BY_CATEGORY_ID_FAILURE = "VIEW_PRODUCT_BY_CATEGORY_ID_FAILURE"

export const VIEW_PRODUCT_BY_ID_REQUEST = "VIEW_PRODUCT_BY_ID_REQUEST"
export const VIEW_PRODUCT_BY_ID_SUCCESS = "VIEW_PRODUCT_BY_ID_SUCCESS"
export const VIEW_PRODUCT_BY_ID_FAILURE = "VIEW_PRODUCT_BY_ID_FAILURE"

export const VIEW_TOP_CATEGORIES_REQUEST = "VIEW_TOP_CATEGORIES_REQUEST"
export const VIEW_TOP_CATEGORIES_SUCCESS = "VIEW_TOP_CATEGORIES_SUCCESS"
export const VIEW_TOP_CATEGORIES_FAILURE = "VIEW_TOP_CATEGORIES_FAILURE"

export const VIEW_ALL_PRODUCTS_REQUEST = "VIEW_ALL_PRODUCTS_REQUEST"
export const VIEW_ALL_PRODUCTS_SUCCESS = "VIEW_ALL_PRODUCTS_SUCCESS"
export const VIEW_ALL_PRODUCTS_FAILURE = "VIEW_ALL_PRODUCTS_FAILURE"

export const GET_BRAND_DROPDOWN_REQUEST = "GET_BRAND_DROPDOWN_REQUEST"
export const GET_BRAND_DROPDOWN_SUCCESS = "GET_BRAND_DROPDOWN_SUCCESS"
export const GET_BRAND_DROPDOWN_FAILURE = "GET_BRAND_DROPDOWN_FAILURE"

export const GET_CATEGORY_DROPDOWN_REQUEST = "GET_CATEGORY_DROPDOWN_REQUEST"
export const GET_CATEGORY_DROPDOWN_SUCCESS = "GET_CATEGORY_DROPDOWN_SUCCESS"
export const GET_CATEGORY_DROPDOWN_FAILURE = "GET_CATEGORY_DROPDOWN_FAILURE"

export const GET_SUB_CATEGORY_DROPDOWN_REQUEST = "GET_SUB_CATEGORY_DROPDOWN_REQUEST"
export const GET_SUB_CATEGORY_DROPDOWN_SUCCESS = "GET_SUB_CATEGORY_DROPDOWN_SUCCESS"
export const GET_SUB_CATEGORY_DROPDOWN_FAILURE = "GET_SUB_CATEGORY_DROPDOWN_FAILURE"

export const VIEW_HOME_SCREEN_PRODUCT_REQUEST = "VIEW_HOME_SCREEN_PRODUCT_REQUEST"
export const VIEW_HOME_SCREEN_PRODUCT_SUCCESS = "VIEW_HOME_SCREEN_PRODUCT_SUCCESS"
export const VIEW_HOME_SCREEN_PRODUCT_FAILURE = "VIEW_HOME_SCREEN_PRODUCT_FAILURE"