import * as types from "../../Config/Packages/PackageLabTestActionType";

export const lifestylePackageRequest = (data,search) => {
  return {
    type: types.LIFESTYLE_PACKAGE_REQUEST,
    data:data,
    search:search
  };
};

export const lifestylePackageSuccess = (data) => {
  return {
    type: types.LIFESTYLE_PACKAGE_SUCCESS,
    data: data,
  };
};

export const lifestylePackageFailure = (data) => {
  return {
    type: types.LIFESTYLE_PACKAGE_FAILURE,
    data: data,
  };
};
