import { combineReducers } from "redux";
import { getQuestionSection1Reducer } from "./GetSectionQuestion/getQuestionSection1Reducer";
import { getQuestionSection2Reducer } from "./GetSectionQuestion/getQuestionSection2Reducer";
import { getQuestionSection3Reducer } from "./GetSectionQuestion/getQuestionSection3Reducer";
import { getQuestionSection4Reducer } from "./GetSectionQuestion/getQuestionSection4Reducer";
import { getQuestionSection5Reducer } from "./GetSectionQuestion/getQuestionSection5Reducer";
import { getQuestionSection6Reducer } from "./GetSectionQuestion/getQuestionSection6Reducer";
import { getQuestionSection7Reducer } from "./GetSectionQuestion/getQuestionSection7Reducer";
import { getQuestionSection8Reducer } from "./GetSectionQuestion/getQuestionSection8Reducer";
import { getQuestionSection9Reducer } from "./GetSectionQuestion/getQuestionSection9Reducer";
import { getQuestionSectionReducer } from "./GetSectionQuestion/getQuestionSectionReducer";

export default combineReducers({
    getQuestionSection: getQuestionSectionReducer,
    getQuestionSection1: getQuestionSection1Reducer,
    getQuestionSection2: getQuestionSection2Reducer,
    getQuestionSection3: getQuestionSection3Reducer,
    getQuestionSection4: getQuestionSection4Reducer,
    getQuestionSection5: getQuestionSection5Reducer,
    getQuestionSection6: getQuestionSection6Reducer,
    getQuestionSection7: getQuestionSection7Reducer,
    getQuestionSection8: getQuestionSection8Reducer,
    getQuestionSection9: getQuestionSection9Reducer,
})