import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../../Config/Banner/BannerActionType";
import * as actions from "../../Action/Banner/getUserBannerAction";
import NetworkService from "../../../network/Network/Banner/UserBannerNetwork";

export function* getUserBannerSaga(action) {
  try {
    let response = yield call(NetworkService.getUserBanner, action);
    yield put(actions.getUserBannerSuccess(response,action.category));
  } catch (err) {
    yield put(actions.getUserBannerFailure(err));
  }
}

export function* watchGetUserBannerSaga() {
  yield takeEvery(types.GET_USER_BANNER_REQUEST, getUserBannerSaga);
}
