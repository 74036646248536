import * as types from "../../Config/EcomUser/EcomUserActionType"

export const viewTopCategoriesRequest = () => {
    return {
        type: types.VIEW_TOP_CATEGORIES_REQUEST,
    }
}

export const  viewTopCategoriesSuccess = (data) => {
    return {
        type: types.VIEW_TOP_CATEGORIES_SUCCESS,
        data:data,
    }
}

export const  viewTopCategoriesFailure = (data) => {
    return {
        type: types.VIEW_TOP_CATEGORIES_FAILURE,
        data:data,
    }
}