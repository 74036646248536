import RequestService from "../../RequestService";
import { api } from "../../../helpers/ElasticSearch/ElasticSearchConstant";

class NetworkService {
    elasticSearch(action) {
        const endPoint = `${api.ElasticSearch}?search=${action.data}`;
        return RequestService.getRequest(endPoint)
    }

    elasticSearchResult(action) {
        if (action.item !== false) {

            const endPoint = `${api.ElasticSearchResult}/web?pageNoForPackage=1&pageNoForProduct=1&pageNoForTest=1&pageSizeForPackage=${action.pageSizeForPackage}&pageSizeForProduct=${action.pageSizeProduct}&pageSizeForTest=${action.pageSizeForTest}&productTypeEnum=${action.productTypeEnum}&uuid=${action.item}`;
            return RequestService.getRequest(endPoint)
        }
        else {
            const endPoint = `${api.ElasticSearchResult}/web?pageNoForPackage=1&pageNoForProduct=1&pageNoForTest=1&pageSizeForPackage=${action.pageSizeForPackage}&pageSizeForProduct=${action.pageSizeProduct}&pageSizeForTest=${action.pageSizeForTest}&search=${action.search}`;
            return RequestService.getRequest(endPoint)

        }
    }
}

export default new NetworkService();
