import { React, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import CancellationAndRefundPolicy from "../component/Organisms/Footer/CancellationAndRefundPolicy";
import Footer from "../component/Organisms/Footer/Footer";
import PrivacyPolicy from "../component/Organisms/Footer/PrivacyPolicy";
import TermsAndConditions from "../component/Organisms/Footer/TermsAndConditions";
import Locked from "../component/Organisms/HRA/Locked";
import Navbar from "../component/Organisms/Navbar/Navbar";
import Banner from "../component/Organisms/NetworkBanner/Banner";
import UpdateProfileEmailOTPScreen from "../component/Organisms/OTP/UpdateProfileOTPScreen/UpdateProfileEmailOTPModal";
import UpdateProfileNumberOTPScreen from "../component/Organisms/OTP/UpdateProfileOTPScreen/UpdateProfileNumberOTPModal";
import OurPlan from "../component/Organisms/PopularPlans/OurPlan";
import AboutUs from "../component/Pages/AboutUs/AboutUs";
import AvertisementBanner from "../component/Pages/Advertisement/AvertisementBanner";
import Ambulance from "../component/Pages/Ambulance/Ambulance";
import Career from "../component/Pages/Career/Career";
import Cart from "../component/Pages/Cart/Cart";
import Checkout from "../component/Pages/Cart/Checkout";
import CheckoutPlan from "../component/Pages/Cart/CheckoutPlan";
import ConfirmPayment from "../component/Pages/Cart/ConfirmPayment";
import FinalPaymentPage from "../component/Pages/Cart/FinalPaymentPage";
import FinalPaymentPlan from "../component/Pages/Cart/FinalPaymentPlan";
import LoadingPayment from "../component/Pages/Cart/LoadingPayment";
import SelectDateAndTime from "../component/Pages/Cart/SelectDateAndTime";
import SelectMember from "../component/Pages/Cart/SelectMember";
import ZeroPaymentConfirmation from "../component/Pages/Cart/ZeroPaymentConfirmation";
import CashlessOPD from "../component/Pages/CashlessOpd/CashlessOPD";
import ComingSoon from "../component/Pages/ComingSoon/ComingSoon";
import ContactUsPage from "../component/Pages/ContactUsPage/ContactUsPage";
import CorporateSignUp from "../component/Pages/CorporateSignUp/CorporateSignUp";
import EMRMPage from "../component/Pages/ERM/EMRMPage";
import MedicalRecordForm from "../component/Pages/ERM/MedicalRecordForm";
import MedicalRecordListing from "../component/Pages/ERM/MedicalRecordListing";
import SelectionScreen from "../component/Pages/ERM/SelectionScreen";
import HRA1a from "../component/Pages/HRA/HRAInfoScreens/HRA1a";
import HRA1b from "../component/Pages/HRA/HRAInfoScreens/HRA1b";
import HRA2a from "../component/Pages/HRA/HRAInfoScreens/HRA2a";
import HRA2b from "../component/Pages/HRA/HRAInfoScreens/HRA2b";
import HRA4a from "../component/Pages/HRA/HRAInfoScreens/HRA4a";
import HRA4b from "../component/Pages/HRA/HRAInfoScreens/HRA4b";
import HRA5a from "../component/Pages/HRA/HRAInfoScreens/HRA5a";
import HRA5b from "../component/Pages/HRA/HRAInfoScreens/HRA5b";
import HRA6a from "../component/Pages/HRA/HRAInfoScreens/HRA6a";
import HRA6b from "../component/Pages/HRA/HRAInfoScreens/HRA6b";
import HRA7a from "../component/Pages/HRA/HRAInfoScreens/HRA7a";
import HRA7b from "../component/Pages/HRA/HRAInfoScreens/HRA7b";
import HRA8a from "../component/Pages/HRA/HRAInfoScreens/HRA8a";
import HRA8b from "../component/Pages/HRA/HRAInfoScreens/HRA8b";
import HRA9a from "../component/Pages/HRA/HRAInfoScreens/HRA9a";
import HRA9b from "../component/Pages/HRA/HRAInfoScreens/HRA9b";
import HRALastScreen from "../component/Pages/HRA/HRAscreens/HRALastScreen";
import Section1 from "../component/Pages/HRA/Section1";
import Section2 from "../component/Pages/HRA/Section2";
import Section3 from "../component/Pages/HRA/Section3";
import Section4 from "../component/Pages/HRA/Section4";
import Section5 from "../component/Pages/HRA/Section5";
import Section6 from "../component/Pages/HRA/Section6";
import Section7 from "../component/Pages/HRA/Section7";
import Section8 from "../component/Pages/HRA/Section8";
import Section9 from "../component/Pages/HRA/Section9";
import HealthCheckupCatagory from "../component/Pages/HealthCheckups/HealthCheckupCatagory";
import HraLanding from "../component/Pages/HraLanding/HraLanding";
import LandingPage from "../component/Pages/LandingPage/LandingPage";
import ForgetPassword from "../component/Pages/Login/ForgetPassword/ForgetPassword";
import LinkExpired from "../component/Pages/Login/ForgetPassword/LinkExpired";
import SingleLogin from "../component/Pages/Login/SingleLogin";
import MentalWellness from "../component/Pages/MentalWellness/MentalWellness";
import AccountSetting from "../component/Pages/MyAccount/AccountSetting";
import AddFamilyMember from "../component/Pages/MyAccount/AddFamilyMember";
import MyAccount from "../component/Pages/MyAccount/MyAccount";
import MyAddressBook from "../component/Pages/MyAccount/MyAddressBook";
import MyPrescription from "../component/Pages/MyAccount/MyPrescription";
import MyReportHra from "../component/Pages/MyAccount/MyReportHra";
import MyReports from "../component/Pages/MyAccount/MyReports";
import NoDiagnosticReport from "../component/Pages/MyAccount/NoDiagnosticReport";
import NoHraReports from "../component/Pages/MyAccount/NoHraReports";
import NoPrescription from "../component/Pages/MyAccount/NoPrescription";
import Network from "../component/Pages/Network/Network";
import NetworkForm from "../component/Pages/Network/NetworkForm";
import PackageDetail from "../component/Pages/Packages/PackageDetail";
import TestDetails from "../component/Pages/Packages/TestDetails";
import PageNotFound from "../component/Pages/PageNotFound/PageNotFound";
import PaymentLink from "../component/Pages/PaymentLink/PaymentLink";
import PaymentRedirectMobile from "../component/Pages/PaymentRedirect";
import PharmacyPage from "../component/Pages/PharmacyPage/PharmacyPage";
import PlanPage from "../component/Pages/PlanPage/PlanPage";
import MyPackageBooking from "../component/Pages/PlansAndBookings/MyPackageBooking";
import MyProgram from "../component/Pages/PlansAndBookings/MyProgram";
import MySubscription from "../component/Pages/PlansAndBookings/MySubscription";
import ProductCategory from "../component/Pages/Products/ProductCategory";
import ProductInfo from "../component/Pages/Products/ProductInfo";
import ProductListing from "../component/Pages/Products/ProductListing";
import SingleRegister from "../component/Pages/Register/SingleRegister";
import Rental from "../component/Pages/Rental/Rental";
import SearchPage from "../component/Pages/SearchPage/SearchPage";
import SearchPagePackage from "../component/Pages/SearchPage/SearchPagePackage";
import HealthAdvisorPage from "../component/Pages/ServicesOffered/HealthAdvisorPage";
import Services from "../component/Pages/ServicesOffered/Services";
import SubscriptionPage from "../component/Pages/Subscription/SubscriptionPage";
import TalkToDoctor from "../component/Pages/TalkToDoctor/TalkToDoctor";
import UnderConstructions from "../component/Pages/UnderConstruction/UnderConstruction";
import ViewEvery from "../component/Pages/View/ViewEvery";
import { RouteLocation } from "./RouteLocation";
import ResetPassword from "../component/Pages/Login/ResetPassword/ResetPassword";
import ResetPasswordMobile from "../component/Pages/Login/ResetPassword/ResetPasswordMobile";
import { useLocation } from "react-router-dom";
import YuvaClinics from "../component/Pages/Clinic/YuvaClinics";

function Routing() {
  const [navbar, setNavbar] = useState(true);
  const [footer, setFooter] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === "/login" ||
      location.pathname === "/register" ||
      location.pathname === "/login/forgetPassword" ||
      location.pathname === "/corporate-signup" ||
      location.pathname === "/login/resetPasswordMobile" ||
      location.pathname === "/login/resetPassword"
    ) {
      setNavbar(false);
      setFooter(false);
    } else {
      setNavbar(true);
      setFooter(true);
    }
  }, [location.pathname]);

  return (
    <>
      {navbar && (
        <>
          <Navbar />
        </>
      )}
      <Routes>
        {/* <ScrollToTop /> */}

        <Route path="*" element={<PageNotFound />} />

        {/*LandingPage */}
        <Route path={RouteLocation.Landing.Landing} element={<LandingPage />} />

        <Route path="/PaymentRedirect" element={<PaymentRedirectMobile />} />

        {/*Account */}
        <Route path={RouteLocation.Account.MyAccount} element={<MyAccount />} />
        <Route
          path={RouteLocation.Account.MyPrescription}
          element={<MyPrescription />}
        />
        <Route path={RouteLocation.Account.MyReports} element={<MyReports />} />
        <Route
          path={RouteLocation.Account.MyReportHra}
          element={<MyReportHra />}
        />

        <Route
          path={RouteLocation.Account.NoPrescription}
          element={<NoPrescription />}
        />
        <Route
          path={RouteLocation.Account.NoDiagnosticReport}
          element={<NoDiagnosticReport />}
        />
        <Route
          path={RouteLocation.Account.NoHraReports}
          element={<NoHraReports />}
        />

        <Route
          path={RouteLocation.Account.AccountSetting}
          element={<AccountSetting />}
        />

        <Route
          path={RouteLocation.Account.AddFamilyMember}
          element={<AddFamilyMember />}
        />

        <Route
          path={RouteLocation.Account.AddressBook}
          element={<MyAddressBook />}
        />

        {/*User Login And Password Routes*/}
        <Route path={RouteLocation.User.Login} element={<SingleLogin />} />

        <Route
          path={RouteLocation.User.ForgetPassword}
          element={<ForgetPassword />}
        />

        <Route
          path={RouteLocation.User.LinkExpired}
          element={<LinkExpired />}
        />

        <Route
          path={RouteLocation.User.ResetPassword}
          element={<ResetPassword />}
        />

        <Route
          path={RouteLocation.User.ResetPasswordMobile}
          element={<ResetPasswordMobile />}
        />

        {/*Register Routes*/}
        <Route
          path={RouteLocation.User.Register}
          element={<SingleRegister />}
        />

        {/*Network Routes*/}
        <Route path={RouteLocation.Network.Network} element={<Banner />} />
        <Route
          path={RouteLocation.Network.searchNetwork}
          element={<Network />}
        />

        <Route path={RouteLocation.Network.JoinUs} element={<NetworkForm />} />

        <Route path={RouteLocation.Network.Form} element={<NetworkForm />} />

        {/*Cart Routes*/}
        <Route path={RouteLocation.Cart.Cart} element={<Cart />} />
        <Route path={RouteLocation.Cart.Checkout} element={<Checkout />} />
        <Route
          path={RouteLocation.Cart.ConfirmPayment}
          element={<ConfirmPayment />}
        />
        <Route
          path={RouteLocation.Cart.ConfirmedZeroPayment}
          element={<ZeroPaymentConfirmation />}
        />

        <Route
          path={RouteLocation.Cart.FinalPaymentPlan}
          element={<FinalPaymentPlan />}
        />
        <Route
          path={RouteLocation.Cart.CheckoutPlan}
          element={<CheckoutPlan />}
        />
        <Route
          path={RouteLocation.Cart.SelectDateAndTime}
          element={<SelectDateAndTime />}
        />

        <Route
          path={RouteLocation.Cart.SelectMember}
          element={<SelectMember />}
        />
        <Route
          path={RouteLocation.Cart.FinalPaymentPage}
          element={<FinalPaymentPage />}
        />
        <Route
          path={RouteLocation.Cart.FinalPaymentPlan}
          element={<FinalPaymentPlan />}
        />
        <Route
          path={RouteLocation.Cart.LoadingPayment}
          element={<LoadingPayment />}
        />

        {/* <Route
          path={RouteLocation.Cart.ConfirmPayment}
          element={<ConfirmPayment />}
        />
        <Route
          path={RouteLocation.Cart.FailedPayment}
          element={<FailedPayment />}
        /> */}
        {/* <Route
          path={RouteLocation.Cart.ZeroPaymentConfirmation}
          element={<ZeroPaymentConfirmation />}
        /> */}

        {/*Services Routes*/}

        <Route
          path={RouteLocation.Services.CashlessOPD}
          element={<CashlessOPD />}
        />
        <Route
          path={RouteLocation.Services.TalkToDoctor}
          element={<TalkToDoctor />}
        />
        <Route path={RouteLocation.Services.Rental} element={<Rental />} />

        {/*Company Routes*/}
        <Route path={RouteLocation.Company.About} element={<AboutUs />} />
        <Route path={RouteLocation.Company.Career} element={<Career />} />
        <Route
          path={RouteLocation.Company.TermsAndConditions}
          element={<TermsAndConditions />}
        />
        <Route
          path={RouteLocation.Company.PrivacyPolicy}
          element={<PrivacyPolicy />}
        />
        <Route
          path={RouteLocation.Company.CancellationAndRefund}
          element={<CancellationAndRefundPolicy />}
        />
        <Route
          path={RouteLocation.Company.ContactUs}
          element={<ContactUsPage />}
        />

        {/*Plan Routes*/}
        <Route path={RouteLocation.Plan.View} element={<PlanPage />} />

        {/*Health Checkup Routes*/}
        <Route
          path={RouteLocation.HealthCheckup.PackageDetail}
          element={<PackageDetail />}
        />

        <Route
          path={RouteLocation.HealthCheckup.HealthCheckupPackages}
          element={<HealthCheckupCatagory />}
        />
        <Route
          path={RouteLocation.SearchPage.SearchPagePackage}
          element={<SearchPagePackage />}
        />

        {/*Popular Diagnostic Routes*/}
        <Route
          path={RouteLocation.Diagnostic.PackageGetLab}
          element={<TestDetails />}
        />

        {/*Subscribe Routes*/}
        <Route
          path={RouteLocation.Subscribe.Subscribe}
          element={<SubscriptionPage />}
        />

        {/*HRA */}
        <Route path={RouteLocation.HRA.Login} element={<Locked />} />
        <Route path={RouteLocation.HRA.Logout} element={<HraLanding />} />
        {/* {token !== null ? ( */}
        <>
          HraLanding
          <Route
            path="/health-risk-assessment/section-1"
            element={<Section1 />}
          />
          <Route
            path="/health-risk-assessment/section-2"
            element={<Section2 />}
          />
          <Route
            path="/health-risk-assessment/section-3"
            element={<Section3 />}
          />
          <Route
            path="/health-risk-assessment/section-4"
            element={<Section4 />}
          />
          <Route
            path="/health-risk-assessment/section-5"
            element={<Section5 />}
          />
          <Route
            path="/health-risk-assessment/section-6"
            element={<Section6 />}
          />
          <Route
            path="/health-risk-assessment/section-7"
            element={<Section7 />}
          />
          <Route
            path="/health-risk-assessment/section-8"
            element={<Section8 />}
          />
          <Route
            path="/health-risk-assessment/section-9"
            element={<Section9 />}
          />
          <Route
            path="/health-risk-assessment/section-1a"
            element={<HRA1a />}
          />
          <Route
            path="/health-risk-assessment/section-1b"
            element={<HRA1b />}
          />
          <Route
            path="/health-risk-assessment/section-2a"
            element={<HRA2a />}
          />
          <Route
            path="/health-risk-assessment/section-2b"
            element={<HRA2b />}
          />
          <Route
            path="/health-risk-assessment/section-4a"
            element={<HRA4a />}
          />
          <Route
            path="/health-risk-assessment/section-4b"
            element={<HRA4b />}
          />
          <Route
            path="/health-risk-assessment/section-5a"
            element={<HRA5a />}
          />
          <Route
            path="/health-risk-assessment/section-5b"
            element={<HRA5b />}
          />
          <Route
            path="/health-risk-assessment/section-6a"
            element={<HRA6a />}
          />
          <Route
            path="/health-risk-assessment/section-6b"
            element={<HRA6b />}
          />
          <Route
            path="/health-risk-assessment/section-7a"
            element={<HRA7a />}
          />
          <Route
            path="/health-risk-assessment/section-7b"
            element={<HRA7b />}
          />
          <Route
            path="/health-risk-assessment/section-8a"
            element={<HRA8a />}
          />
          <Route
            path="/health-risk-assessment/section-8b"
            element={<HRA8b />}
          />
          <Route
            path="/health-risk-assessment/section-9a"
            element={<HRA9a />}
          />
          <Route
            path="/health-risk-assessment/section-9b"
            element={<HRA9b />}
          />
          <Route
            path="/health-risk-assessment/report-generation"
            element={<HRALastScreen />}
          />
        </>

        {/*miscellaneous */}
        <Route path={RouteLocation.Misc.ComingSoon} element={<ComingSoon />} />
        <Route
          path={RouteLocation.Misc.Construction}
          element={<UnderConstructions />}
        />

        {/*miscellaneous */}

        <Route
          path={RouteLocation.PlansAndBookings.MyPackageBooking}
          element={<MyPackageBooking />}
        />
        <Route
          path={RouteLocation.PlansAndBookings.MySubscription}
          element={<MySubscription />}
        />
        <Route
          path={RouteLocation.PlansAndBookings.MyProgram}
          element={<MyProgram />}
        />

        {/* marketing page */}
        <Route path="/marketing" element={<AvertisementBanner />} />
        <Route
          path={RouteLocation.ERM.MedicalRecordForm}
          element={<MedicalRecordForm />}
        />
        <Route
          path={RouteLocation.ERM.MedicalRecordListing}
          element={<MedicalRecordListing />}
        />

        {/* EMRM PAGE */}
        <Route path={RouteLocation.ERM.EMRMPage} element={<EMRMPage />} />
        <Route
          path={RouteLocation.ERM.SelectionPage}
          element={<SelectionScreen />}
        />
        <Route
          path={RouteLocation.SearchPage.SearchPage}
          element={<SearchPage />}
        />
        <Route
          path={RouteLocation.MentalWellness.MentalWellness}
          element={<MentalWellness />}
        />
        {/* marketing page */}
        <Route path="/marketing" element={<AvertisementBanner />} />

        {/* Corporate SignUp */}
        <Route path="/corporate-signup" element={<CorporateSignUp />} />

        {/* update email otp */}
        <Route
          path={RouteLocation.Account.UpdateEmailOTP}
          element={<UpdateProfileEmailOTPScreen />}
        ></Route>

        {/* update Number otp */}
        <Route
          path={RouteLocation.Account.UpdateNumberOTP}
          element={<UpdateProfileNumberOTPScreen />}
        ></Route>

        {/* Services Landing page */}
        <Route
          path={RouteLocation.Landing.Services}
          element={<Services />}
        ></Route>
        <Route
          path={RouteLocation.Landing.HealthAdviser}
          element={<HealthAdvisorPage />}
        ></Route>
        <Route
          path={RouteLocation.Plan.OurPlanSection}
          element={<OurPlan />}
        ></Route>
        <Route path="/payment-link" element={<PaymentLink />} />

        <Route path="/view-test" element={<ViewEvery />} />
        <Route
          path={RouteLocation.Landing.PharmacyPage}
          element={<PharmacyPage />}
        />

        <Route path={RouteLocation.Landing.ambulance} element={<Ambulance />} />
        <Route path={RouteLocation.Landing.YuvaClinicPage} element={<YuvaClinics />} />

        
        {/* Products */}
        <Route
          path={RouteLocation.Products.ProductListing}
          element={<ProductListing />}
        />
        <Route
          path={RouteLocation.Products.ProductInfo}
          element={<ProductInfo />}
        />
        <Route
          path={RouteLocation.Products.ProductCategory}
          element={<ProductCategory />}
        />
        <Route
          path={RouteLocation.Products.ProductViewAllListing}
          element={<ProductCategory />}
        />
      </Routes>
      {footer && (
        <>
          <Footer />
        </>
      )}
    </>
  );
}
export default Routing;
