import * as types from "../../Config/Landing/landingActionType";

export const newsletterSignupRequest = (data) => {
  return {
    type: types.NEWSLETTER_SIGNUP_REQUEST,
    data: data,
  };
};

export const newsletterSignupSuccess = (data) => {
  return {
    type: types.NEWSLETTER_SIGNUP_SUCCESS,
    data: data,
  };
};

export const newsletterSignupFailure = (data) => {
  return {
    type: types.NEWSLETTER_SIGNUP_FAILURE,
    data: data,
  };
};
