import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/EcomUser/EcomUserActionType";
import * as actions from "../../Action/EcomUser/viewTopCategoriesAction";
import NetworkService from "../../../network/Network/EcomUser/EcomUserNetwork";

export function* viewTopCategoriesSaga(action) {
  try {
    let response = yield call(NetworkService.viewTopCategories, action);
    yield put(actions.viewTopCategoriesSuccess(response));
  } catch (err) {
    yield put(actions.viewTopCategoriesFailure(err));
  }
}

export function* watchViewTopCategoriesSaga() {
  yield takeLatest(types.VIEW_TOP_CATEGORIES_REQUEST, viewTopCategoriesSaga);
}