import * as types from "../../Config/Order/OrderActionType";

export const getAllOrderHistoryRequest = (data) => {
  return {
    type: types.GET_ALL_ORDER_HISTORY_REQUEST,
    data:data,
  };
};

export const getAllOrderHistorySuccess = (data) => {
  return {
    type: types.GET_ALL_ORDER_HISTORY_SUCCESS,
    data: data,
  };
};

export const getAllOrderHistoryFailure = (data) => {
  return {
    type: types.GET_ALL_ORDER_HISTORY_FAILURE,
    data: data,
  };
};
