import * as types from "../../Config/MentalWellness/OnMoodNineActionType"

export const getOnMoodNineRequest = () => {
    return {
        type: types.GET_ON_MOOD_NINE_REQUEST,
    }
}

export const getOnMoodNineSuccess = (data) => {
    return {
        type: types.GET_ON_MOOD_NINE_SUCCESS,
        data:data,
    }
}

export const getOnMoodNineFailure = (data) => {
    return {
        type: types.GET_ON_MOOD_NINE_FAILURE,
        data:data,
    }
}