import React from "react";
import Cart from "../../../assets/product/Cart.png";
import downArrow from "../../../assets/product/Vector (10).png";
import upArrow from "../../../assets/product/upArrow.png";
import "./ProductShortDescription.css";
import Swal from "sweetalert2";

function ProductShortDescription({
  data,
  handleDecrement,
  handleIncrement,
  handleSizeClick,
  handleAddToCart,
  selectedSize,
  quantity,
  isSubmitting,
  itemDtoList,
}) {
  const handleAddProduct = () => {
    Swal.fire({
      title: "Coming Soon",
      icon: "info",
      timer: 1500,
    });
  };

  return (
    <div className="productDesc">
      <h1 className="productDescTitle">{data?.name}</h1>
      <div className="productDescBody">
        <div className="productDescPrice">
          <div className="productDescFinalPrice">
            ₹{" "}
            {data?.productPriceResponseDtoForUserList[selectedSize].finalPrice}
          </div>
          <div className="productDescPriceRight">
            {data?.productPriceResponseDtoForUserList[selectedSize]
              .discountPercentage > 0 && (
              <>
                <div className="productCardDiscount">
                  {
                    data?.productPriceResponseDtoForUserList[selectedSize]
                      .discountPercentage
                  }
                  % off
                </div>
                <div className="productCardOriginalPrice">
                  ₹{" "}
                  {
                    data?.productPriceResponseDtoForUserList[selectedSize]
                      .originalPrice
                  }
                </div>
              </>
            )}
          </div>
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: data?.shortDescription }}
          className="productDescList"
        />

        <div className="productDescSize">
          <div className="productDescSizeTitle">Size / Weight</div>
          {data?.productPriceResponseDtoForUserList.map((size, index) => (
            <button
              key={size}
              className={`size-button ${
                selectedSize === index ? "selected-size" : ""
              }`}
              onClick={() => handleSizeClick(index)}
            >
              {size.unit} {size.productUnit}
            </button>
          ))}
        </div>
        <div className="productDescFoot">
          {itemDtoList.length > 0 &&
          itemDtoList.filter(
            (x) =>
              x.productPriceId?.toString() ===
              data?.productPriceResponseDtoForUserList[
                selectedSize
              ].priceId?.toString()
          ).length > 0 ? (
            <></>
          ) : (
            <div className="quantity-counter">
              <p className="quantity">{quantity}</p>
              <div className="quantity-arrows">
                <img src={upArrow} onClick={handleIncrement} alt="up arrow" />
                <img
                  src={downArrow}
                  onClick={handleDecrement}
                  alt="down arrow"
                />
              </div>
            </div>
          )}

          {itemDtoList.length > 0 &&
          itemDtoList.filter(
            (x) =>
              x.productPriceId?.toString() ===
              data?.productPriceResponseDtoForUserList[
                selectedSize
              ].priceId?.toString()
          ).length > 0 ? (
            <button className="productDescriptionButtonAdded">Added</button>
          ) : (
            <button
              className="productDescriptionButton"
              disabled={isSubmitting}
              onClick={handleAddProduct}
              // onClick={handleAddToCart}
            >
              <img src={Cart} alt="cart icon" />
              Add To Cart
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductShortDescription;
