export const User = {
  Login: "/login",
  DiagnosticLogin: "/login/diagnosticLogin",
  HospitalLogin: "/login/hospitalLogin",
  InsurerLogin: "/login/insurerLogin",
  CorporateLogin: "/login/corporateLogin",
  ForgetPassword: "/login/forgetPassword",
  ForgetPasswordNew: "/login/forgetPasswordNew",
  ResetPassword: "/login/resetPassword",
  ResetPasswordMobile: "/login/resetPasswordMobile",
  Google: "/google",
  LinkExpired: "/linkExpired",
  Register: "/register",
  Connection: "/ConnectionBetweenUs",
  Network: "/NetworkConnectionPage",
  RegisterUser: "/registerUser",
  ResetPasswordNew: "/resetPasswordNew",
  
};
