import RequestService from "../../RequestService";
import { api } from "../../../helpers/Address/AddressConstants";

class NetworkService {
  viewAllAddress(action) {
    const endPoint = `${api.viewAllAddress}`;
    return RequestService.getRequest(endPoint)
  }
}

export default new NetworkService();
