import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Packages/PackageLabTestActionType";
import * as actions from "../../Action/Packages/PackageGetTestAction";
import NetworkService from "../../../network/Network/Packages/PackageLabTestNetwork";

export function* packageGetTestSaga(action) {
  try {
    let response = yield call(NetworkService.packageGetLab, action);
    yield put(actions.packageGetTestSuccess(response));
  } catch (err) {
    yield put(actions.packageGetTestFailure(err));
  }
}

export function* watchpackageGetTestSaga() {
  yield takeLatest(types.PACKAGE_GET_TEST_REQUEST, packageGetTestSaga);
}
