import { combineReducers } from "redux";
import { getSectionAnswersReducer } from "./HRAAnswers/getSectionAnswersReducer";
import { section1AnswersReducer } from "./HRAAnswers/section1AnswersReducer";
import { section2AnswersReducer } from "./HRAAnswers/section2AnswersReducer";
import { section3AnswersReducer } from "./HRAAnswers/section3AnswersReducer";
import { section4AnswersReducer } from "./HRAAnswers/section4AnswersReducer";
import { section5AnswersReducer } from "./HRAAnswers/section5AnswersReducer";
import { section6AnswersReducer } from "./HRAAnswers/section6AnswersReducer";
import { section7AnswersReducer } from "./HRAAnswers/section7AnswersReducer";
import { section8AnswersReducer } from "./HRAAnswers/section8AnswersReducer";
import { section9AnswersReducer } from "./HRAAnswers/section9AnswersReducer";

export default combineReducers({
    getSectionAnswers: getSectionAnswersReducer,
    section1Answers: section1AnswersReducer,
    section2Answers: section2AnswersReducer,
    section3Answers: section3AnswersReducer,
    section4Answers: section4AnswersReducer,
    section5Answers: section5AnswersReducer,
    section6Answers: section6AnswersReducer,
    section7Answers: section7AnswersReducer,
    section8Answers: section8AnswersReducer,
    section9Answers: section9AnswersReducer,
})