import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const GetQuestionSection7Request = (data) => {
    return {
        type: types.GET_QUESTION_SECTION7_REQUEST,
        data:data
    }
}

export const  GetQuestionSection7Success = (data) => {
    return {
        type: types.GET_QUESTION_SECTION7_SUCCESS,
        data:data,
    }
}

export const  GetQuestionSection7Failure = (data) => {
    return {
        type: types.GET_QUESTION_SECTION7_FAILURE,
        data:data,
    }
}