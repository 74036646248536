import { ErrorMessage, Field, Form, Formik } from "formik";
import { default as React, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Email from "../../../../assets/Login/emailLogoLogin.png";
import { userForgetPasswordRequest } from "../../../../redux/Action/User/forgetPasswordAction";
import "./ForgotPassword.css";
import logo from "../../../../assets/common/logoPrimaryNavbar.png";

function UserForgotPasswordForm({ setChildData, setStorageCheck }) {
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const forgetPasswordSchema = Yup.object().shape({
    email_or_phone: Yup.string()
      .required("Email / Phone is required")
      .test("email_or_phone", "Email / Phone is invalid", (value) => {
        return validateEmail(value) || validatePhone(parseInt(value ?? "0"));
      }),
  });

  const validateEmail = (email) => {
    return Yup.string().email().isValidSync(email);
  };

  const validatePhone = (phone) => {
    return Yup.number()
      .integer()
      .positive()
      .test((phone) => {
        return phone && phone.toString().length === 10 ? true : false;
      })
      .isValidSync(phone);
  };

  const handleSubmit = (values) => {
    setIsSubmitting(true);
    dispatch(userForgetPasswordRequest(values));

    setChildData(values.email_or_phone);
    let check = values.email_or_phone;
    if (isNaN(check) === false) {
      setStorageCheck(check);
    }
    Swal.fire({
      title: "Please wait ....",
      text: "Generating Link/OTP for you...",
      icon: "info",
      showCancelButton: false,
      showConfirmButton: false,
    });
  };

  return (
    <>
      <div className="signupArea">
        <div className="LogoAreaLOGIN_SIGNUP">
          <img src={logo} alt="Logo" />
        </div>
        <p className="signupAreaFirstHeading">Forgot Password</p>
        <p className="signupAreaSecondHeading">
          Retrieving your password is simple
        </p>
        <div className="signupForm">
          <Formik
            initialValues={{ email_or_phone: "" }}
            validationSchema={forgetPasswordSchema}
            onSubmit={handleSubmit}
          >
            {() => {
              return (
                <Form className="SingleRegisterForm">
                  <div className="signUpBox">
                    <Field
                      type="text"
                      name="email_or_phone"
                      placeholder="Mobile Number Or Email"
                      className="signupInputField"
                    />
                    <ErrorMessage
                      className="errorMessage"
                      name="email_or_phone"
                      component="div"
                    />
                  </div>
                  <button
                    type="submit"
                    className="signupInputFieldButton"
                    disabled={isSubmitting}
                  >
                    Continue
                  </button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default UserForgotPasswordForm;
