import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { PageKeys } from "../../../Utils/testPackageFlag";
import { getTestPackageListingRequest } from "../../../redux/Action/Packages/GetTestPackageLIstingAction";
import Title from "../../Atoms/Title/Title";
import FilterView from "../../Organisms/Filters/FilterView";
import PackageHero from "../../Organisms/Hero/PackageHero";
import Listing from "../../Organisms/Listing/Listing";
import HealthCheckupCatagory from "../HealthCheckups/HealthCheckupCatagory";
import "./ViewEvery.css";
import { Helmet } from "react-helmet";

function ViewEvery() {
  const location = useLocation();
  const minDistance = 0;
  const dispatch = useDispatch();

  const viewEveryStorage = JSON.parse(localStorage.getItem("viewEvery"));

  const [mapData, setMapData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [range, setRange] = useState([
    viewEveryStorage?.minPrice,
    viewEveryStorage?.maxPrice,
  ]);
  const [maxPrice, setMaxPrice] = useState(viewEveryStorage?.maxPrice);
  const [minPrice, setMinPrice] = useState(viewEveryStorage?.minPrice);
  const [debounceMaxPrice, setDebounceMaxPrice] = useState(
    viewEveryStorage?.maxPrice
  );
  const [debounceMinPrice, setDebounceMinPrice] = useState(
    viewEveryStorage?.minPrice
  );
  const [allChecked, setAllChecked] = useState(viewEveryStorage?.allChecked);
  const [radioItem, setRadioItem] = useState(
    viewEveryStorage?.radioItem || "NO_RANGE"
  );
  const [checkboxTest, setCheckboxTest] = useState(false);
  const [checkboxPackage, setCheckboxPackage] = useState(false);
  const [radiology, setRadiology] = useState(viewEveryStorage?.radiology);

  const [showRadiology, setShowRadiology] = useState(
    viewEveryStorage?.allChecked?.includes("Test")
  );
  const [totalPages, setTotalPages] = useState();

  const getTestPackageListing = useSelector(
    (state) => state.package.getTestPackageListing
  );

  const receivedData = location.state ? location.state.data : null;

  useEffect(() => {
    if (receivedData === true) {
      setTimeout(() => {
        const element = document.querySelector("#HealthCheckupCatagory");

        element.scrollIntoView({ behavior: "smooth" });
        element.focus();
      }, 500);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [receivedData]);

  useEffect(() => {
    if (
      getTestPackageListing.data[PageKeys.TEST_AND_PACKAGE]?.data.status === 200
    ) {
      setMapData([
        ...mapData,
        ...getTestPackageListing.data[PageKeys.TEST_AND_PACKAGE]?.data?.data
          ?.data?.testPackageResponseDtoList,
      ]);

      setTotalPages(
        getTestPackageListing.data[PageKeys.TEST_AND_PACKAGE]?.data?.data?.data
          ?.totalPages
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTestPackageListing.data]);

  const priceFilterSlider = [
    {
      value: 0,
    },
    {
      value: 6000,
    },
    {
      value: 10000,
    },
  ];

  const handleSlideFilter = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setRange([Math.min(newValue[0], range[1] - minDistance), range[1]]);
    } else {
      setRange([range[0], Math.max(newValue[1], range[0] + minDistance)]);
    }
    setMinPrice(newValue[0]);
    setMaxPrice(newValue[1]);
    debouncedOnChanged(newValue[0], newValue[1]);
    setPageNo(1);
  };

  const debouncedOnChanged = useDebouncedCallback((value1, value2) => {
    setDebounceMinPrice(value1);
    setDebounceMaxPrice(value2);
    setMapData([]);
    setPageNo(1);
  }, 1000);

  const handleCheckbox = (e) => {
    if (checkboxTest && e.target.value === "Test") {
      setAllChecked(allChecked.filter((item) => item !== e.target.value));
      setCheckboxTest(false);
    } else if (checkboxPackage && e.target.value === "Packages") {
      setAllChecked(allChecked.filter((item) => item !== e.target.value));
      setCheckboxPackage(false);
    }
    if (e.target.checked) {
      if (e.target.value === "Packages") {
        setCheckboxPackage(true);
      } else {
        setCheckboxTest(true);
        setShowRadiology(true);
      }
      setAllChecked([...allChecked, e.target.value]);
    } else {
      if (e.target.value === "Packages") {
        setCheckboxPackage(false);
      } else {
        setCheckboxTest(false);
        setShowRadiology(false);
      }
      setAllChecked(allChecked.filter((item) => item !== e.target.value));
    }
    setPageNo(1);
    setMapData([]);
  };

  const handleRadio = (value) => {
    setRadioItem(value);
    setMapData([]);
  };

  const handleClearAll = () => {
    localStorage.setItem(
      "viewEvery",
      JSON.stringify({
        radioItem: "NO_RANGE",
        minPrice: 0,
        maxPrice: 10000,
        allChecked: [],
        radiology: false,
      })
    );
    setRadioItem("NO_RANGE");
    setRadiology(false);
    setRange([0, 10000]);
    setMinPrice(0);
    setMaxPrice(10000);
    setAllChecked([]);
    setMapData([]);
  };

  const handleRadiology = () => {
    setRadiology(!radiology);
    setMapData([]);
    setPageNo(1);
  };
  useEffect(() => {
    localStorage.setItem(
      "viewEvery",
      JSON.stringify({
        radioItem: radioItem,
        minPrice: minPrice,
        maxPrice: maxPrice,
        allChecked: allChecked,
        radiology: radiology,
      })
    );
    dispatch(
      getTestPackageListingRequest(
        pageNo,
        5,
        radiology,
        radioItem,
        minPrice,
        maxPrice,
        allChecked,
        PageKeys.TEST_AND_PACKAGE
      )
    );
  }, [
    pageNo,
    radioItem,
    debounceMaxPrice,
    debounceMinPrice,
    allChecked,
    radiology,
    dispatch,
    maxPrice,
    minPrice,
  ]);

  return (
    <div>
      <Helmet>
        <title>Best Diagnostic Centre| YUVA| </title>
        <meta
          name="description"
          content="Discover the best diagnostic centre for precise and reliable health assessments. Trust our top-rated diagnostic centre for accurate results and expert care."
        />
        <meta name="keywords" content="Best Diagnostic Centre " />
      </Helmet>
      <PackageHero />
      <div className="everyMain">
        <div className="everyMainRight">
          <div className="everyMainRightHead">
            <div className="everyMainTitle">Browse test/package</div>
            <FilterView
              className="viewEveryBodyRightFilter"
              handleCheckbox={handleCheckbox}
              viewEveryStorage={viewEveryStorage}
              handleRadiology={handleRadiology}
              range={range}
              handleSlideFilter={handleSlideFilter}
              handleRadio={handleRadio}
              priceFilterSlider={priceFilterSlider}
              handleClearAll={handleClearAll}
              showRadiology={showRadiology}
              radioItem={radioItem}
            />
          </div>
          <Listing
            totalPages={totalPages}
            mapData={mapData}
            testAndPackage={"testAndPackage"}
            setPageNo={setPageNo}
            pageNo={pageNo}
          />
          <div id="HealthCheckupCatagory">
            <HealthCheckupCatagory />
          </div>
        </div>
      </div>
      {/* <TypeCBanner position={1} screenType={"DIAGNOSTIC"} mapData={bannerMapData}/> */}
    </div>
  );
}

export default ViewEvery;
