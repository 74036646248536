import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Packages/PackageLabTestActionType";
import * as actions from "../../Action/Packages/PackageLabTestAction";
import NetworkService from "../../../network/Network/Packages/PackageLabTestNetwork";

export function* packageLabTestSaga(action) {
  try {
    let response = yield call(NetworkService.packageLabTest, action);
    yield put(actions.packageLabTestSuccess(response));
  } catch (err) {
    yield put(actions.packageLabTestFailure(err));
  }
}

export function* watchpackageLabTestSaga() {
  yield takeLatest(types.PACKAGE_LAB_TEST_REQUEST, packageLabTestSaga);
}
