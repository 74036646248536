import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { Input } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { professionalServiceNetworkFormRequest } from "../../../redux/Action/Network/professionalServicesNetworkFormAction";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import "./Form.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ProfessionalServicesForm() {
  const [filePerSession, setFilePerSession] = useState();
  const [fileClinicPhoto, setFileClinicPhoto] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileHospPhoto, setFileHospPhoto] = useState();
  const [check, setCheck] = useState(false);
  const [feePerSessionError, setFeePerSessionError] = useState(false);
  const [userPhotoError, setUserPhotoError] = useState(false);
  const [clinicPhotoError, setClinicPhotoError] = useState(false);

  const ProfessionalResponse = useSelector(
    (state) => state.network.professionalServicesNetworkForm
  );

  const dispatch = useDispatch();

  const loginSchema = Yup.object().shape({
    Email: Yup.string()
      .matches(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
        "Enter a Valid E-mail Address"
      )
      .required("Email is required"),
    Services: Yup.string().required("Required Field"),
    Name: Yup.string().trim().required("Required Field"),
    Address: Yup.string().trim().required("Required Field"),
    Location: Yup.string().trim().required("Required Field"),
    City: Yup.string().required("Required Field"),
    Pincode: Yup.string()
      .matches(/^[0-9\b]+$/, "Enter valid pincode")
      .min(6, "Must be exactly 6 digits")
      .max(6, "Must be exactly 6 digits")
      .required("Required Field"),
    ContactNumber: Yup.string()
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits")
      .required("Required Field"),
  });

  const Servicesdata = [
    { name: "Online", id: 1 },

    { name: "Offline", id: 2 },

    { name: "Home Visit", id: 3 },
  ];

  useEffect(() => {
    if (ProfessionalResponse?.error?.data?.response?.status === 400) {
      Swal.fire({
        title: ProfessionalResponse.error?.data.response?.data.errorMessage,
        icon: "error",
      }).then(() => {
        setIsSubmitting(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ProfessionalResponse?.error?.data?.response?.status]);

  const viewCity = useSelector((state) => state.city.viewAllCity);
  const [servicemode, setService] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setService(typeof value === "string" ? value.split(",") : value);
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    setCheck(true);

    let serviceUuidArray = [];
    servicemode.map((x) => {
      Servicesdata.map((item) => {
        if (item.name === x) {
          serviceUuidArray.push(item.name);
        }
        return item;
      });
      return x;
    });

    const ProfessionalServiceData = {
      address: values.Address,
      cityId: values.City,
      contactNumber: values.ContactNumber,
      email: values.Email,
      location: values.Location,
      modeOfSession: serviceUuidArray.toString(),
      name: values.Name,

      pinCode: values.Pincode,
      serviceLocation: "1",
      services: values.Services,
    };

    const newFormData = new FormData();
    newFormData.append(
      "professionalServicePartnerDto",
      JSON.stringify(ProfessionalServiceData)
    );

    if (filePerSession && fileHospPhoto && fileClinicPhoto) {
      newFormData.append("feePerSession", filePerSession);
      newFormData.append("clinicPhoto", fileClinicPhoto);
      newFormData.append("uploadPhotograph", fileHospPhoto);

      try {
        await dispatch(professionalServiceNetworkFormRequest(newFormData));
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (ProfessionalResponse.data?.data?.status === 200) {
      Swal.fire({
        title: ProfessionalResponse?.data?.data?.data.message,
        icon: "success",
      }).then(() => {
        setIsSubmitting(false);
        window.location.reload();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ProfessionalResponse.data?.data?.status]);

  function waitForLoad(id, callback) {
    var timer = setInterval(function () {
      if (
        document.getElementById("fileUploadInput") &&
        document.getElementsByClassName("file-upload-select")[0]
      ) {
        clearInterval(timer);
        callback();
      }
    }, 200);
  }
  waitForLoad("subm", function () {
    let fileInput = document.getElementById("fileUploadInput");
    let fileInput2 = document.getElementById("fileUploadInput1");
    let fileInput3 = document.getElementById("fileUploadInput3");
    fileInput.onchange = function () {
      let filename = fileInput.files[0].name;
      let selectName = document.getElementsByClassName("file-select-name")[0];
      selectName.innerText = filename;
    };
    fileInput2.onchange = function () {
      let filename = fileInput2.files[0].name;
      let selectName = document.getElementsByClassName("file-select-name2")[0];
      selectName.innerText = filename;
    };
    fileInput3.onchange = function () {
      let filename = fileInput3.files[0].name;
      let selectName = document.getElementsByClassName("file-select-name3")[0];
      selectName.innerText = filename;
    };
  });

  const handleFilePerSessionChange = (event) => {
    if (
      event.target.files[0].type === "application/pdf" ||
      event.target.files[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      event.target.files[0].type === "application/vnd.ms-excel"
    ) {
      setFilePerSession(event.target.files[0]);
      setFeePerSessionError(false);
    } else {
      setFeePerSessionError(true);
    }
  };
  const handleFileClinicPhotoChange = (event) => {
    if (
      event.target.files[0].type === "image/png" ||
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "image/jpg"
    ) {
      setFileClinicPhoto(event.target.files[0]);
      setClinicPhotoError(false);
    } else {
      setClinicPhotoError(true);
    }
  };
  const handleFileHospitalPhotoChange = (event) => {
    if (
      event.target.files[0].type === "image/png" ||
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "image/jpg"
    ) {
      setFileHospPhoto(event.target.files[0]);
      setUserPhotoError(false);
    } else {
      setUserPhotoError(true);
    }
  };
  if (ProfessionalResponse.isLoading) {
    return <YuvaLoader />;
  }

  return (
    <div>
      {" "}
      <Formik
        initialValues={{
          Email: "",
          Services: "",
          Name: "",
          Address: "",
          Location: "",
          City: "",
          Pincode: "",
          ContactNumber: "",
          // State: "",
          ModeOfSession: "",
        }}
        enableReinitialize={true}
        validationSchema={loginSchema}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <Form>
            <div className="NetworkPageFormInput">
              <label>
                <Field
                  placeholder="Email"
                  name="Email"
                  type="text"
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="Email"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  name="Services"
                  as="select"
                  placeholder="Select Services"
                  className="NetworkPageFormInputField"
                >
                  <option value="" label="Services" disabled>
                    Select Services{" "}
                  </option>
                  <option
                    value="Yoga Instructor"
                    label="Yoga Instructor"
                  ></option>
                  <option
                    value="Zumba Instructor"
                    label="Zumba Instructor"
                  ></option>
                  <option
                    value="Fitness Instructor"
                    label="Fitness Instructor"
                  ></option>
                  <option value="Dietician" label="Dietician"></option>
                  <option
                    value="Physiotheraphy"
                    label="Physiotheraphy"
                  ></option>
                  <option value="Other" label="Other"></option>
                </Field>
                <ErrorMessage
                  name="Services"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder=" Name of the Clinic"
                  name="Name"
                  type="text"
                  className="NetworkPageFormInputField"
                  // onKeyDown={(event) => preventnumberinput(event)}
                ></Field>
                <ErrorMessage
                  name="Name"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Address"
                  name="Address"
                  type="text"
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="Address"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Location"
                  name="Location"
                  type="text"
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="Location"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  name="City"
                  as="select"
                  placeholder="Select City"
                  className="NetworkPageFormInputField"
                >
                  <option value="" disabled selected>
                    Select City
                  </option>
                  {viewCity?.data?.data?.data?.data.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="City"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Pincode"
                  name="Pincode"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="Pincode"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Contact Number"
                  name="ContactNumber"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="ContactNumber"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              {/* <label>
                <Field
                  name="State"
                  as="select"
                  placeholder="Service Location"
                  className="NetworkPageFormInputField"
                >
                  <option value="" label="Service Location" disabled>
                    Select Service Location{" "}
                  </option>
                  <option value="2" label="Karnataka"></option>
                  <option value="1" label="New Delhi"></option>
                </Field>
                <ErrorMessage
                  name="State"
                  className="FormErrorMesage"
                  component="div"
                />
              </label> */}

              <label>
                <div class="file-upload">
                  <div class="file-upload-select">
                    <div class="file-select-button">Fees Per Session</div>
                    <div class="file-select-name">
                      Supported format PDF and Excel
                    </div>
                    <Input
                      type="file"
                      name="FeePerSession"
                      accept="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      id="fileUploadInput"
                      className="NetworkPageFormInputField"
                      onChange={(event) => handleFilePerSessionChange(event)}
                    />
                  </div>
                  {feePerSessionError && (
                    <div className="errorFile">
                      Please select only .pdf or .xlsx or .xlx extensions
                    </div>
                  )}
                </div>

                {filePerSession?.size / 1024 > 10240 && (
                  <div className="FileSizedisable">
                    {" "}
                    File Size should be less than 10mb
                  </div>
                )}
                {filePerSession == null && check && (
                  <div className="FileSizedisable"> Select the file</div>
                )}
              </label>

              <label>
                <div class="file-upload">
                  <div class="file-upload-select">
                    <div class="file-select-button">
                      Please upload your Photograph
                    </div>
                    <div class="file-select-name2">
                      Supported format .jpg and .png
                    </div>
                    <Input
                      type="file"
                      name="UserPhoto"
                      accept="image/png, image/jpeg, image/jpg"
                      id="fileUploadInput1"
                      className="NetworkPageFormInputField"
                      onChange={(event) => handleFileHospitalPhotoChange(event)}
                    />
                  </div>
                  {userPhotoError && (
                    <div className="errorFile">
                      Please select only .jpg or .png or .jpeg extensions
                    </div>
                  )}
                </div>

                {fileHospPhoto?.size / 1024 > 10240 && (
                  <div className="FileSizedisable">
                    {" "}
                    File Size should be less than 10mb
                  </div>
                )}
                {fileHospPhoto == null && check && (
                  <div className="FileSizedisable"> Select the file</div>
                )}
              </label>

              <label>
                <div class="file-upload">
                  <div class="file-upload-select">
                    <div class="file-select-button">
                      Please upload your clinic/studio Photo
                    </div>
                    <div class="file-select-name3">
                      Supported format .jpg and .png
                    </div>
                    <Input
                      type="file"
                      name="ClinicPhoto"
                      accept="image/png, image/jpeg, image/jpg"
                      id="fileUploadInput3"
                      className="NetworkPageFormInputField"
                      onChange={(event) => handleFileClinicPhotoChange(event)}
                    />
                  </div>
                  {clinicPhotoError && (
                    <div className="errorFile">
                      Please select only .jpg or .png or .jpeg extensions
                    </div>
                  )}
                </div>

                {fileClinicPhoto?.size / 1024 > 10240 && (
                  <div className="FileSizedisable">
                    {" "}
                    File Size should be less than 10mb
                  </div>
                )}
                {fileClinicPhoto == null && check && (
                  <div className="FileSizedisable"> Select the file</div>
                )}
                {/* {fileClinicPhoto == null &&  check && (
                  <div className="FileSizedisable"> Select the file</div>
                )} */}
              </label>

              <label>
                <div className="formControl">
                  <FormControl sx={{ mL: 1, width: "80%" }}>
                    <InputLabel id="demo-multiple-checkbox-label ">
                      Select Services
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      // sx={{width:400}}
                      name="ModeOfSession"
                      value={servicemode}
                      onChange={handleChange}
                      input={<OutlinedInput label=" Select Services" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {Servicesdata?.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          <Checkbox
                            checked={servicemode.indexOf(item.name) > -1}
                          />
                          <ListItemText primary={item.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </label>
            </div>

            <div className="NetworkPageFormSubmit">
              <button
                type="submit"
                className="NetworkPageFormSubmitButton"
                disabled={
                  isSubmitting ||
                  (feePerSessionError || userPhotoError || clinicPhotoError
                    ? true
                    : false)
                }
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ProfessionalServicesForm;
