import * as types from "../../Config/OTP/generateEmailOtpActionType"

const initialState = {
    data: null,
    isLoading: false,
    error: null
}

export const generateEmailOtpReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.GENERATE_EMAIL_OTP_SUCCESS:
            return {
                ...initialState,
                data: action
            }
        case types.GENERATE_EMAIL_OTP_FAILURE:
            return {
                ...initialState,
                error: action
            }
        case types.GENERATE_EMAIL_OTP_REQUEST:
            return {
                ...initialState,
                isLoading: true
            }
        default:
            return state
    }
}