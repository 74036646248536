import RequestService from "../../RequestService";
import { api } from "../../../helpers/ContactUsPage/ContactUsPageConstant";

class NetworkService {
  ContactUsPage() {
    const endPoint = `${api.queryType}`;
    return RequestService.getRequest(endPoint);
  }

  WriteToUs(action) {

    const endPoint = `${api.WriteToUs}`;
    return RequestService.postRequest(endPoint, {
      contactNumber: action.data.contactNumber.toString(),
      email: action.data.email,
      message: action.data.message,
      name: action.data.name,
      queryType: action.data.queryType,
    });
  }
}

export default new NetworkService();
