import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"



export const  Section8AnswersSuccess = (data) => {
    return {
        type: types.SECTION8_ANSWERS_SUCCESS,
        data:data,
    }
}


