import * as types from "../../Config/User/userActionType"

export const userLoginRequest = (data, token, corporateId,category) => {
    return {
        type: types.LOGIN_REQUEST,
        data: data,
        token,
        corporateId,
        category
    }
}

export const userLoginSuccess = (data, category) => {
    return {
        type: types.LOGIN_SUCCESS,
        data: data,
        category
    }
}

export const userLoginFailure = (data) => {
    return {
        type: types.LOGIN_FAILURE,
        data: data,
    }
}