import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const GetLatestSectionRequest = (data) => {
    return {
        type: types.GET_LATEST_SECTION_REQUEST,
        data:data
    }
}

export const GetLatestSectionSuccess = (data) => {
    return {
        type: types.GET_LATEST_SECTION_SUCCESS,
        data:data,
    }
}

export const GetLatestSectionFailure = (data) => {
    return {
        type: types.GET_LATEST_SECTION_FAILURE,
        data:data,
    }
}