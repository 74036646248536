import RequestService from "../../RequestService";
import { api } from "../../../helpers/Landing/landingConstant";

class NetworkService {
  joinNetwork(action) {

    const endPoint = `${api.joinNetwork}`;
    return RequestService.postRequest(endPoint, {

      availability: action.data.availability,
      name: action.data.name,
      number: action.data.number.toString(),
      organization: action.data.organization,
      organizationType: "string"

    });
  }



  newsletterSignup(action) {
    const endPoint = `${api.joinNetwork}`;
    return RequestService.postRequest(endPoint);
  }

  getPopularPackage(action) {
    const endPoint = `${api.getPopularPackage}/popular?pageNo=1&pageSize=${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  getPopularPlan(action) {
    const endPoint = `${api.getPopularPlan}/viewAll`;
    return RequestService.getRequest(endPoint);
  }

  getPopularTest(action) {
    const endPoint = `${api.getPopularTest}/popular?pageNo=1&pageSize=${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  getPopularHomeScreenPlan(action) {
    const endPoint = `${api.getPopularHomeScreenPlan}`;
    return RequestService.getRequest(endPoint);
  }

  getPopularPackageHomeScreen(action) {
    const endPoint = `${api.getPopularPackageHomeScreen}`;
    return RequestService.getRequest(endPoint);
  }

  getPopularTestHomeScreen(action) {
    const endPoint = `${api.getPopularTestHomeScreen}`;
    return RequestService.getRequest(endPoint);
  }

}

export default new NetworkService();
