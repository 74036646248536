import * as types from "../../Config/Packages/PackageLabTestActionType";

export const getTestPackageListingRequest = (
  pageNo,
  pageSize,
  radiology,
  rangeEnum,
  minPrice,
  maxPrice,
  testType,
  category
) => {
  return {
    type: types.GET_TEST_PACKAGE_LISTING_REQUEST,
    pageNo,
    pageSize,
    radiology,
    rangeEnum,
    minPrice,
    maxPrice,
    testType,
    category,
  };
};

export const getTestPackageListingSuccess = (data, category) => {
  return {
    type: types.GET_TEST_PACKAGE_LISTING_SUCCESS,
    data: data,
    category,
  };
};

export const getTestPackageListingFailure = (data) => {
  return {
    type: types.GET_TEST_PACKAGE_LISTING_FAILURE,
    data: data,
  };
};
