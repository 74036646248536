import CryptoJS from "crypto-js";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import storage from "../../../Utils/storage";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import "./SingleLogin.css";
import UserLoginForm from "./UserLoginForm";
import VerificationLoginEmailOTPScreen from "./VerificationLoginEmailOTPScreen";
import VerificationLoginOTPScreen from "./VerificationLoginOTPScreen";

import signupImage from "../../../assets/Register/signupImage.png";
import Logo from "../../../assets/common/logoPrimaryFooter.png";

function SingleLogin() {
  const location = useLocation();
  const navigate = useNavigate();

  const secretKey = process.env.REACT_APP_SECURITY_KEY;

  const [showOTP, setShowOTP] = useState(false);
  const [showMobileOtp, setShowMobileOtp] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const loginResponse = useSelector((state) => state.user.login);
  const validateOTP = useSelector((state) => state.otp.validateOtp);
  const otpMobileData = useSelector((state) => state.otp.otpMobile);
  const otpEmailData = useSelector((state) => state.otp.otp);

  const [childData, setChildData] = useState(null);

  useEffect(() => {
    if (otpMobileData?.data?.data.data.status === true) {
      Swal.fire({
        title: " OTP Is Sent To Your Mobile Number Successfully",
        icon: "success",
      }).then(() => {
        setShowMobileOtp(true);
      });
    }
  }, [otpMobileData?.data?.data.data.status]);

  useEffect(() => {
    if (otpEmailData?.data?.data.data.status === true) {
      Swal.fire({
        title: " OTP Is Sent To Your Email Successfully",
        icon: "success",
      }).then(() => {
        setShowOTP(true);
      });
    }
  }, [otpEmailData?.data?.data.data.status]);

  useEffect(() => {
    if (
      loginResponse.data?.data?.data?.data === null &&
      loginResponse.data?.data?.status === 200
    ) {
      Swal.fire({
        title: `${loginResponse.data?.data?.data?.message}`,
        icon: "success",
      }).then(() => {
        if (childData.email.includes("@")) {
          setShowOTP(true);
        } else {
          setShowMobileOtp(true);
        }
      });
    }
    if (
      loginResponse?.data?.data?.status === 200 &&
      loginResponse?.data?.data.data.data !== null
    ) {
      if (loginResponse.data?.data.data.data.roles.includes("EMPLOYEE")) {
        const encryptedData = CryptoJS.AES.encrypt(
          "EMPLOYEE",
          secretKey
        ).toString();
        const encryptedToken = CryptoJS.AES.encrypt(
          loginResponse.data?.data?.data?.data.refreshToken,
          secretKey
        ).toString();
        storage.setStorage("authorization", encryptedData);
        storage.setStorage("sessionToken", encryptedToken);
        storage.setStorage("token", loginResponse.data?.data?.data?.data.jwt);
        storage.setStorage("name", loginResponse.data?.data.data?.data.name);
        storage.setStorage(
          "profileUpdated",
          loginResponse.data?.data.data?.data.profileUpdated
        );
        storage.setStorage("id", loginResponse.data?.data.data.data.id);
        if (location.state === "package") {
          window.location.href = "/cart-checkout";
        } else if (location.state === "plan") {
          window.location.href = "/cart-checkout-plan";
        } else if (location.state === "mentalWellness") {
          window.location.href = "/mental-wellness";
        } else {
          window.location.href = "/";
        }
      } else if (
        loginResponse.data?.data.data.data.roles.includes("RETAIL_USER")
      ) {
        const encryptedData = CryptoJS.AES.encrypt(
          "RETAIL_USER",
          secretKey
        ).toString();
        const encryptedToken = CryptoJS.AES.encrypt(
          loginResponse.data?.data?.data?.data.refreshToken,
          secretKey
        ).toString();
        storage.setStorage("authorization", encryptedData);
        storage.setStorage("sessionToken", encryptedToken);
        storage.setStorage("token", loginResponse.data?.data?.data?.data.jwt);
        storage.setStorage("name", loginResponse.data?.data.data?.data.name);
        storage.setStorage(
          "profileUpdated",
          loginResponse.data?.data.data?.data.profileUpdated
        );
        storage.setStorage("id", loginResponse.data?.data.data.data.id);
        if (location.state === "package") {
          window.location.href = "/cart-checkout";
        } else if (location.state === "plan") {
          window.location.href = "/cart-checkout-plan";
        } else if (location.state === "mentalWellness") {
          window.location.href = "/mental-wellness";
        } else {
          window.location.href = "/";
        }
      } else {
        Swal.fire({
          title: "Not Authorized",
          icon: "error",
        }).then(() => {
          loginResponse.error = null;
          loginResponse.data = null;
          setSubmitting(false);
        });
      }
    }

    if (loginResponse.error !== null) {
      Swal.fire({
        title: loginResponse?.error?.data?.response?.data.errorMessage,
        icon: "error",
      }).then(() => {
        loginResponse.error = null;
        setSubmitting(false);
      });
    }
  }, [loginResponse]);

  useEffect(() => {
    if (
      validateOTP?.data?.data.status === 200 &&
      validateOTP?.data?.data.data.data !== null
    ) {
      Swal.fire({
        title: "OTP Verified Successfully ",
        icon: "success",
      }).then(() => {
        if (validateOTP.data?.data.data.data.roles.includes("EMPLOYEE")) {
          const encryptedData = CryptoJS.AES.encrypt(
            "EMPLOYEE",
            secretKey
          ).toString();

          storage.setStorage("authorization", encryptedData);
        } else {
          const encryptedData = CryptoJS.AES.encrypt(
            "RETAIL_USER",
            secretKey
          ).toString();
          storage.setStorage("authorization", encryptedData);
        }
        storage.setStorage(
          "profileUpdated",
          validateOTP.data?.data.data?.data.profileUpdated
        );
        const encryptedToken = CryptoJS.AES.encrypt(
          validateOTP.data?.data?.data?.data.refreshToken,
          secretKey
        ).toString();
        storage.setStorage("sessionToken", encryptedToken);
        storage.setStorage("token", validateOTP.data?.data.data.data.jwt);
        storage.setStorage("name", validateOTP.data?.data.data.data.name);
        storage.setStorage("id", validateOTP.data?.data.data.data.id);
        if (location.state === "package") {
          window.location.href = "/checkout";
        } else if (location.state === "plan") {
          window.location.href = "/checkoutPlan";
        } else {
          window.location.href = "/";
        }
      });
    }
  }, [validateOTP?.data?.data?.status]);

  if (storage.getStorage("token") !== null) {
    navigate("/");
  }

  return (
    <div>
      <ScrollToTop />
      <Helmet>
        <title>Yuva | Login</title>
        <meta
          name="description"
          content="Login to Yuva Health to access exclusive healtcare provider account. 
        Take proactive steps towards your well-being with our comprehensive and affordable healthcare solutions. Join us in prioritizing your health journey."
        />
      </Helmet>
      <div className="signupMain">
        <div className="signupMainLeft">
          <img
            src={signupImage}
            className="signupLeftBackground"
            alt="Sign Up"
          />
          <img src={Logo} className="LogoSignUp" alt="Logo" />
        </div>
        <div className="signupMainRight">
          {showMobileOtp ? (
            <VerificationLoginOTPScreen
              allValues={childData}
              mobileNumber={childData?.email}
            />
          ) : showOTP ? (
            <VerificationLoginEmailOTPScreen email={childData.email} />
          ) : (
            <UserLoginForm
              setChildData={setChildData}
              setSubmitting={setSubmitting}
              isSubmitting={isSubmitting}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SingleLogin;
