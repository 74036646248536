import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/TellAboutYourselfPage/TellAboutYourselfType"
import * as actions from "../../Action/TellAboutYourselfPage/TellAboutYourselfPageAction";
import NetworkService from "../../../network/Network/TellAboutYourselfPage/TellAboutYourselfPage";
export function* TellAboutYourselfPageSaga(action) {

  try {
    let response = yield call(NetworkService.TellAboutYourselfNet, action);
    yield put(actions.tellAboutYourselfSuccess(response));
  } catch (err) {
    yield put(actions.tellAboutYourselfFailure(err));
  }
}

export function* watchTellAboutYourselfPageSaga() {
  yield takeLatest(types.TELL_ABOUT_YOURSELF_PAGE_REQUEST, TellAboutYourselfPageSaga);
}