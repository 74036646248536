import * as types from "../../Config/EcomUser/EcomUserActionType";

export const viewAllProductsRequest = (
  pageNo,
  pageSize,
  brandIdList,
  categoryIdList,
  productIdList,
  productName,
  subCategoryIdList
) => {
  return {
    type: types.VIEW_ALL_PRODUCTS_REQUEST,
    pageNo,
    pageSize,
    brandIdList,
    categoryIdList,
    productIdList,
    productName,
    subCategoryIdList,
  };
};

export const viewAllProductsSuccess = (data) => {
  return {
    type: types.VIEW_ALL_PRODUCTS_SUCCESS,
    data: data,
  };
};

export const viewAllProductsFailure = (data) => {
  return {
    type: types.VIEW_ALL_PRODUCTS_FAILURE,
    data: data,
  };
};
