import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Diabetes from "../../../assets/Health_Checkups_Assests/Diabetes1.png";
import Obesity from "../../../assets/Health_Checkups_Assests/Obesity1.png";
import Smoking from "../../../assets/Health_Checkups_Assests/Smoking1.png";
import bloodpressure from "../../../assets/Health_Checkups_Assests/bloodpressure1.png";
import thyroidIssue from "../../../assets/Health_Checkups_Assests/thyroidIssue1.png";
import women from "../../../assets/Health_Checkups_Assests/women1.png";
import { lifestylePackageViewAllRequest } from "../../../redux/Action/Packages/LifeStylePackageViewAllAction";
import { lifestylePackageRequest } from "../../../redux/Action/Packages/LifestylePackageAction";
import Listing from "../../Organisms/Listing/Listing";
import "./HealthCheckupLanding.css";

function HealthCheckupLanding() {
  const [index, setIndex] = useState(0);
  const [show, setShow] = useState(true);
  const [enumNameNew, setEnumNameNew] = useState("OBESITY");
  const [displayNameNew, setDisplayNameNew] = useState("Obesity");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(lifestylePackageViewAllRequest(true));
    let lifestylePackage = {
      pageNo: 1,
      pageSize: 4,
      packageEnum: "OBESITY",
      fromWeb: true,
    };
    dispatch(lifestylePackageRequest(lifestylePackage));
  }, [dispatch]);

  const lifestylePackageViewAll = useSelector(
    (state) => state.package.lifestylePackageViewAll
  );
  const lifestylePackage = useSelector(
    (state) => state.package.lifestylePackage
  );

  const lifeStyle = lifestylePackageViewAll?.data?.data?.data?.data?.map(
    (item) => {
      let image;
      let index;
      switch (item.enumName) {
        case "OBESITY":
          image = Obesity;
          index = 0;
          break;
        case "THYROID":
          index = 1;
          image = thyroidIssue;
          break;
        case "WOMEN_HEALTH":
          index = 2;
          image = women;
          break;
        case "SMOKING_AND_ALCOHOL":
          image = Smoking;
          index = 3;
          break;
        case "DIABETES":
          image = Diabetes;
          index = 4;
          break;
        case "HYPER_TENSION":
          image = bloodpressure;
          index = 5;
          break;
        default:
          image = "";
      }
      return {
        displayName: item.displayName,
        image: image,
        enumName: item.enumName,
        index: index,
      };
    }
  );

  const handleCardClick = (clickedIndex, enumName, displayName) => {
    if (clickedIndex === index) {
      setIndex("");
      setShow(false);
    } else {
      setIndex(clickedIndex);
      setShow(true);
      setEnumNameNew(enumName);
      setDisplayNameNew(displayName);
      let lifestylePackage = {
        pageNo: 1,
        pageSize: 4,
        packageEnum: enumName,
        fromWeb: true,
      };
      dispatch(lifestylePackageRequest(lifestylePackage));
    }
  };

  function handleViewMore() {
    navigate("/view-test", { state: { data: true } });
  }
  return (
    <div className="HealthCheckupMain">
      <div className="FHCSection1">
        <p>LifeStyle Health Packages</p>
      </div>
      <>
        <div className="HealthChekupSection3CardWrapper">
          {lifeStyle?.map((item, ind) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "1em",
              }}
              key={ind}
            >
              <button
                className={"HealthChekupSection3CardIndividual"}
                onClick={() => {
                  handleCardClick(item.index, item.enumName, item.displayName);
                }}
                style={{
                  background: ind === index && "#1C71E1",
                  border: ind === index && "1px solid #1C71E1",
                }}
              >
                <p
                  className="HealthChekupSection3CardText"
                  style={{
                    color: ind === index && "#ffffff",
                  }}
                >
                  {item.displayName}
                </p>
              </button>
            </div>
          ))}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "1em",
            }}
          >
            <button
              className={"HealthChekupSection3CardIndividual"}
              onClick={handleViewMore}
            >
              <p className="HealthChekupSection3CardText">View More</p>
            </button>
          </div>
        </div>
        {show && (
          <div className="HealthCheckupListing">
            <Listing
              mapData={
                lifestylePackage.data?.data.data.data?.testPackageResponseDtoList
              }
              lifestylePackage="LIFESTYLE"
              enumName={enumNameNew}
              displayName={displayNameNew}
              testAndPackage={""}
            />
          </div>
        )}
      </>
    </div>
  );
}

export default HealthCheckupLanding;
