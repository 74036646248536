import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/User/userActionType";
import * as actions from "../../Action/User/loginAction";
import NetworkService from "../../../network/Network/User/userNetwork";

export function* LoginSaga(action) {
  try {
    let response = yield call(NetworkService.loginService, action);
    yield put(actions.userLoginSuccess(response,action.category));
  } catch (err) {
    yield put(actions.userLoginFailure(err));
  }
}

export function* watchLoginSaga() {
  yield takeLatest(types.LOGIN_REQUEST, LoginSaga);
}