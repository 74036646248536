import React from "react";
import { useNavigate } from "react-router-dom";
import myAccountDesign from "../../../assets/MyAccount/myAccountDesign.png";
import TransactionFailedScreen from "../../../assets/cart/TransactionFailedScreen.png";
import Footer from "../../Organisms/Footer/Footer";
import "./ConfirmPayment.css";

const FailedPayment = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };

  return (
    <div>
      <div
        className=""
        style={{
          backgroundImage: `url(${myAccountDesign})`,
          backgroundSize: "131% 29%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="NewFailedPayment">
          <div className="CompleteCartMain">
            <div className="CompleteCart1">
              <img
                className="CompleteImage"
                src={TransactionFailedScreen}
                alt="blue top girl"
              ></img>
              <div className="CompleteText">Transaction Failed</div>

              <div className="CompleteMessageText">
                <div>
                  Your transaction failed. this is usually because of network
                  congestion. .<br></br> Please try again. If you need any
                  further assistance, Please call us on <br></br>
                  <div className="justcolor">7619166998</div>
                </div>
              </div>
              <button className="NewButtonFailed" onClick={ handleClick}>
                Back To Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FailedPayment;
