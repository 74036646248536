import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Account/InReportDiagnosticActionType";
import * as actions from "../../Action/Account/MyReportDiagnostics";
import NetworkService from "../../../network/Network/MyAccount/MyAccountNetwork";

export function* myReportDiagnosticSaga(action) {

  try {
    let response = yield call(NetworkService.myReportDiagnostic, action);
    yield put(actions.myReportDiagnosticSuccess(response));
  } catch (err) {
    yield put(actions.myReportDiagnosticFailure(err));
  }
}

export function* watchMyReportDiagnosticSaga() {
  yield takeLatest(types.MY_REPORT_DIAGNOSTIC_REQUEST, myReportDiagnosticSaga);
}
