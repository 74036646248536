import * as types from "../../Config/User/userActionType"

export const GetProfileRequest = (data) => {
    return {
        type: types.GET_PROFILE_REQUEST,
        data:data
    }
}

export const GetProfileSuccess = (data) => {
    return {
        type: types.GET_PROFILE_SUCCESS,
        data:data,
    }
}

export const GetProfileFailure = (data) => {
    return {
        type: types.GET_PROFILE_FAILURE,
        data:data,
    }
}