import * as types from "../../Config/EcomUser/EcomUserActionType";

export const getCategoryDropdownRequest = () => {
  return {
    type: types.GET_CATEGORY_DROPDOWN_REQUEST,
  };
};

export const getCategoryDropdownSuccess = (data) => {
  return {
    type: types.GET_CATEGORY_DROPDOWN_SUCCESS,
    data: data,
  };
};

export const getCategoryDropdownFailure = (data) => {
  return {
    type: types.GET_CATEGORY_DROPDOWN_FAILURE,
    data: data,
  };
};
