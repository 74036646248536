import * as types from "../../../Config/OTP/OTPValidation/otpValidationActionType"

const initialState = {
    data: null,
    isLoading: false,
    error: null
}

export const otpValidationReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.OTPVALIDATION_SUCCESS:
            return {
                ...initialState,
                data: action
            }
        case types.OTPVALIDATION_FAILURE:
            return {
                ...initialState,
                error: action
            }
        case types.OTPVALIDATION_REQUEST:
            return {
                ...initialState,
                isLoading: true
            }
        default:
            return state
    }
}