import { Box, Slider } from "@mui/material";
import React from "react";

function Filter({
  handleCheckbox,
  viewEveryStorage,
  handleClearAll,
  handleRadiology,
  range,
  handleSlideFilter,
  handleRadio,
  priceFilterSlider,
  showRadiology,
  radioItem,
  filterClick,
}) {
  return (
    <div className={filterClick ? "everyMainFilter" : "everyMainLeft"}>
      <div className="filterHead">Filter By</div>
      <hr className="filterBreak" />
      <div className="filterTest">
        <div className="filterTestType" onChange={(e) => handleCheckbox(e)}>
          <div className="testDiv">
            <input
              type="checkbox"
              name="radiology"
              checked={viewEveryStorage?.allChecked.includes("Test")}
              value={"Test"}
              style={{ cursor: "pointer" }}
            />
            <label for="test" className="filterTestTitle">
              Test
            </label>
          </div>
        </div>
      </div>
      <div className="filterTest">
        <div className="filterTestType" onChange={(e) => handleCheckbox(e)}>
          <div className="testDiv">
            <input
              type="checkbox"
              name="radiology"
              checked={viewEveryStorage?.allChecked.includes("Packages")}
              value={"Packages"}
              style={{ cursor: "pointer" }}
            />
            <label for="test" className="filterTestTitle">
              Package
            </label>
          </div>
        </div>
      </div>
      <>
        <div className="filterTest">
          <div className="filterTestType" onChange={(e) => handleRadiology(e)}>
            <div className="testDiv">
              <input
                type="checkbox"
                name="radiology"
                checked={viewEveryStorage?.radiology === true}
                style={{ cursor: "pointer" }}
              />
              <label for="test" className="filterTestTitle">
                Radiology
              </label>
            </div>
          </div>
        </div>
      </>
      <div className="filterTest">
        <div className="filterPrice">
          <div className="priceDiv">
            <p className="filterPriceTitle">Price Range</p>
            <Box
            className="priceRange"
              sx={{ width: "75%", marginRight: "2em" }}
            >
              <Slider
                size="small"
                getAriaLabel={() => "Minimum distance"}
                value={range}
                onChange={handleSlideFilter}
                valueLabelDisplay="auto"
                disableSwap
                marks={priceFilterSlider}
                max={10000}
                style={{ color: "#1C71E1", cursor: "pointer" }}
                step={100}
              />
              <div className="filterPriceTitle">
                <div>
                  From: <span>₹0</span>
                </div>
                <div className="">
                  To: <span>₹10000</span>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
      <div className="sortDiv">
        <p>Sort By</p>
        <div className="filterTestType">
          <div className="sortContent">
            <input
              type="radio"
              name="sort"
              value={"PRICE_LOW_TO_HIGH"}
              style={{ cursor: "pointer" }}
              checked={radioItem === "PRICE_LOW_TO_HIGH"}
              onChange={() => handleRadio("PRICE_LOW_TO_HIGH")}
            />
            <p className="sortTitle">Price : Low to High</p>
          </div>
          <div className="sortContent">
            <input
              type="radio"
              name="sort"
              value={"PRICE_HIGH_TO_LOW"}
              checked={radioItem === "PRICE_HIGH_TO_LOW"}
              style={{ cursor: "pointer" }}
              onChange={() => handleRadio("PRICE_HIGH_TO_LOW")}
            />
            <p className="sortTitle">Price : High to Low</p>
          </div>
          <div className="sortContent">
            <input
              type="radio"
              name="sort"
              value={"DISCOUNT_LOW_TO_HIGH"}
              checked={radioItem === "DISCOUNT_LOW_TO_HIGH"}
              style={{ cursor: "pointer" }}
              onChange={() => handleRadio("DISCOUNT_LOW_TO_HIGH")}
            />
            <p className="sortTitle">Discount : Low to High</p>
          </div>
          <div className="sortContent">
            <input
              type="radio"
              name="sort"
              value={"DISCOUNT_HIGH_TO_LOW"}
              checked={radioItem === "DISCOUNT_HIGH_TO_LOW"}
              style={{ cursor: "pointer" }}
              onChange={() => handleRadio("DISCOUNT_HIGH_TO_LOW")}
            />
            <p className="sortTitle">Discount : High to Low</p>
          </div>
        </div>
      </div>
      <div onClick={handleClearAll} className="clearAll">
        {" "}
        Clear All
      </div>
    </div>
  );
}

export default Filter;
