import MenuIcon from "@mui/icons-material/Menu";
import Badge from "@mui/material/Badge";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { default as React } from "react";
import { useSelector } from "react-redux";
import shoppingcart from "../../../assets/LandingPage/Shopping_Cart.png";
import mLOGO from "../../../assets/common/mLOGO.png";
import magnifying from "../../../assets/common/magnifying.png";
import Hamburger from "./Hamburger";
import "./Navbar.css";
import "./NavbarMobile.css";
import SearchModal from "./SearchModal";
import SearchModalInput from "./SearchModalInput";

export default function NavbarMobile({
  modalRef,
  handleModal,
  handleKeyPress,
  openSearchModal,
  handleCartClick,
  cartIsEmpty,
  badgeCount,
  id,
  open,
  anchorEl,
  handleClose,
  handleInputModal,
  setopenModal,
  openmodal,
  ans,
  setAns,
  handleAns,
  setOpenSearchModal,
}) {
  const getPopularPackage = useSelector(
    (state) => state.landing.getPopularPackage
  );
  const getPopularPlan = useSelector((state) => state.landing.getPopularPlan);
  const getPopularTest = useSelector((state) => state.landing.getPopularTest);
  const elasticSearch = useSelector((state) => state.elasticSearch);

  return (
    <div style={{ paddingBottom: "4vw" }} className="mobileNavbar">
      <div className="mobileMainBox">
        <div className="MnavbarTitle">
          <div className="i">
            <a className="navLogo" href="/">
              <img src={mLOGO} alt="error" className="navbarLogo" />
            </a>
          </div>
        </div>
        <div>
          <div className="mblCarts">
            <div className="cartMbl">
              <div className="shoppingBagButton" onClick={handleCartClick}>
                <Badge
                  badgeContent={badgeCount === undefined ? 0 : badgeCount}
                  color="primary"
                  style={{ verticalAlign: "inherit" }}
                >
                  <img src={shoppingcart} alt="shoppingCart"></img>
                </Badge>
              </div>
              {cartIsEmpty && (
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Typography sx={{ p: 2 }} color=" #1D2334">
                    Your Cart Is Empty!
                  </Typography>
                </Popover>
              )}
            </div>
            <div className="mblMenu">
              {" "}
              <MenuIcon onClick={handleAns} />{" "}
            </div>
            {ans && <Hamburger open={setAns} />}{" "}
          </div>
        </div>
      </div>
      <div className="searchBarMobile" ref={modalRef}>
        <input
          className="navSearchBar"
          type="search"
          placeholder="Search for Tests and Packages"
          aria-label="Search"
          onClick={handleModal}
          onKeyUp={handleKeyPress}
          onChange={handleInputModal}
          id="search"
          autoComplete="off"
        />
        <div>
          {" "}
          <img src={magnifying} alt="Magnifying" />
        </div>
        {openmodal && (
          <SearchModal
            open={setopenModal}
            setOpenSearchModal={setOpenSearchModal}
            getPopularPackage={getPopularPackage}
            getPopularPlan={getPopularPlan}
            getPopularTest={getPopularTest}
          />
        )}
        {openSearchModal && (
          <SearchModalInput data={elasticSearch.data?.data.data.data} />
        )}
      </div>
    </div>
  );
}
