import React from "react";
import "../HRAscreens/HRAInfoScreens.css";
import backArrow from "../../../../assets/hra/backArrow.png";
import hra4b from "../../../../assets/hra/hra4b.png";
import { useNavigate } from "react-router-dom";
import Footer from "../../../Organisms/Footer/Footer";
import ScrollToTop from "../../../Molecules/ScrollToTop/ScrollToTop";
import storage from "../../../../Utils/storage";
import SingleLogin from "../../Login/SingleLogin";

function HRA4b() {
  const navigate = useNavigate();
  const SectionValue = localStorage.getItem("SectionValue");

  const handlePrevious = () => {
    navigate("/health-risk-assessment/section-4a");
  };
  window.addEventListener("popstate", function (event) {
    storage.setStorage("SectionValue", 3);
  });

  if (storage.getStorage("token") === null) {
    return <SingleLogin />;
  }
  return (
    <>
      <ScrollToTop />

      <div className="hraInfoScreens">
        <div className="hraInfoScreensChild1">
          <p>Your Health Risk Assessment</p>
        </div>
        {SectionValue === "4" && (
          <div
            className="hraInfoScreensChild2"
            style={{ backgroundImage: `url(${hra4b})` }}
          >
            <div className="hraInfoScreensChild2a">
              <div className="hraInfoScreensChild2a1">
                <p>GENERAL SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>DIETARY SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>MENTAL HEALTH SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>ALCOHOL RISK SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a2">
                <div className="hraInfoBackArrow">
                  <img
                    src={backArrow}
                    onClick={handlePrevious}
                    alt="backArrow"
                  />
                  <p>Alcohol Risk Section</p>
                </div>
                <div className="hraInfo">
                  <p>
                    Alcohol consumption has a number of adverse effects on your
                    body.
                    <br />
                    <br />
                    Help us gauge the effects that it might have had on yours by
                    answering the following questions.
                  </p>
                </div>
                <button
                  onClick={() => {
                    localStorage.setItem("SectionValue", 4);
                    navigate("/health-risk-assessment/section-4");
                  }}
                >
                  Discover further
                </button>
              </div>
            </div>
          </div>
        )}
        {SectionValue !== "4" && (
          <div className="hraDenied">
            Not Authorized !! please navigate to previous sections
          </div>
        )}
      </div>
    </>
  );
}

export default HRA4b;
