import { all } from "redux-saga/effects";
import { watchCartDiscountSaga } from "./CartDiscountSaga";
import { watchCreateOrderSaga } from "./CreateOrderSaga";
import { watchGetAllCouponSaga } from "./GetAllCouponSaga";
import { watchPaymentGatewayRedirectSaga } from "./PaymentGatewayRedirectSaga";
import { watchPaymentGatewayStatusSaga } from "./PaymentGatewayStatusSaga";
import { watchRedeemCouponSaga } from "./RedeemCouponSaga";
import { watchRemoveCartItemSaga } from "./RemoveCartItemSaga";
import { watchCreateCartSaga } from "./CreateCartSaga";
import { watchGetCartSaga } from "./GetCartSaga";

function* rootCartSaga() {
  yield all([
    watchCreateCartSaga(),
    watchGetCartSaga(),
    watchRemoveCartItemSaga(),
    watchCartDiscountSaga(),
    watchCreateOrderSaga(),
    watchPaymentGatewayRedirectSaga(),
    watchPaymentGatewayStatusSaga(),
    watchRedeemCouponSaga(),
    watchGetAllCouponSaga(),
  ]);
}

export default rootCartSaga;
