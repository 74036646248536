import { all } from "redux-saga/effects";
import { watchgetPlanDetailSaga } from "./GetPlanDetailSaga";
import { watchgetPlanFullDetailsSaga } from "./GetPlanFullDetailsSaga";
import { watchPlanLockSaga } from "./planLockSaga";
import { watchgetPlanTypeDropdownSaga } from "./PlanTypeDropdownSaga";
import { watchgetPlanRequestCallSaga } from "./RequestCallSaga";
import {watchgetPlanServicesSaga} from "./GetPlanServicesSaga"
import {watchgetPlanServicesDetailsSaga} from "./getPlanServicesDetailSaga";
function* rootPlanSaga() {
    yield all([
        watchgetPlanDetailSaga(),
        watchgetPlanFullDetailsSaga(),
        watchPlanLockSaga(),
        watchgetPlanTypeDropdownSaga(),
        watchgetPlanRequestCallSaga(),
        watchgetPlanServicesSaga(),
        watchgetPlanServicesDetailsSaga(),
    ])
}

export default rootPlanSaga;