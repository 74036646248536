import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const GetSectionAnswersRequest = (data) => {
    return {
        type: types.GET_SECTION_ANSWERS_REQUEST,
        data:data
    }
}

export const  GetSectionAnswersSuccess = (data) => {
    return {
        type: types.GET_SECTION_ANSWERS_SUCCESS,
        data:data,
    }
}

export const  GetSectionAnswersFailure = (data) => {
    return {
        type: types.GET_SECTION_ANSWERS_FAILURE,
        data:data,
    }
}