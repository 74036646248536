import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/User/userActionType";
import * as actions from "../../Action/User/registerAction";
import NetworkService from "../../../network/Network/User/userNetwork";

export function* RegisterSaga(action) {
  try {
    let response = yield call(NetworkService.registerService, action);
    yield put(actions.userRegisterSuccess(response));
  } catch (err) {
    yield put(actions.userRegisterFailure(err));
  }
}

export function* watchRegisterSaga() {
  yield takeLatest(types.REGISTER_REQUEST, RegisterSaga);
}