import { all } from "redux-saga/effects";
import { watchProfileLockSaga } from "./ProfileLock";


function* rootProfileLockSaga() {
    yield all([
        watchProfileLockSaga(),
    ])
}

export default rootProfileLockSaga;