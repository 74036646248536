import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Cross from "../../../assets/MyAccount/cross_button.png";
import "./OrderDetailsModal.css";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetailRequest } from "../../../redux/Action/Order/GetOrderDetailAction";

function OrderDetailsModal(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrderDetailRequest(props.id));
  }, [dispatch, props.id]);

  const getOrderDetail = useSelector((state) => state.order.getOrderDetail);
  const handleClose = () => {
    props.OrderDetails(false);
  };

  return (
    <div>
      <Modal
        open={props.OrderDetails}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainAddAddressModal"
      >
        <Box className="orderDetails">
          <div className="orderDetailsHead">
            <div className="orderDetailsTitle">
              Order Details
              <br />
              <span className="fareBreakup">(Fare Break-up)</span>
            </div>
            <img
              src={Cross}
              className="orderDetailsCross"
              onClick={handleClose}
              alt="Cross"
            />
          </div>

          <div className="orderDetailsCoupon">
            {getOrderDetail?.data?.data?.data?.data.couponName !== null ? (
              <p>
                {getOrderDetail?.data?.data?.data?.data.couponName} Coupon
                applied successfully. ₹
                {getOrderDetail?.data?.data?.data?.data.couponAmount} discount
                applied to your cart.
              </p>
            ) : (
              ""
            )}
          </div>
          {getOrderDetail?.data?.data?.data?.data.itemDetails.map((item) => (
            <div className="orderDetailsStart">
              <div className="orderDetailsStartLeft">
                <p>{item.itemName} (Quantity : {item.allocatedCount})</p>
              </div>
              <div className="orderDetailsStartRight">
                <p>{item.itemCost===item.itemDiscountedCost?"":`₹${item.itemCost}`}</p>
                <p>₹{item.itemDiscountedCost}</p>
              </div>
            </div>
          ))}

          <div className="orderDetailsDiscount">
            <p>Discount</p>
            <p style={{ color: "#319B4B" }}>
              ₹{getOrderDetail?.data?.data?.data?.data.discount}
            </p>
          </div>
          <div className="orderDetailsTotal">
            <div className="orderDetailsTotalLeft">
              <p>Total Amount</p>
            </div>
            <div className="orderDetailsTotalRight">
              <p>{getOrderDetail?.data?.data?.data?.data.totalAmount===getOrderDetail?.data?.data?.data?.data.amountPaid?"":`₹${getOrderDetail?.data?.data?.data?.data.totalAmount}`}</p>
              <p>₹{getOrderDetail?.data?.data?.data?.data.amountPaid}</p>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default OrderDetailsModal;
