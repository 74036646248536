import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/User/userActionType";
import * as actions from "../../Action/User/checkHashIdAction";
import NetworkService from "../../../network/Network/User/userNetwork";

export function* checkHashIdSaga(action) {
  try {
    let response = yield call(NetworkService.checkHashIdService, action);
    yield put(actions.userCheckHashIdSuccess(response));
  } catch (err) {
    yield put(actions.userCheckHashIdFailure(err));
  }
}

export function* watchCheckHashIdSaga() {
  yield takeLatest(types.CHECK_HASH_ID_REQUEST, checkHashIdSaga);
}