import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const GetQuestionSectionRequest = (data) => {
    return {
        type: types.GET_QUESTION_SECTION_REQUEST,
        data:data
    }
}

export const  GetQuestionSectionSuccess = (data) => {
    return {
        type: types.GET_QUESTION_SECTION_SUCCESS,
        data:data,
    }
}

export const  GetQuestionSectionFailure = (data) => {
    return {
        type: types.GET_QUESTION_SECTION_FAILURE,
        data:data,
    }
}