import * as types from "../../Config/Address/AddressActionType"

export const viewAllAddressRequest = (data) => {
    return {
        type: types.VIEW_ALL_ADDRESS_REQUEST,
        data,
    }
}

export const  viewAllAddressSuccess = (data) => {
    return {
        type: types.VIEW_ALL_ADDRESS_SUCCESS,
        data
    }
}

export const  viewAllAddressFailure = (data) => {
    return {
        type: types.VIEW_ALL_ADDRESS_FAILURE,
        data
    }
}