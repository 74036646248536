import RequestService from "../../RequestService";
import { api } from "../../../helpers/User/userConstant";

class NetworkService {
  loginService(action) {
    const endPoint = `${api.login}`;
    return RequestService.postRequest(endPoint, {
      emailOrNumber: action.data.email,
      password: action.data.password,
      token: action.token === null ? null : action.token,
      corporateId: action.corporateId === null ? null : action.corporateId,
    });
  }

  loginGoogleService(action) {
    const endPoint = `${api.loginGoogle}`;
    return RequestService.postRequest(endPoint, {
      token: action.data,
    });
  }

  registerService(action) {
    const endPoint = `${api.register}`;
    return RequestService.postRequest(endPoint, {
      email: action.data.email === undefined ? null : action.data.email,
      name: action.data.name,
      number: action.data.number === undefined ? null : action.data.number,
      otp: action.hash,
      password: action.data.password,
      token: action.data.token === undefined ? null : action.data.token,
    });
  }

  forgetPasswordService(action) {
    const endPoint = `${api.forgetPassword}?emailOrNumber=${action.data.email_or_phone}`;
    return RequestService.postRequest(endPoint);
  }
  checkHashIdService(action) {
    const endPoint = `${api.checkHashId}?hash=${action.data.hash}&emailOrNumber=${action.data.id}`;
    return RequestService.getRequest(endPoint);
  }
  otpMobileVerificationService(action) {
    const endPoint = `${api.otpMobileVerification}?emailOrNumber=${action.data.id}&hash=${action.data.hash}`;
    return RequestService.getRequest(endPoint);
  }

  getProfile(action) {
    const endPoint = `${api.getProfile}`;
    return RequestService.getRequest(endPoint);
  }

  updateProfile(action) {
    if (action.data.DateOfBirth !== null) {
      const datee = action.data.DateOfBirth;
      if (typeof(datee) === 'string') {
        const [day, month, year] = datee.split("/");
        const finalDate = `${year}/${month}/${day}`;
        var date = new Date(finalDate);
        date = date.getTime();
      } else {
        date = action.data.DateOfBirth;
      }
    }

    const endPoint = `${api.updateProfile}`;
    return RequestService.putRequest(endPoint, {
      dob: date,
      address: action.data.Address,
      gender: action.data.Gender,
      pinCode: action.data.Pincode,
      cityId: action.city,
      email: action.valEmail === "" ? null : action.valEmail,
      emailOtp: action.emailCode,
      number: action.valNumber === "" ? null : action.valNumber,
      numberOtp: action.NumberCode,
    });
  }

  AddRelation(action) {
    const endPoint = `${api.AddRelation}`;
    return RequestService.postRequest(endPoint, {
      age: parseInt(action.data.Age),
      name: action.data.Name,
      relation: action.data.Relationship,
    });
  }

  GetAllRelatives(action) {
    if (action.data) {
      if (action.data.version === false) {
        const endPoint = `${api.getAllRelatives}?uuid=${action.data.uuid}`;
        return RequestService.getRequest(endPoint);
      } else {
        const endPoint = `${api.getAllRelatives}?uuid=${action.data.uuid}&version=${action.data.version}&userVersion=${action.data.userVersion}`;
        return RequestService.getRequest(endPoint);
      }
    } else {
      const endPoint = `${api.getAllRelatives}`;
      return RequestService.getRequest(endPoint);
    }
  }

  UploadFamilyPicture(action) {
    const endPoint = `${api.UploadFamilyPicture}`;
    return RequestService.postRequest(endPoint, action.data);
  }

  UploadProfilePicture(action) {
    const endPoint = `${api.UploadProfilePicture}`;
    return RequestService.postRequest(endPoint, action.data);
  }

  resetPasswordService(action) {
    const endPoint = `${api.resetPassword}`;
    if (isNaN(action.data.emailOrNumber) === true) {
      //email
      return RequestService.putRequest(endPoint, {
        emailOrNumber: action.data.values1.id,
        hash: action.data.values1.hash,
        password: action.data.values.password,
      });
    } else if (isNaN(action.data.emailOrNumber) === false) {
      //mobile
      return RequestService.putRequest(endPoint, {
        emailOrNumber: action.data.emailOrNumber,
        hash: action.data.hash,
        password: action.data.values,
      });
    }
  }

  logoutUser(action) {
    const endPoint = `${api.logoutUser}`;
    if (action.bool) {
      return RequestService.postRequest(endPoint);
    } else {
      return RequestService.postRequest(endPoint, {
        fcmToken: action.fcmToken,
        refreshToken: action.refreshToken,
      });
    }
  }
}

export default new NetworkService();
