import React from "react";
import "../HRAscreens/HRAInfoScreens.css";
import backArrow from "../../../../assets/hra/backArrow.png";
import hra1a from "../../../../assets/hra/hra1a.png";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../Molecules/ScrollToTop/ScrollToTop";
import storage from "../../../../Utils/storage";
import SingleLogin from "../../Login/SingleLogin";

function HRA1a() {
  const navigate = useNavigate();

  window.addEventListener("popstate", function (event) {
    storage.setStorage("SectionValue", 1);
  });

  const handlePrevious = () => {
    localStorage.setItem("SectionValue", 1);
    navigate("/health-risk-assessment/section-1");
  };

  if (storage.getStorage("token") === null) {
    return <SingleLogin />;
  }

  const SectionValue = localStorage.getItem("SectionValue");

  return (
    <>
      <ScrollToTop />

      <div className="hraInfoScreens">
        <div className="hraInfoScreensChild1">
          <p>Your Health Risk Assessment</p>
        </div>
        {SectionValue === "2" && (
          <div
            className="hraInfoScreensChild2"
            style={{ backgroundImage: `url(${hra1a})` }}
          >
            <div className="hraInfoScreensChild2a">
              <div className="hraInfoScreensChild2a1">
                <p>GENERAL SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a2">
                <div className="hraInfoBackArrow">
                  <img
                    src={backArrow}
                    onClick={handlePrevious}
                    alt="backArrow"
                  />
                  <p>General Section</p>
                </div>
                <div className="hraInfo">
                  <div>
                    Thank you for the details that you shared. It was great to
                    know you!
                  </div>
                  <br />
                  <div>Let's move on ahead and discover more about you.</div>
                </div>
                <button
                  onClick={() => navigate("/health-risk-assessment/section-1b")}
                >
                  Discover further
                </button>
              </div>
            </div>
          </div>
        )}
        {SectionValue !== "2" && (
          <div className="hraDenied">
            Not Authorized !! please navigate to previous sections
          </div>
        )}
      </div>
    </>
  );
}

export default HRA1a;
