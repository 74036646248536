import * as types from "../../Config/Plan/PlanActionType";

export const getPlanFullDetailsRequest = (data) => {
  return {
    type: types.GET_PLAN_FULL_DETAILS_REQUEST,
    data:data,
  };
};

export const getPlanFullDetailsSuccess = (data) => {
  return {
    type: types.GET_PLAN_FULL_DETAILS_SUCCESS,
    data: data,
  };
};

export const getPlanFullDetailsFailure = (data) => {
  return {
    type: types.GET_PLAN_FULL_DETAILS_FAILURE,
    data: data,
  };
};
