import * as types from "../../Config/CorporateSignUp/CorporateSignUpActionType"

export const getCorporateTokenInfoRequest = (token,id) => {
    return {
        type: types.GET_CORPORATE_TOKEN_INFO_REQUEST,
        token,
        id
    }
}

export const getCorporateTokenInfoSuccess = (data) => {
    return {
        type: types.GET_CORPORATE_TOKEN_INFO_SUCCESS,
        data:data,
    }
}

export const getCorporateTokenInfoFailure = (data) => {
    return {
        type: types.GET_CORPORATE_TOKEN_INFO_FAILURE,
        data:data,
    }
}