import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/OTP/OTPValidation/UpdateProfileOTPValidationType";
import * as actions from "../../../Action/OTP/OTPValidation/UpdateProfileOTPValidationAction";
import NetworkService from "../../../../network/Network/OTP/OTPValidation/UpdateProfileOTPValidation";

export function* UpdateProfilevalidateOtp(action) {
  try {
    let response = yield call(
      NetworkService.UpdateProfilevalidateOtpNetwork,
      action
    );
    yield put(actions.UpdateProfileOtpValidationSuccess(response));
  } catch (err) {
    yield put(actions.UpdateProfileOtpValidationFailure(err));
  }
}

export function* watchUpdateProfilevalidateOtp() {
  yield takeLatest(
    types.UPDATE_PROFILE_OTP_VALIDATION_REQUEST,
    UpdateProfilevalidateOtp
  );
}