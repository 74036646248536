import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import storage from "../../../Utils/storage";
import pinkGift from "../../../assets/blueGift.png";
import { getAllOrderHistoryRequest } from "../../../redux/Action/Order/GetAllOrderHistoryAction";
import Title from "../../Atoms/Title/Title";
import NoPurchaseHistory from "../MyAccount/NoPurchaseHistory";
import "./MyPackageBooking.css";
import OrderDetailsModal from "./OrderDetailsModal";

function MyPackageBooking() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [OrderDetails, setOrderDetails] = useState(false);
  const [orderId, setOrderId] = useState();
  const [dropdown, setDropdown] = useState("");

  useEffect(() => {
    let isPlan = false;
    dispatch(getAllOrderHistoryRequest({ dropdown, isPlan }));
  }, [dispatch, dropdown]);

  const handleOrderDetails = (id) => {
    setOrderDetails(true);
    setOrderId(id);
  };

  const handleDropdown = (event) => {
    setDropdown(event.target.value);
  };

  const getAllOrderHistory = useSelector(
    (state) => state.order.getAllOrderHistory
  );

  return (
    <>
      <Title title="YUVA | Purchase History" />

      <div className="MyPackageBookingMain">
        <div className="MyPackageBookingMainChild1">
          <p>PURCHASE HISTORY</p>
          <select onChange={(event) => handleDropdown(event)} value={dropdown}>
            <option value={""}>All</option>
            <option value={"TEST"}>Test</option>
            <option value={"PACKAGE"}>Package</option>
            <option value={"PRODUCT"}>Product</option>
          </select>
        </div>
        {OrderDetails && (
          <OrderDetailsModal OrderDetails={setOrderDetails} id={orderId} />
        )}
        {getAllOrderHistory?.data?.data?.data?.data.userOrderHistoryResponseDto
          .length !== 0 ? (
          <div>
            {storage.getStorage("token") !== null ? (
              <>
                <div className="MyPackageBookingMainChild2">
                  {getAllOrderHistory?.data?.data?.data?.data.userOrderHistoryResponseDto.map(
                    (item) => (
                      <div className="MyPackageBookingMainChild2Divs">
                        <div className="MyPackageBookingMainChild2DivsUpper">
                          <div className="MyPackageBookingMainChild2DivsUpperChild1">
                            <span>ORDER PLACED</span>
                            <span>
                              {item.date
                                ? new Date(item.date).toLocaleDateString(
                                    "en-GB"
                                  )
                                : ""}
                            </span>
                          </div>
                          <div className="MyPackageBookingMainChild2DivsUpperChild1">
                            <span>TOTAL AMOUNT</span>
                            <span>₹{item.amount} </span>
                          </div>
                          <div className="MyPackageBookingMainChild2DivsUpperChild1">
                            <span>PATIENT NAME</span>
                            <span>{item.name}</span>
                          </div>
                          <div className="MyPackageBookingMainChild2DivsUpperChild4ss">
                            <div className="MyPackageBookingMainChild2DivsUpperChild4Para">
                              ORDER NUMBER - {item.orderNumber}
                            </div>
                            <div className="MyPackageBookingMainChild2DivsUpperChild4AnchorDivss">
                              <div className="MyPackageBookingMainChild2DivsUpperChild4Anchor">
                                {/* Cancel Order */}
                              </div>
                              <div className="MyPackageBookingMainChild2DivsUpperChild4Anchor">
                                {/* Reorder */}
                              </div>
                              {item.invoiceFilePath === null ? (
                                <div className="MyPackageBookingMainChild2DivsUpperChild4Anchorlast">
                                  Booking Pending
                                </div>
                              ) : (
                                <a
                                  href={item.invoiceFilePath}
                                  download=""
                                  className="MyPackageBookingMainChild2DivsUpperChild4Anchorlast"
                                >
                                  Download Invoice
                                </a>
                              )} 
                            </div>
                          </div>
                        </div>
                        <div className="MyPackageBookingMainChild2DivsLower MyPackageBookingMainChild2DivsLowerOrderHistory">
                          <div className="MyPackageBookingMainChild2DivsLowerChild1">
                            <div className="MyPackageBookingMainChild2DivsLowerChild1_1">
                              <img src={pinkGift} alt="OrderIcon" />
                            </div>
                            <div className="MyPackageBookingMainChild2DivsLowerChild1_2">
                              <p>
                                Booking {item.orderStatus}
                                {"   "}(ID: {item.bookingId || item.orderNumber})
                              </p>
                              <p>{item.itemCount} Items</p>
                            </div>
                          </div>
                          <button
                            className="MyPackageBookingMainChild2DivsLowerChild2"
                            onClick={() => handleOrderDetails(item.orderNumber)}
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </>
            ) : (
              navigate("/")
            )}
          </div>
        ) : (
          <NoPurchaseHistory />
        )}
      </div>
    </>
  );
}

export default MyPackageBooking;
