import { all } from "redux-saga/effects";
import { watchdiagnosticTestSaga } from "./DiagnosticTestSaga";
import { watchLifestylePackageSaga } from "./LIfestylePackageSaga";
import { watchLifestylePackageViewAllSaga } from "./LifestylePackageViewAllSaga";
import { watchpackageGetTestSaga } from "./PackageGetTestSaga";
import { watchpackageLabTestSaga } from "./PackageLabTestSaga";
import { watchpackageSelectSaga } from "./PackageSelectSaga";
import { watchGetTestPackageListingSaga } from "./GetTestPackageListingSaga";


function* rootPackagesSaga() {
    yield all([
        watchdiagnosticTestSaga(),
        watchLifestylePackageSaga(),
        watchLifestylePackageViewAllSaga(),
        watchpackageGetTestSaga(),
        watchpackageLabTestSaga(),
        watchpackageSelectSaga(),
        watchGetTestPackageListingSaga(),
    ])
}

export default rootPackagesSaga;