import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import storage from "../../../../Utils/storage";
import SingleLogin from "../../Login/SingleLogin";

function HRALastScreen() {
  const navigate = useNavigate();


  function finalScreenClick() {
    storage.setStorage("SectionValue", 1);
    navigate("/");
  }

  if (storage.getStorage("token") === null) {
    return <SingleLogin />;
  }
  return (
    <>
     
        <div className="HRAQuestionsMain">
          <div className="HRAQuestionsMainChild1">
            <p>Your Health Risk Assessment</p>
          </div>
          <div className="HRAQuestionsMainChild2LastScreen">
            <p>Report Generation In Progress..</p>
            <p>
              You Can Access Your Report From The My Report Section Under
              Profile.
            </p>
            <button className="HRAQuestionsButtonLastScreen" onClick={finalScreenClick}>
              Go Back To Home
            </button>
          </div>
        </div>
      
    </>
  );
}

export default HRALastScreen;
