import { createCartRequest } from "../redux/Action/Cart/CreateCartAction";
import SessionId from "./sessionId";

export const UpdateCart = async (id, dispatch, count) => {
    let dtoList =
        localStorage.getItem("itemDtoList") === null ?
            [] :
            JSON.parse(localStorage.getItem("itemDtoList"))

    try {
        SessionId();

        const newDtoList = dtoList.map(obj => {
            if (obj.productId == id) {
                return { ...obj, count: parseInt(count) }
            }
            return obj;
        })

        await dispatch(createCartRequest(newDtoList));

        return newDtoList;
    } catch (error) {
        return dtoList
    }
};