export const api = {
  viewAllProductCategory: '/ecom/user/category/view-all',
  viewProductByCategoryId: '/ecom/user/product',
  viewProductById: 'ecom/user/product-by-id',
  viewTopCategories: 'ecom/user/home-screen',
  viewAllProducts: 'ecom/user/product/view-all',
  getBrandDropdown: "/ecom/brand/dropdown",
  getCategoryDropdown: "/ecom/category/dropdown",
  getSubCategoryDropdown: "/ecom/sub-category/dropdown",
  viewHomeScreenProduct:'/ecom/user/home-screen-products'
};
