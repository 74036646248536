import CircularProgress from "@mui/material/CircularProgress";
import CryptoJS from "crypto-js";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import storage from "../../../Utils/storage";
import { otpMobileRequest } from "../../../redux/Action/OTP/OTPMobile/otpMobileAction";
import { userRegisterRequest } from "../../../redux/Action/User/registerAction";
import "./UserRegister.css";

function VerificationRegisterOTPScreen({ allValues, mobileNumber }) {

  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [isWrong, setIsWrong] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isExpire, setExpire] = useState(false);
  const [timer, setTimer] = useState(60);
  const [showLoader, setShowLoader] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(true);

  const secretKey = process.env.REACT_APP_SECURITY_KEY;

  const handleChange = (code) => setCode(code);

  const handleClickOtp = () => {
    var finalCode = parseInt(code);
    if (code === "") {
      setIsEmpty(true);
      setIsWrong(false);
      setExpire(false);
    } else {
      dispatch(userRegisterRequest(allValues, finalCode));
      setShowLoader(true);
    }
  };

  const responseRegister = useSelector((state) => state.user.register);

  useEffect(() => {
    if (responseRegister.data?.data.status === 200) {
      Swal.fire({
        title: "Successfully Registered",
        icon: "success",
      }).then(() => {
        window.location.href = "/";
        setShowLoader(false);
      });
    }
  }, [responseRegister.data?.data.status]);

  useEffect(() => {
    if (responseRegister.data) {
      if (
        responseRegister.data?.data?.data?.data.jwt !== null &&
        responseRegister?.data?.data?.data?.data.response !== null &&
        responseRegister.data !== null
      ) {
        const encryptedData = CryptoJS.AES.encrypt(
          "RETAIL_USER",
          secretKey
        ).toString();
        const encryptedToken = CryptoJS.AES.encrypt(
          responseRegister.data?.data?.data?.data.refreshToken,
          secretKey
        ).toString();
        storage.setStorage("sessionToken", encryptedToken);
        storage.setStorage("authorization", encryptedData);
        storage.setStorage(
          "token",
          responseRegister.data?.data?.data?.data?.jwt
        );
        storage.setStorage(
          "name",
          responseRegister.data?.data.data?.data?.name
        );
        storage.setStorage("id", responseRegister.data?.data.data.data?.id);
        storage.setStorage(
          "profileUpdated",
          responseRegister.data?.data.data.data?.profileUpdated
        );
      }
    }
  }, [responseRegister,secretKey]);

  useEffect(() => {
    if (code !== "") {
      if (
        responseRegister.error?.data.response.status === 400 &&
        responseRegister.error?.data.response.data.errorMessage ===
          "Invalid Otp"
      ) {
        setIsWrong(true);
        setExpire(false);
        setShowLoader(false);
        setIsEmpty(false);
        responseRegister.error.data.response.status = null;
      }
      if (
        responseRegister.error?.data.response.status === 400 &&
        responseRegister?.error?.data?.response?.data?.errorMessage ===
          "Otp Expired"
      ) {
        setExpire(true);
        setIsWrong(false);
        setShowLoader(false);
        setIsEmpty(false);
        responseRegister.error.data.response.status = null;
      }
      if (responseRegister.error?.data.response.status === 409) {
        Swal.fire({
          icon: "error",
          title: "You are Already Registered",
          text: "Redirecting to Login Page...",
        }).then(() => {
          window.location.href = "/login";
        });
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      }
    }
  }, [responseRegister.error , code]);

  useEffect(() => {
    let intervalId;
    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      setIsTimerRunning(false);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isTimerRunning, timer]);

  const handleResendOTP = () => {
    setExpire(false);
    setIsWrong(false);
    setTimer(60);
    setIsTimerRunning(true);
    dispatch(otpMobileRequest(mobileNumber));
  };

  return (
    <div className="signupArea">
      <p className="signupAreaFirstHeading">Register Here</p>
      <p className="signupAreaSecondHeading">Verify your Mobile Number here!</p>
      <div className="otpArea">
        <OtpInput
          value={code}
          onChange={handleChange}
          containerStyle={{
            width: "75%",
            height: "100%",
          }}
          numInputs={4}
          separator={<span style={{ width: "8px" }}></span>}
          isInputNum={true}
          shouldAutoFocus={true}
          inputStyle={{
            background: "#F3F0F0",
            border: "1px solid #1C71E1",
            borderRadius: "8px",
            width: "100%",
            height: "75px",
            fontSize: "26px",
            color: "#000",
            fontWeight: "400",
            caretColor: "blue",
          }}
          focusStyle={{
            border: "1px solid #CFD3DB",
            outline: "none",
          }}
        />
      </div>
      <div className="otpRegisterBottomDiv">
        {isEmpty ? (
          <div className="expireOTPMessageMobile">
            <p>Please fill the OTP</p>
          </div>
        ) : null}
        {isExpire ? (
          <div className="expireOTPMessageMobile">
            <p>OTP Expired</p>
          </div>
        ) : null}
        {isWrong ? (
          <div className="expireOTPMessageMobile">Incorrect OTP</div>
        ) : null}
      </div>
      <button
        onClick={handleClickOtp}
        type="submit"
        className="signupInputFieldButton"
        style={showLoader ? { background: "transparent" } : {}}
        disabled={showLoader}
      >
        {showLoader ? <CircularProgress /> : <>Sign In</>}
      </button>
      {isTimerRunning ? (
        <p className="otpRegisterTimer">{timer} seconds</p>
      ) : (
        <p className=" otpRegisterTimer">
          Didn't Recive Code? &nbsp;
          <span
            style={{
              color: "#0089ED",
              cursor: "pointer",
              fontSize: "1em",
            }}
            onClick={handleResendOTP}
          >
            Resend
          </span>
        </p>
      )}
    </div>
  );
}

export default VerificationRegisterOTPScreen;
