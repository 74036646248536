import Section3Info1 from "../../../assets/AdvertisementSection3CardsImages/Section3Info1.png";
import Section3Info2 from "../../../assets/AdvertisementSection3CardsImages/Section3Info2.png";
import Section3Info3 from "../../../assets/AdvertisementSection3CardsImages/Section3Info3.png";
import Section3Info4 from "../../../assets/AdvertisementSection3CardsImages/Section3Info4.png";
import Section3Info5 from "../../../assets/AdvertisementSection3CardsImages/Section3Info5.png";
import Section5image1 from "../../../assets/AdvertisementSection3CardsImages/Section5image1.png";
import Section5image2 from "../../../assets/AdvertisementSection3CardsImages/Section5image2.png";
import section3Cards1 from "../../../assets/AdvertisementSection3CardsImages/section3Cards1.png";
import section3Cards2 from "../../../assets/AdvertisementSection3CardsImages/section3Cards2.png";
import section3Cards3 from "../../../assets/AdvertisementSection3CardsImages/section3Cards3.png";
import section3Cards4 from "../../../assets/AdvertisementSection3CardsImages/section3Cards4.png";
import section3Cards5 from "../../../assets/AdvertisementSection3CardsImages/section3Cards5.png";
import section4C1 from "../../../assets/AdvertisementSection3CardsImages/section4C1.png";
import section4C2 from "../../../assets/AdvertisementSection3CardsImages/section4C2.png";

export const MarketingConstant = {
  section1Header1:
    "Yuva Health Checkup Package Free Limited Time Offer (30 Parameters )",
  section1Header2:
    "Complete Blood Count, Random blood sugar, Blood Urea, SGOT, SGPT,  Serum Cholesterol Urine Routine & Microscopy",
  section1Header3: "FREE ONLINE CONSULTATION",
  section1CCHeader: "*** Collection Charges Rs 200/- applicable.",
  section1TaCHeader: "*** Terms and Condition applied.",
  section1Button: "Get Free Call",

  section2Header: "Talk to our health Advisor",
  section2Button: "Click Here",

  section3Header: "Services",
  section3SubHeader: "What We Offer",
  section3Cards: [
    {
      index: 0,
      name: "OPD Consultation Program",
      icon: section3Cards1,
    },
    {
      index: 1,
      name: "Talk to your Doctor",
      icon: section3Cards2,
    },
    {
      index: 2,
      name: "Health Checkup & Diagnostics",
      icon: section3Cards3,
    },
    {
      index: 3,
      name: "Health Risk Assessment",
      icon: section3Cards4,
    },
    {
      index: 4,
      name: "Get Equipment on Rent",
      icon: section3Cards5,
    },
  ],

  section3CardInfo: [
    {
      name: "OPD Consultation Program",
      description:
        "This exclusive OPD Consultation program offers you OPD consultation in all our network of hospitals and clinics. The benefit can be availed for all the family member enrolled under this subscription program, based on individual needs. Our network ranges from clinic next door to territory care hospitals.",
      icon: Section3Info1,
      route: "/consultation/cashless-opd",
    },
    {
      name: "Talk to your Doctor",
      description:
        "Chat with your doctor anytime and anywhere, Get answers for anything and everything.From Dental infection to Super Specialisations Consultations we got you 24 /7. Yuva Health upholds the highest standards when approving doctors to practice online. That means every doctor you see on the platform is highly qualified to provide the best care possible.",
      icon: Section3Info2,
      route: "/talk-to-doctor",
    },
    {
      name: "Health Checkup & Diagnostics",
      description:
        "We at YUVA HEALTH, we provide health checkup and diagnostic services, It’s a preventive measure that involves a comprehensive assessment of your overall health status. It typically includes a physical examination, blood tests, imaging tests, and other diagnostic tests to identify any underlying health problems or risk factors.",
      icon: Section3Info3,
      route: "/health-checkup",
    },
    {
      name: "Health Risk Assessment",
      description:
        "An HRA, sometimes called a health appraisal or health assessment, is a screening tool that helps individuals identify and understand their health risks and monitor health status over time. A health risk assessment includes a questionnaire, an assessment of health status, and personalised feedback about actions that can be taken to reduce risks, maintain health, and prevent disease.",
      icon: Section3Info4,
      route: "/health-risk-assessment",
    },
    {
      name: "Get Equipment on Rent",
      description:
        "To get equipment on rent, you need to select the product you wish to take on rent for yourself or your loved one, complete your KYC and document verification on our website, and then we will deliver the product to your doorstep. You can then use the product for the available time period as per the rental agreement.",
      icon: Section3Info5,
      route: "/rental-equipment",
    },
  ],

  section3BodyHeader: "OPD Consultation Program",
  section3BodyText:
    "This exclusive OPD Consultation program offers you OPD consultation in all our network of hospitals and clinics. The benefit can be availed for all the family member enrolled under this subscription program, based on individual needs. Our network ranges from clinic next door to territory care hospitals.",
  section3BodyButton: "Learn More",

  section4Header: "Why Choose us",
  section4SubHeader: "We create a Curious Buzzr",
  section4Points: [
    "Preventive Healthcare Programs & Plans For Early Diagnosis.",
    "Physical As Well As Mental Wellness Programs & Plans To Foster A Healthy Life.",
    "Cost-Efficient, Tailor-Made Packages For Any And All Occasions.",
  ],
  section4Button: "Discover More",
  section4Cards: [
    {
      header: "Customised Corporate Program",
      Description:
        "Empowering You To Live Your Best Life Through Holistic Health Approaches.",
      icon: section4C1,
    },
    {
      header: "Customised Retail Plan",
      Description:
        "Empowering You To Live Your Best Life Through Holistic Health Approaches.",
      icon: section4C2,
    },
  ],

  section5Header: "About Us",
  section5SubHeader: "We are here for Health Solution Idea",
  section5Description:
    "At Yuva Health, we bring together a diverse and experienced team with backgrounds in technology, insurance, healthcare, and wellness. Our team has a strong track record of success in the health-tech industry, particularly in launching and implementing India's first cashless OPD (Outpatient Department) in both Rural and Urban India.",
  section5Button: "Discover More",
  section5BannerCity: [
    {
      name: "Bangalore",
      icon: Section5image1,
    },
    {
      name: "New Delhi",
      icon: Section5image2,
    },
  ],
  section5BannerInfo: [
    {
      name: "Hospitals",
      number: "14",
    },
    {
      name: "Clinics",
      number: "76",
    },
    {
      name: "Diagnostic Centers",
      number: "03",
    },
    {
      name: "Professional Services",
      number: "13",
    },
  ],
};
