import React  from 'react'
import "../HRAscreens/HRAInfoScreens.css";
import backArrow from "../../../../assets/hra/backArrow.png"
import hra5a from "../../../../assets/hra/hra5a.png"
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../Molecules/ScrollToTop/ScrollToTop"
import storage from '../../../../Utils/storage';
import SingleLogin from "../../Login/SingleLogin";

function HRA5a() {
    const navigate = useNavigate();
    const SectionValue = localStorage.getItem("SectionValue");

 

    window.addEventListener('popstate', function (event) {
        // execute your function here
        storage.setStorage("SectionValue", 4)
    });
    const handlePrevious = () => {

        localStorage.setItem("SectionValue", 4);
        navigate("/health-risk-assessment/section-4")
    }

    if (storage.getStorage("token") === null) {
        return <SingleLogin />;
    }
    return (
        <>
            <ScrollToTop />

        
                <div className='hraInfoScreens'>
                    <div className='hraInfoScreensChild1'><p>Your Health Risk Assessment</p></div>
          {SectionValue === "5" && (
                  
                    <div className='hraInfoScreensChild2'
                        style={{ backgroundImage: `url(${hra5a})` }}
                    >
                        <div className='hraInfoScreensChild2a'>
                            <div className='hraInfoScreensChild2a1'>
                                <p>GENERAL SECTION</p>
                            </div>
                            <div className='hraInfoScreensChild2a1'>
                                <p>DIETARY SECTION</p>
                            </div>
                            <div className='hraInfoScreensChild2a1'>
                                <p>MENTAL HEALTH SECTION</p>
                            </div>
                            <div className='hraInfoScreensChild2a1'>
                                <p>ALCOHOL RISK SECTION</p>
                            </div>
                            <div className='hraInfoScreensChild2a2'>
                                <div className='hraInfoBackArrow'>
                                    <img src={backArrow} onClick={handlePrevious} alt="backArrow"/>
                                    <p>Alcohol Risk</p>
                                </div>
                                <div className='hraInfo'>
                                    <p>Alcohol consumption is best when it is moderate. Let’s move ahead and discover more about you.</p>
                                </div>
                                <button onClick={() => navigate("/health-risk-assessment/section-5b")}>Discover further</button>
                            </div>
                        </div>
                    </div>
                    )}
                    {SectionValue !== "5" && (
            <div className="hraDenied">Not Authorized !! please navigate to previous sections</div>
          )}
                   

                </div>

        </>

    )
}

export default HRA5a