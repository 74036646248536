import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../../Action/HealthRiskAssessment/GetQuestionSection7Action";
import NetworkService from "../../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* GetQuestionSection7Saga(action) {
  try {
    let response = yield call(NetworkService.GetQuestionSection7, action);
    yield put(actions.GetQuestionSection7Success(response));
  } catch (err) {
    yield put(actions.GetQuestionSection7Failure(err));
  }
}

export function* watchGetQuestionSection7Saga() {
  yield takeLatest(types.GET_QUESTION_SECTION7_REQUEST, GetQuestionSection7Saga);
}
