import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/CheckEmail/CheckEmail";
import * as actions from "../../Action/CheckEmail/checkEmail";
import NetworkService from "../../../network/Network/CheckEmail/CheckEmail";
export function* CheckEmailSaga(action) {
   

  try {
    let response = yield call(NetworkService.CheckEmail, action);
    yield put(actions.CheckEmailSuccess(response));
  } catch (err) {
    yield put(actions.CheckEmailFailure(err));
  }
}

export function* watchCheckEmailSaga() {
  yield takeLatest(types.CHECK_EMAIL_REQUEST, CheckEmailSaga);
}
