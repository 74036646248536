import { combineReducers } from "redux";
import { downloadErmReportReducer } from "./downloadErmReportReducer";
import { medicalRecordDropdownReducer } from "./medicalRecordDropdownReducer"
import { medicalRecordFormReducer } from "./MedicalRecordFormRequestReducer";
import { viewMedicalRecordReducer } from "./viewMedicalRecorListingReducer"

export default combineReducers({
    medicalRecordDropdown: medicalRecordDropdownReducer,
    viewMedicalRecord: viewMedicalRecordReducer,
    downloadErmReport: downloadErmReportReducer,
    medicalRecordForm:medicalRecordFormReducer,
})
