import * as types from "../../Config/Plan/PlanActionType";

export const getPlanServicesRequest = (data) => {
  return {
    type: types.GET_PLAN_SERVICES_REQUEST,
    data:data,
  };
};

export const getPlanServicesSuccess = (data) => {
  return {
    type: types.GET_PLAN_SERVICES_SUCCESS,
    data: data,
  };
};

export const getPlanServicesFailure = (data) => {
  return {
    type: types.GET_PLAN_SERVICES_FAILURE,
    data: data,
  };
};
