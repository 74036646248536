import * as types from "../../Config/Cart/CreateCartActionType";

export const RedeemCouponRequest = (data) => {
  return {
    type: types.REDEEM_COUPON_REQUEST,
    data
  };
};

export const RedeemCouponSuccess = (data) => {
  return {
    type: types.REDEEM_COUPON_SUCCESS,
    data,
  };
};

export const RedeemCouponFailure = (data) => {
  return {
    type: types.REDEEM_COUPON_FAILURE,
    data,
  };
};
