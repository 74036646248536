import * as types from "../../Config/Cart/CreateCartActionType"

export const paymentGatewayRedirectRequest = (id) => {
    return {
        type: types.PAYMENT_GATEWAY_REDIRECT_REQUEST,
        id
    }
}

export const  paymentGatewayRedirectSuccess = (data) => {
    return {
        type: types.PAYMENT_GATEWAY_REDIRECT_SUCCESS,
        data
    }
}

export const  paymentGatewayRedirectFailure = (data) => {
    return {
        type: types.PAYMENT_GATEWAY_REDIRECT_FAILURE,
        data
    }
}