import * as types from "../../Config/EcomUser/EcomUserActionType";

export const getSubCategoryDropdownRequest = (data) => {
  return {
    type: types.GET_SUB_CATEGORY_DROPDOWN_REQUEST,
    data
  };
};

export const getSubCategoryDropdownSuccess = (data) => {
  return {
    type: types.GET_SUB_CATEGORY_DROPDOWN_SUCCESS,
    data: data,
  };
};

export const getSubCategoryDropdownFailure = (data) => {
  return {
    type: types.GET_SUB_CATEGORY_DROPDOWN_FAILURE,
    data: data,
  };
};
