import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPopularPackageHomeScreenRequest } from "../../../redux/Action/Landing/getPopularPackageHomeScreenAction";
import Listing from "../../Organisms/Listing/Listing";
import "./FeatureHealthCheckup.css";
import "./NewFeatureHealthCheckup.css";

function FeatureHealthCheckup() {
  const dispatch = useDispatch();
  const getPopularPackageHomeScreen = useSelector(
    (state) => state.landing.getPopularPackageHomeScreen
  );

  useEffect(() => {
    dispatch(getPopularPackageHomeScreenRequest());
  }, []);
  return (
    <>
      <div className="FHCMain">
        <div className="FHCSection1 health-checkup">
          <p>Popular Health Checkups</p>
        </div>
        <Listing
          mapData={getPopularPackageHomeScreen.data?.data.data.data}
          testAndPackage={"Packages"}
        />
      </div>
    </>
  );
}

export default FeatureHealthCheckup;
