import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import backArrow from "../../../assets/hra/backArrow.png";
import hra10 from "../../../assets/hra/hra10.png";
import { GetLatestSectionRequest } from "../../../redux/Action/HealthRiskAssessment/GetLatestSectionAction";
import { GetQuestionSection9Request } from "../../../redux/Action/HealthRiskAssessment/GetQuestionSection9Action";
import { SaveReportRequest } from "../../../redux/Action/HealthRiskAssessment/SaveReportAction";
import { SaveSectionAnswersRequest } from "../../../redux/Action/HealthRiskAssessment/SaveSectionAnswersAction";
import storage from "../../../Utils/storage";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import SingleLogin from "../Login/SingleLogin";
import "./Sections.css";

function Section9() {
  const dispatch = useDispatch();
  const loginSchema = Yup.object().shape({
    Q57: Yup.string().required("Required Field"),
  });
  const navigate = useNavigate();
  const [json, setJson] = useState();
  const SectionValue = localStorage.getItem("SectionValue");

  useEffect(() => {
    dispatch(GetQuestionSection9Request());

    dispatch(GetLatestSectionRequest(8));
  }, []);

  const GetLatestSection = useSelector((state) => state.hra.getLatestSection);

  useEffect(() => {
    if (GetLatestSection?.data?.data?.data?.data !== undefined) {
      setJson(JSON.parse(GetLatestSection?.data?.data?.data?.data));
    }
  }, [GetLatestSection?.data?.data?.data?.data]);

  const GetQuestionSection = useSelector(
    (state) => state.getQuestion.getQuestionSection9
  );
  
  const Section9Answers = useSelector(
    (state) => state.getAnswer.section9Answers
  );
  const GetSectionAnswers = useSelector(
    (state) => state.getAnswer.getSectionAnswers
  );

  const SaveReport = useSelector((state) => state.hra.saveReport);

  const currentSectionValue = localStorage.getItem("SectionValue");

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(SaveSectionAnswersRequest({ values, currentSectionValue }));
    let mergedObject = Object.assign({}, json, values);

    Object.keys(mergedObject).map((key, index) => {
      if (mergedObject[key] === null) {
        delete mergedObject[key];
      }
      if (key === "Q57") {
        mergedObject[key] = parseInt(mergedObject[key]);
      }
      if (key === "Q9") {
        if (mergedObject[key] === 1 || mergedObject[key] === 2) {
          mergedObject[key] = 3;
        }
      }
      if (key === "Q11") {
        if (mergedObject[key] === 2 || mergedObject[key] === 3) {
          mergedObject[key] = 0;
        } else if (mergedObject[key] === 1) {
          mergedObject[key] = 2;
        } else if (mergedObject[key] === 0) {
          mergedObject[key] = 3;
        }
      }
      if (key === "Q12") {
        if (mergedObject[key] === 2 || mergedObject[key] === 3) {
          mergedObject[key] = 0;
        } else if (mergedObject[key] === 0) {
          mergedObject[key] = 3;
        }
      }
      if (key === "Q13" || key === "Q14") {
        if (
          mergedObject[key] === 0 ||
          mergedObject[key] === 1 ||
          mergedObject[key] === 2
        ) {
          mergedObject[key] = 3;
        } else if (mergedObject[key] === 3) {
          mergedObject[key] = 0;
        }
      }
    });

    let chronicIllness = localStorage.getItem("chronicIllness");
    let CancerReason = localStorage.getItem("CancerReason");
    chronicIllness = chronicIllness === "undefined" ? "" : chronicIllness;
    CancerReason = CancerReason === "undefined" ? "" : CancerReason;
    let relationId =
      localStorage.getItem("relationId") === ""
        ? ""
        : localStorage.getItem("relationId");
    let final = { CancerReason, chronicIllness, mergedObject, relationId };
    dispatch(SaveReportRequest(final));
  };

  function handlePrevious() {
    navigate("/health-risk-assessment/section-9b");
  }

  if (SaveReport?.data?.data.status === 200) {
    Swal.fire({
      icon: "success",
      text: "Your Answers Has Been Recorded Successfully",
    }).then(() => {
      navigate("/health-risk-assessment/report-generation");
    });
  }

  window.addEventListener("popstate", function (event) {
    storage.setStorage("SectionValue", 8);
  });

  if (storage.getStorage("token") === null) {
    return <SingleLogin />;
  }

  if (GetQuestionSection.isLoading) {
    return <YuvaLoader />;
  }
  if (SaveReport.isLoading) {
    return <YuvaLoader />;
  }
  return (
    <>
      <ScrollToTop />

      <div className="HRAQuestionsMain">
        <div className="HRAQuestionsMainChild1">
          <p>Your Health Risk Assessment</p>
        </div>
        {SectionValue === "9" && (
          <div
            className="HRAQuestionsMainChild2"
            style={{ backgroundImage: `url(${hra10})` }}
          >
            <div className="HRAQuestionsMainChild2a">
              <div className="HRAQuestionsMainChild2a1">
                <p>GENERAL SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1 faded">
                <p>DIETARY SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1">
                <p>MENTAL HEALTH SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1 faded">
                <p>ALCOHOL RISK SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1">
                <p>SMOKING & TOBACCO SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1 faded">
                <p>SAFETY HABITS SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1">
                <p>CURRENT MEDICAL CONDITION SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1 faded">
                <p>HERIDETARY AND GENETIC SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1">
                <p>SLEEP SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a2">
                <Formik
                  initialValues={
                    Section9Answers?.data === null
                      ? {
                          Q57: "",
                        }
                      : {
                          Q57: GetSectionAnswers?.data?.data?.data?.data.Q57,
                        }
                  }
                  validationSchema={loginSchema}
                  onSubmit={handleSubmit}
                >
                  {
                    <Form>
                      <div className="HRAQuestionsMainChild2a2div">
                        <div className="hraBackArrow">
                          <img
                            src={backArrow}
                            onClick={handlePrevious}
                            alt="backArrow"
                          />
                          <p>Sleeping Habit</p>
                        </div>
                        <label className="Q_A">
                          <p>
                            {
                              GetQuestionSection?.data?.data?.data?.data[0]
                                .question
                            }
                          </p>
                          <Field
                            as="select"
                            name="Q57"
                            // placeholder="Doctor Name"
                            className="select"
                          >
                            <option value="" disabled>
                              Please Select
                            </option>
                            <option value="0">less than 7 hr</option>
                            <option value="1">between 7-9 hr</option>
                            <option value="2">greater than 9hr</option>
                          </Field>
                          <ErrorMessage
                            className="errorMessageValidation"
                            name="Q57"
                            component="div"
                          />
                        </label>

                        <button type="submit" className="HRAQuestionsButton">
                          Submit
                        </button>
                      </div>
                    </Form>
                  }
                </Formik>
              </div>
            </div>
          </div>
        )}
        {SectionValue !== "9" && (
          <div className="hraDenied">
            Not Authorized !! please navigate to previous sections
          </div>
        )}
      </div>
    </>
  );
}
export default Section9;
