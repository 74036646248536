import * as types from "../../Config/EcomUser/EcomUserActionType"

export const viewProductByIdRequest = (id) => {
    return {
        type: types.VIEW_PRODUCT_BY_ID_REQUEST,
        id
    }
}

export const  viewProductByIdSuccess = (data) => {
    return {
        type: types.VIEW_PRODUCT_BY_ID_SUCCESS,
        data:data,
    }
}

export const  viewProductByIdFailure = (data) => {
    return {
        type: types.VIEW_PRODUCT_BY_ID_FAILURE,
        data:data,
    }
}