import * as types from "../../Config/EcomUser/EcomUserActionType"

export const viewHomeScreenProductRequest = () => {
    return {
        type: types.VIEW_HOME_SCREEN_PRODUCT_REQUEST,
    }
}

export const  viewHomeScreenProductSuccess = (data) => {
    return {
        type: types.VIEW_HOME_SCREEN_PRODUCT_SUCCESS,
        data:data,
    }
}

export const  viewHomeScreenProductFailure = (data) => {
    return {
        type: types.VIEW_HOME_SCREEN_PRODUCT_FAILURE,
        data:data,
    }
}