import * as types from "../../../Config/OTP/OTPMobile/otpMobileActionType"

export const otpMobileRequest = (data) => {
    return {
        type: types.OTPMOBILE_REQUEST,
        data:data
    }
}

export const otpMobileRequestSuccess = (data) => {
    return {
        type: types.OTPMOBILE_SUCCESS,
        data:data,
    }
}

export const otpMobileRequestFailure = (data) => {
    return {
        type: types.OTPMOBILE_FAILURE,
        data:data,
    }
}