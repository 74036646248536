import * as types from "../../Config/Cart/CreateCartActionType"

export const removeCartItemRequest = (id,productType) => {
    return {
        type: types.REMOVE_CART_ITEM_REQUEST,
        id,
        productType
    }
}

export const removeCartItemSuccess = (data) => {
    return {
        type: types.REMOVE_CART_ITEM_SUCCESS,
        data
    }
}

export const removeCartItemFailure = (data) => {
    return {
        type: types.REMOVE_CART_ITEM_FAILURE,
        data
    }
}