import * as types from "../../Config/ContactUsPage/ContactUsPageActionType"

export const WriteToUseRequest = (data) => {
    return {
        type: types.WRITE_TO_US_REQUEST,
        data:data
    }
}

export const  WriteToUsSuccess = (data) => {
    return {
        type: types.WRITE_TO_US_SUCCESS,
        data:data,
    }
}

export const  WriteToUsFailure = (data) => {
    return {
        type: types.WRITE_TO_US_FAILURE,
        data:data,
    }
}