import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/City/cityActionType";
import * as actions from "../../Action/City/viewAllCityAction";
import NetworkService from "../../../network/Network/City/cityNetwork";

export function* viewAllCity() {
  try {
    let response = yield call(NetworkService.viewAllCity);
    yield put(actions.viewAllCitySuccess(response))
  } catch (err) {
    yield put(actions.viewAllCityFailure(err));
  }
}

export function* watchViewAllCity() {
  yield takeLatest(types.VIEW_ALL_CITY_REQUEST, viewAllCity);
}