import { combineReducers } from "redux";
import { getPopularPackageHomeScreenReducer } from "./getPopularPackageHomeScreenReducer";
import { getPopularPackageReducer } from "./getPopularPackageReducer";
import { getPopularPlanHomeScreenReducer } from "./getPopularPlanHomeScreenReducer";
import { getPopularPlanReducer } from "./getPopularPlanReducer";
import { getPopularTestHomeScreenReducer } from "./getPopularTestHomeScreenReducer";
import { getPopularTestReducer } from "./getPopularTestReducer";
import { newsLetterSignupReducer } from "./newsLetterSignupReducer";

export default combineReducers({
    getPopularPackageHomeScreen: getPopularPackageHomeScreenReducer,
    getPopularTestHomeScreen: getPopularTestHomeScreenReducer,
    newsLetterSignup: newsLetterSignupReducer,
    getPopularPlanHomeScreen: getPopularPlanHomeScreenReducer,
    getPopularPackage: getPopularPackageReducer,
    getPopularPlan: getPopularPlanReducer,
    getPopularTest: getPopularTestReducer,
})