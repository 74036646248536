import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/User/userActionType";
import * as actions from "../../Action/User/UploadFamilyPicture";
import NetworkService from "../../../network/Network/User/userNetwork";

export function* UploadFamilyPictureSaga(action) {
  try {
    let response = yield call(NetworkService.UploadFamilyPicture, action);
    yield put(actions.UploadFamilyPicSuccess(response));
  } catch (err) {
    yield put(actions.UploadFamilyPicFailure(err));
  }
}

export function* watchUploadFamilyPictureSaga() {
  yield takeLatest(types.UPLOAD_FAMILY_PICTURE_REQUEST, UploadFamilyPictureSaga);
}