import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { GetRelationsRequest } from "../../../redux/Action/HealthRiskAssessment/GetRelationsAction";
import { AddRelationRequest } from "../../../redux/Action/User/AddRelationAction";
import Swal from "sweetalert2";

import Cross from "../../../assets/MyAccount/cross_button.png";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import storage from "../../../Utils/storage";

function RelationshipModal(props) {
  const dispatch = useDispatch();

  const handleClose = () => {
    props.open(false);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    dispatch(GetRelationsRequest(props?.state));
  }, []);

  const GetRelations = useSelector((state) => state.hra.getRelations);

  const AddRelation = useSelector((state) => state.user.addRelation);

  const relationSchema = Yup.object().shape({
    Name: Yup.string().trim().required("Required Field"),
    Age: Yup.string().required("Required Field"),
    Relationship: Yup.string().required("Required Field"),
  });

  useEffect(() => {
    if (check && AddRelation?.data?.data?.status === 201) {
      Swal.fire({
        title: AddRelation?.data?.data?.data?.message,
        icon: "success",
        customClass: {
          container: "custom-swal-container",
        },
      }).then(() => {
        setIsSubmitting(false);
        props.sendDataToParent(props.state); // Call the function passed down from the parent and pass the data
        props.open(false);
      });
    }
    if (check && AddRelation.error?.data?.response?.status === 400) {
      Swal.fire({
        title: AddRelation?.error?.data?.response?.data?.errorMessage,
        icon: "error",
      }).then(() => {});
    }
  }, [AddRelation]);
  const [check, setCheck] = useState(false);

  const handleSubmit = (values, event) => {
    setIsSubmitting(true);
    setCheck(true);
    dispatch(AddRelationRequest(values));
  };

  return (
    <>
      <div>
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="mainAddAddressModal"
        >
          <Box className="RealtionshipmodalDetails">
            <div className="orderDetailsHead">
              <div className="orderDetailsTitle">
                Add New Member
                <br />
              </div>
              <img
                src={Cross}
                className="orderDetailsCross"
                onClick={handleClose}
                alt="cross"
              />
            </div>

            <Formik
              initialValues={{
                Name: "",
                Age: "",
                Relationship: "",
              }}
              enableReinitialize={true}
              validationSchema={relationSchema}
              onSubmit={handleSubmit}
            >
              {
                <Form>
                  <div>
                    <div className="SelectDetail">
                      <div className="RealtionshipMainHeadingText">Name</div>
                      <label>
                        <Field
                          type="text"
                          name="Name"
                          label="Select Gender"
                          placeholder="Name"
                          className="Relationmodalageinput"
                        ></Field>
                        <ErrorMessage
                          className="RealtionshipMainValidation"
                          name="Name"
                          component="div"
                        />
                      </label>
                    </div>

                    <div className="SelectDetail">
                      <div className="RealtionshipMainHeadingText">Age</div>
                      <label>
                        <Field
                          type="number"
                          name="Age"
                          placeholder="Age"
                          label="Select Gender"
                          className="Relationmodalageinput"
                        ></Field>
                        <ErrorMessage
                          className="RealtionshipMainValidation"
                          name="Age"
                          component="div"
                        />
                      </label>
                    </div>

                    <div className="SelectDetail">
                      <div className="RealtionshipMainHeadingText">
                        Relationship
                      </div>
                      <label>
                        <Field
                          as="Select"
                          name="Relationship"
                          placeholder="Relationship"
                          label="Select Relationship"
                          className="Relationmodalselect"
                        >
                          <option
                            disabled
                            selected
                            value=""
                            label="Relationship"
                          >
                            Select type{" "}
                          </option>

                          {GetRelations?.data?.data?.data?.data?.map(
                            (item, index) => (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            )
                          )}
                        </Field>
                        <ErrorMessage
                          className="RealtionshipMainValidation"
                          name="Relationship"
                          component="div"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="RealtionshipModalSubmit">
                    <button
                      className="RealtionshipModalSubmitBtn"
                      disabled={isSubmitting}
                    >
                      Add Member
                    </button>
                  </div>
                </Form>
              }
            </Formik>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default RelationshipModal;
