import * as types from "../../Config/ERM/MedicalRecordListingConfig"

export const medicalRecordFormRequest = (data) => {
    return {
        type: types.MEDICAL_RECORD_FORM_REQUEST,
        data:data
    }
}

export const medicalRecordFormSuccess = (data) => {
    return {
        type: types.MEDICAL_RECORD_FORM_SUCCESS,
        data:data,
    }
}

export const medicalRecordFormFailure = (data) => {
    return {
        type: types.MEDICAL_RECORD_FORM_FAILURE,
        data:data,
    }
}