import RequestService from "../../RequestService";
import { api } from "../../../helpers/Banner/BannerConstants";

class NetworkService {
  getUserBanner(action) {
    const endPoint = `${api.getUserBanner}?position=${action.position}&screenType=${action.screenType}`;
    return RequestService.getRequest(endPoint)
  }
}

export default new NetworkService();
