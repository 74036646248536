import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const SaveReportRequest = (data) => {
    return {
        type: types.SAVE_REPORT_REQUEST,
        data:data
    }
}

export const  SaveReportSuccess = (data) => {
    return {
        type: types.SAVE_REPORT_SUCCESS,
        data:data,
    }
}

export const  SaveReportFailure = (data) => {
    return {
        type: types.SAVE_REPORT_FAILURE,
        data:data,
    }
}