import * as types from "../../Config/Landing/landingActionType"

export const getPopularPackageHomeScreenRequest = (data) => {
    return {
        type: types.GET_POPULAR_PACKAGE_HOMESCREEN_REQUEST,
        data:data
    }
}

export const getPopularPackageHomeScreenSuccess = (data) => {
    return {
        type: types.GET_POPULAR_PACKAGE_HOMESCREEN_SUCCESS,
        data:data,
    }
}

export const getPopularPackageHomeScreenFailure = (data) => {
    return {
        type: types.GET_POPULAR_PACKAGE_HOMESCREEN_FAILURE,
        data:data,
    }
}