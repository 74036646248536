import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/User/userActionType";
import * as actions from "../../Action/User/forgetPasswordAction";
import NetworkService from "../../../network/Network/User/userNetwork";

export function* ForgetPasswordSaga(action) {
  try {
    let response = yield call(NetworkService.forgetPasswordService, action);
    yield put(actions.userForgetPasswordSuccess(response));
  } catch (err) {
    yield put(actions.userForgetPasswordFailure(err));
  }
}

export function* watchForgetPasswordSaga() {
  yield takeLatest(types.FORGET_PASSWORD_REQUEST, ForgetPasswordSaga);
}