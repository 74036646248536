import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import storage from "../../../Utils/storage";
import { PageKeys } from "../../../Utils/testPackageFlag";
import { getCartRequest } from "../../../redux/Action/Cart/GetCartAction";
import CorporateProgramPage from "../../Organisms/ClientsIntro/CorporateProgramPage";
import OurPlan from "../../Organisms/PopularPlans/OurPlan";
import HealthCheckupLanding from "../HealthCheckUpBundle/HealthCheckupLanding";
import FeatureDiagnostics from "../HealthCheckups/FeatureDiagnostics";
import FeatureHealthheckup from "../HealthCheckups/FeatureHealthCheckup";
import ProductHub from "../Products/ProductHub";
import HealthAdvisorPage from "../ServicesOffered/HealthAdvisorPage";
import Services from "../ServicesOffered/Services";
import "./LandingPage.css";
import { GetProfileRequest } from "../../../redux/Action/User/GetProfileAction";

function LandingPage() {
  const [cart, setCart] = useState();
  const [showModal, setShowModal] = useState(false);
  const [bannerDataTypeA, setBannerDataTypeA] = useState([]);
  const [bannerDataTypeB, setBannerDataTypeB] = useState([]);
  const [bannerDataTypeC, setBannerDataTypeC] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetProfileRequest());
  }, [dispatch]);


  const GetProfile = useSelector((state) => state.user.getProfile);

  storage.removeStorage("PurchasePlanUuid");

  localStorage.setItem(
    "viewEvery",
    JSON.stringify({
      radioItem: "NO_RANGE",
      minPrice: 0,
      maxPrice: 10000,
      allChecked: [],
      radiology: false,
    })
  );

  useEffect(() => {
    dispatch(getCartRequest());
  }, []);

  useEffect(() => {
    if (GetProfile?.data?.data?.data?.data?.kycIsVerified !== undefined) {
      localStorage.setItem("kycVerification", GetProfile.data.data.data.data.kycIsVerified);
    }
  }, [GetProfile?.data]);
  
  useEffect(() => {
    const modalShown = Cookies.get("modalShown") === "true" ? true : false;
    if (!modalShown && showModal === false) {
      Cookies.set("modalShown", "true", { expires: 1 });
      setShowModal(!modalShown);
    }
  }, [showModal]);

  const getCart = useSelector((state) => state.cart.getCart);
  const userBanner = useSelector((state) => state.banner.getUserBanner);

  useEffect(() => {
    if (userBanner?.data[PageKeys.BANNER_TYPE_A]?.data?.status === 200) {
      let ApiData = userBanner.data[PageKeys.BANNER_TYPE_A]?.data.data.data;
      if (
        userBanner?.data[PageKeys.BANNER_TYPE_A]?.data.data.data
          ?.innerBannerResponseDto[0]?.id !== bannerDataTypeA[0]?.id
      ) {
        setBannerDataTypeA((prevData) => {
          if (ApiData && ApiData.innerBannerResponseDto) {
            let tempdata = [...prevData];
            tempdata[ApiData.position - 1] = ApiData.innerBannerResponseDto;
            return tempdata;
          }
          return prevData;
        });
      }
    }
    if (userBanner.data[PageKeys.BANNER_TYPE_B]?.data.status === 200) {
      if (
        userBanner.data[PageKeys.BANNER_TYPE_B]?.data.data.data
          .innerBannerResponseDto[0]?.id !== bannerDataTypeB[0]?.id
      ) {
        setBannerDataTypeB([
          ...bannerDataTypeB,
          ...userBanner.data[PageKeys.BANNER_TYPE_B]?.data.data.data
            .innerBannerResponseDto,
        ]);
      }
    }
    if (userBanner.data[PageKeys.BANNER_TYPE_C]?.data.status === 200) {
      if (
        userBanner.data[PageKeys.BANNER_TYPE_C]?.data.data.data
          .innerBannerResponseDto[0]?.id !== bannerDataTypeC[0]?.id
      ) {
        setBannerDataTypeC([
          ...bannerDataTypeC,
          ...userBanner.data[PageKeys.BANNER_TYPE_C]?.data.data.data
            .innerBannerResponseDto,
        ]);
      }
    }
  }, [userBanner.data]);

  useEffect(() => {
    setCart(getCart.data?.data.data.data);
  }, [getCart]);

  if (cart?.itemDtoList.length > 0) {
    storage.setStorage("itemDtoList", JSON.stringify(cart.itemDtoList));
  } else {
    storage.removeStorage("itemDtoList");
  }

  return (
    <div className="landing">
      <Helmet>
        <title>Complete Body Checkup & Health Packages |YUVA</title>
        <meta
          name="description"
          content="Ensure your well-being with comprehensive medical check-up packages. Get a complete body checkup and stay ahead of health issues."
        />
        <meta
          name="keywords"
          content="comprehensive medical check up package
          Health checkup packages
          complete body checkup"
        />
      </Helmet>

      <HealthAdvisorPage
        position={1}
        screenType={"LANDING_SCREEN"}
        mapData={bannerDataTypeA[0]}
      />
      <Services />
      <ProductHub />
      <CorporateProgramPage />
      <OurPlan />
      <FeatureHealthheckup />
      {/* <TypeABanner
        position={2}
        screenType={"LANDING_SCREEN"}
        mapData={bannerDataTypeA[1]}
      /> */}
      <FeatureDiagnostics />
      <HealthCheckupLanding />
      {/* <LandingDownload /> */}
      {/* <Footer /> */}
    </div>
  );
}
export default LandingPage;
