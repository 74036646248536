import { all } from "redux-saga/effects";
import { watchGetPopularPackageHomeScreenSaga } from "./getPopularPackageHomeScreenSaga";
import { watchGetPopularPlanSaga } from "./getPopularPlanSaga";
import { watchGetPopularTestSaga } from "./getPopularTestSaga";
import { watchJoinNetworkSaga } from "./joinNetworkSaga";
import { watchNewsletterSignupSaga } from "./newsletterSignupSaga";
import { watchGetPopularPlanHomeScreenSaga } from "./getPopularPlanHomeScreenSaga"
import { watchGetPopularTestHomeScreenSaga } from "./getPopularTestHomeScreenSaga"
import { watchGetPopularPackageSaga } from "./getPopularPackageSaga";

function* rootLandingSaga() {
    yield all([
        watchGetPopularPackageHomeScreenSaga(),
        watchGetPopularPlanSaga(),
        watchGetPopularTestSaga(),
        watchJoinNetworkSaga(),
        watchNewsletterSignupSaga(),
        watchGetPopularPlanHomeScreenSaga(),
        watchGetPopularTestHomeScreenSaga(),
        watchGetPopularPackageSaga()
    ])
}

export default rootLandingSaga;