import * as types from "../../Config/User/userActionType"

export const logoutUserRequest = (fcmToken,refreshToken,bool) => {
    return {
        type: types.LOGOUT_USER_REQUEST,
        fcmToken,
        refreshToken,
        bool
    }
}

export const logoutUserSuccess = (data) => {
    return {
        type: types.LOGOUT_USER_SUCCESS,
        data:data,
    }
}

export const logoutUserFailure = (data) => {
    return {
        type: types.LOGOUT_USER_FAILURE,
        data:data,
    }
}