import React from "react";
import { useNavigate } from "react-router-dom";
import MyAccountBanner from "../../../assets/MyAccount/MyAccountBanner.png";
import myAccountDesign from "../../../assets/MyAccount/myAccountDesign.png";
import myAccountPic1 from "../../../assets/MyAccount/myAccountP1.png";
import myAccountPic2 from "../../../assets/MyAccount/myAccountP2.png";
import myAccountPic3 from "../../../assets/MyAccount/myAccountP3.png";
import myAccountPic4 from "../../../assets/MyAccount/myAccountP4.png";
import storage from "../../../Utils/storage";
import Title from "../../Atoms/Title/Title";
import FeatureDiagnostics from "../HealthCheckups/FeatureDiagnostics";
import FeatureHealthCheckup from "../HealthCheckups/FeatureHealthCheckup";
import "./MyAccount.css";

function MyAccount() {
  const navigate = useNavigate();

  if (storage.getStorage("token") === null) {
    navigate("/");
  }

  return (
    <>
      <Title title="Yuva | My Account"></Title>

      <div className="myAccountBody">
        <div
          className="myAccountSubBody"
          style={{
            backgroundImage: `url(${myAccountDesign})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="myAccountGrid">
            <a href="/account-settings">
              <div className="myAccountbox1">
                <div className="myAccountGridHeading">My Account</div>
                <div className="myAccountIconsArea">
                  <img
                    alt="Settings"
                    src={myAccountPic1}
                    width="48"
                    border="none"
                    height="45"
                    className="myAccountPic"
                  />
                </div>
              </div>
            </a>
            <a href="/under-construction">
              <div className="myAccountbox1">
                {" "}
                <div className="myAccountGridHeading">Payment Options</div>
                <div className="myAccountIconsArea">
                  <img
                    alt="Cash Icon"
                    src={myAccountPic2}
                    width="48"
                    border="none"
                    height="45"
                    className="myAccountPic"
                  ></img>
                </div>
              </div>
            </a>

            <a href="/account-add-family-member">
              <div className="myAccountbox1">
                {" "}
                <div className="myAccountGridHeading">Family Details</div>
                <div className="myAccountIconsArea">
                  <img
                    alt="Family Icon"
                    src={myAccountPic3}
                    width="48"
                    border="none"
                    height="45"
                    className="myAccountPic"
                  ></img>
                </div>
              </div>
            </a>

            <a href="/account/saved-address">
              <div className="myAccountbox1">
                {" "}
                <div className="myAccountGridHeading">Address Book</div>
                <div className="myAccountIconsArea">
                  <img
                    alt="Location"
                    src={myAccountPic4}
                    width="48"
                    border="none"
                    height="45"
                    className="myAccountPic"
                  ></img>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage: `url(${MyAccountBanner})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
        className="myAccountBanner"
      >
        <div className="MyAccountOpdMasking1" />
      </div>

      {/* <MyAccountHealthCheckup /> */}

      {/* <MyAccountDiagnostics /> */}

      <FeatureDiagnostics />

      <FeatureHealthCheckup />
    </>
  );
}
export default MyAccount;
