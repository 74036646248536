import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const GetQuestionSection1Request = (data) => {
    return {
        type: types.GET_QUESTION_SECTION1_REQUEST,
        data:data
    }
}

export const  GetQuestionSection1Success = (data) => {
    return {
        type: types.GET_QUESTION_SECTION1_SUCCESS,
        data:data,
    }
}

export const  GetQuestionSection1Failure = (data) => {
    return {
        type: types.GET_QUESTION_SECTION1_FAILURE,
        data:data,
    }
}