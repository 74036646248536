import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Cross from "../../../assets/MyAccount/cross_button.png";
import "./AddAddressModal.css";

function AddAddressModal(props) {
  const navigate = useNavigate();

  const addressSchema = Yup.object().shape({
    line1: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    pincode: Yup.string().required("Required"),
    receiverName: Yup.string().required("Required"),
    contactNumber: Yup.string()
      .required("Required")
      .length(10, "Contact Number is not valid"),
  });

  const viewAllCity = useSelector((state) => state.city.viewAllCity);

  const handleSubmit = (values) => {
    const cityName = viewAllCity.data.data.data.data[values.city - 1].name;

    if (window.location.pathname === "/cart-checkout-plan") {
      navigate("/cart-payment-plan", {
        state: {
          genderEnum: props.location?.state?.genderEnum,
          PatientName: props.location?.state?.PatientName,
          age: props.location?.state?.age,
          address: values.line1,
          cityId: values.city,
          contactNumber: values.contactNumber,
          pinCode: values.pincode,
          plan: true,
          cityName: cityName,
          contactName: values.receiverName,
          away: values.away,
        },
      });
    } else {
      props.addressOpen(false);
      props.newSavedAddress({
        genderEnum: props.location?.state?.genderEnum,
        PatientName: props.location?.state?.PatientName,
        age: props.location?.state?.age,
        relationId: props.location.state.relationId,
        address: values.line1,
        cityId: values.city,
        contactNumber: values.contactNumber,
        pinCode: values.pincode,
        plan: false,
        cityName: cityName,
        contactName: values.receiverName,
        away: values.away,
        index: -1,
      });
    }
  };

  const handleClose = () => {
    props.addressOpen(false);
  };

  return (
    <div>
      <Modal
        open={props.addressOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainAddAddressModal"
      >
        <Box className="addAddressStyle">
          <div className="addAddressHead">
            <div className="addAddressTitle">Add New Address</div>
            <img
              src={Cross}
              className="addAddressCross"
              onClick={handleClose}
              alt="Cross"
            />
          </div>
          <Formik
            initialValues={{
              line1: "",
              city: "",
              pincode: "",
              receiverName: "",
              contactNumber: "",
              away: false,
            }}
            enableReinitialize={true}
            validationSchema={addressSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <div className="addressGrid">
                  <div className="addAddressModal">
                    <div className="addAddressText">Address</div>

                    <div className="addAddressInput">
                      <Field
                        as="textarea"
                        name="line1"
                        rows={5}
                        style={{ resize: "vertical" }}
                        placeholder="Address"
                        className="addAddressInputSelectFieldModal"
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="line1"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className="addAddressModal">
                    <div className="addAddressText">City</div>

                    <div className="addAddressInput">
                      <Field
                        as="select"
                        name="city"
                        className="addAddressInputSelectFieldModal"
                      >
                        <option value="" disabled>
                          Select City{" "}
                        </option>
                        {viewAllCity.data?.data.data.data.map((itm) => (
                          <option value={itm.id}>{itm.name}</option>
                        ))}
                      </Field>
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="city"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className="addAddressModal">
                    <div className="addAddressText">Pin Code</div>
                    <div className="addAddressInput">
                      <Field
                        type="text"
                        name="pincode"
                        placeholder="Pincode"
                        className="addAddressInputSelectFieldModal"
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="pincode"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className="addAddressModal">
                    <div className="addAddressText">Receiver Name</div>
                    <div className="addAddressInput">
                      <Field
                        type="text"
                        name="receiverName"
                        placeholder="Name"
                        className="addAddressInputSelectFieldModal"
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="receiverName"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className="addAddressModal">
                    <div className="addAddressText">Contact Number</div>
                    <div className="addAddressInput">
                      <Field
                        type="number"
                        name="contactNumber"
                        placeholder="Contact Number"
                        className="addAddressInputSelectFieldModal"
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="contactNumber"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className="addAddressModal">
                    <div className="addAddressText">Save As</div>

                    <div className="addAddressInput">
                      <Field
                        as="select"
                        name="away"
                        placeholder="Save As"
                        className="addAddressInputSelectFieldModal"
                      >
                        <option value={false}>Home</option>
                        <option value={true}>Away</option>
                      </Field>
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="away"
                        component="div"
                      />
                    </div>
                  </div>
                </div>
                <div className="addAddressModalButton">
                  <button type="submit">Save Details</button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default AddAddressModal;
