import * as types from "../../Config/Network/networkActionType"

export const diagnosticNetworkFormRequest = (data) => {
    return {
        type: types.DIAGNOSTIC_NETWORK_FORM_REQUEST,
        data:data
    }
}

export const diagnosticNetworkFormSuccess = (data) => {
    return {
        type: types.DIAGNOSTIC_NETWORK_FORM_SUCCESS,
        data:data,
    }
}

export const diagnosticNetworkFormFailure = (data) => {
    return {
        type: types.DIAGNOSTIC_NETWORK_FORM_FAILURE,
        data:data,
    }
}