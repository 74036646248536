import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../../Action/HealthRiskAssessment/GetSectionAnswers/GetSection4AnswersAction";
import NetworkService from "../../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* GetSection4AnswersSaga(action) {
  try {
    let response = yield call(NetworkService.GetSection4Answers, action);
    yield put(actions.GetSection4AnswersSuccess(response));
  } catch (err) {
    yield put(actions.GetSection4AnswersFailure(err));
  }
}

export function* watchGetSection4AnswersSaga() {
  yield takeLatest(types.GET_SECTION_4_ANSWERS_REQUEST, GetSection4AnswersSaga);
}
