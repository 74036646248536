import * as types from "../../Config/Network/networkActionType"

export const hospitalNetworkFormRequest = (data) => {
    return {
        type: types.HOSPITAL_NETWORK_FORM_REQUEST,
        data:data
    }
}

export const hospitalNetworkFormSuccess = (data) => {
    return {
        type: types.HOSPITAL_NETWORK_FORM_SUCCESS,
        data:data,
    }
}

export const hospitalNetworkFormFailure = (data) => {
    return {
        type: types.HOSPITAL_NETWORK_FORM_FAILURE,
        data:data,
    }
}