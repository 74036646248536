import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchBanner2 from "../../../assets/SearchPage/SearchBanner2.png";
import { elasticSearchResultRequest } from "../../../redux/Action/ElasticSearch/ElasticSearchResultAction";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import Title from "../../Atoms/Title/Title";
import Listing from "../../Organisms/Listing/Listing";
import ProductList from "../Products/ProductList";
import "./SearchPage.css";

function SearchPage() {
  const dispatch = useDispatch();

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const item = params.get("item");
  const result = params.get("result");
  const productTypeEnum = params.get("productTypeEnum");
  const uuid = params.get("uuid");
  const name = params.get("name");

  const [pageNoTest, setPageNoTest] = useState(1);
  const [pageSizeTest, setPageSizeTest] = useState(4);
  const [pageNoPackage, setPageNoPackage] = useState(1);
  const [pageSizePackage, setPageSizePackage] = useState(4);

  const elasticSearch = useSelector((state) => state.elasticSearchResult);

  useEffect(() => {
    if (uuid) {
      const data = {
        item: uuid,
        search: false,
        pageSizeForTest: pageSizeTest,
        pageSizeForPackage: pageSizePackage,
        pageSizeProduct: 4,
        productTypeEnum: productTypeEnum,
      };
      dispatch(elasticSearchResultRequest(data));
    } else {
      const data = {
        item: false,
        search: result,
        pageSizeForTest: pageSizeTest,
        pageSizeForPackage: pageSizePackage,
        pageSizeProduct: 4,
      };
      dispatch(elasticSearchResultRequest(data));
    }
  }, [
    result,
    item,
    pageSizeTest,
    pageSizePackage,
    productTypeEnum,
    uuid,
    dispatch,
  ]);

  function pageSizeIncreseTest(e) {
    if (e === "clicked") {
      setPageNoTest(pageNoTest + 1);
      setPageSizeTest(pageSizeTest * 2);
    }
  }

  function pageSizeIncresePackage(e) {
    if (e === "clicked") {
      setPageNoPackage(pageNoPackage + 1);
      setPageSizePackage(pageSizePackage * 2);
    }
  }

  const packageLength =
    elasticSearch.data?.data.data.data.packages.testPackageResponseDtoList
      .length;

  const productLength =
    elasticSearch.data?.data.data.data.productListResponseDtoGridView
      .productResponseDtoForUserGridViewList.length;

  const testLength =
    elasticSearch.data?.data.data.data.tests.testPackageResponseDtoList.length;

  return (
    <>
      <Title title="Yuva | Search result" />
      <>
        <div className="SearchPageBanner">
          <div className="SearchBannerGroup">
            <div className="SearchHeading">
              <div className="searchHeadingText1">
                {" "}
                {result ? result : name}
              </div>
              <div className="searchHeadingText2">Search Results</div>
            </div>
            <div
              className="searchBanner2"
              style={{
                backgroundImage: `url(${SearchBanner2})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100%",
              }}
            ></div>
          </div>
        </div>

        {testLength > 0 || packageLength > 0 || productLength > 0 ? (
          <div>
            {elasticSearch.isLoading ? (
              <div Style={{ marginTop: "-10vw" }}>
                <YuvaLoader />
              </div>
            ) : (
              <div>
                {testLength > 0 && (
                  <div className="searchTestBlock">
                    <div className="SearchTestHeading">Test</div>
                    <Listing
                      mapData={
                        elasticSearch.data?.data.data.data.tests
                          .testPackageResponseDtoList
                      }
                      pageSizeIncreseTest={pageSizeIncreseTest}
                      searchpageTest={"searchpageTest"}
                      pageNo={pageNoTest}
                      totalPages={
                        elasticSearch.data?.data.data.data.tests.totalPages
                      }
                    />
                  </div>
                )}
                {packageLength > 0 && (
                  <div className="searchTestBlock">
                    <div className="SearchTestHeading">Packages</div>
                    <Listing
                      mapData={
                        elasticSearch.data?.data.data.data.packages
                          .testPackageResponseDtoList
                      }
                      pageSizeIncresePackage={pageSizeIncresePackage}
                      searchpageTest={"searchpagepackage"}
                      pageNo={pageNoTest}
                      totalPages={
                        elasticSearch.data?.data.data.data.packages.totalPages
                      }
                    />
                  </div>
                )}
                {productLength > 0 && (
                  <div className="searchTestBlock">
                    <div className="SearchTestHeading">Products</div>
                    {/* {elasticSearch.data?.data.data.data.productListResponseDtoGridView.productResponseDtoForUserGridViewList.map((item) => ( */}

                    <ProductList
                      product={
                        elasticSearch?.data?.data.data.data
                          .productListResponseDtoGridView
                          .productResponseDtoForUserGridViewList
                      }
                    />
                    {/* ))} */}
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="noRecords"> No Records Found</div>
        )}
      </>
    </>
  );
}

export default SearchPage;
