import axiosRequest from "../Utils/instance";

class RequestService {
  postRequest(endPoints, object) {
    return axiosRequest.post(endPoints, object);
  }

  putRequest(endPoints, object) {
    return axiosRequest.put(endPoints, object);
  }

  deleteRequest(endPoints) {
    return axiosRequest.delete(endPoints);
  }

  getRequest(endPoints) {
    return axiosRequest.get(endPoints);
  }
  getFileRequest(endPoints) {
    return axiosRequest.get(endPoints, {
      responseType: "arraybuffer",
    });
  }
}

export default new RequestService();
