import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Packages/PackageLabTestActionType";
import * as actions from "../../Action/Packages/PackageSelectAction";
import NetworkService from "../../../network/Network/Packages/PackageLabTestNetwork";

export function* packageSelectSaga(action) {
  try {
    let response = yield call(NetworkService.packageSelectTest, action);
    yield put(actions.packageSelectSuccess(response));
  } catch (err) {
    yield put(actions.packageSelectFailure(err));
  }
}

export function* watchpackageSelectSaga() {
  yield takeLatest(types.PACKAGE_SELECT_REQUEST, packageSelectSaga);
}
