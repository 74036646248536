import * as types from "../../Config/Account/GetDocumentTypeActionType";

export const GetDocumentTypeRequest = () => {
  return {
    type: types.GET_DOCUMENT_TYPE_REQUEST,
  };
};

export const GetDocumentTypeSuccess = (data) => {
  return {
    type: types.GET_DOCUMENT_TYPE_SUCCESS,
    data,
  };
};

export const GetDocumentTypeFailure = (data) => {
  return {
    type: types.GET_DOCUMENT_TYPE_FAILURE,
    data,
  };
};
