import { all } from "redux-saga/effects";
import { watchGetQuestionSection1Saga } from "./GetSectionQuestion/GetQuestionSection1Saga";
import { watchGetQuestionSection2Saga } from "./GetSectionQuestion/GetQuestionSection2Saga";
import { watchGetQuestionSection3Saga } from "./GetSectionQuestion/GetQuestionSection3Saga";
import { watchGetQuestionSection4Saga } from "./GetSectionQuestion/GetQuestionSection4Saga";
import { watchGetQuestionSection5Saga } from "./GetSectionQuestion/GetQuestionSection5Saga";
import { watchGetQuestionSection6Saga } from "./GetSectionQuestion/GetQuestionSection6Saga";
import { watchGetQuestionSection7Saga } from "./GetSectionQuestion/GetQuestionSection7Saga";
import { watchGetQuestionSection8Saga } from "./GetSectionQuestion/GetQuestionSection8Saga";
import { watchGetQuestionSection9Saga } from "./GetSectionQuestion/GetQuestionSection9Saga";
import { watchGetQuestionSectionSaga } from "./GetSectionQuestion/GetQuestionSectionSaga";


function* rootGetSectionQuestionSaga() {
    yield all([
        watchGetQuestionSection1Saga(),
        watchGetQuestionSection2Saga(),
        watchGetQuestionSection3Saga(),
        watchGetQuestionSection4Saga(),
        watchGetQuestionSection5Saga(),
        watchGetQuestionSection6Saga(),
        watchGetQuestionSection7Saga(),
        watchGetQuestionSection8Saga(),
        watchGetQuestionSection9Saga(),
        watchGetQuestionSectionSaga()
    ])
}

export default rootGetSectionQuestionSaga;