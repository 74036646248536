import SearchIcon from "@mui/icons-material/Search";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import {
  Box,
  InputAdornment,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import storage from "../../../Utils/storage";
import myAccountDesign from "../../../assets/MyAccount/myAccountDesign.png";
import { viewMedicalRecordRequest } from "../../../redux/Action/ERM/MedicalRecordListingAction";
import { downloadErmReportRequest } from "../../../redux/Action/ERM/downloadErmReportAction";
import { medicalRecordDropdownRequest } from "../../../redux/Action/ERM/medicalRecordDropdownAction";
import Title from "../../Atoms/Title/Title";
import "./medicalRecordListing.css";

function MedicalRecordListing() {
  const [searchPage, setSearchPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      viewMedicalRecordRequest(page, pageSize, searchText, selectedValue)
    );
  }, [page, pageSize, searchText, selectedValue, dispatch]);

  useEffect(() => {
    dispatch(medicalRecordDropdownRequest());
  }, [dispatch]);

  const viewInUsers = useSelector((state) => state.Erm.medicalRecordDropdown);

  const handleSelection = (event) => {
    const selectedOption = event.target.value;
    setSelectedValue(selectedOption);
    dispatch(viewMedicalRecordRequest(1, pageSize, searchText, selectedOption));
    setPage(1);
  };

  const viewAllUsers = useSelector((state) => state.Erm.viewMedicalRecord);

  function handlePrint(id) {
    dispatch(downloadErmReportRequest(id));
    if (
      downloadErmReport?.data?.data.status === 200 &&
      downloadErmReport?.data?.data.data !== ""
    ) {
      const contentDisposition =
        downloadErmReport?.data?.data?.headers["content-disposition"];
      const filename = contentDisposition
        .split(";")
        .find((param) => param.trim().startsWith("attachment-filename="))
        .split("=")[1]
        .trim();
      swal({
        title: "File Downloaded Successfully",
        icon: "success",
      }).then(() => {
        const url_1 = window.URL.createObjectURL(
          new Blob([downloadErmReport?.data?.data.data], {
            type: "application/octet-stream",
          })
        );
        const link = document.createElement("a");
        link.href = url_1;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });
    }
  }

  const downloadErmReport = useSelector((state) => state.Erm.downloadErmReport);

  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Id",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
    },
    {
      field: "documentDate",
      headerClassName: "super-app-theme--header",
      headerName: "Date",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.6,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["documentDate"]).toLocaleDateString()}
            </Typography>
          </>
        );
      },
    },
    {
      field: "medicalDocument",
      headerClassName: "super-app-theme--header",
      headerName: "Name of the Document",
      minWidth: 60,
      disableColumnMenu: true,
      sortable: false,
      flex: 1.2,
    },

    {
      field: "uploadedDate",
      headerClassName: "super-app-theme--header",
      headerName: "Date Of Upload",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.8,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["uploadedDate"]).toLocaleDateString()}
            </Typography>
          </>
        );
      },
    },
    {
      field: "healthCenter",
      headerClassName: "super-app-theme--header",
      headerName: "HealthCare Center",
      minWidth: 60,
      disableColumnMenu: true,
      sortable: false,
      flex: 1.2,
      valueFormatter: (params) => {
        return params.value !== null ? params.value : "-";
      },
    },

    {
      field: "Download",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <div>
            <SystemUpdateAltIcon
              sx={{ color: "#e68d36", marginLeft: 1.5, cursor: "pointer" }}
              onClick={() => handlePrint(cellValues.row.id)}
            />
          </div>
        );
      },
    },
  ];

  const handleSearch = (e) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        viewMedicalRecordRequest(page, pageSize, searchText, selectedValue)
      );
      setPage(1);
    } else {
      dispatch(
        viewMedicalRecordRequest(
          searchPage,
          pageSize,
          e.target.value,
          selectedValue
        )
      );
    }
    setSearchText(e.target.value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleDropChange = (event) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        viewMedicalRecordRequest(
          1,
          event.target.value,
          searchText,
          selectedValue
        )
      );
    } else {
      dispatch(
        viewMedicalRecordRequest(
          searchPage,
          event.target.value,
          searchText,
          selectedValue
        )
      );
    }
    setPage(1);
    setPageSize(event.target.value);
  };
  const handlePageChange = (event, value) => {
    if (searchText === "" || searchText.length <= 1) {
      dispatch(
        viewMedicalRecordRequest(value, pageSize, searchText, selectedValue)
      );
      setPage(value);
    } else {
      setSearchPage(value);
      dispatch(
        viewMedicalRecordRequest(value, pageSize, searchText, selectedValue)
      );
    }

    // dispatch(viewAllUsersRequest(value, pageSize, selectedValue));
  };

  function calculateAge(dateOfBirth) {
    const today = new Date();
    const dob = new Date(dateOfBirth);
    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }

    return age;
  }

  if (storage.getStorage("token") === null) {
    navigate("/");
  }

  return (
    <>
      <Title title="Yuva | EMRM" />

      <div
        className=""
        style={{
          backgroundImage: `url(${myAccountDesign})`,
          backgroundSize: "131% 29%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="ermsMainDiv">
          <div className="ermsText">
            <p className="ermsTexting">Electronic Record Management System</p>
          </div>
          <div className="ErmsTextNameAndAge">
            <div className="ErmsTextName">
              Name: {viewAllUsers?.data?.data?.data?.name}
            </div>
            <div className="ErmsTextAge">
              Age:{" "}
              {viewAllUsers?.data?.data?.data?.dob === null ? (
                "--"
              ) : (
                <>
                  {calculateAge(new Date(viewAllUsers?.data?.data?.data?.dob))}
                </>
              )}
            </div>
          </div>
          <hr />
          <div className="ErmsTextNameAndAges">
            <div className="dropdownEmr">
              <select
                className="requestCiting"
                value={selectedValue}
                onChange={handleSelection}
              >
                <option value="">All</option>
                {viewInUsers.data?.data?.data?.data?.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="SearchBarEmr">
              {/* <div className="corporatesearchBar"> */}
              <TextField
                type="text"
                name="search"
                onChange={handleSearch}
                variant="outlined"
                className="emrmSearch"
                placeholder="Search"
                size="small"
                hiddenLabel="true"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: "#52608E" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <hr />
          <div>
            <Box sx={{ height: 400, width: "100%", marginTop: "2%" }}>
              {viewAllUsers?.data?.data?.data?.ermResponseDtoList && (
                <DataGrid
                  sx={{
                    color: "#1D2334",
                    "& .super-app-theme--header": {
                      backgroundColor: "#1C71E1",
                      color: "#F2EFEA",
                      width: "min-content",
                      "& > .MuiDataGrid-columnSeparator": {
                        visibility: "hidden",
                      },
                    },
                    ".MuiTablePagination-toolbar": {
                      alignItems: "baseline",
                      marginTop: "5%",
                    },
                  }}
                  getRowId={(row) => row.id}
                  pagination
                  rowCount={viewAllUsers?.data?.data.data.totalRecords}
                  rows={viewAllUsers?.data?.data?.data?.ermResponseDtoList}
                  disableColumnMenu
                  columns={columns}
                  rowsPerPageOptions={[10, 50, 100]}
                  components={{
                    Footer: "none",
                  }}
                />
              )}
            </Box>

            <div className="MainTablePagination">
              <p className="SeeRows">Rows Per Page:&nbsp;&nbsp;&nbsp;&nbsp;</p>
              <select
                className="dropDown"
                defaultValue={10}
                onChange={handleDropChange}
              >
                <option value={10}>10</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              <Pagination
                page={
                  searchText === "" || searchText.length <= 1
                    ? page
                    : searchPage
                }
                count={viewAllUsers?.data?.data?.data?.totalPages}
                color="primary"
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MedicalRecordListing;
