import { Input } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import myAccountDesign from "../../../assets/MyAccount/myAccountDesign.png";
import { medicalRecordDropdownRequest } from "../../../redux/Action/ERM/medicalRecordDropdownAction";
import { medicalRecordFormRequest } from "../../../redux/Action/ERM/medicalRecordFormRequestAction";
import storage from "../../../Utils/storage";
import Title from "../../Atoms/Title/Title";
import "./MedicalRecordForm.css";

function MedicalRecordForm() {
  const loginSchema = Yup.object().shape({
    healthCentre: Yup.string().required("It is a required field"),
    name: Yup.string().required("It is a required field"),
    date: Yup.string().required("It is a required field"),
  });

  const [selectedValue, setSelectedValue] = useState("");
  const [error, setError] = useState(false);
  const [fileRateList, setFileRateList] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileSelectionError, setFileSelectionError] = useState(false);
  const [fileRateListError, setFileRateListError] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(medicalRecordDropdownRequest());
  }, [dispatch]);

  const viewInUsers = useSelector((state) => state.Erm.medicalRecordDropdown);
  const medicalRecord = useSelector((state) => state.Erm.medicalRecordForm);
  const currentDate = new Date().toISOString().split("T")[0];

  const handleSelection = (event) => {
    const selectedOption = event.target.value;
    setSelectedValue(selectedOption);
    setError(selectedOption === "");
  };

  const handleFileRateListChange = (event) => {
    if (
      event.target.files[0].type === "application/pdf" ||
      event.target.files[0].type === "image/png" ||
      event.target.files[0].type === "image/jpeg"
    ) {
      setFileRateList(event.target.files[0]);
      setFileRateListError(false);
      setFileSelectionError(false);
    } else {
      setFileRateListError(false);
      setFileSelectionError(true);
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (selectedValue === "") {
      setError(true);
    } else {
      setError(false);
    }
    setIsSubmitting(true);

    const epochDate = values.date ? new Date(values.date).getTime() : null;

    if (fileRateList === null) {
      setFileRateListError(true);
      return;
    }

    setFileRateListError(false);

    if (fileSelectionError === true) {
      setFileSelectionError(true);
      setFileRateListError(false);
      return;
    }

    setFileSelectionError(false);
    const ermsRequest = {
      medicalDocument: values.name,
      healthCentre: values.healthCentre,
      date: epochDate,
      documentType: selectedValue,
    };

    const formData = new FormData();
    formData.append("ermRequest", JSON.stringify(ermsRequest));
    formData.append("medicalDocument", fileRateList);
    if (selectedValue !== "") {
      dispatch(medicalRecordFormRequest(formData));
    }
  };

  useEffect(() => {
    if (
      medicalRecord?.data?.data.status === 201 &&
      medicalRecord?.data?.data.data.message !== ""
    ) {
      Swal.fire({
        title: "Success",
        text: `${medicalRecord?.data?.data.data.message}`,
        icon: "success",
      }).then(() => {
        setIsSubmitting(false);
        window.location.href = "/Medical-Record-Listing";
      });
    }

    if (
      (medicalRecord.data?.data.status !== 201 &&
        medicalRecord.data === undefined) ||
      medicalRecord.error !== null
    ) {
      Swal.fire({
        title:
          "" + Object.values(medicalRecord?.error?.data?.response?.data)[0],
        icon: "error",
      });
    }
  }, [medicalRecord]);

  if (storage.getStorage("token") === null) {
    navigate("/");
  }

  function waitForLoad(id, callback) {
    var timer = setInterval(function () {
      if (
        document.getElementById("fileUploadInput") &&
        (document.getElementsByClassName("file-upload-select-ERM")[0] ||
          document.getElementsByClassName("file-upload-select-ERM-Mobile")[0])
      ) {
        clearInterval(timer);
        callback();
      }
    }, 200);
  }

  waitForLoad("subm", function () {
    let fileInput = document.getElementById("fileUploadInput");
    fileInput.onchange = function () {
      let filename = fileInput.files[0]?.name;
      let selectName = document.getElementsByClassName(
        "file-select-name-ERM-mob"
      )[0];
      selectName.innerText = filename;
    };
  });
  return (
    <>
      <Title title="Yuva | EMRM" />

      <div
        className=""
        style={{
          backgroundImage: `url(${myAccountDesign})`,
          backgroundSize: "131% 29%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="ermsMainDiv">
          <div className="ermsText">
            <p className="ermsTexting">Electronic Record Management System</p>
          </div>
          <div className="ermsForm">
            <div className="ermsFormHeading">Create Medical Record</div>
            <Formik
              initialValues={{
                name: "",
                healthCentre: "",
                date: "",
                documentType: "",
              }}
              enableReinitialize={true}
              validationSchema={loginSchema}
              onSubmit={handleSubmit}
            >
              {(formProps) => (
                <Form>
                  <div className="ErmsNewFormInput">
                    <label>
                      <select
                        name="documentType"
                        className="ErmsNewFormInputField"
                        value={selectedValue}
                        onChange={handleSelection}
                      >
                        <option value="">Select</option>
                        {viewInUsers.data?.data?.data?.data?.map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.name}
                          </option>
                        ))}
                      </select>
                    </label>
                    {error && (
                      <div className="errorMessageERM">
                        Please select a value.
                      </div>
                    )}

                    <label>
                      <div className="ErmsNewFormInputFieldDate">
                        <p>Date of Medical Document</p>
                        <Field
                          id="dateInput"
                          placeholder=" Date Of Upload"
                          name="date"
                          type="date"
                          max={currentDate}
                          className="DateUploadText"
                        />
                      </div>
                      <ErrorMessage
                        className="ErmsNewFormInputFieldErrorMessage"
                        name="date"
                        component="div"
                      />
                    </label>

                    <label>
                      <Field
                        placeholder="Name of Medical Document"
                        name="name"
                        type="text"
                        className="ErmsNewFormInputField"
                      />

                      <ErrorMessage
                        name="name"
                        className="ErmsNewFormInputFieldErrorMessage"
                        component="div"
                      />
                    </label>
                    <label>
                      <Field
                        placeholder="Health Center Name"
                        name="healthCentre"
                        type="text"
                        className="ErmsNewFormInputField"
                      />

                      <ErrorMessage
                        name="healthCentre"
                        className="ErmsNewFormInputFieldErrorMessage"
                        component="div"
                      />
                    </label>
                    <label>
                      <div class="file-upload-ERM">
                        <div class="file-upload-select-ERM">
                          <div class="file-select-button-ERM">
                            Upload Medical Document
                          </div>
                          <div className="upladoffile-ERM">
                            <Input
                              type="file"
                              name="RateList"
                              id="fileUploadInputs"
                              accept="application/pdf,image/png, image/jpeg"
                              className="upladoffile-ERMINPUT"
                              onChange={(event) =>
                                handleFileRateListChange(event)
                              }
                            />
                          </div>

                          <div class="file-select-name-ERM">
                            Supported format PDF,PNG and JPEG
                          </div>
                        </div>
                        {fileRateListError && (
                          <div className="errorFileERM">
                            Please Select only PDF,PNG or JPEG file{" "}
                          </div>
                        )}
                      </div>
                      {fileSelectionError && (
                        <div className="errorFileERM">
                          Please select only PDF,PNG or JPEG file.
                        </div>
                      )}

                      {fileRateList?.size / 1024 > 10240 && (
                        <div className="FileSizedisableERM">
                          {" "}
                          File Size should be less than 10mb
                        </div>
                      )}
                      {fileRateListError && (
                        <div className="FileSizedisableERM">
                          {" "}
                          Select the file
                        </div>
                      )}
                    </label>
                  </div>
                  <div className="emrmButtonArea">
                    <button
                      type="submit"
                      className="ermsSubmitButton"
                      disabled={
                        isSubmitting ||
                        fileSelectionError ||
                        fileRateListError ||
                        fileRateList?.size / 1024 > 10240 === true
                      }
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default MedicalRecordForm;
