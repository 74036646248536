import * as types from "../../Config/Account/ApplyForKYCActionType";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const ApplyForKYCReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.APPLY_FOR_KYC_SUCCESS:
      return {
        ...initialState,
        data: action,
      };
    case types.APPLY_FOR_KYC_FAILURE:
      return {
        ...initialState,
        error: action,
      };
    case types.APPLY_FOR_KYC_REQUEST:
      return {
        ...initialState,
        isLoading: true,
      };
    default:
      return state;
  }
};
