import { all } from "redux-saga/effects";
import { watchTellAboutYourselfPageSaga } from "./TellAboutYourselfPage";


function* rootTellAboutYourselfSaga() {
    yield all([
        watchTellAboutYourselfPageSaga(),
    ])
}

export default rootTellAboutYourselfSaga;