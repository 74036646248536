import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { viewAllAddressRequest } from "../../../redux/Action/Address/viewAllAddressAction";
import AddAddressModal from "./AddAddressModal";
import "./Checkout.css";
import storage from "../../../Utils/storage";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import { Helmet } from "react-helmet";
import CartOrderSummaryImmutable from "../../Organisms/Cart/CartOrderSummaryImmutable";
import SelectMember from "./SelectMember.js";
import SelectDateAndTime from "./SelectDateAndTime";
import FinalPaymentPage from "./FinalPaymentPage";

function Checkout() {
  const [address, setAddress] = useState();
  const [addAddress, setAddAddress] = useState(false);
  const [radioItem, setRadioItem] = useState();
  const [cod, setCod] = useState(false);
  const [memberInfo, setMemberInfo] = useState();
  const [time, setTime] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setdata] = useState({});

  const viewAllAddress = useSelector((state) => state.address.viewAllAddress);
  const getCart = useSelector((state) => state.cart.getCart);

  useEffect(() => {
    dispatch(viewAllAddressRequest());
  }, []);

  function memberDetail(name, age, gender, relation, other) {
    setMemberInfo({
      name: name,
      age: age,
      gender: gender,
      relation: relation,
      other: other,
    });
  }
  function newSavedAddress(data) {
    setdata(data);
    setAddress(-1);
  }
  function DateAndTime(someDate) {
    setTime(someDate);
  }

  function SelectPaymentMethod(method) {
    setCod(method);
  }

  const handleAddAddress = () => {
    setAddAddress(true);
  };

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      setRadioItem(data);
    }
  }, [data]);

  const handleRadio = (event, value) => {
    setRadioItem(value);
    setAddress(event);
  };
  var state = {
    PatientName: memberInfo?.name,
    cod: cod,
    address: radioItem?.address,
    age: memberInfo?.age,
    cityId: radioItem?.cityId,
    cityName: radioItem?.cityName,
    contactName: radioItem?.contactName,
    contactNumber: radioItem?.contactNumber,
    genderEnum:
      memberInfo?.gender === null
        ? null
        : memberInfo?.gender === "Male"
        ? "MALE"
        : "FEMALE",
    other: memberInfo?.other,
    pinCode: radioItem?.pinCode,
    plan: false,
    relationId: memberInfo?.relation,
    haveProduct: location?.state?.haveProduct,
    timeSlot: time,
    value: {
      name: memberInfo?.name,
      age: memberInfo?.age,
      gender: memberInfo?.gender,
      relation: memberInfo?.relation,
    },
  };
  if (
    location.state === null ||
    getCart.error !== null ||
    storage.getStorage("token") === null
  ) {
    navigate("/");
  } else {
    return (
      <div className="Cartmain">
        <Helmet>
          <title>Yuva Health - Cart</title>
          <meta
            name="description"
            content="Review and manage your selections with ease at YUVA Health's cart page.
         Experience seamless checkout and personalized service for all your healthcare needs"
          />
        </Helmet>
        <ScrollToTop />
        <div className="AddressSelectArea">
          <div className="AddressList">
            {addAddress && (
              <AddAddressModal
                addressOpen={setAddAddress}
                location={location}
                newSavedAddress={newSavedAddress}
              />
            )}
            <div className="AddressListUpper">
              <p className="AddressListUpperHeading">Select Address</p>
              <button
                type="button"
                className="AddressListUpperButton"
                onClick={handleAddAddress}
              >
                Add New
              </button>
            </div>
            {Object.keys(data).length !== 0 && (
              <div
                className="AddressListLower"
                onClick={(event) => {
                  handleRadio(-1, data);
                }}
              >
                <div className="AddressListLower1">
                  <input
                    type="radio"
                    checked={address == data?.index}
                    value={-1}
                    onChange={() => {
                      handleRadio(-1, data);
                    }}
                  />
                </div>
                <div className="AddressListLower2">
                  <div className="AddressListLower2Name">
                    {data.contactName}
                  </div>
                  <div className="AddressListLower2Address">
                    {data.address}, {data.cityName}, {data.pinCode}
                  </div>
                </div>
                <div className="AddressListLower3">
                  <div className="AddressListLower3Location">
                    {data.away === false ? "Home" : "Office"}
                  </div>
                  <div className="AddressListLower2Address">
                    {data.contactNumber}
                  </div>
                </div>
              </div>
            )}
            <div className="AddressListLowerParent">
              {viewAllAddress?.data?.data.data.data.map((item, index) => (
                <div
                  className="AddressListLower"
                  value={index}
                  onClick={(event) => {
                    handleRadio(index, item);
                  }}
                >
                  <div className="AddressListLower1">
                    <input
                      type="radio"
                      value={index}
                      checked={address == index}
                      onChange={() => {
                        handleRadio(index, item);
                      }}
                    />
                  </div>
                  <div className="AddressListLower2">
                    <div className="AddressListLower2Name">
                      {item.contactName}
                    </div>
                    <div className="AddressListLower2Address">
                      {item.address}, {item.cityName}, {item.pinCode}
                    </div>
                  </div>
                  <div className="AddressListLower3">
                    <div className="AddressListLower3Location">
                      {item.away === false ? "Home" : "Office"}
                    </div>
                    <div className="AddressListLower2Address">
                      {item.contactNumber}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {!location?.state?.haveProduct ? (
            <div>
              <SelectMember memberDetail={memberDetail} />
            </div>
          ) : (
            <></>
          )}
          {!location?.state?.haveProduct ? (
            <div>
              <SelectDateAndTime DateAndTime={DateAndTime} />
            </div>
          ) : (
            <></>
          )}

          {getCart.data?.data.data.data.cartPriceResponseDto.amountToBePaid >
            0 && (
            <div>
              <FinalPaymentPage SelectPaymentMethod={SelectPaymentMethod} />
            </div>
          )}
        </div>
        <div>
          <CartOrderSummaryImmutable state={state} />
        </div>
      </div>
    );
  }
}

export default Checkout;
