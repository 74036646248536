import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/OTP/OTP/otpActionType";
import * as actions from "../../../Action/OTP/OTP/otpAction";
import NetworkService from "../../../../network/Network/OTP/OTP/otpNetwork";

export function* generateOtp(action) {
  try {
    let response = yield call(NetworkService.generateOtp, action);
    yield put(actions.otpRequestSuccess(response));
  } catch (err) {
    yield put(actions.otpRequestFailure(err));
  }
}

export function* watchGenerateOtp() {
  yield takeLatest(types.OTP_REQUEST, generateOtp);
}