import React from "react";
import underline from "../../../assets/LandingPage_Services/underline.png";
import Globe from "../../../assets/Network/globe.png";
import "./networkHero.css";

export default function NetworkHero() {
  return (
    <div className="networkHero">
      <div className="networkHeroLeft">
        <div className="networkHeroText">
          <div className="networkHeroTextTitle">
            <p>Connecting Care,</p>
            <p>Empowering Communities:</p>
            <img
              className="networkHeroTextTitleImg"
              src={underline}
              alt="underline"
            />
          </div>
          <p className="networkHeroTextDesc">
            Your Pathway to Comprehensive Healthcare
          </p>
        </div>
      </div>
      <div className="networkHeroRight">
        <img src={Globe} alt="Globe" />
      </div>
    </div>
  );
}
