import { all } from "redux-saga/effects";
import { watchViewAllYuvaClinic } from "./viewAllYuvaClinicSaga";


function* rootYuvaClinicSaga() {
    yield all([
        watchViewAllYuvaClinic(),
    ])
}

export default rootYuvaClinicSaga;
