import React from "react";
import { useNavigate } from "react-router-dom";
import myAccountDesign from "../../../assets/MyAccount/myAccountDesign.png";
import FinalPaymentNewImage from "../../../assets/cart/FinalPaymentNewImage.png";
import Title from "../../Atoms/Title/Title";
import { useLocation } from 'react-router-dom';
import "./ConfirmPayment.css";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import storage from "../../../Utils/storage";

const ConfirmPayment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { confirmPaymentFlag } = location.state || {};
  const handleClick = () => {
    navigate("/").then(()=>{
      return <ScrollToTop /> ;
       
    });
  };

  if (storage.getStorage("itemDtoList") !== null) {
    storage.removeStorage("itemDtoList");
    window.dispatchEvent(new Event('storage'))
  }

  return (
    <div>
      <ScrollToTop />
      <Title title="Yuva | Payment Success" />
      <div
        className=""
        style={{
          backgroundImage: `url(${myAccountDesign})`,
          backgroundSize: "131% 29%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="NewFailedPayment">
          <div className="CompleteCartMain">
            <div className="CompleteCart1">
              <img className="CompleteImage" src={FinalPaymentNewImage} alt="doctor testing patient"></img>
              {confirmPaymentFlag === true ? <div className="CompleteTextConfirm">
                Thank You<br></br> we have received your order
              </div> :
                <div className="CompleteTextConfirm">
                  Thank You<br></br> we have received your payment
                </div>}


              <div className="CompleteMessageText">
                {confirmPaymentFlag === true ? <div>
                  We have received your booking request . One of our executive will confirm the same.</div>
                  : <div>
                    Thank you for your purchase and payment. One of our customer
                    service executives will be reaching out to you shortly.{" "}
                    <br></br> They will confirm the details of your chosen plan
                    and address any questions or concerns you may have. If you
                    need <br></br>any further assistance . Please feel free to call
                    us on<br></br>
                    <div className="justcolor">7619166998</div>
                  </div>}

              </div>
              <button className="NewButtonFailed" onClick={handleClick}>
                Back To Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPayment;
