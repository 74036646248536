export const api = {
  getPlanDetails: "/plan",
  getPlanFullDetails: "/plan/amount",
  getPlanTypeDropdown:"/plan/dropdown",
  getPlanRequestCall:"/crm/call-request",
  planLock:"/programAndPlan/lock",
  getSelectedPlan:"/plan/single",
  getPlanServices:"/plan/services",
  getPlanServicesDetails: "/plan/user/details",

};
