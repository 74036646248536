import * as types from "../../Config/User/userActionType"

export const userForgetPasswordRequest = (data) => {
    return {
        type: types.FORGET_PASSWORD_REQUEST,
        data:data
    }
}

export const userForgetPasswordSuccess = (data) => {
    return {
        type: types.FORGET_PASSWORD_SUCCESS,
        data:data,
    }
}

export const userForgetPasswordFailure = (data) => {
    return {
        type: types.FORGET_PASSWORD_FAILURE,
        data:data,
    }
}