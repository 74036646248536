import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Email from "../../../assets/Login/emailLogoLogin.png";
import eye from "../../../assets/Login/eye.png";
import { generateEmailOtpRequest } from "../../../redux/Action/OTP/generateEmailOtpAction";
import logo from "../../../assets/common/logoPrimaryNavbar.png";

function CorporateSignUpInfo({ empEmail, setEmpInfo }) {
  const registerSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    password: Yup.string()
      .min(6, "Should be 6 character minimum.")
      .required("No password provided"),
    retypePassword: Yup.string()
      .oneOf([Yup.ref("password"), null], 'Must match "password" field value')
      .min(6, "Should be 6 character minimum.")
      .required("No password provided."),
  });

  const dispatch = useDispatch();
  const [eye1, setEye1] = useState(false);
  const [eye2, setEye2] = useState(false);

  const handleSubmit = (values) => {
    setEmpInfo(values);
    dispatch(generateEmailOtpRequest(values.email, values.name));
  };

  function visiblePassword() {
    setEye1(!eye1);
  }

  function visibleRetypePassword() {
    setEye2(!eye2);
  }

  function preventnumberinput(e) {
    var keycode = e.keycode ? e.keycode : e.which;
    if ((keycode > 47 && keycode < 58) || (keycode > 95 && keycode < 107)) {
      e.preventDefault();
    }
  }

  const corporateTokenInfo = useSelector(
    (state) => state.corporateSignUp.corporateTokenInfo
  );

  return (
    <>
      
      <div className="signupArea">
      <div className="LogoAreaLOGIN_SIGNUP">
        <img src={logo} alt="Logo" />
      </div>
        <p className="signupAreaFirstHeading">Create Account</p>
        <p className="signupAreaSecondHeading">
          Create an account so you can explore
          <br /> all the existing jobs
        </p>
        
        <div className="signupForm">
          <Formik
            initialValues={{
              companyCode:
                corporateTokenInfo.data?.data.data.data.corporateName,
              email: empEmail,
              name: "",
              password: "",
              retypePassword: "",
            }}
            validationSchema={registerSchema}
            onSubmit={handleSubmit}
          >
            {() => {
              return (
                <Form className="SingleRegisterForm">
                  <div className="signUpBox">
                    <Field
                      type="text"
                      name="companyCode"
                      className="signupInputField"
                      disabled={true}
                      placeholder=" Company Code"
                    />
                  </div>
                  <div className="signUpBox">
                    <Field
                      type="text"
                      name="email"
                      placeholder="Mobile Number Or Email"
                      className="signupInputField"
                      disabled={true}
                    />
                    <ErrorMessage
                      className="errorMessage"
                      name="email"
                      component="div"
                    />
                  </div>
                  <div className="signUpBox">
                    <Field
                      type="text"
                      name="name"
                      placeholder="Name"
                      id="registerTextboxde1"
                      className="signupInputField"
                      onKeyDown={(event) => preventnumberinput(event)}
                    />
                    <ErrorMessage
                      className="errorMessage"
                      name="name"
                      component="div"
                    />
                  </div>

                  <div className="signUpBox">
                    <div style={{ position: "relative" }}>
                      {eye1 ? (
                        <Field
                          type="text"
                          name="password"
                          placeholder="Password"
                          id="registerTextboxde2"
                          className="signupInputField"
                        />
                      ) : (
                        <Field
                          type="password"
                          name="password"
                          placeholder="Password"
                          id="registerTextboxde2"
                          className="signupInputField"
                        />
                      )}

                      <ErrorMessage
                        className="errorMessage"
                        name="password"
                        component="div"
                      />

                      <img
                        src={eye}
                        onClick={visiblePassword}
                        className="useregEye"
                        id="eye1"
                        style={{
                          position: "absolute",
                          right: "10%",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                        alt="eye"
                      />
                    </div>
                  </div>
                  <div className="signUpBox">
                    <div style={{ position: "relative" }}>
                      {eye2 ? (
                        <Field
                          type="text"
                          name="retypePassword"
                          placeholder="Confirm Password"
                          className="signupInputField"
                          id="registerTextboxde3"
                        />
                      ) : (
                        <Field
                          type="Password"
                          name="retypePassword"
                          placeholder="Confirm Password"
                          className="signupInputField"
                          id="registerTextboxde3"
                        />
                      )}

                      <ErrorMessage
                        className="errorMessage"
                        name="retypePassword"
                        component="div"
                      />

                      <img
                        src={eye}
                        onClick={visibleRetypePassword}
                        id="eye2"
                        className="useregEye"
                        style={{
                          position: "absolute",
                          right: "10%",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                        alt="eye"
                      />
                    </div>
                  </div>

                  <button type="submit" className="signupInputFieldButton">
                    <img alt="Email" src={Email} />
                    &nbsp; Sign In
                  </button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CorporateSignUpInfo;
