import ClickAwayListener from "@mui/base/ClickAwayListener";
import { MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popper from "@mui/material/Popper";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import rec1 from "../../../assets/LandingpageCarousel/rec1.png";
import rec2 from "../../../assets/LandingpageCarousel/rec2.png";
import rec3 from "../../../assets/LandingpageCarousel/rec3.png";
import rec4 from "../../../assets/LandingpageCarousel/rec4.png";
import rec5 from "../../../assets/LandingpageCarousel/rec5.png";
import rec6 from "../../../assets/LandingpageCarousel/rec6.png";
import RightArrow from "../../../assets/common/RightArrow.png";
import { viewAllCityRequest } from "../../../redux/Action/City/viewAllCityAction";
import "./NavigtaionTabs.css";

export default function NavigationTabs() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popper" : undefined;

  const [tabs, setTabs] = React.useState({
    home: false,
    service: false,
    network: false,
    subscribe: false,
    company: false,
    product: false,
  });

  const handleClick1 = (event) => {
    setAnchorEl1(anchorEl1 ? null : event.currentTarget);
  };

  // const cityNames = [];

  // const viewCityNames = useSelector((state) => state.city.viewAllCity);

  // viewCityNames?.data?.data?.data.data?.map((item) => {
  //   cityNames.push(item.name);
  // });

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popper" : undefined;

  const handleClick2 = (event) => {
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
  };

  useEffect(() => {
    dispatch(viewAllCityRequest());
  }, [dispatch]);

  const clickaway = () => {
    setAnchorEl1(null);
  };
  const clickAway2 = () => {
    setAnchorEl2(null);
  };
  function handle1() {
    dispatch(viewAllCityRequest());
    navigate("/consultation/cashless-opd");
    setValue(1);
  }
  function handle2() {
    navigate("/talk-to-doctor");
    setValue(1);
  }
  function handle3() {
    navigate("/view-test");
    setValue(1);
  }
  function handle4() {
    if (localStorage.getItem("token") === null) {
      navigate("/health-risk-assessment");
      setValue(1);
    } else {
      navigate("/health-risk-assessment-locked");
    }
    setValue(1);
  }
  function handle5() {
    if (localStorage.getItem("token") === null) {
      navigate("/rental-equipment");
      setValue(1);
    } else if (localStorage.getItem("token") !== null) {
      navigate("/rental-equipment");
    }
  }
  function handle6() {
    navigate("/about-us");
    setValue(2);
  }
  function handle7() {
    navigate("/career");
    setValue(2);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleHomeClick = () => {
    navigate("/");
    setTabs({
      home: true,
      service: false,
      network: false,
      subscribe: false,
      company: false,
      mentalWelness: false,
      product: false,
    });
  };
  const handleNetworkClick = () => {
    navigate("/network");
    setTabs({
      home: false,
      service: false,
      network: true,
      subscribe: false,
      company: false,
      mentalWelness: false,
      product: false,
    });
  };
  const handleMentalWelnessClick = () => {
    navigate("/mental-wellness");
    setTabs({
      home: false,
      service: false,
      network: false,
      subscribe: false,
      company: false,
      mentalWelness: true,
      product: false,
    });
  };
  const handleSubscribeClick = () => {
    navigate("/subscription");
    setTabs({
      home: false,
      service: false,
      network: false,
      subscribe: true,
      company: false,
      mentalWelness: false,
      product: false,
    });
  };

  const handleProductClick = () => {
    navigate("/product");
    setTabs({
      home: false,
      service: false,
      network: false,
      subscribe: false,
      company: false,
      mentalWelness: false,
      product: true,
    });
  };

  return (
    <div className="navigationTabsMain">
      <div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "98%",
          }}
        >
          <div
            value={value}
            onChange={handleChange}
            className="navigationTabBody"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                className={tabs.home ? "tabNameTrue" : "tabName"}
                label="Home"
                onClick={() => {
                  handleHomeClick();
                }}
              >
                Home
              </Button>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ClickAwayListener onClickAway={clickaway}>
                <Button
                  className={tabs.service ? "tabNameTrue" : "tabName"}
                  aria-controls={open1 ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open1 ? "true" : undefined}
                  onClick={handleClick1}
                >
                  Services
                </Button>
              </ClickAwayListener>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                className={tabs.mentalWelness ? "tabNameTrue" : "tabName"}
                label="Network"
                onClick={() => handleMentalWelnessClick()}
              >
                Mental Wellness
              </Button>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                className={tabs.network ? "tabNameTrue" : "tabName"}
                label="Network"
                onClick={() => handleNetworkClick()}
              >
                Network
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                className={tabs.subscribe ? "tabNameTrue" : "tabName"}
                label="Subscribe"
                onClick={() => {
                  handleSubscribeClick();
                }}
              >
                Subscribe
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                className={tabs.product ? "tabNameTrue" : "tabName"}
                label="Network"
                onClick={() => handleProductClick()}
              >
                Products
              </Button>
            </div>

            <Popper id={id1} open={open1} anchorEl={anchorEl1}>
              <div className="NavbarGridContainer">
                <div>
                  <Box
                    className="BoxForPopper"
                    sx={{
                      p: 1,
                      bgcolor: "background.paper",
                      border: "hidden",
                      width: "49vw",
                    }}
                  >
                    <MenuItem
                      sx={{ marginTop: "1em", marginBottom: "1em" }}
                      onClick={() => {
                        navigate("/consultation/cashless-opd");
                        setValue(1);
                        setTabs({
                          home: false,
                          service: true,
                          network: false,
                          subscribe: false,
                          company: false,
                          mentalWelness: false,
                          product: false,
                        });
                      }}
                      onTouchStart={handle1}
                    >
                      <img
                        src={RightArrow}
                        className="arrowImage"
                        alt="arrow"
                      />{" "}
                      <span className="NavbarNames">
                        {" "}
                        OPD Consultation Program
                      </span>
                    </MenuItem>
                    <MenuItem
                      sx={{ marginTop: "1em", marginBottom: "1em" }}
                      onClick={() => {
                        navigate("/talk-to-doctor");
                        setValue(1);
                        setTabs({
                          home: false,
                          service: true,
                          network: false,
                          subscribe: false,
                          company: false,
                          mentalWelness: false,
                          product: false,
                        });
                      }}
                      onTouchStart={handle2}
                    >
                      <img
                        src={RightArrow}
                        className="arrowImage"
                        alt="arrow"
                      />
                      <span className="NavbarNames"> Talk to Doctor</span>
                    </MenuItem>
                    <MenuItem
                      sx={{ marginTop: "1em", marginBottom: "1em" }}
                      onClick={() => {
                        navigate("/view-test");
                        setValue(1);
                        setTabs({
                          home: false,
                          service: true,
                          network: false,
                          subscribe: false,
                          company: false,
                          mentalWelness: false,
                          product: false,
                        });
                      }}
                      onTouchStart={handle3}
                    >
                      <img
                        src={RightArrow}
                        className="arrowImage"
                        alt="arrow"
                      />{" "}
                      <span className="NavbarNames">
                        Health Checkup & Diagnostics
                      </span>
                    </MenuItem>
                    <MenuItem
                      sx={{ marginTop: "1em", marginBottom: "1em" }}
                      onClick={() => {
                        if (localStorage.getItem("token") === null) {
                          navigate("/health-risk-assessment");
                          setValue(1);
                          setTabs({
                            home: false,
                            service: true,
                            network: false,
                            subscribe: false,
                            company: false,
                            mentalWelness: false,
                            product: false,
                          });
                        } else {
                          navigate("/health-risk-assessment-locked");
                          setTabs({
                            home: false,
                            service: true,
                            network: false,
                            subscribe: false,
                            company: false,
                            mentalWelness: false,
                            product: false,
                          });
                        }
                      }}
                      onTouchStart={handle4}
                    >
                      <img
                        src={RightArrow}
                        className="arrowImage"
                        alt="arrow"
                      />{" "}
                      <span className="NavbarNames">
                        Health Risk Assessment
                      </span>
                    </MenuItem>
                    <MenuItem
                      sx={{ marginTop: "1em", marginBottom: "1em" }}
                      onClick={() => {
                        if (localStorage.getItem("token") === null) {
                          navigate("/rental-equipment");
                          setValue(1);
                          setTabs({
                            home: false,
                            service: true,
                            network: false,
                            subscribe: false,
                            company: false,
                            mentalWelness: false,
                            product: false,
                          });
                        } else {
                          navigate("/rental-equipment");
                          setTabs({
                            home: false,
                            service: true,
                            network: false,
                            subscribe: false,
                            company: false,
                            mentalWelness: false,
                            product: false,
                          });
                        }
                      }}
                      onTouchStart={handle5}
                    >
                      <img
                        src={RightArrow}
                        className="arrowImage"
                        alt="arrow"
                      />{" "}
                      <span className="NavbarNames">Rental Equipment</span>
                    </MenuItem>
                    <MenuItem
                      sx={{ marginTop: "1em", marginBottom: "1em" }}
                      onClick={() => {
                        if (localStorage.getItem("token") === null) {
                          navigate("/EMRM");
                          setValue(1);
                          setTabs({
                            home: false,
                            service: true,
                            network: false,
                            subscribe: false,
                            company: false,
                            product: false,
                          });
                        } else {
                          navigate("/EMRM");
                          setTabs({
                            home: false,
                            service: true,
                            network: false,
                            subscribe: false,
                            company: false,
                            product: false,
                          });
                        }
                      }}
                      onTouchStart={handle5}
                    >
                      <img alt="Arrow" src={RightArrow} className="arrowImage" />{" "}
                      <span className="NavbarNames">EMRM</span>
                    </MenuItem>
                    {/* <MenuItem
                      sx={{ marginTop: "1em", marginBottom: "1em" }}
                      onClick={() => {
                          navigate("/yuva-clinic");
                          setValue(1);
                          setTabs({
                            home: false,
                            service: true,
                            network: false,
                            subscribe: false,
                            company: false,
                            mentalWelness: false,
                            product: false,
                          });
                        
                      }}
                      onTouchStart={handle4}
                    >
                      <img
                        src={RightArrow}
                        className="arrowImage"
                        alt="arrow"
                      />{" "}
                      <span className="NavbarNames">
                        Yuva Clinics
                      </span>
                    </MenuItem> */}
                  </Box>
                </div>
                <div className="NavbarGridContaineropt">
                  <>
                    <div>
                      <img alt="Rectangle" className="NavbarImages" src={rec1}></img>
                    </div>
                    <div>
                      <img alt="Rectangle" className="NavbarImages" src={rec2}></img>
                    </div>
                    <div>
                      <img alt="Rectangle" className="NavbarImages" src={rec3}></img>
                    </div>

                    <div>
                      <img alt="Rectangle" className="NavbarImages" src={rec4}></img>
                    </div>
                  </>
                </div>
              </div>
            </Popper>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ClickAwayListener onClickAway={clickAway2}>
                <Button
                  className={tabs.company ? "tabNameTrue" : "tabName"}
                  aria-controls={open2 ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? "true" : undefined}
                  onClick={handleClick2}
                >
                  Company
                </Button>
              </ClickAwayListener>
            </div>

            <Popper id={id2} open={open2} anchorEl={anchorEl2}>
              <div className="NavbarGridContainer">
                <div>
                  <Box
                    className="BoxForPopper2"
                    sx={{
                      p: 1,
                      bgcolor: "background.paper",
                      border: "hidden",
                    }}
                  >
                    <MenuItem
                      sx={{ marginTop: "1em", marginBottom: "1em" }}
                      onClick={() => {
                        navigate("/about-us");
                        setValue(2);
                        setTabs({
                          home: false,
                          service: false,
                          network: false,
                          subscribe: false,
                          company: true,
                          mentalWelness: false,
                          product: false,
                        });
                      }}
                      onTouchStart={handle6}
                    >
                      <img alt="Arrow" src={RightArrow} className="arrowImage" />{" "}
                      <span className="NavbarNames">About Us</span>
                    </MenuItem>
                    <MenuItem
                      sx={{ marginTop: "1em", marginBottom: "1em" }}
                      onClick={() => {
                        navigate("/career");
                        setValue(2);
                        setTabs({
                          home: false,
                          service: false,
                          network: false,
                          subscribe: false,
                          product: false,
                          company: true,
                          mentalWelness: false,
                        });
                      }}
                      onTouchStart={handle7}
                    >
                      <img alt="Arrow" src={RightArrow} className="arrowImage" />{" "}
                      <span className="NavbarNames">Career</span>
                    </MenuItem>
                  </Box>
                </div>

                <div className="NavbarGridContaineropt2">
                  <>
                    <div>
                      <img alt="Rectangle" className="NavbarImages" src={rec5}></img>
                    </div>
                    <div>
                      <img alt="Rectangle" className="NavbarImages" src={rec6}></img>
                    </div>
                  </>
                </div>
              </div>
            </Popper>
          </div>
        </Box>
      </div>
    </div>
  );
}
