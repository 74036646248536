import { combineReducers } from "redux";
import { getPlanDetailReducer } from "./getPlanDetailReducer";
import { getPlanFullDetailsReducer } from "./getPlanFullDetailsReducer";
import { getPlanRequestCallReducer } from "./getPlanRequestCallReducer";
import { getPlanTypeDropdownReducer } from "./getPlanTypeDropdownReducer";
import { getSelectedPlanReducer } from "./GetSelectedPlanReducer";
import { planLockReducer } from "./planLockReducer";
import { getPlanServicesReducer } from "./GetPlanServicesReducer";
import { getPlanServicesDetailReducer } from "./getPlanServicesUserDetailReducer";
export default combineReducers({
    getPlanDetail: getPlanDetailReducer,
    getPlanFullDetails: getPlanFullDetailsReducer,
    getPlanRequestCall: getPlanRequestCallReducer,
    getPlanTypeDropdown: getPlanTypeDropdownReducer,
    planLock: planLockReducer,
    getSelectedPlan:getSelectedPlanReducer,
    getPlanServices:getPlanServicesReducer,
    getPlanServicesDetail:getPlanServicesDetailReducer
})