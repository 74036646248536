import { all } from "redux-saga/effects";
import { WatchContinueSectionSaga } from "./ContinueSectionSaga";
import { WatchDeleteSectionAnswersSaga } from "./DeleteSectionAnswersSaga";
import { watchGetHraQuestionsSaga } from "./GetHraQuestionsSaga";
import { WatchGetLatestSectionSaga } from "./GetLatestSectionSaga";
import { watchGetRelationsSaga } from "./GetRelationsSaga";
import rootGetSectionAnswerSaga from "./rootGetSectionAnswerSaga";
import rootGetSectionQuestionSaga from "./rootGetSectionQuestionSaga";
import { WatchSaveReportSaga } from "./SaveReportSaga";
import { WatchSaveSectionAnswersSaga } from "./SaveSectionAnswersSaga";


function* rootHRASaga() {
    yield all([
        rootGetSectionAnswerSaga(),
        rootGetSectionQuestionSaga(),
        WatchContinueSectionSaga(),
        WatchDeleteSectionAnswersSaga(),
        watchGetHraQuestionsSaga(),
        WatchGetLatestSectionSaga(),
        watchGetRelationsSaga(),
        WatchSaveReportSaga(),
        WatchSaveSectionAnswersSaga(),
    ])
}

export default rootHRASaga;