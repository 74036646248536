import React from "react";

function MentalWellnessIframe({ url }) {
  return (
    <div style={{ height: "100vh" }}>
      <iframe src={url} title="Mental Wellness" width="100%" height="80%" />
    </div>
  );
}

export default MentalWellnessIframe;
