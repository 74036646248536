import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SearchBanner2 from "../../../assets/SearchPage/SearchBanner2.png";
import { lifestylePackageRequest } from "../../../redux/Action/Packages/LifestylePackageAction";
import Title from "../../Atoms/Title/Title";
import ScrollRestoration from "../../Molecules/ScrollToTop/ScrollRestoration";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import Listing from "../../Organisms/Listing/Listing";
import "./SearchPage.css";

function SearchPagePackage() {
  const dispatch = useDispatch();
  const { enumName, displayName } = useParams();

  const [pageNo, setPageNo] = useState(1);
  const [mapData, setMapData] = useState([]);
  const [totalPages, setTotalPages] = useState();

  useEffect(() => {
    let lifestylePackage = {
      pageNo: pageNo,
      pageSize: 4,
      packageEnum: enumName,
      fromWeb: true,
    };
    dispatch(lifestylePackageRequest(lifestylePackage));
  }, [pageNo, enumName, dispatch]);

  const lifestylePackage = useSelector(
    (state) => state.package.lifestylePackage
  );

  useEffect(() => {
    if (lifestylePackage.data?.data.status === 200) {
      const newTestPackageList =
        lifestylePackage?.data?.data?.data?.data?.testPackageResponseDtoList;
      if (newTestPackageList[0]?.id !== mapData[0]?.id) {
        setMapData((prevMapData) => [...prevMapData, ...newTestPackageList]);
      }
      setTotalPages(lifestylePackage?.data?.data?.data?.data?.totalPages);
      lifestylePackage.data = null;
    }
  }, [lifestylePackage,mapData]);

  return (
    <>
      <Title title="Yuva | Lifestyle Package" />
      <ScrollRestoration />
      <ScrollToTop />
      <>
        <div className="SearchPageBanner">
          <div className="SearchBannerGroup">
            <div className="SearchHeading">
              <div className="searchHeadingText2">{displayName}</div>
            </div>
            <div
              className="searchBanner2"
              style={{
                backgroundImage: `url(${SearchBanner2})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100%",
              }}
            ></div>
          </div>
        </div>
        <div className="searchPackageListing">
          <Listing
            totalPages={totalPages}
            mapData={mapData}
            setPageNo={setPageNo}
            pageNo={pageNo}
            LifestyleExpand={"LifestyleExpand"}
          />
        </div>
      </>
    </>
  );
}

export default SearchPagePackage;
