import React from "react";
import PharmacyImage from "../../../assets/PharmacyPage/PharmacyImage.png";
import Title from "../../Atoms/Title/Title";
import "./PharmacyPage.css";

function PharmacyPage() {
  return (
    <>
      <Title title="YUVA | Pharmacy" />
      <div className="pharmacyMain">
        <div className="pharmacyTextsPhoto">
          <div className="pharmacyText">
            Assists you in ordering your prescriptions right at your doorstep
          </div>
          <div>
            <img
              src={PharmacyImage}
              alt="Pharmacy"
              className="pharmacyImage"
            />
          </div>
        </div>
        <div className="pharmacyText2">
          At Yuva Health, we understand the importance of easy access to
          prescription medications. Our mission is to simplify your healthcare
          journey by offering a seamless way to order your prescriptions right
          at your doorstep through our subscription plan. With Yuava Health, you
          can enjoy peace of mind knowing that your essential medications are
          just a click away.
        </div>
        <div className="textAndCard">
          <div className="chooseSubscription">
            Why Choose Yuva Health's Prescription Subscription Plan?
          </div>
          <div className="allCards">
            <div className="cardContentDiv">
              <div className="cardText">Convenience</div>
              <div className="subCardText">
                Life can get busy, and sometimes picking up your prescriptions
                from a pharmacy can be a hassle. With Yuava Health, you can say
                goodbye to long queues and traffic jams. Our prescription
                subscription plan ensures that your medications are delivered
                directly to your door, saving you time and effort.
              </div>
            </div>
            <div className="cardContentDiv">
              <div className="cardText">Consistency</div>
              <div className="subCardText">
                Maintaining your medication regimen is crucial for your health.
                Our subscription plan helps you stay on track by ensuring you
                never run out of your prescribed medications. No more
                last-minute trips to the pharmacy or worrying about refills.
              </div>
            </div>
            <div className="cardContentDiv">
              <div className="cardText">Efficiency</div>
              <div className="subCardText">
                We believe that healthcare should be affordable. Our
                subscription plan offers competitive pricing, helping you save
                money on your prescription medications compared to traditional
                pharmacy prices. Plus, you'll avoid unnecessary expenses like
                transportation costs.
              </div>
            </div>
            <div className="cardContentDiv">
              <div className="cardText">Personalized Care</div>
              <div className="subCardText">
                We understand that each individual's healthcare needs are
                unique. Yuava Health provides a personalized experience,
                allowing you to manage your prescriptions online, track your
                medication history, and receive tailored reminders for refills
                and dosage schedules.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PharmacyPage;
