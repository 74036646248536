import * as types from "../../Config/User/userActionType"

export const userOtpMobileVerificationRequest = (data) => {
    return {
        type: types.OTP_MOBILE_VERIFICATION_REQUEST,
        data:data
    }
}

export const userOtpMobileVerificationSuccess = (data) => {
    return {
        type: types.OTP_MOBILE_VERIFICATION_SUCCESS,
        data:data,
    }
}

export const userOtpMobileVerificationFailure = (data) => {
    return {
        type: types.OTP_MOBILE_VERIFICATION_FAILURE,
        data:data,
    }
}