import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/User/userActionType";
import * as actions from "../../Action/User/UploadProfilePicture";
import NetworkService from "../../../network/Network/User/userNetwork";

export function* UploadProfilePictureSaga(action) {
  try {
    let response = yield call(NetworkService.UploadProfilePicture, action);
    yield put(actions.UploadProfilePicSuccess(response));
  } catch (err) {
    yield put(actions.UploadProfilePicFailure(err));
  }
}

export function* watchUploadProfilePictureSaga() {
  yield takeLatest(types.UPLOAD_PROFILE_PICTURE_REQUEST, UploadProfilePictureSaga);
}