import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/YuvaClinic/YuvaClinicActionType";
import * as actions from "../../Action/YuvaClinic/viewAllYuvaClinicAction";
import NetworkService from "../../../network/Network/YuvaClinic/YuvaClinicNetwork";

export function* viewAllYuvaClinic(action) {
  try {
    let response = yield call(NetworkService.viewAllYuvaClinic,action);
    yield put(actions.viewAllYuvaClinicSuccess(response))
  } catch (err) {
    yield put(actions.viewAllYuvaClinicFailure(err));
  }
}

export function* watchViewAllYuvaClinic() {
  yield takeLatest(types.VIEW_ALL_YUVA_CLINIC_REQUEST, viewAllYuvaClinic);
}