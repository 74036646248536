import { combineReducers } from "redux";
import { otpReducer } from "./OTP/otpReducer";
import { otpMobileReducer } from "./OTPMobile/otpMobileReducer";
import { otpValidationReducer } from "./OTPValidation/otpValidationReducer";
import { otpMobileValidationReducer } from "./OTPValidationMobile/otpMobileValidationReducer";
import { generateEmailOtpReducer } from "./generateEmailOtpReducer";
import { UpdateProfileOtpValidationReducer } from "./OTPValidation/UpdateProfileOtpValidationReducer";

export default combineReducers({
  otp: otpReducer,
  otpMobile: otpMobileReducer,
  validateOtp: otpValidationReducer,
  validateMobileOtp: otpMobileValidationReducer,
  generateEmailOtp: generateEmailOtpReducer,
  UpdateProfileOtpValidation: UpdateProfileOtpValidationReducer,
});
