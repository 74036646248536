import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import backArrow from "../../../assets/hra/backArrow.png";
import hra5 from "../../../assets/hra/hra6.png";
import { GetQuestionSection5Request } from "../../../redux/Action/HealthRiskAssessment/GetQuestionSection5Action";
import { GetSection5AnswersRequest } from "../../../redux/Action/HealthRiskAssessment/GetSectionAnswers/GetSection5AnswersAction";
import { Section5AnswersSuccess } from "../../../redux/Action/HealthRiskAssessment/HRAAnswers/Section5AnswersAction";
import { SaveSectionAnswersRequest } from "../../../redux/Action/HealthRiskAssessment/SaveSectionAnswersAction";
import storage from "../../../Utils/storage";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import SingleLogin from "../Login/SingleLogin";
import { dropDisabled, pickeryesno } from "./HraDropdowns";
import "./Sections.css";
import { Section5Schema2 } from "./ValidationSchemas";

function Section5() {
  const dispatch = useDispatch();
  const [smoker, setSmoker] = useState("");
  const SectionValue = localStorage.getItem("SectionValue");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(GetQuestionSection5Request());
    dispatch(GetSection5AnswersRequest());
  }, []);

  let age = localStorage.getItem("age");
  age = parseInt(age);

  const Section5Schema = Yup.object().shape({
    Q35: Yup.string().required("Required Field"),
    Q36: Yup.number()
      .integer("The age at which you started smoking must be an integer")
      .min(
        12,
        "The age at which you started smoking cannot be before you turned 12 years old"
      )
      .max(
        age,
        "The age at which you started smoking cannot be greater than your present age"
      )
      .required("Required Field"),
    Q37: Yup.number()
      .integer("Number of cigarettes should be an integer")
      .min(1, "Number of cigarettes should be greater than 0")
      .required("Required Field"),
    Q38: Yup.string().required("Required Field"),
  });

  const GetQuestionSection = useSelector(
    (state) => state.getQuestion.getQuestionSection5
  );
  const currentSectionValue = localStorage.getItem("SectionValue");
  const GetSectionAnswers = useSelector(
    (state) => state.getSectionAnswer.getSection5Answers
  );

  const [json, setJson] = useState();
  const [counter, setCounter] = useState(false);

  useEffect(() => {
    if (GetSectionAnswers?.data?.data?.data?.data !== undefined) {
      setJson(JSON.parse(GetSectionAnswers?.data?.data?.data?.data));
      setCounter(true);
    }
  }, [GetSectionAnswers?.data?.data?.data?.data]);
  useEffect(() => {
    if (json?.Q35.toString() === "1") {
      setSmoker("1");
    } else {
      setSmoker("0");
    }
  }, [json]);

  window.addEventListener("popstate", function (event) {
    storage.setStorage("SectionValue", 4);
  });

  const handleSubmit = (values, { setSubmitting }) => {
    localStorage.setItem("SectionValue", 6);
    if (values.Q35 === "0") {
      values.Q36 = "";
      values.Q37 = "";
    }
    dispatch(Section5AnswersSuccess(values));

    dispatch(SaveSectionAnswersRequest({ values, currentSectionValue }));
    navigate("/health-risk-assessment/section-6a");
  };

  const handlePrevious = () => {
    navigate("/health-risk-assessment/section-5b");
  };

  const handleSmoker = (values) => {
    if (values.target.value === "0") {
      setSmoker("0");
    } else {
      setSmoker("1");
    }
  };

  if (storage.getStorage("token") === null) {
    return <SingleLogin />;
  }

  if (GetQuestionSection.isLoading) {
    return <YuvaLoader />;
  }
  return (
    <>
      <ScrollToTop />

      <div className="HRAQuestionsMain">
        <div className="HRAQuestionsMainChild1">
          <p>Your Health Risk Assessment</p>
        </div>
        {SectionValue === "5" && (
          <div
            className="HRAQuestionsMainChild2"
            style={{ backgroundImage: `url(${hra5})` }}
          >
            <div className="HRAQuestionsMainChild2a">
              <div className="HRAQuestionsMainChild2a1">
                <p>GENERAL SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1 faded">
                <p>DIETARY SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1">
                <p>MENTAL HEALTH SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1 faded">
                <p>ALCOHOL RISK SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1">
                <p>SMOKING & TOBACCO SECTION</p>
              </div>
              {SectionValue === "5" && (
                <div className="HRAQuestionsMainChild2a2">
                  <Formik
                    initialValues={
                      !counter
                        ? {
                            Q35: "",
                            Q36: "",
                            Q37: "",
                            Q38: "",
                          }
                        : {
                            Q35: json?.Q35.toString(),
                            Q36: json?.Q36 === null ? "" : json?.Q36.toString(),
                            Q37: json?.Q37 === null ? "" : json?.Q37.toString(),
                            Q38: json?.Q38.toString(),
                          }
                    }
                    validationSchema={
                      smoker === "1" ? Section5Schema : Section5Schema2
                    }
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting, setFieldValue, values }) => {
                      return (
                        <Form>
                          <div className="HRAQuestionsMainChild2a2div">
                            <div className="hraBackArrow">
                              <img
                                src={backArrow}
                                onClick={handlePrevious}
                                alt="backArrow"
                              />
                              <p>Smoking and Tobacco Risk</p>
                            </div>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection?.data?.data?.data?.data[0]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q35"
                                // placeholder="Doctor Name"
                                // value={smoker}
                                className="select"
                                onChange={(e) => {
                                  setFieldValue("Q35", e.target.value);
                                  handleSmoker(e);
                                }}
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickeryesno.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q35"
                                component="div"
                              />
                            </label>

                            {smoker === "1" && (
                              <>
                                <label className="Q_A">
                                  <p>
                                    {
                                      GetQuestionSection?.data?.data?.data
                                        ?.data[1].question
                                    }
                                  </p>
                                  <Field
                                    type="number"
                                    name="Q36"
                                    className="select"
                                    onWheel={(event) =>
                                      event.currentTarget.blur()
                                    }
                                  />
                                  <ErrorMessage
                                    className="errorMessageValidation"
                                    name="Q36"
                                    component="div"
                                  />
                                </label>

                                <label className="Q_A">
                                  <p>
                                    {
                                      GetQuestionSection?.data?.data?.data
                                        ?.data[2].question
                                    }
                                  </p>
                                  <Field
                                    type="number"
                                    name="Q37"
                                    className="select"
                                    onWheel={(event) =>
                                      event.currentTarget.blur()
                                    }
                                  />
                                  <ErrorMessage
                                    className="errorMessageValidation"
                                    name="Q37"
                                    component="div"
                                  />
                                </label>
                              </>
                            )}
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection?.data?.data?.data?.data[3]
                                    .question
                                }
                              </p>
                              <Field as="select" name="Q38" className="select">
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickeryesno.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q38"
                                component="div"
                              />
                            </label>
                            <button
                              type="submit"
                              className="HRAQuestionsButton"
                            >
                              Next
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        )}
        {SectionValue !== "5" && (
          <div className="hraDenied">
            Not Authorized !! please navigate to previous sections
          </div>
        )}
      </div>
    </>
  );
}

export default Section5;
