import React from "react";
import { useNavigate } from "react-router";
import "./OurPlan.css";
import PopularPlans from "./PopularPlans";
function OurPlan() {
  const navigate=useNavigate();
  return (
    <>
      <div className="PlanLanding">
        <div className="PlanLandingUpper">
        <p className="PlanLandingHeading">
          Choose The Plan That Fits Your Needs.
        </p>
        <p onClick={()=>navigate("/subscription")}>View All</p>

        </div>
        <PopularPlans />
      </div>
    </>
  );
}

export default OurPlan;
