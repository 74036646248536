import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Cart/CreateCartActionType";
import * as actions from "../../Action/Cart/CreateCartAction";
import NetworkService from "../../../network/Network/Cart/CartNetwork";

export function* createCartSaga(action) {

  try {
    let response = yield call(NetworkService.createCart, action);
    yield put(actions.createCartSuccess(response));
  } catch (err) {
    yield put(actions.createCartFailure(err));
  }
}

export function* watchCreateCartSaga() {
  yield takeLatest(types.CREATE_CART_REQUEST, createCartSaga);
}