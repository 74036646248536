import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import storage from "../../../Utils/storage";
import signupImage from "../../../assets/Register/signupImage.png";
import Logo from "../../../assets/common/logoPrimaryFooter.png";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import "./SingleRegister.css";
import UserRegisterForm from "./UserRegisterForm";
import VerificationRegisterOTPScreen from "./VerificationRegisterOTPScreen";

function SingleRegister() {
  const navigate = useNavigate();

  const otpMobileData = useSelector((state) => state.otp.otpMobile);
  const [showMobileOtp, setShowMobileOtp] = useState(false);
  useEffect(() => {
    if (otpMobileData?.data?.data.data.status === true) {
      Swal.fire({
        title: " OTP Is Sent To Your Mobile Number Successfully",
        icon: "success",
      }).then(() => {
        setShowMobileOtp(true);
      });
    }
  }, [otpMobileData?.data?.data.data.status]);

  const [childData, setChildData] = useState(null);
  if (otpMobileData?.error?.data.response.status === 409) {
    Swal.fire({
      icon: "error",
      title: "You are Already Registered",
      text: "Redirecting to Login Page...",
    });
    setTimeout(() => {
      window.location.href = "/login";
    }, 3000);
  }
  if (otpMobileData.isLoading) {
    return <YuvaLoader />;
  }

  if (storage.getStorage("token") !== null) {
    navigate("/");
  }

  return (
    <div>
      <Helmet>
        <title>Yuva Health: Register </title>
        <meta name="description" content="Register with Yuva Health, your trusted healthcare provider, to access a range of comprehensive health services. 
        Take proactive steps towards your well-being with our tailored and affordable healthcare solutions. Join us in prioritizing your health journey." />
      </Helmet>
      <div>
        <div className="signupMain">
          <div className="signupMainLeft">
            <img src={signupImage} className="signupLeftBackground" alt="Sign Up"/>
            <img src={Logo} className="LogoSignUp" alt="logo" />
          </div>
          <div className="signupMainRight">
            {showMobileOtp ? (
              <VerificationRegisterOTPScreen
                allValues={childData}
                mobileNumber={childData?.number}
              />
            ) : (
              <>
                <UserRegisterForm setChildData={setChildData} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleRegister;
