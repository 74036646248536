import * as types from "../../Config/Network/networkActionType"

export const viewAllHospitalRequest = (page,search) => {
    return {
        type: types.VIEW_ALL_HOSPITAL_REQUEST,
        page,
        search
    }
}

export const viewAllHospitalSuccess = (data) => {
    return {
        type: types.VIEW_ALL_HOSPITAL_SUCCESS,
        data:data,
    }
}

export const viewAllHospitalFailure = (data) => {
    return {
        type: types.VIEW_ALL_HOSPITAL_FAILURE,
        data:data,
    }
}