import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Cart/CreateCartActionType";
import * as actions from "../../Action/Cart/RedeemCouponAction";
import NetworkService from "../../../network/Network/Cart/CartNetwork";

export function* RedeemCouponSaga(action) {

  try {
    let response = yield call(NetworkService.RedeemCoupon, action);
    yield put(actions.RedeemCouponSuccess(response));
  } catch (err) {
    yield put(actions.RedeemCouponFailure(err));
  }
}

export function* watchRedeemCouponSaga() {
  yield takeLatest(types.REDEEM_COUPON_REQUEST, RedeemCouponSaga);
}
