import React from "react";
import LoaderGif from "../../../assets/Loader/loader.gif";
import "./Loader.css";

function YuvaLoader() {
  return (
    <div className="YuvaLoader">
      <img src={LoaderGif} alt="Loading..." />
    </div>
  );
}

export default YuvaLoader;
