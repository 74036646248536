import * as types from "../../Config/Plan/PlanActionType";

export const getPlanTypeDropdownRequest = (data) => {
  return {
    type: types.PLAN_TYPE_DROPDOWN_REQUEST,
    data:data,
  };
};

export const getPlanTypeDropdownSuccess = (data) => {
  return {
    type: types.PLAN_TYPE_DROPDOWN_SUCCESS,
    data: data,
  };
};

export const getPlanTypeDropdownFailure = (data) => {
  return {
    type: types.PLAN_TYPE_DROPDOWN_FAILURE,
    data: data,
  };
};
