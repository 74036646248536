export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILURE = "FORGET_PASSWORD_FAILURE";

export const CHECK_HASH_ID_REQUEST = "CHECK_HASH_ID_REQUEST";
export const CHECK_HASH_ID_SUCCESS = "CHECK_HASH_ID_SUCCESS";
export const CHECK_HASH_ID_FAILURE = "CHECK_HASH_ID_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const OTP_MOBILE_VERIFICATION_REQUEST =
  "OTP_MOBILE_VERIFICATION_REQUEST";
export const OTP_MOBILE_VERIFICATION_SUCCESS =
  "OTP_MOBILE_VERIFICATION_SUCCESS";
export const OTP_MOBILE_VERIFICATION_FAILURE =
  "OTP_MOBILE_VERIFICATION_FAILURE";

export const LOGIN_GOOGLE_REQUEST = "LOGIN_GOOGLE_REQUEST";
export const LOGIN_GOOGLE_SUCCESS = "LOGIN_GOOGLE_SUCCESS";
export const LOGIN_GOOGLE_FAILURE = "LOGIN_GOOGLE_FAILURE";

export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const ADD_RELATION_REQUEST = "ADD_RELATION_REQUEST";
export const ADD_RELATION_SUCCESS = "ADD_RELATION_SUCCESS";
export const ADD_RELATION_FAILURE = "ADD_RELATION_FAILURE";
export const ADD_RELATION_CLEAR_REQUEST = "ADD_RELATION_CLEAR_REQUEST";


export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

export const GET_RELATIVES_REQUEST = "GET_RELATIVES_REQUEST";
export const GET_RELATIVES_SUCCESS = "GET_RELATIVES_SUCCESS";
export const GET_RELATIVES_FAILURE = "GET_RELATIVES_FAILURE";

export const UPLOAD_FAMILY_PICTURE_REQUEST = "UPLOAD_FAMILY_PICTURE_REQUEST";
export const UPLOAD_FAMILY_PICTURE_SUCCESS = "UPLOAD_FAMILY_PICTURE_SUCCESS";
export const UPLOAD_FAMILY_PICTURE_FAILURE = "UPLOAD_FAMILY_PICTURE_FAILURE";

export const UPLOAD_PROFILE_PICTURE_REQUEST = "UPLOAD_PROFILE_PICTURE_REQUEST";
export const UPLOAD_PROFILE_PICTURE_SUCCESS = "UPLOAD_PROFILE_PICTURE_SUCCESS";
export const UPLOAD_PROFILE_PICTURE_FAILURE = "UPLOAD_PROFILE_PICTURE_FAILURE";

export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE";
