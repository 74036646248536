import * as Yup from "yup";
import storage from "../../../Utils/storage";

export const Section7Schema = Yup.object().shape({
  // Q41: Yup.string().when("Q41", {
  //   is: "1",
  //   then: Yup.string().required("Required field"),
  // }),
  // Q45: Yup.string().when("Q41", {
  //   is: "1",
  //   then: Yup.string().required("Required field"),
  // }),
  // Q42: Yup.string().when("Q41", {
  //   is: "1",
  //   then: Yup.string().required("Required field"),
  // }),
  // Q49: Yup.string().when("Q41", {
  //   is: "1",
  //   then: Yup.string().required("Required field"),
  // }),
  // Q43: Yup.string().when("Q42", {
  //   is: "1",
  //   then: Yup.string().required("Required field"),
  // }),
  // Q44: Yup.string().when("Q43", {
  //   is: "1",
  //   then: Yup.string()
  //     .test(
  //       "is-greater-than-4.5",
  //       "Value must be greater than or equal to 4.5",
  //       function (value) {
  //         return parseFloat(value) >= 4.5;
  //       }
  //     )
  //     .test(
  //       "is-valid-number",
  //       "Value must be a number with at most 1 digit after the decimal point",
  //       function (value) {
  //         return /^[0-9]+\.?[0-9]{0,1}$/.test(value);
  //       }
  //     )
  //     .required("Required field"),
  // }),
  // Q46: Yup.string().when("Q45", {
  //   is: "1",
  //   then: Yup.string().required("Required field"),
  // }),
  // Q47: Yup.string().when("Q46", {
  //   is: "1",
  //   then: Yup.string().required("Required field"),
  // }),
  // Q48: Yup.string().when("Q46", {
  //   is: "1",
  //   then: Yup.string().required("Required field"),
  // }),
  // Q50: Yup.string().required("Required Field"),
  // chronicIllnessReason: Yup.string().when("Q50", {
  //   is: "1",
  //   then: Yup.string().required("Please Enter the reason"),
  // }),
  // CancerReason: Yup.string().when("Q49", {
  //   is: "1",
  //   then: Yup.string().required("Please Enter the reason"),
  // }),
});

export const Section8Schema = Yup.object().shape({
  Q51: Yup.string().required("Required Field"),
  Q52: Yup.string().required("Required Field"),
  Q53: Yup.string().required("Required Field"),
  Q54: Yup.string().required("Required Field"),
  Q55: Yup.string().required("Required Field"),
  Q56: Yup.string().required("Required Field"),
});

export const Section5Schema2 = Yup.object().shape({
  Q35: Yup.string().required("Required Field"),
  Q38: Yup.string().required("Required Field"),
});

export const Section1Schema = Yup.object().shape({
    Q2: Yup.number()
    .typeError("Required Field")
      .min(12, "Age must be greater than or equal to 12")
      .max(100, "Age must be less than or equal to 100")
      .required("Required Field"),
    Q3: Yup.number()
      .min(120, "Height must be greater than or equal to 120")
      .max(219, "Height must be less than or equal to 219")
      .required("Required Field"),
    Q4: Yup.number()
      .min(20, "Weight must be greater than or equal to 20")
      .max(219, "Weight must be less than or equal to 219")
      .required("Required Field"),
    Q5: Yup.number()
      .min(20, "Waist Size must be greater than or equal to 20")
      .max(47, "Waist Size must be less than or equal to 47")
      .required("Required Field"),
    Q58: Yup.string().required("Required Field"),
  });

export const section2Schema = Yup.object().shape({
  Q6: Yup.string().required("Please select value"),
  Q7: Yup.string().required("Please select value"),
  Q8: Yup.string().required("Please select value"),
  Q9: Yup.string().required("Please select value"),
  Q10: Yup.string().required("Please select value"),
  Q11: Yup.string().required("Please select value"),
  Q12: Yup.string().required("Please select value"),
  Q13: Yup.string().required("Please select value"),
  Q14: Yup.string().required("Please select value"),
});

export const section3Schema = Yup.object().shape({
  Q15_PHQ: Yup.string().required("Please select value"),
  Q16_PHQ: Yup.string().required("Please select value"),
  Q17_PHQ: Yup.string().required("Please select value"),
  Q18_PHQ: Yup.string().required("Please select value"),
  Q19_PHQ: Yup.string().required("Please select value"),
  Q20_PHQ: Yup.string().required("Please select value"),
  Q21_PHQ: Yup.string().required("Please select value"),
  Q22_PHQ: Yup.string().required("Please select value"),
  Q23_PHQ: Yup.string().required("Please select value"),
  Q24_GAD: Yup.string().required("Please select value"),
  Q25_GAD: Yup.string().required("Please select value"),
  Q26_GAD: Yup.string().required("Please select value"),
  Q27_GAD: Yup.string().required("Please select value"),
  Q28_GAD: Yup.string().required("Please select value"),
  Q29_GAD: Yup.string().required("Please select value"),
  Q30_GAD: Yup.string().required("Please select value"),
});