import * as types from "../../Config/Order/OrderActionType";

export const getProgramSubscriptionRequest = (data) => {
  return {
    type: types.GET_PROGRAM_SUBSCRIPTION_REQUEST,
    data:data,
  };
};

export const getProgramSubscriptionSuccess = (data) => {
  return {
    type: types.GET_PROGRAM_SUBSCRIPTION_SUCCESS,
    data: data,
  };
};

export const getProgramSubscriptionFailure = (data) => {
  return {
    type: types.GET_PROGRAM_SUBSCRIPTION_FAILURE,
    data: data,
  };
};
