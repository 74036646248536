import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import talktodoctor_banner from "../../../assets/TalkToDoctorAssests/Rectangle 1113.png";
import TalkToDoctor_bacteria from "../../../assets/TalkToDoctorAssests/TalkToDoctor_bacteria.png";
import TalkToDoctor_childcare from "../../../assets/TalkToDoctorAssests/TalkToDoctor_childcare.png";
import TalkToDoctor_chills from "../../../assets/TalkToDoctorAssests/TalkToDoctor_chills.png";
import TalkToDoctor_exercise_running from "../../../assets/TalkToDoctorAssests/TalkToDoctor_exercise_running.png";
import TalkToDoctor_exercisewalksupported from "../../../assets/TalkToDoctorAssests/TalkToDoctor_exercisewalksupported.png";
import TalkToDoctor_sexualreproductivehealth from "../../../assets/TalkToDoctorAssests/TalkToDoctor_sexualreproductivehealth.png";
import TalkToDoctorwoman from "../../../assets/TalkToDoctorAssests/TalkToDoctorwoman.png";
import earsnoseandthroat from "../../../assets/TalkToDoctorAssests/earsnoseandthroat.png";
import Title from "../../Atoms/Title/Title";
import BookAppointmentCarousel from "./BookAppointmentCarousel";
import MedicalConditionPage from "./MedicalConditionPage";
import "./TalkToDoctor.css";
import "./TalkToDoctorModal.css";
import VirtualConsultationPage from "./VirtualConsultationPage";
import TalkToDoctorSliderCard from "../../Organisms/Slider/TalkToDoctorSliderCard";
import { Helmet } from "react-helmet";

function TalkToDoctor() {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  function handleclose() {
    setOpen(false);
  }
  function handleClick(e) {
    setOpen(true);
  }
  function handleProceedButton() {
    navigate("/subscription");
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  let image = [
    {
      Image: earsnoseandthroat,
      Name: "Ear, Nose & Throat",
    },
    {
      Image: TalkToDoctor_bacteria,
      Name: "Skin Problem",
    },
    {
      Image: TalkToDoctor_childcare,
      Name: "Child/Infant Issues",
    },
    {
      Image: TalkToDoctor_chills,
      Name: "Cold, Cough & Fever",
    },
    {
      Image: TalkToDoctor_exercise_running,
      Name: "Online Physiotherapy",
    },
    {
      Image: TalkToDoctor_exercisewalksupported,
      Name: "General Physician",
    },
    {
      Image: TalkToDoctor_sexualreproductivehealth,
      Name: "Sexual Problems",
    },
    {
      Image: TalkToDoctorwoman,
      Name: "Women's Issues",
    },

    {
      Image: earsnoseandthroat,
      Name: "Ear, Nose & Throat",
    },
    {
      Image: TalkToDoctor_bacteria,
      Name: "Skin Problem",
    },
    {
      Image: TalkToDoctor_childcare,
      Name: "Child/Infant Issues",
    },
    {
      Image: TalkToDoctor_chills,
      Name: "Cold, Cough & Fever",
    },
    {
      Image: TalkToDoctor_exercise_running,
      Name: "Online Physiotherapy",
    },
    {
      Image: TalkToDoctor_exercisewalksupported,
      Name: "General Physician",
    },
    {
      Image: TalkToDoctor_sexualreproductivehealth,
      Name: "Sexual Problems",
    },
    {
      Image: TalkToDoctorwoman,
      Name: "Women's Issues",
    },
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow className={"nextArrow"} />,
    prevArrow: <SamplePrevArrow className={"nextArrow"} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ display: "block" }}
        onClick={onClick}
      />
    );
  }
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ display: "block"}}
        onClick={onClick}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Free Online Doctor Consultation | YUVA</title>
        <meta
          name="description"
          content="Book OPD online appointments and get free online doctor consultations. Convenient, quick, and accessible healthcare services at your fingertips."
        />
        <meta name="keywords" content="Free Online Doctor Consultation" />
      </Helmet>
      {open && (
        <div>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="mainModal"
          >
            <Box className="style1">
              <div className="TellUsAboutYourselfMain">
                <div className="TalkToDoctorModalRelationsBox">
                  <div className="TalkToDoctorModalBoxOuter">
                    <div className="TalktodocmodalHeading">
                      To Avail Talk to Doctor consultation please subscribe to
                      Yuva Health Plans
                    </div>
                  </div>
                  <div className="TalkToDoctorBtnSaveRealtions">
                    <button
                      className="TTDButtonSaveRealtions"
                      onClick={handleclose}
                    >
                      Close
                    </button>
                    <button
                      className="TTDButtonSaveRealtions"
                      onClick={handleProceedButton}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      )}
      <>
        <div className="TalkToDactor_OuterContainer">
          <div className="TalkToDactorHeadingImage">
            <div className="TalkToDactor_Heading">
              Talk To Doctor
              <br />
              <div className="TalkToDactor_Heading2">Anytime Anywhere</div>
            </div>
            <img src={talktodoctor_banner} alt="Talk to Doctor banner" />
          </div>
          <div className="LifeAlteringIssuePageContainer">
            <div className="LifeAlteringIssuePageHeading">
              Chat with doctor anytime anywhere
            </div>
            <div className="LifeAlteringIssuePageSubHeading">
              Get answers for anything and everything. From Dental infection to
              Super Specialisations Consultations we got you 24 /7
            </div>

            <div className="LifeAlteringIssuePageContainerCorosal">
              <Slider {...settings} className="TalkToDoctorSlider">
                {image.length > 0 &&
                  image?.map((item, index) => (
                    <TalkToDoctorSliderCard Content={item} />
                  ))}
              </Slider>
            </div>
          </div>
          <MedicalConditionPage />
          <BookAppointmentCarousel />
          <VirtualConsultationPage />
          <div className="TalkToDoctorLastTextComponent">
            <div className="TalkToDactorCardBlock4">
              <div className="TalkToDactorCardBlock4Heading">
                3600+ doctors and everyone's the best.
              </div>
              <div className="TalkToDactorCardBlock4SubHeading">
                Yuva Health upholds the highest standards when approving doctors
                to practice online. That means every doctor you see on the
                platform is highly qualified to provide the best care possible.
              </div>
              <button
                onClick={() => handleClick()}
                className="ButtonTalktodoctor"
              >
                Choose A Doctor
              </button>
              </div>
          </div>
        </div>
      </>
    </>
  );
}
export default TalkToDoctor;
