import RequestService from "../../RequestService";
import { api } from "../../../helpers/RefreshToken/refreshTokenConstant";


class NetworkService {
    refreshToken(action) {
        const endPoint = `${api.refreshToken}`;
        return RequestService.postRequest(endPoint, {
            fcmToken: action.fcmToken,
            token: action.token
        });
    }
}

export default new NetworkService();