import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/OTP/OTPValidationMobile/otpMobileValidationActionType";
import * as actions from "../../../Action/OTP/OTPValidationMobile/otpMobileValidationAction";
import NetworkService from "../../../../network/Network/OTP/OTPValidationMobile/otpMobileValidationNetwork.js";

export function* validateMobileOtp(action) {
  try {
    let response = yield call(NetworkService.validateMobileOtp, action);
    yield put(actions.otpMobileValidationRequestSuccess(response));
  } catch (err) {
    yield put(actions.otpMobileValidationRequestFailure(err));
  }
}

export function* watchValidateMobileOtp() {
  yield takeLatest(types.OTPMOBILEVALIDATION_REQUEST, validateMobileOtp);
}