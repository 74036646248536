import React from "react";
import "./SearchModalInput.css";

function SearchModalInput({ data }) {
  const handleRedirection = async (item) => {
    window.location.href =
      "/Search?item=true&name=" +
      item.name +
      "&uuid=" +
      item.attributeUuid +
      "&productTypeEnum=" +
      item.productTypeEnum;
  };

  return (
    <div>
      <div className="SearchInputModalMainContainer">
        <div className="SearchInputModalMainContainerGrid">
          <div>
            {data?.length > 0 ? (
              data?.map((item) => (
                <div
                  className="SearchModalInputMainSubText"
                  onClick={() => handleRedirection(item)}
                >
                  {item.name}
                </div>
              ))
            ) : (
              <div className="SearchModalInputMainSubText">
                No Records Found
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchModalInput;
