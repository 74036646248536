import { all } from "redux-saga/effects";
import { watchMyPrescriptionSaga } from "./myPrescriptionSaga";
import { watchMyReportDiagnosticSaga } from "./myReportDiagnosticSaga";
import { watchMyReportSaga } from "./myReportSaga";
import { watchGetDocumentTypeSaga } from "./GetDocumentTypeSaga";
import { watchApplyForKYCSaga } from "./ApplyForKYCSaga";


function* rootAccountSaga() {
    yield all([
        watchMyPrescriptionSaga(),
        watchMyReportDiagnosticSaga(),
        watchMyReportSaga(),
        watchGetDocumentTypeSaga(),
        watchApplyForKYCSaga(),
    ])
}

export default rootAccountSaga;
