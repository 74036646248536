import RequestService from "../../RequestService";
import { api } from "../../../helpers/HealthRiskAssessment/HealthRiskAssessmentConstants";

class NetworkService {
  GetQuestionSection(action) {
    const endPoint = `${api.hraQuestions}/${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  GetQuestionSection1() {
    const endPoint = `${api.hraQuestions}/1`;
    return RequestService.getRequest(endPoint);
  }

  GetQuestionSection2() {
    const endPoint = `${api.hraQuestions}/2`;
    return RequestService.getRequest(endPoint);
  }

  GetQuestionSection3() {
    const endPoint = `${api.hraQuestions}/3`;
    return RequestService.getRequest(endPoint);
  }

  GetQuestionSection4() {
    const endPoint = `${api.hraQuestions}/4`;
    return RequestService.getRequest(endPoint);
  }

  GetQuestionSection5() {
    const endPoint = `${api.hraQuestions}/5`;
    return RequestService.getRequest(endPoint);
  }

  GetQuestionSection6() {
    const endPoint = `${api.hraQuestions}/6`;
    return RequestService.getRequest(endPoint);
  }

  GetQuestionSection7() {
    const endPoint = `${api.hraQuestions}/7`;
    return RequestService.getRequest(endPoint);
  }

  GetQuestionSection8() {
    const endPoint = `${api.hraQuestions}/8`;
    return RequestService.getRequest(endPoint);
  }

  GetQuestionSection9() {
    const endPoint = `${api.hraQuestions}/9`;
    return RequestService.getRequest(endPoint);
  }

  SaveReport(action) {
    let finaldata = {};
    if (action.data.relationId === "") {
      finaldata = {
        answers: action.data.mergedObject,
        cancer: action.data.CancerReason,
        illness: action.data.chronicIllness,
      };
    } else {
      finaldata = {
        answers: action.data.mergedObject,
        cancer: action.data.CancerReason,
        illness: action.data.chronicIllness,
        relationId: parseInt(action.data.relationId),
      };
    }

    const endPoint = `${api.saveReport}`;
    return RequestService.postRequest(endPoint, finaldata);
  }

  ContinueSection() {
    const endPoint = `${api.ContinueSection}`;
    return RequestService.getRequest(endPoint);
  }

  GetHraQuestions(action) {
    const endPoint = `${api.GetHraQuestions}/${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  GetSectionAnswers(action) {
    const endPoint = `${api.GetSectionAnswers}?sectionId=${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  DeleteSectionAnswers(action) {
    const endPoint = `${api.DeleteSectionAnswers}`;
    return RequestService.deleteRequest(endPoint);
  }

  SaveSectionAnswers(action) {
    const endPoint = `${api.SaveSectionAnswers}`;
    return RequestService.putRequest(endPoint, {
      answers: action.data.values,
      sectionId: parseInt(action.data.currentSectionValue),
    });
  }

  GetRelations(action) {
    if (action.data !== undefined) {
      if (!action.data.version) {
        const endPoint = `${api.Relations}?uuid=${action.data}`;
        return RequestService.getRequest(endPoint);
      } else if (action.data) {
        const endPoint = `${api.Relations}?uuid=${action?.data?.uuid}&version=${action?.data?.version}`;
        return RequestService.getRequest(endPoint);
      } else {
        const endPoint = `${api.Relations}`;
        return RequestService.getRequest(endPoint);
      }
    } else {
      const endPoint = `${api.Relations}`;
      return RequestService.getRequest(endPoint);
    }
  }

  GetLatestSection(action) {
    const endPoint = `${api.GetLatestSection}?sectionId=${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  GetSection1Answers(action) {
    const endPoint = `${api.GetSectionAnswers}?sectionId=1`;
    return RequestService.getRequest(endPoint);
  }

  GetSection2Answers(action) {
    const endPoint = `${api.GetSectionAnswers}?sectionId=2`;
    return RequestService.getRequest(endPoint);
  }

  GetSection3Answers(action) {
    const endPoint = `${api.GetSectionAnswers}?sectionId=3`;
    return RequestService.getRequest(endPoint);
  }

  GetSection4Answers(action) {
    const endPoint = `${api.GetSectionAnswers}?sectionId=4`;
    return RequestService.getRequest(endPoint);
  }

  GetSection5Answers(action) {
    const endPoint = `${api.GetSectionAnswers}?sectionId=5`;
    return RequestService.getRequest(endPoint);
  }

  GetSection6Answers(action) {
    const endPoint = `${api.GetSectionAnswers}?sectionId=6`;
    return RequestService.getRequest(endPoint);
  }

  GetSection7Answers(action) {
    const endPoint = `${api.GetSectionAnswers}?sectionId=7`;
    return RequestService.getRequest(endPoint);
  }

  GetSection8Answers(action) {
    const endPoint = `${api.GetSectionAnswers}?sectionId=8`;
    return RequestService.getRequest(endPoint);
  }

  GetSection9Answers(action) {
    const endPoint = `${api.GetSectionAnswers}?sectionId=9`;
    return RequestService.getRequest(endPoint);
  }
}

export default new NetworkService();
