import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

function PaymentLink() {
    const search = useLocation().search;

    const enc = new URLSearchParams(search).get("encRequest");

    useEffect(() => {
        const paymentURL = process.env.REACT_APP_CCAVENUE_URL;
        const paymentAccessCode = process.env.REACT_APP_ACCESS_CODE;

        window.location.href = `${paymentURL}?command=initiateTransaction&encRequest=${enc}&access_code=${paymentAccessCode}`;
    }, [enc])

    return (
        <div>

        </div>
    )
}

export default PaymentLink;