import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"



export const  Section3AnswersSuccess = (data) => {
    return {
        type: types.SECTION3_ANSWERS_SUCCESS,
        data:data,
    }
}


