import InfoIcon from "@mui/icons-material/Info";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import storage from "../../../Utils/storage";
import backArrow from "../../../assets/hra/backArrow.png";
import hra3 from "../../../assets/hra/hra3.png";
import { GetQuestionSection3Request } from "../../../redux/Action/HealthRiskAssessment/GetQuestionSection3Action";
import { GetSection3AnswersRequest } from "../../../redux/Action/HealthRiskAssessment/GetSectionAnswers/GetSection3AnswersAction";
import { Section3AnswersSuccess } from "../../../redux/Action/HealthRiskAssessment/HRAAnswers/Section3AnswersAction";
import { SaveSectionAnswersRequest } from "../../../redux/Action/HealthRiskAssessment/SaveSectionAnswersAction";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import SingleLogin from "../Login/SingleLogin";
import { dropDisabled, pickerhealth } from "./HraDropdowns";
import "./Sections.css";
import { section3Schema } from "./ValidationSchemas";

function Section3() {
  const SectionValue = localStorage.getItem("SectionValue");

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(GetQuestionSection3Request());
    dispatch(GetSection3AnswersRequest());
  }, []);

  const dispatch = useDispatch();

  const currentSectionValue = localStorage.getItem("SectionValue");
  const GetSectionAnswers = useSelector(
    (state) => state.getSectionAnswer.getSection3Answers
  );
  const [json, setJson] = useState();
  const [counter, setCounter] = useState(false);

  useEffect(() => {
    if (GetSectionAnswers?.data?.data?.data?.data !== undefined) {
      setJson(JSON.parse(GetSectionAnswers?.data?.data?.data?.data));
      setCounter(true);
    }
  }, [GetSectionAnswers?.data?.data?.data?.data]);

  window.addEventListener("popstate", function (event) {
    storage.setStorage("SectionValue", 2);
  });
  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    localStorage.setItem("SectionValue", 4);
    dispatch(Section3AnswersSuccess(values));

    dispatch(SaveSectionAnswersRequest({ values, currentSectionValue }));
    navigate("/health-risk-assessment/section-4a");
  };

  const handlePrevious = () => {
    navigate("/health-risk-assessment/section-2b");
  };

  const GetQuestionSection = useSelector(
    (state) => state.getQuestion.getQuestionSection3
  );

  if (storage.getStorage("token") === null) {
    return <SingleLogin />;
  }

  if (GetQuestionSection.isLoading) {
    return <YuvaLoader />;
  }
  return (
    <>
      <ScrollToTop />

      <div className="HRAQuestionsMain">
        <div className="HRAQuestionsMainChild1">
          <p>Your Health Risk Assessment</p>
        </div>
        {SectionValue === "3" && (
          <div
            className="HRAQuestionsMainChild2"
            style={{ backgroundImage: `url(${hra3})` }}
          >
            <div className="HRAQuestionsMainChild2a">
              <div className="HRAQuestionsMainChild2a1">
                <p>GENERAL SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1 faded">
                <p>DIETARY SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1">
                <p>MENTAL HEALTH SECTION</p>
              </div>
              {SectionValue === "3" && (
                <div className="HRAQuestionsMainChild2a2">
                  <Formik
                    initialValues={
                      !counter
                        ? {
                            Q15_PHQ: "",
                            Q16_PHQ: "",
                            Q17_PHQ: "",
                            Q18_PHQ: "",
                            Q19_PHQ: "",
                            Q20_PHQ: "",
                            Q21_PHQ: "",
                            Q22_PHQ: "",
                            Q23_PHQ: "",
                            Q24_GAD: "",
                            Q25_GAD: "",
                            Q26_GAD: "",
                            Q27_GAD: "",
                            Q28_GAD: "",
                            Q29_GAD: "",
                            Q30_GAD: "",
                          }
                        : {
                            Q15_PHQ: json?.Q15_PHQ.toString(),
                            Q16_PHQ: json?.Q16_PHQ.toString(),
                            Q17_PHQ: json?.Q17_PHQ.toString(),
                            Q18_PHQ: json?.Q18_PHQ.toString(),
                            Q19_PHQ: json?.Q19_PHQ.toString(),
                            Q20_PHQ: json?.Q20_PHQ.toString(),
                            Q21_PHQ: json?.Q21_PHQ.toString(),
                            Q22_PHQ: json?.Q22_PHQ.toString(),
                            Q23_PHQ: json?.Q23_PHQ.toString(),
                            Q24_GAD: json?.Q24_GAD.toString(),
                            Q25_GAD: json?.Q25_GAD.toString(),
                            Q26_GAD: json?.Q26_GAD.toString(),
                            Q27_GAD: json?.Q27_GAD.toString(),
                            Q28_GAD: json?.Q28_GAD.toString(),
                            Q29_GAD: json?.Q29_GAD.toString(),
                            Q30_GAD: json?.Q30_GAD.toString(),
                          }
                    }
                    validationSchema={section3Schema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                  >
                    {({ isSubmitting }) => {
                      return (
                        <Form>
                          <div className="HRAQuestionsMainChild2a2div">
                            <div className="hraBackArrow">
                              <img
                                src={backArrow}
                                onClick={handlePrevious}
                                alt="backArrow"
                              />
                              <p>Mental Health Section</p>
                            </div>
                            <label className="Q_A">
                              <p>
                                In Last 2 weeks, how often have you been
                                bothered by any of the following problems?
                              </p>
                            </label>

                            <div className="infoflex">
                              <p className="SingleQuestion">PHQ</p>
                              <div className="infoMentalHealth">
                                <InfoIcon className="eyeicon"></InfoIcon>{" "}
                                <span class="myServicesDescriptionText">
                                  The PHQ-9 is a multipurpose instrument for
                                  screening, diagnosing, monitoring and
                                  measuring the severity of depression.
                                </span>
                              </div>
                            </div>

                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection.data?.data.data.data[0]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q15_PHQ"
                                className="select"
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerhealth.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q15_PHQ"
                                component="div"
                              />
                            </label>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection.data?.data.data.data[1]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q16_PHQ"
                                className="select"
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerhealth.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q16_PHQ"
                                component="div"
                              />
                            </label>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection.data?.data.data.data[2]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q17_PHQ"
                                className="select"
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerhealth.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q17_PHQ"
                                component="div"
                              />
                            </label>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection.data?.data.data.data[3]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q18_PHQ"
                                className="select"
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerhealth.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q18_PHQ"
                                component="div"
                              />
                            </label>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection.data?.data.data.data[4]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q19_PHQ"
                                className="select"
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerhealth.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q19_PHQ"
                                component="div"
                              />
                            </label>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection.data?.data.data.data[5]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q20_PHQ"
                                className="select"
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerhealth.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q20_PHQ"
                                component="div"
                              />
                            </label>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection.data?.data.data.data[6]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q21_PHQ"
                                className="select"
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerhealth.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q21_PHQ"
                                component="div"
                              />
                            </label>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection.data?.data.data.data[7]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q22_PHQ"
                                className="select"
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerhealth.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q22_PHQ"
                                component="div"
                              />
                            </label>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection.data?.data.data.data[8]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q23_PHQ"
                                className="select"
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerhealth.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q23_PHQ"
                                component="div"
                              />
                            </label>
                            <div className="infoflex">
                              <p className="SingleQuestion">GAD</p>
                              <div className="infoMentalHealth">
                                <InfoIcon className="eyeicon"></InfoIcon>{" "}
                                <span class="myServicesDescriptionText">
                                  The Generalized Anxiety Disorder 7 (GAD-7) is
                                  a easy to perform initial screening tool for
                                  generalized anxiety disorder.
                                </span>
                              </div>
                            </div>

                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection.data?.data.data.data[9]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q24_GAD"
                                className="select"
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerhealth.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q24_GAD"
                                component="div"
                              />
                            </label>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection.data?.data.data.data[10]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q25_GAD"
                                className="select"
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerhealth.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q25_GAD"
                                component="div"
                              />
                            </label>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection.data?.data.data.data[11]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q26_GAD"
                                className="select"
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerhealth.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q26_GAD"
                                component="div"
                              />
                            </label>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection.data?.data.data.data[12]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q27_GAD"
                                className="select"
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerhealth.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q27_GAD"
                                component="div"
                              />
                            </label>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection.data?.data.data.data[13]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q28_GAD"
                                className="select"
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerhealth.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q28_GAD"
                                component="div"
                              />
                            </label>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection.data?.data.data.data[14]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q29_GAD"
                                className="select"
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerhealth.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q29_GAD"
                                component="div"
                              />
                            </label>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection.data?.data.data.data[15]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q30_GAD"
                                className="select"
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickerhealth.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q30_GAD"
                                component="div"
                              />
                            </label>
                            <button
                              type="submit"
                              className="HRAQuestionsButton"
                              disabled={isSubmitting}
                            >
                              Next
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        )}

        {SectionValue !== "3" && (
          <div className="hraDenied">
            Not Authorized !! please navigate to previous sections
          </div>
        )}
      </div>
    </>
  );
}

export default Section3;
