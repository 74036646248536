import { all } from "redux-saga/effects";
import {watchdeviceTokenSaga} from "./DeviceTokenSaga"


function* rootdeviceSaga() {
    yield all([
        watchdeviceTokenSaga(),
      
    ])
}

export default rootdeviceSaga;
