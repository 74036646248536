import { initializeApp } from "firebase/app";
import { isSupported, getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId:process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
const VAPID_KEY = process.env.REACT_APP_VAPID_KEY;

const firebaseApp = initializeApp(firebaseConfig);

export const requestPermission = async (handleFCMToken) => {
  if (isSupported()) {
    const messaging = getMessaging(firebaseApp);
    try {
      let currentToken = await getToken(messaging, {
        vapidKey: VAPID_KEY,
      });

      const permission = await Notification.requestPermission(); 

      if (permission === "granted") {
        handleFCMToken(currentToken);
      } else if (permission === "denied") {
        alert("Notification permission denied");
      }
    } catch (error) {
    }
  } else {
  }
};

export { firebaseApp };