import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const GetQuestionSection5Request = (data) => {
    return {
        type: types.GET_QUESTION_SECTION5_REQUEST,
        data:data
    }
}

export const  GetQuestionSection5Success = (data) => {
    return {
        type: types.GET_QUESTION_SECTION5_SUCCESS,
        data:data,
    }
}

export const  GetQuestionSection5Failure = (data) => {
    return {
        type: types.GET_QUESTION_SECTION5_FAILURE,
        data:data,
    }
}