import { useEffect } from "react";

export default function ScrollToTop() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return null;
}
