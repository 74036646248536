import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const GetSection4AnswersRequest = (data) => {
    return {
        type: types.GET_SECTION_4_ANSWERS_REQUEST,
        data:data
    }
}

export const  GetSection4AnswersSuccess = (data) => {
    return {
        type: types.GET_SECTION_4_ANSWERS_SUCCESS,
        data:data,
    }
}

export const  GetSection4AnswersFailure = (data) => {
    return {
        type: types.GET_SECTION_4_ANSWERS_FAILURE,
        data:data,
    }
}