import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Points1 from "../../../assets/TalkToDoctorAssests/TalkToDoctorPoints.png";
import Tick2 from "../../../assets/TalkToDoctorAssests/Tick2.png";
import Tick3 from "../../../assets/TalkToDoctorAssests/Tick3.png";
import pic1 from "../../../assets/TalkToDoctorAssests/talktodocpic1.png";
import pic2 from "../../../assets/TalkToDoctorAssests/talktodocpic2.png";
import pic3 from "../../../assets/TalkToDoctorAssests/talktodocpic3.png";

function VirtualConsultationPage() {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  function handleclose() {
    setOpen(false);
  }
  function handleClick() {
    setOpen(true);
  }
  function handleProceedButton() {
    navigate("/subscription");
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <>
      {open && (
        <div>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="mainModal"
          >
            <Box className="style1">
              <div className="TellUsAboutYourselfMain">
                <div className="TalkToDoctorModalRelationsBox">
                  <div className="TalkToDoctorModalBoxOuter">
                    <div className="TalktodocmodalHeading">
                      To Avail Talk to Doctor consultation please subscribe to
                      Yuva Health Plans
                    </div>
                  </div>
                  <div className="TalkToDoctorBtnSaveRealtions">
                    <button
                      className="TTDButtonSaveRealtions"
                      onClick={handleclose}
                    >
                      Close
                    </button>
                    <button
                      className="TTDButtonSaveRealtions"
                      onClick={handleProceedButton}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      )}
      <div className="VirtualConsultationPageContainer">
        <div className="VirtualConsultationPageHeading">
          consultation at your finger tip
        </div>
        <div className="VirtualConsultationPageSubHeading">How it works:</div>
        <div className="VirtualConsultation_grid-container">
          <div className="VirtualConsultation_grid-containerLeft">
            <div className="VirtualConsultation_grid-containerLeftFirst">
              <div className="VirtualConsultation_grid-containerLeftFirstLeft">
                <img src={Points1} alt="points" />
                <div className="VirtualConsultation_grid-containerLeftFirstLeftSerialNumber">
                  1
                </div>
              </div>
              <div className="VirtualConsultation_grid-containerLeftFirstRight">
                Write your health query in detail.
              </div>
            </div>
            <div className="VirtualConsultation_grid-containerLeftFirst">
              <div className="VirtualConsultation_grid-containerLeftFirstLeft">
                <img src={Points1} alt="points" />
                <div className="VirtualConsultation_grid-containerLeftFirstLeftSerialNumber">
                  2
                </div>
              </div>
              <div className="VirtualConsultation_grid-containerLeftFirstRight">
                Choose a subscription plan that fits your needs and pay online
              </div>
            </div>
            <div className="VirtualConsultation_grid-containerLeftFirst">
              <div className="VirtualConsultation_grid-containerLeftFirstLeft">
                <img src={Points1} alt="points" />
                <div className="VirtualConsultation_grid-containerLeftFirstLeftSerialNumber">
                  3
                </div>
              </div>
              <div className="VirtualConsultation_grid-containerLeftFirstRight">
                Chat unlimited with a doctor based on your plan.
              </div>
            </div>
            <button
              type="button"
              onClick={() => handleClick()}
              className="VirtualConsultationBTn"
            >
              Start chat Conversation
            </button>
          </div>
          <div className="VirtualConsultation_grid-item">
            <div className="VirtualConsultation_grid-itemInside">
              <div className="lo">
                <div>
                  Booking request with WKBY Albany, NY @11:35AM et via satellite
                  radio.
                  <img
                    className="TalkToDoctorTick"
                    src={Tick2}
                    alt="tick"
                  ></img>
                </div>
              </div>
              <div className="VirtualConsultation_gridpic1">
                <img
                  src={pic1}
                  className="VirtualConsultationfitsIcon"
                  alt="avatar"
                />{" "}
              </div>
            </div>

            <div className="VirtualConsultation_grid-itemInside1">
              <div className="lo">
                <div>
                  Your booking request with Dan Smith has been <br />
                  accepted.
                  <img
                    className="TalkToDoctorTick"
                    src={Tick2}
                    alt="tick"
                  ></img>
                </div>
              </div>
              <div className="VirtualConsultation_gridpic1">
                <img
                  src={pic2}
                  className="VirtualConsultationfitsIcon"
                  alt="avatar"
                />{" "}
              </div>
            </div>

            <div className="VirtualConsultation_grid-itemInside2">
              <div className="lo">
                <div>
                  Asha and Dan you are now connected,Have a <br />
                  great hit.
                  <img
                    className="TalkToDoctorTick"
                    src={Tick3}
                    alt="tick"
                  ></img>
                </div>
              </div>
              <div className="VirtualConsultation_gridpic1">
                <img
                  src={pic3}
                  className="VirtualConsultationfitsIcon"
                  alt="avatar"
                />{" "}
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={() => handleClick()}
            className="VirtualConsultationBTn1"
          >
            Start chat Conversation
          </button>
        </div>
      </div>
    </>
  );
}

export default VirtualConsultationPage;
