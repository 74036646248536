import React from "react";
import apple from "../../../assets/Health_Checkups_Assests/apple.png";
import bg from "../../../assets/hra/bg.png";
import googleplaybadge from "../../../assets/talktodoctorworking/googleplaybadge.png";
import "./HraHealthRisk.css";
import { useNavigate } from "react-router-dom";
import Title from "../../Atoms/Title/Title";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import { Helmet } from "react-helmet";

function HraHealthRisk() {
  const navigate = useNavigate();

  function handleClick(e) {
    if (localStorage.getItem("token") !== null) {
      navigate("/health-risk-assessment-locked");
    } else {
       window.location.href = "/login"
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>Health Risk Assessment | YUVA</title>
        <meta
          name="description"
          content="Evaluate your health with our comprehensive risk assessment. Identify potential health risks and take proactive steps towards a healthier future."
        />
        <meta name="keywords" content="Risk Assessment" />
      </Helmet>
      <div className="HraHealthRisk_OuterContainer">
        <div className="HraHealthRiskHeadingImage">
          <div className="hraOpdMasking">
            <div className="hraOpdMaskingGridContainer">
              <div className="HraHealthRisk_Heading">
                Health Risk Assessment
              </div>
              <div className="hraOpdMaskingGriditem2">
                <div className="hraOpdMaskingGriditem2Container">
                  <div className="hraOpdMaskingContainerText1">
                    How is your Health doing today?
                  </div>
                  <div className="hraOpdMaskingContainerText2">
                    Check your Health Report Now.
                  </div>
                  <img className="HealthDoingImage" src={bg} alt="patterns"></img>
                  <button
                    className="hraOpdMaskingGriditem2ContainerButton"
                    onClick={() => handleClick()}
                  >
                    Get Your Report Today
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="HraHealthRisk_BlockTwo">
          <div className="HraHealthRisk_BlockTwoHeading">
            WHAT IS HEALTH RISK ASSESSMENT
          </div>
          <div className="HraHealthRisk_BlockTwoSubHeading">
            A health risk assessment, or HRA, sometimes called a health
            appraisal or health assessment, is a screening tool that helps
            individuals identify and understand their health risks and monitor
            health status over time. A health risk assessment includes a
            questionnaire, an assessment of health status, and personalised
            feedback about actions that can be taken to reduce risks, maintain
            health, and prevent disease.
          </div>
        </div>

        <div className="HraHealthRisk_BlockThree">
          <div
            style={{
              background:
                "linear-gradient(90.56deg, #1c71e1 0.48%, #c2d0e3 55.86%, #1c71e1 97.15%) 0% 0% / 100% no-repeat",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100%",
            }}
            className="CashlessOPDHeadingImage"
          >
            <div className="CashlessOPD_Heading1">
              <div className="OPDheading">
                Download our app to access your reports...
              </div>
              <div className="iconandroidios">
                <a
                  href="https://play.google.com/store/apps/details?id=com.yuva"
                  target="_blank"
                >
                  <img className="androidStore" src={googleplaybadge} alt="play store"></img>
                </a>
                <a
                  href="https://apps.apple.com/in/app/yuvahealth/id6449449413"
                  target="_blank"
                >
                  <img className="iosStore" src={apple} alt="app store"></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default HraHealthRisk;
