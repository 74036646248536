import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const ContinueSectionRequest = () => {
    return {
        type: types.CONTINUE_SECTION_REQUEST,
    }
}

export const ContinueSectionSuccess = (data) => {
    return {
        type: types.CONTINUE_SECTION_SUCCESS,
        data:data,
    }
}

export const ContinueSectionFailure = (data) => {
    return {
        type: types.CONTINUE_SECTION_FAILURE,
        data:data,
    }
}