import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"



export const  Section2AnswersSuccess = (data) => {
    return {
        type: types.SECTION2_ANSWERS_SUCCESS,
        data:data,
    }
}


