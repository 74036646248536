import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Cart/CreateCartActionType";
import * as actions from "../../Action/Cart/GetCartAction";
import NetworkService from "../../../network/Network/Cart/CartNetwork";

export function* getCartSaga(action) {

  try {
    let response = yield call(NetworkService.getCart, action);
    yield put(actions.getCartSuccess(response));
  } catch (err) {
    yield put(actions.getCartFailure(err));
  }
}

export function* watchGetCartSaga() {
  yield takeLatest(types.GET_CART_REQUEST, getCartSaga);
}
