import * as types from "../../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"



export const  Section4AnswersSuccess = (data) => {
    return {
        type: types.SECTION4_ANSWERS_SUCCESS,
        data:data,
    }
}


