import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Doctor from "../../../../assets/OTP/otpBackgroundImage.png";
import otpCutButton from "../../../../assets/common/otpCutButton.png";
import { UpdateProfileOtpValidationRequest } from "../../../../redux/Action/OTP/OTPValidation/UpdateProfileOTPValidationAction";
import { generateEmailOtpRequest } from "../../../../redux/Action/OTP/generateEmailOtpAction";
import "../OTP.css";

function UpdateProfileEmailOTPScreen(prop) {
  const [code, setCode] = useState("");
  const [isExpire, setExpire] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isWrong, setIsWrong] = useState(false);
  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();
  const [showExpireE, setShowExpireE] = useState(false);
  const [resendOTP, setResendOTP] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const handleChange = (code) => setCode(code);

  useEffect(() => {
    startCountdown();
  }, []);

  const dispatch = useDispatch();

  function startCountdown() {
    const startingMinutes = 0.5;
    let time = startingMinutes * 60;

    setInterval(() => {
      const minutes = Math.floor(time / 60);
      let seconds = time % 60;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      setTimerMinutes(minutes);
      setTimerSeconds(seconds);
      time--;
      if (time < 0) {
        setShowExpireE(true);
        setResendOTP(true);
      } else {
        setResendOTP(false);
      }
    }, 1000);
  }

  function resendOtp() {
    setIsWrong(false);
    dispatch(generateEmailOtpRequest(prop.email, prop.name));
    prop.setShow(false);

    Swal.fire({
      title: "Please wait!!",
      text: "Generating OTP for you... check your Email",
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  const UpdateProfileOtpValidation = useSelector(
    (state) => state.otp.UpdateProfileOtpValidation
  );
  useEffect(() => {
    if (UpdateProfileOtpValidation.isLoading) {
      setShowLoader(true);
    }
  }, [UpdateProfileOtpValidation]);

  useEffect(() => {
    if (isEmpty === true) {
      setTimeout(() => {
        setIsEmpty(false);
      }, 2000);
    }
  }, [isEmpty]);

  function handleSubmit() {
    if (code.length === 4) {
      var finalCode = parseInt(code);
      prop.OTPEmailCode(finalCode);
      dispatch(
        UpdateProfileOtpValidationRequest({ email: prop.email, otp: finalCode })
      );
    } else {
      setIsEmpty(true);
    }
  }

  if (UpdateProfileOtpValidation?.data?.data?.status === 200) {
    prop.closeOtpBox();
    UpdateProfileOtpValidation.data.data.status = null;
  }

  useEffect(() => {
    if (
      UpdateProfileOtpValidation?.error?.data?.response?.status === 400 &&
      UpdateProfileOtpValidation?.error?.data?.response?.data?.errorMessage ===
        "Invalid Otp"
    ) {
      setIsWrong(true);
      setExpire(false);
      setShowLoader(false);
      UpdateProfileOtpValidation.error.data.response.data.errorMessage = "";
    } else {
      setIsWrong(false);
    }
  }, [UpdateProfileOtpValidation]);

  useEffect(() => {
    if (
      UpdateProfileOtpValidation?.error?.data?.response?.status === 400 &&
      UpdateProfileOtpValidation?.error?.data?.response?.data?.errorMessage ===
        "Otp Expired"
    ) {
      setExpire(true);
      setIsWrong(false);

      setShowLoader(false);
      UpdateProfileOtpValidation.error.data.response.data.errorMessage = "";
    } else {
      setExpire(false);
    }
  }, [UpdateProfileOtpValidation]);

  return (
    <div className="mainOtpBoxNewVerifyMobile">
      <div className="mainOtpBox1New">
        <img src={Doctor} alt="doctor" />
      </div>
      <div className="mainOtpBox2New">
        <div className="upperOtp1New">
          <div onClick={prop.closeOtpBox}>
            <img src={otpCutButton} alt="cross" />
          </div>
        </div>
        <div className="upperOtp2New">Verify your Email ID</div>
        <div className="middleOtp1New">
          <div className="otpHeadingNew">OTP</div>
          <div className="otpBoxesNew">
            <OtpInput
              value={code}
              onChange={handleChange}
              className="OtpInput"
              containerStyle={{
                width: "100%",
                height: "100%",
              }}
              numInputs={4}
              separator={<span style={{ width: "8px" }}></span>}
              isInputNum={true}
              // shouldAutoFocus={true}
              inputStyle={{
                background: "#F3F0F0",
                border: "1px solid #1C71E1",
                borderRadius: "8px",
                width: "100%",
                height: "75px",
                fontSize: "26px",
                color: "#000",
                fontWeight: "400",
                caretColor: "blue",
                zIndex: "1",
              }}
              focusStyle={{
                border: "1px solid #CFD3DB",
                outline: "none",
              }}
            />
          </div>
        </div>
        <div>
          {isExpire ? (
            <div className="expireMessage">
              <p>OTP Expired.</p>
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          {isWrong ? (
            <div className="wrongOtpMessage">
              <p>Incorrect OTP</p>
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          {isEmpty ? (
            <div className="wrongOtpMessage">
              <p>Please fill the OTP</p>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="otpTimerNew">
          <div className="otpTimer1New"></div>
          <div className="otpTimer2New">
            {resendOTP ? (
              <p id="resendOtp" className="resendOtp" onClick={resendOtp}>
                Resend OTP
              </p>
            ) : (
              <p id="resendOtp" className="resendOtp" onClick={resendOtp}></p>
            )}
            <div className="expire">
              {showExpireE ? (
                ""
              ) : (
                <p id="timeShow">
                  {timerMinutes}:{timerSeconds}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="bottomOtpNew">
          <button
            onClick={handleSubmit}
            style={showLoader ? { background: "transparent" } : {}}
            disabled={showLoader ? true : false}
          >
            {showLoader ? <CircularProgress /> : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default UpdateProfileEmailOTPScreen;
