import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/User/userActionType";
import * as actions from "../../Action/User/UpdateProfile";
import NetworkService from "../../../network/Network/User/userNetwork";

export function* UpdateProfileSaga(action) {
  try {
    let response = yield call(NetworkService.updateProfile, action);
    yield put(actions.UpdateProfileSuccess(response));
  } catch (err) {
    yield put(actions.UpdateProfileFailure(err));
  }
}

export function* watchUpdateProfileSaga() {
  yield takeLatest(types.UPDATE_PROFILE_REQUEST, UpdateProfileSaga);
}