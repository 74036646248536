import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Plan/PlanActionType";
import * as actions from "../../Action/Plan/PlanTypeDropdown";
import NetworkService from "../../../network/Network/Plan/GetPLanDetailNetwork";

export function* getPlanTypeDropdownSaga(action) {
  try {
    let response = yield call(NetworkService.getPlanTypeDropdown, action);
    yield put(actions.getPlanTypeDropdownSuccess(response));
  } catch (err) {
    yield put(actions.getPlanTypeDropdownFailure(err));
  }
}

export function* watchgetPlanTypeDropdownSaga() {
  yield takeLatest(types.PLAN_TYPE_DROPDOWN_REQUEST, getPlanTypeDropdownSaga);
}
