import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Cart/CreateCartActionType";
import * as actions from "../../Action/Cart/CreateOrderAction";
import NetworkService from "../../../network/Network/Cart/CartNetwork";

export function* createOrderSaga(action) {
  try {
    let response = yield call(NetworkService.createOrder, action);
    yield put(actions.createOrderSuccess(response));
  } catch (err) {
    yield put(actions.createOrderFailure(err));
  }
}

export function* watchCreateOrderSaga() {
  yield takeLatest(types.CREATE_ORDER_REQUEST, createOrderSaga);
}
