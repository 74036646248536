import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { Input } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { hospitalNetworkFormRequest } from "../../../redux/Action/Network/hospitalNetworkFormAction";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import "./Form.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function HospitalForm() {
  const [fileDoctorList, setDoctorList] = useState();
  const [fileRateList, setFileRateList] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileHospitalPhoto, setFileHospitalPhoto] = useState();
  const [errorDoctorList, setErrorDoctorList] = useState(false);
  const [errorRateList, setErrorRateList] = useState(false);
  const [errorHospitalPhoto, setErrorHospitalPhoto] = useState(false);

  const dispatch = useDispatch();
  const hospitalNetworkForm = useSelector(
    (state) => state.network.hospitalNetworkForm
  );
  const loginSchema = Yup.object().shape({
    Email: Yup.string()
      .matches(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
        "Enter a Valid E-mail Address"
      )
      .required("Email is required"),
    Name: Yup.string().trim().required("Required Field"),
    EntityType: Yup.string().required("Required Field"),
    OwnerName: Yup.string().trim().required("Required Field"),
    Address: Yup.string().trim().required("Required Field"),
    Location: Yup.string().trim().required("Required Field"),
    City: Yup.string().required("Required Field"),
    Pincode: Yup.string()
      .matches(/^[0-9\b]+$/, "Enter valid pincode")
      .min(6, "Must be exactly 6 digits")
      .max(6, "Must be exactly 6 digits")
      .required("Required Field"),
    ContactNumber: Yup.string()
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits")
      .required("Required Field"),
    Speciality: Yup.string().required("Required Field"),
    NoOfBeds: Yup.string()
      .required("Required Field")
      .test(
        "Is positive?",
        "The number must be greater than 0!",
        (value) => value > 0
      ),
  });

  const Servicesdata = [
    { name: "OPD", id: 1 },
    { name: "IPD", id: 2 },

    { name: "Pathology(Biochemistry,Microbiology, Pathology)", id: 3 },

    { name: "Radiology", id: 4 },
    { name: "CT", id: 5 },

    { name: "MRI", id: 6 },

    { name: "ECHO", id: 7 },
  ];

  useEffect(() => {
    if (hospitalNetworkForm?.error?.data?.response?.status === 400) {
      Swal.fire({
        title: hospitalNetworkForm.error?.data.response?.data.errorMessage,
        icon: "error",
      }).then(() => {
        setIsSubmitting(false);
      });
    }
  }, [hospitalNetworkForm?.error]);

  const viewCity = useSelector((state) => state.city.viewAllCity);
  const [service, setService] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setService(typeof value === "string" ? value.split(",") : value);
  };

  const [check, setCheck] = useState(false);
  
  const handleSubmit = async (values, { setSubmitting }) => {
    setCheck(true);
    setIsSubmitting(true);
    let serviceUuidArray = [];
    service.forEach((x) => {
      Servicesdata.forEach((item) => {
        if (item.name === x) {
          serviceUuidArray.push(item.name);
        }
      });
    });

    const hospitalData = {
      address: values.Address,
      cityId: values.City,
      contactNumber: values.ContactNumber,
      email: values.Email,
      entityType: values.EntityType,
      location: values.Location,
      name: values.Name,
      noOfBeds: values.NoOfBeds,
      outSourceServices: values.OutsourceServices,
      ownerName: values.OwnerName,
      pinCode: values.Pincode,
      services: serviceUuidArray.toString(),
      speciality: values.Speciality,
    };

    const newFormData = new FormData();
    newFormData.append(
      "hospitalPartnerRequestDto",
      JSON.stringify(hospitalData)
    );

    if (fileDoctorList && fileRateList && fileHospitalPhoto) {
      newFormData.append("doctorList", fileDoctorList);
      newFormData.append("uploadRateList", fileRateList);
      newFormData.append("hospitalPhoto", fileHospitalPhoto);

      try {
        await dispatch(hospitalNetworkFormRequest(newFormData));

        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  const hospitalResponse = useSelector(
    (state) => state.network.hospitalNetworkForm
  );

  useEffect(() => {
    if (hospitalResponse.data?.data?.status === 200) {
      Swal.fire({
        title: hospitalResponse?.data?.data?.data.message,
        icon: "success",
      }).then(() => {
        setIsSubmitting(false);
        window.location.reload();
      });
    }
  }, [hospitalResponse.data]);

  function preventnumberinput(e) {
    var keycode = e.keycode ? e.keycode : e.which;
    if ((keycode > 47 && keycode < 58) || (keycode > 95 && keycode < 107)) {
      e.preventDefault();
    }
  }
  function waitForLoad(id, callback) {
    var timer = setInterval(function () {
      if (
        document.getElementById("fileUploadInput") &&
        document.getElementsByClassName("file-upload-select")[0]
      ) {
        clearInterval(timer);
        callback();
      }
    }, 200);
  }
  waitForLoad("subm", function () {
    let fileInput = document.getElementById("fileUploadInput");
    let fileInput2 = document.getElementById("fileUploadInput1");
    let fileInput3 = document.getElementById("fileUploadInput3");

    fileInput.onchange = function () {
      let filename = fileInput.files[0].name;
      let selectName = document.getElementsByClassName("file-select-name")[0];
      selectName.innerText = filename;
    };
    fileInput2.onchange = function () {
      let filename = fileInput2.files[0].name;
      let selectName = document.getElementsByClassName("file-select-name2")[0];
      selectName.innerText = filename;
    };
    fileInput3.onchange = function () {
      let filename = fileInput3.files[0].name;
      let selectName = document.getElementsByClassName("file-select-name3")[0];
      selectName.innerText = filename;
    };
  });

  const handleFileDoctorListChange = (event) => {
    if (
      event.target.files[0].type === "application/pdf" ||
      event.target.files[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      event.target.files[0].type === "application/vnd.ms-excel"
    ) {
      setDoctorList(event.target.files[0]);
      setErrorDoctorList(false);
    } else {
      setErrorDoctorList(true);
    }
  };

  const handleFileRateListChange = (event) => {
    if (
      event.target.files[0].type === "application/pdf" ||
      event.target.files[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      event.target.files[0].type === "application/vnd.ms-excel"
    ) {
      setFileRateList(event.target.files[0]);
      setErrorRateList(false);
    } else {
      setErrorRateList(true);
    }
  };

  const handleFileHospitalPhotoChange = (event) => {
    if (
      event.target.files[0].type === "image/png" ||
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "image/jpg"
    ) {
      setFileHospitalPhoto(event.target.files[0]);
      setErrorHospitalPhoto(false);
    } else {
      setErrorHospitalPhoto(true);
    }
  };

  if (hospitalResponse.isLoading) {
    return <YuvaLoader />;
  }

  return (
    <div>
      {/* {hospitalResponse.isLoading && <Loader />}{" "} */}
      <Formik
        initialValues={{
          Email: "",
          Name: "",
          EntityType: "",
          OwnerName: "",
          Address: "",
          Location: "",
          City: "",
          Pincode: "",
          ContactNumber: "",
          Speciality: "",
          NoOfBeds: "",
          Services: "",
          OutsourceServices: "",
        }}
        enableReinitialize={true}
        validationSchema={loginSchema}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <Form>
            <div className="NetworkPageFormInput">
              <label>
                <Field
                  placeholder="Email"
                  name="Email"
                  type="text"
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="Email"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Name of the Hospital"
                  name="Name"
                  type="text"
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="Name"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  name="EntityType"
                  as="select"
                  placeholder="Select Entity Type"
                  className="NetworkPageFormInputField"
                >
                  <option value="" label="Entity type" disabled>
                    Select type{" "}
                  </option>
                  <option value="Private Ltd." label="Private Ltd.">
                    {" "}
                  </option>
                  <option value="LLP" label="LLP"></option>
                  <option value="Propritership" label="Propritership"></option>
                  <option value="Partnership" label="Partnership"></option>
                  <option value="Other" label="Other"></option>
                </Field>
                <ErrorMessage
                  name="EntityType"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Name of the Owner/Managing Director"
                  name="OwnerName"
                  type="text"
                  className="NetworkPageFormInputField"
                  onKeyDown={(event) => preventnumberinput(event)}
                ></Field>
                <ErrorMessage
                  name="OwnerName"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Address"
                  name="Address"
                  type="text"
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="Address"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Location"
                  name="Location"
                  type="text"
                  className="NetworkPageFormInputField"
                  onKeyDown={(event) => preventnumberinput(event)}
                ></Field>
                <ErrorMessage
                  name="Location"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  name="City"
                  as="select"
                  placeholder="Select City"
                  className="NetworkPageFormInputField"
                >
                  <option value="" disabled selected>
                    Select City
                  </option>
                  {viewCity?.data?.data?.data?.data.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="City"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Pincode"
                  name="Pincode"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="Pincode"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Contact Number"
                  name="ContactNumber"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="ContactNumber"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  name="Speciality"
                  as="select"
                  placeholder="Select Speciality"
                  className="NetworkPageFormInputField"
                >
                  <option value="" label="Speciality" disabled>
                    Select Speciality{" "}
                  </option>
                  <option
                    value="Single Speciality"
                    label="Single Speciality "
                  ></option>
                  <option
                    value="Multi Speciality "
                    label="Multi Speciality "
                  ></option>
                </Field>
                <ErrorMessage
                  name="Speciality"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <Field
                  placeholder="Number of Beds"
                  name="NoOfBeds"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="NoOfBeds"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <div class="file-upload">
                  <div class="file-upload-select">
                    <div class="file-select-button">
                      Upload the List of Doctor with Speciality
                    </div>
                    <div class="file-select-name">
                      Supported format PDF and Excel{" "}
                    </div>
                    <Input
                      type="file"
                      name="DoctorWithSpeciality"
                      id="fileUploadInput"
                      accept="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      className="NetworkPageFormInputField"
                      onChange={(event) => handleFileDoctorListChange(event)}
                    />
                  </div>
                  {errorDoctorList && (
                    <div className="errorFile">
                      Please Select only .pdf or .xlsx Extensions
                    </div>
                  )}
                </div>

                {fileDoctorList?.size / 1024 > 10240 && (
                  <div className="FileSizedisable">
                    {" "}
                    File Size should be less than 10mb
                  </div>
                )}
                {fileDoctorList == null && check && (
                  <div className="FileSizedisable"> Select the file</div>
                )}
              </label>

              <label>
                <div class="file-upload">
                  <div class="file-upload-select">
                    <div class="file-select-button">Upload Rate List</div>
                    <div class="file-select-name2">
                      Supported format PDF and Excel{" "}
                    </div>
                    <Input
                      type="file"
                      name="RateList"
                      id="fileUploadInput1"
                      accept="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      className="NetworkPageFormInputField"
                      onChange={(event) => handleFileRateListChange(event)}
                    />
                  </div>
                  {errorRateList && (
                    <div className="errorFile">
                      Please Select only .pdf or .xlsx Extensions
                    </div>
                  )}
                </div>

                {fileRateList?.size / 1024 > 10240 && (
                  <div className="FileSizedisable">
                    {" "}
                    File Size should be less than 10mb
                  </div>
                )}
                {fileRateList == null && check && (
                  <div className="FileSizedisable"> Select the file</div>
                )}
              </label>

              <label>
                <div class="file-upload">
                  <div class="file-upload-select">
                    <div class="file-select-button">
                      Upload Photo of Hospital
                    </div>
                    <div class="file-select-name3">
                      Supported format .jpg and .png
                    </div>
                    <Input
                      type="file"
                      name="HospitalPhoto"
                      accept="image/png, image/jpeg ,image/jpg"
                      id="fileUploadInput3"
                      className="NetworkPageFormInputField"
                      onChange={(event) => handleFileHospitalPhotoChange(event)}
                    />
                  </div>
                  {errorHospitalPhoto && (
                    <div className="errorFile">
                      Please Select only .png or .jpeg or .jpg Extensions
                    </div>
                  )}
                </div>

                {fileHospitalPhoto?.size / 1024 > 10240 && (
                  <div className="FileSizedisable">
                    {" "}
                    File Size should be less than 10mb
                  </div>
                )}
                {fileHospitalPhoto == null && check && (
                  <div className="FileSizedisable"> Select the file</div>
                )}
              </label>

              <label>
                <Field
                  placeholder="List of outsource services (optional)"
                  name="OutsourceServices"
                  type="text"
                  className="NetworkPageFormInputField"
                ></Field>
                <ErrorMessage
                  name="OutsourceServices"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>

              <label>
                <div className="formControl">
                  <FormControl sx={{ mL: 1, width: "80%" }}>
                    <InputLabel id="demo-multiple-checkbox-label ">
                      Select Services
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      // sx={{width:400}}
                      name="Services"
                      value={service}
                      onChange={handleChange}
                      input={<OutlinedInput label=" Select Services" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {Servicesdata?.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          <Checkbox checked={service.indexOf(item.name) > -1} />
                          <ListItemText primary={item.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <ErrorMessage
                  name="Services"
                  className="FormErrorMesage"
                  component="div"
                />
              </label>
            </div>

            <div className="NetworkPageFormSubmit">
              <button
                type="submit"
                className="NetworkPageFormSubmitButton"
                disabled={
                  isSubmitting ||
                  (errorRateList || errorHospitalPhoto || errorDoctorList
                    ? true
                    : false)
                }
              >
                Submit
              </button>
            </div>
          </Form>
        )}
        {/* } */}
      </Formik>
    </div>
  );
}

export default HospitalForm;
