import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './redux/Reducer/rootReducer';
import createSagaMiddleware from 'redux-saga'
import rootSaga from './redux/Saga/rootSaga'

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer:rootReducer,
  middleware:() => [sagaMiddleware],
});

sagaMiddleware.run(rootSaga)

export default store