export const PACKAGE_LAB_TEST_SUCCESS = "PACKAGE_LAB_TEST_SUCCESS"
export const PACKAGE_LAB_TEST_FAILURE = "PACKAGE_LAB_TEST_FAILURE"
export const PACKAGE_LAB_TEST_REQUEST = "PACKAGE_LAB_TEST_REQUEST"


export const PACKAGE_SELECT_SUCCESS = "PACKAGE_SELECT_SUCCESS"
export const PACKAGE_SELECT_FAILURE = "PACKAGE_SELECT_FAILURE"
export const PACKAGE_SELECT_REQUEST = "PACKAGE_SELECT_REQUEST"


export const DIAGNOSTIC_TEST_SELECT_SUCCESS = "DIAGNOSTIC_TEST_SELECT_SUCCESS"
export const DIAGNOSTIC_TEST_SELECT_FAILURE = "DIAGNOSTIC_TEST_SELECT_FAILURE"
export const DIAGNOSTIC_TEST_SELECT_REQUEST = "DIAGNOSTIC_TEST_SELECT_REQUEST"

export const LIFESTYLE_PACKAGE_SUCCESS = "LIFESTYLE_PACKAGE_SUCCESS"
export const LIFESTYLE_PACKAGE_FAILURE = "LIFESTYLE_PACKAGE_FAILURE"
export const LIFESTYLE_PACKAGE_REQUEST = "LIFESTYLE_PACKAGE_REQUEST"

export const LIFESTYLE_PACKAGE_VIEWALL_SUCCESS = "LIFESTYLE_PACKAGE_VIEWALL_SUCCESS"
export const LIFESTYLE_PACKAGE_VIEWALL_FAILURE = "LIFESTYLE_PACKAGE_VIEWALL_FAILURE"
export const LIFESTYLE_PACKAGE_VIEWALL_REQUEST = "LIFESTYLE_PACKAGE_VIEWALL_REQUEST"


export const PACKAGE_GET_TEST_SUCCESS = "PACKAGE_GET_TEST_SUCCESS"
export const PACKAGE_GET_TEST_FAILURE = "PACKAGE_GET_TEST_FAILURE"
export const PACKAGE_GET_TEST_REQUEST = "PACKAGE_GET_TEST_REQUEST"

export const GET_TEST_PACKAGE_LISTING_SUCCESS = "GET_TEST_PACKAGE_LISTING_SUCCESS"
export const GET_TEST_PACKAGE_LISTING_FAILURE = "GET_TEST_PACKAGE_LISTING_FAILURE"
export const GET_TEST_PACKAGE_LISTING_REQUEST = "GET_TEST_PACKAGE_LISTING_REQUEST"
