import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { viewProductByCategoryIdRequest } from '../../../redux/Action/EcomUser/viewProductByCategoryIdAction'
import ScrollToTop from '../../Molecules/ScrollToTop/ScrollToTop'
import ProductHero from '../../Organisms/Products/ProductHero'
import ProductList from '../../Organisms/Products/ProductList'
import Title from '../../Atoms/Title/Title'

function ProductListing() {
  const search = useLocation().search;

  const categoryId = new URLSearchParams(search).get("category-id");

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(viewProductByCategoryIdRequest(categoryId))
  }, [categoryId,dispatch])

  const productInfo = useSelector((state) => state.eCom.viewProductByCategoryId)

  return (
    <div>
      <ScrollToTop />
      {productInfo.data?.data.data.data.categoryName &&
        <Title title={`Yuva | ${productInfo.data?.data.data.data.categoryName}`} />
      }
      <ProductHero
        name={productInfo.data?.data.data.data.categoryName}
        image={productInfo.data?.data.data.data.imageFilepath} />
      {productInfo.data?.data.data.data.productList.map((item, index) => (
        <div style={{ padding: "0 2vw" }}>
          <ProductList product={item.productResponseDtoForUserList} subCategoryName={item.subCategoryName} />
        </div>
      ))}
    </div>
  )
}

export default ProductListing


