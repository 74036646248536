import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import storage from "../../../Utils/storage";
import prescriptionFile from "../../../assets/MyAccount/prescriptionFile.png";
import { myPrescriptionRequest } from "../../../redux/Action/Account/MyPrescriptionAction";
import Title from "../../Atoms/Title/Title";
import "./MyPrescription.css";
import NoPrescription from "./NoPrescription";

function MyPrescription() {
  const search = useLocation().search;
  const serviceUuid = new URLSearchParams(search).get("serviceUuid");
  const [id, setId] = useState(new URLSearchParams(search).get("id"));

  const [dropdown, setDropdown] = useState(
    serviceUuid || "0641b94d-16c4-430e-93ce-7877123d0574"
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleChangeServiceUuid = (newServiceUuid) => {
    if (newServiceUuid !== serviceUuid) {
      const newSearch = new URLSearchParams(location.search);
      newSearch.set("serviceUuid", newServiceUuid);
      const newLocation = {
        ...location,
        search: "",
      };
      navigate(newLocation, { replace: true });
    }
  };

  if (storage.getStorage("token") === null) {
    navigate("/");
  }
  const removeSearchParam = () => {
    if (serviceUuid) {
      handleChangeServiceUuid();
    }
  };
  useEffect(() => {
    if (serviceUuid) {
      setDropdown(serviceUuid);
    }
  }, [serviceUuid]);

  useEffect(() => {
    dispatch(myPrescriptionRequest(dropdown, id));
  }, [dispatch, dropdown, id]);

  const handleSelectChange = (e) => {
    removeSearchParam();
    setDropdown(e.target.value);
    setId(null);
  };

  const handleViewAll = () => {
    setId(null);
    removeSearchParam();
    setDropdown(serviceUuid);
  };

  const myPrescription = useSelector((state) => state.account.myPrescription);

  return (
    <>
      <Title title="YUVA | Prescription" />
      <>
        <div className="myprescriptionmaindiv">
          <div className="myprescriptiontext">MY PRESCRIPTIONS</div>
          &nbsp;&nbsp;
          <div className="myprescriptionalltexts">
            <div className="myprescriptionalltextbtn">
              <div className="myprescriptionalltextget">
                Get a comprehensive understanding about your health through Yuva
                Health Reports.
              </div>
              <select
                onChange={(e) => handleSelectChange(e)}
                value={dropdown}
                className="myPrescriptionDropdown"
              >
                <option value="0641b94d-16c4-430e-93ce-7877123d0574">
                  APPOINTMENT
                </option>
                <option value="bb4385d4-7f92-11ed-a1eb-0242ac120002">
                  TALK TO DOCTOR
                </option>
              </select>
            </div>
            {myPrescription?.data === null ||
            myPrescription?.data.data.data.data.prescriptionResponseDto
              .length === 0 ? (
              <NoPrescription />
            ) : (
              <div className="templateNamesPres">
                {myPrescription?.data?.data?.data.data.prescriptionResponseDto.map(
                  (item, index) => (
                    <div className="prescriptionBoxTexts">
                      <div className="prescriptionBoxTextsStarts">
                        <img
                          className="prescriptionFile"
                          src={prescriptionFile}
                          alt="Prescription File"
                        ></img>

                        <div className="presText">
                          <div className="prescriptionPre"></div>

                          <div className="prescriptionPre">
                            &nbsp;
                            {item.fileName}
                          </div>
                        </div>
                      </div>
                      <div className="DownloadPresc">
                        <div className="genDate">
                          Generated On -{" "}
                          {new Date(item.timeSlot).toLocaleString()}
                        </div>
                        <div className="downloadIcon">
                          <a href={item.filePath} download="Prescreption">
                            Download
                          </a>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
            {id !== null && (
              <button
                className="myprescriptionalltextbtnViewAll"
                onClick={handleViewAll}
              >
                View All Reports
              </button>
            )}
          </div>
        </div>
      </>
    </>
  );
}

export default MyPrescription;
