import React, { useEffect, useState } from "react";
import HraHeaderPic from "../../../assets/hra/HraHeaderPic.png";
import HraLandingGridPic from "../../../assets/hra/HraLandingGridPic.png";
import "./HraLanding.css";
import apple from "../../../assets/Health_Checkups_Assests/apple.png";
import hraHeader3Pic from "../../../assets/hra/hraHeader3Pic.png";
import googleplaybadge from "../../../assets/talktodoctorworking/googleplaybadge.png";
import Footer from "../../Organisms/Footer/Footer";
import HraHealthRisk from "./HraHealthRisk";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";

function HraLanding() {
  const navigate = useNavigate();

  function handleClick(e) {
    if (localStorage.getItem("token") !== null) {
      navigate("/health-risk-assessment-locked")
    } else {
      window.location.href = "/login"
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div>
      <ScrollToTop />
      {/* on mobile screen */}
      <>
        <HraHealthRisk />
        <div className="HraLandingBody">
          <div className="HraLandingHeading2">
            BENEFITS OF HEALTH RISK ASSESSMENT
          </div>

          <div className="HraLandingwrapper">
            <div className="HraLandingGridOne">
              <div className="HraLandingpoints1">
                <ol>
                  <li className="HraLandingb1">
                    Demographic characteristics – age, gender
                  </li>
                  <li className="HraLandingb1">
                    Lifestyle behaviours – exercise, eating habits, alcohol
                    and tobacco use
                  </li>
                  <li className="HraLandingb1">
                    Emotional health – mood, stress, life events
                  </li>
                  <li className="HraLandingb1">
                    Physical health – weight, blood pressure, cholesterol
                    levels
                  </li>
                  <li className="HraLandingb1">
                    Current and previous health conditions
                  </li>
                  <li className="HraLandingb1">Preventive screenings</li>
                  <li className="HraLandingb1">
                    Readiness to change behaviours to improve health
                  </li>
                </ol>
              </div>
            </div>
            <div
              className="HraLandingGridTwo"
              style={{
                backgroundImage: `url(${HraLandingGridPic})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100%",
              }}
            ></div>
          </div>
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <div className="HraLandingPara">
              Our HRA tool helps corporate to identify the health condition of
              individual and corporates. It allow the corporate to select
              program offering to the organization which can benefit them by
              allowing lifestyle changes and preventive healthcare checkups.
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default HraLanding;
