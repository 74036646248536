import * as types from "../../Config/User/userActionType";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const addRelationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.ADD_RELATION_SUCCESS:
      return {
        ...initialState,
        data: action,
      };
    case types.ADD_RELATION_FAILURE:
      return {
        ...initialState,
        error: action,
      };
    case types.ADD_RELATION_REQUEST:
      return {
        ...initialState,
        isLoading: true,
      };
      case types.ADD_RELATION_CLEAR_REQUEST:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
