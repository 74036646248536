import * as types from "../../Config/Plan/PlanActionType";

export const getPlanDetailRequest = (data) => {
  return {
    type: types.GET_PLAN_DETAIL_REQUEST,
    data:data,
  };
};

export const getPlanDetailSuccess = (data) => {
  return {
    type: types.GET_PLAN_DETAIL_SUCCESS,
    data: data,
  };
};

export const getPlanDetailFailure = (data) => {
  return {
    type: types.GET_PLAN_DETAIL_FAILURE,
    data: data,
  };
};
