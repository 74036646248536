import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DownArrow from "../../../assets/SubcriptionPageAssets/DownArrow.png";
import UpArrow from "../../../assets/SubcriptionPageAssets/UpArrow.png";
import { getPopularPlanRequest } from "../../../redux/Action/Landing/getPopularPlanAction";
import { getPlanServicesRequest } from "../../../redux/Action/Plan/GetPlanServices";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import createSlug from "../../Molecules/SlugGenerator/CreateSlug";
import "./subscriptionBody.css";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";

export default function SubscriptionBody() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getPopularPlan = useSelector((state) => state.landing.getPopularPlan);
  const getPlanServices = useSelector((state) => state.plan.getPlanServices);
  const [selectedPlan, setSelectedPlan] = useState();

  useEffect(() => {
    dispatch(getPopularPlanRequest());
  }, [dispatch]);

  const handleShowButtonClick = (index, uuid) => {
    if (selectedPlan !== index) {
      setSelectedPlan(index);
    } else {
      setSelectedPlan("");
    }
    dispatch(getPlanServicesRequest(uuid));
  };

  const handleBuyNow = async (index) => {
    try {
      const selectedItem = getPopularPlan?.data?.data?.data?.data[index];
      const slug = await createSlug(selectedItem.name);
      navigate(`/plan/${slug}?id=${selectedItem.planUuid}`);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (error) {}
  };

  const getPriceDisplay = (finalCost, price) => {
    if (finalCost > 0) {
      return finalCost === price ? (
        <div>₹{finalCost}</div>
      ) : (
        <div className="">
          <del className="OurPLanYearlyPricedel">₹{price}</del>
          <div>₹{finalCost}</div>
        </div>
      );
    }
    return null;
  };

  if (getPopularPlan.isLoading || getPopularPlan.data === null) {
    return (
      <>
        <ScrollToTop />
        <YuvaLoader />
      </>
    );
  }

  return (
    <div className="subscriptionBody">
      <div className="subscriptionHead">Empowering Your Health: Our Plans</div>
      <div className="subscriptionDesc">
        Empowering You To Live Your Best Life Through Holistic Health Approaches
      </div>
      <div>
        <div className="popularPlanBody">
          {getPopularPlan.data?.data.data.data.map((item, index) => (
            <div
              className="popularCardWrapper"
              key={index}
              onClick={() => handleShowButtonClick(index, item.planUuid)}
            >
              <div className="popularPlanCard" key={item.planUuid}>
                <div className="popularPlanCardBody">
                  <div className="popularPlanCardTitle">{item.name}</div>
                  {(item.quarterlyFinalCost > 0 && "Quarterly") ||
                    (item.halfYearlyFinalCost > 0 && "Half Yearly") ||
                    (item.yearlyFinalCost > 0 && "Yearly")}
                  {getPriceDisplay(
                    item.quarterlyFinalCost,
                    item.quarterlyPrice
                  ) ||
                    getPriceDisplay(
                      item.halfYearlyFinalCost,
                      item.halfYearlyPrice
                    ) ||
                    getPriceDisplay(item.yearlyFinalCost, item.yearlyPrice)}
                  <div className="popularPlanCardRelation">
                    {item.relationsAllowed !== null &&
                    item.showRelative !== null
                      ? item.showRelative.split(",").join(", ")
                      : item.showRelative === null
                      ? item.relationsAllowed.split(",").join(", ")
                      : item.showRelative.split(",").join(", ")}
                  </div>
                </div>
                <div className="popularPlanCardArrow">
                  {selectedPlan === index ? (
                    <img src={UpArrow} alt="Up arrow" />
                  ) : (
                    <img src={DownArrow} alt="Down arrow" />
                  )}
                </div>
              </div>
              {selectedPlan === index && (
                <>
                  <div className="popularPlanCardInfo">
                    {getPlanServices?.data?.data?.data?.data.map(
                      (item, index) => (
                        <div key={index}>
                          <div className="popularPlanCardInfoTitle">
                            {" "}
                            {item.name}
                          </div>
                          <p className="popularPlanCardInfoAvailable">
                            {item.allocatedCount}
                          </p>
                        </div>
                      )
                    )}
                  </div>
                  <div className="popularPlanCardInfoButton">
                    <button onClick={() => handleBuyNow(index)}>Buy Now</button>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
