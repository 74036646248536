export const VIEW_MEDICAL_RECORD_REQUEST = "VIEW_MEDICAL_RECORD_REQUEST"
export const VIEW_MEDICAL_RECORD_SUCCESS = "VIEW_MEDICAL_RECORD_SUCCESS"
export const VIEW_MEDICAL_RECORD_FAILURE = "VIEW_MEDICAL_RECORD_FAILURE"

export const MEDICAL_RECORD_DROPDOWN_REQUEST = "MEDICAL_RECORD_DROPDOWN_REQUEST"
export const MEDICAL_RECORD_DROPDOWN_SUCCESS = "MEDICAL_RECORD_DROPDOWN_SUCCESS"
export const MEDICAL_RECORD_DROPDOWN_FAILURE = "MEDICAL_RECORD_DROPDOWN_FAILURE"

export const  DOWNLOAD_ERM_REPORT_REQUEST = " DOWNLOAD_ERM_REPORT_REQUEST"
export const DOWNLOAD_ERM_REPORT_SUCCESS =  "DOWNLOAD_ERM_REPORT_SUCCESS"
export const DOWNLOAD_ERM_REPORT_FAILURE =  "DOWNLOAD_ERM_REPORT_FAILURE"

export const MEDICAL_RECORD_FORM_REQUEST ="MEDICAL_RECORD_FORM_REQUEST"
export const MEDICAL_RECORD_FORM_SUCCESS= "MEDICAL_RECORD_FORM_SUCCESS"
export const MEDICAL_RECORD_FORM_FAILURE = "MEDICAL_RECORD_FORM_FAILURE"