export const Cart = {
    Cart: "/cart",
    Checkout : "/cart-checkout",
    CheckoutPlan : "/cart-checkout-plan",
    SelectMember: "/cart-checkout-select-member",
    SelectDateAndTime : "/cart-checkout-select-date-and-time",
    FinalPaymentPage:"/cart-payment",
    FinalPaymentPlan:"/cart-payment-plan",
    LoadingPayment : "/LoadingPayment",
    CancelPayment : "/cancelPayment",   
    ConfirmPayment:"/confirmPayment",
    ConfirmedZeroPayment:"/ConfirmedZeroPayment"
}