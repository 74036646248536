import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Cart/CreateCartActionType";
import * as actions from "../../Action/Cart/PaymentGatewayRedirectRequest";
import NetworkService from "../../../network/Network/Cart/CartNetwork";

export function* paymentGatewayRedirectSaga(action) {

  try {
    let response = yield call(NetworkService.paymentGatewayRedirect, action);
    yield put(actions.paymentGatewayRedirectSuccess(response));
  } catch (err) {
    yield put(actions.paymentGatewayRedirectFailure(err));
  }
}

export function* watchPaymentGatewayRedirectSaga() {
  yield takeLatest(types.PAYMENT_GATEWAY_REDIRECT_REQUEST, paymentGatewayRedirectSaga);
}
