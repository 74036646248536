import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/EcomUser/EcomUserActionType";
import * as actions from "../../Action/EcomUser/viewProductByIdAction";
import NetworkService from "../../../network/Network/EcomUser/EcomUserNetwork";

export function* viewProductByIdSaga(action) {
  try {
    let response = yield call(NetworkService.viewProductById, action);
    yield put(actions.viewProductByIdSuccess(response));
  } catch (err) {
    yield put(actions.viewProductByIdFailure(err));
  }
}

export function* watchViewProductByIdSaga() {
  yield takeLatest(types.VIEW_PRODUCT_BY_ID_REQUEST, viewProductByIdSaga);
}