import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

function ProductImageCarousel({ mapData }) {
  const productImageSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="productImageCarousel">
      <Slider {...productImageSettings} className="productImageSlider">
        {mapData?.map((item) => (
          <img src={item.imageFilepath} alt="Product" />
        ))}
      </Slider>
    </div>
  );
}

export default ProductImageCarousel;
