export const GET_ORDER_DETAIL_SUCCESS = "GET_ORDER_DETAIL_SUCCESS"
export const GET_ORDER_DETAIL_FAILURE = "GET_ORDER_DETAIL_FAILURE"
export const GET_ORDER_DETAIL_REQUEST = "GET_ORDER_DETAIL_REQUEST"

export const GET_ALL_ORDER_HISTORY_SUCCESS = "GET_ALL_ORDER_HISTORY_SUCCESS"
export const GET_ALL_ORDER_HISTORY_FAILURE = "GET_ALL_ORDER_HISTORY_FAILURE"
export const GET_ALL_ORDER_HISTORY_REQUEST = "GET_ALL_ORDER_HISTORY_REQUEST"

export const GET_PLAN_SUBSCRIPTION_SUCCESS = "GET_PLAN_SUBSCRIPTION_SUCCESS"
export const GET_PLAN_SUBSCRIPTION_FAILURE = "GET_PLAN_SUBSCRIPTION_FAILURE"
export const GET_PLAN_SUBSCRIPTION_REQUEST = "GET_PLAN_SUBSCRIPTION_REQUEST"

export const GET_PROGRAM_SUBSCRIPTION_SUCCESS = "GET_PROGRAM_SUBSCRIPTION_SUCCESS"
export const GET_PROGRAM_SUBSCRIPTION_FAILURE = "GET_PROGRAM_SUBSCRIPTION_FAILURE"
export const GET_PROGRAM_SUBSCRIPTION_REQUEST = "GET_PROGRAM_SUBSCRIPTION_REQUEST"

