import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/ContactUsPage/ContactUsPageActionType";
import * as actions from "../../Action/ContactUsPage/WriteToUsAction";
import NetworkService from "../../../network/Network/ContactUsPage/ContactUsPageNetwork";
export function* WriteToUsSaga(action) {

  try {
    let response = yield call(NetworkService.WriteToUs, action);
    yield put(actions.WriteToUsSuccess(response));
  } catch (err) {
    yield put(actions.WriteToUsFailure(err));
  }
}

export function* watchWriteToUsSaga() {
  yield takeLatest(types.WRITE_TO_US_REQUEST, WriteToUsSaga);
}
