import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../../Config/Packages/PackageLabTestActionType";
import * as actions from "../../Action/Packages/GetTestPackageLIstingAction";
import NetworkService from "../../../network/Network/Packages/PackageLabTestNetwork";

export function* GetTestPackageListingSaga(action) {
  try {
    let response = yield call(NetworkService.GetTestPackageListing, action);
    yield put(actions.getTestPackageListingSuccess(response, action.category));
  } catch (err) {
    yield put(actions.getTestPackageListingFailure(err));
  }
}

export function* watchGetTestPackageListingSaga() {
  yield takeEvery(types.GET_TEST_PACKAGE_LISTING_REQUEST, GetTestPackageListingSaga);
}
