import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import Email from "../../../assets/Login/emailLogoLogin.png";
import { generateEmailOtpRequest } from "../../../redux/Action/OTP/generateEmailOtpAction";
import { userRegisterRequest } from "../../../redux/Action/User/registerAction";
import "./corporateSignUp.css";

function CorporateSignUpOtp({ empInfo, token }) {
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [isWrong, setIsWrong] = useState(false);
  const [isExpire, setExpire] = useState(false);
  const [timer, setTimer] = useState(60);
  const [showLoader, setShowLoader] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const handleChange = (code) => setCode(code);

  const handleClickOtp = () => {
    dispatch(userRegisterRequest({ ...empInfo, token }, parseInt(code)));
  };

  const responseRegister = useSelector((state) => state.user.register);

  useEffect(() => {
    if (
      responseRegister.error?.data.response.status === 400 &&
      responseRegister.error?.data.response.data.errorMessage === "Invalid Otp"
    ) {
      setIsWrong(true);
      setExpire(false);
      setShowLoader(false);
      responseRegister.error.data.response.status = null;
    }
    if (
      responseRegister.error?.data.response.status === 400 &&
      responseRegister?.error?.data?.response?.data?.errorMessage ===
        "Otp Expired"
    ) {
      setExpire(true);
      setIsWrong(false);
      setShowLoader(false);
      responseRegister.error.data.response.status = null;
    }
  }, [responseRegister.error]);

  useEffect(() => {
    if (responseRegister.isLoading) {
      setShowLoader(true);
    }
  }, [responseRegister]);

  useEffect(() => {
    let intervalId;
    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      setIsTimerRunning(false);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isTimerRunning, timer]);

  const handleResendOTP = () => {
    setExpire(false);
    setIsWrong(false);
    setTimer(60);
    setIsTimerRunning(true);
    dispatch(generateEmailOtpRequest(empInfo.email, empInfo.name));
  };

  return (
    <div className="signupArea">
      <p className="signupAreaSecondHeading">Verify your OTP here!</p>
      <div className="otpArea">
        <OtpInput
          value={code}
          onChange={handleChange}
          containerStyle={{
            width: "75%",
            height: "100%",
          }}
          numInputs={4}
          separator={<span style={{ width: "8px" }}></span>}
          isInputNum={true}
          shouldAutoFocus={true}
          inputStyle={{
            background: "#F3F0F0",
            border: "1px solid #1C71E1",
            borderRadius: "8px",
            width: "100%",
            height: "75px",
            fontSize: "26px",
            color: "#000",
            fontWeight: "400",
            caretColor: "blue",
          }}
          focusStyle={{
            border: "1px solid #CFD3DB",
            outline: "none",
          }}
        />
      </div>
      <div className="otpRegisterBottomDiv">
        {isExpire ? (
          <div className="expireOTPMessageMobile">
            <p>OTP Expired</p>
          </div>
        ) : null}
        {isWrong ? (
          <div className="expireOTPMessageMobile">Incorrect OTP</div>
        ) : null}
      </div>

      <button
        onClick={handleClickOtp}
        type="submit"
        className="signupInputFieldButton"
        style={showLoader ? { background: "transparent" } : {}}
        disabled={showLoader ? true : false}
      >
        {showLoader ? (
          <CircularProgress />
        ) : (
          <>
            <img alt="Email" src={Email} />
            &nbsp; Sign In
          </>
        )}
      </button>
      {isTimerRunning ? (
        <p className="otpRegisterTimer">{timer} seconds</p>
      ) : (
        <p className=" otpRegisterTimer">
          Didn't Recive Code? &nbsp;
          <span
            style={{
              color: "#0089ED",
              cursor: "pointer",
              fontSize: "1em",
            }}
            onClick={handleResendOTP}
          >
            Resend
          </span>
        </p>
      )}
    </div>
  );
}

export default CorporateSignUpOtp;
