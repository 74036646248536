import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const GetQuestionSection3Request = (data) => {
    return {
        type: types.GET_QUESTION_SECTION3_REQUEST,
        data:data
    }
}

export const  GetQuestionSection3Success = (data) => {
    return {
        type: types.GET_QUESTION_SECTION3_SUCCESS,
        data:data,
    }
}

export const  GetQuestionSection3Failure = (data) => {
    return {
        type: types.GET_QUESTION_SECTION3_FAILURE,
        data:data,
    }
}