import { all } from "redux-saga/effects";
import { watchGetAllOrderHistorySaga } from "./GetAllOrderHistorySaga";
import { watchGetOrderDetailSaga } from "./GetOrderDetailSaga";
import { watchGetPlanSubscriptionSaga } from "./GetPlanSubscriptionSaga";
import { watchGetProgramSubscriptionSaga } from "./GetProgramSubscriptionSaga";



function* rootOrderSaga() {
    yield all([
        watchGetAllOrderHistorySaga(),
        watchGetOrderDetailSaga(),
        watchGetPlanSubscriptionSaga(),
        watchGetProgramSubscriptionSaga()

    ])
}

export default rootOrderSaga;