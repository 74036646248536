import React from "react";
import Title from "../../Atoms/Title/Title";
import MentalWellnessLanding from "../../Organisms/MentalWellness/MentalWellnessLanding";

function MentalWellness() {
  return (
    <>
      <Title title="YUVA | Priority OPD Consultation in a medical institution near you." />
        <MentalWellnessLanding />
    </>
  );
}

export default MentalWellness;
