import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/ERM/MedicalRecordListingConfig"
import * as actions from "../../Action/ERM/MedicalRecordListingAction"
import NetworkService from "../../../network/Network/ERM/medicalRecordDropdownNetwork"


export function* viewMedicalRecord(action) {
  try {
    let response = yield call(NetworkService.viewMedicalRecord, action);
    yield put(actions.viewMedicalRecordSuccess(response));
  } catch (err) {
    yield put(actions.viewMedicalRecordFailure(err));
  }
}

export function* watchViewMedicalRecordSaga() {
  yield takeLatest(types.VIEW_MEDICAL_RECORD_REQUEST, viewMedicalRecord);
}
