import { all } from "redux-saga/effects";
import { watchContactUsPageSaga } from "./ContactUsPageSaga";
import { watchWriteToUsSaga } from "./WriteToUsSaga";

function* rootContactUsSaga() {
    yield all([
        watchContactUsPageSaga(),
        watchWriteToUsSaga(),
    ])
}

export default rootContactUsSaga;