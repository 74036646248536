import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Token/DeviceToken"
import * as actions from "../../Action/Token/DeviceTokenAction";
import NetworkService from "../../../network/Network/Token/deviceTokenNetwork";
export function* deviceTokenSaga(action) {
  try {
    let response = yield call(NetworkService.deviceTokenNetwork, action);
    yield put(actions.deviceTokenSuccess(response));
  } catch (err) {
    yield put(actions.deviceTokenFailure(err));
  }
}

export function* watchdeviceTokenSaga() {
  yield takeLatest(types.DEVICE_TOKEN_REQUEST, deviceTokenSaga);
}