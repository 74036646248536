import React from "react";
import CareerBackground from "../../../assets/CarrerPage/CareerBackground.png";
import CareerV1 from "../../../assets/CarrerPage/CareerV1.png";
import CareerV2 from "../../../assets/CarrerPage/CareerV2.png";
import CareerV3 from "../../../assets/CarrerPage/CareerV3.png";
import CareerV4 from "../../../assets/CarrerPage/CareerV4.png";
import CareerVector1 from "../../../assets/CarrerPage/CareerVector1.png";
import CareerVector2 from "../../../assets/CarrerPage/CareerVector2.png";
import CareerVector3 from "../../../assets/CarrerPage/CareerVector3.png";
import CareerVector4 from "../../../assets/CarrerPage/CareerVector4.png";
import Title from "../../Atoms/Title/Title";
import "./Career.css";

function Career() {
  return (
    <div>
      <Title title="YUVA | Help us build a better tomorrow. Join Now." />
      <div>
        <div className="CareerBody">
          <div className="CareerHeading">
            <p>Career</p>
            <img
              src={CareerBackground}
              alt="Career"
              className="CareerHeadingImage"
            />
          </div>

          <div className="CareerHeading2">WHY JOIN YUVA HEALTH</div>

          <div className="CareerSubHeading">
            We at Yuva Health are a curious bunch. We believe in turning ideas
            into reality, breathing life into products that will be
            revolutionary innovations to facilitate the healthcare industry, and
            giving wings to wellness programs for small and large organizations
            alike to help them fly without fright. We are actively on the
            lookout for people who, like us, want to leverage the power of
            technology to create a better world for the future.
          </div>

          <div class="Careerwrapper">
            <div className="careerImg">
              <img
                className="CareerImage"
                src={CareerVector1}
                alt="electric"
              ></img>
              <div className="vectorName">
                Dynamic and fast paced work environment
              </div>
            </div>
            <div className="careerImg">
              {" "}
              <img
                className="CareerImage"
                src={CareerVector2}
                alt="4 arrows"
              ></img>
              <div className="vectorName">Open Work Culture </div>
            </div>
            <div className="careerImg">
              {" "}
              <img
                className="CareerImage"
                src={CareerVector3}
                alt="4 squares"
              ></img>
              <div className="vectorName">Soul of a Startup</div>
            </div>
            <div className="careerImg">
              {" "}
              <img
                className="CareerImage"
                src={CareerVector4}
                alt="puzzle"
              ></img>
              <div className="vectorName">Learning in leaps and bounds</div>
            </div>
          </div>

          <div className="CareerHeading2">JOB OPENINGS</div>

          <div className="CareerSubHeading2">A place for everyone!</div>

          <div class="Careerwrapper3">
            <div className="careerImg">
              <img
                className="CareerImage2"
                src={CareerV1}
                alt="student studying"
              ></img>
              <div className="vectorName1">Freshers</div>
            </div>
            <div className="careerImg">
              {" "}
              <img className="CareerImage2" src={CareerV2} alt="graduate"></img>
              <div className="vectorName1">Interns</div>
            </div>
            <div className="careerImg">
              {" "}
              <img
                className="CareerImage2"
                src={CareerV3}
                alt="men in coat"
              ></img>
              <div className="vectorName1">Experiences</div>
            </div>
            <div className="careerImg">
              {" "}
              <img className="CareerImage2" src={CareerV4} alt="folder"></img>
              <div className="vectorName1">Open Positions</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Career;
