import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import storage from "../../../Utils/storage";
import Details from "../../Molecules/Plan/PlanDetails";
import "./planContent.css";
import Swal from "sweetalert2";

export default function PlanContent() {
  const navigate = useNavigate();
  
  const search = useLocation().search;
  const PlanUuid = new URLSearchParams(search).get("id");

  const getPlanServicesDetail = useSelector(
    (state) => state.plan.getPlanServicesDetail
  );

  function handleBuy(e) {
    if(localStorage.getItem("kycVerification")==="true"){
    storage.setStorage("PurchasePlanUuid", PlanUuid);
    if (storage.getStorage("token") !== null) {
      navigate(`/cart-checkout-plan`, {
        state: {
          uuid: PlanUuid,
        },
      });
    } else {
      navigate("/login", {
        state: "plan",
      });
    }
  }
  else{
    Swal.fire({
      title: "Your KYC is not completed !! You can purchase the plan once the KYC is completed ..",
      showDenyButton: true,
      confirmButtonText: "Go for KYC",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/account-settings");
      } else if (result.isDenied) {
       
      }
    });
  }
  }
  return (
    <div className="planContent">
      <div className="planContentButtons">
        {/* {buttonInfo.map((item, index) => (
          <button
            className={`planContentButton ${index === clickedButtonIndex ? "clicked" : ""
              }`}
            key={index}
            onClick={() => handleClick(index)}
          >
            {item}
          </button>
        ))} */}
      </div>
      <Details getPlanServicesDetail={getPlanServicesDetail} />
      <div className="PlanTermsAndConditionsButtonDiv">
        <button
          className="PlanTermsAndConditionsButton"
          onClick={() => {
            handleBuy();
          }}
        >
          {" "}
          BUY NOW
        </button>
      </div>
    </div>
  );
}
