import * as types from "../../Config/Cart/CreateCartActionType"

export const createOrderRequest = (data) => {
    return {
        type: types.CREATE_ORDER_REQUEST,
        data,
    }
}

export const  createOrderSuccess = (data) => {
    return {
        type: types.CREATE_ORDER_SUCCESS,
        data
    }
}

export const  createOrderFailure = (data) => {
    return {
        type: types.CREATE_ORDER_FAILURE,
        data
    }
}