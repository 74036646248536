import * as types from "../../Config/Plan/PlanActionType";

export const planLockRequest = (data) => {
  return {
    type: types.PLAN_LOCK_REQUEST,
    data:data,
  };
};

export const planLockSuccess = (data) => {
  return {
    type: types.PLAN_LOCK_SUCCESS,
    data: data,
  };
};

export const planLockFailure = (data) => {
  return {
    type: types.PLAN_LOCK_FAILURE,
    data: data,
  };
};
