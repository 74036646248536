export function register() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
        })
        .catch((error) => {
        });
    }
  }
  