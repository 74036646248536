import * as types from "../../Config/Landing/landingActionType"

export const joinNetworkRequest = (data) => {
    return {
        type: types.JOIN_NETWORK_REQUEST,
        data:data
    }
}

export const joinNetworkSuccess = (data) => {
    return {
        type: types.JOIN_NETWORK_SUCCESS,
        data:data,
    }
}

export const joinNetworkFailure = (data) => {
    return {
        type: types.JOIN_NETWORK_FAILURE,
        data:data,
    }
}