import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import storage from "../../../Utils/storage";
import Hexagon from "../../../assets/common/Hexagon.png";
import Lock from "../../../assets/common/Lock.png";
import { ContinueSectionRequest } from "../../../redux/Action/HealthRiskAssessment/ContinueSectionAction";
import { DeleteSectionAnswersRequest } from "../../../redux/Action/HealthRiskAssessment/DeleteSectionAnswersAction";
import Title from "../../Atoms/Title/Title";
import HraRelationSelectionModal from "../Modal/HraRelationSelectionModal";
import "./Locked.css";

function Locked() {
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(false);
  const [relationModal, setRelationModal] = useState(false);
  const dispatch = useDispatch();

  

  useEffect(() => {
    if (storage.getStorage("token") !== null) {
      setLogin(true);
    }
  }, [isLogin]);

  useEffect(() => {
    dispatch(ContinueSectionRequest());
  }, [dispatch]);

  const res = useSelector((state) => state.hra.continueSection);
  const DeleteSectionAnswers = useSelector(
    (state) => state.hra.deleteSectionAnswers
  );

  const handleClick = () => {
    Swal.fire({
      icon: "error",
      text: "Please Login First",
    });
  };
  function handleLockedClick() {
    if (storage.getStorage("token") !== null) {
      setLogin(true);
      storage.setStorage("SectionValue", 1);
      navigate("/health-risk-assessment/section-1");
    } else {
      handleClick();
    }
  }

  function handleLockedClickNew() {
    if (res?.data?.data?.data?.data?.continueHra === true) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Start New",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("", "You Have Started the new Assessment", "success").then(
            () => {
              if (storage.getStorage("token") !== "") {
                setLogin(true);
                storage.setStorage("SectionValue", 1);
                dispatch(DeleteSectionAnswersRequest());
                window.location.reload();
              }
            }
          );
        }
      });
    } else {
      if (storage.getStorage("token") !== "") {
        setLogin(true);
        setOpenModal(true);

        localStorage.setItem("SectionValue", 1);
        dispatch(DeleteSectionAnswersRequest());
      }
    }
  }
  useEffect(() => {
    if (DeleteSectionAnswers?.data?.data?.status === 200) {
      setRelationModal(true);
      DeleteSectionAnswers.data.data.status = null;
    }
  }, [DeleteSectionAnswers]);
  return (
    <>
      <Title title="YUVA |  State-of-the-art HRA for a healthier tomorrow." />

        <div>
          {relationModal && openModal && (
            <HraRelationSelectionModal
              open={openModal}
              relationModal={relationModal}
              setOpenModal={setOpenModal}
            />
          )}
          <div className="mainLocked">
            <div className="lockedTitle">
              <p>Your Health Risk Assessment</p>
            </div>
            <div className="lockedCard">
              <div className="lockedCardRoom">
                <div className="lockedCardRoomParagraph">
                  <p>The Journey towards a healthy life starts now</p>
                </div>
                {isLogin ? (
                  <div className="getStartedLogin">
                    <button
                      className="getStartedLoginStart"
                      onClick={handleLockedClickNew}
                    >
                      Start New
                    </button>
                    {res?.data?.data?.data?.data?.continueHra && (
                      <button
                        className="getStartedLoginResume"
                        onClick={handleLockedClick}
                      >
                        Resume
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="getStarted">
                    {isLogin ? "" : <img src={Lock} alt="error" />}
                    <button>Get Started</button>
                  </div>
                )}
                <img className="Hexagon" src={Hexagon} alt="error" />
              </div>
              <div className="lockedCardImage"></div>
            </div>
          </div>
        </div>
      
    </>
  );
}

export default Locked;
