import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const GetHraQuestionsRequest = (data) => {
    return {
        type: types.GET_HRA_QUESTIONS_REQUEST,
        data:data
    }
}

export const  GetHraQuestionsSuccess = (data) => {
    return {
        type: types.GET_HRA_QUESTIONS_SUCCESS,
        data:data,
    }
}

export const  GetHraQuestionsFailure = (data) => {
    return {
        type: types.GET_HRA_QUESTIONS_FAILURE,
        data:data,
    }
}