import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Mobile1 from "../../../assets/AdvertisementSection3CardsImages/Mobile1.png";
import Mobile2 from "../../../assets/AdvertisementSection3CardsImages/Mobile2.png";
import Section5image1 from "../../../assets/AdvertisementSection3CardsImages/Section5image1.png";
import Section5image2 from "../../../assets/AdvertisementSection3CardsImages/Section5image2.png";
import hospitalSign from "../../../assets/AdvertisementSection3CardsImages/hospitalSign.png";
import section4C1 from "../../../assets/AdvertisementSection3CardsImages/section4C1.png";
import section4C2 from "../../../assets/AdvertisementSection3CardsImages/section4C2.png";
import section4c3 from "../../../assets/AdvertisementSection3CardsImages/section4c3.png";
import section4p2 from "../../../assets/AdvertisementSection3CardsImages/section4p2.png";
import logo from "../../../assets/common/logoPrimaryNavbar.png";
import { getCallRequest } from "../../../redux/Action/Advertisement/GetCallRequestAction";
import { viewAllCityRequest } from "../../../redux/Action/City/viewAllCityAction";
import "./AdvertisementBannerMobile.css";
import { MarketingConstant } from "./AdvertisementConstant";

function AdvertisementBannerMobile() {
  const [index, setIndex] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getCallFormValidation = Yup.object().shape({
    mobileNumber: Yup.string()
      .matches(/^\d{10}$/, "Please Enter Valid Number")
      .required("Mobile number is required"),
    Name: Yup.string().required("Required Field"),
    city: Yup.string().required("Required Field"),
  });

  useEffect(() => {
    dispatch(viewAllCityRequest());
  }, [dispatch]);

  const viewAllCity = useSelector((state) => state.city.viewAllCity);

  const handleSubmit = (values) => {
    setIsSubmitting(true);
    dispatch(getCallRequest(values));
  };

  const getCall = useSelector((state) => state.advertisement.getCall);

  const handleClick = () => {
    if (index < 4) {
      setIndex(index + 1);
    } else {
      setIndex(0);
    }
  };
  const handleClickBack = () => {
    if (index > 0) {
      setIndex(index - 1);
    } else {
      setIndex(4);
    }
  };

  if (
    getCall?.data?.data.status === 201 &&
    getCall?.data?.data.data.message !== ""
  ) {
    Swal.fire({
      title: "Your form has been Submitted",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  return (
    <div className="AdvertisementBannerMobileMain">
      <div className="AdvertisementBannerMobile1">
        <img
          src={logo}
          className="AdvertisementBannerMobile1Logo"
          alt="Yuva Logo"
        />
        <img
          src={Mobile1}
          className="AdvertisementBannerMobile1Background"
          alt="Background"
        />
        <div className="AdvertisementBannerMobile1Inputs">
          <Formik
            initialValues={{
              mobileNumber: "",
              Name: "",
              city: "",
            }}
            validationSchema={getCallFormValidation}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {
              <Form>
                <div className="AdvertisementForm">
                  <Field
                    type="number"
                    name="mobileNumber"
                    placeholder="Enter Mobile Number*"
                    className="AdvertisementInput"
                    onWheel={(event) => event.currentTarget.blur()}
                  />
                  <ErrorMessage
                    className="errorMessageValidation"
                    name="mobileNumber"
                    component="div"
                  />
                  <Field
                    type="text"
                    name="Name"
                    placeholder="Enter Your Name*"
                    className="AdvertisementInput"
                  />
                  <ErrorMessage
                    className="errorMessageValidation"
                    name="Name"
                    component="div"
                  />
                  <Field as="select" name="city" className="AdvertisementInput">
                    <option value="" disabled>
                      Select Your City
                    </option>
                    {viewAllCity?.data?.data?.data.data.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    className="errorMessageValidation"
                    name="city"
                    component="div"
                  />
                  <button
                    type="submit"
                    className="AdvertisementCallButton"
                    disabled={isSubmitting}
                  >
                    Get Free Call
                  </button>
                </div>
              </Form>
            }
          </Formik>
        </div>
      </div>
      <div className="AdvertisementBannerMobile2">
        <p>
          Complete Blood Count, Random blood sugar, Blood Urea, SGOT, SGPT,
          Serum Cholesterol Urine Routine & Microscopy
        </p>
        <img src={hospitalSign} alt="Hospital Sign" />
        <p>FREE ONLINE CONSULTATION</p>
        <p>*** Collection Charges Rs 200/- applicable.</p>
        <img src={Mobile2} alt="Background" />
      </div>
      <div className="AdvertisementBannerMobile3">
        <p>Talk to our health Advisor</p>
        <button onClick={() => navigate("/talk-to-doctor")}>Click Here</button>
      </div>
      <div className="AdvertisementBannerMobile4">
        <p>Services</p>
        <p>What We Offer</p>
        <div className="AdvertisementBannerMobile4Comment">
          {MarketingConstant.section3Cards.map(
            (item) =>
              index === item.index && (
                <>
                  <img src={item.icon} alt="Icons" />
                  <p>{item.name}</p>
                </>
              )
          )}
        </div>
        <div className="forwardCircle" onClick={handleClick}></div>
        <div className="backwardCircle" onClick={handleClickBack}></div>
      </div>
      <div className="AdvertisementBannerMobile5">
        <p>{MarketingConstant.section3CardInfo[index].name}</p>
        <p>{MarketingConstant.section3CardInfo[index].description}</p>
        <button
          onClick={() =>
            navigate(`${MarketingConstant.section3CardInfo[index].route}`)
          }
        >
          Learn More
        </button>
      </div>
      <div className="AdvertisementBannerMobile6">
        <p>Why Choose us</p>
        <p>We create a Curious Buzz</p>
      </div>
      <div className="AdvertisementBannerMobile7">
        <ul>
          <li>Preventive Healthcare Programs & Plans For Early Diagnosis.</li>
          <li>
            Physical As Well As Mental Wellness Programs & Plans To Foster A
            Healthy Life.
          </li>
          <li>
            Cost-Efficient, Tailor-Made Packages For Any And All Occasions.
          </li>
        </ul>
      </div>
      <div className="AdvertisementBannerMobile8">
        <img
          className="AdvertisementBannerMobile8Image"
          src={section4c3}
          alt="Grid"
        />
        <div className="AdvertisementBannerMobile8Child1">
          <img src={section4C1} alt="Badge" />
          <p>
            Customised Corporate
            <br />
            Program
          </p>
          <p>
            Empowering You To Live Your Best Life Through Holistic Health
            Approaches.
          </p>
        </div>
        <div className="AdvertisementBannerMobile8Child2">
          <img src={section4C2} alt="Badge" />
          <p>
            Customised Retail
            <br />
            Plan
          </p>
          <p>
            Empowering You To Live Your Best Life Through Holistic Health
            Approaches.
          </p>
        </div>
      </div>
      <div className="AdvertisementBannerMobile9">
        <button onClick={() => navigate("/subscription")}>Discover More</button>
      </div>
      <div className="AdvertisementBannerMobile10">
        <p>About Us</p>
        <p>We are here for Health Solution Idea</p>
        <img src={section4p2} alt="Grid" />
        <p>
          At Yuva Health, we bring together a diverse and experienced team with
          backgrounds in technology, insurance, healthcare, and wellness. Our
          team has a strong track record of success in the health-tech industry,
          particularly in launching and implementing India's first cashless OPD
          (Outpatient Department) in both Rural and Urban India.
        </p>
      </div>
      <div className="AdvertisementBannerMobile11">
        <div className="AdvertisementBannerMobile11Child1">
          <img src={Section5image1} alt="Bangalore" />
          <p>Bangalore</p>
        </div>
        <div className="AdvertisementBannerMobile11Child2">
          <img src={Section5image2} alt="New Delhi" />
          <p>New Delhi</p>
        </div>
        <div className="AdvertisementBannerMobile11Child3">
          <div className="AdvertisementBannerMobile11Child3_1">
            <p>14</p>
            <p>Hospitals</p>
          </div>
          <div className="AdvertisementBannerMobile11Child3_1">
            <p>76</p>
            <p>Clinics</p>
          </div>
          <div className="AdvertisementBannerMobile11Child3_1">
            <p>03</p>
            <p>Diagnostic Centers</p>
          </div>
          <div className="AdvertisementBannerMobile11Child3_1">
            <p>13</p>
            <p>Professional Services</p>
          </div>
        </div>
        <button
          className="AdvertisementBannerMobile11Button"
          onClick={() => navigate("/network")}
        >
          Discover More
        </button>
      </div>
    </div>
  );
}

export default AdvertisementBannerMobile;
