import RequestService from "../../RequestService";
import { api } from "../../../helpers/Token/DeviceTokenhelper";

class NetworkService {
  deviceTokenNetwork(action) {
    const endPoint = `${api.token}/${action.data}/true`;
    return RequestService.postRequest(endPoint);
  }
}
export default new NetworkService();
