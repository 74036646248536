import React, { useState } from "react";
import AllBrand from "../Products/AllBrand";
import "./FilterTest.css";

export default function FilterTest({
  title,
  mapping,
  handleCheckboxChange,
  checkboxBrand,
}) {

  const [moreBrandClick, setMoreBrandClick] = useState(false);
  const [moreCategoryClick, setMoreCategoryClick] = useState(false);
  const [moreSubCategoryClick, setMoreSubCategoryClick] = useState(false);

  const handleMoreClick = (title) => {
    if (title === "Brand") {
      setMoreSubCategoryClick(false);
      setMoreCategoryClick(false);
      setMoreBrandClick(true);
    } else if (title === "Category") {
      setMoreCategoryClick(true);
      setMoreBrandClick(false);
      setMoreSubCategoryClick(false);
    } else if (title === "Sub Category") {
      setMoreSubCategoryClick(true);
      setMoreCategoryClick(false);
      setMoreBrandClick(false);
    }
  };

  return (
    <div className="filtersBody">
      <p>{title}</p>
      {mapping?.map((item, index) => {
        if (index < 8) {
          return (
            <div className="filtersBodyDiv" key={index}>
              <input
                type="checkbox"
                value={item.id}
                checked={checkboxBrand?.includes(item.id)}
                onChange={handleCheckboxChange}
                key={index}
              />
              <div className="filtersBodyLabels">
                <label className="itemName">{item.name}</label>
                {item.count > 0 && <div>{item.count}</div>}
              </div>
            </div>
          );
        }
        return null;
      })}
      {mapping?.length > 8 && (
        <div className="moreFilter" onClick={() => handleMoreClick(title)}>
          + {mapping?.length - 8} more
        </div>
      )}
      {moreBrandClick && (
        <AllBrand
          content={mapping}
          handleCheckboxChange={handleCheckboxChange}
          checkboxes={checkboxBrand}
          setMoreBrandClick={setMoreBrandClick}
          name={title}
        />
      )}
      {moreCategoryClick && (
        <AllBrand
          content={mapping}
          handleCheckboxChange={handleCheckboxChange}
          checkboxes={checkboxBrand}
          setMoreBrandClick={setMoreCategoryClick}
          name={title}
        />
      )}
      {moreSubCategoryClick && (
        <AllBrand
          content={mapping}
          handleCheckboxChange={handleCheckboxChange}
          checkboxes={checkboxBrand}
          setMoreBrandClick={setMoreSubCategoryClick}
          name={title}
        />
      )}
    </div>
  );
}
