import * as types from "../../Config/Landing/landingActionType"

export const getPopularPlanHomeScreenRequest = (data) => {
    return {
        type: types.GET_POPULAR_PLAN_HOMESCREEN_REQUEST,
        data:data
    }
}

export const getPopularPlanHomeScreenSuccess = (data) => {
    return {
        type: types.GET_POPULAR_PLAN_HOMESCREEN_SUCCESS,
        data:data,
    }
}

export const getPopularPlanHomeScreenFailure = (data) => {
    return {
        type: types.GET_POPULAR_PLAN_HOMESCREEN_FAILURE,
        data:data,
    }
}