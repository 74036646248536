import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPopularTestHomeScreenRequest } from "../../../redux/Action/Landing/getPopularTestHomeScreenAction";
import Listing from "../../Organisms/Listing/Listing";
import ListingMobile from "../../Organisms/Listing/ListingMobile";
import { PageKeys } from "../../../Utils/testPackageFlag";

function FeatureDiagnostics() {
  useEffect(() => {
    dispatch(getPopularTestHomeScreenRequest());
  }, []);

  const dispatch = useDispatch();
  const getPopularTestHomeScreen = useSelector(
    (state) => state.landing.getPopularTestHomeScreen
  );
  return (
    <>
      <div className="FHCMain">
        <div className="FHCSection1">
          <p>Popular Diagnostics</p>
        </div>
      </div>
      <Listing
        mapData={getPopularTestHomeScreen.data?.data.data.data}
        testAndPackage={"Test"}
      />
    </>
  );
}

export default FeatureDiagnostics;
