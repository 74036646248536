import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/EcomUser/EcomUserActionType";
import * as actions from "../../Action/EcomUser/getSubCategoryDropdownAction";
import NetworkService from "../../../network/Network/EcomUser/EcomUserNetwork";

export function* getSubCategoryDropdownSaga(action) {
  try {
    let response = yield call(NetworkService.getSubCategoryDropdown, action);
    yield put(actions.getSubCategoryDropdownSuccess(response));
  } catch (err) {
    yield put(actions.getSubCategoryDropdownFailure(err));
  }
}

export function* watchGetSubCategoryDropdownSaga() {

  yield takeLatest(types.GET_SUB_CATEGORY_DROPDOWN_REQUEST, getSubCategoryDropdownSaga);
}
