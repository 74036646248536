import { combineReducers } from "redux";
import rootAccountReducer from "./Account/rootAccountReducer";
import rootAddressReducer from "./Address/rootAddressReducer";
import rootCartReducer from "./Cart/rootCartReducer";
import rootCheckEmailReducer from "./CheckEmail/rootCheckEmailReducer";
import rootContactUsReducer from "./ContactUsPage/rootContactUsReducer";
import rootGetSectionAnswerReducer from "./HealthRiskAssessment/rootGetSectionAnswerReducer";
import rootGetSectionQuestionReducer from "./HealthRiskAssessment/rootGetSectionQuestionReducer";
import rootHRAAnswerReducer from "./HealthRiskAssessment/rootHRAAnswerReducer";
import rootHRAReducer from "./HealthRiskAssessment/rootHRAReducer";
import rootLandingReducer from "./Landing/rootLandingReducer";
import rootNetworkReducer from "./Network/rootNetworkReducer";
import rootOrderReducer from "./Order/rootOrderReducer";
import rootOtpReducer from "./OTP/rootOtpReducer";
import rootPackagesReducer from "./Packages/rootPackagesReducer";
import rootPlanReducer from "./Plan/rootPlanReducer";
import rootUserReducer from "./User/rootUserReducer";
import rootTokenReducer from "./Token/rootTokenReducer";
import { profileLockReducer } from "./ProfileLock/ProfileLock";
import { TellAboutYourselfPageReducer } from "./TellAboutYourselfPage/TellAboutYourselfPage";
import { elasticSearchReducer } from "./ElasticSearch/elasticSearchReducer";
import { elasticSearchResultReducer } from "./ElasticSearch/ElasticSearchResultReducer";
import rootAdvertisementReducer from "./Advertisement/rootAdvertisementReducer";
import { viewMedicalRecordReducer } from "./ERM/viewMedicalRecorListingReducer";
import { medicalRecordDropdownReducer } from "./ERM/medicalRecordDropdownReducer";
import rootErmReducers from "./ERM/rootErmReducers";
import rootOnMoodNineRootReducer from "./MentalWellness/rootOnMoodNineRootReducer";
import rootCorporateSignUpReducer from "./CorporateSignUp/rootCorporateSignUpReducer";
import rootUserBannerReducer from "./Banner/rootUserBannerReducer";
import rootRefreshTokenReducer from "./RefreshToken/rootRefreshTokenReducer";
import rootEcomUserReducer from "./EcomUser/rootEcomUserReducer";
import rootCityReducer from "./City/rootCityReducer";
import rootYuvaClinicReducer from "./YuvaClinic/rootYuvaClinicReducer";

export default combineReducers({
  landing: rootLandingReducer,
  account: rootAccountReducer,
  address: rootAddressReducer,
  cart: rootCartReducer,
  checkEmail: rootCheckEmailReducer,
  contactUs: rootContactUsReducer,
  getSectionAnswer: rootGetSectionAnswerReducer,
  getAnswer: rootHRAAnswerReducer,
  getQuestion: rootGetSectionQuestionReducer,
  hra: rootHRAReducer,
  network: rootNetworkReducer,
  order: rootOrderReducer,
  otp: rootOtpReducer,
  package: rootPackagesReducer,
  plan: rootPlanReducer,
  user: rootUserReducer,
  TellAboutYourselfPage: TellAboutYourselfPageReducer,
  elasticSearch: elasticSearchReducer,
  profileLock: profileLockReducer,
  advertisement: rootAdvertisementReducer,
  viewMedicalRecord: viewMedicalRecordReducer,
  medicalRecordDropdown: medicalRecordDropdownReducer,
  Erm: rootErmReducers,
  corporateSignUp: rootCorporateSignUpReducer,
  elasticSearchResult: elasticSearchResultReducer,
  onMoodNine: rootOnMoodNineRootReducer,
  banner: rootUserBannerReducer,
  token: rootTokenReducer,
  refreshToken: rootRefreshTokenReducer,
  eCom: rootEcomUserReducer,
  city: rootCityReducer,
  YuvaClinic:rootYuvaClinicReducer,
});