import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Email from "../../../assets/Login/emailLogoLogin.png";
import { userLoginRequest } from "../../../redux/Action/User/loginAction";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import eye from "../../../assets/Login/eye.png";
import logo from "../../../assets/common/logoPrimaryNavbar.png";

import * as Yup from "yup";

function UserLoginForm({ setChildData, isSubmitting, setSubmitting }) {
  const loginSchema = Yup.object().shape({
    password: Yup.string().required("Please enter password"),
    email: Yup.string()
      .matches(
        /^(?:[\w.-]+@[\w.-]+\.[a-zA-Z]{2,}|(?:\+?\d{1,3})?[ -]?\(?\d{3}\)?[ -]?\d{3}[ -]?\d{4})$/,
        "Enter a Valid E-mail or Phone number"
      )
      .required("Email or Phone number is required!"),
  });
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [showPass, setShowPass] = useState(false);

  function handlePasswordEye() {
    setShowPass(!showPass);
  }

  const handleSubmit = (values) => {
    dispatch(userLoginRequest(values, null, null));
    setSubmitting(true);
    setChildData(values);
  };

  return (
    <div className="signupArea">
      <div className="LogoAreaLOGIN_SIGNUP">
        <img src={logo} alt="Logo" />
      </div>
      <p className="signupAreaFirstHeading">Login Here</p>
      <p className="signupAreaSecondHeading">
        Welcome back you’ve been missed!
      </p>
      <div className="signupForm">
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={loginSchema}
          onSubmit={handleSubmit}
        >
          {() => {
            return (
              <Form className="SingleRegisterForm">
                <div className="signUpBox">
                  <Field
                    type="text"
                    name="email"
                    placeholder="Mobile Number Or Email"
                    className="signupInputField"
                  />
                  <ErrorMessage
                    className="errorMessage"
                    name="email"
                    component="div"
                  />
                </div>

                <div className="signUpBox">
                  <div style={{ position: "relative" }}>
                    {showPass ? (
                      <Field
                        type="text"
                        name="password"
                        placeholder="Password"
                        className="signupInputField"
                      />
                    ) : (
                      <Field
                        type="password"
                        name="password"
                        placeholder="Password"
                        className="signupInputField"
                      />
                    )}
                    <img
                      alt="Eye Icon"
                      src={eye}
                      onClick={handlePasswordEye}
                      className="useregEye"
                      style={{
                        position: "absolute",
                        right: "10%",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    />
                  </div>
                  <ErrorMessage
                    className="errorMessage"
                    name="password"
                    component="div"
                  />
                </div>

                <button
                  type="submit"
                  className="signupInputFieldButton"
                  disabled={isSubmitting}
                >
                  <img alt="Email" src={Email} /> &nbsp; Login
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
      <p className="signupSignInTag">
        Forget Password? &nbsp;
        <span
          style={{
            color: "#0089ED",
            cursor: "pointer",
            fontSize: "1em",
          }}
          onClick={() => navigate("/login/forgetPassword")}
        >
          Click here
        </span>
      </p>
      <p className="signupSignInTag" style={{ marginTop: "5.3em" }}>
        Don't have an account? &nbsp;
        <span
          style={{
            color: "#0089ED",
            cursor: "pointer",
            fontSize: "1em",
          }}
          onClick={() => navigate("/register")}
        >
          Register Now
        </span>
      </p>
    </div>
  );
}

export default UserLoginForm;
