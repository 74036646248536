import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddCart } from "../../../Utils/addCart";
import useLocalStorageSync from "../../../hooks/useLocalStorageSync";
import { createCartClear } from "../../../redux/Action/Cart/CreateCartAction";
import ProductCard from "../../Molecules/Product/ProductCard";

function ProductList({ product, subCategoryName, col }) {
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [itemList, setItemList] = useState();

  const itemDtoList = useLocalStorageSync("itemDtoList");

  const createCart = useSelector((state) => state.cart.createCart);

  useEffect(() => {
    if (createCart.data?.data.status === 200 && itemList) {
      localStorage.setItem("itemDtoList", JSON.stringify(itemList));
      window.dispatchEvent(new Event("storage"));
    }
    return () => {
      dispatch(createCartClear());
    };
  }, [createCart.data, createCart.error, itemList, dispatch]);

  const handleAddCart = async (item) => {
    const data = {
      name: item.name,
      id: item.productId,
      productPriceId: item.priceId,
    };
    try {
      setIsSubmitting(true);
      setItemList(await AddCart(data, dispatch, setIsSubmitting, 1));
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="productLister">
      <>
        {subCategoryName && (
          <div className="productListHead">
            <div className="productListTitle">{subCategoryName}</div>
            {product.length > 8 && (
              <div className="productListViewAll">View More &raquo;</div>
            )}
          </div>
        )}

        <div className={col ? "productViewAllCards" : "productListCards"}>
          {product?.map((productContent, index) => (
            <ProductCard
              productContent={productContent}
              handleAddCart={handleAddCart}
              isSubmitting={isSubmitting}
              itemDtoList={itemDtoList}
              key={index}
            />
          ))}
        </div>
      </>
    </div>
  );
}

export default ProductList;
