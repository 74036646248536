import * as types from "../../Config/User/userActionType"

export const AddRelationRequest = (data) => {
    return {
        type: types.ADD_RELATION_REQUEST,
        data:data
    }
}

export const AddRelationSuccess = (data) => {
    return {
        type: types.ADD_RELATION_SUCCESS,
        data:data,
    }
}

export const AddRelationFailure = (data) => {
    return {
        type: types.ADD_RELATION_FAILURE,
        data:data,
    }
}

export const AddRelationClearRequest = (data) => {
    return {
        type: types.ADD_RELATION_CLEAR_REQUEST,
        data:data,
    }
}