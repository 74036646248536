import * as types from "../../Config/YuvaClinic/YuvaClinicActionType"

export const viewAllYuvaClinicRequest = (data) => {
    return {
        type: types.VIEW_ALL_YUVA_CLINIC_REQUEST,
        data:data,
    }
}

export const viewAllYuvaClinicSuccess = (data) => {
    return {
        type: types.VIEW_ALL_YUVA_CLINIC_SUCCESS,
        data:data,
    }
}

export const viewAllYuvaClinicFailure = (data) => {
    return {
        type: types.VIEW_ALL_YUVA_CLINIC_FAILURE,
        data:data,
    }
}