import * as types from "../../Config/ElasticSearch/ElasticSearchActionType";

const initialState = {
    data: null,
    isLoading: false,
    error: null
}

export const elasticSearchResultReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.ELASTIC_SEARCH_RESULT_SUCCESS:
            return {
                ...initialState,
                data: action,
            }
        case types.ELASTIC_SEARCH_RESULT_FAILURE:
            return {
                ...initialState,
                error: action
            }
        case types.ELASTIC_SEARCH_RESULT_REQUEST:
            return {
                ...initialState,
                isLoading: true
            }
        default:
            return state
    }
}