import * as types from "../../Config/User/userActionType"

export const userResetPasswordRequest = (data) => {
    return {
        type: types.RESET_PASSWORD_REQUEST,
        data:data
    }
}

export const userResetPasswordSuccess = (data,data1) => {
    return {
        type: types.RESET_PASSWORD_SUCCESS,
        data:data
    }
}

export const userResetPasswordFailure = (data,data1) => {
    return {
        type: types.RESET_PASSWORD_FAILURE,
        data:data
    }
}