import React from "react";
import "../HRAscreens/HRAInfoScreens.css";
import backArrow from "../../../../assets/hra/backArrow.png";
import hra2a from "../../../../assets/hra/hra2a.png";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../../Molecules/ScrollToTop/ScrollToTop";
import storage from "../../../../Utils/storage";
import SingleLogin from "../../Login/SingleLogin";

function HRA2a() {
  const navigate = useNavigate();

  const handlePrevious = () => {
    localStorage.setItem("SectionValue", 2);
    navigate("/health-risk-assessment/section-2");
  };
  window.addEventListener("popstate", function (event) {
    storage.setStorage("SectionValue", 2);
  });

  if (storage.getStorage("token") === null) {
    return <SingleLogin />;
  }
  const SectionValue = localStorage.getItem("SectionValue");

  return (
    <>
      <ScrollToTop />

      <div className="hraInfoScreens">
        <div className="hraInfoScreensChild1">
          <p>Your Health Risk Assessment</p>
        </div>
        {SectionValue === "3" && (
          <div
            className="hraInfoScreensChild2"
            style={{ backgroundImage: `url(${hra2a})` }}
          >
            <div className="hraInfoScreensChild2a">
              <div className="hraInfoScreensChild2a1">
                <p>GENERAL SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a1">
                <p>DIETARY SECTION</p>
              </div>
              <div className="hraInfoScreensChild2a2">
                <div className="hraInfoBackArrow">
                  <img
                    src={backArrow}
                    onClick={handlePrevious}
                    alt="backArrow"
                  />
                  <p>Dietary Section</p>
                </div>
                <div className="hraInfo">
                  <p>
                    Understanding your dietary habits will help us gain a better
                    understanding of you. Let's move ahead and discover more
                    about you.{" "}
                  </p>
                </div>
                <button
                  onClick={() => navigate("/health-risk-assessment/section-2b")}
                >
                  Discover further
                </button>
              </div>
            </div>
          </div>
        )}
        {SectionValue !== "3" && (
          <div className="hraDenied">
            Not Authorized !! please navigate to previous sections
          </div>
        )}
      </div>
    </>
  );
}

export default HRA2a;
