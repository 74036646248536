import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Landing/landingActionType";
import * as actions from "../../Action/Landing/getPopularTestHomeScreenAction";
import NetworkService from "../../../network/Network/Landing/landingNetwork";

export function* getPopularTestHomeScreenSaga(action) {

  try {
    let response = yield call(NetworkService.getPopularTestHomeScreen, action);
    yield put(actions.getPopularTestHomeScreenSuccess(response));
  } catch (err) {
    yield put(actions.getPopularTestHomeScreenFailure(err));
  }
}

export function* watchGetPopularTestHomeScreenSaga() {
  yield takeLatest(types.GET_POPULAR_TEST_HOMESCREEN_REQUEST, getPopularTestHomeScreenSaga);
}