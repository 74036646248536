import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Network/networkActionType";
import * as actions from "../../Action/Network/viewAllNetworkAction";
import NetworkService from "../../../network/Network/Network/NetworkPageNetwork";

export function* viewAllNetworkSaga(action) {
  try {
    let response = yield call(NetworkService.viewAllNetwork,action);
    yield put(actions.viewAllNetworkSuccess(response));
  } catch (err) {
    yield put(actions.viewAllNetworkFailure(err));
  }
}

export function* watchViewAllNetworkSaga() {
  yield takeLatest(types.VIEW_ALL_NETWORK_REQUEST, viewAllNetworkSaga);
}