import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const GetQuestionSection6Request = (data) => {
    return {
        type: types.GET_QUESTION_SECTION6_REQUEST,
        data:data
    }
}

export const  GetQuestionSection6Success = (data) => {
    return {
        type: types.GET_QUESTION_SECTION6_SUCCESS,
        data:data,
    }
}

export const  GetQuestionSection6Failure = (data) => {
    return {
        type: types.GET_QUESTION_SECTION6_FAILURE,
        data:data,
    }
}