import FilterAltIcon from "@mui/icons-material/FilterAlt";
import React, { useState } from "react";
import Filter from "./Filter";

export default function FilterView({
  handleCheckbox,
  viewEveryStorage,
  handleClearAll,
  handleRadiology,
  range,
  handleSlideFilter,
  handleRadio,
  priceFilterSlider,
  showRadiology,
  radioItem,
}) {
  const [filterClick, setFilterClick] = useState(false);

  return (
    <div>
      <div
        className="viewEveryBodyRightFilter"
        onClick={() => setFilterClick(true)}
      >
        <div className="filterIcon">
          <div className="filterIconName">Filter</div>
          <FilterAltIcon fontSize="small" />
        </div>
      </div>
      <div className={filterClick ? "drawer open" : "drawer"}>
        <div className="drawer-content">
          <span className="drawer-content-span">Filters</span>
          <span
            className="close-btn"
            onClick={() => setFilterClick(!filterClick)}
          >
            &times;
          </span>
          <hr />
          <div>
            <Filter
              handleCheckbox={handleCheckbox}
              viewEveryStorage={viewEveryStorage}
              handleRadiology={handleRadiology}
              range={range}
              handleSlideFilter={handleSlideFilter}
              handleRadio={handleRadio}
              priceFilterSlider={priceFilterSlider}
              handleClearAll={handleClearAll}
              showRadiology={showRadiology}
              filterClick={filterClick}
              radioItem={radioItem}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
