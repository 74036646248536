import { combineReducers } from "redux";
import { getSection1AnswersReducer } from "./GetSectionAnswers/getSection1AnswersReducer";
import { getSection2AnswersReducer } from "./GetSectionAnswers/getSection2AnswersReducer";
import { getSection3AnswersReducer } from "./GetSectionAnswers/getSection3AnswersReducer";
import { getSection4AnswersReducer } from "./GetSectionAnswers/getSection4AnswersReducer";
import { getSection5AnswersReducer } from "./GetSectionAnswers/getSection5AnswersReducer";
import { getSection6AnswersReducer } from "./GetSectionAnswers/getSection6AnswersReducer";
import { getSection7AnswersReducer } from "./GetSectionAnswers/getSection7AnswersReducer";
import { getSection8AnswersReducer } from "./GetSectionAnswers/getSection8AnswersReducer";
import { getSection9AnswersReducer } from "./GetSectionAnswers/getSection9AnswersReducer";

export default combineReducers({
    getSection1Answers: getSection1AnswersReducer,
    getSection2Answers: getSection2AnswersReducer,
    getSection3Answers: getSection3AnswersReducer,
    getSection4Answers: getSection4AnswersReducer,
    getSection5Answers: getSection5AnswersReducer,
    getSection6Answers: getSection6AnswersReducer,
    getSection7Answers: getSection7AnswersReducer,
    getSection8Answers: getSection8AnswersReducer,
    getSection9Answers: getSection9AnswersReducer,
})