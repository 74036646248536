import { createCartRequest } from "../redux/Action/Cart/CreateCartAction";
import SessionId from "./sessionId";

export const AddCart = async (item, dispatch, setIsSubmitting,count) => {

    try {
        SessionId();

        const type = item.productPriceId ? "PRODUCT" : (item.test === false ? "PACKAGE" : "TEST")

        const finalItem = {
            count: count,
            name: item.name,
            productId: item.id,
            productType: type,
            productPriceId:item.productPriceId,
        };

        let dtoList =
            localStorage.getItem("itemDtoList") === null ?
                [] :
                JSON.parse(localStorage.getItem("itemDtoList"))

        dtoList.push(finalItem);

        await dispatch(createCartRequest(dtoList));

        setIsSubmitting(false);
        return dtoList;
    } catch (error) {
        setIsSubmitting(false);
    }
};