import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/City/cityActionType";
import * as actions from "../../Action/City/getCityAction";
import NetworkService from "../../../network/Network/City/cityNetwork";

export function* getCity(action) {
  try {
    let response = yield call(NetworkService.getCity, action);
    yield put(actions.getCitySuccess(response));
  } catch (err) {
    yield put(actions.getCityFailure(err));
  }
}

export function* watchGetCity() {
  yield takeLatest(types.GET_CITY_REQUEST, getCity);
}