import * as types from "../../Config/OTP/generateEmailOtpActionType"

export const generateEmailOtpRequest = (email,name) => {
    return {
        type: types.GENERATE_EMAIL_OTP_REQUEST,
        email,
        name
    }
}

export const generateEmailOtpSuccess = (data) => {
    return {
        type: types.GENERATE_EMAIL_OTP_SUCCESS,
        data:data
    }
}

export const generateEmailOtpFailure = (data) => {
    return {
        type: types.GENERATE_EMAIL_OTP_FAILURE,
        data:data,
    }
}