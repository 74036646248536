import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import storage from "../../../Utils/storage";
import banner2 from "../../../assets/Network/banner2.png";
import { viewNetworkCountRequest } from "../../../redux/Action/Network/viewNetworkCountAction";
import Title from "../../Atoms/Title/Title";
import "./Banner.css";
import NetworkHero from "./NetworkHero";

function Banner() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const viewNetworkCount = useSelector(
    (state) => state.network.viewNetworkCount
  );

  useEffect(() => {
    dispatch(viewNetworkCountRequest());
  }, []);

  const handleNavigate = () => {
    navigate("/join-us/form");
  };
  const str = window.location.href;

  function searchNetwork() {
    navigate("/find-us");
  }

  const joinOurNetwork = () => {
    storage.setStorage("FormType", "na");
    navigate("/join-us/form");
  };

  return (
    <>
      <Title title="YUVA |  Partnered with the best." />
      <NetworkHero />
      <div className="bannerContainer1">
        <div className="bannerHeading">Our Network</div>
        <div className="bannerContent1">
          Our cloud-based network platform currently includes over 1,000 active
          provider partners across India, such as specialty physicians,
          hospitals, labs, X-ray facilities, home healthcare companies, and
          same-day surgery centers. With our network, our members have access to
          a wide range of healthcare services and facilities, making it easier
          for them to find the care they need, when they need it. Overall, our
          provider network is designed to provide our members with a
          comprehensive range of affordable healthcare services, focusing on
          transparency, convenience, and quality.
        </div>
      </div>

      <div className="bannerHeadingImage2">
        <div
          className="bannerGridBlock"
          style={{
            backgroundImage: `url(${banner2})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
        >
          <div className="bannerGridContainer">
            <div className="bannerGridContent1">
              {" "}
              {viewNetworkCount.data?.data.data.data["HOSPITAL"]}
            </div>
            <div className="bannerGridContent2">Network Hospital</div>
          </div>
          <div className="bannerGridContainer">
            <div className="bannerGridContent1">
              {" "}
              {viewNetworkCount.data?.data.data.data["CLINIC"]}
            </div>
            <div className="bannerGridContent2">Network Clinic</div>
          </div>
          <div className="bannerGridContainer">
            <div className="bannerGridContent1">
              {" "}
              {viewNetworkCount.data?.data.data.data["DIAGNOSTIC"]}
            </div>
            <div className="bannerGridContent2">Diagnostic Center</div>
          </div>
          <div className="bannerGridContainer">
            <div className="bannerGridContent1">
              {viewNetworkCount.data?.data.data.data["PROFESSIONAL_SERVICE"]}
            </div>
            <div className="bannerGridContent2">Professional Service</div>
          </div>
        </div>
      </div>

      <div className="bannerContainer1">
        <div className="bannerHeading">
          Our Outpatient Consultation Services
        </div>
        <div className="bannerContent1">
          We provide cashless outpatient services across a network of 600+
          hospitals and clinics in over 50 cities throughout India. Our members
          can access our website to view the profiles of the healthcare
          providers in our network, book appointments, and manage their medical
          records in a secure online environment.
          <br />
          <br />
          We have over 1000 healthcare providers connected to us through a
          cloud-based data exchange platform, allowing for real-time data
          exchange and scheduling to provide a seamless and convenient customer
          experience. Our platform also offers easy access to medical reports
          and prescription histories, making it easier for our members to track
          their health and manage their care.
        </div>
        <div className="networkBannerButton">
          <button onClick={searchNetwork} className="bannerButton">
            Search our network
          </button>
        </div>

        <div className="bannerHeading">Partner With Us</div>
        <div className="bannerContent1">
          When you partner with us you will have access to our unrivaled and
          inexhaustible network of members. Our empanelment process is
          straightforward and transparent, and we are always happy to answer any
          questions you may have. We require all of our network providers to
          meet certain quality standards, and we regularly monitor our network
          to ensure that our members are receiving the best possible care. By
          partnering with us, you will be joining a network of healthcare
          providers who are committed to providing the best possible care to our
          members.
        </div>
        <div className="networkBannerButton">
          <button onClick={joinOurNetwork} className="bannerButton">
            Partner with Us{" "}
          </button>
        </div>
      </div>
    </>
  );
}

export default Banner;
