import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import backArrow from "../../../assets/hra/backArrow.png";
import hra5 from "../../../assets/hra/hra5.png";
import { GetQuestionSection4Request } from "../../../redux/Action/HealthRiskAssessment/GetQuestionSection4Action";
import { GetSection4AnswersRequest } from "../../../redux/Action/HealthRiskAssessment/GetSectionAnswers/GetSection4AnswersAction";
import { Section4AnswersSuccess } from "../../../redux/Action/HealthRiskAssessment/HRAAnswers/Section4AnswersAction";
import { SaveSectionAnswersRequest } from "../../../redux/Action/HealthRiskAssessment/SaveSectionAnswersAction";
import storage from "../../../Utils/storage";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import Footer from "../../Organisms/Footer/Footer";
import SingleLogin from "../Login/SingleLogin";
import {
  dropDisabled,
  pickeryesno,
  pickeralcohol2,
  pickeralcohol3,
  pickeralcohol4,
} from "./HraDropdowns";

function Section4() {
  const [drinking, setDrinking] = useState("");
  const SectionValue = localStorage.getItem("SectionValue");
  const navigate = useNavigate();

  const newSchema = Yup.object().shape({
    Q31: Yup.string().required("Please select value"),
    Q32: Yup.string().when("Q31", {
      is: "1",
      then: Yup.string().required("Please select value"),
    }),
    Q33: Yup.string().when("Q31", {
      is: "1",
      then: Yup.string().required("Please select value"),
    }),
    Q34: Yup.string().when("Q31", {
      is: "1",
      then: Yup.string().required("Please select value"),
    }),
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetQuestionSection4Request());
    dispatch(GetSection4AnswersRequest());
  }, []);
  const currentSectionValue = localStorage.getItem("SectionValue");
  const GetSectionAnswers = useSelector(
    (state) => state.getSectionAnswer.getSection4Answers
  );
  const [json, setJson] = useState();
  const [counter, setCounter] = useState(false);

  useEffect(() => {
    if (GetSectionAnswers?.data?.data?.data?.data !== undefined) {
      setJson(JSON.parse(GetSectionAnswers?.data?.data?.data?.data));
      setCounter(true);
    }
  }, [GetSectionAnswers?.data?.data?.data?.data]);

  useEffect(() => {
    if (json?.Q31.toString() === "1") {
      setDrinking("1");
    } else {
      setDrinking("0");
    }
  }, [json]);

  window.addEventListener("popstate", function (event) {
    storage.setStorage("SectionValue", 3);
  });

  const handleSubmit = (values, { setSubmitting }) => {
    localStorage.setItem("SectionValue", 5);
    if (values.Q31 === "0") {
      values.Q32 = "";
      values.Q33 = "";
      values.Q34 = "";
    }
    dispatch(Section4AnswersSuccess(values));
    dispatch(SaveSectionAnswersRequest({ values, currentSectionValue }));
    navigate("/health-risk-assessment/section-5a");
    setSubmitting(false);
  };

  const handlePrevious = () => {
    navigate("/health-risk-assessment/section-4b");
  };

  const GetQuestionSection = useSelector(
    (state) => state.getQuestion.getQuestionSection4
  );

  const handleDrinking = (e) => {
    if (e.target.value === "0") {
      setDrinking("0");
    } else {
      setDrinking("1");
    }
  };

  if (storage.getStorage("token") === null) {
    return <SingleLogin />;
  }

  if (GetQuestionSection.isLoading) {
    return <YuvaLoader />;
  }
  return (
    <>
      <ScrollToTop />

      <div className="HRAQuestionsMain">
        <div className="HRAQuestionsMainChild1">
          <p>Your Health Risk Assessment</p>
        </div>
        {SectionValue === "4" && (
          <div
            className="HRAQuestionsMainChild2"
            style={{ backgroundImage: `url(${hra5})` }}
          >
            <div className="HRAQuestionsMainChild2a">
              <div className="HRAQuestionsMainChild2a1">
                <p>GENERAL SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1 faded">
                <p>DIETARY SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1">
                <p>MENTAL HEALTH SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1">
                <p>ALCOHOL RISK SECTION</p>
              </div>
              {SectionValue === "4" && (
                <div className="HRAQuestionsMainChild2a2">
                  <Formik
                    initialValues={
                      !counter
                        ? {
                            Q31: "",
                            Q32: "",
                            Q33: "",
                            Q34: "",
                          }
                        : {
                            Q31: json?.Q31 === null ? "" : json?.Q31.toString(),
                            Q32: json?.Q32 === null ? "" : json?.Q32.toString(),
                            Q33: json?.Q33 === null ? "" : json?.Q33.toString(),
                            Q34: json?.Q34 === null ? "" : json?.Q34.toString(),
                          }
                    }
                    validationSchema={newSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                  >
                    {({ isSubmitting, setFieldValue, values }) => {
                      return (
                        <Form>
                          <div className="HRAQuestionsMainChild2a2div">
                            <div className="hraBackArrow">
                              <img
                                src={backArrow}
                                onClick={handlePrevious}
                                alt="backArrow"
                              />
                              <p>Alcohol Risk</p>
                            </div>
                            <label className="Q_A">
                              <p>
                                {
                                  GetQuestionSection.data?.data.data.data[0]
                                    .question
                                }
                              </p>
                              <Field
                                as="select"
                                name="Q31"
                                className="select"
                                onChange={(e) => {
                                  setFieldValue("Q31", e.target.value);
                                  handleDrinking(e);
                                }}
                              >
                                <option value={dropDisabled.value} disabled>
                                  {dropDisabled.message}
                                </option>
                                {pickeryesno.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.option}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                className="errorMessageValidation"
                                name="Q31"
                                component="div"
                              />
                            </label>
                            {drinking === "1" && (
                              <>
                                <label className="Q_A">
                                  <p>
                                    {
                                      GetQuestionSection.data?.data.data.data[1]
                                        .question
                                    }
                                  </p>
                                  <Field
                                    as="select"
                                    name="Q32"
                                    className="select"
                                  >
                                    <option value={dropDisabled.value} disabled>
                                      {dropDisabled.message}
                                    </option>
                                    {pickeralcohol2.map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.option}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    className="errorMessageValidation"
                                    name="Q32"
                                    component="div"
                                  />
                                </label>
                                <label className="Q_A">
                                  <p>
                                    {
                                      GetQuestionSection.data?.data.data.data[2]
                                        .question
                                    }
                                  </p>
                                  <Field
                                    as="select"
                                    name="Q33"
                                    className="select"
                                  >
                                    <option value={dropDisabled.value} disabled>
                                      {dropDisabled.message}
                                    </option>
                                    {pickeralcohol3.map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.option}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    className="errorMessageValidation"
                                    name="Q33"
                                    component="div"
                                  />
                                </label>
                                <label className="Q_A">
                                  <p>
                                    {
                                      GetQuestionSection.data?.data.data.data[3]
                                        .question
                                    }
                                  </p>
                                  <Field
                                    as="select"
                                    name="Q34"
                                    className="select"
                                  >
                                    <option value={dropDisabled.value} disabled>
                                      {dropDisabled.message}
                                    </option>
                                    {pickeralcohol4.map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.option}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    className="errorMessageValidation"
                                    name="Q34"
                                    component="div"
                                  />
                                </label>
                              </>
                            )}

                            <button
                              type="submit"
                              className="HRAQuestionsButton"
                              disabled={isSubmitting}
                            >
                              Next
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        )}
        {SectionValue !== "4" && (
          <div className="hraDenied">
            Not Authorized !! please navigate to previous sections
          </div>
        )}
      </div>
    </>
  );
}

export default Section4;
