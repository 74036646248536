import * as React from "react";
import { useState } from "react";
import {  useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import storage from "../../../Utils/storage";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import { Helmet } from "react-helmet";
import "./FinalPaymentPage.css";

function FinalPaymentPage({ SelectPaymentMethod }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState(false);
  const getCart = useSelector((state) => state.cart.getCart);

  const handlePaymentMethod = (e) => {
    setPaymentMethod(e);
    SelectPaymentMethod(e);
  };

  if (
    getCart.isLoading ||
    (getCart.data === null &&
      getCart.error === null &&
      getCart.isLoading === false)
  ) {
    return <YuvaLoader />;
  }

  if (
    location.state === null ||
    getCart.error !== null ||
    storage.getStorage("token") === null
  ) {
    navigate("/");
  } else {
    return (
      <>
        <Helmet>
          <title>YUVA | Final Payment</title>
          <meta
            name="description"
            content="Review and manage your selections with ease at YUVA Health's cart page.
         Experience seamless checkout and personalized service for all your healthcare needs"
          />
        </Helmet>
        <div className="AddressList">
          <div className="AddressListUpper">
            <p className="AddressListUpperHeading">Select Payment Method</p>
          </div>
          <div
            className="AddressListLowerDateAndTimeFPP"
            onClick={(e) => handlePaymentMethod(true)}
          >
            <div className="SelectPaymemtLeft">
              <input
                type="radio"
                value={true}
                id="cod"
                name="paymentMode"
                checked={paymentMethod === true}
                onChange={(e) => handlePaymentMethod(true)}
              />
              <p className="SelectPaymemtLeftLabel">Cash On Delivery</p>
            </div>
            <p className="SelectPaymentTerms">Term & Condition Apply*</p>
          </div>
          <div
            className="AddressListLowerDateAndTimeFPP"
            onClick={(e) => handlePaymentMethod(false)}
          >
            <div className="SelectPaymemtLeft">
              <input
                type="radio"
                value={false}
                id="online"
                name="paymentMode"
                checked={paymentMethod === false}
                onChange={(e) => handlePaymentMethod(false)}
              />
              <p className="SelectPaymemtLeftLabel">Online</p>
            </div>
            <p className="SelectPaymentTerms">Term & Condition Apply*</p>
          </div>
        </div>
      </>
    );
  }
}
export default FinalPaymentPage;
