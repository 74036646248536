import * as types from "../../Config/ContactUsPage/ContactUsPageActionType"

export const QueryTypeRequest = (data) => {
    return {
        type: types.QUERY_TYPE_REQUEST,
        data:data
    }
}

export const  QueryTypeSuccess = (data) => {
    return {
        type: types.QUERY_TYPE_SUCCESS,
        data:data,
    }
}

export const  QueryTypeFailure = (data) => {
    return {
        type: types.QUERY_TYPE_FAILURE,
        data:data,
    }
}