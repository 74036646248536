export const api = {
  cart: "/cart",
  removeItem: "/cart",
  CartDiscount:"/",
  createOrder: "/order",
  paymentGatewayRedirect: "/paymentGateway/redirect", 
  RedeemCoupon:"/coupon/redeem",
  GetAllCoupon:"/coupon",
  paymentGatewayStatus: "/paymentGateway/status"
};
