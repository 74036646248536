import * as types from "../../Config/User/userActionType";

export const UploadFamilyPicRequest = (data) => {
    return {
        type: types.UPLOAD_FAMILY_PICTURE_REQUEST,
        data:data,
    }
}

export const UploadFamilyPicSuccess = (data) => {
    return {
        type: types.UPLOAD_FAMILY_PICTURE_SUCCESS,
        data:data,
    }
}

export const UploadFamilyPicFailure = (data) => {
    return {
        type: types.UPLOAD_FAMILY_PICTURE_FAILURE,
        data:data,
    }
}