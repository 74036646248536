import RequestService from "../../../RequestService";
import { api } from "../../../../helpers/OTP/OTP/otpConstant";

class NetworkService {
  generateOtp(action) {
    const endPoint = `${api.generateOtp}`;
    return RequestService.postRequest(endPoint, {
      email: action.data
    });
  }
}

export default new NetworkService();
