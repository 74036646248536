import RequestService from "../../../RequestService";
import { api } from "../../../../helpers/OTP/OTPValidation/UpdateProfileOTPValidation";

class NetworkService {
  UpdateProfilevalidateOtpNetwork(action) {
    const endPoint = `${api.UpdateProfilevalidateOtp}`;
    return RequestService.postRequest(endPoint, {
      emailOrNumber: action.data.email,
      otp: action.data.otp,
    });
  }
}

export default new NetworkService();