import * as types from "../../Config/CheckEmail/CheckNumber"

export const CheckNumberRequest = (data) => {
    return {
        type: types.CHECK_NUMBER_REQUEST,
        data:data,
    }
}

export const  CheckNumberSuccess = (data) => {
    return {
        type: types.CHECK_NUMBER_SUCCESS,
        data:data,
    }
}

export const  CheckNumberFailure = (data) => {
    return {
        type: types.CHECK_NUMBER_FAILURE,
        data:data,
    }
}