import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import storage from "../../../Utils/storage";
import { GetRelationsRequest } from "../../../redux/Action/HealthRiskAssessment/GetRelationsAction";
import { GetAllRelativesRequest } from "../../../redux/Action/User/GetAllRelatives";
import { GetProfileRequest } from "../../../redux/Action/User/GetProfileAction";
import { UpdateProfileRequest } from "../../../redux/Action/User/UpdateProfile";
import "./TellAboutYourselfPage.css";

function HraRelationSelectionModal(prop) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
 

  useEffect(() => {
    dispatch(GetProfileRequest());
    dispatch(GetRelationsRequest());
    dispatch(GetAllRelativesRequest());
  }, [dispatch]);

  const GetProfile = useSelector((state) => state.user.getProfile);
  const GetAllRelatives = useSelector((state) => state.user.getAllRelatives);
  const updateProfile = useSelector((state) => state.user.updateProfile);

  const [open, setOpen] = useState(true);
  const [selectRelation, setSelectRelation] = useState("0");
  const [selectGender, setSelectGender] = useState();
  const [selectGenderSpouse, setSelectGenderSpouse] = useState();

  const [showDetailsSpouse, setShowDetailsSpouse] = useState(false);
  const [relationSpouse, setRelationSpouse] = useState();

  const [showDetails, setShowDetails] = useState(false);
  const [date, setDate] = useState(null);

  let finalName = "";
  let finalAge = "";
  let finalGender = "";
  let finalId = "";

  function handleRelation(e) {
    setSelectRelation(e.target.value);
  }

  function handleGender(e) {
    setSelectGender(e.target.value);
  }
  function handleGenderSpouse(e) {
    setSelectGenderSpouse(e.target.value);
  }
  function handleDate(e) {
    setDate(e.target.value);
  }

  useEffect(() => {
    if (updateProfile.data?.data?.status === 200 && selectRelation === "0") {
      setShowDetails(false);
      setShowDetailsSpouse(false);
      storage.setStorage("profileUpdated", true);
      if (storage.getStorage("profileUpdated") === "false") {
        setShowDetails(true);
        setShowDetailsSpouse(false);
      } else if (
        updateProfile.data !== null
          ? updateProfile.data?.data?.data?.data.age < 12
          : GetProfile.data?.data?.data?.data.age < 12
      ) {
        Swal.fire({
          title: "Not Applicable for HRA  !! (Age should not be less than 12)",
          icon: "error",
        }).then(() => {
          updateProfile.data = null;
          setShowDetails(false);
        });
      } else {
        localStorage.setItem(
          "age",
          updateProfile.data !== null
            ? updateProfile.data?.data?.data?.data.age
            : GetProfile?.data?.data?.data?.data.age
        );
        localStorage.setItem(
          "gender",
          updateProfile.data !== null
            ? updateProfile.data?.data?.data?.data.gender
            : GetProfile?.data?.data?.data?.data.gender
        );
        localStorage.setItem("relationId", "");
        localStorage.setItem("relationName", finalName);
        updateProfile.data = null;

        navigate("/health-risk-assessment/section-1");
      }
    }

    if (
      updateProfile.data?.data?.status === 200 &&
      selectRelation !== "0" &&
      relationSpouse !== "Spouse"
    ) {
      setShowDetails(false);
      setShowDetailsSpouse(false);
    }

    if (
      updateProfile.data?.data?.status === 200 &&
      selectRelation !== "0" &&
      relationSpouse === "Spouse"
    ) {
      setShowDetails(false);
      setShowDetailsSpouse(false);
      if (finalAge < 12) {
        Swal.fire({
          title: "Not Applicable for HRA  !! (Age should not be less than 12)",
          icon: "error",
        }).then(() => {
          updateProfile.data = null;
          setShowDetailsSpouse(false);
        });
      } else {
        setShowDetailsSpouse(false);
        setShowDetails(false);
        updateProfile.data.data.status = null;
        localStorage.setItem("age", finalAge);
        localStorage.setItem(
          "gender",
          GetProfile?.data?.data?.data?.data.gender === "Male"
            ? "Male"
            : "Female"
        );
        localStorage.setItem("relationId", finalId);
        localStorage.setItem("relationName", finalName);
        updateProfile.data = null;

        navigate("/health-risk-assessment/section-1");
      }
    }
  }, [
    updateProfile,
    GetProfile,
    finalAge,
    finalName,
    finalId,
    relationSpouse,
    selectRelation,
    navigate,
  ]);

  GetAllRelatives?.data?.data?.data?.data.forEach((item) => {
    if (item.id === parseInt(selectRelation)) {
      finalAge = item.age;
    }
  });
  GetAllRelatives?.data?.data?.data?.data.forEach((item) => {
    if (item.id === parseInt(selectRelation)) {
      finalGender = item.gender;
    }
  });
  GetAllRelatives?.data?.data?.data?.data.forEach((item) => {
    if (item.id === parseInt(selectRelation)) {
      finalId = item.id;
    }
  });
  GetAllRelatives?.data?.data?.data?.data.forEach((item) => {
    if (item.id === parseInt(selectRelation)) {
      finalName = item.name;
    }
  });

  const handleSubmit = (values) => {
    if (selectRelation === "0") {
      if (storage.getStorage("profileUpdated") === "false") {
        setShowDetails(true);
        setShowDetailsSpouse(false);
      } else if (
        updateProfile.data !== null
          ? updateProfile.data?.data?.data?.data.age < 12
          : GetProfile.data?.data?.data?.data.age < 12
      ) {
        Swal.fire({
          title: "Not Applicable for HRA  !! (Age should not be less than 12)",
          icon: "error",
        });
      } else {
        localStorage.setItem(
          "age",
          updateProfile.data !== null
            ? updateProfile.data?.data?.data?.data.age
            : GetProfile?.data?.data?.data?.data.age
        );
        localStorage.setItem(
          "gender",
          updateProfile.data !== null
            ? updateProfile.data?.data?.data?.data.gender
            : GetProfile?.data?.data?.data?.data.gender
        );
        localStorage.setItem("relationId", "");
        localStorage.setItem("relationName", finalName);

        navigate("/health-risk-assessment/section-1");
      }
    } else if (relationSpouse === "Spouse" && updateProfile.data !== null) {
      if (finalAge < 12) {
        Swal.fire({
          title: "Not Applicable for HRA  !! (Age should not be less than 12)",
          icon: "error",
        });
      } else {
        setShowDetailsSpouse(false);
        setShowDetails(false);
        localStorage.setItem("age", finalAge);
        localStorage.setItem(
          "gender",
          GetProfile?.data?.data?.data?.data.gender === "Male"
            ? "Male"
            : "Female"
        );
        localStorage.setItem("relationId", finalId);
        localStorage.setItem("relationName", finalName);

        navigate("/health-risk-assessment/section-1");
      }
    } else if (
      relationSpouse === "Spouse" &&
      updateProfile.data === null &&
      GetProfile?.data?.data?.data?.data.gender === null
    ) {
      setShowDetailsSpouse(true);
      setShowDetails(false);
    } else {
      setShowDetails(false);

      if (finalAge < 12) {
        Swal.fire({
          title: "Not Applicable for HRA  !! (Age should not be less than 12)",
          icon: "error",
        });
      } else {
        localStorage.setItem("age", finalAge);
        localStorage.setItem("gender", finalGender);
        localStorage.setItem("relationId", finalId);
        localStorage.setItem("relationName", finalName);

        navigate("/health-risk-assessment/section-1");
      }
    }
  };
  useEffect(() => {
    if (selectRelation === "0") {
      setShowDetailsSpouse(false);
    } else if (relationSpouse === "Spouse") {
      setShowDetails(false);
    } else if (relationSpouse !== "Spouse") {
      setShowDetailsSpouse(false);
      setShowDetails(false);
    }
  }, [selectRelation, relationSpouse]);
  function handleClose() {
    prop.setOpenModal("");
    setOpen(false);
  }

  function saveData() {
    const submissionData = {
      DateOfBirth: date,
      Address: null,
      Gender: selectGender,
      Pincode: null,
    };
    let cityId = null;
    let email = null;
    let emailOtp = null;
    let number = null;
    let numberOtp = null;

    dispatch(
      UpdateProfileRequest(
        submissionData,
        cityId,
        numberOtp,
        emailOtp,
        email,
        number
      )
    );
  }

  var DateConverted = new Date(date);
  DateConverted = DateConverted.getTime();
  function saveData() {
    if(DateConverted===0 || selectGender===undefined){
      Swal.fire({
        title: "Please fill the details carefully",
        icon: "info",
    })
    }
    else{
      const submissionData = {
        DateOfBirth: DateConverted,
        Address: null,
        Gender: selectGender,
        Pincode: null,
      };
      let cityId = null;
      let email = null;
      let emailOtp = null;
      let number = null;
      let numberOtp = null;

      dispatch(
        UpdateProfileRequest(
          submissionData,
          cityId,
          numberOtp,
          emailOtp,
          email,
          number
        )
      );
    }
      
    }
  

  useEffect(() => {
    GetAllRelatives?.data?.data?.data?.data?.forEach((item) => {
      if (parseInt(selectRelation) === item.id) {
        setRelationSpouse(item.relation);
      }
    });
  }, [selectRelation, GetAllRelatives]);

  function saveDataSpouse() {
    const submissionData = {
      DateOfBirth: null,
      Address: null,
      Gender: selectGenderSpouse,
      Pincode: null,
    };
    let cityId = null;
    let email = null;
    let emailOtp = null;
    let number = null;
    let numberOtp = null;
    dispatch(
      UpdateProfileRequest(
        submissionData,
        cityId,
        numberOtp,
        emailOtp,
        email,
        number
      )
    );
  }

  return (
    <>
      
     
          {open && (
            <div>
              <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="mainModal"
                // onClick={handleClose}
              >
                <Box className="style1">
                  <div className="TellUsAboutYourselfMain">
                    <div className="RelationsBox">
                      <div className="BoxOuter">
                        <div className="TellAboutTitle">
                          Who is this Assessment for?
                          <CloseIcon
                            className="cutButtonTitleIcon"
                            onClick={handleClose}
                          />
                        </div>

                        <label>
                          <select
                            onChange={handleRelation}
                            name="relation"
                            className="inputFieldRelations"
                            value={selectRelation}
                          >
                            <option value="0" selected>
                              Myself &nbsp;
                            </option>

                            {GetAllRelatives?.data?.data?.data?.data?.map(
                              (item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                  &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                                  {item.relation}
                                  &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;(
                                  {item.age})
                                </option>
                              )
                            )}
                          </select>
                        </label>
                        <br />
                        {showDetails &&
                        GetProfile.data?.data.data.data.gender === null ? (
                          <label>
                            <p style={{ marginTop: "1em" }}>
                              Select your gender
                            </p>
                            <select
                              onChange={handleGender}
                              name="selectGender"
                              className="inputFieldRelations"
                              value={selectGender}
                              style={{ marginTop: "-.5em" }}
                            >
                              <option value="">Select gender</option>
                              <option value="Male" label="Male">
                                Male
                              </option>
                              <option value="Female" label="Female">
                                Female
                              </option>
                            </select>
                          </label>
                        ) : (
                          ""
                        )}
                        <br />
                        {showDetails ? (
                          <label>
                            <p style={{ marginTop: "1em" }}>
                              Enter your Date of Birth
                            </p>

                            <input
                              type="date"
                              className="inputFieldRelations"
                              style={{
                                background: "#EFEFEF",
                                border: ".5px solid gray",
                                marginTop: "-.5em",
                              }}
                              onChange={handleDate}
                            />
                          </label>
                        ) : (
                          ""
                        )}

                        {showDetailsSpouse ? (
                          <label>
                            <p style={{ marginTop: "1em" }}>
                              Select your spouse gender
                            </p>
                            <select
                              onChange={handleGenderSpouse}
                              name="selectGender"
                              className="inputFieldRelations"
                              value={selectGenderSpouse}
                              style={{ marginTop: "-.5em" }}
                            >
                              <option value="">Select gender</option>
                              <option value="Male" label="Female">
                                Male
                              </option>
                              <option value="Female" label="Male">
                                Female
                              </option>
                            </select>
                          </label>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="BtnSaveRealtions">
                        <button
                          className="ButtonSaveRealtions"
                          onClick={
                            showDetails === true
                              ? saveData
                              : showDetailsSpouse === true
                              ? saveDataSpouse
                              : handleSubmit
                          }
                          style={
                            updateProfile.isLoading
                              ? { background: "transparent" }
                              : {}
                          }
                          disabled={updateProfile.isLoading ? true : false}
                        >
                          {(showDetails === true &&
                            storage.getStorage("profileUpdated") === "false") ||
                          showDetailsSpouse === true ? (
                            <>
                              {updateProfile.isLoading ? (
                                <CircularProgress />
                              ) : (
                                "Save"
                              )}{" "}
                            </>
                          ) : (
                            <> Let's Start</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
          )}
       
    </>
  );
}

export default HraRelationSelectionModal;
