import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import backArrow from "../../../assets/hra/backArrow.png";
import hra6 from "../../../assets/hra/hra7.png";
import { GetQuestionSection6Request } from "../../../redux/Action/HealthRiskAssessment/GetQuestionSection6Action";
import { GetSection6AnswersRequest } from "../../../redux/Action/HealthRiskAssessment/GetSectionAnswers/GetSection6AnswersAction";
import { Section6AnswersSuccess } from "../../../redux/Action/HealthRiskAssessment/HRAAnswers/Section6AnswersAction";
import { SaveSectionAnswersRequest } from "../../../redux/Action/HealthRiskAssessment/SaveSectionAnswersAction";
import storage from "../../../Utils/storage";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import SingleLogin from "../Login/SingleLogin";
import { dropDisabled, pickeryesno } from "./HraDropdowns";
import "./Sections.css";

function Section6() {
  const dispatch = useDispatch();
  const SectionValue = localStorage.getItem("SectionValue");
  const navigate = useNavigate();

  const Schema6 = Yup.object().shape({
    Q39: Yup.string().required("Required Field"),
    Q40: Yup.string().required("Required Field"),
  });

  useEffect(() => {
    dispatch(GetQuestionSection6Request());
    dispatch(GetSection6AnswersRequest());
  }, []);

  const GetQuestionSection = useSelector(
    (state) => state.getQuestion.getQuestionSection6
  );
  const currentSectionValue = localStorage.getItem("SectionValue");
  const GetSectionAnswers = useSelector(
    (state) => state.getSectionAnswer.getSection6Answers
  );
  const [json, setJson] = useState();
  const [counter, setCounter] = useState(false);

  useEffect(() => {
    if (GetSectionAnswers?.data?.data?.data?.data !== undefined) {
      setJson(JSON.parse(GetSectionAnswers?.data?.data?.data?.data));
      setCounter(true);
    }
  }, [GetSectionAnswers?.data?.data?.data?.data]);

  window.addEventListener("popstate", function (event) {
    storage.setStorage("SectionValue", 5);
  });

  const handleSubmit = (values, { setSubmitting }) => {
    localStorage.setItem("SectionValue", 7);
    dispatch(Section6AnswersSuccess(values));

    dispatch(SaveSectionAnswersRequest({ values, currentSectionValue }));
    navigate("/health-risk-assessment/section-7a");
  };

  const handlePrevious = () => {
    navigate("/health-risk-assessment/section-6b");
  };

  if (storage.getStorage("token") === null) {
    return <SingleLogin />;
  }

  if (GetQuestionSection.isLoading) {
    return <YuvaLoader />;
  }
  return (
    <>
      <ScrollToTop />

      <div className="HRAQuestionsMain">
        <div className="HRAQuestionsMainChild1">
          <p>Your Health Risk Assessment</p>
        </div>
        {SectionValue === "6" && (
          <div
            className="HRAQuestionsMainChild2"
            style={{ backgroundImage: `url(${hra6})` }}
          >
            <div className="HRAQuestionsMainChild2a">
              <div className="HRAQuestionsMainChild2a1">
                <p>GENERAL SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1 faded">
                <p>DIETARY SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1">
                <p>MENTAL HEALTH SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1 faded">
                <p>ALCOHOL RISK SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1">
                <p>SMOKING & TOBACCO SECTION</p>
              </div>
              <div className="HRAQuestionsMainChild2a1 faded">
                <p>SAFETY HABITS SECTION</p>
              </div>
              {SectionValue === "6" && (
                <div className="HRAQuestionsMainChild2a2">
                  <Formik
                    initialValues={
                      !counter
                        ? {
                            Q39: "",
                            Q40: "",
                          }
                        : {
                            Q39: json?.Q39.toString(),
                            Q40: json?.Q40.toString(),
                          }
                    }
                    validationSchema={Schema6}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                  >
                    {
                      <Form>
                        <div className="HRAQuestionsMainChild2a2div">
                          <div className="hraBackArrow">
                            <img
                              src={backArrow}
                              onClick={handlePrevious}
                              alt="backArrow"
                            />
                            <p>Safety Risk</p>
                          </div>
                          <label className="Q_A">
                            <p>
                              {
                                GetQuestionSection?.data?.data?.data?.data[0]
                                  .question
                              }
                            </p>
                            <Field
                              as="select"
                              name="Q39"
                              // placeholder="Doctor Name"
                              className="select"
                            >
                              <option value={dropDisabled.value} disabled>
                                {dropDisabled.message}
                              </option>
                              {pickeryesno.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.option}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              className="errorMessageValidation"
                              name="Q39"
                              component="div"
                            />
                          </label>

                          <label className="Q_A">
                            <p>
                              {
                                GetQuestionSection?.data?.data?.data?.data[1]
                                  .question
                              }
                            </p>
                            <Field
                              as="select"
                              name="Q40"
                              // placeholder="Doctor Name"
                              className="select"
                            >
                              <option value={dropDisabled.value} disabled>
                                {dropDisabled.message}
                              </option>
                              {pickeryesno.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.option}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              className="errorMessageValidation"
                              name="Q40"
                              component="div"
                            />
                          </label>
                          <button type="submit" className="HRAQuestionsButton">
                            Next
                          </button>
                        </div>
                      </Form>
                    }
                  </Formik>
                </div>
              )}
            </div>
          </div>
        )}
        {SectionValue !== "6" && (
          <div className="hraDenied">
            Not Authorized !! please navigate to previous sections
          </div>
        )}
      </div>
    </>
  );
}

export default Section6;
