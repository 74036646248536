import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../Action/HealthRiskAssessment/ContinueSectionAction";
import NetworkService from "../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* ContinueSectionSaga(action) {
  try {
    let response = yield call(NetworkService.ContinueSection, action);
    yield put(actions.ContinueSectionSuccess(response));
  } catch (err) {
    yield put(actions.ContinueSectionFailure(err));
  }
}

export function* WatchContinueSectionSaga() {
  yield takeLatest(types.CONTINUE_SECTION_REQUEST, ContinueSectionSaga);
}
