import RequestService from "../../RequestService";
import { api } from "../../../helpers/ERM/viewMedicalRecordConstant"

class NetworkService {
   medicalRecordDropdown(action) {
   
        const endPoint = `${api.medicalRecordDropdown}`;
        return RequestService.getRequest(endPoint)
    }
    // viewMedicalRecord(action) {
    //     const endPoint = `${api.ViewMedicalRecord}?pageNo=${action.page}&pageSize=${action.pageSize}`;
    //     return RequestService.getRequest(endPoint)
    // }
    viewMedicalRecord(action){
        const endPoint = `${api.ViewMedicalRecord}?pageNo=${action.page}&pageSize=${action.pageSize}`;
        return RequestService.postRequest(endPoint, {
            searchKey: action.search,
          documentType:action.selectedValue
        });
      }
      downloadErmReport(action){
        const endPoint = `${api.downloadErmReport}?recordId=${action.data}`;
        return RequestService.getFileRequest(endPoint);
      }


      medicalRecordForm(action) {
        const endPoint = `${api.medicalRecordForm}`;
        return RequestService.postRequest(
          endPoint,
          action.data
    
          //   {
          //   address: action.data.Address,
          //   cityId: action.data.City,
          //   clinicName: action.data.Name,
          //   clinicPhoto: action.data.ClinicPhoto,
          //   clinicType: action.data.ClinicType,
          //   consultationFees: action.data.ConsultationPrice,
          //   email: action.data.Email,
          //   location: action.data.Location,
          //   number: action.data.ContactNumber,
          //   ownerName: action.data.OwnerName,
          //   panNumber: action.data.PanCardNumber,
          //   pinCode: action.data.Pincode,
          //   rateList: action.data.RateList,
          //   registrationNumber: action.data.RegistrationNumber,
          //   services: action.data.Services,
          // }
        );
      }
      
}

export default new NetworkService();
