import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { otpRequest } from "../../../redux/Action/OTP/OTP/otpAction";
import { otpValidationRequest } from "../../../redux/Action/OTP/OTPValidation/otpValidationAction";

function VerificationLoginEmailOTPScreen({ email, name }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [isWrong, setIsWrong] = useState(false);
  const [isExpire, setExpire] = useState(false);
  const [timer, setTimer] = useState(30);
  const [showLoader, setShowLoader] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(true);

  const handleChange = (code) => setCode(code);

  const handleClickOtp = () => {
    if (code.length === 4) {
      var finalCode = parseInt(code);
      dispatch(
        otpValidationRequest({ email: email, otp: finalCode, name: name })
      );
    }
  };

  const validateOTP = useSelector((state) => state.otp.validateOtp);

  useEffect(() => {
    if (validateOTP.data?.data.status === 200) {
      Swal.fire({
        title: "Successfully Login",
        icon: "success",
      }).then(() => {
        navigate("/");
      });
    }
  }, [validateOTP.data?.data.status, navigate]);

  useEffect(() => {
    if (
      validateOTP.error?.data.response.status === 400 &&
      validateOTP.error?.data.response.data.errorMessage === "Invalid Otp"
    ) {
      setIsWrong(true);
      setExpire(false);
      setShowLoader(false);
      validateOTP.error.data.response.status = null;
    }
    if (
      validateOTP.error?.data.response.status === 400 &&
      validateOTP?.error?.data?.response?.data?.errorMessage === "Otp Expired"
    ) {
      setExpire(true);
      setIsWrong(false);
      setShowLoader(false);
      validateOTP.error.data.response.status = null;
    }
  }, [validateOTP.error?.data.response]);

  useEffect(() => {
    let intervalId;
    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      setIsTimerRunning(false);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isTimerRunning, timer]);

  const handleResendOTP = () => {
    setExpire(false);
    setIsWrong(false);
    setTimer(60);
    setIsTimerRunning(true);
    dispatch(otpRequest(email));
  };

  return (
    <div className="signupArea">
      <p className="signupAreaFirstHeading">Login Here</p>
      <p className="signupAreaSecondHeading">Verify your Email here!</p>
      <div className="otpArea">
        <OtpInput
          value={code}
          onChange={handleChange}
          containerStyle={{
            width: "75%",
            height: "100%",
          }}
          numInputs={4}
          separator={<span style={{ width: "8px" }}></span>}
          isInputNum={true}
          shouldAutoFocus={true}
          inputStyle={{
            background: "#F3F0F0",
            border: "1px solid #1C71E1",
            borderRadius: "8px",
            width: "100%",
            height: "75px",
            fontSize: "26px",
            color: "#000",
            fontWeight: "400",
            caretColor: "blue",
          }}
          focusStyle={{
            border: "1px solid #CFD3DB",
            outline: "none",
          }}
        />
      </div>
      <div className="otpRegisterBottomDiv">
        {isExpire ? (
          <div className="expireOTPMessageMobile">
            <p>OTP Expired</p>
          </div>
        ) : null}
        {isWrong ? (
          <div className="expireOTPMessageMobile">Incorrect OTP</div>
        ) : null}
      </div>
      <button
        onClick={handleClickOtp}
        type="submit"
        className="signupInputFieldButton"
        style={showLoader ? { background: "transparent" } : {}}
        disabled={showLoader}
      >
        {showLoader ? <CircularProgress /> : <>Sign In</>}
      </button>
      {isTimerRunning ? (
        <p className="otpRegisterTimer">{timer} seconds</p>
      ) : (
        <p className=" otpRegisterTimer">
          Didn't Recive Code? &nbsp;
          <span
            style={{
              color: "#0089ED",
              cursor: "pointer",
              fontSize: "1em",
            }}
            onClick={handleResendOTP}
          >
            Resend
          </span>
        </p>
      )}
    </div>
  );
}

export default VerificationLoginEmailOTPScreen;
