import * as types from "../../Config/Landing/landingActionType"

export const getPopularTestHomeScreenRequest = (data) => {
    return {
        type: types.GET_POPULAR_TEST_HOMESCREEN_REQUEST,
        data:data
    }
}

export const getPopularTestHomeScreenSuccess = (data) => {
    return {
        type: types.GET_POPULAR_TEST_HOMESCREEN_SUCCESS,
        data:data,
    }
}

export const getPopularTestHomeScreenFailure = (data) => {
    return {
        type: types.GET_POPULAR_TEST_HOMESCREEN_FAILURE,
        data:data,
    }
}