import * as types from "../../Config/Network/networkActionType"

export const connectionBetweenUsRequest = (data) => {
    return {
        type: types.CONNECTION_BETWEEN_US_REQUEST,
        data:data
    }
}

export const connectionBetweenUsSuccess = (data) => {

    return {
        type: types.CONNECTION_BETWEEN_US_SUCCESS,
        data:data,
    }
}

export const connectionBetweenUsFailure = (data) => {
    return {
        type: types.CONNECTION_BETWEEN_US_FAILURE,
        data:data,
    }
}