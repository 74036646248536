import * as types from "../../Config/Network/networkActionType"

export const clinicNetworkFormRequest = (data) => {
    return {
        type: types.CLINIC_NETWORK_FORM_REQUEST,
        data:data
    }
}

export const clinicNetworkFormSuccess = (data) => {
    return {
        type: types.CLINIC_NETWORK_FORM_SUCCESS,
        data:data,
    }
}

export const clinicNetworkFormFailure = (data) => {
    return {
        type: types.CLINIC_NETWORK_FORM_FAILURE,
        data:data,
    }
}