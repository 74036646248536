import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType"

export const DeleteSectionAnswersRequest = (data) => {
    return {
        type: types.DELETE_SECTION_ANSWERS_REQUEST,
        data:data
    }
}

export const  DeleteSectionAnswersSuccess = (data) => {
    return {
        type: types.DELETE_SECTION_ANSWERS_SUCCESS,
        data:data,
    }
}

export const  DeleteSectionAnswersFailure = (data) => {
    return {
        type: types.DELETE_SECTION_ANSWERS_FAILURE,
        data:data,
    }
}