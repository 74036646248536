import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import storage from "../../../Utils/storage";
import Logo from "../../../assets/common/logoPrimaryNavbar.png";
import ninemood from "../../../assets/mentalwelness/9MOODS.png";
import Ellipse1 from "../../../assets/mentalwelness/Ellipse1.png";
import Ellipse2 from "../../../assets/mentalwelness/Ellipse2.png";
import Ellipse3 from "../../../assets/mentalwelness/Ellipse3.png";
import handshake from "../../../assets/mentalwelness/Emoji.png";
import polygon1 from "../../../assets/mentalwelness/Polygon1.png";
import polygon2 from "../../../assets/mentalwelness/Polygon2.png";
import polygon3 from "../../../assets/mentalwelness/Polygon3.png";
import polygon4 from "../../../assets/mentalwelness/Polygon4.png";
import pic1 from "../../../assets/mentalwelness/assementpic1.png";
import pic2 from "../../../assets/mentalwelness/assementpic2.png";
import pic3 from "../../../assets/mentalwelness/assementpic3.png";
import pic4 from "../../../assets/mentalwelness/assementpic4.png";
import hands from "../../../assets/mentalwelness/clean_hands.png";
import design from "../../../assets/mentalwelness/design.png";
import man from "../../../assets/mentalwelness/man.png";
import mind from "../../../assets/mentalwelness/mind.png";
import onmoodpic from "../../../assets/mentalwelness/onmoodpic.png";
import rec1 from "../../../assets/mentalwelness/rec1.png";
import rec2 from "../../../assets/mentalwelness/rec2.png";
import rec3 from "../../../assets/mentalwelness/rec3.png";
import yogaimage from "../../../assets/mentalwelness/yogaimage.png";
import RequestCallback from "../Subscription/RequestCallback";
import "./MentalWellnessLanding.css";
import MentalWellnessMember from "./MentalWellnessMember";
import { Helmet } from "react-helmet";

function MentalWellnessLanding() {
  const [proceedButton, setProceedButton] = useState(true);
  const [showMoodNine, setShowMoodNine] = useState(false);
  const [isVideoPlaying, setVideoPlaying] = useState(false);

  const navigate = useNavigate();

  const handlePlayButtonClick = () => {
    setVideoPlaying(true);
  };

  const handleSelfCareButton = () => {
    if (storage.getStorage("token") === null) {
      navigate("/login", { state: "mentalWellness" });
    } else {
      setShowMoodNine(true);
    }
  };

  function closeMentalWellness() {
    setShowMoodNine(false);
  }

  return (
    <>
      <Helmet>
        <title>
          {" "}
          Mental Health Awareness | Improve Sleep Naturally for Better Wellness
        </title>
        <meta
          name="description"
          content="Boost mental health awareness and discover tips on how to sleep better at night naturally. Enhance your mental health and wellness with effective sleep solutions."
        />
        <meta
          name="keywords"
          content="Mental health awareness mental health and wellness not able to sleep at night how to sleep better at night naturally"
        />
      </Helmet>
      {showMoodNine && proceedButton === true ? (
        <div className="overlayMental" />
      ) : null}
      {showMoodNine ? (
        <MentalWellnessMember
          closeMentalWellness={closeMentalWellness}
          setProceedButton={setProceedButton}
          proceedButton={proceedButton}
        />
      ) : (
        ""
      )}
      {proceedButton === true && (
        <div>
          <div className="video-player-container">
            {isVideoPlaying ? (
              <video className="video-player" controls>
                <source
                  src="https://yuva-public.s3.ap-south-1.amazonaws.com/Intro+with+www.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            ) : (
              <div className="MentalWellness_OuterContainer">
                <div className="MentalWellness_grid-item">
                  <div className="MentalWellness_LearnHeading">
                    Learn & Practice
                  </div>
                  <div className="MentalWellness_SelfCareHeading">
                    Emotional <br />
                    <span className="MentalWellness_CareHeading">
                      Self-Care
                    </span>
                  </div>
                  <div className="MentalWellness_subHeading">
                    For a Happy and Health mind
                  </div>
                  <div className="MentalWellness_btn">
                    <button
                      className="MentalWellness_btn_ConsultNow"
                      onClick={handleSelfCareButton}
                    >
                      Self Care
                    </button>
                  </div>
                </div>
                <div className="MentalWellness_grid-item">
                  <div className="MentalWellness_grid-vedioImages">
                    <img
                      alt="Mental Wellness Background"
                      src={Ellipse1}
                      className="MentalWellness_vedio_Image"
                    ></img>
                    <img
                      alt="Play button"
                      src={Ellipse2}
                      className="MentalWellness_vedio_Image2"
                      onClick={handlePlayButtonClick}
                    ></img>
                    <img
                      alt="Metal wellness"
                      src={Ellipse3}
                      className="MentalWellness_vedio_Image1"
                    ></img>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* About ONMOOD9 */}
          <div className="ABoutOnMoodContainer_mentalWelness">
            <div className="ABoutOnMoodHeading_mentalWelness">
              <span className="AboutHeading_mentalWelness">About</span> ONMOOD
              <span className="AboutHeading_mentalWelness">9</span>
            </div>
            <div className="mentalWelness_aboutgridcontainer">
              <div className="mentalWelness_aboutgridone">
                <div>
                  Emotions make you happy and sad, motivate you for hard work
                  and support you in failures, they make you enjoy your success
                  and regret your losses. All your actions & behaviour are
                  governed by your emotions and Moods. There are 4 pleasant and
                  4 unpleasant Moods, triggered by different thoughts and
                  situations. You need to control and balance these 8 Moods, to
                  reach the 9th Mood of calm & Bliss. Onmood9 by combination of
                  techniques from ancient meditation and modern psychology helps
                  you manage negative & extreme emotions like Anger, anxiety,
                  depression and cultivate positive moods & habits like Joy,
                  compassion, self-acceptance, forgiveness & gratitude.
                </div>
              </div>
              <div className="mentalwellness_yoga">
                <img
                  alt="Yoga"
                  className="mentalwellness_yogaimage"
                  src={yogaimage}
                ></img>
                <img
                  alt="9 mood"
                  className="mentalwellness_nineimage"
                  src={ninemood}
                ></img>
              </div>
            </div>
          </div>

          {/* Self-Assessment */}
          <div className="Assessment_main_container">
            <div className="Self_assesment_grid">
              <div className="Self_assesment_grid_container">
                <div className="selfAssesmentGridBox">
                  <div className="Self_assesment_gridOne">
                    <div>
                      <img
                        alt="Assessment"
                        className="assessment_image1"
                        src={pic1}
                      ></img>
                    </div>
                  </div>
                </div>
                <div className="Self_assesment_subheading">Self-Assessment</div>
                <div className="Self_assesment_subheading2">
                  Emotional assessment to judge emotional status and provide
                  recommendation for improvement
                </div>
              </div>
              <div className="Self_assesment_grid_container">
                <div className="selfAssesmentGridBox">
                  <div className="Self_assesment_gridOne">
                    <div>
                      <img
                        alt="Assessment"
                        className="assessment_image1"
                        src={pic2}
                      ></img>
                    </div>
                  </div>
                </div>
                <div className="Self_assesment_subheading">Self-Learning</div>
                <div className="Self_assesment_subheading2">
                  Courses & Videos by psychologist & meditation trainers to
                  nurture and manage emotions
                </div>
              </div>
              <div className="Self_assesment_grid_container">
                <div className="selfAssesmentGridBox">
                  <div className="Self_assesment_gridOne">
                    <div>
                      <img
                        alt="Assessment"
                        className="assessment_image1"
                        src={pic3}
                      ></img>
                    </div>
                  </div>
                </div>
                <div className="Self_assesment_subheading">Self-Tracking</div>
                <div className="Self_assesment_subheading2">
                  Learn to identify and monitor your Feelings, Moods & Thought
                  through trackers and charts
                </div>
              </div>
              <div className="Self_assesment_grid_container">
                <div className="selfAssesmentGridBox">
                  <div className="Self_assesment_gridOne">
                    <div>
                      <img
                        alt="Assessment"
                        className="assessment_image1"
                        src={pic4}
                      ></img>
                    </div>
                  </div>
                </div>
                <div className="Self_assesment_subheading">Self-Healing</div>
                <div className="Self_assesment_subheading2">
                  Guided, movements & Breathing exercises, Contemplation &
                  Meditation, Sleep training & Music
                </div>
              </div>
            </div>
          </div>
          {/* phone */}
          <RequestCallback />

          {/* the 4 layers */}
          <div>
            <div className="four_layers_Mainheading">The 4-Layers</div>
            <div className="four_layers_Subheading">
              Emotions not only influence your thoughts, decisions and actions
              but also affect all 4 layers of your consciousness, that is Body,
              Energy, Mind & Wisdom. Onmood9 takes a holistic approach to
              emotional wellbeing and guides you step by step to manage the
              impact of emotions at all 4 layers.
            </div>
            <div className="mentalWellness-recdesignContainer">
              <img
                alt="Background"
                src={rec1}
                className="mentalWellness-rec1designContainer"
              ></img>
              <img
                src={rec2}
                alt="Background"
                className="mentalWellness-rec2designContainer"
              ></img>
              <img
                src={rec3}
                alt="Background"
                className="mentalWellness-rec3designContainer"
              ></img>
            </div>

            <div className="four_layers_gridcontainer">
              <div className="fourlayer_mainconatinergrid">
                <div className="four_layers_gridcontainerone">
                  <div className="images_layers_position">
                    <img
                      alt="fourlayer_polygon"
                      className="fourlayer_polygon"
                      src={polygon1}
                    ></img>
                    <img
                      alt="fourlayer_images"
                      className="fourlayer_images"
                      src={man}
                    ></img>
                  </div>
                  <div className="four_layers_bodyheading">Body</div>
                  <div className="four_layers_boxsubheading">
                    Mindful movements & Gestures to control intense emotions and
                    behaviour
                  </div>
                </div>
              </div>

              <div>
                <div className="four_layers_gridcontainertwo">
                  <div className="images_layers_position">
                    <img
                      alt="fourlayer_polygon"
                      className="fourlayer_polygon"
                      src={polygon2}
                    ></img>
                    <img
                      alt="fourlayer_images"
                      className="fourlayer_images"
                      src={design}
                    ></img>
                  </div>
                  <div className="four_layers_energyheading">Energy</div>
                  <div className="four_layers_boxsubheading">
                    Breathing exercises & Self-healing to cultivate positive
                    energy and emotions
                  </div>
                </div>
                <div className="four_layers_gridcontainertwo">
                  <div className="images_layers_position">
                    <img
                      alt="fourlayer_polygon"
                      className="fourlayer_polygon"
                      src={polygon3}
                    ></img>
                    <img
                      alt="fourlayer_imagestwo"
                      className="fourlayer_imagestwo"
                      src={mind}
                    ></img>
                  </div>
                  <div className="four_layers_mindheading">Mind</div>
                  <div className="four_layers_boxsubheading">
                    Contemplation & Self-help Cognitive techniques to manage
                    negative thoughts
                  </div>
                </div>
              </div>
              <div className="fourlayer_mainconatinergrid">
                <div className="four_layers_gridcontainerone">
                  <div className="images_layers_position">
                    <img
                      alt="fourlayer_polygon"
                      className="fourlayer_polygon"
                      src={polygon4}
                    ></img>
                    <img
                      alt="fourlayer_imagestwo"
                      className="fourlayer_imagestwo"
                      src={hands}
                    ></img>
                  </div>
                  <div className="four_layers_wisdomheading">Wisdom</div>
                  <div className="four_layers_boxsubheading">
                    Guided Meditation to manage Moods & reprogram the
                    subconscious
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* logi */}
          <div className="MentalAssessment_logo_box ">
            <div className="MentalAssessment_image_container">
              <img
                alt="MentalAssessment_image1"
                className="MentalAssessment_image1"
                src={Logo}
              ></img>
              <img
                alt="MentalAssessment_image2"
                className="MentalAssessment_image2"
                src={handshake}
              ></img>
              <img
                alt="MentalAssessment_image3"
                className="MentalAssessment_image3"
                src={onmoodpic}
              ></img>
            </div>
            <div className="MentalAssessment_logo_heading">
              Yuva Health in Partner with Onmood9...!
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MentalWellnessLanding;
