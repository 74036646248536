import * as types from "../../Config/Packages/PackageLabTestActionType";

export const diagnosticTestRequest = (data,page) => {
  return {
    type: types.DIAGNOSTIC_TEST_SELECT_REQUEST,
    data:data,
    page:page
  };
};

export const diagnosticTestSuccess = (data) => {
  return {
    type: types.DIAGNOSTIC_TEST_SELECT_SUCCESS,
    data: data,
  };
};

export const diagnosticTestFailure = (data) => {
  return {
    type: types.DIAGNOSTIC_TEST_SELECT_FAILURE,
    data: data,
  };
};
