import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { getPlanRequestCall } from "../../../redux/Action/Plan/RequestCall";
import "./requestCallback.css";

function RequestCallback() {
  const dispatch = useDispatch();

  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState(false);

  const PlanRequestCall = useSelector((state) => state.plan.getPlanRequestCall);

  const HandleSubmit = (values) => {
    if (mobileNumber.length === 0) {
      setError(true);
    } else {
      dispatch(getPlanRequestCall(mobileNumber));
    }
  };

  const handleNumber = (event) => {
    setMobileNumber(event.target.value);
  };

  useEffect(() => {
    if (PlanRequestCall?.data?.data?.status === 201) {
      Swal.fire({
        title: "Thanks for contacting us, soon we will be contacting you!",
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    }
    if (PlanRequestCall?.error?.data?.response?.status === 400) {
      Swal.fire({
        title: "Invalid Mobile Number!",
        icon: "error",
      }).then(() => {});
    }
  }, [PlanRequestCall]);

  return (
    <div className="GetExpertGuidance">
      <div className="GetExpertGuidanceHeading">
        Get expert guidance from a Health Advisor - Request a call back
      </div>
      <div className="GetExpertGuidanceHeadingCallGrid">
        <input
          placeholder="Enter Mobile Number"
          type="number"
          value={mobileNumber}
          onChange={handleNumber}
          className="GetExpertGuidanceHeadingCallFirst"
        ></input>

        <div
          className="GetExpertGuidanceHeadingCallSecond"
          onClick={HandleSubmit}
        >
          Free Phone Consultation
        </div>
        {error && mobileNumber.length <= 0 ? (
          <label className="Subscribepagevalidation">
            Please Enter the Phone Number*
          </label>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default RequestCallback;
