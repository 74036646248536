import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/EcomUser/EcomUserActionType";
import * as actions from "../../Action/EcomUser/viewProductByCategoryIdAction";
import NetworkService from "../../../network/Network/EcomUser/EcomUserNetwork";

export function* viewProductByCategoryIdSaga(action) {
  try {
    let response = yield call(NetworkService.viewProductByCategoryId, action);
    yield put(actions.viewProductByCategoryIdSuccess(response));
  } catch (err) {
    yield put(actions.viewProductByCategoryIdFailure(err));
  }
}

export function* watchViewProductByCategoryIdSaga() {
  yield takeLatest(types.VIEW_PRODUCT_BY_CATEGORY_ID_REQUEST, viewProductByCategoryIdSaga);
}