import { PageKeys } from "../../../Utils/testPackageFlag";
import * as types from "../../Config/Packages/PackageLabTestActionType";

const initialState = {
    data: {
        [PageKeys.ONLY_TEST]: null,
        [PageKeys.ONLY_PACKAGE]: null,
        [PageKeys.TEST_AND_PACKAGE]: null,
    },
    isLoading: false,
    error: null
}


export const getTestPackageListingReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.GET_TEST_PACKAGE_LISTING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: {
                    ...state.data,
                    [action.category]: action
                }
            }
        case types.GET_TEST_PACKAGE_LISTING_FAILURE:
            return {
                ...state,
                error: action
            }
        case types.GET_TEST_PACKAGE_LISTING_REQUEST:
            return {
                ...state,
                isLoading: true
            }

        default:
            return state
    }
}