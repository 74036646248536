import React from "react";
import BrokenBulb from "../../../../assets/common/broken-light-bulb.png";
import Title from "../../../Atoms/Title/Title";
import "./LinkExpired.css";

function LinkExpired() {
  return (
    <>
    <Title title={"Yuva | Link Expired"} />
      <div className="linkExpiredmainWrapper">
        <p className="linkExpiredHeader">Link Broken</p>
        <img alt="Broken Bub" src={BrokenBulb} />
      </div>
     
    </>
  );
}
export default LinkExpired;
