export const CREATE_CART_REQUEST = "CREATE_CART_REQUEST"
export const CREATE_CART_SUCCESS = "CREATE_CART_SUCCESS"
export const CREATE_CART_FAILURE = "CREATE_CART_FAILURE"
export const CREATE_CART_CLEAR = "CREATE_CART_CLEAR"

export const GET_CART_REQUEST = "GET_CART_REQUEST"
export const GET_CART_SUCCESS = "GET_CART_SUCCESS"
export const GET_CART_FAILURE = "GET_CART_FAILURE"

export const REMOVE_CART_ITEM_REQUEST = "REMOVE_CART_ITEM_REQUEST"
export const REMOVE_CART_ITEM_SUCCESS = "REMOVE_CART_ITEM_SUCCESS"
export const REMOVE_CART_ITEM_FAILURE = "REMOVE_CART_ITEM_FAILURE"

export const CART_DISCOUNT_REQUEST = "CART_DISCOUNT_REQUEST"
export const CART_DISCOUNT_SUCCESS = "CART_DISCOUNT_SUCCESS"
export const CART_DISCOUNT_FAILURE = "CART_DISCOUNT_FAILURE"

export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST"
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS"
export const CREATE_ORDER_FAILURE = "CREATE_ORDER_FAILURE"

export const PAYMENT_GATEWAY_REDIRECT_REQUEST = "PAYMENT_GATEWAY_REDIRECT_REQUEST"
export const PAYMENT_GATEWAY_REDIRECT_SUCCESS = "PAYMENT_GATEWAY_REDIRECT_SUCCESS"
export const PAYMENT_GATEWAY_REDIRECT_FAILURE = "PAYMENT_GATEWAY_REDIRECT_FAILURE"

export const PAYMENT_GATEWAY_STATUS_REQUEST = "PAYMENT_GATEWAY_STATUS_REQUEST"
export const PAYMENT_GATEWAY_STATUS_SUCCESS = "PAYMENT_GATEWAY_STATUS_SUCCESS"
export const PAYMENT_GATEWAY_STATUS_FAILURE = "PAYMENT_GATEWAY_STATUS_FAILURE"

export const REDEEM_COUPON_REQUEST = "REDEEM_COUPON_REQUEST"
export const REDEEM_COUPON_SUCCESS = "REDEEM_COUPON_SUCCESS"
export const REDEEM_COUPON_FAILURE = "REDEEM_COUPON_FAILURE"

export const GET_ALL_COUPON_REQUEST ="GET_ALL_COUPON_REQUEST"
export const GET_ALL_COUPON_SUCCESS="GET_ALL_COUPON_SUCCESS"
export const GET_ALL_COUPON_FAILURE="GET_ALL_COUPON_FAILURE"