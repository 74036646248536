import CryptoJS from "crypto-js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import storage from "../../../Utils/storage";
import { getCorporateTokenInfoRequest } from "../../../redux/Action/CorporateSignUp/getCorporateInfoAction";
import Title from "../../Atoms/Title/Title";
import CorporateSignUpInfo from "../../Organisms/CorporateSignUp/CorporateSignUpInfo";
import CorporateSignUpOtp from "../../Organisms/CorporateSignUp/CorporateSignUpOtp";
import CorporateSignUpScreen from "../../Organisms/CorporateSignUp/CorporateSignUpScreen";
import signupImage from "../../../assets/Register/signupImage.png";
import Logo from "../../../assets/common/logoPrimaryFooter.png";
import logo from "../../../assets/common/logoPrimaryNavbar.png";

function CorporateSignUp() {
  const [isMobile, setIsMobile] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [empEmail, setEmpEmail] = useState(null);
  const [empInfo, setEmpInfo] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const id = new URLSearchParams(search).get("id");
  const secretKey = process.env.REACT_APP_SECURITY_KEY;

 

  useEffect(() => {
    if (window.outerWidth <= 600) {
      setIsMobile(true);
    }
  }, [window.outerWidth]);

  useEffect(() => {
    dispatch(getCorporateTokenInfoRequest(token, id));
  }, []);

  const generateEmailOtp = useSelector((state) => state.otp.generateEmailOtp);
  const checkEmailSelector = useSelector(
    (state) => state.checkEmail.checkEmail
  );
  const responseRegister = useSelector((state) => state.user.register);
  const corporateTokenInfo = useSelector(
    (state) => state.corporateSignUp.corporateTokenInfo
  );
  const loginResponse = useSelector((state) => state.user.login);

  useEffect(() => {
    if (corporateTokenInfo.error !== null) {
      navigate("/linkExpired");
    }
  }, [corporateTokenInfo]);

  useEffect(() => {
    if (checkEmailSelector.error?.data.response.status === 302) {
      setIsLogin(true);
    } else if (checkEmailSelector.error?.data.response.status === 400) {
      setIsLogin(true);
    }
  }, [checkEmailSelector.error?.data.response.status]);

  useEffect(() => {
    if (
      responseRegister.data?.data?.data?.jwt !== null &&
      responseRegister?.data?.data?.data.response !== null &&
      responseRegister.data !== null
    ) {
      const encryptedData = CryptoJS.AES.encrypt(
        "EMPLOYEE",
        secretKey
      ).toString();
      storage.setStorage("authorization", encryptedData);
      storage.setStorage("token", responseRegister.data?.data?.data?.data?.jwt);
      storage.setStorage("name", responseRegister.data?.data.data?.data?.name);
      storage.setStorage("id", responseRegister.data?.data.data.data?.id);
      storage.setStorage(
        "profileUpdated",
        responseRegister.data?.data.data.data?.profileUpdated
      );
      window.location.href = "/";
    }
  }, [responseRegister]);

  useEffect(() => {
    if (
      loginResponse?.data?.data?.status === 200 &&
      loginResponse?.data?.data.data.data !== null
    ) {
      const encryptedData = CryptoJS.AES.encrypt(
        "EMPLOYEE",
        secretKey
      ).toString();
      storage.setStorage("authorization", encryptedData);
      storage.setStorage("token", loginResponse.data?.data?.data?.data.jwt);
      storage.setStorage("name", loginResponse.data?.data.data?.data.name);
      storage.setStorage(
        "profileUpdated",
        loginResponse.data?.data.data?.data.profileUpdated
      );
      storage.setStorage("id", loginResponse.data?.data.data.data.id);
      window.location.href = "/";
    }
    if (
      loginResponse.error !== null &&
      loginResponse.error.data.response.data.errorMessage ===
        "User is already assigned to this program"
    ) {
      Swal.fire({
        title: loginResponse?.error?.data?.response?.data.errorMessage,
        icon: "info",
      }).then(() => {
        window.location.href = "/";
      });
    } else if (loginResponse.error !== null) {
      Swal.fire({
        title: loginResponse?.error?.data?.response?.data.errorMessage,
        icon: "error",
      });
    }
  }, [loginResponse]);

  return (
    <div>
      <Title title="Yuva | Sign In"></Title>
      <div>
      
        <div className="signupMain">
          <div className="signupMainLeft">
            <img src={signupImage} className="signupLeftBackground" alt="blue screen"/>
            <img src={Logo} className="LogoSignUp" alt="yuva Logo"/>
          </div>
          
          <div className="signupMainRight">
            {generateEmailOtp.data?.data.status === 200 ? (
              <CorporateSignUpOtp
                isMobile={isMobile}
                empInfo={empInfo}
                token={token}
              />
            ) : checkEmailSelector.error?.data.response.status === 404 ? (
              <CorporateSignUpInfo
                isMobile={isMobile}
                empEmail={empEmail}
                setEmpInfo={setEmpInfo}
              />
            ) : (
              <CorporateSignUpScreen
                isMobile={isMobile}
                token={token}
                corporateId={id}
                setEmpEmail={setEmpEmail}
                isLogin={isLogin}
              />
            )}
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default CorporateSignUp;
