import { combineReducers } from "redux";
import { cartDiscountReducer } from "./cartDiscountReducer";
import { removeCartItemReducer } from './removeCartItemReducer';
import { createOrderReducer } from './createOrderReducer';
import { paymentGatewayRedirectReducer } from './paymentGatewayRedirectReducer';
import { paymentGatewayStatusReducer } from './paymentGatewayStatusReducer';
import { redeemCouponReducer } from "./redeemCouponReducer";
import { getAllCouponReducer } from "./getAllCouponReducer";
import { createCartReducer } from "./createCartReducer";
import { getCartReducer } from "./getCartReducer";

export default combineReducers({
    createCart: createCartReducer,
    getCart: getCartReducer,
    removeCartItem: removeCartItemReducer,
    cartDiscount: cartDiscountReducer,
    createOrder: createOrderReducer,
    paymentGatewayRedirect: paymentGatewayRedirectReducer,
    paymentGatewayStatus: paymentGatewayStatusReducer,
    redeemCoupon: redeemCouponReducer,
    getAllCoupon: getAllCouponReducer,
})