import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Network/networkActionType";
import * as actions from "../../Action/Network/viewAllNetworkTypeAction";
import NetworkService from "../../../network/Network/Network/NetworkPageNetwork";

export function* viewAllNetworkTypeSaga() {
  try {
    let response = yield call(NetworkService.viewAllNetworkType);
    yield put(actions.viewAllNetworkTypeSuccess(response));
  } catch (err) {
    yield put(actions.viewAllNetworkTypeFailure(err));
  }
}

export function* watchViewAllNetworkTypeSaga() {
  yield takeLatest(types.VIEW_ALL_NETWORK_TYPE_REQUEST, viewAllNetworkTypeSaga);
}