import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Plan/PlanActionType";
import * as actions from "../../Action/Plan/GetPlanFullDetailsAction";
import NetworkService from "../../../network/Network/Plan/GetPLanDetailNetwork";

export function* getPlanFullDetailsSaga(action) {
  try {
    let response = yield call(NetworkService.getPlanFullDetails, action);
    yield put(actions.getPlanFullDetailsSuccess(response));
  } catch (err) {
    yield put(actions.getPlanFullDetailsFailure(err));
  }
}

export function* watchgetPlanFullDetailsSaga() {
  yield takeLatest(types.GET_PLAN_FULL_DETAILS_REQUEST, getPlanFullDetailsSaga);
}
