export const api = {
  check: "/check",
  joinNetwork: "/network",
  newsletterSignup: '/',
  getPopularPlan: '/plan',
  getPopularPackage: '/package',
  getPopularTest: '/test',
  getPopularHomeScreenPlan:"/plan/home-screen",
  getPopularPackageHomeScreen:"/package/home-screen?limit=4",
  getPopularTestHomeScreen:"/test/home-screen?limit=4",
}
