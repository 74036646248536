import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/ERM/MedicalRecordListingConfig"
import * as actions from "../../Action/ERM/downloadErmReportAction"
import NetworkService from "../../../network/Network/ERM/medicalRecordDropdownNetwork"

export function* downloadErmReportSaga(action) {
  try {
      //
    let response = yield call(NetworkService.downloadErmReport, action);
    yield put(actions.downloadErmReportSuccess(response));
  } catch (err) {
    yield put(actions.downloadErmReportFailure(err));
  }
}

export function* watchdownloadErmReportSaga() {
  yield takeLatest(types.DOWNLOAD_ERM_REPORT_REQUEST, downloadErmReportSaga);
}