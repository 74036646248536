import React from "react";

function ProductDescription({ title, description }) {
  return (
    <div>
      <div
        style={{ marginTop: "1.5em", marginLeft: "2em", marginBottom: "1.5em" }}
      >
        <h3 style={{ color: "#1C71E1", fontSize: "2.25em" }}>{title}</h3>
        <div
          className="productDescriptionBody"
          style={{ marginLeft: "1em", marginRight: "1em" }}
        >
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    </div>
  );
}

export default ProductDescription;
