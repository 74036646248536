import { all } from "redux-saga/effects";
import { watchViewAllProductCategorySaga } from "./viewAllProductCategorySaga";
import { watchViewProductByCategoryIdSaga } from "./viewProductByCategoryIdSaga";
import { watchViewProductByIdSaga } from "./viewProductByIdSaga";
import { watchViewTopCategoriesSaga } from "./viewTopCategoriesSaga";
import { watchViewAllProductsSaga } from "./viewAllProductSaga";
import { watchGetBrandDropdownSaga } from "./getBrandDropdownSaga";
import { watchGetCategoryDropdownSaga } from "./getCategoryDropdownSaga";
import { watchGetSubCategoryDropdownSaga } from "./getSubCategoryDropdownSaga";
import { watchViewHomeScreenProductSaga } from "./viewHomeScreenProductSaga";

function* rootEcomUserSaga() {
    yield all([
        watchViewAllProductCategorySaga(),
        watchViewProductByCategoryIdSaga(),
        watchViewProductByIdSaga(),
        watchViewTopCategoriesSaga(),
        watchViewAllProductsSaga(),
        watchGetBrandDropdownSaga(),
        watchGetCategoryDropdownSaga(),
        watchGetSubCategoryDropdownSaga(),
        watchViewHomeScreenProductSaga()
    ])
}

export default rootEcomUserSaga;