import * as types from "../../../Config/OTP/OTPValidation/otpValidationActionType"

export const otpValidationRequest = (data) => {
    return {
        type: types.OTPVALIDATION_REQUEST,
        data:data
    }
}

export const otpValidationRequestSuccess = (data) => {
    return {
        type: types.OTPVALIDATION_SUCCESS,
        data:data,
    }
}

export const otpValidationRequestFailure = (data) => {
    return {
        type: types.OTPVALIDATION_FAILURE,
        data:data,
    }
}