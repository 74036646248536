import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Landing/landingActionType";
import * as actions from "../../Action/Landing/getPopularTestAction";
import NetworkService from "../../../network/Network/Landing/landingNetwork";

export function* getPopularTestSaga(action) {

  try {
    let response = yield call(NetworkService.getPopularTest, action);
    yield put(actions.getPopularTestSuccess(response));
  } catch (err) {
    yield put(actions.getPopularTestFailure(err));
  }
}

export function* watchGetPopularTestSaga() {
  yield takeLatest(types.GET_POPULAR_TEST_REQUEST, getPopularTestSaga);
}