import * as types from "../../Config/Banner/BannerActionType"

export const getUserBannerRequest = (position, screenType, category) => {
    return {
        type: types.GET_USER_BANNER_REQUEST,
        position,
        screenType,
        category
    }
}

export const getUserBannerSuccess = (data, category) => {
    return {
        type: types.GET_USER_BANNER_SUCCESS,
        data,
        category
    }
}

export const getUserBannerFailure = (data) => {
    return {
        type: types.GET_USER_BANNER_FAILURE,
        data
    }
}