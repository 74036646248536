import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AddCart } from "../../../Utils/addCart";
import { getTestMetaData } from "../../../Utils/testMetaData";
import { createCartClear } from "../../../redux/Action/Cart/CreateCartAction";
import { packageGetTestRequest } from "../../../redux/Action/Packages/PackageGetTestAction";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import Title from "../../Atoms/Title/Title";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import AboutPackage from "../../Organisms/AboutTestAndPackage/AboutPackage";
import SubscriptionHero from "../../Organisms/Hero/SubscriptionHero";

function TestDetails() {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const [itemList, setItemList] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");

  const dispatch = useDispatch();

  const [selectedCollapse, setSelectedCollapse] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const packageGetTest = useSelector((state) => state.package.packageGetTest);
  const createCart = useSelector((state) => state.cart.createCart);

  useEffect(() => {
    dispatch(packageGetTestRequest(id));
    
    const { title, description, keywords } = getTestMetaData(id);
    setTitle(title);
    setDescription(description);
    setKeywords(keywords);
  }, [dispatch, id]);

  useEffect(() => {
    if (createCart.data?.data.status === 200 && itemList) {
      localStorage.setItem("itemDtoList", JSON.stringify(itemList));
      window.dispatchEvent(new Event("storage"));
    }
    return () => {
      dispatch(createCartClear());
    };
  }, [createCart.data, createCart.error, itemList, dispatch]);

  const handleClick = (index) => {
    setSelectedCollapse(index === selectedCollapse ? null : index);
    setIsOpen(!isOpen);
  };

  const handleAddCart = async () => {
    const data = {
      name: packageGetTest.data.data.data.data.name,
      id: packageGetTest.data.data.data.data.id,
      test: true,
    };
    try {
      setIsSubmitting(true);
      setItemList(await AddCart(data, dispatch, setIsSubmitting, 1));
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  if (packageGetTest?.isLoading) {
    return <YuvaLoader />;
  }

  return (
    <div className="testDetails">
      <ScrollToTop />
      {title ? (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
        </Helmet>
      ) : (
        <Title title={`Yuva | ${packageGetTest?.data?.data?.data.data.name}`} />
      )}
      <SubscriptionHero
        packageName={packageGetTest?.data?.data?.data.data.name}
        handleClick={handleAddCart}
        isSubmitting={isSubmitting}
        discountedPackageCost={
          packageGetTest?.data?.data?.data.data.discountCost
        }
        packageCost={packageGetTest?.data?.data?.data.data.cost}
        id={id}
        isTest={true}
      />
      <AboutPackage
        data={packageGetTest?.data?.data?.data.data}
        handleClick={handleClick}
        selectedCollapse={selectedCollapse}
        mapData={packageGetTest?.data?.data?.data.data.parameters}
      />
    </div>
  );
}

export default TestDetails;
