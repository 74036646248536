import CryptoJS from "crypto-js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import storage from "../../../Utils/storage";
import Coupon from "../../../assets/coupon.png";
import { createOrderRequest } from "../../../redux/Action/Cart/CreateOrderAction";
import { refreshTokenRequest } from "../../../redux/Action/RefreshToken/refreshTokenAction";
import { CircularProgress } from "@mui/material";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { paymentGatewayRedirectRequest } from "../../../redux/Action/Cart/PaymentGatewayRedirectRequest";

function CartOrderSummaryImmutable({ state }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirmPaymentFlag, setConfirmPaymentFlag] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [paymentCompleted, setPaymentCompleted] = useState(false);

  const paymentURL = process.env.REACT_APP_CCAVENUE_URL;
  const paymentAccessCode = process.env.REACT_APP_ACCESS_CODE;
  const secretKey = process.env.REACT_APP_SECURITY_KEY;

  const refreshToken = useSelector((state) => state.refreshToken.refreshToken);
  const cart = location.state.cart;
  const createOrder = useSelector((state) => state.cart.createOrder);

  const getCart = useSelector((state) => state.cart.getCart);

  const handlePayment = () => {
    if (state.address === undefined) {
      Swal.fire({
        title: "Please Select Address",
        icon: "error",
      });
    } else if (state.value.name === undefined && state.haveProduct === false) {
      Swal.fire({
        title: "Please Select Any Member",
        icon: "error",
      });
    } else if (state.timeSlot === undefined && state.haveProduct === false) {
      Swal.fire({
        title: "Please Select Date And Time",
        icon: "error",
      });
    } else if (state.timeSlot < new Date().getTime() + 2 * 60 * 60 * 1000) {
      swal({
        title: "Selected Time and Date cannot be less than 2 hours",
        icon: "error",
      });
    } else {
      const fcmToken = storage.getStorage("fcmToken");
      const token = storage.getStorage("sessionToken");
      const secretKey = process.env.REACT_APP_SECURITY_KEY;
      const decryptedBytes = CryptoJS.AES.decrypt(token, secretKey);
      const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
      setPaymentCompleted(true);
      dispatch(refreshTokenRequest(decryptedData, fcmToken));
    }
  };
  useEffect(() => {
    if (refreshToken.data !== null && paymentCompleted === true) {
      const encryptedToken = CryptoJS.AES.encrypt(
        refreshToken.data?.data.data.data.refreshToken,
        secretKey
      ).toString();
      storage.setStorage("token", refreshToken.data?.data.data.data.jwt);
      storage.setStorage("sessionToken", encryptedToken);
      window.dispatchEvent(new Event("refresh"));
      var finalState;
      if (getCart?.data?.data?.data?.data?.totalCost === 0) {
        finalState = { ...state, cod: "false" };
      } else {
        finalState = { ...state, cod: state.cod.toString() };
      }
      dispatch(createOrderRequest(finalState));
    } else if (refreshToken.error !== null) {
      navigate("/login");
    }
  }, [refreshToken]);

  useEffect(() => {
    if (
      createOrder.data?.data.status === 200 &&
      createOrder.data?.data.data.data.amountZero === false &&
      paymentCompleted === true
    ) {
      let sd = {
        id: createOrder.data?.data.data.data.id,
        plan: false,
      };
      if (state.cod.toString() === "false") {
        dispatch(paymentGatewayRedirectRequest(sd));
      } else {
        setConfirmPaymentFlag(true);
      }
    }
    if (
      createOrder.data?.data.status === 200 &&
      createOrder.data?.data.data.data.amountZero === true &&
      paymentCompleted === true
    ) {
      window.location.href = "./ConfirmedZeroPayment";
    }
  }, [createOrder]);

  const paymentGatewayRedirect = useSelector(
    (state) => state.cart.paymentGatewayRedirect
  );

  useEffect(() => {
    if (
      paymentGatewayRedirect.data?.data.status === 200 &&
      paymentCompleted === true
    ) {
      setPaymentCompleted(false);
      setShowLoader(false);
      window.location.href = `${paymentURL}?command=initiateTransaction&encRequest=${paymentGatewayRedirect.data?.data.data.data.encRequest}&access_code=${paymentAccessCode}`;
    }
  }, [paymentGatewayRedirect]);

  useEffect(() => {
    if (paymentGatewayRedirect.isLoading) {
      setShowLoader(true);
    }
  }, [paymentGatewayRedirect]);

  if (confirmPaymentFlag) {
    navigate("/confirmPayment", {
      state: { confirmPaymentFlag: confirmPaymentFlag },
    });
  }

  return (
    <div className="CartMainSecondContent">
      <h5 className="CartMainSecondContentHeaderNew">Order Summary</h5>

      <div className="CartMainSecondContentText">
        <div className="secondCartText">Price</div>
        <div className="PriceCartText">
          ₹{" "}
          {cart?.itemDtoList.length > 0
            ? cart?.cartPriceResponseDto.totalCost
            : 0}
          /-
        </div>
      </div>
      {cart?.cartPriceResponseDto.discountBeforeCoupon === 0 ? (
        ""
      ) : (
        <div
          className="CartMainSecondContentText"
          style={{ marginTop: "1.1em" }}
        >
          <div className="secondCartText">Discount</div>
          <div className="PriceCartText2">
            {`₹ ${cart?.cartPriceResponseDto.discountBeforeCoupon}/-`}
          </div>
        </div>
      )}

      <div className="CartMainSecondContentText" style={{ marginTop: "1.1em" }}>
        <div className="secondCartText">Order Amount</div>
        <div className="PriceCartText">
          ₹ {cart?.cartPriceResponseDto.costAfterDiscount}/-
        </div>
      </div>
      <div className="CartMainSecondContentText">
        <div className="secondCartTextgst">Inclusive GST*</div>
      </div>

      <div className="CartMainSecondContentText" style={{ marginTop: "1.1em" }}>
        <div className="secondCartText">Collection Charges</div>
        <div className="PriceCartText">
          ₹ {cart?.cartPriceResponseDto.processingCharge}/-
        </div>
      </div>
      <div className="CartMainSecondContentText">
        <div className="secondCartTextsmallProcessingCharge">
          Applicable for Diagnostic Tests*
        </div>
      </div>
      {getCart?.data?.data?.data?.data.cartPriceResponseDto.couponCode !==
      null ? (
        <div className="finalPaymentPlanNewDescriptionCartPage">
          <div className="finalPaymentPlanNewAppliedCoupon">
            <img src={Coupon} alt="CouponIcon" />

            <p>
              {getCart?.data?.data?.data?.data.cartPriceResponseDto.couponCode}
            </p>
            {/* <img src={cross} onClick={RemoveCoupon} alt="crossIcon" style={{cursor:"pointer"}}/> */}
          </div>
          <h1 className="finalPaymentPlanNewDescriptionFixedText4">
            - ₹{cart?.cartPriceResponseDto.discountForCoupon}/-
          </h1>
        </div>
      ) : (
        <></>
      )}
      <div className="CartMainSecondContentText" style={{ marginTop: "1.1em" }}>
        <div className="secondCartText">Total</div>
        <div className="PriceCartText">
          ₹ {cart?.cartPriceResponseDto.amountToBePaid}/-
        </div>
      </div>

      <button
        className="buttonOfSecondCart"
        onClick={handlePayment}
        disabled={showLoader === true ? true : false}
        type="button"
      >
        {showLoader === true ? (
          <CircularProgress color="warning" style={{ color: "#fff" }} />
        ) : (
          "Place Order"
        )}
      </button>
    </div>
  );
}

export default CartOrderSummaryImmutable;
