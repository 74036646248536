import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Landing/landingActionType";
import * as actions from "../../Action/Landing/getPopularPackageAction";
import NetworkService from "../../../network/Network/Landing/landingNetwork";

export function* getPopularPackageSaga(action) {
  try {
    let response = yield call(NetworkService.getPopularPackage, action);
    yield put(actions.getPopularPackageSuccess(response));
  } catch (err) {
    yield put(actions.getPopularPackageFailure(err));
  }
}

export function* watchGetPopularPackageSaga() {
  yield takeLatest(types.GET_POPULAR_PACKAGE_REQUEST, getPopularPackageSaga);
}