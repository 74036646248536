import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/ERM/MedicalRecordListingConfig";
import * as actions from "../../Action/ERM/medicalRecordFormRequestAction";
import NetworkService from "../../../network/Network/ERM/medicalRecordDropdownNetwork"

export function* medicalRecordFormSaga(action) {
  try {
    let response = yield call(NetworkService.medicalRecordForm, action);
    yield put(actions.medicalRecordFormSuccess(response));
  } catch (err) {
    yield put(actions.medicalRecordFormFailure(err));
  }
}

export function* watchmedicalRecordFormSaga() {
  yield takeLatest(types.MEDICAL_RECORD_FORM_REQUEST, medicalRecordFormSaga);
}