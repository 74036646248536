import React, { useState } from "react";
import "./FrequentlyAskedQuestion.css";
import { useNavigate } from "react-router-dom";
import FrequentlyQuestion from "../../../assets/SubcriptionPageAssets/FrequentlyQuestion.png";
function FrequentlyAskedQuestion(props) {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  function HandleNeedHelpBtn(e) {
    navigate(`/contact-us`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div
      style={{
        border: "0.5px solid #9C9C9C",
        margin: "2em 4vw",
        borderRadius: "12px",
      }}
    >
      <div className="GetExpertGuidanceHeadingFAQ">FAQ</div>
      <div className="FrequentlyAskedQuestionContainer">
        <div className="FrequentlyAskedQuestionGrid">
          <div className="FrequentlyAskedQuestionDataContent">
            {props.data.map((item, i) => (
              <div className="FrequentlyAskedQuestionGriditem">
                <div
                  className="FrequentlyAskedQuestionitemtitle"
                  onClick={() => toggle(i)}
                >
                  <div className="FrequentlyAskedQuestionQuestionW">
                    {item.question}
                  </div>
                  <span>
                    {selected === i ? (
                      <div className="PlusSymbol">-</div>
                    ) : (
                      <div className="PlusSymbol">+</div>
                    )}
                  </span>
                </div>
                <div
                  className={
                    selected === i
                      ? "FrequentlyAskedQuestionitemcontent show"
                      : "FrequentlyAskedQuestionitemcontent"
                  }
                >
                  <div className="FrequentlyAskedQuestionAnswerW">
                    {item.answer}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="FrequentlyQuestionIMageDiv">
            <img className="FrequentlyQuestionIMage" src={FrequentlyQuestion} alt="family laughing"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrequentlyAskedQuestion;
