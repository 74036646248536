import * as types from "../../Config/Packages/PackageLabTestActionType";

export const packageSelectRequest = (data) => {
  return {
    type: types.PACKAGE_SELECT_REQUEST,
    data:data,
  };
};

export const packageSelectSuccess = (data) => {
  return {
    type: types.PACKAGE_SELECT_SUCCESS,
    data: data,
  };
};

export const packageSelectFailure = (data) => {
  return {
    type: types.PACKAGE_SELECT_FAILURE,
    data: data,
  };
};
