import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Landing/landingActionType";
import * as actions from "../../Action/Landing/newsletterSignupAction";
import NetworkService from "../../../network/Network/Landing/landingNetwork";

export function* newsletterSignupSaga(action) {
  try {
    let response = yield call(NetworkService.newsletterSignup, action);
    yield put(actions.newsletterSignupSuccess(response));
  } catch (err) {
    yield put(actions.newsletterSignupFailure(err));
  }
}

export function* watchNewsletterSignupSaga() {
  yield takeLatest(types.NEWSLETTER_SIGNUP_REQUEST, newsletterSignupSaga);
}
