import * as types from "../../Config/User/userActionType";

export const UpdateProfileRequest = (
  data,
  city,
  NumberCode,
  emailCode,
  valEmail,
  valNumber
) => {
  return {
    type: types.UPDATE_PROFILE_REQUEST,
    data: data,
    city: city,
    NumberCode: NumberCode,
    emailCode: emailCode,
    valEmail: valEmail,
    valNumber: valNumber,
    
  };
};

export const UpdateProfileSuccess = (data) => {
  return {
    type: types.UPDATE_PROFILE_SUCCESS,
    data: data,
  };
};

export const UpdateProfileFailure = (data) => {
  return {
    type: types.UPDATE_PROFILE_FAILURE,
    data: data,
  };
};
