import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";
import * as actions from "../../Action/HealthRiskAssessment/DeleteSectionAnswersAction";
import NetworkService from "../../../network/Network/HealthRiskAssessment/HealthRiskAssessmentNetwork";

export function* DeleteSectionAnswersSaga(action) {
  try {
    let response = yield call(NetworkService.DeleteSectionAnswers, action);
    yield put(actions.DeleteSectionAnswersSuccess(response));
  } catch (err) {
    yield put(actions.DeleteSectionAnswersFailure(err));
  }
}

export function* WatchDeleteSectionAnswersSaga() {
  yield takeLatest(types.DELETE_SECTION_ANSWERS_REQUEST, DeleteSectionAnswersSaga);
}
