import { api } from "../../../helpers/TellAboutYourself/TellAboutYourselfPage";
import storage from "../../../Utils/storage";
import axios from "axios";

class NetworkService {
  TellAboutYourselfNet(action) {
    const endPoint = `${api.TellAboutYourself}`;
    let token = storage.getStorage("token");
    return axios.put(
      `api/v1/yuva${endPoint}`,
      {
        dob: action.data.date,
        gender: action.data.Gender,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
}

export default new NetworkService();
