import * as types from "../../Config/HealthRiskAssessment/HealthRiskAssessmentActionType";

export const GetRelationsRequest = (data) => {
  return {
    type: types.GET_RELATIONS_REQUEST,
    data:data,
  };
};


export const GetRelationsSuccess = (data) => {
  return {
    type: types.GET_RELATIONS_SUCCESS,
    data: data,
  };
};

export const GetRelationsFailure = (data) => {
  return {
    type: types.GET_RELATIONS_FAILURE,
    data: data,
  };
};
