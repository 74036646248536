import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import backArrow from "../../../assets/hra/backArrow.png";
import hra9 from "../../../assets/hra/hra9.png";
import { GetQuestionSection8Request } from "../../../redux/Action/HealthRiskAssessment/GetQuestionSection8Action";
import { GetSection8AnswersRequest } from "../../../redux/Action/HealthRiskAssessment/GetSectionAnswers/GetSection8AnswersAction";
import { Section8AnswersSuccess } from "../../../redux/Action/HealthRiskAssessment/HRAAnswers/Section8AnswersAction";
import { SaveSectionAnswersRequest } from "../../../redux/Action/HealthRiskAssessment/SaveSectionAnswersAction";
import storage from "../../../Utils/storage";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";
import SingleLogin from "../Login/SingleLogin";
import { dropDisabled, pickeryesno } from "./HraDropdowns";
import "./Sections.css";
import { Section8Schema } from "./ValidationSchemas";

function Section8() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SectionValue = localStorage.getItem("SectionValue");

  useEffect(() => {
    dispatch(GetQuestionSection8Request());
    dispatch(GetSection8AnswersRequest());
  }, []);
  const GetQuestionSection = useSelector(
    (state) => state.getQuestion.getQuestionSection8
  );
  const [json, setJson] = useState();
  const [counter, setCounter] = useState(false);

  const currentSectionValue = localStorage.getItem("SectionValue");
  const GetSectionAnswers = useSelector(
    (state) => state.getSectionAnswer.getSection8Answers
  );

  useEffect(() => {
    if (GetSectionAnswers?.data?.data?.data?.data !== undefined) {
      setJson(JSON.parse(GetSectionAnswers?.data?.data?.data?.data));
      setCounter(true);
    }
  }, [GetSectionAnswers?.data?.data?.data?.data]);

  window.addEventListener("popstate", function (event) {
    storage.setStorage("SectionValue", 7);
  });
  const handleSubmit = (values, { setSubmitting }) => {
    localStorage.setItem("SectionValue", 9);
    dispatch(Section8AnswersSuccess(values));

    dispatch(SaveSectionAnswersRequest({ values, currentSectionValue }));
    navigate("/health-risk-assessment/section-9a");
  };
  const SaveSectionAnswers = useSelector(
    (state) => state.hra.saveSectionAnswers
  );

  function handlePrevious() {
    navigate("/health-risk-assessment/section-8b");
  }

  if (storage.getStorage("token") === null) {
    return <SingleLogin />;
  }

  if (GetQuestionSection.isLoading) {
    return <YuvaLoader />;
  }
  return (
    <>
      <ScrollToTop />

      <div>
        <div className="HRAQuestionsMain">
          <div className="HRAQuestionsMainChild1">
            <p>Your Health Risk Assessment</p>
          </div>
          {SectionValue === "8" && (
            <div
              className="HRAQuestionsMainChild2"
              style={{ backgroundImage: `url(${hra9})` }}
            >
              <div className="HRAQuestionsMainChild2a">
                <div className="HRAQuestionsMainChild2a1">
                  <p>GENERAL SECTION</p>
                </div>
                <div className="HRAQuestionsMainChild2a1 faded">
                  <p>DIETARY SECTION</p>
                </div>
                <div className="HRAQuestionsMainChild2a1">
                  <p>MENTAL HEALTH SECTION</p>
                </div>
                <div className="HRAQuestionsMainChild2a1 faded">
                  <p>ALCOHOL RISK SECTION</p>
                </div>
                <div className="HRAQuestionsMainChild2a1">
                  <p>SMOKING & TOBACCO SECTION</p>
                </div>
                <div className="HRAQuestionsMainChild2a1 faded">
                  <p>SAFETY HABITS SECTION</p>
                </div>
                <div className="HRAQuestionsMainChild2a1">
                  <p>CURRENT MEDICAL CONDITION SECTION</p>
                </div>
                <div className="HRAQuestionsMainChild2a1 faded">
                  <p>HERIDETARY AND GENETIC SECTION</p>
                </div>
                <div className="HRAQuestionsMainChild2a2">
                  <Formik
                    initialValues={
                      !counter
                        ? {
                            Q51: "",
                            Q52: "",
                            Q53: "",
                            Q54: "",
                            Q55: "",
                            Q56: "",
                          }
                        : {
                            Q51: json?.Q51.toString(),
                            Q52: json?.Q52.toString(),
                            Q53: json?.Q53.toString(),
                            Q54: json?.Q54.toString(),
                            Q55: json?.Q55.toString(),
                            Q56: json?.Q56.toString(),
                          }
                    }
                    validationSchema={Section8Schema}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                  >
                    {
                      <Form>
                        <div className="HRAQuestionsMainChild2a2div">
                          <div className="hraBackArrow">
                            <img
                              src={backArrow}
                              onClick={handlePrevious}
                              alt="backArrow"
                            />
                            <p>Hereditary and Genetic</p>
                          </div>
                          <label className="Q_A">
                            <p>
                              {
                                GetQuestionSection?.data?.data?.data?.data[0]
                                  .question
                              }
                            </p>
                            <Field
                              as="select"
                              name="Q51"
                              // placeholder="Doctor Name"
                              className="select"
                            >
                              <option value={dropDisabled.value} disabled>
                                {dropDisabled.message}
                              </option>
                              {pickeryesno.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.option}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              className="errorMessageValidation"
                              name="Q51"
                              component="div"
                            />
                          </label>
                          <label className="Q_A">
                            <p>
                              {
                                GetQuestionSection?.data?.data?.data?.data[1]
                                  .question
                              }
                            </p>
                            <Field
                              as="select"
                              name="Q52"
                              // placeholder="Doctor Name"
                              className="select"
                            >
                              <option value={dropDisabled.value} disabled>
                                {dropDisabled.message}
                              </option>
                              {pickeryesno.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.option}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              className="errorMessageValidation"
                              name="Q52"
                              component="div"
                            />
                          </label>
                          <label className="Q_A">
                            <p>
                              {
                                GetQuestionSection?.data?.data?.data?.data[2]
                                  .question
                              }
                            </p>
                            <Field
                              as="select"
                              name="Q53"
                              // placeholder="Doctor Name"
                              className="select"
                            >
                              <option value={dropDisabled.value} disabled>
                                {dropDisabled.message}
                              </option>
                              {pickeryesno.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.option}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              className="errorMessageValidation"
                              name="Q53"
                              component="div"
                            />
                          </label>
                          <label className="Q_A">
                            <p>
                              {
                                GetQuestionSection?.data?.data?.data?.data[3]
                                  .question
                              }
                            </p>
                            <Field
                              as="select"
                              name="Q54"
                              // placeholder="Doctor Name"
                              className="select"
                            >
                              <option value={dropDisabled.value} disabled>
                                {dropDisabled.message}
                              </option>
                              {pickeryesno.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.option}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              className="errorMessageValidation"
                              name="Q54"
                              component="div"
                            />
                          </label>
                          <label className="Q_A">
                            <p>
                              {
                                GetQuestionSection?.data?.data?.data?.data[4]
                                  .question
                              }
                            </p>
                            <Field
                              as="select"
                              name="Q55"
                              // placeholder="Doctor Name"
                              className="select"
                            >
                              <option value={dropDisabled.value} disabled>
                                {dropDisabled.message}
                              </option>
                              {pickeryesno.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.option}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              className="errorMessageValidation"
                              name="Q55"
                              component="div"
                            />
                          </label>
                          <label className="Q_A">
                            <p>
                              {
                                GetQuestionSection?.data?.data?.data?.data[5]
                                  .question
                              }
                            </p>
                            <Field
                              as="select"
                              name="Q56"
                              // placeholder="Doctor Name"
                              className="select"
                            >
                              <option value={dropDisabled.value} disabled>
                                {dropDisabled.message}
                              </option>
                              {pickeryesno.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.option}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              className="errorMessageValidation"
                              name="Q56"
                              component="div"
                            />
                          </label>

                          <button type="submit" className="HRAQuestionsButton">
                            Next
                          </button>
                        </div>
                      </Form>
                    }
                  </Formik>
                </div>
              </div>
            </div>
          )}
        </div>

        {SectionValue !== "8" && (
          <div className="hraDenied">
            Not Authorized !! please navigate to previous sections
          </div>
        )}
      </div>
    </>
  );
}
export default Section8;
