import React from 'react'
import "./ComingSoon.css"
import comingSoonImage from "../../../assets/OPD Cashless/raft.png"
import Footer from "../../Organisms/Footer/Footer"

function ComingSoon() {
  return (
    <>
    <div className='ComingSoonMain'>
        <p>We are Coming Soon</p>
        <img src={comingSoonImage} alt='raft'/>
    </div>
   
    
    </>
  )
}

export default ComingSoon