import CircularProgress from "@mui/material/CircularProgress";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { GetRelationsRequest } from "../../../redux/Action/HealthRiskAssessment/GetRelationsAction";
import { AddRelationRequest } from "../../../redux/Action/User/AddRelationAction";
import { GetAllRelativesRequest } from "../../../redux/Action/User/GetAllRelatives";
import storage from "../../../Utils/storage";
import Title from "../../Atoms/Title/Title";
import "./AddFamilyMember.css";

function AddFamilyMember() {
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const name = localStorage.getItem("name");

  useEffect(() => {
    if (!name) {
      window.location.href = "/login";
    }
  }, [name]);

  useEffect(() => {
    dispatch(GetRelationsRequest());
    dispatch(GetAllRelativesRequest());
  }, [dispatch]);

  if (storage.getStorage("token") === null) {
    navigate("/");
  }

  const loginSchema = Yup.object().shape({
    Name: Yup.string().trim().required("Required Field"),
    Age: Yup.number()
      .integer("Age must be an integer")
      .required("Required Field"),
    Relationship: Yup.string().required("Required Field"),
  });

  const handleSubmit = (values) => {
    setIsSubmitting(true);
    dispatch(AddRelationRequest(values));
  };

  const GetAllRelatives = useSelector((state) => state.user.getAllRelatives);

  const GetRelations = useSelector((state) => state.hra.getRelations);
  const AddRelation = useSelector((state) => state.user.addRelation);

  useEffect(() => {
    if (AddRelation.data?.data.status === 201) {
      Swal.fire({
        title: AddRelation?.data?.data?.data?.message,
        icon: "success",
      }).then(() => {
        setIsSubmitting(false);
        window.location.reload();
      });
    } else if (AddRelation.error !== null) {
      Swal.fire({
        title: AddRelation?.error?.data?.response?.data?.errorMessage,
        icon: "error",
      }).then(() => {
        setIsSubmitting(false);
        window.location.reload();
      });
    }
  }, [AddRelation]);

  return (
    <>
      <Title title="Yuva | Add Family Member"></Title>
      <>
        <div className="AddFamilyMemberWrapper">
          <div className="AddFamilyMemberSection1">
            <h1 className="AddFamilyMemberHeader">ADD FAMILY MEMBER</h1>
            <Formik
              initialValues={{
                Name: "",
                Age: "",
                Relationship: "",
              }}
              enableReinitialize={true}
              validationSchema={loginSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className="AddFamilyMemberInputRow1">
                  <div className="AddFamilyMemberInputDiv">
                    <h3 className="AddFamilyMemberInputName">Name</h3>
                  </div>

                  <div className="AddFamilyMemberInputDiv">
                    <label>
                      <Field
                        placeholder="Name"
                        name="Name"
                        type="text"
                        className="AddFamilyMemberInputField"
                      ></Field>
                      <ErrorMessage
                        name="Name"
                        className="AddFamilyMemberError"
                        component="div"
                      />
                    </label>
                  </div>

                  <div className="AddFamilyMemberInputDiv2">
                    <h3 className="AddFamilyMemberInputName">Age</h3>
                  </div>

                  <div className="AddFamilyMemberInputDiv">
                    <label>
                      <Field
                        placeholder="Age"
                        name="Age"
                        type="number"
                        className="AddFamilyMemberInputField"
                      ></Field>
                      <ErrorMessage
                        name="Age"
                        className="AddFamilyMemberError"
                        component="div"
                      />
                    </label>
                  </div>

                  <div className="AddFamilyMemberInputDiv3">
                    <h3 className="AddFamilyMemberInputName">Relationship</h3>
                  </div>

                  <div className="AddFamilyMemberInputDiv3">
                    <label>
                      <Field
                        as="select"
                        name="Relationship"
                        placeholder="Relationship"
                        className="AddFamilyMemberInputFieldD"
                      >
                        <option value="" disabled selected>
                          Select Relationship
                        </option>
                        {GetRelations?.data?.data?.data?.data?.map(
                          (item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          )
                        )}
                      </Field>
                      <ErrorMessage
                        className="AddFamilyMemberError"
                        name="Relationship"
                        component="div"
                      />
                    </label>
                  </div>
                </div>

                <div className="submitButtonAddMemberWrapper">
                  <button
                    type="submit"
                    className="submitButtonAddMember"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <CircularProgress /> : <div>Submit</div>}
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
          <div className="AddFamilyMemberCardsWrapper">
            {GetAllRelatives?.data?.data?.data?.data?.map((item) => (
              <div className="AddFamilyMemberCards">
                <h1 className="AddFamilyMemberTitle">{item.name}</h1>
                <h1 className="AddFamilyMemberDescription">{item.relation}</h1>
                <h1 className="AddFamilyMemberDescription">{item.age} Years</h1>
              </div>
            ))}
          </div>
        </div>
      </>
    </>
  );
}

export default AddFamilyMember;
