import React from "react";
import { useNavigate } from "react-router-dom";
import createSlug from "../../Molecules/SlugGenerator/CreateSlug";
import "./SearchModal.css";

function SearchModal({
  getPopularPackage,
  getPopularPlan,
  getPopularTest,
  homeScreenProduct,
}) {
  const navigate = useNavigate();

  const handleTestNavigate = async (item) => {
    try {
      const slug = await createSlug(item.testName);
      window.location.href = `/test/${slug}?id=${item.testId}`;
    } catch (error) {}
  };

  const handlePackageNavigate = async (item) => {
    try {
      const slug = await createSlug(item.packageName);
      window.location.href = `/package/${slug}?id=${item.packageUuid}`;
    } catch (error) {}
  };

  const handlePlanNavigate = async (index) => {
    try {
      const slug = await createSlug(
        getPopularPlan?.data?.data?.data?.data[index].name
      );
      navigate(
        `/plan/${slug}?id=${getPopularPlan?.data?.data?.data?.data[index].planUuid}`
      );
      window.location.reload();
    } catch (error) {}
  };


  function handleProductNavigation(id) {
    navigate("/product-info?product-id=" + id);
    window.location.reload();
  }

  return (
    <div>
      <div className="SearchModalMainContainer">
        <div className="SearchModalMainContainerGrid">
          <div>
            <div className="SearchModalMainText">Test</div>
            {getPopularTest.data?.data?.data.data.popularTestResponseDtoList.map(
              (item, index) => (
                <div
                  className="SearchModalMainSubText"
                  onClick={() => handleTestNavigate(item)}
                  key={index}
                >
                  {item.testName}
                </div>
              )
            )}
          </div>
          <div>
            <div className="SearchModalMainText">Packages</div>
            {getPopularPackage?.data?.data?.data.data.popularPackageResponseDtoList.map(
              (item, index) => (
                <div
                  className="SearchModalMainSubText"
                  onClick={() => handlePackageNavigate(item)}
                  key={index}
                >
                  {item.packageName}
                </div>
              )
            )}
          </div>
          <div>
            <div className="SearchModalMainText">Plans</div>
            {getPopularPlan?.data?.data?.data.data
              ?.slice(0, 8)
              .map((item, index) => (
                <div
                  className="SearchModalMainSubText"
                  onClick={() => handlePlanNavigate(index)}
                  key={index}
                >
                  {item.name}
                </div>
              ))}
          </div>
          <div>
            <div className="SearchModalMainText">Product</div>
            {homeScreenProduct?.data?.data.data.data?.map((item, index) => (
              <div
                className="SearchModalMainSubText"
                onClick={() => handleProductNavigation(item.productId)}
                key={index}
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchModal;
