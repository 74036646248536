import RequestService from "../../RequestService";
import { api } from "../../../helpers/CheckEmail/CheckEmail";

class NetworkService {
  CheckEmail(action) {
    if(action.corporateId === null || action.corporateId === undefined){
      const endPoint = `${api.check}/${action.data}`;
      return RequestService.getRequest(endPoint);
    }
    const endPoint = `${api.check}/${action.data}?corporateId=${action.corporateId}&token=${action.token}`;
    return RequestService.getRequest(endPoint);

  }

//   WriteToUs(action) {

//     const endPoint = `${api.WriteToUs}`;
//     return RequestService.postRequest(endPoint, {
//       contactNumber: action.data.contactNumber.toString(),
//       email: action.data.email,
//       message: action.data.message,
//       name: action.data.name,
//       queryType: action.data.queryType,
//     });
//   }
}

export default new NetworkService();
