import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Cart/CreateCartActionType";
import * as actions from "../../Action/Cart/GetAllCouponAction";
import NetworkService from "../../../network/Network/Cart/CartNetwork";

export function* GetAllCoupon(action) {
  try {
    let response = yield call(NetworkService.GetAllCoupon, action);
    yield put(actions.GetAllCouponSuccess(response));
  } catch (err) {
    yield put(actions.GetAllCouponFailure(err));
  }
}

export function* watchGetAllCouponSaga() {
  yield takeLatest(types.GET_ALL_COUPON_REQUEST, GetAllCoupon);
}
