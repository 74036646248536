import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/RefreshToken/refreshTokenActionType";
import * as actions from "../../Action/RefreshToken/refreshTokenAction";
import NetworkService from "../../../network/Network/RefreshToken/RefreshTokenNetwork";

export function* refreshTokenSaga(action) {
  try {
    let response = yield call(NetworkService.refreshToken, action);
    yield put(actions.refreshTokenSuccess(response));
  } catch (err) {
    yield put(actions.refreshTokenFailure(err));
  }
}

export function* watchRefreshTokenSaga() {
  yield takeLatest(types.REFRESH_TOKEN_REQUEST, refreshTokenSaga);
}
