export const CONNECTION_BETWEEN_US_SUCCESS = "CONNECTION_BETWEEN_US_SUCCESS"
export const CONNECTION_BETWEEN_US_FAILURE = "CONNECTION_BETWEEN_US_FAILURE"
export const CONNECTION_BETWEEN_US_REQUEST = "CONNECTION_BETWEEN_US_REQUEST"

export const VIEW_ALL_HOSPITAL_SUCCESS = "VIEW_ALL_HOSPITAL_SUCCESS"
export const VIEW_ALL_HOSPITAL_FAILURE = "VIEW_ALL_HOSPITAL_FAILURE"
export const VIEW_ALL_HOSPITAL_REQUEST = "VIEW_ALL_HOSPITAL_REQUEST"

export const HOSPITAL_NETWORK_FORM_SUCCESS = "HOSPITAL_NETWORK_FORM_SUCCESS"
export const HOSPITAL_NETWORK_FORM_FAILURE = "HOSPITAL_NETWORK_FORM_FAILURE"
export const HOSPITAL_NETWORK_FORM_REQUEST = "HOSPITAL_NETWORK_FORM_REQUEST"

export const CLINIC_NETWORK_FORM_SUCCESS = "CLINIC_NETWORK_FORM_SUCCESS"
export const CLINIC_NETWORK_FORM_FAILURE = "CLINIC_NETWORK_FORM_FAILURE"
export const CLINIC_NETWORK_FORM_REQUEST = "CLINIC_NETWORK_FORM_REQUEST"

export const PROFESSIONAL_SERVICES_NETWORK_FORM_SUCCESS = "PROFESSIONAL_SERVICES_NETWORK_FORM_SUCCESS"
export const PROFESSIONAL_SERVICES_NETWORK_FORM_FAILURE = "PROFESSIONAL_SERVICES_NETWORK_FORM_FAILURE"
export const PROFESSIONAL_SERVICES_NETWORK_FORM_REQUEST = "PROFESSIONAL_SERVICES_NETWORK_FORM_REQUEST"

export const DIAGNOSTIC_NETWORK_FORM_SUCCESS = "DIAGNOSTIC_NETWORK_FORM_SUCCESS"
export const DIAGNOSTIC_NETWORK_FORM_FAILURE = "DIAGNOSTIC_NETWORK_FORM_FAILURE"
export const DIAGNOSTIC_NETWORK_FORM_REQUEST = "DIAGNOSTIC_NETWORK_FORM_REQUEST"

export const VIEW_ALL_NETWORK_TYPE_SUCCESS = "VIEW_ALL_NETWORK_TYPE_SUCCESS"
export const VIEW_ALL_NETWORK_TYPE_FAILURE = "VIEW_ALL_NETWORK_TYPE_FAILURE"
export const VIEW_ALL_NETWORK_TYPE_REQUEST = "VIEW_ALL_NETWORK_TYPE_REQUEST"

export const VIEW_ALL_NETWORK_SUCCESS = "VIEW_ALL_NETWORK_SUCCESS"
export const VIEW_ALL_NETWORK_FAILURE = "VIEW_ALL_NETWORK_FAILURE"
export const VIEW_ALL_NETWORK_REQUEST = "VIEW_ALL_NETWORK_REQUEST"


export const VIEW_NETWORK_COUNT_SUCCESS = "VIEW_NETWORK_COUNT_SUCCESS"
export const VIEW_NETWORK_COUNT_FAILURE = "VIEW_NETWORK_COUNT_FAILURE"
export const VIEW_NETWORK_COUNT_REQUEST = "VIEW_NETWORK_COUNT_REQUEST"