import { combineReducers } from "redux";
import { viewAllProductCategoryReducer } from "./viewAllProductCategoryReducer";
import { viewProductByCategoryIdReducer } from "./viewProductByCategoryIdReducer";
import { viewProductByIdReducer } from "./viewProductByIdReducer";
import { viewTopCategoriesReducer } from "./viewTopCategoriesReducer";
import { viewAllProductsReducer } from "./viewAllProductsReducer";
import { getBrandDropdownReducer } from "./getBrandDropdownReducer";
import { getCategoryDropdownReducer } from "./getCategoryDropdownReducer";
import { getSubCategoryDropdownReducer } from "./getSubCategoryDropdownReducer";
import { viewHomeScreenProductReducer } from "./viewHomeScreenProductReducer";

export default combineReducers({
    viewAllProductCategory: viewAllProductCategoryReducer,
    viewProductByCategoryId: viewProductByCategoryIdReducer,
    viewProductById: viewProductByIdReducer,
    viewTopCategories: viewTopCategoriesReducer,
    viewAllProducts: viewAllProductsReducer,
    getBrandDropdown: getBrandDropdownReducer,
    getCategoryDropdown: getCategoryDropdownReducer,
    getSubCategoryDropdown: getSubCategoryDropdownReducer,
    viewHomeScreenProduct: viewHomeScreenProductReducer,
})