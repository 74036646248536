import RequestService from "../../../RequestService";
import { api } from "../../../../helpers/OTP/OTPValidationMobile/otpMobileValidationConstant";

class NetworkService {

  validateMobileOtp(action) {
    const endPoint = `${api.validateMobileOtp}`;
    return RequestService.postRequest(endPoint,{
      emailOrNumber:action.data.id,
      otp:action.data.hash
    });
    
  }
  
}

export default new NetworkService();